import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
//import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
//import { Item } from '../models/item';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { EncryptionService } from '../servicios/encryption.service';
import { Observable } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  private baseUrl = environment.baseUrl;

  constructor(
    private router: Router,
    private encryption: EncryptionService,
    private http: HttpClient
  ) {

    this.router.errorHandler = (error: any) => {
      this.router.navigate(['/']); // or redirect to default route
    }
  }

  private handleError(error: Response | any) {
    // console.error(error);
    return Observable.throw(error);
  }

  gotoUrl(url: string) {
    this.router.navigate([url]);
    return false;
  }

  getToken() {
    const token = localStorage.getItem("JWT_TOKEN");
    if (token === null) {
      return null;
    }
    return token;
  }

  isLoggednIn() {

    const LOGGED_IN = localStorage.getItem("LOGGED_IN");

    if (LOGGED_IN === null) {
      return null;
    }

    if (LOGGED_IN === 'LOGGED_IN') {
      return true;
    }

    localStorage.removeItem("JWT_TOKEN");
    localStorage.removeItem("LOGGED_IN");

    return false;
  }

  logout() {
    localStorage.removeItem("JWT_TOKEN");
    localStorage.removeItem("LOGGED_IN");
    return true;
  }

  getUserInfoFromToken(token = '', decrypted = true) {
    let tokenPayload: any;
    let userInfo: any;

    try {
      if (token.length === 0) {
        token = this.getToken();
      }

      // if (userInfo === null) {
      //   this.cerrarSesion();
      // }

      // console.log(token);
      if (decrypted === true) {
        token = this.encryption.decryptUsingAES256(token);
        tokenPayload = jwt_decode(token);
        userInfo = tokenPayload.data.userInfo;
      } else {
        tokenPayload = jwt_decode(token);
        userInfo = tokenPayload.data.userInfo;
      }
      return userInfo;
    } catch (e) {
      console.log('Error getUserInfoFromToken:' + e)
      return null;
    }
  }

  getTipoUsuario() {
    let userInfo: any;
    try {
      userInfo = this.getUserInfoFromToken();
      userInfo = userInfo.tipo;
      return userInfo; //Tipo;
    } catch (e) {
      console.log('Error getUserInfoFromToken:' + e)
      return null;
    }
  }




  refreshToken(): Observable<any> {
    console.log('Cambio de página...');
    const options = {} as any; // Set any options you like
    console.log(localStorage.getItem('LOCAL_USER1'));
    console.log(localStorage.getItem('LOCAL_USER2'));
    const localUser1 = localStorage.getItem('LOCAL_USER1');
    const localUser2 = localStorage.getItem('LOCAL_USER2');
    const formData = new FormData();
    formData.append('local_user1', localUser1);
    formData.append('local_user2', localUser2);
    return this.http.post(this.baseUrl + 'users/refreshToken', formData, options)
      .map(
        res => res
      ).catch(
        this.handleError
      );
  }

  getData(queryString: string = '', list: string, withList = true): Observable<any> {
    //const options = {} as any; // Set any options you like
    //  const token = localStorage.getItem("JWT_TOKEN");

    //const options = new HttpHeaders()
    // .append('Access-Control-Allow-Origin', '*')
    // .append('body', '');
    //.append('Content-Type', 'application/json; charset=utf-8')
    // .append('Accept', 'application/json')
    //  .append('Authorization', 'Bearer ' + token);

    // headers: new HttpHeaders().append('Authorization', 'Bearer <yourtokenhere>'),

    // const httpHeaders = new HttpHeaders({
    //   //  'Content-Type': multipart/form-data
    //   'Content-Type': 'application/json; charset=utf-8',
    //   'Accept': 'application/json',
    //   'Authorization': 'Bearer '+token
    // });

    // const httpHeaders = new Headers();
    // httpHeaders.append('Access-Control-Allow-Headers', 'Content-Type');
    // httpHeaders.append('Access-Control-Allow-Methods', 'GET');
    // httpHeaders.append('Access-Control-Allow-Origin', '*');

    // const httpHeaders = new HttpHeaders({
    //   //    'Content-Type': 'multipart/form-data',
    //   //'Content-Type': 'application/json; charset=utf-8',
    //   'Accept': 'application/json',
    //   'Authorization': 'Bearer ' + token
    // });

    // const options = {
    //   headers: httpHeaders
    // };

    // const httpHeaders = new HttpHeaders();
    // httpHeaders.set('Authorization', 'Bearer '+token);

    if (withList) {
      return this.http.get(this.baseUrl + queryString)
        // .retryWhen(errors => errors.delay(500))
        .map(
          res => res[list]
        ).catch(
          this.handleError
        );
    }

    /*    if (withList) {
      return this.http.get(this.baseUrl + queryString)
      .retryWhen(errors =>
        errors.pipe(
          tap(errorStatus => {
            let OrgReq;
            console.log("tap: ", errorStatus);
            if (errorStatus.status === 401) {
              OrgReq = req.clone();
              this.authModalRef = this.modalService.show(AuthenticationComponent, this.initialState);  
              this.authModalRef.content.onLoggedIn.subscribe(loggedIn => {
                    console.log("has logged? ", loggedIn, OrgReq);
                    return next.handle(OrgReq);
                });
            }

            throw errorStatus;

          })
        )
      )
        .map(
          res => res[list]
        ).catch(
          this.handleError
        );
    }
*/

    return this.http.get(this.baseUrl + queryString)
      // this.retryWhen(errors => errors.delay(500))
      .map(
        res => res
      ).catch(
        this.handleError
      );

  }

  async getDataPromise(queryString: string = '', list: string, withList = true): Promise<any> {
    let promise = await new Promise((resolve, reject) => {
      if (withList) {
        this.http.get(this.baseUrl + queryString)
          .toPromise()
          .then(
            res => { // Success
              console.log(res);
              res = res[list]
              console.log(res);
              resolve();
            },
            msg => { // Error
              console.log(msg);
              reject(msg);
            });
        // res => res[list])
        // .catch(this.handleError);

      }

      this.http.get(this.baseUrl + queryString)
        .toPromise()
        .then(
          res => { // Success
            res = res[list]
            console.log(res);
            resolve();
          },
          msg => { // Error
            console.log(msg);
            reject(msg);
          });
    });

    return promise;
  }



  postData(formData: any, url: string): Observable<any> {

    const httpHeaders = new HttpHeaders({
      //  'Content-Type': multipart/form-data
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

    const options = {
      headers: httpHeaders
    };

    return this.http.post(this.baseUrl + url, JSON.stringify(formData), options)
      // .retryWhen((errors) => {
      //   console.log(errors);
      //   return errors.scan((intentos: number) => {
      //     intentos++;
      //     if (intentos < 3) {
      //       console.log('Intento: ' + intentos);
      //       return intentos;
      //     } else {
      //       throw errors;
      //     }
      //   }, 0).delay(1000)
      // })
      .map(
        res => res
      ).catch(
        this.handleError
      );
  }

  // postAndPutFormData(formData: FormData, url: string, postOrPut='post'): Observable<any> {
  //   // const options = {} as any; // Set any options you like

  //   if(postOrPut==='post') {
  //     return this.http.post(this.baseUrl + url, formData)
  //     .map(
  //       res => res
  //     ).catch(
  //       this.handleError
  //     );
  //   } else {

  //   }

  //  }

  handleRetry(errors: Observable<Response>): Observable<any> {

    return errors.mergeMap((error) => {
      console.log(error);

      if (error.status === 400) {
        console.log('hizo el intento');
        // Add an authorization header, perhaps.
        // ...
        // Emit anything (the error instance, for example) to retry:
        return Observable.of(error);

      } else {

        // Throw the error to give up retrying:
        return Observable.throw(error);
      }
    });
  }

  //RETRY https://www.youtube.com/watch?v=leTQOMk9PIo
  //retry https://stackoverflow.com/questions/50103048/how-to-use-angular-service-inside-rxjs-retrywhen-method
  //https://stackoverflow.com/questions/44377619/angular-2-observable-retrywhen-doesnt-include-status-in-error
  //retry https://stackoverflow.com/questions/51904407/how-to-throw-the-original-error-when-using-rxjs-retrywhen-with-zero-retries

  postFormData(formData: FormData, url: string): Observable<any> {
    // const options = {} as any; // Set any options you like
    return this.http.post(this.baseUrl + url, formData)
      //   .retryWhen(this.handleRetry)
      //    .delay(1000)
      .map(
        res => res
      ).catch(
        this.handleError
      );
  }

  /*    .retryWhen((error: Observable<Response>): Observable<any> => {
        console.log(error);
        return error.scan((intentos: number) => {
          intentos++;
          if (intentos < 3) {
            console.log('Intento: ' + intentos);
            return intentos;
          } else {
            throw error;
          }
        }, 0).delay(1000)
      }).catch((err) => {
        // if (err.status === 400 || err.status === 422) {
        return Observable.throw(err);
        // } 
        // else {
        //    
        //     this.wpService.errors.next(err);
        // }
      })
      .finally(() => {

      })*/
  putFormData(formData: FormData, url: string): Observable<any> {
    formData.append('_method', 'PUT');
    //   const options = {} as any; // Set any options you like
    return this.http.post(this.baseUrl + url, formData)
      ///  .retryWhen(errors => errors.delay(500))
      .map(
        res => res
      ).catch(
        this.handleError
      );
  }

  bloquearRegistro(url: string): Observable<any> {
    const formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('estado', 'Bloqueado');
    const options = {} as any; // Set any options you like
    return this.http.post(this.baseUrl + url, formData, options)
      .map(
        res => res
      ).catch(
        this.handleError
      );
  }

  desBloquearRegistro(url: string): Observable<any> {
    const formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('estado', 'Activo');
    const options = {} as any; // Set any options you like
    return this.http.post(this.baseUrl + url, formData, options)
      .map(
        res => res
      ).catch(
        this.handleError
      );
  }

  deleteData(path: string, id: number, queryString = ''): Observable<any> {

    if (queryString.length > 0) {
      return this.http.delete(this.baseUrl + path + '/' + id + '' + queryString)
        .map(
          res => res
        ).catch(
          this.handleError
        );
    }

    return this.http.delete(this.baseUrl + path + '/' + id)
      .map(
        res => res
      ).catch(
        this.handleError
      );
  }

  loginUsuarios(user: string, password: string) {
    const formData = new FormData();
    formData.set('username', user);
    formData.set('password', password);
    return this.http.post(this.baseUrl + 'users/login', formData)
      .map(
        res => res
      ).catch( 
        this.handleError
      );
  }

  postJsonData(url: string, formData): Observable<any> {

    const options = {} as any; // Set any options you like

    //const formData = new FormData();
    //  formData.append('_method', 'PUT');
    // formData.append('historia_json', JSON.stringify(json1));

    return this.http.post(this.baseUrl + url, formData, options)
      .map(res => res)
      .catch(this.handleError);

  }

  public collapseAll(grid: any, currentPage, pageSize, indx?) {
    console.log(grid.data);
    if (grid.data !== undefined) {
      const data: any = grid.data;
      indx = indx - 1;
      if (data.data.length > 0) {
        Object.keys(data.data).forEach((index) => {
          // console.log({index: index});
          // const indice = (Number(pageSize) * Number(currentPage)) - Number(pageSize);
          // console.log(indx);
          const fila = Number(index) + indx;
          //  console.log(fila);
          grid.collapseRow(fila);
        });
      }
    }
  }



  public expandRow(grid: any, rowIndex: Number) {
    grid.expandRow(Number(rowIndex));
  }

  public expandAll(grid: any) {
    const data = grid.data;
    Object.keys(data.data).forEach((index) => {
      grid.expandRow(index);
    })
  }

  setFilaActiva(index: any, lista: string) {
    sessionStorage.setItem(lista, '' + index);
  }

  setRegistroActivo(index: any, lista: string) {
    sessionStorage.setItem(lista, '' + index);
  }

  getFilaActiva(lista: string) {
    const fila = sessionStorage.getItem(lista);
    if (fila !== null) {
      return fila;
    }
    return null;
  }


  removerVariablesSession() {

    //Pacientes
    sessionStorage.removeItem('valFiltroListadoPacientes');
    sessionStorage.removeItem('queryStringListadoPacientes');
    sessionStorage.removeItem('ListadoPacientesFilaActiva');

    //Cies
    sessionStorage.removeItem('ListadoCiesValFiltro');
    sessionStorage.removeItem('ListadoCiesQueryString');
    sessionStorage.removeItem('ListadoCiesFilaActiva');

    //Generos
    sessionStorage.removeItem('ListadoGenerosValFiltro');
    sessionStorage.removeItem('ListadoGenerosQueryString');
    sessionStorage.removeItem('ListadoGenerosFilaActiva');

    //Grupos sanguineos
    sessionStorage.removeItem('ListadoGruposSanValFiltro');
    sessionStorage.removeItem('ListadoGruposSanFilaActiva');
    sessionStorage.removeItem('ListadoGruposSanQueryString');

    //Tipos de documentos
    sessionStorage.removeItem('ListadoTiposIdsValFiltro');
    sessionStorage.removeItem('ListadoTiposIdsFilaActiva');
    sessionStorage.removeItem('ListadoTiposIdsQueryString');

    //Estados civiles
    sessionStorage.removeItem('ListadoEstadosCivValFiltro');
    sessionStorage.removeItem('ListadoEstadosCivQueryString');
    sessionStorage.removeItem('ListadoEstadosCivFilaActiva');

    //Nieveles  de escolaridad
    sessionStorage.removeItem('ListadoNivelesEscValFiltro');
    sessionStorage.removeItem('ListadoNivelesEscQueryString');
    sessionStorage.removeItem('ListadoNivelesEscFilaActiva');

    //Eps
    sessionStorage.removeItem('ListadoEpsValFiltro');
    sessionStorage.removeItem('ListadoEpsQueryString');
    sessionStorage.removeItem('ListadoEpsFilaActiva');

    //Departamentos
    sessionStorage.removeItem('ListadoDepartamentosValFiltro');
    sessionStorage.removeItem('ListadoDepartamentosQueryString');
    sessionStorage.removeItem('ListadoDepartamentosFilaActiva');

    //Fondos de pensión
    sessionStorage.removeItem('ListadoFondosPensionValFiltro');
    sessionStorage.removeItem('ListadoFondosPensionQueryString');
    sessionStorage.removeItem('ListadoFondosPensionFilaActiva');

    //Especialidades médicas
    sessionStorage.removeItem('ListadoEspecialidadesValFiltro');
    sessionStorage.removeItem('ListadoEspecialidadesQueryString');
    sessionStorage.removeItem('ListadoEspecialidadesFilaActiva');

    //Consultorios
    sessionStorage.removeItem('ListadoConsultoriosValFiltro');
    sessionStorage.removeItem('ListadoConsultoriosQueryString');
    sessionStorage.removeItem('ListadoConsultoriosFilaActiva');

    //Tipo de citas
    sessionStorage.removeItem('ListadoTiposCitasValFiltro');
    sessionStorage.removeItem('ListadoTiposCitasQueryString');
    sessionStorage.removeItem('ListadoTiposCitasFilaActiva');

    //Ciudades
    sessionStorage.removeItem('ListadoCiudadesValFiltro');
    sessionStorage.removeItem('ListadoCiudadesQueryString');
    sessionStorage.removeItem('ListadoCiudadesFilaActiva');

    //Ocupaciones
    sessionStorage.removeItem('ListadoOcupacionesValFiltro');
    sessionStorage.removeItem('ListadoOcupacionesQueryString');
    sessionStorage.removeItem('ListadoOcupacionesFilaActiva');

    //Paises
    sessionStorage.removeItem('ListadoCountriesValFiltro');
    sessionStorage.removeItem('ListadoCountriesQueryString');
    sessionStorage.removeItem('ListadoCountriesFilaActiva');
  }

  // putData(formData: any, url: string): Observable<any> {

  //   const httpHeaders = new HttpHeaders({
  //     // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  //     // 'Accept': 'application/json'
  //     'Content-Type': 'application/json',
  //   });

  //   const options = {
  //     headers: httpHeaders
  //   };

  //   //   const formData1 = new FormData();
  //   // this.statusChangeForm.actvie = status);
  //   //  formData1.append('statusxx', status);

  //   // console.log(formData);

  //   return this.http.put(this.baseUrl + url, formData, options)
  //     .map(
  //       res => res
  //     ).catch(
  //       this.handleError
  //     );
  // }

  // putFormData(formData: FormData, url: string): Observable<any> {


  //   return this.http.put(this.baseUrl + url, formData)
  //     .map(
  //       res => res
  //     ).catch(
  //       this.handleError
  //     );
  // }

  // // ************************************ IVAS ************************
  // getIva(queryString: string = ''): Observable<any> {
  //   return this.http.get(this.baseUrl + 'iva' + queryString)
  //     .map(
  //       res => res
  //     ).catch(
  //       this.handleError
  //     );
  // }


  // postIva(formData: any): Observable<any> {

  //   const httpHeaders = new HttpHeaders({
  //     'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  //     'Accept': 'application/json'
  //   });

  //   const options = {
  //     headers: httpHeaders
  //   };

  //   return this.http.post(this.baseUrl + 'iva', JSON.stringify(formData), options)
  //     .map(
  //       res => res
  //     ).catch(
  //       this.handleError
  //     );
  // }
  // ************************************ ITEMS *************************************

  // Otras opciones https://stackoverflow.com/questions/22783108/convert-js-object-to-form-data/22783314
  // postItem(data: any): Observable<any> {

  //   const formData = new FormData();
  //   formData.append('categoryId', data.categoryId);
  //   formData.append('description', data.description);
  //   formData.append('expirationDate', data.expirationDate);
  //   formData.append('img', data.imgFile);
  //   formData.append('reference', data.reference);
  //   formData.append('iva_id', data.iva_id);
  //   formData.append('accountId', data.accountId);
  //   formData.append('lot', data.lot);
  //   formData.append('name', data.name);
  //   formData.append('notes', data.notes);
  //   formData.append('purchasePrice', data.purchasePrice);
  //   formData.append('salePrice', data.salePrice);
  //   formData.append('taxes', JSON.stringify(data.taxes));
  //   formData.append('unitCost', data.unitCost);
  //   formData.append('price_list', JSON.stringify(data.price_list));
  //   formData.append('unitId', data.unitId);
  //   formData.append('warehouses', JSON.stringify(data.warehouses));

  //   return this.http.post(this.baseUrl + 'items', formData)
  //     .map(
  //       res => res
  //     ).catch(
  //       this.handleError
  //     );
  // }

  // getItems1(queryString: string): Observable<Item[]> {
  //   return this.http.get<Item[]>(this.baseUrl + 'items' + queryString)
  //     .map(
  //       res => res
  //     ).catch(
  //       this.handleError
  //     );
  // }

  // getItems(queryString: string): Observable<any> {
  //   return this.http.get(this.baseUrl + 'items' + queryString)
  //     .map(
  //       res => res
  //     ).catch(
  //       this.handleError
  //     );
  // }


  // deleteItem(id) {
  //   return this.http.delete(this.baseUrl + 'items/' + id)
  //     .map(
  //       res => res
  //     ).catch(
  //       this.handleError
  //     );
  // }


  // // ************************************ CONTACTS *************************************


  // getContacts(queryString: string): Observable<any> {
  //   return this.http.get(this.baseUrl + 'contacts' + queryString)
  //     .map(
  //       res => res
  //     ).catch(
  //       this.handleError
  //     );
  // }

  // deleteContact(id) {
  //   return this.http.delete(this.baseUrl + 'contacts/' + id)
  //     .map(
  //       res => res
  //     ).catch(
  //       this.handleError
  //     );
  // }

}

