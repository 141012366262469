import { element } from "protractor";
import {
  Component,
  OnInit,
  AfterContentInit,
  ChangeDetectorRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MaxLength,
  OnlyIntegerNumbers,
  MinLength,
  ImageValidator,
} from "src/app/validators/CustomValidators";
import { ServiciosService } from "../../servicios/servicios.service";
import { environment } from "src/environments/environment";
import { GetMsgFromError } from "src/app/functions/SharedFunctions";
import { serverErrorsInfo } from "src/app/utilities/serverErrorsInfo";

@Component({
  selector: "app-agregar-citas",
  templateUrl: "./agregar-citas.component.html",
  styleUrls: ["./agregar-citas.component.css"],
})

// interface HandleNameChangeInterface {
//   target: HTMLInputElement;
// }
export class AgregarCitasComponent implements OnInit, AfterContentInit {
  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private apiServices: ServiciosService
  ) {
    window.scroll(0, 0);
  }

  tablaRangos = "";
  formData: FormGroup;

  // Ventana
  verVentanaBuscarMedico = false;
  verVentanaBuscarPaciente = false;
  verVentanaConfirmacionCita = false;
  rangosPermitidos = [];
  serverErrorsInfo: any = {};
  rangosHoras = [];
  fechasServidor: any;
  fecha: string;
  dia: string;
  rango: string;
  hora: string;
  itemMedico: any;
  itemPaciente: any;
  arrayNoDisponibles = [];
  arrayIdNoDisponibles = [];
  lstMedicos = [];
  pacienteId = 0;
  rangoCitaVisualizada = "";
  ngModelVerMedico = "";
  ngModelVerMedicoIde = "";
  ngModelVerMedicoCelular = "";
  baseUrl2 = environment.baseUrl2;
  ngModelVerPaciente = "";
  ngModelVerPacienteIde = "";
  ngModelVerPacienteCelular = "";

  ngModelVerFecha = "";
  ngModelVerHora = "";

  ngModelCosultorioNombre = "";
  ngModelCosultorioCelular = "";
  ngModelCosultorioTelefono = "";
  ngModelCosultorioDireccion = "";

  ngModelVerEspecialidad = "";

  especidadliadId = 0;
  medicoId = 0;
  consultorioId = 0;
  tipoCitaId = 0;
  //tieneErroresCampos = false;
  desBtnGuardar = false;
  generandoReporte = false;

  verBotonesDetalleCita = {
    guardar: false,
    imprimir: false,
  };

  alertMsg2 = {
    showAlert: false,
    msg: "",
    class: "",
  };

  alertMsg1 = {
    showAlert: false,
    msg: "",
    class: "",
  };

  mostrarTablaRangos = false;
  // Spinners
  spinnerConfirmarCita = false;

  spinnerCargarRangos = false;
  submitted = false;
  lstConsultorios: any;

  public btnAddContact: Array<any> = [
    {
      text: "Nuevo cliente",
      icon: "k-icon k-i-eye",
      click: () => {
        //  this.searchModalForms.showAddNewConctat = true;
      },
    },
  ];

  dias = {
    lunes: "",
    martes: "",
    miercoles: "",
    jueves: "",
    viernes: "",
    sabado: "",
    domingo: "",
  };

  cambioValorCampos = false;

  errores = {
    fecha: false,
    medico: false,
    paciente: false,
    tipoCita: false,
    consultorio: false,
    nota: false,
  };

  get cboMedicos() {
    return this.formData.get("cboMedicos");
  }
  get txtNota() {
    return this.formData.get("txtNota");
  }
  get txtPaciente() {
    return this.formData.get("txtPaciente");
  }
  get txtFecha() {
    return this.formData.get("txtFecha");
  }
  get cboTipoCita() {
    return this.formData.get("cboTipoCita");
  }
  get cboConsultorios() {
    return this.formData.get("cboConsultorios");
  }
  get cboEspecialidad() {
    return this.formData.get("cboEspecialidad");
  }

  crearFila(): FormGroup {
    return this.formBuilder.group({
      lblFranja: [""],
      // cie10Titulo: [''],
    });
  }

  ngOnInit(): void {
    this.formData = this.formBuilder.group({
      cboMedicos: [
        { value: "", disabled: true },
        [Validators.required, MaxLength(false, 145)],
      ],
      txtPaciente: ["", [Validators.required, MaxLength(false, 145)]],
      txtFecha: ["", [Validators.required]],
      cboTipoCita: [
        "",
        [Validators.required, MaxLength(false, 18), OnlyIntegerNumbers()],
      ],
      cboEspecialidad: [
        "",
        [Validators.required, MaxLength(false, 18), OnlyIntegerNumbers()],
      ],
      cboConsultorios: [
        "",
        [Validators.required, MaxLength(false, 18), OnlyIntegerNumbers()],
      ],
      txtNota: ["", [MaxLength(true, 1000)]],
      filasFranjaHrs: this.formBuilder.array([this.crearFila()]),

      // ngModelVerMedico: [''],
      // ngModelVerMedicoIde: [''],
      // ngModelVerPaciente: [''],
      // ngModelVerPacienteIde: [''],
      // ngModelVerFecha: [''],
      // ngModelVerHora: [''],
    });

    // this.formData.valueChanges.subscribe(item => {
    //  console.log('Esta entrando')
    //   if(this.cambioValorCampos === true) {
    //     this.cargarCitas();
    //   }

    // });

    // this.formData.get('txtFecha').valueChanges.subscribe(value => {

    //   // this.dias.lunes = 'Nuevo';
    //   //  window.document.querySelector('[value="rango12-dia3"]').className = '';

    //   // this.spinnerCargarRangos = true;

    // });
  }

  ngAfterContentInit() {
    this.generarTabla();
  }
  cboEspecialidad_change(espId) {
    if (espId === undefined || espId === null || espId === "") {
      this.cboMedicos.setValue("");
      this.cboMedicos.disable();
      this.cboMedicos.reset();
      this.mostrarTablaRangos = false;
      this.medicoId = 0;
      return false;
    }

    // if (this.cboTipoCita.errors === null && this.txtFecha.errors === null
    //   && this.txtPaciente.errors === null
    //   && this.cboEspecialidad.errors === null
    //   && this.cboConsultorios.errors === null) {

    //   console.log('Esta llegando aquí');
    //  this.cargarCitas();

    if (espId > 0) {
      this.apiServices
        .getData("medicos" + "?espid=" + espId + "&limit=500", "Medicos", false)
        .subscribe(
          (response) => {
            if (
              response !== null &&
              response.success !== undefined &&
              response.success === true
            ) {
              this.especidadliadId = espId;
              const medicos = response["Medicos"];
              this.cboMedicos.enable();
              this.cboMedicos.reset();
              this.cboMedicos.setValue("");
              this.lstMedicos = medicos;
              console.log({ lstMedicos: this.lstMedicos });
              if (this.lstMedicos.length === 0) {
                this.cboMedicos.disable();
              }
            } else {
              this.cboMedicos.disable();
              this.alertMsg1.showAlert = true;
              this.alertMsg1.class = "alert alert-danger";
              this.alertMsg1.msg =
                "Error al intentar cargar los médicos. Intente de nuevo";
            }

            this.validarCargarCitas();
          },
          (error) => {
            console.log(error);
            this.cboMedicos.disable();
            this.alertMsg1.showAlert = true;
            this.alertMsg1.class = "alert alert-danger";
            this.alertMsg1.msg =
              "Error al intentar cargar los médicos. Intente de nuevo";
          },
          () => {
            //console.log('Finally cboEspecialidad_click');
          }
        );
    }

    // }
  }

  cargarCitas() {
    console.log("Cargando citas....");
    // if (this.cboTipoCita.errors !== null
    //   && this.cboMedicos.errors !== null && this.txtPaciente.errors !== null
    //   && this.cboConsultorios.errors !== null) {
    //   return false;
    // }
    this.alertMsg1.showAlert = false;
    this.resetearCeldas();

    console.log(this.txtFecha.value);
    console.log({ idPaciente: this.pacienteId });
    let queryStrig = "";
    queryStrig += "citas?";
    queryStrig += "fecha=" + this.txtFecha.value.toISOString();
    queryStrig += "&consultorio=" + this.cboConsultorios.value;
    queryStrig += "&medico=" + this.formData.get("cboMedicos").value;
    queryStrig += "&paciente=" + this.pacienteId;

    this.apiServices.getData(queryStrig, "citas", false).subscribe(
      (response) => {
        console.log(response);
        if (response.getdays !== undefined) {
          this.dias.lunes = response.getdays.Monday;
          this.dias.martes = response.getdays.Tuesday;
          this.dias.miercoles = response.getdays.Wednesday;
          this.dias.jueves = response.getdays.Thursday;
          this.dias.viernes = response.getdays.Friday;
          this.dias.sabado = response.getdays.Saturday;
          this.dias.domingo = response.getdays.Sunday;
          // this.mostrarTablaRangos = true;
          const citas = response.Citas;

          console.log(citas);
          // this.generarTabla();

          if (citas.length > 0) {
            // console.log('por aqui');
            Object.keys(citas).forEach((index) => {
              console.log(citas[index].dia);
              console.log(citas[index].rango);
              console.log(citas[index].rango + "-" + citas[index].dia);
              const celda = window.document.querySelector(
                '[value="' + citas[index].rango + "-" + citas[index].dia + '"]'
              );
              console.log(celda.className);
              //  if(celda.className!== 'btn-default btn1 btn-xs cursorDefault' ) {
              celda.className = "btn-default btn1 btn-xs cursorDefault";
              celda.textContent = "No disponible";
              //  }
              console.log(citas[index]);
              let msg = "";

              console.log(this.itemMedico);
              if (this.itemMedico.id === citas[index].medico_id) {
                msg += "Médico: NO disponible en el horario seleccionado.";
                msg += "\n";
              } else {
                msg += "Médico: disponible en el horario seleccionado.";
                msg += "\n";
              }

              if (this.itemPaciente.id === citas[index].paciente_id) {
                msg += "Paciente: NO disponible en el horario seleccionado.";
                msg += "\n";
              } else {
                msg += "Paciente: disponible en el horario seleccionado.";
                msg += "\n";
              }

              if (this.cboConsultorios.value == citas[index].consultorio_id) {
                msg += "Consultorio: NO disponible en el horario seleccionado.";
                msg += "\n";
              } else {
                msg += "Consultorio: disponible en el horario seleccionado.";
                msg += "\n";
              }

              celda.setAttribute("data-toggle", "tooltip");
              celda.setAttribute("title", msg);
              this.arrayNoDisponibles.push(
                citas[index].rango + "-" + citas[index].dia
              );
              this.arrayIdNoDisponibles.push({
                codigo: citas[index].codigo,
                rango: citas[index].rango + "-" + citas[index].dia,
              });
              console.log(this.arrayNoDisponibles);
            });
          }
          // } else {
          //   this.resetearCeldas();
          // }

          console.log(this.arrayNoDisponibles);
        }

        // else {
        //   console.log('Error al intentar obtener las fechas desde el servidor');
        //   this.mostrarMsgError('Error al intentar obtener la agenda de citas, debe intentar nuevamente' );
        //   this.mostrarTablaRangos = false;
        // }
      },
      (error) => {
        console.log(error);
        if (error.error.response !== undefined) {
          this.mostrarMsgError(error.error.response);
        } else {
          this.mostrarMsgError(
            "Error al intentar obtener la agenda de citas, debe intentar nuevamente"
          );
        }

        //  this.mostrarTablaRangos = false;
      },
      () => {
        console.log("Finally");
        this.spinnerCargarRangos = false;

        if (this.validacionDeDatosCita() === true) {
          console.log("Errores de validación");
          return false;
        }

        console.log(this.formData.get("cboMedicos").value);
        console.log(this.formData.get("txtPaciente").value);
        console.log(this.formData.get("txtFecha").value);
        console.log(this.formData.get("cboTipoCita").value);
        console.log(this.formData.get("cboConsultorios").value);

        // if (value === null) {
        //   this.errores.fecha = true;
        //   return false;
        //   // this.alertMsg2.showAlert = true;
        //   // this.alertMsg2.msg = ''
        // }
      }
    );

    this.cdr.detectChanges();
  }

  valueChangedTipoCita($event) {
    if ($event === undefined || $event === null || $event === "") {
      this.mostrarTablaRangos = false;
      return false;
    }

    this.tipoCitaId = $event;
    this.validarCargarCitas();
  }

  valueChangedConsultorio($event) {
    if ($event === undefined || $event === null || $event === "") {
      this.mostrarTablaRangos = false;
      return false;
    }

    this.consultorioId = $event;
    this.validarCargarCitas();
  }

  resetearCeldas() {
    const lengthDisponibles = this.arrayNoDisponibles.length;
    let i = 0;
    for (i; i < lengthDisponibles; i++) {
      const celda = window.document.querySelector(
        '[value="' + this.arrayNoDisponibles[i] + '"]'
      );
      celda.removeAttribute("data-toggle");
      celda.removeAttribute("title");
      celda.className = "btn-primary btn1 btn-xs cursorDefault";
      celda.textContent = "Disponible";
    }

    this.arrayNoDisponibles = [];
    this.arrayIdNoDisponibles = [];
  }

  valueChangedCita($event) {
    console.log($event);
    if ($event === undefined || $event === null || $event === "") {
      this.mostrarTablaRangos = false;
      return false;
    }

    this.validarCargarCitas();
    // } else {

    //   alert('Error al intentar cargar la agenda, debe verificar que no contenga errores');
    // }
  }

  onBlurConsultorio($event) {
    if (this.cboConsultorios.errors !== null) {
      console.log("Entro aquí");
      this.errores.consultorio = true;
    }
  }

  onBlurTipoCita($event) {
    if (this.cboTipoCita.errors !== null) {
      console.log("Entro aquí");
      this.errores.tipoCita = true;
    }
  }

  onBlurFecha($event) {
    if (this.txtFecha.errors !== null) {
      console.log("Entro aquí");
      this.errores.fecha = true;
    }
  }

  validacionDeDatosCita(): boolean {
    const valcboMedicos = this.formData.get("cboMedicos").value;
    const valTxtPaciente = this.formData.get("txtPaciente").value;
    const valTxtFecha = this.formData.get("txtFecha").value;
    const valCboTipoCita = this.formData.get("cboTipoCita").value;
    const valCboConsultorios = this.formData.get("cboConsultorios").value;
    let cualquierError = false;

    if (valcboMedicos === "") {
      cualquierError = true;
      this.errores.medico = true;
    }

    if (valTxtPaciente === "") {
      cualquierError = true;
      this.errores.paciente = true;
    }

    if (valTxtFecha === "") {
      cualquierError = true;
      this.errores.fecha = true;
    }

    if (valCboTipoCita === "") {
      cualquierError = true;
      this.errores.tipoCita = true;
    }

    if (valCboConsultorios === "") {
      cualquierError = true;
      this.errores.consultorio = true;
    }

    return cualquierError;
  }

  eventoClicTxtFecha($event) {
    console.log(this.formData);

    if (
      this.cboTipoCita.errors === null &&
      this.cboMedicos.errors === null &&
      this.txtPaciente.errors === null &&
      this.cboConsultorios.errors === null &&
      this.formData.get("cboMedicos").enabled
    ) {
      // this.txtFecha.errors = null;
      // console.log('Esta generando la tabla rangos');
      //   this.mostrarTablaRangos = true;
      this.formData.get("txtFecha").enable();
      this.formData.get("txtFecha").reset();
      //this.formData.get('txtFecha').updateValueAndValidity();
    } else {
      alert("Primero debe completar todos los datos");
    }
    // } else {
    //  // this.txtFecha.errors = null;
    //   console.log('Esta generando la tabla rangos');
    //   this.mostrarTablaRangos = false;
    //   this.formData.get('txtFecha').reset();
    //   //this.formData.get('txtFecha').updateValueAndValidity();
    //   alert('Primero debe completar todos los datos');
    // }
    // console.log($event.target.element);
  }
  btnMostrarVentanaBuscarMedico() {
    this.verVentanaBuscarMedico = true;
  }

  btnMostrarVentanaBuscarPaciente() {
    this.verVentanaBuscarPaciente = true;
  }

  itemVentanaBuscarPaciente($event) {
    console.log($event);
    if ($event === undefined || $event === null || $event === "") {
      alert("Error al intentar obtener la información del paciente");
      this.mostrarTablaRangos = false;
      return false;
    }
    this.formData
      .get("txtPaciente")
      .setValue($event.nombres + " " + $event.apellidos);
    this.ngModelVerPaciente = $event.nombres + " " + $event.apellidos;
    this.ngModelVerPacienteIde = $event.identificacion;
    this.ngModelVerPacienteCelular = $event.celular;
    this.itemPaciente = $event;
    this.pacienteId = $event.id;
    console.log(this.pacienteId);
    this.verVentanaBuscarPaciente = false;
    // this.cargarCitas();
    this.validarCargarCitas();
  }

  public closeModals(): void {
    this.verVentanaBuscarMedico = false;
    this.verVentanaBuscarPaciente = false;
    this.verVentanaConfirmacionCita = false;
    this.alertMsg2.msg = "";
    this.alertMsg2.showAlert = false;
    // this.alertMsg1.msg = '';
    // this.alertMsg1.showAlert = false;
  }

  cboMedicos_change($event) {
    console.log($event);

    if ($event === undefined || $event === null || $event === "") {
      this.medicoId = 0;
      this.mostrarTablaRangos = false;
      return false;
    }

    this.medicoId = $event;
    this.validarCargarCitas();

    // if(Object.keys(this.lstMedicos).length>0) {
    //   Object.keys(this.lstMedicos).keys( index => {
    //     console.log(index);
    //   });

    // }

    if (this.lstMedicos.length > 0) {
      this.lstMedicos.forEach((element, index, array) => {
        if (Number($event) === Number(element.id)) {
          this.itemMedico = element;

          this.ngModelVerMedico = element.nombres + " " + element.apellidos;
          this.ngModelVerMedicoIde = element.identificacion;
          this.ngModelVerMedicoCelular = element.celular;
        }
        //  console.log(element); // 100, 200, 300
        //   console.log(index); // 0, 1, 2
        //   console.log(array); // same myArray object 3 times
      });
    }
  }

  validarCargarCitas() {
    console.log({ ErrorcboMedicos: this.cboMedicos.errors });

    if (
      this.cboTipoCita.errors === null &&
      this.txtFecha.errors === null &&
      this.cboMedicos.errors === null &&
      this.cboMedicos.enabled &&
      this.cboEspecialidad.errors === null &&
      this.formData.get("cboMedicos").enabled &&
      this.cboConsultorios.errors === null
    ) {
      this.mostrarTablaRangos = true;
      this.spinnerCargarRangos = true;
      this.mostrarTablaRangos = true;
      this.submitted = false;
      //  this.arrayNoDisponibles = [];
      //  this.arrayIdNoDisponibles = [];
      this.cargarCitas();
    } else {
      this.mostrarTablaRangos = false;
    }
  }

  generarTabla() {
    const maxCol = 8;
    const maxFil = 48;
    // let rango = 0;
    let hora = 6; //Hora de inicio
    let horaAux = 0; //Hora de inicio
    let minutos = 0;
    let minutosAux = 0;
    let table = "";

    const rangos = [];
    const obJrangos = [];

    for (let fila = 0; fila < maxFil; fila++) {
      table += "<tr>";
      //   jsonTable += '{';
      for (let col = 0; col < maxCol; col++) {
        if (col === 0) {
          //Columna 0
          if (minutos === 30) {
            // Para cambiar los rangos es aquí 30 = 30 minutos, 45 = 15 minus
            minutosAux = minutos;
            horaAux = hora + 1;

            let newMinutos2 = minutosAux.toString() + "0";
            if (newMinutos2.length > 2) {
              newMinutos2 = newMinutos2.substring(0, newMinutos2.length - 1);
            }

            let newHora = "0" + hora.toString();
            if (newHora.length > 2) {
              newHora = newHora.substring(1);
            }

            let newHora2 = "0" + horaAux.toString();
            if (newHora2.length > 2) {
              newHora2 = newHora2.substring(1);
            }

            table +=
              "<td>" +
              newHora +
              ":" +
              newMinutos2 +
              " - " +
              newHora2 +
              ":00" +
              "</td>";

            //    filasx.push({ td: newHora + ':' + newMinutos2 + ' - ' + newHora2 + ':00' });

            // if (fila >= 23) { //A  partir de que fila se pone PM
            //   table += '<td>' + newHora + ':' + newMinutos2 + ' PM - ' + horaAux + ':00 PM' + '</td>';
            // } else {
            //   table += '<td>' + newHora + ':' + newMinutos2 + ' AM - ' + horaAux + ':00 AM' + '</td>';
            // }
            rangos["rango" + fila] =
              newHora + ":" + newMinutos2 + " - " + newHora2 + ":00";
            //  const key = 'rango' + fila;
            const value =
              newHora + ":" + newMinutos2 + " - " + newHora2 + ":00";

            // obJrangos.push({rango: fila, value: value});
            obJrangos.push({ fila: fila, col: col, rango: value });
            //    rango1.push(hora + ':' + minutosAux + ' AM');
            //    rango2.push(horaAux + ':00 AM');
            hora++;
            minutos = 0;
            minutosAux = -30;
          } else {
            minutos = minutos + 30;
            //   console.log(hora + ':' + minutosAux + ' AM - ' + hora + ':' + minutos + ' AM');
            // jsonTable += 'td: ' + hora + ':' + minutosAux + ' AM - ' + hora + ':' + minutos + ' AM,';
            //  rangos.push(hora + ':' + minutosAux + ' AM - ' + hora + ':' + minutos + ' AM');
            //  rango1.push(hora + ':' + minutosAux + ' AM');
            //  rango2.push(hora + ':' + minutos + ' AM');

            let newMinutos = minutos.toString() + "0";
            if (newMinutos.length > 2) {
              newMinutos = newMinutos.substring(0, newMinutos.length - 1);
            }

            let newMinutos2 = minutosAux.toString() + "0";
            if (newMinutos2.length > 2) {
              newMinutos2 = newMinutos2.substring(0, newMinutos2.length - 1);
            }

            let newHora = "0" + hora.toString();
            if (newHora.length > 2) {
              newHora = newHora.substring(1);
            }

            let newHora2 = "0" + hora.toString();
            if (newHora2.length > 2) {
              newHora2 = newHora2.substring(1);
            }

            table +=
              "<td>" +
              newHora +
              ":" +
              newMinutos2 +
              " - " +
              newHora2 +
              ":" +
              newMinutos +
              "</td>";

            rangos["rango" + fila] =
              newHora + ":" + newMinutos2 + " - " + newHora2 + ":" + newMinutos;

            const value =
              newHora + ":" + newMinutos2 + " - " + newHora2 + ":" + newMinutos;

            obJrangos.push({ fila: fila, col: col, rango: value });
            //  filasx.push({ td: newHora + ':' + newMinutos2 + ' - ' + newHora2 + ':' + newMinutos });
            // if (fila >= 23) { //A  partir de que fila se pone PM
            //   table += '<td>' + newHora + ':' + newMinutos2 + ' PM - ' + hora + ':' + newMinutos + ' PM' + '</td>';
            // } else {
            //   table += '<td>' + newHora + ':' + newMinutos2 + ' AM - ' + hora + ':' + newMinutos + ' AM' + '</td>';
            // }
          }
          minutosAux = minutosAux + 30;
        } else {
          table += `<td><span value='rango` + fila + `-dia`;
          table +=
            col +
            `' class='btn-primary btn1 btn-xs cursorDefault' >Disponible</span></td>`;
        }
      }

      table += "</tr>";
      this.rangosPermitidos.push("rango" + fila);
    }
    //  filasx2.push(filasx);
    //  console.log(table);
    ///   table += '</table>';

    this.tablaRangos = table;
    this.rangosHoras = obJrangos;
    this.rangosHoras.length = 36;
    // console.log(this.rangosHoras);
    // console.log(obJrangos);
    //  console.log(rango1);
    //  console.log(rango2);
  }

  programarEvento($event) {
    // const value = $event;

    //var target = $event.target || $event.srcElement || $event.currentTarget;
    //var idAttr = target.attributes.id;
    //var value1 = idAttr.nodeValue; //Funciona
    //console.log(idAttr); //id="rango1-dia5"
    //  console.log(value1); //Funciona

    //console.log(element) //Funciona

    // console.log($event.getAttribute('id').value);
    const element = $event.target as HTMLElement;
    const value = element.getAttribute("value");
    const rangoCitaVisualizada = element.getAttribute("id");

    console.log(value);

    if (value == null) {
      return false;
    }

    const arrayRangoDia = value.split("-");
    if (arrayRangoDia.length !== 2) {
      console.log("Error al intentar asignar la cita");
      alert("Error al intentar asignar la cita ");
      return false;
    }

    const [rango, dia] = arrayRangoDia;
    const dias = ["dia1", "dia2", "dia3", "dia4", "dia5", "dia6", "dia7"];
    // const dias = ['Dia', 'Martes','Miercoles', 'Jueves','Viernes','Sabado', 'Domingo'];

    if (
      dias.includes(dia) === false ||
      this.rangosPermitidos.includes(rango) === false
    ) {
      console.log("Error al intentar asignar la cita");
      alert("Error al intentar asignar la cita");
      return false;
    }

    this.dia = dia;
    this.rango = rango;

    // this.ngModelVerHora = this.hora = this.rangosHoras[rango];

    const result = this.rangosHoras.filter((obj) => {
      //this.rangosHoras.find(obj => {
      //  console.log(obj);
      // console.log(rango);
      const tempRango = "rango" + obj.fila;
      //  console.log(tempRango);
      if (tempRango === rango) {
        return obj.rango;
      }

      return null;
    });

    if (result.length > 0) {
      this.ngModelVerHora = this.hora = result[0].rango;
    } else {
      alert(
        "Ocurrió un error al intentar obtener la hora, debe intentar de nuevo"
      );
      return false;
    }
    console.log(result);

    this.fecha = this.ngModelVerFecha = this.obtenerFecha(dia, this.dias);
    this.verVentanaConfirmacionCita = true;
    this.formData.get("txtNota").setValue("");
    this.desBtnGuardar = false;
    this.cargarDatosConsultorio();
    // console.log(index);
    // console.log(this.lstConsultorios[index]);

    console.log({ rangoHoras: this.rangosHoras });
    console.log(this.dias);

    console.log(rango);
    console.log(dia);

    //Para saber si se puede visualizar guardar o imprimir
    // if (this.arrayNoDisponibles.includes(rangoCitaVisualizada)) {
    //   this.verBotonesDetalleCita.guardar = false;
    //   this.verBotonesDetalleCita.imprimir = true;
    // } else {
    //   this.verBotonesDetalleCita.guardar = true;
    //   this.verBotonesDetalleCita.imprimir = false;
    // }
    this.rangoCitaVisualizada = rangoCitaVisualizada;
    this.habDesGuardarImprimir(rangoCitaVisualizada);
  }

  obtenerFecha(dia: string, dias: any) {
    let fecha = "";
    switch (dia) {
      case "dia1":
        fecha = dias.lunes;
        break;
      case "dia2":
        fecha = dias.martes;
        break;
      case "dia3":
        fecha = dias.miercoles;
        break;
      case "dia4":
        fecha = dias.jueves;
        break;
      case "dia5":
        fecha = dias.viernes;
        break;

      case "dia6":
        fecha = dias.sabado;
        break;

      case "dia7":
        fecha = dias.domingo;
        break;
    }

    return fecha;
  }

  cargarDatosConsultorio() {
    const idConsultorio = this.formData.get("cboConsultorios").value;
    if (idConsultorio !== undefined) {
      Object.keys(this.lstConsultorios).forEach((index) => {
        const item = this.lstConsultorios[index];
        if (idConsultorio === item.id) {
          this.ngModelCosultorioNombre = item.nombre;
          this.ngModelCosultorioDireccion = item.direccion;
          this.ngModelCosultorioTelefono = item.telefono;
          this.ngModelCosultorioCelular = item.celular;
        }
      });
    }
  }

  dataConsultorios($event) {
    console.log($event);
    this.lstConsultorios = $event;
    // this.ngModelCosultorioNombre = $event.nombre;
    // this.ngModelCosultorioDireccion = $event.direccion;
    // this.ngModelCosultorioTelefono = $event.telefono;
    // this.ngModelCosultorioCelular = $event.celular;
  }

  getDays($value) {
    console.log($value);
    this.dias.lunes = "Nuevo";
  }

  generarPdf() {
    console.log(this.arrayIdNoDisponibles);
    console.log(this.rangoCitaVisualizada);

    const result = this.arrayIdNoDisponibles.filter((obj) => {
      //this.rangosHoras.find(obj => {
      console.log(obj);
      console.log(this.rangoCitaVisualizada);

      if (obj.rango === this.rangoCitaVisualizada) {
        return obj.codigo;
      }

      return null;
    });
    console.log(result);

    if (result.length > 0) {
      console.log(result);
      const codigo = result[0].codigo;
      this.generandoReporte = true;
      let url = "reportes/generarpdf?origen=citas";
      url += "&codigo=" + codigo;
      this.apiServices.getData(url, "Reporte", false).subscribe(
        (data) => {
          console.log(data);

          if (
            data !== null &&
            data.success !== undefined &&
            data.success === true
          ) {
            // this.tienePdf = true;
            // this.urlPdf = this.baseUrl2 + data.path;
            this.generandoReporte = false;
            window.open(this.baseUrl2 + data.path);
          } else {
            this.mostrarMsgError(
              "Error al intentar generar el PDF, Debe intentar de nuevo"
            );
          }

          this.generandoReporte = false;
        },
        (error) => {
          console.log(error);
          //  this.codigoFormula = 0;
          //  this.cerrarVentana();
          this.generandoReporte = false;
          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError(
              "Error al intentar generar el PDF, Debe intentar de nuevo"
            );
          }
        },
        () => {}
      );
    } else {
      alert("Error al intentar generar la cita médica, debe intentar de nuevo");
    }
  }

  mostrarMsgError(msg: string) {
    this.alertMsg1.class = "alert alert-danger";
    this.alertMsg1.showAlert = true;
    this.alertMsg1.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg1.class = "alert alert-success";
    this.alertMsg1.showAlert = true;
    this.alertMsg1.msg = msg;
  }

  agendarCita() {
    this.submitted = true;
    if (this.formData.invalid) {
      this.closeModals();
      this.alertMsg1.class = "alert alert-success";
      this.alertMsg1.showAlert = true;
      this.alertMsg1.msg =
        "El formulario que intenta enviar contiene errores. Debe verificar e intentar de nuevo";
      return false;
    }
    console.log(this.dia);
    console.log(this.rango);
    console.log(this.ngModelVerMedico);
    console.log(this.ngModelVerPaciente);
    console.log(this.ngModelVerFecha);
    console.log(this.ngModelVerHora);

    const formData = new FormData();
    formData.set("paciente_id", "" + this.pacienteId);
    formData.set("medico_id", "" + this.medicoId);
    formData.set("especialidad_id", "" + this.especidadliadId);
    formData.set("rango", this.rango);
    formData.set("fecha", this.fecha);
    formData.set("dia", this.dia);
    formData.set("hora", this.hora);
    formData.set("consultorio_id", "" + this.consultorioId);
    formData.set("tipocita_id", "" + this.tipoCitaId);
    formData.set("nota", this.txtNota.value);

    this.apiServices.postFormData(formData, "citas").subscribe(
      (response) => {
        console.log(response);
        if (
          response !== null &&
          response.success !== undefined &&
          response.success === true
        ) {
          this.alertMsg2.class = "alert alert-success";
          const citaAsignada = response.item;
          const celda = window.document.querySelector(
            '[value="' + citaAsignada.rango + "-" + citaAsignada.dia + '"]'
          );
          celda.className = "btn-default btn1 btn-xs cursorDefault";
          celda.textContent = "No disponible";
          //this.desBtnGuardar = true;

          this.arrayNoDisponibles.push(
            citaAsignada.rango + "-" + citaAsignada.dia
          );
          this.arrayIdNoDisponibles.push({
            codigo: citaAsignada.codigo,
            rango: citaAsignada.rango + "-" + citaAsignada.dia,
          });
          this.habDesGuardarImprimir(
            citaAsignada.rango + "-" + citaAsignada.dia
          );
          this.alertMsg2.showAlert = true;
          this.alertMsg2.msg =
            response.response + " Código: " + citaAsignada.codigo;
        } else {
          let msg = "Error al intentar procesar los datos enviados, ";
          msg +=
            "debe verificar que los datos ingresados en el formulario no tengan inconsistencias";
          this.mostrarMsgError(msg);
          this.closeModals();
        }
      },
      (dataError) => {
        console.log(dataError);

        if (dataError.error.response !== undefined) {
          this.mostrarMsgError(dataError.error.response);
        } else {
          let msg = "Error al intentar procesar los datos enviados, ";
          msg +=
            "debe verificar que los datos ingresados en el formulario no tengan inconsistencias";
          this.mostrarMsgError(msg);
        }

        const errors = GetMsgFromError(dataError);
        console.log(errors);

        this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
          errors.errors
        );
        this.closeModals();
        console.log(this.serverErrorsInfo);
        //  this.accionEjecutandose = false;
        window.scroll(0, 0);
      },
      () => {
        console.log("finally");
      }
    );
  }

  habDesGuardarImprimir(rango) {
    //this.rangoCitaVisualizada = 0;
    //Para saber si se puede visualizar guardar o imprimir
    if (this.arrayNoDisponibles.includes(rango)) {
      this.verBotonesDetalleCita.guardar = false;
      this.verBotonesDetalleCita.imprimir = true;
    } else {
      this.verBotonesDetalleCita.guardar = true;
      this.verBotonesDetalleCita.imprimir = false;
    }
  }
}
export class ListMed implements OnInit, AfterContentInit {
  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private apiServices: ServiciosService
  ) {
    window.scroll(0, 0);
  }

  tablaRangos = "";
  formData: FormGroup;

  // Ventana
  verVentanaBuscarMedico = false;
  verVentanaBuscarPaciente = false;
  verVentanaConfirmacionCita = false;
  rangosPermitidos = [];
  serverErrorsInfo: any = {};
  rangosHoras = [];
  fechasServidor: any;
  fecha: string;
  dia: string;
  rango: string;
  hora: string;
  itemMedico: any;
  itemPaciente: any;
  arrayNoDisponibles = [];
  arrayIdNoDisponibles = [];
  lstMedicos = [];
  pacienteId = 0;
  rangoCitaVisualizada = "";
  ngModelVerMedico = "";
  ngModelVerMedicoIde = "";
  ngModelVerMedicoCelular = "";
  baseUrl2 = environment.baseUrl2;
  ngModelVerPaciente = "";
  ngModelVerPacienteIde = "";
  ngModelVerPacienteCelular = "";

  ngModelVerFecha = "";
  ngModelVerHora = "";

  ngModelCosultorioNombre = "";
  ngModelCosultorioCelular = "";
  ngModelCosultorioTelefono = "";
  ngModelCosultorioDireccion = "";

  ngModelVerEspecialidad = "";

  especidadliadId = 0;
  medicoId = 0;
  consultorioId = 0;
  tipoCitaId = 0;
  //tieneErroresCampos = false;
  desBtnGuardar = false;
  generandoReporte = false;

  verBotonesDetalleCita = {
    guardar: false,
    imprimir: false,
  };

  alertMsg2 = {
    showAlert: false,
    msg: "",
    class: "",
  };

  alertMsg1 = {
    showAlert: false,
    msg: "",
    class: "",
  };

  mostrarTablaRangos = false;
  // Spinners
  spinnerConfirmarCita = false;

  spinnerCargarRangos = false;
  submitted = false;
  lstConsultorios: any;

  public btnAddContact: Array<any> = [
    {
      text: "Nuevo cliente",
      icon: "k-icon k-i-eye",
      click: () => {
        //  this.searchModalForms.showAddNewConctat = true;
      },
    },
  ];

  dias = {
    lunes: "",
    martes: "",
    miercoles: "",
    jueves: "",
    viernes: "",
    sabado: "",
    domingo: "",
  };

  cambioValorCampos = false;

  errores = {
    fecha: false,
    medico: false,
    paciente: false,
    tipoCita: false,
    consultorio: false,
    nota: false,
  };

  get cboMedicos() {
    return this.formData.get("cboMedicos");
  }
  get txtNota() {
    return this.formData.get("txtNota");
  }
  get txtPaciente() {
    return this.formData.get("txtPaciente");
  }
  get txtFecha() {
    return this.formData.get("txtFecha");
  }
  get cboTipoCita() {
    return this.formData.get("cboTipoCita");
  }
  get cboConsultorios() {
    return this.formData.get("cboConsultorios");
  }
  get cboEspecialidad() {
    return this.formData.get("cboEspecialidad");
  }

  crearFila(): FormGroup {
    return this.formBuilder.group({
      lblFranja: [""],
      // cie10Titulo: [''],
    });
  }

  ngOnInit(): void {
    this.formData = this.formBuilder.group({
      cboMedicos: [
        { value: "", disabled: true },
        [Validators.required, MaxLength(false, 145)],
      ],
      txtPaciente: ["", [Validators.required, MaxLength(false, 145)]],
      txtFecha: ["", [Validators.required]],
      cboTipoCita: [
        "",
        [Validators.required, MaxLength(false, 18), OnlyIntegerNumbers()],
      ],
      cboEspecialidad: [
        "",
        [Validators.required, MaxLength(false, 18), OnlyIntegerNumbers()],
      ],
      cboConsultorios: [
        "",
        [Validators.required, MaxLength(false, 18), OnlyIntegerNumbers()],
      ],
      txtNota: ["", [MaxLength(true, 1000)]],
      filasFranjaHrs: this.formBuilder.array([this.crearFila()]),

      // ngModelVerMedico: [''],
      // ngModelVerMedicoIde: [''],
      // ngModelVerPaciente: [''],
      // ngModelVerPacienteIde: [''],
      // ngModelVerFecha: [''],
      // ngModelVerHora: [''],
    });

    // this.formData.valueChanges.subscribe(item => {
    //  console.log('Esta entrando')
    //   if(this.cambioValorCampos === true) {
    //     this.cargarCitas();
    //   }

    // });

    // this.formData.get('txtFecha').valueChanges.subscribe(value => {

    //   // this.dias.lunes = 'Nuevo';
    //   //  window.document.querySelector('[value="rango12-dia3"]').className = '';

    //   // this.spinnerCargarRangos = true;

    // });
  }

  ngAfterContentInit() {
    this.generarTabla();
  }
  cboEspecialidad_change(espId) {
    if (espId === undefined || espId === null || espId === "") {
      this.cboMedicos.setValue("");
      this.cboMedicos.disable();
      this.cboMedicos.reset();
      this.mostrarTablaRangos = false;
      this.medicoId = 0;
      return false;
    }

    // if (this.cboTipoCita.errors === null && this.txtFecha.errors === null
    //   && this.txtPaciente.errors === null
    //   && this.cboEspecialidad.errors === null
    //   && this.cboConsultorios.errors === null) {

    //   console.log('Esta llegando aquí');
    //  this.cargarCitas();

    if (espId > 0) {
      this.apiServices
        .getData("medicos" + "?espid=" + espId + "&limit=500", "Medicos", false)
        .subscribe(
          (response) => {
            if (
              response !== null &&
              response.success !== undefined &&
              response.success === true
            ) {
              this.especidadliadId = espId;
              const medicos = response["Medicos"];
              this.cboMedicos.enable();
              this.cboMedicos.reset();
              this.cboMedicos.setValue("");
              this.lstMedicos = medicos;
              console.log({ lstMedicos: this.lstMedicos });
              if (this.lstMedicos.length === 0) {
                this.cboMedicos.disable();
              }
            } else {
              this.cboMedicos.disable();
              this.alertMsg1.showAlert = true;
              this.alertMsg1.class = "alert alert-danger";
              this.alertMsg1.msg =
                "Error al intentar cargar los médicos. Intente de nuevo";
            }

            this.validarCargarCitas();
          },
          (error) => {
            console.log(error);
            this.cboMedicos.disable();
            this.alertMsg1.showAlert = true;
            this.alertMsg1.class = "alert alert-danger";
            this.alertMsg1.msg =
              "Error al intentar cargar los médicos. Intente de nuevo";
          },
          () => {
            //console.log('Finally cboEspecialidad_click');
          }
        );
    }

    // }
  }

  cargarCitas() {
    console.log("Cargando citas....");
    // if (this.cboTipoCita.errors !== null
    //   && this.cboMedicos.errors !== null && this.txtPaciente.errors !== null
    //   && this.cboConsultorios.errors !== null) {
    //   return false;
    // }
    this.alertMsg1.showAlert = false;
    this.resetearCeldas();

    console.log(this.txtFecha.value);
    console.log({ idPaciente: this.pacienteId });
    let queryStrig = "";
    queryStrig += "citas?";
    queryStrig += "fecha=" + this.txtFecha.value.toISOString();
    queryStrig += "&consultorio=" + this.cboConsultorios.value;
    queryStrig += "&medico=" + this.formData.get("cboMedicos").value;
    queryStrig += "&paciente=" + this.pacienteId;

    this.apiServices.getData(queryStrig, "citas", false).subscribe(
      (response) => {
        console.log(response);
        if (response.getdays !== undefined) {
          this.dias.lunes = response.getdays.Monday;
          this.dias.martes = response.getdays.Tuesday;
          this.dias.miercoles = response.getdays.Wednesday;
          this.dias.jueves = response.getdays.Thursday;
          this.dias.viernes = response.getdays.Friday;
          this.dias.sabado = response.getdays.Saturday;
          this.dias.domingo = response.getdays.Sunday;
          // this.mostrarTablaRangos = true;
          const citas = response.Citas;

          console.log(citas);
          // this.generarTabla();

          if (citas.length > 0) {
            // console.log('por aqui');
            Object.keys(citas).forEach((index) => {
              console.log(citas[index].dia);
              console.log(citas[index].rango);
              console.log(citas[index].rango + "-" + citas[index].dia);
              const celda = window.document.querySelector(
                '[value="' + citas[index].rango + "-" + citas[index].dia + '"]'
              );
              console.log(celda.className);
              //  if(celda.className!== 'btn-default btn1 btn-xs cursorDefault' ) {
              celda.className = "btn-default btn1 btn-xs cursorDefault";
              celda.textContent = "No disponible";
              //  }
              console.log(citas[index]);
              let msg = "";

              console.log(this.itemMedico);
              if (this.itemMedico.id === citas[index].medico_id) {
                msg += "Médico: NO disponible en el horario seleccionado.";
                msg += "\n";
              } else {
                msg += "Médico: disponible en el horario seleccionado.";
                msg += "\n";
              }

              if (this.itemPaciente.id === citas[index].paciente_id) {
                msg += "Paciente: NO disponible en el horario seleccionado.";
                msg += "\n";
              } else {
                msg += "Paciente: disponible en el horario seleccionado.";
                msg += "\n";
              }

              if (this.cboConsultorios.value == citas[index].consultorio_id) {
                msg += "Consultorio: NO disponible en el horario seleccionado.";
                msg += "\n";
              } else {
                msg += "Consultorio: disponible en el horario seleccionado.";
                msg += "\n";
              }

              celda.setAttribute("data-toggle", "tooltip");
              celda.setAttribute("title", msg);
              this.arrayNoDisponibles.push(
                citas[index].rango + "-" + citas[index].dia
              );
              this.arrayIdNoDisponibles.push({
                codigo: citas[index].codigo,
                rango: citas[index].rango + "-" + citas[index].dia,
              });
              console.log(this.arrayNoDisponibles);
            });
          }
          // } else {
          //   this.resetearCeldas();
          // }

          console.log(this.arrayNoDisponibles);
        }

        // else {
        //   console.log('Error al intentar obtener las fechas desde el servidor');
        //   this.mostrarMsgError('Error al intentar obtener la agenda de citas, debe intentar nuevamente' );
        //   this.mostrarTablaRangos = false;
        // }
      },
      (error) => {
        console.log(error);
        if (error.error.response !== undefined) {
          this.mostrarMsgError(error.error.response);
        } else {
          this.mostrarMsgError(
            "Error al intentar obtener la agenda de citas, debe intentar nuevamente"
          );
        }

        //  this.mostrarTablaRangos = false;
      },
      () => {
        console.log("Finally");
        this.spinnerCargarRangos = false;

        if (this.validacionDeDatosCita() === true) {
          console.log("Errores de validación");
          return false;
        }

        console.log(this.formData.get("cboMedicos").value);
        console.log(this.formData.get("txtPaciente").value);
        console.log(this.formData.get("txtFecha").value);
        console.log(this.formData.get("cboTipoCita").value);
        console.log(this.formData.get("cboConsultorios").value);

        // if (value === null) {
        //   this.errores.fecha = true;
        //   return false;
        //   // this.alertMsg2.showAlert = true;
        //   // this.alertMsg2.msg = ''
        // }
      }
    );

    this.cdr.detectChanges();
  }

  valueChangedTipoCita($event) {
    if ($event === undefined || $event === null || $event === "") {
      this.mostrarTablaRangos = false;
      return false;
    }

    this.tipoCitaId = $event;
    this.validarCargarCitas();
  }

  valueChangedConsultorio($event) {
    if ($event === undefined || $event === null || $event === "") {
      this.mostrarTablaRangos = false;
      return false;
    }

    this.consultorioId = $event;
    this.validarCargarCitas();
  }

  resetearCeldas() {
    const lengthDisponibles = this.arrayNoDisponibles.length;
    let i = 0;
    for (i; i < lengthDisponibles; i++) {
      const celda = window.document.querySelector(
        '[value="' + this.arrayNoDisponibles[i] + '"]'
      );
      celda.removeAttribute("data-toggle");
      celda.removeAttribute("title");
      celda.className = "btn-primary btn1 btn-xs cursorDefault";
      celda.textContent = "Disponible";
    }

    this.arrayNoDisponibles = [];
    this.arrayIdNoDisponibles = [];
  }

  valueChangedCita($event) {
    console.log($event);
    if ($event === undefined || $event === null || $event === "") {
      this.mostrarTablaRangos = false;
      return false;
    }

    this.validarCargarCitas();
    // } else {

    //   alert('Error al intentar cargar la agenda, debe verificar que no contenga errores');
    // }
  }

  onBlurConsultorio($event) {
    if (this.cboConsultorios.errors !== null) {
      console.log("Entro aquí");
      this.errores.consultorio = true;
    }
  }

  onBlurTipoCita($event) {
    if (this.cboTipoCita.errors !== null) {
      console.log("Entro aquí");
      this.errores.tipoCita = true;
    }
  }

  onBlurFecha($event) {
    if (this.txtFecha.errors !== null) {
      console.log("Entro aquí");
      this.errores.fecha = true;
    }
  }

  validacionDeDatosCita(): boolean {
    const valcboMedicos = this.formData.get("cboMedicos").value;
    const valTxtPaciente = this.formData.get("txtPaciente").value;
    const valTxtFecha = this.formData.get("txtFecha").value;
    const valCboTipoCita = this.formData.get("cboTipoCita").value;
    const valCboConsultorios = this.formData.get("cboConsultorios").value;
    let cualquierError = false;

    if (valcboMedicos === "") {
      cualquierError = true;
      this.errores.medico = true;
    }

    if (valTxtPaciente === "") {
      cualquierError = true;
      this.errores.paciente = true;
    }

    if (valTxtFecha === "") {
      cualquierError = true;
      this.errores.fecha = true;
    }

    if (valCboTipoCita === "") {
      cualquierError = true;
      this.errores.tipoCita = true;
    }

    if (valCboConsultorios === "") {
      cualquierError = true;
      this.errores.consultorio = true;
    }

    return cualquierError;
  }

  eventoClicTxtFecha($event) {
    console.log(this.formData);

    if (
      this.cboTipoCita.errors === null &&
      this.cboMedicos.errors === null &&
      this.txtPaciente.errors === null &&
      this.cboConsultorios.errors === null &&
      this.formData.get("cboMedicos").enabled
    ) {
      // this.txtFecha.errors = null;
      // console.log('Esta generando la tabla rangos');
      //   this.mostrarTablaRangos = true;
      this.formData.get("txtFecha").enable();
      this.formData.get("txtFecha").reset();
      //this.formData.get('txtFecha').updateValueAndValidity();
    } else {
      alert("Primero debe completar todos los datos");
    }
    // } else {
    //  // this.txtFecha.errors = null;
    //   console.log('Esta generando la tabla rangos');
    //   this.mostrarTablaRangos = false;
    //   this.formData.get('txtFecha').reset();
    //   //this.formData.get('txtFecha').updateValueAndValidity();
    //   alert('Primero debe completar todos los datos');
    // }
    // console.log($event.target.element);
  }
  btnMostrarVentanaBuscarMedico() {
    this.verVentanaBuscarMedico = true;
  }

  btnMostrarVentanaBuscarPaciente() {
    this.verVentanaBuscarPaciente = true;
  }

  itemVentanaBuscarPaciente($event) {
    console.log($event);
    if ($event === undefined || $event === null || $event === "") {
      alert("Error al intentar obtener la información del paciente");
      this.mostrarTablaRangos = false;
      return false;
    }
    this.formData
      .get("txtPaciente")
      .setValue($event.nombres + " " + $event.apellidos);
    this.ngModelVerPaciente = $event.nombres + " " + $event.apellidos;
    this.ngModelVerPacienteIde = $event.identificacion;
    this.ngModelVerPacienteCelular = $event.celular;
    this.itemPaciente = $event;
    this.pacienteId = $event.id;
    console.log(this.pacienteId);
    this.verVentanaBuscarPaciente = false;
    // this.cargarCitas();
    this.validarCargarCitas();
  }

  public closeModals(): void {
    this.verVentanaBuscarMedico = false;
    this.verVentanaBuscarPaciente = false;
    this.verVentanaConfirmacionCita = false;
    this.alertMsg2.msg = "";
    this.alertMsg2.showAlert = false;
    // this.alertMsg1.msg = '';
    // this.alertMsg1.showAlert = false;
  }

  cboMedicos_change($event) {
    console.log($event);

    if ($event === undefined || $event === null || $event === "") {
      this.medicoId = 0;
      this.mostrarTablaRangos = false;
      return false;
    }

    this.medicoId = $event;
    this.validarCargarCitas();

    // if(Object.keys(this.lstMedicos).length>0) {
    //   Object.keys(this.lstMedicos).keys( index => {
    //     console.log(index);
    //   });

    // }

    if (this.lstMedicos.length > 0) {
      this.lstMedicos.forEach((element, index, array) => {
        if (Number($event) === Number(element.id)) {
          this.itemMedico = element;

          this.ngModelVerMedico = element.nombres + " " + element.apellidos;
          this.ngModelVerMedicoIde = element.identificacion;
          this.ngModelVerMedicoCelular = element.celular;
        }
        //  console.log(element); // 100, 200, 300
        //   console.log(index); // 0, 1, 2
        //   console.log(array); // same myArray object 3 times
      });
    }
  }

  validarCargarCitas() {
    console.log({ ErrorcboMedicos: this.cboMedicos.errors });

    if (
      this.cboTipoCita.errors === null &&
      this.txtFecha.errors === null &&
      this.cboMedicos.errors === null &&
      this.cboMedicos.enabled &&
      this.cboEspecialidad.errors === null &&
      this.formData.get("cboMedicos").enabled &&
      this.cboConsultorios.errors === null
    ) {
      this.mostrarTablaRangos = true;
      this.spinnerCargarRangos = true;
      this.mostrarTablaRangos = true;
      this.submitted = false;
      //  this.arrayNoDisponibles = [];
      //  this.arrayIdNoDisponibles = [];
      this.cargarCitas();
    } else {
      this.mostrarTablaRangos = false;
    }
  }

  generarTabla() {
    const maxCol = 8;
    const maxFil = 48;
    // let rango = 0;
    let hora = 0; //Hora de inicio
    let horaAux = 0; //Hora de inicio
    let minutos = 0;
    let minutosAux = 0;
    let table = "";

    const rangos = [];
    const obJrangos = [];

    for (let fila = 0; fila < maxFil; fila++) {
      table += "<tr>";
      //   jsonTable += '{';
      for (let col = 0; col < maxCol; col++) {
        if (col === 0) {
          //Columna 0
          if (minutos === 30) {
            // Para cambiar los rangos es aquí 30 = 30 minutos, 45 = 15 minus
            minutosAux = minutos;
            horaAux = hora + 1;

            let newMinutos2 = minutosAux.toString() + "0";
            if (newMinutos2.length > 2) {
              newMinutos2 = newMinutos2.substring(0, newMinutos2.length - 1);
            }

            let newHora = "0" + hora.toString();
            if (newHora.length > 2) {
              newHora = newHora.substring(1);
            }

            let newHora2 = "0" + horaAux.toString();
            if (newHora2.length > 2) {
              newHora2 = newHora2.substring(1);
            }

            table +=
              "<td>" +
              newHora +
              ":" +
              newMinutos2 +
              " - " +
              newHora2 +
              ":00" +
              "</td>";

            //    filasx.push({ td: newHora + ':' + newMinutos2 + ' - ' + newHora2 + ':00' });

            // if (fila >= 23) { //A  partir de que fila se pone PM
            //   table += '<td>' + newHora + ':' + newMinutos2 + ' PM - ' + horaAux + ':00 PM' + '</td>';
            // } else {
            //   table += '<td>' + newHora + ':' + newMinutos2 + ' AM - ' + horaAux + ':00 AM' + '</td>';
            // }
            rangos["rango" + fila] =
              newHora + ":" + newMinutos2 + " - " + newHora2 + ":00";
            //  const key = 'rango' + fila;
            const value =
              newHora + ":" + newMinutos2 + " - " + newHora2 + ":00";

            // obJrangos.push({rango: fila, value: value});
            obJrangos.push({ fila: fila, col: col, rango: value });
            //    rango1.push(hora + ':' + minutosAux + ' AM');
            //    rango2.push(horaAux + ':00 AM');
            hora++;
            minutos = 0;
            minutosAux = -30;
          } else {
            minutos = minutos + 30;
            //   console.log(hora + ':' + minutosAux + ' AM - ' + hora + ':' + minutos + ' AM');
            // jsonTable += 'td: ' + hora + ':' + minutosAux + ' AM - ' + hora + ':' + minutos + ' AM,';
            //  rangos.push(hora + ':' + minutosAux + ' AM - ' + hora + ':' + minutos + ' AM');
            //  rango1.push(hora + ':' + minutosAux + ' AM');
            //  rango2.push(hora + ':' + minutos + ' AM');

            let newMinutos = minutos.toString() + "0";
            if (newMinutos.length > 2) {
              newMinutos = newMinutos.substring(0, newMinutos.length - 1);
            }

            let newMinutos2 = minutosAux.toString() + "0";
            if (newMinutos2.length > 2) {
              newMinutos2 = newMinutos2.substring(0, newMinutos2.length - 1);
            }

            let newHora = "0" + hora.toString();
            if (newHora.length > 2) {
              newHora = newHora.substring(1);
            }

            let newHora2 = "0" + hora.toString();
            if (newHora2.length > 2) {
              newHora2 = newHora2.substring(1);
            }

            table +=
              "<td>" +
              newHora +
              ":" +
              newMinutos2 +
              " - " +
              newHora2 +
              ":" +
              newMinutos +
              "</td>";

            rangos["rango" + fila] =
              newHora + ":" + newMinutos2 + " - " + newHora2 + ":" + newMinutos;

            const value =
              newHora + ":" + newMinutos2 + " - " + newHora2 + ":" + newMinutos;

            obJrangos.push({ fila: fila, col: col, rango: value });
            //  filasx.push({ td: newHora + ':' + newMinutos2 + ' - ' + newHora2 + ':' + newMinutos });
            // if (fila >= 23) { //A  partir de que fila se pone PM
            //   table += '<td>' + newHora + ':' + newMinutos2 + ' PM - ' + hora + ':' + newMinutos + ' PM' + '</td>';
            // } else {
            //   table += '<td>' + newHora + ':' + newMinutos2 + ' AM - ' + hora + ':' + newMinutos + ' AM' + '</td>';
            // }
          }
          minutosAux = minutosAux + 30;
        } else {
          table += `<td><span value='rango` + fila + `-dia`;
          table +=
            col +
            `' class='btn-primary btn1 btn-xs cursorDefault' >Disponible</span></td>`;
        }
      }

      table += "</tr>";
      this.rangosPermitidos.push("rango" + fila);
    }
    //  filasx2.push(filasx);
    //  console.log(table);
    ///   table += '</table>';

    this.tablaRangos = table;
    this.rangosHoras = obJrangos;
    this.rangosHoras.length = 48;
    // console.log(this.rangosHoras);
    // console.log(obJrangos);
    //  console.log(rango1);
    //  console.log(rango2);
  }

  programarEvento($event) {
    // const value = $event;

    //var target = $event.target || $event.srcElement || $event.currentTarget;
    //var idAttr = target.attributes.id;
    //var value1 = idAttr.nodeValue; //Funciona
    //console.log(idAttr); //id="rango1-dia5"
    //  console.log(value1); //Funciona

    //console.log(element) //Funciona

    // console.log($event.getAttribute('id').value);
    const element = $event.target as HTMLElement;
    const value = element.getAttribute("value");
    const rangoCitaVisualizada = element.getAttribute("id");

    console.log(value);

    if (value == null) {
      return false;
    }

    const arrayRangoDia = value.split("-");
    if (arrayRangoDia.length !== 2) {
      console.log("Error al intentar asignar la cita");
      alert("Error al intentar asignar la cita ");
      return false;
    }

    const [rango, dia] = arrayRangoDia;
    const dias = ["dia1", "dia2", "dia3", "dia4", "dia5", "dia6", "dia7"];
    // const dias = ['Dia', 'Martes','Miercoles', 'Jueves','Viernes','Sabado', 'Domingo'];

    if (
      dias.includes(dia) === false ||
      this.rangosPermitidos.includes(rango) === false
    ) {
      console.log("Error al intentar asignar la cita");
      alert("Error al intentar asignar la cita");
      return false;
    }

    this.dia = dia;
    this.rango = rango;

    // this.ngModelVerHora = this.hora = this.rangosHoras[rango];

    const result = this.rangosHoras.filter((obj) => {
      //this.rangosHoras.find(obj => {
      //  console.log(obj);
      // console.log(rango);
      const tempRango = "rango" + obj.fila;
      //  console.log(tempRango);
      if (tempRango === rango) {
        return obj.rango;
      }

      return null;
    });

    if (result.length > 0) {
      this.ngModelVerHora = this.hora = result[0].rango;
    } else {
      alert(
        "Ocurrió un error al intentar obtener la hora, debe intentar de nuevo"
      );
      return false;
    }
    console.log(result);

    this.fecha = this.ngModelVerFecha = this.obtenerFecha(dia, this.dias);
    this.verVentanaConfirmacionCita = true;
    this.formData.get("txtNota").setValue("");
    this.desBtnGuardar = false;
    this.cargarDatosConsultorio();
    // console.log(index);
    // console.log(this.lstConsultorios[index]);

    console.log({ rangoHoras: this.rangosHoras });
    console.log(this.dias);

    console.log(rango);
    console.log(dia);

    //Para saber si se puede visualizar guardar o imprimir
    // if (this.arrayNoDisponibles.includes(rangoCitaVisualizada)) {
    //   this.verBotonesDetalleCita.guardar = false;
    //   this.verBotonesDetalleCita.imprimir = true;
    // } else {
    //   this.verBotonesDetalleCita.guardar = true;
    //   this.verBotonesDetalleCita.imprimir = false;
    // }
    this.rangoCitaVisualizada = rangoCitaVisualizada;
    this.habDesGuardarImprimir(rangoCitaVisualizada);
  }

  obtenerFecha(dia: string, dias: any) {
    let fecha = "";
    switch (dia) {
      case "dia1":
        fecha = dias.lunes;
        break;
      case "dia2":
        fecha = dias.martes;
        break;
      case "dia3":
        fecha = dias.miercoles;
        break;
      case "dia4":
        fecha = dias.jueves;
        break;
      case "dia5":
        fecha = dias.viernes;
        break;

      case "dia6":
        fecha = dias.sabado;
        break;

      case "dia7":
        fecha = dias.domingo;
        break;
    }

    return fecha;
  }

  cargarDatosConsultorio() {
    const idConsultorio = this.formData.get("cboConsultorios").value;
    if (idConsultorio !== undefined) {
      Object.keys(this.lstConsultorios).forEach((index) => {
        const item = this.lstConsultorios[index];
        if (idConsultorio === item.id) {
          this.ngModelCosultorioNombre = item.nombre;
          this.ngModelCosultorioDireccion = item.direccion;
          this.ngModelCosultorioTelefono = item.telefono;
          this.ngModelCosultorioCelular = item.celular;
        }
      });
    }
  }

  dataConsultorios($event) {
    console.log($event);
    this.lstConsultorios = $event;
    // this.ngModelCosultorioNombre = $event.nombre;
    // this.ngModelCosultorioDireccion = $event.direccion;
    // this.ngModelCosultorioTelefono = $event.telefono;
    // this.ngModelCosultorioCelular = $event.celular;
  }

  getDays($value) {
    console.log($value);
    this.dias.lunes = "Nuevo";
  }

  generarPdf() {
    console.log(this.arrayIdNoDisponibles);
    console.log(this.rangoCitaVisualizada);

    const result = this.arrayIdNoDisponibles.filter((obj) => {
      //this.rangosHoras.find(obj => {
      console.log(obj);
      console.log(this.rangoCitaVisualizada);

      if (obj.rango === this.rangoCitaVisualizada) {
        return obj.codigo;
      }

      return null;
    });
    console.log(result);

    if (result.length > 0) {
      console.log(result);
      const codigo = result[0].codigo;
      this.generandoReporte = true;
      let url = "reportes/generarpdf?origen=citas";
      url += "&codigo=" + codigo;
      this.apiServices.getData(url, "Reporte", false).subscribe(
        (data) => {
          console.log(data);

          if (
            data !== null &&
            data.success !== undefined &&
            data.success === true
          ) {
            // this.tienePdf = true;
            // this.urlPdf = this.baseUrl2 + data.path;
            this.generandoReporte = false;
            window.open(this.baseUrl2 + data.path);
          } else {
            this.mostrarMsgError(
              "Error al intentar generar el PDF, Debe intentar de nuevo"
            );
          }

          this.generandoReporte = false;
        },
        (error) => {
          console.log(error);
          //  this.codigoFormula = 0;
          //  this.cerrarVentana();
          this.generandoReporte = false;
          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError(
              "Error al intentar generar el PDF, Debe intentar de nuevo"
            );
          }
        },
        () => {}
      );
    } else {
      alert("Error al intentar generar la cita médica, debe intentar de nuevo");
    }
  }

  mostrarMsgError(msg: string) {
    this.alertMsg1.class = "alert alert-danger";
    this.alertMsg1.showAlert = true;
    this.alertMsg1.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg1.class = "alert alert-success";
    this.alertMsg1.showAlert = true;
    this.alertMsg1.msg = msg;
  }

  agendarCita() {
    this.submitted = true;
    if (this.formData.invalid) {
      this.closeModals();
      this.alertMsg1.class = "alert alert-success";
      this.alertMsg1.showAlert = true;
      this.alertMsg1.msg =
        "El formulario que intenta enviar contiene errores. Debe verificar e intentar de nuevo";
      return false;
    }
    console.log(this.dia);
    console.log(this.rango);
    console.log(this.ngModelVerMedico);
    console.log(this.ngModelVerPaciente);
    console.log(this.ngModelVerFecha);
    console.log(this.ngModelVerHora);

    const formData = new FormData();
    formData.set("paciente_id", "" + this.pacienteId);
    formData.set("medico_id", "" + this.medicoId);
    formData.set("especialidad_id", "" + this.especidadliadId);
    formData.set("rango", this.rango);
    formData.set("fecha", this.fecha);
    formData.set("dia", this.dia);
    formData.set("hora", this.hora);
    formData.set("consultorio_id", "" + this.consultorioId);
    formData.set("tipocita_id", "" + this.tipoCitaId);
    formData.set("nota", this.txtNota.value);

    this.apiServices.postFormData(formData, "citas").subscribe(
      (response) => {
        console.log(response);
        if (
          response !== null &&
          response.success !== undefined &&
          response.success === true
        ) {
          this.alertMsg2.class = "alert alert-success";
          const citaAsignada = response.item;
          const celda = window.document.querySelector(
            '[value="' + citaAsignada.rango + "-" + citaAsignada.dia + '"]'
          );
          celda.className = "btn-default btn1 btn-xs cursorDefault";
          celda.textContent = "No disponible";
          //this.desBtnGuardar = true;

          this.arrayNoDisponibles.push(
            citaAsignada.rango + "-" + citaAsignada.dia
          );
          this.arrayIdNoDisponibles.push({
            codigo: citaAsignada.codigo,
            rango: citaAsignada.rango + "-" + citaAsignada.dia,
          });
          this.habDesGuardarImprimir(
            citaAsignada.rango + "-" + citaAsignada.dia
          );
          this.alertMsg2.showAlert = true;
          this.alertMsg2.msg =
            response.response + " Código: " + citaAsignada.codigo;
        } else {
          let msg = "Error al intentar procesar los datos enviados, ";
          msg +=
            "debe verificar que los datos ingresados en el formulario no tengan inconsistencias";
          this.mostrarMsgError(msg);
          this.closeModals();
        }
      },
      (dataError) => {
        console.log(dataError);

        if (dataError.error.response !== undefined) {
          this.mostrarMsgError(dataError.error.response);
        } else {
          let msg = "Error al intentar procesar los datos enviados, ";
          msg +=
            "debe verificar que los datos ingresados en el formulario no tengan inconsistencias";
          this.mostrarMsgError(msg);
        }

        const errors = GetMsgFromError(dataError);
        console.log(errors);

        this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
          errors.errors
        );
        this.closeModals();
        console.log(this.serverErrorsInfo);
        //  this.accionEjecutandose = false;
        window.scroll(0, 0);
      },
      () => {
        console.log("finally");
      }
    );
  }

  habDesGuardarImprimir(rango) {
    //this.rangoCitaVisualizada = 0;
    //Para saber si se puede visualizar guardar o imprimir
    if (this.arrayNoDisponibles.includes(rango)) {
      this.verBotonesDetalleCita.guardar = false;
      this.verBotonesDetalleCita.imprimir = true;
    } else {
      this.verBotonesDetalleCita.guardar = true;
      this.verBotonesDetalleCita.imprimir = false;
    }
  }
}
