import { Injectable } from '@angular/core';
/* import { Router } from '@angular/router';*/
// import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransferServiceService {

  /*  private approvalStageMessage = new BehaviorSubject('Basic Approval is required!');
   public currentApprovalStageMessage = this.approvalStageMessage.asObservable();

   constructor() {

   }
   updateApprovalMessage(message: string) {
   this.approvalStageMessage.next(message)
   } */

  private subject = new Subject<any>();
  constructor() { }

  setData(message: any) {
    this.subject.next(message);
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }
}
