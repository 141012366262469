import { Component, OnInit, Input, AfterContentInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-datos-paciente',
  templateUrl: './datos-paciente.component.html',
  styleUrls: ['./datos-paciente.component.css']
})
export class DatosPacienteComponent implements OnInit, AfterContentInit {

  @Input() datosPaciente: any;
  @Output() eventoDatosPaciente = new EventEmitter<any>();
  
  ngModelTxtNombrePaciente = '';
  ngModelTxtIdenPaciente = '';
  ngModelTxtEstadoCivilPaciente = '';
  ngModelTxtGeneroPaciente = '';
  ngModelTxtOcupacionPaciente = '';
  ngModelTxtPaisPaciente = '';
  ngModelTxtCiuPaciente = '';
  ngModelTxtDepPaciente = '';
  ngModelTxtDireccionPaciente = '';
  ngModelTxtTelefonoPaciente = '';
  ngModelTxtFecNacPaciente = '';
  ngModelTxtEdadPaciente = '';

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterContentInit() {
    // console.log(this.datosPaciente);
   // this.getPaciente
    console.log(this.getAge(this.datosPaciente.fecha_nac));
    this.ngModelTxtNombrePaciente = this.datosPaciente.nombres + ' ' + this.datosPaciente.apellidos;
    this.ngModelTxtIdenPaciente = this.datosPaciente.identificacion;
    this.ngModelTxtEstadoCivilPaciente = this.datosPaciente.EstadoCivil.nombre;
    this.ngModelTxtGeneroPaciente = this.datosPaciente.Genero.nombre;
    this.ngModelTxtOcupacionPaciente = this.datosPaciente.Ocupacion.nombre;
    this.ngModelTxtPaisPaciente = this.datosPaciente.Country.nombre;

    if (this.datosPaciente.CiudadResidencia !== undefined) {
      this.ngModelTxtCiuPaciente = this.datosPaciente.CiudadResidencia.nombre;
    } else {
      this.ngModelTxtCiuPaciente = this.datosPaciente.ciudad_residencia
    }

    if (this.datosPaciente.DepartamentoResidencia !== undefined) {
      this.ngModelTxtDepPaciente = this.datosPaciente.DepartamentoResidencia.nombre;
    }

    this.ngModelTxtDireccionPaciente = this.datosPaciente.direccion;
    this.ngModelTxtTelefonoPaciente = this.datosPaciente.celular;
    this.ngModelTxtFecNacPaciente = this.datosPaciente.fecha_nac;
    this.ngModelTxtEdadPaciente = '' + this.getAge(this.datosPaciente.fecha_nac);
  }

  getAge(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  
  /*getPaciente(id: number) {

    console.log({ getPacienteId: id });

    if (id > 0) {
      let urlParams = 'pacientes/' + id;
      // urlParams += 'id/' + pacienteId;
      urlParams += '?grpsa=all&estciv=all';
      urlParams += '&genero=all&ocup=all&ciures=all&depres=all&countryid=all';

      this.apiServices.getData(urlParams, 'Paciente', false)
        .subscribe(data => {
          if (data!== null && data.success === true) {
            console.log(data['Paciente']);
            this.eventoDatosPaciente.emit(data['Paciente']);
            this.cargarDatos(data['Paciente']);
          } else {
            this.eventoDatosPaciente.emit(null);
          }

        }, error => {
          this.eventoDatosPaciente.emit(null);
          console.log(error);
        }, () => {
          console.log('Finally');
        });
    } else {
      this.eventoDatosPaciente.emit(null);
    }

  } */
}
