import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-dialogo-confirmacion',
  templateUrl: './dialogo-confirmacion.component.html',
  styleUrls: ['./dialogo-confirmacion.component.css']
})
export class DialogoConfirmacionComponent implements OnInit {

//  @Output() EventoCerrar = new EventEmitter<any>();
  @Output() EventoSiNo = new EventEmitter<any>();
  @Input() textoMsg = '¿Está seguro(a)?';
  @Input() tituloVentana = 'Confirmación';
  @Input() mostrarVentana = false;

  constructor() { }

  ngOnInit(): void {
  }

  btnConfirmar($event) {
    console.log($event);
    if ($event === 'Si') {
      this.EventoSiNo.emit(true);
    } else if ($event === 'No') {
      this.EventoSiNo.emit(false);
      this.mostrarVentana = false;
    }
  }

}
