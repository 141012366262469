export const serverErrorsInfo = {

  setServerErrorsInfo: function setServerErrorsInfo(serverErrs: any) {
    const errors: any = {};
    const entries = Object.entries(serverErrs);
    for (const [index, object] of entries) {
      const item: any = object;
      errors.serverErrorsInfo = true;
      switch (item.key) {
        case 'identificacion':
          errors.identificacionError = true;
          errors.identificacionErrorMsg = item.value;
          break;
        case 'nombres':
          errors.nombresError = true;
          errors.nombresErrorMsg = item.value;
          break;
        case 'apellidos':
          errors.apellidosError = true;
          errors.apellidosErrorMsg = item.value;
          break;
        case 'direccion':
          errors.direccionError = true;
          errors.direccionErrorMsg = item.value;
          break;
        case 'email':
          errors.emailError = true;
          errors.emailErrorMsg = item.value;
          break;
        case 'celular':
          errors.celularError = true;
          errors.celularErrorMsg = item.value;
          break;
        case 'especialidad_id':
          errors.especialidadIdError = true;
          errors.especialidadIdErrorMsg = item.value;
          break;
        case 'password':
          errors.passwordError = true;
          errors.passwordErrorMsg = item.value;
          break;
        case 'num_registro':
          errors.numRegistoMedicoError = true;
          errors.numRegistoMedicoErrorMsg = item.value;
          break;
        case 'foto':
          errors.fotoError = true;
          errors.fotoErrorMsg = item.value;
          break;
        case 'ocupacion_id':
          errors.ocupacionError = true;
          errors.ocupacionErrorMsg = item.value;
          break;
        case 'ide_responsable':
          errors.identificacionResponsableError = true;
          errors.identificacionResponsableErrorMsg = item.value;
          break;
        case 'nom_responsable':
          errors.nomResponsableError = true;
          errors.nomResponsableErrorMsg = item.value;
          break;
        case 'cel_responsable':
          errors.celResponsableError = true;
          errors.celResponsableErrorMsg = item.value;
          break;
        case 'par_responsable':
          errors.parResponsableError = true;
          errors.parResponsableErrorMsg = item.value;
          break;
        case 'tipodocumento_id':
          errors.tipoIdError = true;
          errors.tipoIdIdErrorMsg = item.value;
          break;
        case 'genero_id':
          errors.generoError = true;
          errors.generoErrorMsg = item.value;
          break;
        case 'estatura':
          errors.estaturaError = true;
          errors.estaturaErrorMsg = item.value;
          break;
        case 'peso':
          errors.pesoError = true;
          errors.pesoErrorMsg = item.value;
          break;
        case 'gruposanguineo_id':
          errors.grupoSanError = true;
          errors.grupoSanErrorMsg = item.value;
          break;
        case 'celular':
          errors.celularError = true;
          errors.celularErrorMsg = item.value;
          break;
        case 'num_hijos':
          errors.numHijosError = true;
          errors.numHijosErrorMsg = item.value;
          break;
        case 'escolaridad_id':
          errors.nivelEscoError = true;
          errors.nivelEscoErrorMsg = item.value;
          break;
        case 'ocupacion_id':
          errors.ocupacionError = true;
          errors.ocupacionErrorMsg = item.value;
          break;
        case 'fecha_nacimiento':
          errors.fechaNacError = true;
          errors.fechaNacErrorMsg = item.value;
          break;
        case 'departamentoresidencia_id':
          errors.depResError = true;
          errors.depResErrorMsg = item.value;
          break;
        case 'ciudadresidencia_id':
          errors.ciuResError = true;
          errors.ciuResErrorMsg = item.value;
          break;

        case 'ciudad_residencia':
          errors.ciuResError = true;
          errors.ciuResErrorMsg = item.value;
          break;
          
        case 'country_id':
          errors.paisResError = true;
          errors.paisResErrorMsg = item.value;
          break;
        case 'direccion':
          errors.direccionError = true;
          errors.direccionErrorMsg = item.value;
          break;
        case 'eps_id':
          errors.epsError = true;
          errors.epsErrorMsg = item.value;
          break;
        case 'fondopension_id':
          errors.fondoPensionesError = true;
          errors.fondoPensionesErrorMsg = item.value;
          break;
        case 'foto':
          errors.fotoError = true;
          errors.fotoErrorMsg = item.value;
          break;
        case 'estadocivil_id':
          errors.estadoCivilError = true;
          errors.estadoCivilErrorMsg = item.value;
          break;
        case 'notas':
          errors.notasError = true;
          errors.notasErrorMsg = item.value;
          break;
        case 'clausurada':
          errors.clausuradaError = true;
          errors.clausuradaErrorMsg = item.value;
          break;
        case 'paciente_id':
          errors.pacienteIdError = true;
          errors.pacienteIdErrorMsg = item.value;
          break;
        case 'consulta_motivo':
          errors.consultaMotivoError = true;
          errors.consultaMotivoErrorMsg = item.value;
          break;
        case 'consulta_notas':
          errors.consultaNotasError = true;
          errors.consultaNotasErrorMsg = item.value;
          break;
        case 'enfermedad_actual':
          errors.enfermedadActualError = true;
          errors.enfermedadActualErrorMsg = item.value;
          break;
        case 'enfermedad_notas':
          errors.enfermedadNotasError = true;
          errors.enfermedadNnotasErrorMsg = item.value;
          break;
        case 'ant_patologicos':
          errors.antPatologicosError = true;
          errors.antPatologicosErrorMsg = item.value;
          break;
        case 'ant_traumas':
          errors.antTraumasError = true;
          errors.antTraumasErrorMsg = item.value;
          break;
        case 'ant_familiares':
          errors.antFamiliaresError = true;
          errors.antFamiliaresErrorMsg = item.value;
          break;
        case 'ant_quirurgicos':
          errors.antQuirurgicosError = true;
          errors.antQuirurgicosErrorMsg = item.value;
          break;
        case 'ant_farmacologicos':
          errors.antFarmacologicosError = true;
          errors.antFarmacologicosErrorMsg = item.value;
          break;
        case 'ant_mestruacion':
          errors.antMestruacionError = true;
          errors.antMestruacionErrorMsg = item.value;
          break;
        case 'ant_alergicos':
          errors.antAlergicosError = true;
          errors.antAlergicosErrorMsg = item.value;
          break;
        case 'alergico':
          errors.alergicoError = true;
          errors.alergicoErrorMsg = item.value;
          break;
        case 'ant_toxicos':
          errors.antToxicosError = true;
          errors.antToxicosErrorMsg = item.value;
          break;
        case 'ant_vacunacion':
          errors.antVacunacionError = true;
          errors.antVacunacionErrorMsg = item.value;
          break;
        case 'ant_planificacion':
          errors.antPlanificacionError = true;
          errors.antPlanificacionErrorMsg = item.value;
          break;
        case 'ant_gineco_gravidez':
          errors.antGinecoGravidezError = true;
          errors.antGinecoGravidezErrorMsg = item.value;
          break;
        case 'ant_gineco_partos':
          errors.antGinecoPartosError = true;
          errors.antGinecoPartosErrorMsg = item.value;
          break;
        case 'ant_gineco_vivos':
          errors.antGinecoVivosError = true;
          errors.antGinecoVivosErrorMsg = item.value;
          break;
        case 'ant_gineco_abortos':
          errors.antGinecoAbortosError = true;
          errors.antGinecoAbortosErrorMsg = item.value;
          break;
        case 'ant_gineco_cesareas':
          errors.antGinecoCesareasError = true;
          errors.antGinecoCesareasErrorMsg = item.value;
          break;
        case 'ant_notas':
          errors.antNotasError = true;
          errors.antNotasErrorMsg = item.value;
          break;
        case 'sistema_visual':
          errors.sistemaVisualError = true;
          errors.sistemaVisualErrorMsg = item.value;
          break;
        case 'sistema_auditivo':
          errors.sistemaAuditivoError = true;
          errors.sistemaAuditivoErrorMsg = item.value;
          break;
        case 'sistema_digestivo':
          errors.sistemaDigestivoError = true;
          errors.sistemaDigestivoErrorMsg = item.value;
          break;
        case 'sistema_muscular':
          errors.sistemaMuscularError = true;
          errors.sistemaMuscularErrorMsg = item.value;
          break;
        case 'sistema_esqueletico':
          errors.sistemaEsqueleticoError = true;
          errors.sistemaEsqueleticoErrorMsg = item.value;
          break;
        case 'sistema_nervioso':
          errors.sistemaNerviosoError = true;
          errors.sistemaNerviosoErrorMsg = item.value;
          break;
        case 'piel':
          errors.pielError = true;
          errors.pielErrorMsg = item.value;
          break;
        case 'sistema_tegumentario':
          errors.sistemaTegumentario = true;
          errors.sistemaTegumentarioErrorMsg = item.value;
          break;
        case 'sistema_notas':
          errors.sistemaNotasError = true;
          errors.sistemaNotasErrorMsg = item.value;
          break;
        case 'signos_temp':
          errors.signosTempError = true;
          errors.signosTempErrorMsg = item.value;
          break;
        case 'signos_pulso':
          errors.signosPulsoError = true;
          errors.signosPulsoErrorMsg = item.value;
          break;
        case 'signos_frecuencia':
          errors.signosFrecuenciaError = true;
          errors.signosFrecuenciaErrorMsg = item.value;
          break;
        case 'signos_tension1':
          errors.signosTension1Error = true;
          errors.signosTension1ErrorMsg = item.value;
          break;
        case 'signos_tension2':
          errors.signosTension2Error = true;
          errors.signosTension2ErrorMsg = item.value;
          break;
        case 'signos_peso':
          errors.signosPesoError = true;
          errors.signosPesoErrorMsg = item.value;
          break;
        case 'signos_talla':
          errors.signosTallaError = true;
          errors.signosTallaErrorMsg = item.value;
          break;
        case 'signos_imc':
          errors.signosImcError = true;
          errors.signosImcErrorMsg = item.value;
          break;
        case 'signos_notas':
          errors.signosNotasError = true;
          errors.signosNotasErrorMsg = item.value;
          break;
        case 'examen_ter_sup':
          errors.examenTerSupError = true;
          errors.examenTerSupErrorMsg = item.value;
          break;
        case 'examen_ter_med':
          errors.examenTerMedError = true;
          errors.examenTerMedErrorMsg = item.value;
          break;
        case 'examen_ter_inf':
          errors.examenTerInfError = true;
          errors.examenTerInfErrorMsg = item.value;
          break;
        case 'examen_cuello':
          errors.examenCuelloError = true;
          errors.examenCuelloErrorMsg = item.value;
          break;
        case 'examen_anterior':
          errors.examenAnteriorError = true;
          errors.examenAnteriorErrorMsg = item.value;
          break;
        case 'examen_posterior':
          errors.examenPosteriorError = true;
          errors.examenPosteriorErrorMsg = item.value;
          break;
        case 'examen_abdomen':
          errors.examenAbdomenError = true;
          errors.examenAbdomenErrorMsg = item.value;
          break;
        case 'examen_mien_sup':
          errors.examenMienSupError = true;
          errors.examenMienSupErrorMsg = item.value;
          break;
        case 'examen_mien_inf':
          errors.examenMienInfError = true;
          errors.examenMienInfErrorMsg = item.value;
          break;
        case 'examen_genitales':
          errors.examenGenitalesError = true;
          errors.examenGenitalesErrorMsg = item.value;
          break;
        case 'examen_gluteos':
          errors.examenGluteosError = true;
          errors.examenGluteosErrorMsg = item.value;
          break;
        case 'examen_notas':
          errors.examenNotasError = true;
          errors.examenNotasErrorMsg = item.value;
          break;
        case 'examen_mamas':
          errors.examenMamasError = true;
          errors.examenMamasErrorMsg = item.value;
          break;
        case 'para_paraclinicos':
          errors.paraParaclinicosError = true;
          errors.paraParaclinicosErrorMsg = item.value;
          break;
        case 'para_notas':
          errors.paraNotasError = true;
          errors.paraNotasErrorMsg = item.value;
          break;
        case 'analisis_analisis':
          errors.analisisAnalisisError = true;
          errors.analisisAnalisisErrorMsg = item.value;
          break;
        case 'analisis_notas':
          errors.analisisNotas = true;
          errors.analisisNotasErrorMsg = item.value;
          break;
        case 'diagnosticos_cie10':
          errors.diagnosticosCie10Error = true;
          errors.diagnosticosCie10ErrorMsg = item.value;
          break;
        case 'diagnostico_manual':
          errors.diagnosticoManualError = true;
          errors.diagnosticoManualErrorMsg = item.value;
          break;
        case 'diagnostico_notas':
          errors.diagnosticoNotasError = true;
          errors.diagnosticoNotasErrorMsg = item.value;
          break;
        case 'plan_plan':
          errors.planPlanError = true;
          errors.planPlanErrorMsg = item.value;
          break;
        case 'plan_notas':
          errors.planNotasError = true;
          errors.planNotasErrorMsg = item.value;
          break;
        case 'general_notas':
          errors.generalNotasError = true;
          errors.generalNotasErrorMsg = item.value;
          break;

        case 'tipousuario_id':
          errors.tipoUsuarioError = true;
          errors.tipoUsuarioErrorMsg = item.value;
          break;

        case 'username':
          errors.userNameError = true;
          errors.userNameErrorMsg = item.value;
          break;

        case 'password':
          errors.passwordError = true;
          errors.passwordErrorMsg = item.value;
          break;

        case 'dirigido_a':
          errors.dirigidoAError = true;
          errors.dirigidoAErrorMsg = item.value;
          break;

        case 'diagnostico':
          errors.diagnosticoError = true;
          errors.diagnosticoErrorMsg = item.value;
          break;

        case 'diagnosticos_cie10':
          errors.diagnosticosCie10Error = true;
          errors.diagnosticosCie1ErrorMsg = item.value;
          break;

        case 'fecha_inicio':
          errors.fechaInicioError = true;
          errors.fechaInicioErrorMsg = item.value;
          break;

        case 'fecha_fin':
          errors.fechaFinError = true;
          errors.fechaFinErrorMsg = item.value;
          break;

        case 'origen':
          errors.origenError = true;
          errors.origenErrorMsg = item.value;
          break;

        case 'num_dias':
          errors.numDiasError = true;
          errors.numDiasErrorMsg = item.value;
          break;

        case 'telefono_empresa':
          errors.telefonoEmpresaError = true;
          errors.telefonoEmpresaErrorMsg = item.value;
          break;

        case 'direccion_empresa':
          errors.direccionEmpresaError = true;
          errors.direccionEmpresaErrorMsg = item.value;
          break;

        case 'notas_incapacidad':
          errors.notasIncapacidadError = true;
          errors.notasIncapacidadErrorMsg = item.value;
          break;

        case 'medico_id':
          errors.medicoIdError = true;
          errors.medicoIdErrorMsg = item.value;
          break;

        case 'estado':
          errors.estadoError = true;
          errors.estadoErrorMsg = item.value;
          break;

        case 'hora_inicio':
          errors.horaInicioError = true;
          errors.horaInicioErrorMsg = item.value;
          break;

        case 'hora_fin':
          errors.horaFinError = true;
          errors.horaFinErrorMsg = item.value;
          break;

        case 'anestesiologo':
          errors.anestesiologoError = true;
          errors.anestesiologoErrorMsg = item.value;
          break;

        case 'ayudante':
          errors.ayudanteError = true;
          errors.ayudanteErrorMsg = item.value;
          break;

        case 'tipo_anestesia':
          errors.tipoAnestesiaError = true;
          errors.tipoAnestesiaErrorMsg = item.value;
          break;

        case 'antibiotico':
          errors.antibioticoError = true;
          errors.antibioticoErrorMsg = item.value;
          break;

        case 'sangrado':
          errors.sangradoError = true;
          errors.sangradoErrorMsg = item.value;
          break;

        case 'complicaciones':
          errors.complicacionesError = true;
          errors.complicacionesErrorMsg = item.value;
          break;

        case 'procedimiento':
          errors.procedimientoError = true;
          errors.procedimientoErrorMsg = item.value;
          break;

        case 'telefono_empresa':
          errors.telefonoEmpresaError = true;
          errors.telefonoEmpresaErrorMsg = item.value;
          break;


        case 'hallazgos':
          errors.hallazgosError = true;
          errors.hallazgosErrorMsg = item.value;
          break;


        case 'descripcion':
          errors.descripcionError = true;
          errors.descripcionErrorMsg = item.value;
          break;

        case 'conducta':
          errors.conductaError = true;
          errors.conductaErrorMsg = item.value;
          break;

        case 'plan':
          errors.planError = true;
          errors.planErrorrMsg = item.value;
          break;

        case 'ayudante_ide':
          errors.ayudanteIdeError = true;
          errors.ayudanteIdeErrorMsg = item.value;
          break;

        case 'anestesiologo_ide':
          errors.anestesiologoIdeError = true;
          errors.anestesiologoIdeErrorMsg = item.value;
          break;

        case 'capitulo':
          errors.capituloError = true;
          errors.capituloErrorMsg = item.value;
          break;

        case 'nombre_capitulo':
          errors.nombreCapituloError = true;
          errors.nombreCapituloMsg = item.value;
          break;

        case 'codigo':
          errors.codigoError = true;
          errors.codigoErrorMsg = item.value;
          break;

        case 'titulo':
          errors.tituloError = true;
          errors.tituloErrorMsg = item.value;
          break;

        case 'consultorio_id':
          errors.consultorioIdError = true;
          errors.consultorioIdErrorMsg = item.value;
          break;

        case 'tipocita_id':
          errors.consultorioTipoIdError = true;
          errors.consultorioTipoIdErrorMsg = item.value;
          break;

        case 'tipodocumento_id':
          errors.tipoIdError = true;
          errors.tipoIdErrorMsg = item.value;
          break;

        case 'nota':
          errors.notaError = true;
          errors.notaErrorMsg = item.value;
          break;

        case 'fecha':
          errors.fechaError = true;
          errors.fechaErrorMsg = item.value;
          break;
      }

    }
    console.log(errors);
    return errors;
  },

  resetServerError: function resetServerError(srvErrorsInfo) {
    srvErrorsInfo.nameError = false;
    srvErrorsInfo.typeError = false;
    srvErrorsInfo.valueError = false;
  }
};

