import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-audits',
  templateUrl: './audits.component.html',
  styleUrls: ['./audits.component.css']
})
export class AuditsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
