import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Input,
  AfterViewInit,
  ElementRef,
  Output,
  EventEmitter,
  AfterContentInit,
} from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import {
  MaxLength,
  OnlyIntegerNumbers,
  MinLength,
  OnlyLetters,
} from "src/app/validators/CustomValidators";
import { ServiciosService } from "../../servicios/servicios.service";
import { Router, ActivatedRoute } from "@angular/router";
import { GetMsgFromError } from "src/app/functions/SharedFunctions";
import { serverErrorsInfo } from "src/app/utilities/serverErrorsInfo";
import { NotificationService } from "@progress/kendo-angular-notification";
import { FileRestrictions } from "@progress/kendo-angular-upload";
import { environment } from "src/environments/environment";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { TransferService } from "src/app/servicios/transfer.service";
import { EncryptionService } from "src/app/servicios/encryption.service";

@Component({
  selector: "app-detalle-paciente-potencial",
  templateUrl: "./detalle-paciente-potencial.component.html",
  styleUrls: ["./detalle-paciente-potencial.component.css"],
})
export class DetallePacientesPotencialesComponent
  implements OnInit, AfterViewInit, AfterContentInit
{
  @ViewChild("appendTo", { read: ViewContainerRef })
  public appendTo: ViewContainerRef;
  @Input() inputMedicoId = 0;
  @ViewChild("imgFoto", { static: false }) imgFoto: ElementRef;
  @Output() ValueChanged = new EventEmitter<any>();
  @Output() OutputCerrarVentana = new EventEmitter<any>();

  activarKTextbox: false;
  formData: FormGroup;
  submitted = false;
  serverErrorsInfo: any = {};
  public imagePath;
  imgURL: any;
  public message: string;
  mostrarVentanaConfirmacion = false;
  spinnerActivo = true;
  tipoUsuario = "";
  spinners = {
    mostrarSpinner: false,
  };
  usuarioId = 0;
  lstEspecialidades = [];

  baseUrl2 = environment.baseUrl2;

  queryParams: any;

  public myFiles: Array<any>;
  // public userImages: Array<FileInfo>;

  loadedData = false;
  medicoId = 0;
  registroEncontrado = false;
  estadoMedico = "";

  windowNotification = {
    msg: "",
    title: "",
    buttonMsg: "Cerrar",
    status: false,
  };

  nombreCompleto = "";

  public userImages: Array<any>;

  alertMsg = {
    showAlert: false,
    msg: "",
    class: "",
  };

  public myRestrictions: FileRestrictions = {
    allowedExtensions: [".jpg", ".jpeg", ".png"],
    maxFileSize: 2097152,
  };

  public lstEstados: Array<{ text: string; value: string }> = [
    { text: "Activo", value: "Activo" },
    { text: "Bloqueado", value: "Bloqueado" },
  ];

  customData = {
    especialdiades: [],
  };

  fotos = {
    mini: "",
    original: "",
  };
  lstData: any[];
  tempLstData: any[];

  constructor(
    private router: Router,
    private apiServices: ServiciosService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private encryption: EncryptionService,
    private transferService: TransferService,
    private formBuilder: FormBuilder
  ) {
    window.scrollTo(0, 0);
    // console.log('aqui');
    // const navigation = this.router.getCurrentNavigation();
    // this.queryParams = navigation.extras.queryParams;
    // console.log(this.queryParams);
    this.activatedRoute.queryParams.subscribe((params) => {
      const medicoId = params.medicoId;
      this.medicoId = medicoId;

      console.log(medicoId);
      // this.getData(medicoId);
    });
  }

  ngAfterContentInit() {
    this.spinnerActivo = true;
    //this.medicoId = this.inputMedicoId;
    this.getData(this.medicoId);

    this.tipoUsuario = this.apiServices.getTipoUsuario();
  }

  ngAfterViewInit(): void {}

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "startsWith",
  };

  ngOnInit(): void {
    this.formData = this.formBuilder.group({
      cboTipoId: [
        "",
        [Validators.required, MaxLength(false, 18), OnlyIntegerNumbers()],
      ],
      txtIdentificacion: [
        "",
        [Validators.required, MaxLength(false, 20), OnlyIntegerNumbers()],
      ],
      txtNombres: ["", [Validators.required, MaxLength(false, 100)]],
      txtApellidos: ["", [Validators.required, MaxLength(false, 100)]],
      txtCelular: [
        "",
        [
          Validators.required,
          MaxLength(false, 15),
          MinLength(false, 10),
          OnlyIntegerNumbers(),
        ],
      ],
      txtEmail: [
        "",
        [Validators.required, MaxLength(false, 75), Validators.email],
      ],
    });
  }

  // Gets
  get cboTipoId() {
    return this.formData.get("cboTipoId");
  }
  get txtIdentificacion() {
    return this.formData.get("txtIdentificacion");
  }
  get txtNombres() {
    return this.formData.get("txtNombres");
  }
  get txtApellidos() {
    return this.formData.get("txtApellidos");
  }
  get txtCelular() {
    return this.formData.get("txtCelular");
  }
  get txtEmail() {
    return this.formData.get("txtEmail");
  }

  btnGuardarCambios() {
    this.submitted = true;
    if (this.formData.invalid) {
      this.serverErrorsInfo.serverErrorsInfo = false;
      return false;
    }

    this.mostrarVentanaConfirmacion = true;
  }

  onSubmit() {
    this.alertMsg.showAlert = false;

    // console.log(this.formData);

    //  console.log(this.formData.get('txtFoto').value[0]);
    // if (this.formData.get('txtPassword').value.toString().trim === '') {
    //   this.formData.get('txtPassword').disable();
    // }

    this.mostrarVentanaConfirmacion = false;

    this.submitted = true;

    if (this.formData.invalid) {
      this.mostrarMsgError(
        "El formulario contiene errores, debe verificar e intentar de nuevo"
      );
      this.serverErrorsInfo.serverErrorsInfo = false;
      //  this.spinners.mostrarSpinner = false;
      return false;
    }

    this.spinners.mostrarSpinner = true;
    const formData = new FormData();
    formData.append("tipodocumento_id", this.formData.get("cboTipoId").value);
    formData.append(
      "identificacion",
      this.formData.get("txtIdentificacion").value
    );
    formData.append("id", this.medicoId.toString());
    formData.append("nombres", this.formData.get("txtNombres").value);
    formData.append("apellidos", this.formData.get("txtApellidos").value);
    formData.append("celular", this.formData.get("txtCelular").value);
    formData.append("email", this.formData.get("txtEmail").value);

    this.apiServices
      .putFormData(formData, "pacientespotenciales/" + this.medicoId)
      .subscribe(
        (res) => {
          const response = res;

          console.log(response);

          if (
            response !== null &&
            response.success !== undefined &&
            response.success === true
          ) {
            const item = response["Pacientespotenciales"];
            this.alertMsg.class = "alert alert-success";
            this.ValueChanged.emit(true);
            this.submitted = false;
            // const usuarioId = item.id;
            this.serverErrorsInfo.serverErrorsInfo = false;

            let userInfo = this.apiServices.getUserInfoFromToken();
            userInfo = userInfo.userInfo;
            const idUserLogin = userInfo["id"];

            console.log(idUserLogin);
          } else {
            this.alertMsg.class = "alert alert-danger";
          }

          this.serverErrorsInfo.serverErrorsInfo = false;
          this.alertMsg.showAlert = true;
          this.alertMsg.msg = response.response;
          //  console.log(this.alertMsg);
        },
        (error) => {
          //  if (error.status !== 401) {
          this.spinners.mostrarSpinner = false;

          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError("Error al intentar guardar el registro");
          }

          const errors = GetMsgFromError(error);
          console.log(errors);

          this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
            errors.errors
          );

          this.serverErrorsInfo.serverErrorsInfo = true;
          console.log(this.serverErrorsInfo);
          //  this.spinners.mostrarSpinner = false;

          // this.spinners.mostrarSpinner = false;
          window.scrollTo(0, 0);
        },
        () => {
          console.log("Finally");
          this.spinners.mostrarSpinner = false;
          //  this.mostrarVentanaConfirmacion = false;
          window.scrollTo(0, 0);
        }
      );
  }

  bloquearMedico() {
    this.alertMsg.showAlert = false;
    this.spinners.mostrarSpinner = true;

    this.apiServices
      .bloquearRegistro("pacientespotenciales/" + this.medicoId)
      .subscribe(
        (res) => {
          const response = res;
          console.log(response);

          if (response.success === "true") {
            this.alertMsg.class = "alert alert-success";
            this.estadoMedico = response.estado;
          } else {
            this.alertMsg.class = "alert alert-danger";
          }

          this.serverErrorsInfo.serverErrorsInfo = false;
          this.alertMsg.showAlert = true;
          this.alertMsg.msg = response.response;

          //  console.log(this.alertMsg);
        },
        (error) => {
          if (error.status !== 401) {
            this.alertMsg.showAlert = true;
            this.alertMsg.class = "alert alert-danger";
            this.alertMsg.msg = "Error al intentar guardar el registro";

            const errors = GetMsgFromError(error);
            console.log(errors);

            this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
              errors.errors
            );
            console.log(this.serverErrorsInfo);
            this.spinners.mostrarSpinner = false;
            // this.spinners.mostrarSpinner = false;
            // window.scrollTo(0, 0);
          } else {
            console.log("Error: " + error.status);
          }
        },
        () => {
          console.log("Finally");
          this.spinners.mostrarSpinner = false;
          window.scrollTo(0, 0);
        }
      );
  }

  getData(medicoId: number) {
    // console.log(itemId);
    console.log({ medicoId: medicoId });
    if (medicoId !== undefined && Number(medicoId) > 0) {
      let urlParams = "pacientespotenciales/" + medicoId;
      // urlParams += 'id/' + medicoId;
      // let item: Item;
      this.apiServices
        .getData(urlParams, "Pacientespotenciales", false)
        .subscribe(
          (data) => {
            console.log({ registroEncontrado: this.registroEncontrado });
            console.log(data);
            if (
              data !== null &&
              data.success !== undefined &&
              data.success === true
            ) {
              const item = data["Pacientespotenciales"];
              console.log(data);
              console.log({ registroEncontrado: this.registroEncontrado });
              this.registroEncontrado = true;

              //  Set Form Values
              this.cboTipoId.patchValue(item.tipodocumento_id);
              this.txtIdentificacion.patchValue(item.identificacion);
              this.txtNombres.patchValue(item.nombres);
              this.txtApellidos.patchValue(item.apellidos);
              this.txtEmail.patchValue(item.email);
              this.txtCelular.patchValue(item.celular);
              this.medicoId = item.id;
              this.nombreCompleto = ": " + item.nombres + " " + item.apellidos;
              this.usuarioId = item.id;
              // this.estadoMedico = item.estado;
              //  this.fotos.original = item.foto;
            } else {
              this.registroEncontrado = false;
            }

            this.spinnerActivo = false;
          },
          (error) => {
            if (error.status !== 401) {
              console.log(error);
              const errors = GetMsgFromError(error);
              this.alertMsg.showAlert = true;
              this.alertMsg.class = "alert alert-danger";
              this.alertMsg.msg = "" + errors.msg;
              this.spinnerActivo = false;
              this.registroEncontrado = false;

              //  console.log(errors);

              this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
                errors.errors
              );
              console.log(this.serverErrorsInfo);
            } else {
              console.log("Error: " + error.status);
            }
          },
          () => {
            console.log("Finally");
            //  this.loadedData = true;
            // this.spinners.mostrarSpinner = false;
          }
        );
    } else {
      //  this.loadingData = false;
      this.spinnerActivo = false;
      this.registroEncontrado = false;
      this.alertMsg.showAlert = true;
      this.alertMsg.class = "alert alert-danger";
      this.alertMsg.msg = "Error al intentar cargar el registro";
    }
  }

  deshabilitarControles(omitir = []) {
    // this.desBtnGuardar = true;
    Object.keys(this.formData.controls).forEach((element) => {
      if (!omitir.includes(element)) {
        this.formData.get(element).disable();
      }
    });
  }

  public btnCerrarVentanaConfirmacion() {
    this.mostrarVentanaConfirmacion = false;
  }
  public btnCerrarVentanaDetalle() {
    this.OutputCerrarVentana.emit(true);
  }
  public showSuccess(msg: string): void {
    this.notificationService.show({
      appendTo: this.appendTo,
      content: "" + msg,
      hideAfter: 600,
      cssClass: "button-notification",
      animation: { type: "fade", duration: 400 },
      position: { horizontal: "center", vertical: "top" },
      type: { style: "success", icon: true },
      closable: true,
    });
  }

  public showError(msg: string): void {
    this.notificationService.show({
      appendTo: this.appendTo,
      content: "" + msg,
      hideAfter: 600,
      cssClass: "button-notification",
      animation: { type: "fade", duration: 400 },
      position: { horizontal: "center", vertical: "top" },
      type: { style: "error", icon: true },
      closable: true,
    });
  }

  mostrarMsgError(msg: string) {
    this.alertMsg.class = "alert alert-danger";
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = "alert alert-success";
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  onFileSelect(event) {
    const file = (event.target as HTMLInputElement).files[0];

    //  console.log(event.target.files.length);
    // console.log(event.target.files);
    // console.log(event.target.value);
    // if (event.target.files.length > 0) {
    // const file = event.target.files[0];
    console.log(file);
    //this.formData.get('txtFoto').setValue(file);
    //}
  }
  convertToPaciente() {
    console.log(this.medicoId);
    if (this.medicoId !== undefined && Number(this.medicoId) > 0) {
      let urlParams = "pacientespotenciales/" + this.medicoId;
      // urlParams += 'id/' + medicoId;
      // let item: Item;
      this.apiServices
        .getData(urlParams, "Pacientespotenciales", false)
        .subscribe(
          (data) => {
            if (
              data !== null &&
              data.success !== undefined &&
              data.success === true
            ) {
              const item = data["Pacientespotenciales"];
              console.log(item.identificacion);

              const formData = new FormData();

              formData.append("tipodocumento_id", item.tipodocumento_id);
              let identifi = "_" + item.identificacion;
              // console.log(identifi);
              formData.append("identificacion", item.identificacion); //hay que
              formData.append("nombres", item.nombres);
              formData.append("apellidos", item.apellidos);
              formData.append("celular", item.celular);
              formData.append("email", item.email); //hay que
              formData.append("fecha_nacimiento", "2001-01-01");
              formData.append("edad", "20");
              formData.append("escolaridad_id", "23");
              formData.append("country_id", "49");
              formData.append("departamentoresidencia_id", "24");
              formData.append("ciudadresidencia_id", "1009");
              formData.append("ciudad_residencia", "");
              formData.append("genero_id", "9");
              formData.append("gruposanguineo_id", "1");
              formData.append("direccion", "Cra.");
              formData.append("eps_id", "19");
              formData.append("fondopension_id", "8");
              formData.append("ocupacion_id", "409");
              formData.append("estadocivil_id", "1");
              formData.append("estado", "Activo");
              formData.append("num_hijos", "0");
              formData.append("notas", "");

              this.apiServices
                .postFormData(formData, "pacientespotenciales/passtopaciente")
                .subscribe(
                  (res) => {
                    const response = res;
                    //  //console.log(response);
                    // this.serverErrorsInfo.serverErrorsInfo = false;

                    if (
                      response !== null &&
                      response.success !== undefined &&
                      response.success === true
                    ) {
                      // this.alertMsg.class = 'alert alert-success';
                      // this.limpiarControles();
                      // this.submitted = false;
                      // this.serverErrorsInfo.serverErrorsInfo = false;
                    } else {
                      // this.alertMsg.class = 'alert alert-danger';
                    }

                    // this.alertMsg.showAlert = true;
                    // this.alertMsg.msg = response.response;
                  },
                  (error) => {
                    //  if (error.status !== 401) {
                    //   this.spinners.mostrarSpinner = false;

                    // this.spinners.spinnerSave = false;

                    if (error.error.response !== undefined) {
                      // this.mostrarMsgError(error.error.response);
                    } else {
                      // this.mostrarMsgError('Error al intentar guardar el registro');
                    }

                    // const errors = GetMsgFromError(error);
                    // console.log(errors);

                    // this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
                    //   errors.errors
                    // );

                    // this.serverErrorsInfo.serverErrorsInfo = true;
                    // console.log(this.serverErrorsInfo);
                    //  this.spinners.mostrarSpinner = false;

                    // this.spinners.mostrarSpinner = false;
                    window.scrollTo(0, 0);
                  },
                  () => {
                    //console.log('Finally');
                    // this.spinners.spinnerSave = false;
                    // window.scrollTo(0, 0);
                  }
                );
            }

            this.spinnerActivo = false;
          },
          (error) => {
            if (error.status !== 401) {
              console.log(error);
              const errors = GetMsgFromError(error);
              this.alertMsg.showAlert = true;
              this.alertMsg.class = "alert alert-danger";
              this.alertMsg.msg = "" + errors.msg;
              this.spinnerActivo = false;
              this.registroEncontrado = false;

              //  console.log(errors);

              this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
                errors.errors
              );
              console.log(this.serverErrorsInfo);
            } else {
              console.log("Error: " + error.status);
            }
          },
          () => {
            //  this.loadedData = true;
            // this.spinners.mostrarSpinner = false;
          }
        );
    }
    const formData = new FormData();
    this.apiServices
      .putFormData(
        formData,
        "pacientespotenciales/bloqueopotencial/" + this.medicoId
      )
      .subscribe(
        (res) => {
          const response = res;

          console.log(response);

          if (
            response !== null &&
            response.success !== undefined &&
            response.success === true
          ) {
            const item = response["Pacientespotenciales"];
            this.alertMsg.class = "alert alert-success";
            this.ValueChanged.emit(true);
            this.submitted = false;
            // const usuarioId = item.id;
            this.serverErrorsInfo.serverErrorsInfo = false;

            setTimeout(() => this.router.navigate(["/ListadoPacientes"]), 1000);

            let userInfo = this.apiServices.getUserInfoFromToken();
            userInfo = userInfo.userInfo;
            const idUserLogin = userInfo["id"];

            console.log(idUserLogin);
          } else {
            this.alertMsg.class = "alert alert-danger";
          }

          this.serverErrorsInfo.serverErrorsInfo = false;
          this.alertMsg.showAlert = true;
          this.alertMsg.msg = response.response;
          //  console.log(this.alertMsg);
        },
        (error) => {
          //  if (error.status !== 401) {
          this.spinners.mostrarSpinner = false;

          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError("Error al intentar guardar el registro");
          }

          const errors = GetMsgFromError(error);
          console.log(errors);

          this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
            errors.errors
          );

          this.serverErrorsInfo.serverErrorsInfo = true;
          console.log(this.serverErrorsInfo);
          //  this.spinners.mostrarSpinner = false;

          // this.spinners.mostrarSpinner = false;
          window.scrollTo(0, 0);
        },
        () => {
          console.log("Finally");
          this.spinners.mostrarSpinner = false;

          //  this.mostrarVentanaConfirmacion = false;
          window.scrollTo(0, 0);
        }
      );
  }
}
