import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { ServiciosService } from '../../servicios/servicios.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-validar-credenciales',
  templateUrl: './validar-credenciales.component.html',
  styleUrls: ['./validar-credenciales.component.css']
})
export class ValidarCredencialesComponent implements OnInit, AfterViewInit {
  
  formData: FormGroup;
  submitted = false;
  @Output() btnLogin = new EventEmitter<any>();
  @Input() tipoUsuario = 'MEDICO';
  @Input() origen = '';
  @Input() origenId = 0;
  @Input() url = 'users/validarCredencialesMedicos';
  @Input() tituloBoton = 'Validar credenciales';

  constructor(
    private formBuilder: FormBuilder,
    private apiServices: ServiciosService) {
  }

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  }

  ngOnInit(): void {

    this.formData = this.formBuilder.group({
      txtPassword: ['', Validators.required],
      txtUsername: ['', Validators.required]
    });
  }

  ngAfterViewInit() {

  }

  iniciarSesion() {
  //  localStorage.clear();
  //  sessionStorage.clear();
    this.submitted = true;
    if (this.formData.invalid) { return; }

    const username = this.formData.get('txtUsername').value;
    const password = this.formData.get('txtPassword').value;

    const formData = new FormData();
    formData.set('username', username);
    formData.set('password', password);
    formData.set('origen', this.origen);
    formData.set('id', ''+this.origenId);

    try {
      this.apiServices.postFormData(formData, this.url)
        .subscribe(data => {
          console.log(data);
          if (data.success === true) {
            console.log(data['User']);
            this.btnLogin.emit(data['User']);
            this.alertMsg.showAlert = false;
          } else {
            this.btnLogin.emit(null)
            this.alertMsg.showAlert = true;
            this.alertMsg.class = 'alert alert-danger';
            this.alertMsg.msg = 'Usuario o contraseña incorrecta';
          }
        }, (error) => {
          this.alertMsg.showAlert = true;
          this.alertMsg.class = 'alert alert-danger';
          if(error.error.response !== undefined) {
            this.alertMsg.msg = error.error.response;
          } else {
            this.alertMsg.msg = 'Error al intentar validar las credenciales';
          }
          this.btnLogin.emit(null);
        }, () => {
          console.log('finally');
        });
    } catch (e) {
      console.log(e);
      this.btnLogin.emit(null);
    }

  }

  get txtUsername() {
    return this.formData.get('txtUsername');
  }

  get txtPassword() {
    return this.formData.get('txtPassword');
  }
}
