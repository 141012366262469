import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {
  MaxLength,
  OnlyIntegerNumbers,
  OneDecimal,
  MinLength
} from 'src/app/validators/CustomValidators';
import { ServiciosService } from '../../servicios/servicios.service';
import { serverErrorsInfo } from 'src/app/utilities/serverErrorsInfo';
import { GetMsgFromError } from 'src/app/functions/SharedFunctions';
import { environment } from 'src/environments/environment';
import { TimeSelectorComponent } from '@progress/kendo-angular-dateinputs';

@Component({
  selector: 'app-form-formulas',
  templateUrl: './form-formulas.component.html',
  styleUrls: ['./form-formulas.component.css']
})
export class FormFormulasComponent implements OnInit, AfterContentInit {

  pacienteId = 0;
  formulaId = 0;
  formData: FormGroup;
  submitted = false;
  serverErrorsInfo: any = {};
  desBtnGuardar = false;
  cargandoDatos = false;
  registroEncontrado = false;
  nombrePaciente = '';
  esAlergico = false;
  tipoUsuario = null;
  saltarValidacion = false;
  medicoId = 0;

  accionGuardarEditar = '';
  nuevoRegistro = false;
  tituloFormulario = '';
  tipoAccion = '';
  dataAcciones: any;
  errorDeCarga = false;
  codigoFormula = 0;
  url = 'formulas';
  msgNotificacion = '';
  skipLocationChange = false;
  tienePdf = false;
  urlPdf = '';
  accionEjecutandose = false;
  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  };

  urlOrigen = {
    origen: '',
    tieneOrigen: false,
    url: '',
    id: 0
  }

  origen = '';
  codigoOrigen = 0;
  medicoIdReg = 0;
  
  baseUrl2 = environment.baseUrl2;

  verVentanas = {
    verVentanaConfirmarGuardar: false,
    titVenCon: '',
    tetVenCon: '',
    accion: '',
    verVentanaCredenciales: false,
    verVentanaConfirmarCredenciales: false,
    titVentanaConfirmarCredenciales: '',
    tetVentanaConfirmarCredenciales: '',
    verVentanaImprimir: false,
    titVentanaImprimir: 'Generar PDF',
    texVentanaImprimir: '¿Desea generar un PDF?'
  }


  dataPaciente: any;
  datosPacienteCargados = false;

  // ngModelTxtCiuResPaciente = '';




  constructor(private router: Router,
    private apiServices: ServiciosService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {

    this.cargandoDatos = true;

    this.formData = this.formBuilder.group({

      txtFormula: ['', [Validators.required, MaxLength(false, 6000)]],
      txtNotas: ['', [MaxLength(true, 6000)]],

    });

    window.scrollTo(0, 0);


    this.tipoUsuario = this.apiServices.getTipoUsuario();
    const userInfo = this.apiServices.getUserInfoFromToken();
    //  this.medicoId = userInfo.userInfo.id;
    if (this.tipoUsuario === 'MEDICO') {
      this.medicoId = userInfo.userInfo.id;
    } else {
      this.medicoId = 0;
    }

    // debugger;
  }

  isInt(value) {
    return Number.isInteger(Number(value));
  }


  ngAfterContentInit(): void {


    this.activatedRoute.queryParams.subscribe(params => {
      const tipo = params.tipo;
      const formulaId = params.formulaId;
      const pacienteId = params.pacienteId;
      console.log({ paciente_id: this.isInt(pacienteId) });
      this.registroEncontrado = false;

      this.tipoAccion = tipo;
      this.pacienteId = pacienteId;
      this.formulaId = formulaId;
      console.log({ tipoRecibido: tipo });
      //this.tipoAccion = params.tipo;

      //Determinar el orien de la incapacidad
      this.origen = params.origen;
      this.codigoOrigen = params.codigo_origen;

      if (this.origen !== undefined &&
        this.codigoOrigen !== undefined &&
        this.isInt(this.codigoOrigen)) {
        this.determinarOrigenFormula();
      }

      if (tipo === 'nuevo') {
        // this.tipoAccion = tipo;
        this.limpiarContoles();
        this.nuevoRegistro = true;
        this.tituloFormulario = 'Registrar nueva formula médica';
        this.dataAcciones = this.dataAccionesesGuardar();
        this.codigoFormula = 0;
        this.tienePdf = false;

      } else if (tipo === 'detalle') {
        //  this.tipoAccion = tipo;
        this.nuevoRegistro = false;
        this.dataAcciones = this.dataAccionesesEditar();
        this.tituloFormulario = 'Detalle de la formula médica';
      } else {
        this.errorDeCarga = true;
        //   this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar determinar el tipo de acción a realizar.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      }

      if (pacienteId === undefined && formulaId === undefined) {
        this.errorDeCarga = true;
        //   this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe 1.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      } else if (this.isInt(pacienteId) === false && tipo == 'nuevo') {
        this.errorDeCarga = true;
        console.log({ tipoRecibido2: tipo });
        //  this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe 2.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      } else if (this.isInt(formulaId) === false && tipo == 'detalle') {
        this.errorDeCarga = true;
        //  this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar cargar los datos. El ID de la Formula es incorrecto o no existe 3.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      }

      if (this.nuevoRegistro === false && this.errorDeCarga === false) {
        this.cargandoDatos = true;
        this.getData(this.formulaId);
        this.tipoUsuario = this.apiServices.getTipoUsuario();
        //  console.log({ tipoUsuario: this.apiServices.getTipoUsuario() });
      } else if (this.nuevoRegistro === true && this.errorDeCarga === false) {
        this.getPaciente(this.pacienteId);
      }

      console.log({ nuevoRegistro: this.nuevoRegistro });
    });



    // this.formData.get('txtTiempo').disable();

  }

  determinarOrigenFormula() {
    switch (this.origen) {
      case 'control':
        this.urlOrigen.tieneOrigen = true;
        this.urlOrigen.origen = 'Controles';
        this.urlOrigen.url = 'FormControles';
        this.urlOrigen.id = this.codigoOrigen;
        break;
        case 'evolucion':
        this.urlOrigen.tieneOrigen = true;
        this.urlOrigen.origen = 'Evoluciones';
        this.urlOrigen.url = 'FormEvoluciones';
        this.urlOrigen.id = this.codigoOrigen;
        break;
      case 'cirugia':
        this.urlOrigen.tieneOrigen = true;
        this.urlOrigen.origen = 'Cirugias';
        this.urlOrigen.url = 'FormCirugias';
        this.urlOrigen.id = this.codigoOrigen;
        break;
    }
  }

  get txtFormula() {
    return this.formData.get('txtFormula');
  }
  get txtNotas() {
    return this.formData.get('txtNotas');
  }

  getData(formulaId: number) {
    console.log(formulaId);
    console.log({ formulaId: formulaId })
    if (formulaId !== undefined && Number(formulaId) > 0) {
      let urlParams = 'formulas/' + formulaId;
      // urlParams += 'id/' + formulaId;
      //   urlParams += '?espid=all';
      // let item: Item;
      this.apiServices.getData(urlParams, 'Formula', false).subscribe(
        data => {
          // console.log({ registroEncontrado: this.registroEncontrado })
          // console.log(data);
          if (data.success === true && data['Formula'] !== undefined) {
            const item = data['Formula'];
            console.log(data);
            // console.log({registroEncontrado: item.paciente_id})
            this.registroEncontrado = true;
            this.formulaId = item.id;
            this.medicoIdReg = item.medico_id;
            this.pacienteId = item.paciente_id;
            //this.// = item.id;

            this.tituloFormulario = 'Detalle de la Formula médica. Código: ' + item.codigo;
            this.codigoFormula = item.codigo;

            this.txtFormula.setValue(item.formula);
            this.txtNotas.setValue(item.notas_formula);

            //  this.FormulaCodigo = '# ' + item.codigo;
            this.getPaciente(item.paciente_id);

            if (this.tipoUsuario === 'ADMIN') {
              this.habilitarControles();
              this.desBtnGuardar = false;
            } else if (!this.medicoActualHizoIngreso(this.medicoId, this.medicoIdReg, this.tipoUsuario)) {
               this.deshabilitarControles();
               this.desBtnGuardar = true;
             }


          } else {
            this.cargandoDatos = false;
            this.registroEncontrado = false;
            this.alertMsg.showAlert = true;
            this.alertMsg.class = 'alert alert-danger';
            this.alertMsg.msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe.';
          }

          //  this.cargandoDatos = false;
        },
        error => {
          console.log(error);
          const errors = GetMsgFromError(error);
          this.alertMsg.showAlert = true;
          this.alertMsg.class = 'alert alert-danger';
          this.alertMsg.msg = '' + errors.msg;
          this.cargandoDatos = false;
          this.registroEncontrado = false;

          //  console.log(errors);

          this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(errors.errors);
          console.log(this.serverErrorsInfo);
          // this.spinners.mostrarSpinner = false;
        },
        () => {
          console.log('Finally');
          //  this.loadedData = true;
          // this.spinners.mostrarSpinner = false;
        }
      );
    } else {
      //  this.loadingData = false;
      this.cargandoDatos = false;
      this.registroEncontrado = false;
      this.alertMsg.showAlert = true;
      this.alertMsg.class = 'alert alert-danger';
      this.alertMsg.msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe.';
    }
  }

  medicoActualHizoIngreso(idMedicoLogin: Number, idMedicoHistoria: Number, tipoUsuario: string) {
    console.log({ idMedicoHistoria: idMedicoHistoria });
    console.log({ idMedicoLogin: idMedicoLogin });

    if (tipoUsuario === 'MEDICO' && (idMedicoHistoria === idMedicoLogin)) {
      return true; //Si es el propietario
    }
    return false;
  }

  // get txtAyudante() {
  //   return this.formData.get('txtAyudante');
  // }

  // get txtAntibiotico() {
  //   return this.formData.get('txtAntibiotico');
  // }


  // btnAcciones_click($event) {
  //   console.log($event);
  //   switch ($event.item) {
  //     case 'listado_pacientes':
  //       this.router.navigate([$event.url], {});
  //       break;
  //     case 'historia_paciente':
  //       //this.router.navigate([$event.url], {$event.params});
  //       this.btnGotoUrl($event.url, this.pacienteId);
  //       break;
  //     case 'imprimir':
  //       // this.router.navigate([$event.url], {});
  //       break
  //   }
  // }

  // btnGotoUrl(url: string, param) {
  //   // this.verVentanaDetallePaciente = true;
  //   const queryParams = {
  //     pacienteId: this.pacienteId
  //   };
  //   this.router.navigate([url], { queryParams });
  //   return false;
  // }

  getPaciente(id: number) {

    console.log({ getPacienteId: id });

    if (id > 0) {
      let urlParams = 'pacientes/' + id;
      // urlParams += 'id/' + pacienteId;
      // urlParams += '?';

      this.apiServices.getData(urlParams, 'Paciente', false)
        .subscribe(data => {

          if (data.success === true) {
            this.dataPaciente = data['Paciente'];
            this.dataPaciente['fecha_actual'] = data.fecha_actual;
            // const fechaActual = data.fecha_actual;
            console.log(data);
            this.datosPacienteCargados = true;

            console.log(this.dataPaciente.alergico);

            if (data.Paciente.alergico.trim() === 'Si') {
              console.log('Ingrgeso a alérgico');
              this.esAlergico = true;
            } else {
              this.esAlergico = false;
            }

            this.registroEncontrado = true;

          } else {
            this.registroEncontrado = false;

            let msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe 5.';
            //   msg += 'Debe verificar que el formulario no contenga errores';
            this.mostrarMsgError(msg);
          }

          this.cargandoDatos = false;
        }, error => {
          this.cargandoDatos = false;
          this.registroEncontrado = false;
          console.log(error);
        }, () => {
          console.log('Finally');
        });
    } else {
      //  this.cargandoDatos = false;
      this.registroEncontrado = false;
      this.cargandoDatos = false;
      let msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe 6.';
      //   msg += 'Debe verificar que el formulario no contenga errores';
      this.mostrarMsgError(msg);
    }

  }




  // 1
  btnMostrarConfirmarNuevo_click() {
    this.accionGuardarEditar = 'nuevo';
    this.verVentanas.verVentanaConfirmarGuardar = true;
    this.verVentanas.titVenCon = 'Confirmación de acción';
    this.verVentanas.tetVenCon = '¿Está seguro(s)?';
  }

  // 1.1
  btnMostrarConfirmarEditar_click() {

    this.cerrarVentana();
    this.alertMsg.showAlert = false;

    /****** VALIDAR FORMULARIO ********/

    this.msgNotificacion = '';
    if (this.accionGuardarEditar === 'editar') {
      this.msgNotificacion = 'editar';
    } else {
      this.msgNotificacion = 'guardar';
    }
    this.submitted = true;
    if (this.formData.invalid) {
      this.msgNotificacion = 'Error al intentar ' + this.msgNotificacion + ' la formula médica. ';
      this.msgNotificacion += 'Verifique que el formulario no contenga errorese intente de nuevo';
      this.mostrarMsgError(this.msgNotificacion);
      window.scroll(0, 0);
      return false;
    }

    /****** FIN VALIDAR FORMULARIO */

    this.accionGuardarEditar = 'editar';
    this.verVentanas.verVentanaConfirmarGuardar = true;
    this.verVentanas.titVenCon = 'Confirmación de acción';
    this.verVentanas.tetVenCon = '¿Está seguro(s)?';
  }

  // 2
  btnConfimarGuardar_click($event) {
    this.cerrarVentana();
    this.alertMsg.showAlert = false;

    /****** VALIDAR FORMULARIO ********/

    this.msgNotificacion = '';
    if (this.accionGuardarEditar === 'editar') {
      this.msgNotificacion = 'editar';
    } else {
      this.msgNotificacion = 'guardar';
    }
    this.submitted = true;
    if (this.formData.invalid) {
      this.msgNotificacion = 'Error al intentar ' + this.msgNotificacion + ' la formula médica. ';
      this.msgNotificacion += 'Verifique que el formulario no contenga errorese intente de nuevo';
      this.mostrarMsgError(this.msgNotificacion);
      window.scroll(0, 0);
      return false;
    }

    console.log(this.tipoUsuario);
    console.log($event);

    /****** FIN VALIDAR FORMULARIO */
    if ($event === true && this.tipoUsuario === 'MEDICO') {
      this.enviarDatos();
    }
    else if ($event === true && this.tipoUsuario !== 'MEDICO' && this.saltarValidacion === false) {
      let msg = 'Se ha detectado que ';
      msg += 'su rol no es Médico. Para poder generar la Formula <br />';
      msg += 'primero debe ingresar las credenciales del médico que será asociado a la misma. <br />';
      msg += 'Presione el botón Si para continuar.';
      this.verVentanas.verVentanaConfirmarCredenciales = true;
      this.verVentanas.titVentanaConfirmarCredenciales = 'Notificación';
      this.verVentanas.tetVentanaConfirmarCredenciales = msg;
    } else if ($event === true && this.tipoUsuario !== 'MEDICO' && this.saltarValidacion === true) {
      this.enviarDatos();
    }
  }

  // 3
  btnConfirmarCredenciales_click($event) {
    // console.log($event);
    if ($event === true) {
      this.cerrarVentana();
      this.verVentanas.verVentanaCredenciales = true;
    } else {
      this.cerrarVentana();
    }
  }

  // 4
  btnLogin_click($event) {
    console.log($event);
    this.cerrarVentana();
    if ($event !== null) {
      this.medicoId = $event.id;
      this.saltarValidacion = true;
      this.enviarDatos();
    }
  }

  btnGenerarPdf_click($event) {

    if ($event !== null && $event === true) {
      console.log($event);
      console.log($event);
      if (this.tienePdf === true) {
        window.open(this.urlPdf);
      } else {
        this.generarPdf();
      }


    }

    this.cerrarVentana();

  }


  generarPdf() {
    this.accionEjecutandose = true;
    let url = 'reportes/generarpdf?origen=formulas';
    url += '&codigo=' + this.codigoFormula+'&pacienteid='+this.pacienteId
    this.apiServices.getData(url, 'Reporte', false)
      .subscribe(
        data => {
          console.log(data);

          if (data !== undefined && data.success === true) {
            this.tienePdf = true;
            this.urlPdf = this.baseUrl2 + data.path;
            this.accionEjecutandose = false;
            window.open(this.baseUrl2 + data.path);
          } else {
            this.mostrarMsgError('Error al intentar generar el PDF, Debe intentar de nuevo');
          }
          this.accionEjecutandose = false;
        }, error => {
          console.log(error);
          //  this.codigoFormula = 0;
          //  this.cerrarVentana();
          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError('Error al intentar generar el PDF, Debe intentar de nuevo');
          }
          this.accionEjecutandose = false;
        }, () => {

        });
  }

  /* validacionFormulario() {
     this.msgNotificacion = '';
     if (this.accionGuardarEditar === 'editar') {
       this.url = 'formulas/' + this.formulaId;
       this.msgNotificacion = 'editar';
     } else {
       this.msgNotificacion = 'guardar';
     }
 
     if (this.formData.invalid) {
       this.msgNotificacion = 'Error al intentar ' + this.msgNotificacion + ' la formula médica. ';
       this.msgNotificacion += 'Verifique que el formulario no contenga errorese intente de nuevo';
       this.mostrarMsgError(this.msgNotificacion);
       window.scroll(0, 0);
       return false;
     }
   }*/
  enviarDatos() {
    this.submitted = true;

    console.log(this.formData);
    //  this.submitted = true;



    //console.log({ guardarComoNueva: this.guardarComoNueva });
    //Sino es un médico no puede dejar generar la Formula

    const formData = new FormData();
    formData.append('paciente_id', this.dataPaciente.id);
    formData.append('medico_id', '' + this.medicoId);// this.apiServices.getUserInfoFromToken();

    if (this.urlOrigen.tieneOrigen) {
      formData.append('origen', this.urlOrigen.origen);
      formData.append('origen_id', '' + this.urlOrigen.id);
    }

    formData.append('formula', this.formData.get('txtFormula').value);
    formData.append('notas_formula', this.formData.get('txtNotas').value);


    console.log(this.accionGuardarEditar);


    if (this.accionGuardarEditar === 'editar') {
      this.url = 'formulas/' + this.formulaId;
      formData.append('_method', 'PUT');
    } else {
      this.url = 'formulas';
    }

    let codigo = 0;

    this.cerrarVentana();

    this.apiServices.postFormData(formData, this.url)
      .subscribe(data => {
        console.log(data);
        this.alertMsg.showAlert = true;

        if (data !== null && data.success === true) {
          this.alertMsg.class = 'alert alert-success';
          codigo = data.codigo;
         // this.codigoFormula = codigo;
          this.desBtnGuardar = true;
          this.submitted = false;

          if (this.tipoAccion === 'detalle') {
            this.habilitarControles();
            this.tienePdf = false;
          } else if (this.tipoAccion === 'nuevo') {
            this.deshabilitarControles();
          }

          this.formulaId = data.Formula.id;
          this.alertMsg.msg = data.response + '. Código: ' + codigo;
          this.tituloFormulario = 'Detalle de la Formula médica. Código: ' + codigo;

          //Generar PDF
          this.codigoFormula = codigo;
          this.verVentanas.verVentanaImprimir = true;


        } else {
          this.alertMsg.class = 'alert alert-danger';
          this.alertMsg.msg = data.response;
          this.desBtnGuardar = false;
        }



        // debugger;
      }, dataError => {
        console.log(dataError);
        if (dataError.error.response !== undefined) {
          this.mostrarMsgError(dataError.error.response);
        } else {
          this.mostrarMsgError('Error al intentar procesar los datos enviados');
        }

        this.desBtnGuardar = false;
        const errors = GetMsgFromError(dataError);
        console.log(errors);

        this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
          errors.errors
        );
        this.cerrarVentana();

        console.log(this.serverErrorsInfo);
        this.desBtnGuardar = false;
        window.scroll(0, 0);
      }, () => {
        // debugger;
        console.log('finally')
        window.scroll(0, 0);
      });
  }

  mostrarMsgError(msg: string) {
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  habilitarControles(omitir = []) {
    this.desBtnGuardar = false;
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).enable();
      }
    });

  }

  deshabilitarControles(omitir = []) {
    this.desBtnGuardar = true;
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).disable();
      }
    });
  }
  cerrarVentana() {
    this.verVentanas.verVentanaConfirmarGuardar = false;
    this.verVentanas.verVentanaConfirmarCredenciales = false;
    this.verVentanas.verVentanaCredenciales = false;
    this.verVentanas.verVentanaImprimir = false;

  }

  impiarContoles(omitir = []) {

    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).reset();
      }
    });

    // Object.keys(this.formData.controls).forEach(element => {
    //   this.formData.get(element).reset();
    // });
  }

  btnGotoUrl(url: string, params = null, skip = false) {
    // this.verVentanaDetallePaciente = true;
    console.log(url);

    if (skip === true) {
      // skipLocationChange: this.skipLocationChange
      if (params === null) {
        this.router.navigate([url], { skipLocationChange: skip });
      } else {
        const queryParams = params;
        this.router.navigate([url], { queryParams, skipLocationChange: skip });
      }

    } else {
      if (params === null) {
        this.router.navigate([url]);
      } else {
        const queryParams = params;
        this.router.navigate([url], { queryParams, });
      }
    }
    return false;
  }

  btnAcciones_click($event) {
    console.log($event);

    if ($event.item === 'listado_pacientes') {
      this.btnGotoUrl('ListadoPacientes');

    } else if ($event.item === 'historia_paciente') {
      this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id });

    } else if ($event.item === 'listado_formulas') {
      this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id });

    } else if ($event.item === 'reutilizar_datos') {
      const omitir = ['cboEstados', 'chkProrroga'];
      this.habilitarControles(omitir);
      this.alertMsg.showAlert = false;
      this.submitted = false;

    } else if ($event.item === 'limpiar_datos') {
      const omitir = ['cboEstados', 'chkProrroga'];
      this.habilitarControles(omitir);
      this.limpiarContoles(omitir);
      this.alertMsg.showAlert = false;
      this.submitted = false;

    } 
    
    // else if ($event.item === 'habilitar_edicion_editar') {
    //   //  const omitir = ['cboEstados', 'chkProrroga'];
    //   this.habilitarControles();
    //   //  this.limpiarContoles(omitir);
    //   this.alertMsg.showAlert = false;
    //   this.submitted = false;

    // }

    else if ($event.item === 'detalle_cirugia') {
      console.log(this.urlOrigen);
      if (this.urlOrigen.tieneOrigen === true && this.urlOrigen.origen==='Cirugias' ) {
        this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id, tipo: 'detalle', cirugiaId: this.urlOrigen.id });
      } else {
        alert('La formula médica no proviene de una descripción quirúrgica');
      }
    }
    else if ($event.item === 'detalle_control') {
      if (this.urlOrigen.tieneOrigen === true && this.urlOrigen.origen==='Controles' ) {
        this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id, tipo: 'detalle', controlId: this.urlOrigen.id });
      } else {
        alert('La formula médica no proviene de un control');
      }
    }
    
    else if ($event.item === 'detalle_evolucion') {
      if (this.urlOrigen.tieneOrigen === true && this.urlOrigen.origen==='Evoluciones' ) {
        this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id, tipo: 'detalle', evolucionId: this.urlOrigen.id });
      } else {
        alert('La formula médica no proviene de una evolución');
      }
    }
    else if ($event.item === 'registro_vacio') {
      this.habilitarControles();
      this.codigoFormula = 0; //Resetear el código de la formula
      this.tienePdf = false;
      this.limpiarContoles();
      console.log(this.codigoFormula);
      this.alertMsg.showAlert = false;
      this.accionGuardarEditar = 'nuevo';
      this.submitted = false;
      this.saltarValidacion = false;
     // this.urlOrigen.tieneOrigen = false;
      this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id, tipo: 'nuevo' });

    } else if ($event.item === 'imprimir') {
      console.log(this.codigoFormula);
      if (this.codigoFormula > 0) {
        this.generarPdf();
      } else if (this.codigoFormula === 0) {
        alert('Primero debe guardar');
      }

    }

  }

  limpiarContoles(omitir = []) {

    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).reset();
      }
    });

    // Object.keys(this.formData.controls).forEach(element => {
    //   this.formData.get(element).reset();
    // });
  }
  dataAccionesesGuardar() {
    return [{
      text: 'Volver al listado de pacientes', url: 'ListadoPacientes', item: 'listado_pacientes'
    }, {
      text: 'Volver a la historia clinica del paciente', url: 'HistoriaPaciente', item: 'historia_paciente'
    }, {
      text: 'Volver al historial de formulas', url: 'ListadoFormulas', item: 'listado_formulas'
    }
      , {
      text: 'Volver a la descripción quirúrgica (si existe)', url: 'FormCirugias', item: 'detalle_cirugia'
    },
    {
      text: 'Volver al control (si existe)', url: 'FormControles', item: 'detalle_control'
    },
    {
      text: 'Volver a la evolución (si existe)', url: 'FormEvoluciones', item: 'detalle_evolucion'
    },
    // , {
    //   text: 'Nueva formula médica a partir de la actual', item: 'reutilizar_datos', url: '',
    // }

    {
      text: 'Nueva formula médica vacía', item: 'registro_vacio', url: 'FormFormulas',
    }, {
      text: 'Imprimir', item: 'imprimir'
    }];
  }

  dataAccionesesEditar() {
    return [{
      text: 'Volver al listado de pacientes', url: 'ListadoPacientes', item: 'listado_pacientes'
    }, {
      text: 'Volver a la historia clinica del paciente', url: 'HistoriaPaciente', item: 'historia_paciente'
    }, {
      text: 'Volver al historial de formulas médicas', url: 'ListadoFormulas', item: 'listado_formulas'
    },
    // {
    //   text: 'Volver a la descripción quirúrgica (si existe)', url: 'FormCirugias', item: 'detalle_cirugia'
    // },
    // {
    //   text: 'Volver al control (si existe)', url: 'FormControles', item: 'detalle_control'
    // },
    // {
    //   text: 'Volver a la evolución (si existe)', url: 'FormEvoluciones', item: 'detalle_evolucion'
    // },
    {
      text: 'Nueva formula médica vacía', item: 'registro_vacio', url: 'FormFormulas',
    }, {
      text: 'Imprimir', item: 'imprimir'
    }];
  }
}

