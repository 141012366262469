import { Component, OnInit, ElementRef, ViewChild, AfterContentInit } from '@angular/core';
import { GridDataResult, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ServiciosService } from '../../servicios/servicios.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { TransferServiceService } from '../../servicios/tra';
import { ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';



@Component({
  selector: 'app-listado-medicos',
  templateUrl: './listado-medicos.component.html',
  styleUrls: ['./listado-medicos.component.css']
})
export class ListadoMedicosComponent implements OnInit, AfterContentInit {
  // Filters
  @ViewChild('txtId', { static: false }) txtId: ElementRef;
  @ViewChild('txtName', { static: false }) txtName: ElementRef;
  @ViewChild('txtReference', { static: false }) txtReference: ElementRef;
  @ViewChild(GridComponent, { static: false }) grid: GridComponent;


  // var grid = $('#Grid').data('kendoGrid')


  public gridView: GridDataResult;
  public buttonCount = 15;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  public skip = 0;
  currentPage = 1;
  public multiple = true;
  public allowUnsort = true;
  windowFilters = false;
  public sort: SortDescriptor[] = [];
  windowFormFilters: FormGroup;
  loading = true;
  queryString = '';
  queryStringTotales = '';
  showWindowFilters = false;

  medicoId = 0;
  medicoNombre = '';
  dataGridViewIndex = 0;
  dataGridViewItem: any;
  paginaInicio = 0;
  //  loading = true;

  // showItemDetails = false;




  verVentanaDetalleMedico = false;


  // dataPricesList: Array<{ id: string, name: string }>;
  // dataUnitMeasurements: Array<{ id: string, name: string }>;
  // dataCategories: Array<{ id: string, name: string }>;
  // datawarehouses: Array<{ id: string, name: string }>;
  // dataAccounts: Array<{ id: string, name: string }>;
  // dataTaxes: Array<{ id: string, name: string }>;

  public lstStatus: Array<{ text: string, value: number }> = [
    { text: 'Activo', value: 1 },
    { text: 'Inactivo', value: 0 }
  ];

  // loading = true;


  lstData: any[];
  tempLstData: any[];
  public pageSize = 10;
  totalPages = 0;
  pageCount = 0;
  gridData: any[];
  // gridData: GridDataResult;


  constructor(
    private apiServices: ServiciosService,
    private formBuilder: FormBuilder,
    private location: Location,
    //   private transfereService: TransferServiceService,
    private cd: ChangeDetectorRef,
    private router: Router) {

  }


  ngAfterContentInit() {
    setTimeout(_ => {
      const filaActiva = sessionStorage.getItem('ListadoMedicosFilaActiva');
      console.log({ filaActiva: filaActiva });
      if (filaActiva !== null) {
        console.log({ filaActiva: filaActiva });
        this.grid.expandRow(Number(filaActiva))
      }


      const paginaActiva = sessionStorage.getItem('ListadoMedicosPaginaActiva');
      console.log({ paginaActiva: paginaActiva });
      if (paginaActiva !== null) {
        this.getData('medicos?page=' + paginaActiva + '&espid=all');
      } else {
        this.getData('medicos?page=1' + '&espid=all');
      }

    }, 0);
  }
  
  ngOnInit() {
  // this.getData('medicos?page=1&espid=all');

    this.windowFormFilters = this.formBuilder.group({
      txtFiltro: ['']
    });
    window.scroll(0,0);
  }

  onSubmitFilters() {

    this.loading = true;
    this.queryString = '';

    console.log(this.windowFormFilters);
    // console.log(this.queryString);

    const valFiltro = this.windowFormFilters.get('txtFiltro').value;

    if (valFiltro.length === 0) {
      this.getData('medicos?page=1&espid=all');
      return false;
    }

    let queryString = '';
    if (this.queryString.length === 0) {

      queryString = 'medicos?page=1';
      this.queryString += '&nombres=' + valFiltro;
      this.queryString += '&identificacion=' + valFiltro;
      this.queryString += '&espid=all';

      queryString = queryString + this.queryString;
    }

    //  this.queryString = queryString;

    console.log(queryString);
    // this.itemData.purchasePrice = this.windowFormFilters.get('txtPurPre').value;
    // this.itemData.salePrice = this.windowFormFilters.get('txtSalPre').value;
    // this.itemData.status = this.windowFormFilters.get('cboStatus').value;

    // // this.itemData.unitCost = this.windowFormFilters.get('txtUnitCost').value;
    // // console.log('Formato bueno: '+this.windowFormFilters.get('txtRegDate').value);
    // let regDate = '';
    // // console.log(this.windowFormFilters.get('txtRegDate').value);
    // if (this.windowFormFilters.get('txtRegDate').value !== '' && this.windowFormFilters.get('txtRegDate').value !== null) {
    //   regDate = new Date(this.windowFormFilters.get('txtRegDate').value).toISOString().substring(0, 10);
    // }

    // let expDate = '';
    // // console.log(this.windowFormFilters.get('txtExpDate').value);
    // if (this.windowFormFilters.get('txtExpDate').value !== '' && this.windowFormFilters.get('txtExpDate').value !== null) {
    //   expDate = new Date(this.windowFormFilters.get('txtExpDate').value).toISOString().substring(0, 10);
    // }

    // this.itemData.regisrtration_date = regDate;
    // this.itemData.expirationDate = expDate;

    // this.itemData.lot = this.windowFormFilters.get('txtLot').value;

    // // Arrays
    // this.itemData.price_list = this.windowFormFilters.get('cboPriceList').value;
    // this.itemData.ivas = this.windowFormFilters.get('cboIva').value;
    // this.itemData.categories = this.windowFormFilters.get('cboCategories').value;
    // this.itemData.taxes = this.windowFormFilters.get('cboTaxes').value;
    // this.itemData.units = this.windowFormFilters.get('cboUnits').value;
    // this.itemData.warehouses = this.windowFormFilters.get('cboWareHouses').value;
    // this.itemData.accounts = this.windowFormFilters.get('cboAccounts').value;

    // console.log(this.itemData);


    // this.queryString += 'pur_price=' + this.itemData.purchasePrice;
    // this.queryString += '&';
    // this.queryString += 'salePrice=' + this.itemData.salePrice;
    // this.queryString += '&';
    // this.queryString += 'status=' + this.itemData.status;
    // this.queryString += '&';
    // this.queryString += 'reg_date=' + this.itemData.regisrtration_date;
    // this.queryString += '&';
    // this.queryString += 'exp_date=' + this.itemData.expirationDate;
    // this.queryString += '&';
    // this.queryString += 'lot=' + this.itemData.lot;
    // this.queryString += '&';
    // this.queryString += 'prid=' + this.itemData.price_list;
    // this.queryString += '&';
    // this.queryString += 'iva=' + this.itemData.ivas;
    // this.queryString += '&';
    // this.queryString += 'catid=' + this.itemData.categories;
    // this.queryString += '&';
    // this.queryString += 'taxes=' + this.itemData.taxes;
    // this.queryString += '&';
    // this.queryString += 'units=' + this.itemData.units;
    // this.queryString += '&';
    // this.queryString += 'whid=' + this.itemData.warehouses;
    // this.queryString += '&';
    // this.queryString += 'accounts=' + this.itemData.accounts;
    // this.queryString += '&';
    // this.queryString += 'name=' + this.windowFormFilters.get('txtName').value;;
    // this.queryString += '&';
    // this.queryString += 'reference=' + this.windowFormFilters.get('txtReference').value;
    // this.queryString += '&';
    // this.queryString += 'id=' + this.windowFormFilters.get('txtId').value;
    //  this.queryString += '&';
    //  this.queryString += 'page=' + page;

    // console.log(this.itemRegistrationForm.controls['wareHousesRows']);
    // console.log(this.itemRegistrationForm.controls['wareHousesRows']['controls'].length);
    // console.log(this.itemRegistrationForm.controls['winariesRows']['controls']);




    // console.log(this.queryString);

    this.getData(queryString);



    // console.log(this.dataW);

    // this.postItem(this.itemData);
  }

  btnCerrarDetalleMedico() {
    //  this.getData(this.queryStringTotales);
    this.verVentanaDetalleMedico = false;
  }

  eventoCerrarVentana($event) {
    this.verVentanaDetalleMedico = false;
  }

  eventoEditarMedico($itemEditado = false) {
    console.log($itemEditado);
    if ($itemEditado === true) {
      this.getData(this.queryStringTotales);
    }

    // console.log($event);
    //  console.log(this.grid);
    //  console.log(this.grid.data);
    // const data = this.grid.data as any;
    // data.data[this.dataGridViewIndex] = $itemEditado;
    // console.log(data.data[this.dataGridViewIndex]);
    // const newData = [];
    // let newDataLength = 0;
    // Object.keys(data.data).forEach((index) => {
    //   // console.log(item);
    //   // console.log(key);
    //   newData.push(data.data[index]);
    //   newDataLength++;
    //   //  console.log(data.data[index]);
    //   // console.log(item + ': ' + key + ' -> '+data.data[key]);
    // });


    // this.gridView = {
    //   data: newData,
    //   total: newDataLength
    // };

    //   console.log(this.gridView);


  }

  btnVerAgenda(dataItem, index) {
    const queryParams = {
      medicoId: dataItem.id
    };

    this.router.navigate(['AgendaMedicos'], { queryParams });

    return false;

  }

  btnVerDetalleMedico(dataItem, index) {
    // this.verVentanaDetalleMedico = true;
    // // dataItem.apellidos = 'xxxxxxx';
    // this.dataGridViewIndex = index;
    // this.dataGridViewItem = dataItem;
    // this.medicoId = dataItem.id;
    // this.medicoNombre = dataItem.nombre_completo;
    // this.router.navigateByUrl('/AddItem');
    //  this.router.navigateByUrl('/DetalleMedico');

    //  console.log({invoiceId: id });
    const queryParams = {
      medicoId: dataItem.id
    };

     //  this.transfereService.setData(queryParams);

   // this.router.navigateByUrl('/DetalleMedico?medicoId=' + id);

   // this.location.replaceState('/DetalleMedico?medicoId=' + id);

    this.router.navigate(['DetalleMedico'], { queryParams });

    return false;

  }

  btnAddInvoice() {
    // this.router.navigateByUrl('/AddItem');
    this.router.navigateByUrl('/AddInvoice');
  }

  showFilters() {
    if (this.showWindowFilters) {
      this.showWindowFilters = false;
    } else {
      this.showWindowFilters = true;
    }
    // this.showWindowFilters = val;
  }

  /*   getPriceList(queryString: string = '') {
      // this.list.loading = true;

      this.apiServices.getPriceList(queryString)
        .subscribe(data => {
          this.dataPricesList = data.priceList;
          console.log(this.dataPricesList);
          this.windowFilters = true;

        }, (error) => {
          console.log(error);
        });
    }

    getUnitMeasurements(queryString: string = '') {
      this.apiServices.getUnitMeasurements(queryString)
        .subscribe(data => {
          this.dataUnitMeasurements = data.unitsMeasurement;
        }, (error) => {
          console.log(error);
        });
    }

    getDataCategories(queryString: string = '') {
      this.apiServices.getDataCategories(queryString)
        .subscribe(data => {
          this.dataCategories = data.itemsCategories;
        }, (error) => {
          console.log(error);
        });
    }

    getwarehouses(queryString: string = '') {
      this.apiServices.getwarehouses(queryString)
        .subscribe(data => {
          // console.log(data);
          this.datawarehouses = data.warehouses;

        }, (error) => {
          console.log(error);
        });
    }

    getAccountingAccounts(queryString: string = '') {
      this.apiServices.getAccountingAccounts(queryString)
        .subscribe(data => {
          // console.log(data);
          this.dataAccounts = data.accountingAccounts;

        }, (error) => {
          console.log(error);
        });
    }

    getTaxes(queryString: string = '') {
      this.apiServices.getTaxes(queryString)
        .subscribe(data => {
          // console.log(data);
          this.dataTaxes = data.taxes;

        }, (error) => {
          console.log(error);
        });
    } */


  /*public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.gridData, this.state);
  }*/


  onCellClick(event) {
    // event.dataItem.name = 'hola';
    console.log(event);
    // event.sender.expandRow(event.rowIndex);
    this.apiServices.collapseAll(this.grid, this.currentPage, this.pageSize, Number(this.paginaInicio));
    sessionStorage.setItem('ListadoMedicosFilaActiva', event.index)
    sessionStorage.setItem('ListadoMedicosPaginaActiva', '' + this.currentPage)
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    console.log({ 'state': state });
    this.loading = true;
    this.skip = state.skip;
    this.pageSize = state.take;
    this.sort = state.sort;

    if (this.skip === 0) {
      this.skip = 1;
    }

    this.currentPage = Math.floor(this.skip / this.pageSize);
    this.currentPage++;
    sessionStorage.setItem('ListadoMedicosPaginaActiva', '' + this.currentPage);

    let queryString = 'medicos?page=' + this.currentPage;

    if (this.sort.length > 0) {
      const sortDir = this.sort[0].dir;
      const sortField = this.sort[0].field;
      queryString += '&sortfield=' + sortField + '&sortdir=' + sortDir;
    }

    if (this.queryString.length === 0) {
      queryString += '&espid=all';
    }

    queryString = queryString + this.queryString;
    console.log(queryString);

    this.getData(queryString);

    setTimeout(_ => {
      this.apiServices.collapseAll(this.grid, this.currentPage, this.pageSize, Number(this.paginaInicio));
    }, 0);
    //this.onSubmitFilters(this.currentPage);
  }


  /*public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.getData('?page=' + this.currentPage);
  } */

  public filterChange(filter: CompositeFilterDescriptor): void {
    console.log(filter);
  }

  // openWindowFilters() {
  //   // this.getData();

  // }

  // closeWindowFilters() {
  //   // this.windowFormFilters.reset();
  //   this.showWindowFilters = false;
  // }

  // public close(status) {
  //   // console.log(`Dialog result: ${status}`);
  //   // this.messageEvent.emit(false);
  //   this.itemDetails.showItemDetails = false;
  // }

  getData(queryString: string = '') {
    this.queryStringTotales = queryString;
    console.log({ 'queryStringTotales': this.queryStringTotales });
    this.apiServices.getData(queryString, 'medicos', false).subscribe(
      data => {
        this.lstData = this.tempLstData = data['Medicos'];

        console.log(this.lstData); 
        if (this.lstData !== null && data.success !== undefined && data.success === true) {

          this.totalPages = data.paginationInfo.count;

          this.gridView = {
            data: this.lstData,
            total: this.totalPages
          };

          // Paginación inteligente

          if (data.paginationInfo !== undefined) {
            this.pageSize = Number(data.paginationInfo.perPage);
            const page = Number(data.paginationInfo.page);
            const perPage = Number(data.paginationInfo.perPage);
            console.log((page * perPage) - perPage);
            const saltar = (page * perPage) - perPage;
            this.skip = saltar;
            this.paginaInicio = data.paginationInfo.start;
          }
        } else {
          this.gridView = {
            data: [],
            total: 0
          };
        }

        //  console.log(this.gridView);
        // this.grid.resetGroupsState();
        // this.grid.que
        this.loading = false;
        // this.loading = false;

      }, (error) => {
        console.log(error);
        this.loading = false;
        // this.loading = false;
      });


  }


  /*public sliderChange(pageIndex: number): void {
    this.skip = (pageIndex - 1) * this.pageSize;
    console.log(pageIndex);
  } */

  /*  public pageChange(event: PageChangeEvent): void {

     //  this.grid.nativeElement.notifyDataChange();

     // console.log(currentPage);


     this.skip = event.skip;
     this.pageSize = event.take;

     this.loading = true;
     this.skip = event.skip;




     /* if (this.queryString === '') {
        this.queryString += '?page=' + this.currentPage;
      } else {
        this.queryString += '&page=' + this.currentPage;
      }*/

  //if (this.totalPages > 0 && this.currentPage <= this.pageCount) {





  // this.notifyDataChange();

  // this.getData(this.queryString);
  //}

  public exportToPDF(grid: GridComponent): void {
    grid.saveAsPDF();
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  btnClick() {
    // this.router.navigateByUrl('/AddItem');
    this.router.navigateByUrl('/AddInvoice');
  }


  searchItemById() {
    this.currentPage = 1;
    this.loading = true;
    let queryString = '';
    let id = '';
    let name = '';
    let reference = '';

    if (this.txtId.nativeElement.value.trim()) {
      id = 'id=' + this.txtId.nativeElement.value.trim();
    } else {
      id = '';
    }

    if (this.txtName.nativeElement.value.trim()) {
      name = 'name=' + this.txtName.nativeElement.value.trim();
    } else {
      name = '';
    }

    if (this.txtReference.nativeElement.value.trim()) {
      reference = 'reference=' + this.txtReference.nativeElement.value.trim();
    } else {
      reference = '';
    }

    if (id !== '' || name !== '' || reference !== '') {
      queryString = '?' + id + '&' + name + '&' + reference;
    }

    // this.getData();
    const page = 1;
    //this.onSubmitFilters(page);
    this.skip = 0;

    // console.log(this.currentPage);
  }

  applyFilters() {

  }

  // viewItem(id, rowIndex) {
  //   console.log(id);

  //   // this.itemDetails.itemId = id;
  //   this.loading = true;
  //   this.getItem(id);
  // }


  deleteItem(id, rowIndex) {
    // console.log($event);
    console.log(rowIndex);

    // this.grid.collapseRow(rowIndex);



    this.loading = true;
    this.apiServices.deleteData('medicos', id).subscribe(
      data => {

        console.log(data);
        this.grid.collapseRow(rowIndex);
        // delete this.gridData['lastname'];
        /* let ind = 0;
         // const filtered = this.gridData.filter(function (el) { return el.id == 'id'; });
         this.lstData.forEach(item => {
           if (item.id === id) {
             this.gridView.data.splice(this.lstData.indexOf(item), 1);
           }

           // console.log(item);
         });


         // this.gridView.data.splice(index, 1);
        // this.cd.detectChanges();

         //this.lstData = this.tempLstData = data.items;
         // this.gridData = process(this.lstData, this.state);
         // console.log(data.paginationInfo.count);
         // this.totalPages = data.paginationInfo.count;
         // this.pageCount = data.paginationInfo.pageCount;
         // console.log(this.totalPages);
         // this.lstData.length = this.totalPages;
         // this.gridData = [];
         // this.gridData = this.lstData;
         // this.lstData.length = this.totalPages;
         // console.log(this.gridData);

         // this.view = this.lstData;
         // this.notifyDataChange();
         /* this.gridData = {
            data: orderBy(this.lstData, this.sort),
            total: this.totalPages
          };*/

        //  this.onSubmitFilters(this.currentPage);
        // this.cd.detectChanges();

        //  console.log(this.gridView);
        // this.grid.resetGroupsState();
        // this.grid.que
        this.loading = false;
        // this.loading = false;

      }, (error) => {
        console.log(error);
        this.loading = false;

        // this.loading = false;
      }, () => {
        this.loading = false;
      });

  }

  showAll() {
    this.skip = 0;
    this.windowFormFilters.reset();
    this.loading = true;

    this.getData('medicos');
  }

  // receiveMessageFromViewItem($event) {
  //   console.log($event);
  //   this.itemDetails.showItemDetails = $event;
  // }


  // getItem(id) {
  //   const queryString = 'items/view?id=' + id + '&whid=all&accounts=all&catid=all&prid=all&iva=all&units=all&tax=all';
  //   this.apiServices.getData(queryString, 'items', false)
  //     .subscribe(data => {



  //       this.itemDetails.newData = data.items[0];

  //       // console.log(data.items[0].length);

  //       if (this.itemDetails.newData) {

  //         this.itemDetails.newData['pricelists'].forEach(element => {
  //           this.itemDetails.pricelists.push({ 'name': element.name, 'value': element.PricelistsItems.value });
  //         });

  //         this.itemDetails.taxes = [];
  //         this.itemDetails.taxes.push({
  //           'name': this.itemDetails.newData['tax'].name,
  //           'value': this.itemDetails.newData['tax'].value
  //         });

  //         this.itemDetails.newData['warehouses'].forEach(element => {
  //           this.itemDetails.warehouses.push({
  //             'name': element.name,
  //             'quantity': element.ItemsWarehouses.available_quantity
  //           });
  //         });

  //         this.itemDetails.windowTitle = 'Detalle del artículo: ' + this.itemDetails.newData.id;

  //         this.itemDetails.showItemDetails = true;
  //       } else {
  //         //  this.loading = false;
  //         this.wNotification.status = true;
  //         this.wNotification.title = 'Error';
  //         this.wNotification.textMsg = 'No se puede cargar los detalles del artículo';
  //       }

  //     }, (error: any) => {
  //       console.log(error);
  //     }, () => {
  //       this.loading = false;
  //       console.log('final');
  //     });
  // }

  // enableDisableItem(item, status) {
  //   // console.log(item);
  //   const formData = new FormData();

  //   if (status === 'Activo') {
  //     formData.append('active', '0');
  //     //  item.status = 'Inactivo';
  //   } else {
  //     formData.append('active', '1');
  //     // item.status = 'Activo';
  //   }

  //   formData.append('_method', 'PUT');

  //   this.apiServices.postFormData(formData, 'items/' + item.id)
  //     .subscribe(res => {
  //       // console.log(res);
  //       this.wNotification.textMsg = res.response;
  //       this.wNotification.status = true;
  //       this.wNotification.buttonMsg = 'Cerrar';

  //       // console.log(parseInt(res.item.active, 10));
  //       // console.log(res.response );
  //       // console.log(Boolean(res.response)) ;

  //       if (Boolean(res.response) === true) {

  //         if (parseInt(res.item.active, 10) === 1) {
  //           item.status = 'Activo';
  //           // console.log('Entro aquí 1');
  //         } else if (parseInt(res.item.active, 10) === 0) {
  //           //  console.log('Entro aquí 2');
  //           item.status = 'Inactivo';
  //         }

  //       }

  //     }, (error: any) => {
  //       console.log(error);
  //     }, () => {
  //       console.log(status);
  //     });
  // }

  // closeWindowNotification() {
  //   this.wNotification.textMsg = '';
  //   this.wNotification.status = false;
  // }

  // closeWindowAdjustment() {
  //   this.wAdjustment.textMsg = '';
  //   this.wAdjustment.status = false;
  // }

  // openWindowAdjustment(id) {
  //   this.wAdjustment.disableBtn = true;
  //   this.wAdjustment.status = true;
  //   this.wAdjustment.itemId = id;
  //   this.wAdjustment.buttonMsg = 'Adjustar';
  // }


  // valueChangeItemWareHouses(value): void {
  //   if (value !== undefined && value > 0) {
  //     this.wAdjustment.disableBtn = false;
  //     this.urlParams.wareHouseId = value;
  //     console.log({ 'item id': value });
  //   } else {
  //     this.wAdjustment.disableBtn = true;
  //   }
  // }

  // goToInventoryAdjustment(id) {

  //   this.urlParams.itemID = id;
  //   // routerLink='/InventoryAdjustments'
  //   this.router.navigate(['AddInventoryAdjustment'], { queryParams: this.urlParams }); // here 'About' is path
  // }

  // goToInvoiceDetails(id) {
  //   console.log({ 'invoiceId': id });
  //   const queryParams = {
  //     invoiceId: id
  //   };

  //   //    this.transfereService.setData(queryParams);

  //   // this.router.navigateByUrl('/EditItem?itemId=' + id);

  //   // this.location.replaceState('/EditItem?itemId=' + id);

  //   this.router.navigate(['InvoiceDetails'], { queryParams: queryParams });

  //   return false;
  // }

  // goToRegisterPayment(id) {
  //   // console.log({ 'invoiceId': id });
  //   const queryParams = {
  //     invoiceId: id
  //   };

  //   ///  this.transfereService.setData(queryParams);

  //   // this.router.navigateByUrl('/EditItem?itemId=' + id);

  //   // this.location.replaceState('/EditItem?itemId=' + id);

  //   this.router.navigate(['RegisterPayment'], { queryParams: queryParams });

  //   return false;
  // }

}
