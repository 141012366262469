import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { GridDataResult, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ServiciosService } from '../../servicios/servicios.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { TransferServiceService } from '../../servicios/tra';
import { ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-ventana-buscar-paciente',
  templateUrl: './ventana-buscar-paciente.component.html',
  styleUrls: ['./ventana-buscar-paciente.component.css']
})
export class VentanaBuscarPacienteComponent implements OnInit {


  // Filters
  @ViewChild('txtId', { static: false }) txtId: ElementRef;
  @ViewChild('txtName', { static: false }) txtName: ElementRef;
  @ViewChild('txtReference', { static: false }) txtReference: ElementRef;
  @ViewChild(GridComponent, { static: false }) grid: GridComponent;
  @Output() messageEvent = new EventEmitter<any>();


  // var grid = $('#Grid').data('kendoGrid')


  public gridView: GridDataResult;
  public buttonCount = 15;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  public skip = 0;
  currentPage = 1;
  public multiple = true;
  public allowUnsort = true;
  windowFilters = false;
  public sort: SortDescriptor[] = [];
  formData: FormGroup;
  loading = true;
  queryString = '';
  queryStringTotales = '';
  showWindowFilters = false;

  medicoId = 0;
  medicoNombre = '';
  dataGridViewIndex = 0;
  dataGridViewItem: any;

  verVentanaDetalleMedico = false;

  public lstStatus: Array<{ text: string, value: number }> = [
    { text: 'Activo', value: 1 },
    { text: 'Inactivo', value: 0 }
  ];

  // loading = true;


  lstData: any[];
  tempLstData: any[];
  public pageSize = 10;
  totalPages = 0;
  pageCount = 0;
  gridData: any[];
  // gridData: GridDataResult;


  constructor(
    private apiServices: ServiciosService,
    private formBuilder: FormBuilder,
    private location: Location,
    //   private transfereService: TransferServiceService,
    private cd: ChangeDetectorRef,
    private router: Router) {

  }

  ngOnInit() {
    this.getData('pacientes?page=1');

    this.formData = this.formBuilder.group({
      txtFiltro: ['']
    });
  }

  selectItem(dataItem) {

    this.queryString = '';
    this.queryString += 'pacientes/' + dataItem.id;

    this.apiServices.getData(this.queryString, 'Paciente').subscribe(
      data => {
        this.lstData = this.tempLstData = data;
      //  console.log(data);
        this.messageEvent.emit(this.lstData);
        this.loading = false;
      //  console.log(this.lstData);
      }, (error) => {
        console.log(error);
        this.loading = false;
        // this.loading = false;
      });

    // console.log(dataItem);

  }

  onSubmitFilters() {

    this.loading = true;
    this.queryString = '';

    console.log(this.formData);
    // console.log(this.queryString);

    const valFiltro = this.formData.get('txtFiltro').value;

    if (valFiltro.length === 0) {
      this.getData('pacientes?page=1');
      return false;
    }

    let queryString = '';
    if (this.queryString.length === 0) {

      queryString = 'pacientes?page=1';
      this.queryString += '&nombres=' + valFiltro;
      this.queryString += '&identificacion=' + valFiltro;
     // this.queryString += '&genero=all';

      queryString = queryString + this.queryString;
    }

    console.log(queryString);
    this.getData(queryString);
  }

  btnCerrarDetalleMedico() {
    //  this.getData(this.queryStringTotales);
    this.verVentanaDetalleMedico = false;
  }

  eventoCerrarVentana($event) {
    this.verVentanaDetalleMedico = false;
  }

  eventoEditarMedico($itemEditado = false) {
    console.log($itemEditado);
    if ($itemEditado === true) {
      this.getData(this.queryStringTotales);
    }

  }


  btnVerDetalleMedico(dataItem, index) {
    this.verVentanaDetalleMedico = true;
    // dataItem.apellidos = 'xxxxxxx';
    this.dataGridViewIndex = index;
    this.dataGridViewItem = dataItem;
    this.medicoId = dataItem.id;
    this.medicoNombre = dataItem.nombre_completo;


  }

  btnAddInvoice() {
    // this.router.navigateByUrl('/AddItem');
    this.router.navigateByUrl('/AddInvoice');
  }

  showFilters() {
    if (this.showWindowFilters) {
      this.showWindowFilters = false;
    } else {
      this.showWindowFilters = true;
    }
    // this.showWindowFilters = val;
  }


  onCellClick(event) {
    // event.dataItem.name = 'hola';
    console.log(event);
    // event.sender.expandRow(event.rowIndex);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    console.log({ 'state': state });

    this.skip = state.skip;
    this.pageSize = state.take;
    this.sort = state.sort;

    if (this.skip === 0) {
      this.skip = 1;
    }

    this.currentPage = Math.floor(this.skip / this.pageSize);
    this.currentPage++;


    let queryString = 'pacientes?page=' + this.currentPage;

    if (this.sort.length > 0) {
      const sortDir = this.sort[0].dir;
      const sortField = this.sort[0].field;
      queryString += '&sortfield=' + sortField + '&sortdir=' + sortDir;
    }

    // if (this.queryString.length === 0) {
    //   queryString += '&egenero=all';
    // }

    queryString = queryString + this.queryString;
    console.log(queryString);

    this.getData(queryString);


    //this.onSubmitFilters(this.currentPage);
  }


  /*public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.getData('?page=' + this.currentPage);
  } */

  public filterChange(filter: CompositeFilterDescriptor): void {
    console.log(filter);
  }


  getData(queryString: string = '') {
    this.queryStringTotales = queryString;
    console.log({ 'queryStringTotales': this.queryStringTotales });
    this.apiServices.getData(queryString, 'pacientes', false).subscribe(
      data => {
        this.lstData = this.tempLstData = data['Pacientes'];

        console.log(data);
        // this.gridData = process(this.lstData, this.state);
        // console.log(data.paginationInfo.count);
        this.totalPages = data.paginationInfo.count;


        this.gridView = {
          data: this.lstData,
          total: this.totalPages
        };

        //  console.log(this.gridView);
        // this.grid.resetGroupsState();
        // this.grid.que
        this.loading = false;
        // this.loading = false;

      }, (error) => {
        console.log(error);
        this.loading = false;
        // this.loading = false;
      });


  }


  public exportToPDF(grid: GridComponent): void {
    grid.saveAsPDF();
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  btnClick() {
    // this.router.navigateByUrl('/AddItem');
    this.router.navigateByUrl('/AddInvoice');
  }


  searchItemById() {
    this.currentPage = 1;
    this.loading = true;
    let queryString = '';
    let id = '';
    let name = '';
    let reference = '';

    if (this.txtId.nativeElement.value.trim()) {
      id = 'id=' + this.txtId.nativeElement.value.trim();
    } else {
      id = '';
    }

    if (this.txtName.nativeElement.value.trim()) {
      name = 'name=' + this.txtName.nativeElement.value.trim();
    } else {
      name = '';
    }

    if (this.txtReference.nativeElement.value.trim()) {
      reference = 'reference=' + this.txtReference.nativeElement.value.trim();
    } else {
      reference = '';
    }

    if (id !== '' || name !== '' || reference !== '') {
      queryString = '?' + id + '&' + name + '&' + reference;
    }

    // this.getData();
    const page = 1;
    //this.onSubmitFilters(page);
    this.skip = 0;

    // console.log(this.currentPage);
  }

  applyFilters() {

  }


  deleteItem(id, rowIndex) {
    // console.log($event);
    console.log(rowIndex);


    this.loading = true;
    this.apiServices.deleteData('pacientes', id).subscribe(
      data => {

        console.log(data);
        this.grid.collapseRow(rowIndex);

        this.loading = false;
        // this.loading = false;

      }, (error) => {
        console.log(error);
        this.loading = false;

        // this.loading = false;
      }, () => {
        this.loading = false;
      });

  }

  showAll($event) {
    this.skip = 0;
    this.formData.reset();
    this.loading = true;

    this.getData('pacientes');
  }


}
