// https://malcoded.com/posts/angular-reactive-form-validation/
import { AbstractControl } from '@angular/forms';

export class ImageValidator {
  static imageSizeValidator(maxSize: number) {
    return (input: AbstractControl) => {

 
      if (input.value[0] !== null && input.value[0].length > 0) {
        return input.value[0].size > maxSize ? { errorMaxSize: true } : null;
      }

      return null;
    };
  }

  static imageExtensionValidator(whiteListImageExtension: Array<string>) {
    return (input: AbstractControl) => {
      if (input.value[0]) {
        return whiteListImageExtension.includes(input.value[0].type) ? null : { errorExtension: true };
      }
      return null;
    };
  }
}

export function OneDecimal(control: AbstractControl) {
  // //console.log(control.value);
  // let regex = /^(?:\d*\.\d{1,2}|\d+)$/;
  const regex = /^(?:\d*\.\d{1,2}|\d+)$/;
  if (!regex.test(control.value)) {
    return { 'validOneDecimal': true };
  }

  return null;
}

/* Some input filters you might want to use:

Integer values (both positive and negative):
/^-?\d*$/.test(value)
Integer values (positive only):
/^\d*$/.test(value)
Integer values (positive and up to a particular limit):
/^\d*$/.test(value) && (value === "" || parseInt(value) <= 500)
Floating point values (allowing both . and , as decimal separator):
/^-?\d*[.,]?\d*$/.test(value)
Currency values (i.e. at most two decimal places):
/^-?\d*[.,]?\d{0,2}$/.test(value)
Hexadecimal values:
/^[0-9a-f]*$/i.test(value)

*/

/*export function OnlyIntegerNumbers(control: AbstractControl) {
    // //console.log(control.value);
    // let regex = '/^\d+$/';
    let regEx = new RegExp('^[0-9]*$');
    //  let regex = '/^\d*$/'; //solo valores positivos
    if (!regEx.test(control.value) || control.value.toString().length == 0) {
        return { 'validOnlyIntegerNumbers': true };
    }

    return null;
} */

export const OnlyLettersNumbers = (empty: boolean = false) => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {

    // [A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$

    const regex = /^[a-z0-9]+( [a-z0-9]+)*$/i; // Letras, Numeros y espacios en blanco
    // let regex = /^[a-z]+( [a-z]+)*$/i; //solo letras

    // //console.log("canridad "+control.value.length);
    // //console.log(""+empty);

    if (empty === true && control.value.toString() === 0) {
      return null;
    }

    if (!regex.test(control.value)) {
      return { 'validOnlyLettersNumbers': true };
    }

    return null;
  };
};

// Validar que el valor sea entero positivo y mayo que cero
// false no puede quedar vacio
// true puede quedar vacio
export const OnlyLetters = (empty: boolean = false) => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {

    // [A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$

    //const regex = /^[a-z0-9]+( [a-z0-9]+)*$/i; // Letras, Numeros y espacios en blanco
    const regex = /^[a-z]+( [a-z]+)*$/i; //solo letras

    // //console.log("canridad "+control.value.length);
    // //console.log(""+empty);

    if (empty === true && control.value.toString() === 0) {
      return null;
    }

    if (!regex.test(control.value)) {
      return { 'validOnlyLetters': true };
    }

    return null;
  };
};





// false no puede quedar vacio (por defecto)
// true puede quedar vacio

export const OnlyIntegerNumbers = (empty: boolean = false) => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {

  //    //console.log(control.value);
    //  //console.log(empty);

    if (control.value === undefined && empty === false) {
      return { 'validOnlyIntegerNumbers': true };
    } else if (control.value === null && empty === false) {
      return { 'validOnlyIntegerNumbers': true };
    } else if (control.value === null && empty === true) {
      return null;
    } else if (control.value === undefined && empty === true) {
      return null;
    } else if (empty === true && control.value.toString().length === 0) {
      return null;
    }
    // //console.log("Valor: "+control.value.toString());

    const regEx = new RegExp('^[0-9]*$');
    if (!regEx.test(control.value) || control.value.toString().length === 0) {
      //   //console.log('Esta entrando aquí');
      return { 'validOnlyIntegerNumbers': true };
    }


    return null;
  };
};

// Validar que el valor sea entero positivo y mayo que cero
// false no puede quedar vacio
// true puede quedar vacio
export const GreaterThanZero = (empty: boolean = false) => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {

    console.log({GreaterThanZero: control.value});
    //  //console.log(empty);

    if (control.value === undefined && empty === false) {
      return { greaterThanZero: true };
    } else if (control.value === null && empty === false) {
      return { greaterThanZero: true };
    } else if (control.value === null && empty === true) {
      return null;
    } else if (control.value === undefined && empty === true) {
      return null;
    } else if (empty === true && control.value.toString().length === 0) {
      return null;
    }

    if (Number(control.value) <= 0) {
      //   //console.log('Esta entrando aquí');
      return { 'greaterThanZero': true };
    }


    return null;
  };
};

// false no puede quedar vacio
// true puede quedar vacio
export const MaxLength = (empty: boolean = false, maxLength) => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {

    //  //console.log(control.value);
    //  //console.log(empty);
    // undefined
    if (control.value === undefined && empty === false) {
      //console.log('validMaxLength');
      return { 'validMaxLength': true };
    } else if (control.value === null && empty === false) {
      //console.log('validMaxLength');
      return null;
      // return { 'validMaxLength': true };
    } else if (control.value === null && empty === true) {
      return null;
    } else if (control.value === undefined && empty === true) {
      return null;
    } else if (empty === true && control.value.toString().length === 0) {
      return null;
    } else if (empty === false && control.value.toString().length > maxLength) {
      //console.log('validMaxLength');
      return { 'validMaxLength': true };
    }

    return null;
  };
};

export const IsRequired = () => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {

    // //console.log(control.value);

    if (control.value === undefined) {
      return { 'validIsRequired': true };
    } else if (control.value === null) {
      return { 'validIsRequired': true };
    }

    const val = control.value.toString().trim();

    if (val.length === 0) {
      return { 'validIsRequired': true };
    }


    return null;
  };
};

export const MinLength = (empty: boolean = false, minLength) => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {

    if (control.value === undefined && empty === false) {
      return { 'validMinLength': true };
    } else if (control.value === null && empty === false) {
      return { 'validMinLength': true };
    } else if (control.value === null && empty === true) {
      return null;
    } else if (control.value === undefined && empty === true) {
      return null;
    } else if (empty === true && control.value.toString().length === 0) {
      return null;
    } else if (empty === false && control.value.toString().length < minLength) {
      return { 'validMinLength': true };
    }

    return null;
  };
};

export const OnlyValidCharacters = (empty: boolean = false) => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    // //console.log(control.value);
    // let regex = /^(?:\d*\.\d{1,2}|\d+)$/;
    // let regex = /^[a-z0-9]+( [a-z0-9]+)*$/i; //ESTE FUNCIONA DEJA ACEPTAR MAS DE UN ESPACIO EN BLANCO

    if (empty === true && control === null) {
      return null;
    } else if (empty === true && control.value.toString().length === 0) {
      return null;
    }

    const regex = '/^([a-zA-Z0-9 _-]+)$/'; // ESTE  SOLO DEJA ACEPTAR UN ESPACIO EN BLANCO ENTRE PALABRAS

    try {
      if (regex.match(control.value)) {
        return { 'validOnlyValidCharacters': true };
      }
    } catch (e) {
      return { 'validOnlyValidCharacters': true };
    }

    return null;
  };
};


export const EmailValidator = (empty: boolean = false) => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {

    if (empty === true && control.value.toString().length === 0) {
      return null;
    }

    const regex = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

    try {
      if (regex.match(control.value)) {
        return { 'validEmail': true };
      }
    } catch (e) {
      return { 'validEmail': true };
    }

    return null;
  };
};


// const onlyIntegerNumbers = '/^(?:\d*\.\d{1,2}|\d+)$/'; // '[0-9]*';
    // const preventInvalidCharacters = '[a-zA-Z0-9][A-Za-z0-9._-]*';
    // para contraseseñas  const preventInvalidCharacters = '^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$';
 //   const preventInvalidCharacters = '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$'; //Este deja meter varios espacios en blanco


// https://alligator.io/angular/reactive-forms-custom-validator/

// Control personalizado https://almerosteyn.com/2016/03/angular2-form-validation-component
