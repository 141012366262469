import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiciosService } from '../../servicios/servicios.service';
import { EncryptionService } from '../../servicios/encryption.service';

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.css']
})
export class MenuLateralComponent implements OnInit {

  tipoUsuario = '';
  userInfo: any;
  constructor(private router: Router,
    private encryption: EncryptionService,
    private apiServices: ServiciosService) { }



  ngOnInit(): void {

    let token = this.apiServices.getToken();
    // token = this.encryption.decryptUsingAES256(token);
    let userInfo = this.apiServices.getUserInfoFromToken(token, true);
    this.userInfo = userInfo.userInfo;
    //    console.log({userInfoMenu: userInfo });

    this.tipoUsuario = userInfo.tipo.toUpperCase();
  }

  gotoUrl(url: string) {
    this.removerVariablesSessionCies();
    this.router.navigate([url]);
    return false;
  }

  btnDatosEmpresa_click(url: string) {
    this.removerVariablesSessionCies();
    this.router.navigate([url]);
    return false;
  }

  btnNuevoPaciente_click(url: string) {
    const queryParams = {
      tipo: 'nuevo'
    };
    this.router.navigate([url], { queryParams });
    return false;
  }

  btnGotoUrl_click(url: string) {
    this.removerVariablesSessionCies();
    const queryParams = {
      tipo: 'nuevo'
    };
    this.router.navigate([url], { queryParams });
    return false;
  }


  removerVariablesSessionCies() {

    this.apiServices.removerVariablesSession();
    //Pacientes
    // sessionStorage.removeItem('valFiltroListadoPacientes');
    // sessionStorage.removeItem('queryStringListadoPacientes');
    // sessionStorage.removeItem('ListadoPacientesFilaActiva');

    // //Cies
    // sessionStorage.removeItem('ListadoCiesValFiltro');
    // sessionStorage.removeItem('ListadoCiesQueryString');
    // sessionStorage.removeItem('ListadoCiesFilaActiva');

    // //Generos
    // sessionStorage.removeItem('ListadoGenerosValFiltro');
    // sessionStorage.removeItem('ListadoGenerosQueryString');
    // sessionStorage.removeItem('ListadoGenerosFilaActiva');

    // //Grupos sanguineos
    // sessionStorage.removeItem('ListadoGruposSanValFiltro');
    // sessionStorage.removeItem('ListadoGruposSanFilaActiva');
    // sessionStorage.removeItem('ListadoGruposSanQueryString');

    // //Tipos de documentos
    // sessionStorage.removeItem('ListadoTiposIdsValFiltro');
    // sessionStorage.removeItem('ListadoTiposIdsFilaActiva');
    // sessionStorage.removeItem('ListadoTiposIdsQueryString');

    // //Estados civiles
    // sessionStorage.removeItem('ListadoEstadosCivValFiltro');
    // sessionStorage.removeItem('ListadoEstadosCivQueryString');
    // sessionStorage.removeItem('ListadoEstadosCivFilaActiva');

    // //Nieveles  de escolaridad
    // sessionStorage.removeItem('ListadoNivelesEscValFiltro');
    // sessionStorage.removeItem('ListadoNivelesEscQueryString');
    // sessionStorage.removeItem('ListadoNivelesEscFilaActiva');

    // //Eps
    // sessionStorage.removeItem('ListadoEpsValFiltro');
    // sessionStorage.removeItem('ListadoEpsQueryString');
    // sessionStorage.removeItem('ListadoEpsFilaActiva');

    // //Departamentos
    // sessionStorage.removeItem('ListadoDepartamentosValFiltro');
    // sessionStorage.removeItem('ListadoDepartamentosQueryString');
    // sessionStorage.removeItem('ListadoDepartamentosFilaActiva');

    // //Fondos de pensión
    // sessionStorage.removeItem('ListadoFondosPensionValFiltro');
    // sessionStorage.removeItem('ListadoFondosPensionQueryString');
    // sessionStorage.removeItem('ListadoFondosPensionFilaActiva');

    // //Especialidades médicas
    // sessionStorage.removeItem('ListadoEspecialidadesValFiltro');
    // sessionStorage.removeItem('ListadoEspecialidadesQueryString');
    // sessionStorage.removeItem('ListadoEspecialidadesFilaActiva');

    // //Consultorios
    // sessionStorage.removeItem('ListadoConsultoriosValFiltro');
    // sessionStorage.removeItem('ListadoConsultoriosQueryString');
    // sessionStorage.removeItem('ListadoConsultoriosFilaActiva');

    //  //Tipo de citas
    //  sessionStorage.removeItem('ListadoTiposCitasValFiltro');
    // sessionStorage.removeItem('ListadoTiposCitasQueryString');
    // sessionStorage.removeItem('ListadoTiposCitasFilaActiva');

    //  //Ciudades
    //  sessionStorage.removeItem('ListadoCiudadesValFiltro');
    //  sessionStorage.removeItem('ListadoCiudadesQueryString');
    //  sessionStorage.removeItem('ListadoCiudadesFilaActiva');

    //  //Ocupaciones
    //  sessionStorage.removeItem('ListadoOcupacionesValFiltro');
    //  sessionStorage.removeItem('ListadoOcupacionesQueryString');
    //  sessionStorage.removeItem('ListadoOcupacionesFilaActiva');

    //  sessionStorage.removeItem('ListadoCountriesValFiltro');
    //         sessionStorage.removeItem('ListadoCountriesQueryString');
    //         sessionStorage.removeItem('ListadoCountriesFilaActiva');
  }
}
