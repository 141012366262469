import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { GridDataResult, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ServiciosService } from '../../servicios/servicios.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { TransferServiceService } from '../../servicios/tra';
import { ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';


@Component({
  selector: 'app-ventana-buscar-medico',
  templateUrl: './ventana-buscar-medico.component.html',
  styleUrls: ['./ventana-buscar-medico.component.css']
})
export class VentanaBuscarMedicoComponent implements OnInit {

  // Filters
  @ViewChild('txtId', { static: false }) txtId: ElementRef;
  @ViewChild('txtName', { static: false }) txtName: ElementRef;
  @ViewChild('txtReference', { static: false }) txtReference: ElementRef;
  @ViewChild(GridComponent, { static: false }) grid: GridComponent;
  @Output() messageEvent = new EventEmitter<any>();


  // var grid = $('#Grid').data('kendoGrid')


  public gridView: GridDataResult;
  public buttonCount = 15;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  public skip = 0;
  currentPage = 1;
  public multiple = true;
  public allowUnsort = true;
  windowFilters = false;
  public sort: SortDescriptor[] = [];
  formData: FormGroup;
  loading = true;
  queryString = '';
  queryStringTotales = '';
  showWindowFilters = false;

  medicoId = 0;
  medicoNombre = '';
  dataGridViewIndex = 0;
  dataGridViewItem: any;

  verVentanaDetalleMedico = false;

  public lstStatus: Array<{ text: string, value: number }> = [
    { text: 'Activo', value: 1 },
    { text: 'Inactivo', value: 0 }
  ];

  // loading = true;


  lstData: any[];
  tempLstData: any[];
  public pageSize = 10;
  totalPages = 0;
  pageCount = 0;
  gridData: any[];
  // gridData: GridDataResult;


  constructor(
    private apiServices: ServiciosService,
    private formBuilder: FormBuilder,
    private location: Location,
    //   private transfereService: TransferServiceService,
    private cd: ChangeDetectorRef,
    private router: Router) {

  }

  ngOnInit() {
    this.getData('medicos?page=1&espid=all');

    this.formData = this.formBuilder.group({
      txtFiltro: ['']
    });
  }

  selectItem(dataItem) {

    this.queryString = '';
    this.queryString += 'medicos/' + dataItem.id + '?espid=all';

    this.apiServices.getData(this.queryString, 'Medico').subscribe(
      data => {
        this.lstData = this.tempLstData = data;
        console.log(data);
        this.messageEvent.emit(this.lstData);
        this.loading = false;
      //  console.log(this.lstData);
      }, (error) => {
        console.log(error);
        this.loading = false;
        // this.loading = false;
      });

    // console.log(dataItem);

  }

  onSubmitFilters() {

    this.loading = true;
    this.queryString = '';

    console.log(this.formData);
    // console.log(this.queryString);

    const valFiltro = this.formData.get('txtFiltro').value;

    if (valFiltro.length === 0) {
      this.getData('medicos?page=1&spid=all');
      return false;
    }

    let queryString = '';
    if (this.queryString.length === 0) {

      queryString = 'medicos?page=1';
      this.queryString += '&nombres=' + valFiltro;
      this.queryString += '&identificacion=' + valFiltro;
      this.queryString += '&espid=all';

      queryString = queryString + this.queryString;
    }

    //  this.queryString = queryString;

    console.log(queryString);


    this.getData(queryString);



    // console.log(this.dataW);

    // this.postItem(this.itemData);
  }

  btnCerrarDetalleMedico() {
    //  this.getData(this.queryStringTotales);
    this.verVentanaDetalleMedico = false;
  }

  eventoCerrarVentana($event) {
    this.verVentanaDetalleMedico = false;
  }

  eventoEditarMedico($itemEditado = false) {
    console.log($itemEditado);
    if ($itemEditado === true) {
      this.getData(this.queryStringTotales);
    }

  }


  btnVerDetalleMedico(dataItem, index) {
    this.verVentanaDetalleMedico = true;
    // dataItem.apellidos = 'xxxxxxx';
    this.dataGridViewIndex = index;
    this.dataGridViewItem = dataItem;
    this.medicoId = dataItem.id;
    this.medicoNombre = dataItem.nombre_completo;


  }

  btnAddInvoice() {
    // this.router.navigateByUrl('/AddItem');
    this.router.navigateByUrl('/AddInvoice');
  }

  showFilters() {
    if (this.showWindowFilters) {
      this.showWindowFilters = false;
    } else {
      this.showWindowFilters = true;
    }
    // this.showWindowFilters = val;
  }


  onCellClick(event) {
    // event.dataItem.name = 'hola';
    console.log(event);
    // event.sender.expandRow(event.rowIndex);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    console.log({ 'state': state });

    this.skip = state.skip;
    this.pageSize = state.take;
    this.sort = state.sort;

    if (this.skip === 0) {
      this.skip = 1;
    }

    this.currentPage = Math.floor(this.skip / this.pageSize);
    this.currentPage++;


    let queryString = 'medicos?page=' + this.currentPage;

    if (this.sort.length > 0) {
      const sortDir = this.sort[0].dir;
      const sortField = this.sort[0].field;
      queryString += '&sortfield=' + sortField + '&sortdir=' + sortDir;
    }

    if (this.queryString.length === 0) {
      queryString += '&espid=all';
    }

    queryString = queryString + this.queryString;
    console.log(queryString);

    this.getData(queryString);


    //this.onSubmitFilters(this.currentPage);
  }


  /*public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.getData('?page=' + this.currentPage);
  } */

  public filterChange(filter: CompositeFilterDescriptor): void {
    console.log(filter);
  }


  getData(queryString: string = '') {
    this.queryStringTotales = queryString;
    console.log({ 'queryStringTotales': this.queryStringTotales });
    this.apiServices.getData(queryString, 'medicos', false).subscribe(
      data => {
        this.lstData = this.tempLstData = data['Medicos'];

        console.log(data);
        // this.gridData = process(this.lstData, this.state);
        // console.log(data.paginationInfo.count);
        this.totalPages = data.paginationInfo.count;
        // this.pageCount = data.paginationInfo.pageCount;
        // console.log(this.totalPages);
        // this.lstData.length = this.totalPages;
        // this.gridData = [];
        // this.gridData = this.lstData;
        // this.lstData.length = this.totalPages;
        // console.log(this.gridData);

        // this.view = this.lstData;
        // this.notifyDataChange();
        /* this.gridData = {
           data: orderBy(this.lstData, this.sort),
           total: this.totalPages
         };*/

        this.gridView = {
          data: this.lstData,
          total: this.totalPages
        };

        //  console.log(this.gridView);
        // this.grid.resetGroupsState();
        // this.grid.que
        this.loading = false;
        // this.loading = false;

      }, (error) => {
        console.log(error);
        this.loading = false;
        // this.loading = false;
      });


  }


  /*public sliderChange(pageIndex: number): void {
    this.skip = (pageIndex - 1) * this.pageSize;
    console.log(pageIndex);
  } */

  /*  public pageChange(event: PageChangeEvent): void {

     //  this.grid.nativeElement.notifyDataChange();

     // console.log(currentPage);


     this.skip = event.skip;
     this.pageSize = event.take;

     this.loading = true;
     this.skip = event.skip;




     /* if (this.queryString === '') {
        this.queryString += '?page=' + this.currentPage;
      } else {
        this.queryString += '&page=' + this.currentPage;
      }*/

  //if (this.totalPages > 0 && this.currentPage <= this.pageCount) {





  // this.notifyDataChange();

  // this.getData(this.queryString);
  //}

  public exportToPDF(grid: GridComponent): void {
    grid.saveAsPDF();
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  btnClick() {
    // this.router.navigateByUrl('/AddItem');
    this.router.navigateByUrl('/AddInvoice');
  }


  searchItemById() {
    this.currentPage = 1;
    this.loading = true;
    let queryString = '';
    let id = '';
    let name = '';
    let reference = '';

    if (this.txtId.nativeElement.value.trim()) {
      id = 'id=' + this.txtId.nativeElement.value.trim();
    } else {
      id = '';
    }

    if (this.txtName.nativeElement.value.trim()) {
      name = 'name=' + this.txtName.nativeElement.value.trim();
    } else {
      name = '';
    }

    if (this.txtReference.nativeElement.value.trim()) {
      reference = 'reference=' + this.txtReference.nativeElement.value.trim();
    } else {
      reference = '';
    }

    if (id !== '' || name !== '' || reference !== '') {
      queryString = '?' + id + '&' + name + '&' + reference;
    }

    // this.getData();
    const page = 1;
    //this.onSubmitFilters(page);
    this.skip = 0;

    // console.log(this.currentPage);
  }

  applyFilters() {

  }

  // viewItem(id, rowIndex) {
  //   console.log(id);

  //   // this.itemDetails.itemId = id;
  //   this.loading = true;
  //   this.getItem(id);
  // }


  deleteItem(id, rowIndex) {
    // console.log($event);
    console.log(rowIndex);

    // this.grid.collapseRow(rowIndex);



    this.loading = true;
    this.apiServices.deleteData('medicos', id).subscribe(
      data => {

        console.log(data);
        this.grid.collapseRow(rowIndex);
        // delete this.gridData['lastname'];
        /* let ind = 0;
         // const filtered = this.gridData.filter(function (el) { return el.id == 'id'; });
         this.lstData.forEach(item => {
           if (item.id === id) {
             this.gridView.data.splice(this.lstData.indexOf(item), 1);
           }

           // console.log(item);
         });


         // this.gridView.data.splice(index, 1);
        // this.cd.detectChanges();

         //this.lstData = this.tempLstData = data.medicos;
         // this.gridData = process(this.lstData, this.state);
         // console.log(data.paginationInfo.count);
         // this.totalPages = data.paginationInfo.count;
         // this.pageCount = data.paginationInfo.pageCount;
         // console.log(this.totalPages);
         // this.lstData.length = this.totalPages;
         // this.gridData = [];
         // this.gridData = this.lstData;
         // this.lstData.length = this.totalPages;
         // console.log(this.gridData);

         // this.view = this.lstData;
         // this.notifyDataChange();
         /* this.gridData = {
            data: orderBy(this.lstData, this.sort),
            total: this.totalPages
          };*/

        //  this.onSubmitFilters(this.currentPage);
        // this.cd.detectChanges();

        //  console.log(this.gridView);
        // this.grid.resetGroupsState();
        // this.grid.que
        this.loading = false;
        // this.loading = false;

      }, (error) => {
        console.log(error);
        this.loading = false;

        // this.loading = false;
      }, () => {
        this.loading = false;
      });

  }

  showAll($event) {
    this.skip = 0;
    this.formData.reset();
    this.loading = true;

    this.getData('medicos');
  }

  // receiveMessageFromViewItem($event) {
  //   console.log($event);
  //   this.itemDetails.showItemDetails = $event;
  // }


  // getItem(id) {
  //   const queryString = 'medicos/view?id=' + id + '&whid=all&accounts=all&catid=all&prid=all&iva=all&units=all&tax=all';
  //   this.apiServices.getData(queryString, 'medicos', false)
  //     .subscribe(data => {



  //       this.itemDetails.newData = data.medicos[0];

  //       // console.log(data.medicos[0].length);

  //       if (this.itemDetails.newData) {

  //         this.itemDetails.newData['pricelists'].forEach(element => {
  //           this.itemDetails.pricelists.push({ 'name': element.name, 'value': element.Pricelistsmedicos.value });
  //         });

  //         this.itemDetails.taxes = [];
  //         this.itemDetails.taxes.push({
  //           'name': this.itemDetails.newData['tax'].name,
  //           'value': this.itemDetails.newData['tax'].value
  //         });

  //         this.itemDetails.newData['warehouses'].forEach(element => {
  //           this.itemDetails.warehouses.push({
  //             'name': element.name,
  //             'quantity': element.medicosWarehouses.available_quantity
  //           });
  //         });

  //         this.itemDetails.windowTitle = 'Detalle del artículo: ' + this.itemDetails.newData.id;

  //         this.itemDetails.showItemDetails = true;
  //       } else {
  //         //  this.loading = false;
  //         this.wNotification.status = true;
  //         this.wNotification.title = 'Error';
  //         this.wNotification.textMsg = 'No se puede cargar los detalles del artículo';
  //       }

  //     }, (error: any) => {
  //       console.log(error);
  //     }, () => {
  //       this.loading = false;
  //       console.log('final');
  //     });
  // }

  // enableDisableItem(item, status) {
  //   // console.log(item);
  //   const formData = new FormData();

  //   if (status === 'Activo') {
  //     formData.append('active', '0');
  //     //  item.status = 'Inactivo';
  //   } else {
  //     formData.append('active', '1');
  //     // item.status = 'Activo';
  //   }

  //   formData.append('_method', 'PUT');

  //   this.apiServices.postFormData(formData, 'medicos/' + item.id)
  //     .subscribe(res => {
  //       // console.log(res);
  //       this.wNotification.textMsg = res.response;
  //       this.wNotification.status = true;
  //       this.wNotification.buttonMsg = 'Cerrar';

  //       // console.log(parseInt(res.item.active, 10));
  //       // console.log(res.response );
  //       // console.log(Boolean(res.response)) ;

  //       if (Boolean(res.response) === true) {

  //         if (parseInt(res.item.active, 10) === 1) {
  //           item.status = 'Activo';
  //           // console.log('Entro aquí 1');
  //         } else if (parseInt(res.item.active, 10) === 0) {
  //           //  console.log('Entro aquí 2');
  //           item.status = 'Inactivo';
  //         }

  //       }

  //     }, (error: any) => {
  //       console.log(error);
  //     }, () => {
  //       console.log(status);
  //     });
  // }

  // closeWindowNotification() {
  //   this.wNotification.textMsg = '';
  //   this.wNotification.status = false;
  // }

  // closeWindowAdjustment() {
  //   this.wAdjustment.textMsg = '';
  //   this.wAdjustment.status = false;
  // }

  // openWindowAdjustment(id) {
  //   this.wAdjustment.disableBtn = true;
  //   this.wAdjustment.status = true;
  //   this.wAdjustment.itemId = id;
  //   this.wAdjustment.buttonMsg = 'Adjustar';
  // }


  // valueChangeItemWareHouses(value): void {
  //   if (value !== undefined && value > 0) {
  //     this.wAdjustment.disableBtn = false;
  //     this.urlParams.wareHouseId = value;
  //     console.log({ 'item id': value });
  //   } else {
  //     this.wAdjustment.disableBtn = true;
  //   }
  // }

  // goToInventoryAdjustment(id) {

  //   this.urlParams.itemID = id;
  //   // routerLink='/InventoryAdjustments'
  //   this.router.navigate(['AddInventoryAdjustment'], { queryParams: this.urlParams }); // here 'About' is path
  // }

  // goToInvoiceDetails(id) {
  //   console.log({ 'invoiceId': id });
  //   const queryParams = {
  //     invoiceId: id
  //   };

  //   //    this.transfereService.setData(queryParams);

  //   // this.router.navigateByUrl('/EditItem?itemId=' + id);

  //   // this.location.replaceState('/EditItem?itemId=' + id);

  //   this.router.navigate(['InvoiceDetails'], { queryParams: queryParams });

  //   return false;
  // }

  // goToRegisterPayment(id) {
  //   // console.log({ 'invoiceId': id });
  //   const queryParams = {
  //     invoiceId: id
  //   };

  //   ///  this.transfereService.setData(queryParams);

  //   // this.router.navigateByUrl('/EditItem?itemId=' + id);

  //   // this.location.replaceState('/EditItem?itemId=' + id);

  //   this.router.navigate(['RegisterPayment'], { queryParams: queryParams });

  //   return false;
  // }

}
