import { Component, OnInit, ViewChild, ViewContainerRef, AfterContentInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MaxLength, OnlyIntegerNumbers } from 'src/app/validators/CustomValidators';
import { ServiciosService } from '../../servicios/servicios.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-datos-empresa',
  templateUrl: './datos-empresa.component.html',
  styleUrls: ['./datos-empresa.component.css']
})
export class DatosEmpresaComponent implements OnInit, AfterContentInit {

  @ViewChild('appendTo', { read: ViewContainerRef }) public appendTo: ViewContainerRef;

  activarKTextbox: false
  formData: FormGroup;
  submitted = false;
  nuevoRegistro = false;
  tipoUsuario: any;
  tipoAccion = '';
  tituloFormulario = '';
  errorDeCarga = false;
  cargandoDatos = true;
  accionEjecutandose = false;
  parametroId = 0;

  public lstEstados: Array<{ text: string; value: string }> = [
    { text: 'Activo', value: 'Activo' },
    { text: 'Bloqueado', value: 'Bloqueado' }
  ];

  public userImages: Array<any>;

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  }

  verVentanas = {
    verVentanaConfirmarGuardar: false,
    titVenCon: 'Confirmación de acción',
    textVenCon: '¿Está seguro(s)?',
    verVentanaConfirmarBorrar: false,
    titVenNotificacion: 'Notificación',
    textVenNotificacion: 'Registro borrado correctamente',
    ventanaNotificacion: false
  }

  constructor(
    private router: Router,
    private apiServices: ServiciosService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder) {
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    this.formData = this.formBuilder.group({
      txtRazonSocial: ['', [Validators.required, MaxLength(false, 200)]],
      txtNit: ['', [MaxLength(true, 50)]],
      txtRepresentanteLegal: ['', [MaxLength(true, 150)]],
      txtTelefono1: ['', [Validators.required, MaxLength(false, 15)]],
      txtTelefono2: ['', [MaxLength(true, 15)]],
      txtCelular1: ['', [Validators.required, MaxLength(false, 15)]],
      txtCelular2: ['', [MaxLength(true, 15)]],
      txtEmail: ['', [Validators.required, Validators.email, MaxLength(false, 150)]],
      txtSitioWeb: ['', [MaxLength(true, 300)]],
      txtDireccion: ['', [Validators.required, MaxLength(false, 200)]],
    });


  }

    // Gets
    get txtRazonSocial() { return this.formData.get('txtRazonSocial'); }
    get txtNit() { return this.formData.get('txtNit'); }
    get txtRepresentanteLegal() { return this.formData.get('txtRepresentanteLegal'); }
    get txtTelefono1() { return this.formData.get('txtTelefono1'); }
    get txtTelefono2() { return this.formData.get('txtTelefono2'); }
    get txtCelular1() { return this.formData.get('txtCelular1'); }
    get txtCelular2() { return this.formData.get('txtCelular2'); }
    get txtEmail() { return this.formData.get('txtEmail'); }
    get txtSitioWeb() { return this.formData.get('txtSitioWeb'); }
    get txtDireccion() { return this.formData.get('txtDireccion'); }

  ngAfterContentInit(): void {
        this.getData(1);
  }

  getData(parametroId: number) {

    if (parametroId !== undefined && Number(parametroId) > 0) {
      let urlParams = 'datosempresas/' + parametroId;
      // urlParams += 'id/' + formulaId;
      //   urlParams += '?espid=all';
      // let item: Item;
      this.apiServices.getData(urlParams, 'Datosempresa', false).subscribe(
        data => {
          // console.log({ registroEncontrado: this.registroEncontrado })
          // console.log(data);
          if (data.success === true && data['Datosempresa'] !== undefined) {
           
            const item = data['Datosempresa'];

            console.log(item);

            this.formData.get('txtRazonSocial').setValue(item.nombre);
            this.formData.get('txtNit').setValue(item.nit);
            this.formData.get('txtRepresentanteLegal').setValue(item.rep_legal);
            this.formData.get('txtTelefono1').setValue(item.telefono1);
            this.formData.get('txtTelefono2').setValue(item.telefono2);
            this.formData.get('txtCelular1').setValue(item.celular1);
            this.formData.get('txtCelular2').setValue(item.celular2);
            this.formData.get('txtEmail').setValue(item.email1);
            this.formData.get('txtDireccion').setValue(item.direccion1);
            this.formData.get('txtSitioWeb').setValue(item.sitio_web);

            this.tituloFormulario = 'Datos de la empresa';

         //   this.formData.get('cboEstados').setValue(item.estado);


          } else {
           
            this.mostrarMsgError('Error al intentar cargar los datos. El ID es incorrecto o no existe el registro.');
          }

          this.cargandoDatos = false;
        },
        error => {
          console.log(error);
          this.cargandoDatos = false;

          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError('Ocurrió un error al intentar cargar el registro, debe intentar de nuevo');
          }
          this.errorDeCarga = true;
        },
        () => {
          console.log('Finally');
          //  this.loadedData = true;
          // this.spinners.mostrarSpinner = false;
        }
      );
    } else {
      //  this.loadingData = false;
      this.cargandoDatos = false;
      this.mostrarMsgError('Error al intentar cargar los datos. El ID es incorrecto o no existe el registro.');
    }
  }

  isInt(value) {
    return Number.isInteger(Number(value));
  }

  limpiarContoles(omitir = []) {

    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).reset();
        this.formData.get(element).updateValueAndValidity();
      }
    });
  }

 // get cboEstados() { return this.formData.get('cboEstados'); }


  btnMostrarConfimarGuardar_click($event) {
    this.verVentanas.verVentanaConfirmarGuardar = true;
  }


  btnConfimarGuardar_click($event) {
    this.verVentanas.verVentanaConfirmarGuardar = false;
    if ($event === true) {
      this.enviarDatos();
    }
    return false;
  }


  enviarDatos() {

    this.alertMsg.showAlert = false;
    this.submitted = true;

    console.log(this.formData);

    if (this.formData.invalid) {
      // this.serverErrorsInfo.serverErrorsInfo = false;
      this.mostrarMsgError('El formulario contiene errores, debe verificar e intentar de nuevo');
      return false;
    }

    this.accionEjecutandose = true;
    // this.spinners.spinnerSave = true;
    const formData = new FormData();

    formData.append('nombre', this.formData.get('txtRazonSocial').value);
    formData.append('nit', this.formData.get('txtNit').value);
    formData.append('rep_legal', this.formData.get('txtRepresentanteLegal').value);
    formData.append('telefono1', this.formData.get('txtTelefono1').value);
    formData.append('telefono2', this.formData.get('txtTelefono2').value);
    formData.append('direccion1', this.formData.get('txtDireccion').value);
    formData.append('celular1', this.formData.get('txtCelular1').value);
    formData.append('celular2', this.formData.get('txtCelular2').value);
    formData.append('email', this.formData.get('txtEmail').value);
    formData.append('sitio_web', this.formData.get('txtSitioWeb').value);
    formData.append('_method', 'PUT');
   
    this.apiServices.putFormData(formData, 'datosempresas/1')
      .subscribe(res => {
        const response = res;

        if (response.success!== undefined && response.success === true) {
          this.alertMsg.class = 'alert alert-success';
          this.submitted = false;
        } else {
          this.alertMsg.class = 'alert alert-danger';
        }

        this.alertMsg.showAlert = true;
        this.alertMsg.msg = response.response;
        this.accionEjecutandose = false;

      }, (error) => {

        this.alertMsg.showAlert = true;
        this.alertMsg.class = 'alert alert-danger';

        if (error.error.response !== undefined) {
          this.alertMsg.msg = error.error.response;
        } else {
          this.alertMsg.msg = 'Error al intentar guardar los cambios';
        }
        this.accionEjecutandose = false;
        window.scroll(0, 0);
      },
        () => {
          console.log('Finally');
          //  this.spinners.spinnerSave = false;
          window.scroll(0, 0);
        }
      );

  }

  mostrarMsgError(msg: string) {
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  cerrarVentana($event) {
    this.alertMsg.showAlert = false;
  }

  btnEventoNotificacion($event) {
    this.verVentanas.ventanaNotificacion = false; 
    this.btnGotoUrl('ListadoCies');
  }

  // btnMostrarConfimarBorrar_click(dataItem, rowIndex) {
  //   this.verVentanas.verVentanaConfirmarBorrar = true;
  // }


  // btnConfimarBorrar_click($event) {
  //   this.verVentanas.verVentanaConfirmarBorrar = false;
  //   if ($event === true) {
  //     this.borrarRegistro(this.parametroId);
  //   }
  //   return false;
  // }

  // borrarRegistro(idRegistroBorrar: number) {
  //   this.accionEjecutandose = true;
  //   this.apiServices.deleteData('datosempresas', idRegistroBorrar, '')
  //     .subscribe(
  //       data => {
  //         console.log(data);
  //         if (data !== null && data.success === true) {
  //           this.accionEjecutandose = false;
  //           sessionStorage.removeItem('ListadoCiesValFiltro');
  //           sessionStorage.removeItem('ListadoCiesFilaActiva');
  //           sessionStorage.removeItem('ListadoCiesQueryString');
  //           this.verVentanas.ventanaNotificacion = true;     
  //         } else if (data !== null && data.response !== undefined) {
  //           this.mostrarMsgError(data.response);
  //         } else {
  //           this.mostrarMsgError('Ocurrió un error al intentar borrar el registro');
  //         }

  //         this.accionEjecutandose = false;

  //       }, (error) => {
  //         console.log(error);
  //         if (error.error.response !== undefined) {
  //           this.mostrarMsgError(error.error.response);
  //         } else {
  //           this.mostrarMsgError('Error al intentar borrar el registro, debe intentar nuevamente');
  //         }
  //         this.accionEjecutandose = false;
  //         // this.loading = false;
  //       });
  // }

  // btnEventoNotificacion($event) {
  //   this.verVentanas.ventanaNotificacion = false; 
  //   this.btnGotoUrl('ListadoCies');
  // }

  btnGotoUrl(url: string) {
    this.router.navigate([url]);
    return false;
  }


}