import { Component, OnInit, Input, Output, EventEmitter, AfterContentInit } from '@angular/core';
import { ServiciosService } from '../../servicios/servicios.service';

@Component({
  selector: 'app-ventana-adjuntar-fotos',
  templateUrl: './ventana-adjuntar-fotos.component.html',
  styleUrls: ['./ventana-adjuntar-fotos.component.css']
})
export class VentanaAdjuntarFotosComponent implements OnInit, AfterContentInit {

  @Input() width: 400;
  @Input() verVentana = false;
  @Input() tituloVentana = 'Adjuntar fotos';  
  @Input() pacienteId = '';
  // @Input() userId = '';
  // @Input() ngModelNombreFoto = '';

  // @Input() placeHolder = 'Escriba o despliegue las opciones';
  // @Output() OpenModalClick = new EventEmitter<any>();
  // @Output() ValueChanged = new EventEmitter<any>();
  @Output() CerrarVentana = new EventEmitter<any>();


  @Input() disableControlIfEmpty = false;

  fotoAdjunta = undefined;
  subiendoFoto = false;
  constructor(private apiServices: ServiciosService) { }

  alertMsgFoto = {
    showAlert: false,
    msg: '',
    class: ''
  };

  ngModelNombreFoto = '';

  ngOnInit(): void {
 
  }

  ngAfterContentInit() {

    console.log({ paciente_Id: this.pacienteId });
  }
  removerFoto($event) {
    this.fotoAdjunta = undefined;
    // const file = (event.target as HTMLInputElement).files[0];
    // console.log(file);
  }
  seleccionFotoExamenFisico(archivo) {
    //  console.log(archivo)
    if (archivo.files) {
      this.fotoAdjunta = archivo.files[0].rawFile;
    }

    // this.verVentanas.adjuntarFotos = false;
    this.alertMsgFoto.showAlert = false;
    this.alertMsgFoto.msg = '';

  }

  cerrarVentana() {
    this.CerrarVentana.emit(true)
  }

  subirFotoExamen() {
    if (this.fotoAdjunta !== undefined) {
      this.subiendoFoto = true;
      const fotoExamenFisico = new FormData();
      fotoExamenFisico.set('nombre', this.ngModelNombreFoto);
      fotoExamenFisico.set('archivo', this.fotoAdjunta);
     // fotoExamenFisico.set('codigo_historia', this.codigoHistoria);
      fotoExamenFisico.set('paciente_id', this.pacienteId);
     // fotoExamenFisico.set('paciente_id', this.pacienteId);
      fotoExamenFisico.set('tipo', 'examenFisico');

      this.apiServices.postFormData(fotoExamenFisico, 'archivos').
        subscribe(data => {
          console.log(data);
          if (data.success === true) {
            this.alertMsgFoto.showAlert = true;
            this.alertMsgFoto.class = 'alert alert-success';
            this.alertMsgFoto.msg = data.response;
          } else {
            this.alertMsgFoto.showAlert = true;
            this.alertMsgFoto.class = 'alert alert-danger';
            this.alertMsgFoto.msg = data.response;
          }
        }, data => {
          console.log(data);
          this.alertMsgFoto.showAlert = true;
          this.alertMsgFoto.class = 'alert alert-danger';
          if (data.error.response !== undefined) {
            this.alertMsgFoto.msg = data.error.response;
          } else {
            this.alertMsgFoto.msg = 'Error al intentar subir la foto';
          }
        }, () => {
          console.log('finally subirFotoExamenFisico');
          this.subiendoFoto = false;
        });

    } else {
      this.alertMsgFoto.showAlert = true;
      this.alertMsgFoto.class = 'alert alert-danger';
      this.alertMsgFoto.msg = 'Primero debe seleccionar una foto';
    }
  }

}
