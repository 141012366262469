import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServiciosService } from '../../servicios/servicios.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @Output() btnLogin = new EventEmitter<any>();
  @Output() btnCerrar = new EventEmitter<any>();

  formData: FormGroup;
  submitted = false;

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  }

  constructor(private apiServices: ServiciosService,
    private formBuilder: FormBuilder, ) { }

  ngOnInit(): void {
    this.formData = this.formBuilder.group({
      txtPassword: ['', Validators.required],
      txtUsername: ['', Validators.required]
    });
  }

  btnLogin_click() {
    this.submitted = true;
    //   console.log('Está entrando aquí');

    if (this.formData.invalid) {
      return;
    }

    const key = 'loggedId';
    //  localStorage.setItem(key, 'true');

    const formData = new FormData();
    formData.set('username', this.formData.get('txtUsername').value);
    formData.set('password', this.formData.get('txtPassword').value);


    this.apiServices.postFormData(formData, 'users/login')
      .subscribe(data => {
        console.log(data);
        this.alertMsg.showAlert = true;
        this.alertMsg.class = 'alert alert-danger';
        this.alertMsg.msg = 'Usuario o contraseña incorrecta';

        if (data === null) {
          return false;
        }

        if (data.loggedIn === true) {
          this.alertMsg.showAlert = false;
          localStorage.setItem(key, 'true');
          localStorage.setItem('userInfo', JSON.stringify(data.user));
          this.btnLogin.emit({loggedIn: true, userInfo: data.user});
        }

        this.btnLogin.emit({loggedIn: false, userInfo: null });
        this.alertMsg.msg = data.response;

      }, (error) => {
        console.log(error);
        this.alertMsg.showAlert = true;
        this.alertMsg.class = 'alert alert-danger';
        this.alertMsg.msg = 'Error al intentar iniciar sesión';
      }, () => {
        console.log('finally');
      });
  }

  btnCerrar_click() {
    this.btnCerrar.emit(true);
  }

  get txtUsername() {
    return this.formData.get('txtUsername');
  }

  get txtPassword() {
    return this.formData.get('txtPassword');
  }


}
