import { Component, OnInit, AfterViewInit, AfterContentInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MaxLength, OnlyIntegerNumbers,  MinLength } from 'src/app/validators/CustomValidators';
import { ServiciosService } from '../../servicios/servicios.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GetMsgFromError } from 'src/app/functions/SharedFunctions';
import { serverErrorsInfo } from 'src/app/utilities/serverErrorsInfo';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { environment } from 'src/environments/environment';
import { TransferService } from '../../servicios/transfer.service';
import { EncryptionService } from 'src/app/servicios/encryption.service';

@Component({
  selector: 'app-detalle-usuario',
  templateUrl: './detalle-usuario.component.html',
  styleUrls: ['./detalle-usuario.component.css']
})
export class DetalleUsuarioComponent implements OnInit, AfterViewInit, AfterContentInit {
  @ViewChild('imgFoto', { static: false }) imgFoto: ElementRef;

  registroEncontrado = false;
  itemAdmin: any;
  activarKTextbox: false
  formData: FormGroup;
  submitted = false;
  serverErrorsInfo: any = {};
  estadoUsuario: any;
  nombreCompleto = '';
  baseUrl2 = environment.baseUrl2;
  usuarioId = 0;
  imgURL: any;
  fotos = {
    mini: '',
    original: ''
  };

  tipoUsuario = '';
  userInfo: any;

  lstTiposUsuarios: Array<{ text: string, value: string }> = [
    { text: 'Administrador', value: 'ADMIN' },
    { text: 'Operador', value: 'OPERADOR' }
  ];

  lstEstados: Array<{ text: string, value: string }> = [
    { text: 'Activo(a)', value: 'Activo' },
    { text: 'Bloqueado(a)', value: 'Bloqueado' }
  ];

  loadingData = true;

  spinners = {
    spinnerSave: false
  };

  desBtnGuardar = false;

  // ngModelTxtCiuResPaciente = '';

  dataAcciones: Array<any> = [{
    text: 'Volver al listado', item: 'listado_registros', url: 'ListadoUsuarios'
  }, {
    text: 'Nueva edición', item: 'nuevo_registro', url: '/AgregarUsuario'
  }];


  public myFiles: Array<any>;
  // public userImages: Array<FileInfo>;

  ventanaConfirmacion = {
    verVenCon: false,
    titVenCon: '',
    tetVenCon: '',
    accion: '',
  }

  windowNotification = {
    msg: '',
    title: '',
    buttonMsg: 'Cerrar',
    status: false,
  };

  public userImages: Array<any>;

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  }

  public myRestrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png'],
    maxFileSize: 2097152
  };


  constructor(
    private router: Router,
    private apiServices: ServiciosService,
    private activatedRoute: ActivatedRoute,
    private encryption: EncryptionService,
    private transferService: TransferService,
    private formBuilder: FormBuilder) {
    window.scrollTo(0, 0);
    //  console.log('aqui');
    const navigation = this.router.getCurrentNavigation();
    // this.queryParams = navigation.extras.queryParams;
    //  console.log(this.queryParams);
    this.activatedRoute.queryParams.subscribe(params => {
      const usuarioId = params.usuarioId;
      this.usuarioId = usuarioId;
    });

  }
  ngAfterViewInit(): void {
    // throw new Error("Method not implemented.");
  }

  ngAfterContentInit(): void {
    this.getData(this.usuarioId);
  }

  ngOnInit(): void {
    this.formData = this.formBuilder.group({
      txtIdentificacion: ['', [Validators.required, MaxLength(false, 20), OnlyIntegerNumbers()]],
      txtNombres: ['', [Validators.required,  MaxLength(false, 100)]],
      txtPassword: ['', [MinLength(true, 10), MaxLength(true, 30)]],

      cboEstados: ['', [Validators.required]],
      txtNotas: [''],
      txtApellidos: ['', [Validators.required,  MaxLength(false, 100)]],
      txtCelular: ['', [Validators.required,MinLength(false, 10), MaxLength(false, 15), OnlyIntegerNumbers()]],
      txtEmail: ['', [Validators.required, MaxLength(false, 75), Validators.email]],
      txtDireccion: ['', [Validators.required, MaxLength(false, 200)]],
      //  txtFoto: [''],
      txtFoto: [''],
      cboTipoUsuario: ['', [Validators.required]],
    });

    this.tipoUsuario = this.apiServices.getTipoUsuario();
    const userInfo = this.apiServices.getUserInfoFromToken();
    this.userInfo = userInfo.userInfo;

    // this.formData.get('txtIdentificacion').valueChanges.subscribe(value => {
    //   this.serverErrorsInfo.identificacionError = false;
    // });

    // this.formData.get('txtEmail').valueChanges.subscribe(value => {
    //   this.serverErrorsInfo.usernameError = false;
    // });

  }

  modalAgregarEspecialidad_Click($event) {
    console.log('modalAgregarEspecialidad_Click');
  }

  // Gets
  get txtIdentificacion() { return this.formData.get('txtIdentificacion'); }
  get txtNombres() { return this.formData.get('txtNombres'); }
  get txtPassword() { return this.formData.get('txtPassword'); }
  get cboEstados() { return this.formData.get('cboEstados'); }
  get txtApellidos() { return this.formData.get('txtApellidos'); }
  get txtDireccion() { return this.formData.get('txtDireccion'); }
  get txtCelular() { return this.formData.get('txtCelular'); }
  get txtNotas() { return this.formData.get('txtNotas'); }
  get txtEmail() { return this.formData.get('txtEmail'); }
  get txtFoto() { return this.formData.get('txtFoto'); }
  get cboTipoUsuario() { return this.formData.get('cboTipoUsuario'); }

  onSubmit() {

    this.alertMsg.showAlert = false;
    this.serverErrorsInfo.serverErrorsInfo = false;
    console.log(this.formData);

    //console.log(this.formData.get('txtFoto').value[0]);

    this.submitted = true;

    if (this.formData.invalid) {
      this.mostrarMsgError('El formulario contiene errores, debe verificar e intentar de nuevo');
      this.serverErrorsInfo.serverErrorsInfo = false;
      return false;
    }

    this.spinners.spinnerSave = true;
    const formData = new FormData();

    formData.append('identificacion', this.formData.get('txtIdentificacion').value);
    formData.append('nombres', this.formData.get('txtNombres').value);
    formData.append('password', this.formData.get('txtPassword').value);
    formData.append('apellidos', this.formData.get('txtApellidos').value);
    formData.append('direccion', this.formData.get('txtDireccion').value);
    formData.append('notas', this.formData.get('txtNotas').value);
    formData.append('celular', this.formData.get('txtCelular').value);
    formData.append('email', this.formData.get('txtEmail').value);
    formData.append('estado', this.formData.get('cboEstados').value);
    //  formData.append('num_registro', this.formData.get('txtNumRegistro').value);

    console.log(this.formData.get('txtFoto').value);

    if (this.formData.get('txtFoto').value !== null) {
      if (this.formData.get('txtFoto').value[0] !== null) {
        formData.append('foto', this.formData.get('txtFoto').value[0]);
      }
    } else {
      formData.append('foto', '');
    }

    formData.append('tipo', this.formData.get('cboTipoUsuario').value);

    this.apiServices.putFormData(formData, 'admins/' + this.usuarioId)
      .subscribe(res => {
        const response = res;
        console.log(response);
        this.serverErrorsInfo.serverErrorsInfo = false;

        if (response !== null && response.success !== undefined && response.success === true) {
          this.alertMsg.class = 'alert alert-success';
          //   this.deshabilitarControles();
          //  this.desBtnGuardar = true;
          this.submitted = false;

          //     console.log(this.infoUsuario());

          this.formData.get('txtFoto').reset();
          this.estadoUsuario = response.item.estado;
          const usuarioId = response.item.id;

          //   this.usuarioId
          // window.location.reload();
          //  console.log('recargo la página');
          if (this.tipoUsuario === 'OPERADOR') {
            const omitir = ['txtPassword', 'txtFoto', 'txtCelular', 'txtDireccion'];
            this.deshabilitarControles(omitir);
          }


          let userInfo = this.apiServices.getUserInfoFromToken();
          userInfo = userInfo.userInfo;
          const idUserLogin = userInfo['id'];
          console.log(idUserLogin);
          if (Number(idUserLogin) === Number(usuarioId)) {
            console.log('Ingreso aquí');
            if (response.urlFotoMiniatura !== undefined) {
              this.fotos.mini = this.baseUrl2 + '' + response.urlFotoMiniatura;
              //   localStorage.setItem('fotoIconosPerfil',  this.fotos.mini);
              localStorage.setItem('fotoIconosPerfil', this.fotos.mini);
              this.imgFoto.nativeElement.src = this.fotos.mini;
              console.log(this.fotos.mini);
              this.transferService.setData({ data: this.fotos.mini, item: 'fotoIconosPerfil' });
            }

            if (this.formData.get('txtPassword').value !== '') {
              const password = this.formData.get('txtPassword').value;
              localStorage.setItem('LOCAL_USER2', this.encryption.encryptUsingAES256(password));
            }
          }

        } else {
          this.alertMsg.class = 'alert alert-danger';
        }

        this.alertMsg.showAlert = true;
        if (response !== null && response.response !== undefined) {
          this.alertMsg.msg = response.response;
        } else {
          this.alertMsg.msg = 'Error al intentar realizar los cambios. Debe intentar de nuevo';
        }


      }, (error) => {

        this.alertMsg.showAlert = true;
        this.alertMsg.class = 'alert alert-danger';

        console.log(error.error.response);
        if (error.error.response !== undefined) {
          this.alertMsg.msg = error.error.response;
        } else {
          this.alertMsg.msg = 'Error al intentar guardar el registro. Verifque los errores e intente de nuevo.';
        }


        const errors = GetMsgFromError(error);
        console.log(errors);
        // this.serverErrorsInfo.serverErrorsInfo = true;
        this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(errors.errors);
        // console.log(this.serverErrorsInfo.length);

        // console.log(this.serverErrorsInfo);
        this.spinners.spinnerSave = false;
        // this.spinners.spinnerSave = false;
        //window.scrollTo(0, 0);
      },
        () => {
          console.log('Finally');
          this.spinners.spinnerSave = false;
          window.scrollTo(0, 0);
        }
      );

  }

  onFileSelect(event) {

    //  const file = (event.target as HTMLInputElement).files[0];

    // console.log('Entro aquí');

    const foto = this.formData.get('txtFoto').value[0];
    const reader = new FileReader();
    // this.imagePath = files;
    reader.readAsDataURL(foto);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      // const foto1 = window.document.getElementById('txtFoto') as HTMLInputElement;
      // foto1.src = this.imgURL;
      // console.log(this.imgFoto.nativeElement.src);
      //console.log(this.imgURL);
      this.imgFoto.nativeElement.src = this.imgURL;
      // console.log(this.imgFoto.nativeElement.src);
    }
  }


  cerrarVentana($event) {
    this.alertMsg.showAlert = false;
  }

  btnGotoUrl_click(url: string) {
    try {
      this.apiServices.gotoUrl(url);
    } catch (e) {
      alert(e.message);
    }
  }

  mostrarMsgError(msg: string) {
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  btnMostrarConfirmar_click() {
    this.ventanaConfirmacion.verVenCon = true;
    this.ventanaConfirmacion.titVenCon = 'Confirmación de acción';
    this.ventanaConfirmacion.tetVenCon = '¿Está seguro(s)?';
  }

  btnConfirmarAccion($event) {
    if ($event === true) {
      this.onSubmit();
    }

    this.ventanaConfirmacion.verVenCon = false;
  }

  btnAccciones_Click($event) {
    console.log($event);
    switch ($event.item) {
      case 'nuevo_registro':
        const omitir = [];
        this.habilitarControles(omitir, false);
        // this.limpiarControles();
        this.desBtnGuardar = false;
        this.alertMsg.showAlert = false;
        this.serverErrorsInfo.serverErrorsInfo = false;
        this.submitted = false;
        window.scroll(0, 0);
        break;
      case 'listado_registros':
        this.router.navigate([$event.url], {});
        break;
      case 'imprimir':
        break;
    }
  }

  habilitarControles(omitir = [], reset = true) {
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).enable();
        if (reset === true) {
          this.formData.get(element).reset();
        }
        //  this.formData.get(element).updateValueAndValidity();
      }
    });

  }

  limpiarControles(omitir = []) {
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).setValue('');
      }
    });

  }

  deshabilitarControles(omitir = []) {
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).disable();
      }
    });
  }

  getData(usuarioId: number) {
    // console.log(itemAdminId);
    if (usuarioId !== undefined && usuarioId > 0) {
      let urlParams = 'admins/' + usuarioId;

      // let itemAdmin: Item;
      this.apiServices.getData(urlParams, 'Admin', false).subscribe(
        data => {
          this.alertMsg.showAlert = false;
          if (data.success === true) {
            console.log(data);
            this.itemAdmin = data['Admin'];
            const itemAdmin = data['Admin'];
            this.registroEncontrado = true;
            this.formData.get('txtNombres').setValue(itemAdmin.nombres);
            this.formData.get('txtIdentificacion').setValue(itemAdmin.identificacion);
            this.formData.get('txtApellidos').setValue(itemAdmin.apellidos);

            this.formData.get('txtCelular').setValue(itemAdmin.celular);
            this.formData.get('txtEmail').setValue(itemAdmin.email);
            this.formData.get('cboTipoUsuario').patchValue(itemAdmin.tipo);
            this.formData.get('txtDireccion').patchValue(itemAdmin.direccion);
            this.formData.get('txtNotas').patchValue(itemAdmin.notas);

            this.formData.get('cboEstados').patchValue(itemAdmin.estado);
            this.estadoUsuario = itemAdmin.estado;
            console.log({ estadoUsuario: this.estadoUsuario })
            this.nombreCompleto = itemAdmin.nombres + ' ' + itemAdmin.apellidos + ' (' + itemAdmin.identificacion + ')';

            if (this.tipoUsuario === 'OPERADOR') {
              const omitir = ['txtPassword', 'txtFoto', 'txtCelular', 'txtDireccion'];
              this.deshabilitarControles(omitir);
            }


            if (itemAdmin.urlFotoMiniatura) {
              this.fotos.mini = this.baseUrl2 + '' + itemAdmin.urlFotoMiniatura;
            } else {
              this.fotos.mini = '';
            }
          } else {
            this.alertMsg.showAlert = true;
            this.alertMsg.class = 'alert alert-danger';
            this.alertMsg.msg = 'Error al intentar cargar el usuario. Verifque que el usuario que intenta cargar este registrado.';
          }
        },
        error => {
          console.log(error);
          const errors = GetMsgFromError(error);
          this.alertMsg.showAlert = true;
          this.alertMsg.class = 'alert alert-danger';
          console.log(error.error.response);
          if (error.error.response !== undefined) {
            this.alertMsg.msg = error.error.response;
          } else {
            this.alertMsg.msg = 'Error al intentar cargar el usuario. Verifque que el usuario que intenta cargar este registrado.';
          }

          // this.loadedData = true;
          // this.registroEncontrado = false;
          this.loadingData = false;
          this.registroEncontrado = false;
          console.log(errors);
        },
        () => {
          this.loadingData = false;

        }
      );
    } else {
      this.loadingData = false;
      this.registroEncontrado = false;
      this.alertMsg.showAlert = true;
      this.alertMsg.class = 'alert alert-danger';
      this.alertMsg.msg = 'Error al intentar cargar el usuario';
    }
  }

  deshabilitarControlesPorRol(omitir = []) {
    this.desBtnGuardar = true;
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).disable();
      }
    });
  }
}

