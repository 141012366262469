import { Component, OnInit, ViewChild, ViewContainerRef, AfterContentInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MaxLength } from 'src/app/validators/CustomValidators';
import { ServiciosService } from '../../servicios/servicios.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-form-niveles-esc',
  templateUrl: './form-niveles-esc.component.html',
  styleUrls: ['./form-niveles-esc.component.css']
})
export class FormNivelesEscComponent implements OnInit, AfterContentInit{

  @ViewChild('appendTo', { read: ViewContainerRef }) public appendTo: ViewContainerRef;

  activarKTextbox: false
  formData: FormGroup;
  submitted = false;
  nuevoRegistro = false;
  tipoUsuario: any;
  tipoAccion = '';
  tituloFormulario = '';
  errorDeCarga = false;
  cargandoDatos = true;
  accionEjecutandose =false;
  parametroId = 0;

  public lstEstados: Array<{ text: string; value: string }> = [
    { text: 'Activo', value: 'Activo' },
    { text: 'Bloqueado', value: 'Bloqueado' }
  ];

  public userImages: Array<any>;

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  }

  verVentanas = {
    verVentanaConfirmarGuardar: false,
    titVenCon: 'Confirmación de acción',
    textVenCon: '¿Está seguro(s)?',
    verVentanaConfirmarBorrar: false,
    titVenNotificacion: 'Notificación',
    textVenNotificacion: 'Registro borrado correctamente',
    ventanaNotificacion: false
  }
  constructor(
    private router: Router,
    private apiServices: ServiciosService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder) {
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    this.formData = this.formBuilder.group({
      txtValor: ['', [Validators.required, MaxLength(false, 50)]]
   //   cboEstados: ['', [Validators.required]],
    });


  }

  ngAfterContentInit(): void {

    this.tipoUsuario = this.apiServices.getTipoUsuario();
    const userInfo = this.apiServices.getUserInfoFromToken();
    // if (this.tipoUsuario === 'MEDICO') {
    //   this.medicoId = userInfo.userInfo.id;
    // } else {
    //   this.medicoId = 0;
    // }

    this.activatedRoute.queryParams.subscribe(params => {

      const tipo = params.tipo;
      const parametroId = params.parametroId;
      this.tipoAccion = tipo;
      this.cerrarVentanas();

      if (tipo === 'nuevo') { //Nuevo registro
        // tipo = tipo;
        this.limpiarContoles();
        this.nuevoRegistro = true;
        this.tituloFormulario = 'Registrar nuevo nivel de escolaridad';
        
      //  this.formData.get('cboEstados').disable();
        this.errorDeCarga = false;
        this.cargandoDatos = false;

      } else if (tipo === 'detalle'
        && parametroId !== undefined &&
        this.isInt(parametroId) === true) { //Detalle registro

        // if (this.tipoUsuario === 'ADMIN') {
        //   this.formData.get('cboEstados').enable();
        // } else {
        //   this.formData.get('cboEstados').disable();
        // }

        //  tipo = tipo;
        this.nuevoRegistro = false;
        this.errorDeCarga = false;
        this.cargandoDatos = false;
        this.parametroId = parametroId;

        this.getData(parametroId);

      } else {
        this.errorDeCarga = true;
        this.tituloFormulario = 'Tipo de acción desconocida';
        this.cargandoDatos = false;
        const msg = 'Error al intentar determinar el tipo de acción a realizar.';
        this.mostrarMsgError(msg);
        return false;
      }

    });

  }

  getData(parametroId: number) {

    if (parametroId !== undefined && Number(parametroId) > 0) {
      let urlParams = 'escolaridads/' + parametroId;
      // urlParams += 'id/' + formulaId;
      //   urlParams += '?espid=all';
      // let item: Item;
      this.apiServices.getData(urlParams, 'Escolaridad', false).subscribe(
        data => {
          // console.log({ registroEncontrado: this.registroEncontrado })
          // console.log(data);
          if (data.success === true && data['Escolaridad'] !== undefined) {
            const item = data['Escolaridad'];
            console.log(data);

            this.formData.get('txtValor').setValue(item.nombre);
            this.tituloFormulario = 'Detalle del nivel de escolaridad: ' + item.codigo;
          //  this.formData.get('cboEstados').setValue(item.estado);

          } else {
            this.cargandoDatos = false;
            this.mostrarMsgError('Error al intentar cargar los datos. El ID es incorrecto o no existe el registro.');
          }

          //  this.cargandoDatos = false;
        },
        error => {
          console.log(error);
          this.cargandoDatos = false;

          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError('Ocurrió un error al intentar cargar el registro, debe intentar de nuevo');
          }

          this.errorDeCarga = true;
        },
        () => {
          console.log('Finally');
          //  this.loadedData = true;
          // this.spinners.mostrarSpinner = false;
        }
      );
    } else {
      //  this.loadingData = false;
      this.cargandoDatos = false;
      this.mostrarMsgError('Error al intentar cargar los datos. El ID es incorrecto o no existe el registro.');
    }
  }

  isInt(value) {
    return Number.isInteger(Number(value));
  }

  limpiarContoles(omitir = []) {

    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).reset();
        this.formData.get(element).updateValueAndValidity();
      }
    });
  }
  // Gets
  get txtValor() { return this.formData.get('txtValor'); }
 // get cboEstados() { return this.formData.get('cboEstados'); }


  btnMostrarConfimarGuardar_click($event) {
    this.verVentanas.verVentanaConfirmarGuardar = true;
  }


  btnConfimarGuardar_click($event) {
    this.verVentanas.verVentanaConfirmarGuardar = false;
    if($event===true) {
      this.enviarDatos();
    }
    return false;
  }


  enviarDatos() {
    
    this.alertMsg.showAlert = false;
    this.submitted = true;

    console.log(this.formData);

    if (this.formData.invalid) {
      // this.serverErrorsInfo.serverErrorsInfo = false;
      return false;
    }

    this.accionEjecutandose = true;
    // this.spinners.spinnerSave = true;
    const formData = new FormData();

    formData.append('parametro_id', ''+this.parametroId);
    formData.append('nombre', this.formData.get('txtValor').value);

    if (this.tipoAccion === 'nuevo') {
      formData.append('accion', 'guardar');
    } else if (this.tipoAccion === 'detalle') {
   //   formData.append('estado', this.formData.get('cboEstados').value);
      formData.append('accion', 'editar');
    } else {
      this.mostrarMsgError('Error al intentar determinar el tipo de acción');
      return false;
    }


    this.apiServices.postFormData(formData, 'escolaridads')
      .subscribe(res => {
        const response = res;

        if (response.success !== undefined && response.success === true) {
          this.alertMsg.class = 'alert alert-success';
          if (this.tipoAccion === 'nuevo') {

            sessionStorage.removeItem('ListadoNivelesEscValFiltro');
            sessionStorage.removeItem('ListadoNivelesEscQueryString');
            sessionStorage.removeItem('ListadoNivelesEscFilaActiva');

            this.limpiarContoles();
          }
          this.submitted = false;
        } else {
          this.alertMsg.class = 'alert alert-danger';
        }

        this.alertMsg.showAlert = true;
        this.alertMsg.msg = response.response;
        this.accionEjecutandose = false;

      }, (error) => {

        this.alertMsg.showAlert = true;
        this.alertMsg.class = 'alert alert-danger';

        if (error.error.response !== undefined) {
          this.alertMsg.msg = error.error.response;
        } else {
          this.alertMsg.msg = 'Error al intentar guardar el registro';
        }
        this.accionEjecutandose = false;
        window.scroll(0, 0);
      },
        () => {
          console.log('Finally');
          //  this.spinners.spinnerSave = false;
          window.scroll(0, 0);
        }
      );

  }

  mostrarMsgError(msg: string) {
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  cerrarVentana($event) {
    this.alertMsg.showAlert = false;
  }

  btnMostrarConfimarBorrar_click(dataItem, rowIndex) {
    this.verVentanas.verVentanaConfirmarBorrar = true;
  }


  btnConfimarBorrar_click($event) {
    this.verVentanas.verVentanaConfirmarBorrar = false;
    if ($event === true) {
      this.borrarRegistro(this.parametroId);
    }
    return false;
  }

  borrarRegistro(idRegistroBorrar: number) {
    this.accionEjecutandose = true;
    this.apiServices.deleteData('escolaridads', idRegistroBorrar, '')
      .subscribe(
        data => {
          console.log(data);
          if (data !== null && data.success === true) {
            this.accionEjecutandose = false;
            
            sessionStorage.removeItem('ListadoNivelesEscValFiltro');
            sessionStorage.removeItem('ListadoNivelesEscQueryString');
            sessionStorage.removeItem('ListadoNivelesEscFilaActiva');

            this.verVentanas.ventanaNotificacion = true;     
          } else if (data !== null && data.response !== undefined) {
            this.mostrarMsgError(data.response);
          } else {
            this.mostrarMsgError('Ocurrió un error al intentar borrar el registro');
          }

          this.accionEjecutandose = false;

        }, (error) => {
          console.log(error);
          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError('Error al intentar borrar el registro, debe intentar nuevamente');
          }
          this.accionEjecutandose = false;
          // this.loading = false;
        });
  }

  btnEventoNotificacion($event) {
    this.verVentanas.ventanaNotificacion = false; 
    this.btnGotoUrl('ListadoNivelesEsc');
  }

  btnGotoUrl(url: string) {
    this.router.navigate([url]);
    return false;
  }

  cerrarVentanas() {
    this.verVentanas.verVentanaConfirmarGuardar = false;
    this.verVentanas.verVentanaConfirmarBorrar = false;
    this.alertMsg.showAlert = false;
  }
}