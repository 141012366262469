import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MaxLength, OnlyIntegerNumbers, MinLength, OnlyLetters } from 'src/app/validators/CustomValidators';
import { ServiciosService } from '../../servicios/servicios.service';
import { Router } from '@angular/router';
import { GetMsgFromError } from 'src/app/functions/SharedFunctions';
import { serverErrorsInfo } from 'src/app/utilities/serverErrorsInfo';
//import { NotificationService } from '@progress/kendo-angular-notification';
import { FileRestrictions } from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-agregar-usuario',
  templateUrl: './agregar-usuario.component.html',
  styleUrls: ['./agregar-usuario.component.css']
})
export class AgregarUsuarioComponent implements OnInit {


  activarKTextbox: false
  formData: FormGroup;
  submitted = false;
  serverErrorsInfo: any = {};

  lstTiposUsuarios: Array<{ text: string, value: string }> = [
    { text: 'Administrador', value: 'ADMIN' },
    { text: 'Operador', value: 'OPERADOR' }
  ];

  spinners = {
    spinnerSave: false
  };

  desBtnGuardar = false;

  // ngModelTxtCiuResPaciente = '';

  dataAcciones: Array<any> = [{
    text: 'Volver al listado', item: 'listado_registros', url: 'ListadoUsuarios'
  }, {
    text: 'Nuevo registro', item: 'nuevo_registro', url: '/AgregarUsuario'
  }];


  public myFiles: Array<any>;
  // public userImages: Array<FileInfo>;

  ventanaConfirmacion = {
    verVenCon: false,
    titVenCon: '',
    tetVenCon: '',
    accion: '',
  }

  windowNotification = {
    msg: '',
    title: '',
    buttonMsg: 'Cerrar',
    status: false,
  };

  public userImages: Array<any>;

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  }

  public myRestrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png'],
    maxFileSize: 2097152
  };


  constructor(
    private router: Router,
    private apiServices: ServiciosService,
   // private notificationService: NotificationService,
    private formBuilder: FormBuilder) {


    window.scrollTo(0, 0);
  }



  ngOnInit(): void {
    this.formData = this.formBuilder.group({
      txtIdentificacion: ['', [Validators.required, MaxLength(false, 20), OnlyIntegerNumbers()]],
      txtNombres: ['', [Validators.required,  MaxLength(false, 100)]],
      txtPassword: ['', [Validators.required, MinLength(false, 10), MaxLength(false, 30)]],
      //    txtNumRegistro: ['', [Validators.required]],
      txtApellidos: ['', [Validators.required, MaxLength(false, 100)]],
      txtCelular: ['', [Validators.required, MinLength(false, 10), MaxLength(false, 15), OnlyIntegerNumbers()]],
      txtEmail: ['', [Validators.required, Validators.email, MaxLength(false, 75)]],
      txtDireccion: ['', [Validators.required, MaxLength(false, 200)]],
      txtNotas: [''],
      txtFoto: [''],
      cboTipoUsuario: ['', [Validators.required]],
    });

    // this.formData.get('txtIdentificacion').valueChanges.subscribe(value => {
    //   this.serverErrorsInfo.identificacionError = false;
    // });

    // this.formData.get('txtEmail').valueChanges.subscribe(value => {
    //   this.serverErrorsInfo.usernameError = false;
    // });

  }

  modalAgregarEspecialidad_Click($event) {
    console.log('modalAgregarEspecialidad_Click');
  }

  // Gets
  get txtIdentificacion() { return this.formData.get('txtIdentificacion'); }
  get txtNombres() { return this.formData.get('txtNombres'); }
  get txtPassword() { return this.formData.get('txtPassword'); }
  get txtNotas() { return this.formData.get('txtNotas'); }
  get txtApellidos() { return this.formData.get('txtApellidos'); }
  get txtDireccion() { return this.formData.get('txtDireccion'); }
  get txtCelular() { return this.formData.get('txtCelular'); }
  get txtEmail() { return this.formData.get('txtEmail'); }
  get txtFoto() { return this.formData.get('txtFoto'); }
  get cboTipoUsuario() { return this.formData.get('cboTipoUsuario'); }

  onSubmit() {

    this.alertMsg.showAlert = false;
    this.serverErrorsInfo.serverErrorsInfo = false;
    console.log(this.formData);

    //console.log(this.formData.get('txtFoto').value[0]);

    this.submitted = true;

    if (this.formData.invalid) {
      this.mostrarMsgError('El formulario contiene errores, debe verificar e intentar de nuevo');
      this.serverErrorsInfo.serverErrorsInfo = false;
      return false;
    }

    this.spinners.spinnerSave = true;
    const formData = new FormData();

    formData.append('identificacion', this.formData.get('txtIdentificacion').value);
    formData.append('nombres', this.formData.get('txtNombres').value);
    formData.append('password', this.formData.get('txtPassword').value);
    formData.append('apellidos', this.formData.get('txtApellidos').value);
    formData.append('direccion', this.formData.get('txtDireccion').value);
    formData.append('celular', this.formData.get('txtCelular').value);
    formData.append('email', this.formData.get('txtEmail').value);
    //  formData.append('num_registro', this.formData.get('txtNumRegistro').value);
    if (this.formData.get('txtFoto').value !== null) {
      if(this.formData.get('txtFoto').value[0] !== null) {
        formData.append('foto', this.formData.get('txtFoto').value[0]);
      }
    } else {
      formData.append('foto', '');
    }
    
    formData.append('tipo', this.formData.get('cboTipoUsuario').value);

    this.apiServices.postFormData(formData, 'admins')
      .subscribe(res => {
        const response = res;
        //  console.log(response);
        this.serverErrorsInfo.serverErrorsInfo = false;

        if (response.success === true) {
          this.alertMsg.class = 'alert alert-success';
          this.limpiarControles();
          this.submitted = false;
          this.serverErrorsInfo.serverErrorsInfo = false;
       //   this.desBtnGuardar = true;
          this.submitted = false;
          this.formData.get('txtFoto').reset();
        } else {
          this.alertMsg.class = 'alert alert-danger';
        }

        this.alertMsg.showAlert = true;
        this.alertMsg.msg = response.response;

      }, (error) => {

        this.alertMsg.showAlert = true;
        this.alertMsg.class = 'alert alert-danger';
     

        console.log(error.error.response);
        if(error.error.response!==undefined) {
          this.alertMsg.msg =error.error.response;
        } else {
          this.alertMsg.msg = 'Error al intentar guardar el registro. Verifque los errores e intente de nuevo.';
        }
        

        const errors = GetMsgFromError(error);
        console.log(errors);
        // this.serverErrorsInfo.serverErrorsInfo = true;
        this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(errors.errors);
       // console.log(this.serverErrorsInfo.length);
   
       // console.log(this.serverErrorsInfo);
        this.spinners.spinnerSave = false;
        // this.spinners.spinnerSave = false;
        //window.scrollTo(0, 0);
      },
        () => {
          console.log('Finally');
          this.spinners.spinnerSave = false;
          window.scrollTo(0, 0);
        }
      );

  }

  onFileSelect(event) {

    const file = (event.target as HTMLInputElement).files[0];

    console.log(file);
    //this.formData.get('txtFoto').setValue(file);
    //}
  }


  cerrarVentana($event) {
    this.alertMsg.showAlert = false;
  }

  btnGotoUrl_click(url: string) {
    try {
      this.apiServices.gotoUrl(url);
    } catch (e) {
      alert(e.message);
    }
  }

  mostrarMsgError(msg: string) {
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  btnMostrarConfirmar_click() {
    this.ventanaConfirmacion.verVenCon = true;
    this.ventanaConfirmacion.titVenCon = 'Confirmación de acción';
    this.ventanaConfirmacion.tetVenCon = '¿Está seguro(s)?';
  }

  btnConfirmarAccion($event) {
    if ($event === true) {
      this.onSubmit();
    }

    this.ventanaConfirmacion.verVenCon = false;
  }

  btnAccciones_Click($event) {
    console.log($event);
    switch ($event.item) {
      case 'nuevo_registro':
        const omitir = [];
        this.habilitarControles(omitir);
        this.limpiarControles();
        this.desBtnGuardar = false;
        this.alertMsg.showAlert = false;
        this.serverErrorsInfo.serverErrorsInfo = false;
        this.submitted = false;
        window.scroll(0, 0);
        break;
      case 'listado_registros':
        this.router.navigate([$event.url], {});
        break;
      case 'imprimir':
        break;
    }
  }

  habilitarControles(omitir = []) {
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).enable();
        this.formData.get(element).reset();
      //  this.formData.get(element).updateValueAndValidity();
      }
    });

  }

  limpiarControles(omitir = []) {
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).reset();
        this.formData.get(element).updateValueAndValidity();
      }
    });

  }

  deshabilitarControles(omitir = []) {
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).disable();
      }
    });
  }

}
