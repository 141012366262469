import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterContentInit, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { GreaterThanZero } from 'src/app/validators/CustomValidators';
import { ServiciosService } from '../../servicios/servicios.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { environment } from 'src/environments/environment';
import { GetMsgFromError } from 'src/app/functions/SharedFunctions';
import { serverErrorsInfo } from 'src/app/utilities/serverErrorsInfo';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
//import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: 'app-historia-paciente',
  templateUrl: './historia-paciente.component.html',
  styleUrls: ['./historia-paciente.component.css']
})
export class HistoriaPacienteComponent implements OnInit, AfterContentInit, AfterViewInit {
  // @ViewChildren('elementosAntecedentesRef') elementosAntecedentesRef: QueryList<ElementRef>
  // @ViewChildren('elementosRevisionSistemasRef') elementosRevisionSistemasRef: QueryList<ElementRef>
  @ViewChild('chkAlergiaRef') chkAlergiaRef: ElementRef<HTMLInputElement>;
  @ViewChild('chkHabDesEdicion', { static: false }) chkHabDesEdicion: ElementRef<HTMLInputElement>;


  formData: FormGroup;
  tituloVentanaCredenciales = 'Verificación de credenciales';
  baseUrl2 = environment.baseUrl2;
  buscandoCodigoCie = false;
  medicoIdReg = 0;
  tipoAccion = '';
  ngModelArchivo: any;
  ngModelFoto: any;

  // historiaId = 0;
  // jsonOriginal = {};
  clausurada = false;
  codigoHistoria = 0;
  historiaId = 0;
  // dataAcciones: any;
  nuevoRegistro = false;
  errorDeCarga = false;
  cargandoDatos = true;
  dataPaciente: any;
  datosPacienteCargados = false;
  saltarValidacion = false;
  medicoId = 0;
  medicioIdOriginal = 0;
  dataItem = null;
  guardarClausurarEditar = '';
  msgNotificacion = '';
  tipoUsuario = '';
  tituloFormulario = '';
  tituloBotonCredenciales = 'Validar credenciales'
  desBtnGuardar = false;
  registroEncontrado = false;
  registroGuardado = false;
  desBtnBorrarFoto = false;
  desBtnBorrarArchivo = false;

  verVentanas = {
    verVenConBorArchivo: false,
    titVenConBorArchivo: '',
    tetVenConBorArchivo: '',
    verVenConBorFoto: false,
    titVenConBorFoto: '',
    tetVenConBorFoto: '',
    adjuntarFotos: false,
    verFotos: false,
    verArchivos: false,
    tituloVentanaTexto: '',
    ventanaPlaceHolder: '',
    mostrarRegistrarNota: false,
    mostrarNumerosGineco: false,
    mostrarCalendario: false,
    mostrarBuscarCie10: false,
    mostrarVentanaLogin: false,
    adjuntarArchivos: false,
    verVentanaConfirmarGuardar: false,
    titVenCon: '',
    tetVenCon: '',
    accion: '',
    verVentanaCredenciales: false,
    verVentanaConfirmarCredenciales: false,
    titentanaConfirmarCredenciales: '',
    tetVentanaConfirmarCredenciales: '',

  }

  accionEjecutandose = false;
  medicoDelIngreso = '';

  serverErrorsInfo: any = {};

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  };

  alertMsgFoto = {
    showAlert: false,
    msg: '',
    class: ''
  };

  alertMsgArchivo = {
    showAlert: false,
    msg: '',
    class: ''
  };

  public gridView: GridDataResult;
  public buttonCount = 10;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  public skip = 0;

  public multiple = true;
  public allowUnsort = true;

  public sort: SortDescriptor[] = [];
  public pageSize = 5;
  totalPages = 0;
  pageCount = 0;

  esAlergico = false;
  submitted = false;
  loadingData = false;
  historiaClausurada: boolean;

  dataAcciones: Array<any> = [
    { text: 'Volver al listado de pacientes', url: 'ListadoPacientes', item: 'listado_pacientes' },
    { text: 'Generar formula médica', url: 'FormFormulas', item: 'agregar_formula' },
    { text: 'Generar incapacidad', url: 'FormIncapacidades', item: 'agregar_incapacidad' },
    { text: 'Imprimir', url: '', item: 'imprimir' }
  ];


  ginecoSelectedValues = [];



  badgeValues = {
    ginecoObstetricos: '',
    mestruacion: '',
    tegumentario: ''
  }

  valoresPorDefecto = [];

  pacienteId = '';

  nombrePaciente = '';
  historiaJson = '';

  lstCodigosCie10: [];

  opcionesSelecciondas = {
    patologias: '',
    traumas: ''
  }


  valoresCie10Agegados = [];

  // #region ************************ DECLARACIÓNES ENFERMEDAD ACTUAL
  get textEnfermedadActual() { return this.formData.get('txtEnfermedadActual'); }
  get textNotasEnfermedadActual() { return this.formData.get('txtNotasEnfermedadActual'); }
  // #endregion


  // #region ************************ DECLARACIÓNES MOTIVO DE CONSULTA
  get textMotivoConsulta() { return this.formData.get('txtMotivoConsulta'); }
  get textNotasMotivoConsulta() { return this.formData.get('txtNotasMotivoConsulta'); }
  //#endregion

  // #region ************************ DECLARACIÓNES PARA-CLÍNICOS
  get textParaClinicos() { return this.formData.get('txtParaClinicos'); }
  get textNotasClinicos() { return this.formData.get('txtNotasParaClinicos'); }
  // #endregion

  // #region ************************ DECLARACIÓNES ANÁLISIS
  get textAnalisis() { return this.formData.get('txtAnalisis'); }
  get textNotasAnalisis() { return this.formData.get('txtNotasAnalisis'); }
  // #endregion

  // #region ************************ DECLARACIÓNES SIGNOS VITALES
  get textTemp() { return this.formData.get('txtTemp'); }
  get textPulso() { return this.formData.get('txtPulso'); }
  get textFrecuencia() { return this.formData.get('txtFrecuencia'); }
  get textTension1() { return this.formData.get('txtTension1'); }
  get textTension2() { return this.formData.get('txtTension2'); }
  get textPeso() { return this.formData.get('txtPeso'); }
  get textTalla() { return this.formData.get('txtTalla'); }
  get textImc() { return this.formData.get('txtImc'); }
  get textNotasSignos() { return this.formData.get('txtNotasSignos'); }
  // #endregion

  // #region ************************ DECLARACIÓNES DIAGNOSTICO
  indiceCie10 = 0;
  cie10Rows: FormArray;
  get comboCie10() { return this.formData.get('cboCie10'); }
  get textdiagnosticoNotas() { return this.formData.get('txtDiagnosticoNotas'); }
  msjErrorCie10 = '';

  get textDiagnostico() { return this.formData.get('txtDiagnostico'); }
  get textDiagnosticoNotas() { return this.formData.get('txtDiagnosticoNotas'); }


  // #endregion

  // #region ************************ DECLARACIÓNES PLAN
  get textPlan() { return this.formData.get('txtPlan'); }
  get textPlanNotas() { return this.formData.get('txtPlanNotas'); }
  // #endregion

  // #region ************************ DECLARACIÓNES ANOTACIONES GENERALES
  get textNotasGenerales() { return this.formData.get('txtNotasGenerales'); }
  // get textPlanNotas() { return this.formData.get('txtPlanNotas'); }
  // #endregion
  // ginecoRows: FormArray;
  // msjErrorGinecoOpcion = '';
  // msjErrorGinecoValor = '';

  // #region ************************** DECLARACIÓNES ANTECEDENTES PERSONALES

  get textPatologicos() { return this.formData.get('txtPatologicos'); }
  get textTraumas() { return this.formData.get('txtTraumas'); }
  get textFamiliares() { return this.formData.get('txtFamiliares'); }
  get textQuirurgicos() { return this.formData.get('txtQuirurgicos'); }
  get textFarmacologicos() { return this.formData.get('txtFarmacologicos'); }
  get textAlergicos() { return this.formData.get('txtAlergicos'); }
  get textToxicos() { return this.formData.get('txtToxicos'); }
  get textEsquemaDeVacunacion() { return this.formData.get('txtEsquemaDeVacunacion'); }
  get comboGinecoObstetricosOpcion() { return this.formData.get('cboGinecoObstetricosOpcion'); }
  get comboGinecoObstetricosValor() { return this.formData.get('cboGinecoObstetricosValor'); }
  get textPlanificacion() { return this.formData.get('txtPlanificacion'); }
  get comboMestruacion() { return this.formData.get('cboMestruacion'); }
  get textMestruacion() { return this.formData.get('txtMestruacion'); }

  get textGravidez() { return this.formData.get('txtGravidez'); }
  get textPartos() { return this.formData.get('txtPartos'); }
  get textVivos() { return this.formData.get('txtVivos'); }
  get textAbortos() { return this.formData.get('txtAbortos'); }
  get textCesareas() { return this.formData.get('txtCesareas'); }

  get textNotaAntecedentesPersonales() {
    return this.formData.get('txtNotaAntecedentesPersonales');
  }

  dataCies: Array<{ id: string, codigo_titulo: string }>;

  dataGinecoObstetricosOpciones: Array<{ text: string, value: string }> = [
    { text: 'G - Gravidez', value: 'G - Gravidez' },
    { text: 'P - Partos', value: 'P - Partos' },
    { text: 'V - Vivos', value: 'V - Vivos' },
    { text: 'A - Abortos', value: 'A - Abortos' },
    { text: 'C - Cesáreas', value: 'C - Cesáreas' },
    { text: 'No', value: 'No' },
    { text: 'No aplica', value: 'No aplica' },
  ];

  dataGinecoObstetricosValores: Array<number> = [
    0, 1, 2, 3, 4, 5, 6, 7
  ];

  dataMestruacion: Array<{ text: string, value: string }> = [
    { text: 'Si', value: 'Si' },
    { text: 'No', value: 'No' }
  ];

  arrayAntPersonalesElementos = {
    patologicos: 'txtPatologicos',
    familiares: 'txtFamiliares',
    traumas: 'txtTraumas',
    quirurgicos: 'txtQuirurgicos',
    farmacologicos: 'txtFarmacologicos',
    alergicos: 'txtAlergicos',
    toxicos: 'txtToxicos',
    //  ginecoObstetricos: 'cboGinecoObstetricosOpcion',
    //  ginecoObstetricosOpcion: 'cboGinecoObstetricosOpcion',
    //  ginecoObstetricosValor: 'cboGinecoObstetricosValor',
    esquemaVacunacion: 'txtEsquemaDeVacunacion',
    //  mestruacion: 'txtMestruacion',
    planificacion: 'txtPlanificacion',
    notas: 'txtNotaAntecedentesPersonales'
  }

  public dataAntecedentes: Array<string> = ['Si', 'No', 'No aplica'];


  // #endregion

  // #region ************************** DECLARACIÓNES REVISIÓN SISTEMAS

  get textSistemaVisual() { return this.formData.get('txtSistemaVisual'); }
  get textSistemaAuditivo() { return this.formData.get('txtSistemaAuditivo'); }
  get textSistemaDigestivo() { return this.formData.get('txtSistemaDigestivo'); }
  get textSistemaMuscular() { return this.formData.get('txtSistemaMuscular'); }
  get textSistemaEsqueletico() { return this.formData.get('txtSistemaEsqueletico'); }
  get textSistemaNervioso() { return this.formData.get('txtSistemaNervioso'); }
  get textPiel() { return this.formData.get('txtPiel'); }
  get comboSistemaTegumentario() { return this.formData.get('cboSistemaTegumentario'); }
  get textNotaSistemas() { return this.formData.get('txtNotaSistemas'); }

  dataRevisionSistemas: Array<string> = ['Si', 'No', 'No aplica'];

  dataSistemaTegumentario: Array<{ text: string, value: string }> = [
    { text: 'No', value: 'No' },
    { text: 'I', value: 'I' },
    { text: 'II', value: 'II' },
    { text: 'III', value: 'III' },
    { text: 'IV', value: 'IV' },
    { text: 'V', value: 'V' },
    { text: 'VI', value: 'VI' },
    { text: 'VII', value: 'VII' }
  ];

  arrayrRevisionSistemasElementos = {
    sistemaVisual: 'txtSistemaVisual',
    sistemaAuditivo: 'txtSistemaAuditivo',
    sistemaDigestivo: 'txtSistemaDigestivo',
    sistemaMuscular: 'txtSistemaMuscular',
    sistemaEsqueletico: 'txtSistemaEsqueletico',
    sistemaNervioso: 'txtSistemaNervioso',
    piel: 'txtPiel',
    // sistemaTegumentario: 'cboSistemaTegumentario',
    notas: 'txtNotaSistemas'
  }
  // #endregion

  // #region ************************** DECLARACIÓNES EXAMEN FÍSICO


  subiendoFoto = false;
  fotosServidor = [];
  fotoVisualizada: any;
  indiceFotoVisualizada = 0;
  urlFotoVisualizada = '';

  public width = '400px';
  public height = '400px';

  public myRestrictions: FileRestrictions = {
    maxFileSize: 3097152
  };

  ngModelNombreFoto: '';
  ngModelNombreArchivo: '';
  fotoExamenFisico = undefined;
  archivoParaClinico = undefined;

  get textTercioSuperior() { return this.formData.get('txtTercioSuperior'); }
  get textTercioMedio() { return this.formData.get('txtTercioMedio'); }
  get textTercioInferior() { return this.formData.get('txtTercioInferior'); }
  get textCuello() { return this.formData.get('txtCuello'); }
  get textMamas() { return this.formData.get('txtMamas'); }
  get textAbdomen() { return this.formData.get('txtAbdomen'); }
  get textDorso() { return this.formData.get('txtDorso'); }
  get textTorax() { return this.formData.get('txtTorax'); }
  get textGluteos() { return this.formData.get('txtGluteos'); }
  get textMiembroSuperior() { return this.formData.get('txtMiembroSuperior'); }
  get textMiembroInferior() { return this.formData.get('txtMiembroInferior'); }
  get textGenitales() { return this.formData.get('txtGenitales'); }
  get textNotasExamen() { return this.formData.get('txtNotasExamen'); }


  arrayExamenFisicoElementos = {
    tercioSuperior: 'txtTercioSuperior',
    tercioMedio: 'txtTercioMedio',
    tercioInferior: 'txtTercioInferior',
    cuello: 'txtCuello',
    mamas: 'txtMamas',
    abdomen: 'txtAbdomen',
    dorso: 'txtDorso',
    gluteos: 'txtGluteos',
    miembroSuperior: 'txtMiembroSuperior',
    miembroInferior: 'txtMiembroInferior',
    genitales: 'txtGenitales',
    notas: 'txtNotasExamen'
  }

  ngAfterViewInit() {


    // this.ChangeDetectorRef.

    //  this.cd.detectChanges();p
  }

  /*ngAfterContentInit(): void {
    this.deshabilitarValidaciones();
    this.getData(Number(this.pacienteId));
    // this.cd.detectChanges();
  }*/

  isInt(value) {
    return Number.isInteger(Number(value));
  }

  ngAfterContentInit(): void {

    console.log('Entro aquí otras vez...');

    this.deshabilitarValidaciones();

    this.activatedRoute.queryParams.subscribe(params => {
      // const tipo = params.tipo;
      //  const historiaId = params.historiaId;
      const pacienteId = params.pacienteId;
      //console.log({ paciente_id: this.isInt(pacienteId) });
      this.registroEncontrado = false;

      //this.tipoAccion = tipo;
      this.pacienteId = pacienteId;

      this.getData(Number(this.pacienteId));

      console.log({ nuevoRegistro: this.nuevoRegistro });
    });



    // this.formData.get('txtTiempo').disable();

  }

  getPaciente(id: number) {

    console.log({ getPacienteId: id });

    if (id > 0) {
      let urlParams = 'pacientes/' + id;
      // urlParams += 'id/' + pacienteId;
      urlParams += '?grpsa=all&estciv=all';
      urlParams += '&genero=all&ocup=all&ciures=all&depres=all&countryid=all';


      this.apiServices.getData(urlParams, 'Paciente', false)
        .subscribe(data => {

          if (data.success === true) {
            this.dataPaciente = data['Paciente'];
            this.dataPaciente['fecha_actual'] = data.fecha_actual;
            this.pacienteId = this.dataPaciente.id;
            // const fechaActual = data.fecha_actual;
            console.log(data);
            this.datosPacienteCargados = true;

            console.log(this.dataPaciente.alergico);

            if (this.dataPaciente.alergico.trim() === 'Si') {
              this.esAlergico = true;
            } else {
              this.esAlergico = false;
            }

            this.registroEncontrado = true;

            this.pacienteId = this.dataPaciente.id;
            this.nombrePaciente = this.dataPaciente.nombres + ' ' + this.dataPaciente.apellidos + ' (' + this.dataPaciente.identificacion + ')';

            this.cargandoDatos = false;

          } else {
            this.registroEncontrado = false;
            this.cargandoDatos = false;
            let msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe.';
            //   msg += 'Debe verificar que el formulario no contenga errores';
            this.mostrarMsgError(msg);
          }

          this.cargandoDatos = false;
        }, error => {
          this.cargandoDatos = false;
          this.registroEncontrado = false;
          console.log(error);
        }, () => {
          console.log('Finally');
        });
    } else {
      //  this.cargandoDatos = false;
      this.registroEncontrado = false;
      this.cargandoDatos = false;
      let msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe.';
      //   msg += 'Debe verificar que el formulario no contenga errores';
      this.mostrarMsgError(msg);
    }

  }


  calcularImc(): void {
    let imc = 0;
    const peso = this.formData.get('txtPeso').value;
    let talla = this.formData.get('txtTalla').value;
    console.log(peso);
    console.log(talla);
    talla = talla * talla;
    this.formData.get('txtImc').setValue(0);
    if (peso > 0 && talla > 0) {
      // const peso = Number(item.txtPeso);
      // let talla = item.txtTalla;
      // talla = Number(talla) * Number(talla);
      imc = peso / talla;
      console.log(imc);
      this.formData.get('txtImc').setValue(imc)
      // debugger;
    }
    // this.cajasDeTexto.signosVitales.imc = imc;
  }

  //#region *************************** MÉTODOS SIGNOS VITALES
  signosVitales_input($event, pregunta: string) {

    this.calcularImc();


  }


  // #region ************************** MÉTODOS ANTECEDENTES PERSONALES

  chkAlergicos_click($event) {
    console.log($event);
    console.log(this.chkAlergiaRef.nativeElement.checked);
    if (this.chkAlergiaRef.nativeElement.checked === true) {
      this.chkAlergiaRef.nativeElement.checked = true;
      this.formData.get('txtAlergicos').enable();
      this.formData.get('txtAlergicos').setValue('');
      // this.esAlergico = 'Si';
      this.esAlergico = true;
    } else {
      this.chkAlergiaRef.nativeElement.checked = false;
      //  this.esAlergico = 'No';
      this.formData.get('txtAlergicos').setValue('No');
      this.formData.get('txtAlergicos').disable();
      this.esAlergico = false;
    }
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }



  btnBorrarFotoExamenFisico_click() {

    if (this.fotosServidor == null || this.fotosServidor.length === 0) {
      return false;
    }
    // console.log(this.fotoVisualizada);
    this.alertMsg.showAlert = false;
    this.verVentanas.verVenConBorFoto = true;
    this.verVentanas.titVenConBorFoto = 'Confirmación de borrado';
    this.verVentanas.tetVenConBorFoto = '¿Está seguro de borrar la foto?';

    console.log(this.verVentanas);
    console.log(this.fotosServidor);

  }

  // Evento de confirmar borrado
  btnConfirmarBorradoFotoExamenFisico_click($event) {
    this.alertMsgFoto.showAlert = false;
    this.verVentanas.verVenConBorFoto = false;
    console.log(this.fotoVisualizada);

    let codigoFoto = 0;
    if (Number(this.indiceFotoVisualizada) === 0) {
      codigoFoto = this.fotosServidor[0].codigo;
    } else {
      codigoFoto = this.fotoVisualizada.item.codigo;
    }

    // debugger;
    if ($event === true) {
      this.apiServices.deleteData('archivos', codigoFoto, '?pacienteId=' + this.pacienteId).
        subscribe((data: any) => {
          // const data = response as any;
          if (data.success === true) {
            this.alertMsgFoto.showAlert = true;
            this.alertMsgFoto.class = 'alert alert-success';
            this.alertMsgFoto.msg = data.response;
            this.btnVerFotos_click();
            if (this.fotosServidor !== null) {
              if (this.fotosServidor.length > 0) {

                //  console.log(Number(this.indiceFotoVisualizada));

                if (Number(this.indiceFotoVisualizada) === 0) {
                  this.indiceFotoVisualizada = Number(this.indiceFotoVisualizada) + 1;
                } else if (Number(this.indiceFotoVisualizada) > 0) {
                  this.indiceFotoVisualizada = Number(this.indiceFotoVisualizada) - 1;
                }

                // console.log(this.fotoVisualizada);
                //  console.log(Number(this.indiceFotoVisualizada));
                Object.keys(this.fotosServidor).forEach(index => {
                  const item = this.fotosServidor[index];

                  if (Number(item.posicion) === Number(this.indiceFotoVisualizada)) {
                    console.log(Number(this.indiceFotoVisualizada));
                    const obj = {
                      index: Number(index),
                      item: {
                        title: item.nombre,
                        url: this.baseUrl2 + item.foto,
                        posicion: Number(index),
                        id: item.id,
                        codigo: item.codigo
                      }
                    };

                    this.fotoVisualizada = obj;
                    //    console.log({ IngresoAqui: this.fotoVisualizada });
                  }
                });
              } else {
                this.alertMsg.showAlert = false;
              }
            }

            //  debugger;
            console.log({ fotosServidor3: this.fotosServidor });
            console.log({ fotoVisualizada3: this.fotoVisualizada });
            console.log({ indiceFotoVisualizada3: Number(this.indiceFotoVisualizada) });

            //debugger;
          } else {
            this.alertMsgFoto.showAlert = true;
            this.alertMsgFoto.class = 'alert alert-danger';
            this.alertMsgFoto.msg = data.response;
          }
        }, data => {
          console.log(data);
          this.alertMsgFoto.showAlert = true;
          this.alertMsgFoto.class = 'alert alert-danger';
          this.alertMsgFoto.msg = 'Error al intentar borrar la foto. Intente de nuevo.';
        }, () => {
          console.log('finally borrar foto');
          this.subiendoFoto = false;
        });
    }


  }

  btnAdjuntarFotos_click($event) {

    if (this.registroGuardado === false) {
      alert('Primero debe guardar (al menos por primera vez, para asociar el ingreso a un médico)');
      return false;
    }

    this.verVentanas.adjuntarFotos = true;
  }

  btnAdjuntarArchivos_click($event) {

    if (this.registroGuardado === false) {
      alert('Primero debe guardar (al menos por primera vez, para asociar el ingreso a un médico)');
      return false;
    }

    this.verVentanas.adjuntarArchivos = true;
  }

  itemChanged($event) {
    console.log({ itemChanged$event: $event });
    this.indiceFotoVisualizada = Number($event.index);
    this.urlFotoVisualizada = $event.item.url;
    this.fotoVisualizada = $event;
    console.log($event);
  }

  btnVerFotos_click($event?) {
    //  this.codigoHistoria
    this.verVentanas.verFotos = true;
    let queryString = 'pacientes/archivos/' + this.pacienteId;
    queryString += '?tipo=FOTO_EXAMEN_FISICO';
    queryString += '&origen=HISTORIA_CLINICA';
    queryString += '&codigoasociado=' + this.codigoHistoria;

    this.apiServices.getData(queryString, 'archivos', false).
      subscribe(data => {
        console.log(data);
        if (data !== null && data.success === true && data.archivos !== null) {
          const archivos = data['Archivos'];
          this.fotosServidor = [];
          if (archivos !== null) {
            Object.keys(archivos).forEach((index) => {
              //  console.log(index);
              const item = archivos[index];
              const obj = {
                title: item.nombre,
                url: this.baseUrl2 + item.foto,
                posicion: Number(index),
                id: item.id,
                codigo: item.codigo
              };

              this.fotosServidor.push(
                obj
              );

              if (Number(index) === 0) {
                this.urlFotoVisualizada = obj.url;
              }
            });

            console.log({ btnVerFotos_click: this.fotosServidor });
            console.log({ indiceFotoVisualizada4: Number(this.indiceFotoVisualizada) });
          }

          // console.log(this.fotosServidor);;

        } else {
          this.alertMsgFoto.showAlert = true;
          this.alertMsgFoto.class = 'alert alert-danger';
          this.alertMsgFoto.msg = data.response;
        }
      }, data => {
        console.log(data);
        this.alertMsgFoto.showAlert = true;
        this.alertMsgFoto.class = 'alert alert-danger';
        this.alertMsgFoto.msg = data.error.response;
      }, () => {
        console.log('finally subirFotoExamenFisico');
        this.subiendoFoto = false;
      });

    // debugger;
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    console.log({ 'state': state });
    // let queryString

    this.skip = state.skip;
    this.pageSize = state.take;
    this.sort = state.sort;

    if (this.skip === 0) {
      this.skip = 1;
    }

    let currentPage = Math.floor(this.skip / this.pageSize);
    currentPage++;

    // this.verVentanas.verArchivos = true;
    let queryString = 'pacientes/archivos/' + this.pacienteId;
    queryString += '?page=' + currentPage + '&tipo=ARCHIVO_PARA_CLINICO';
    queryString += '&origen=HISTORIA_CLINICA';
    queryString += '&codigoasociado=' + this.codigoHistoria;

    this.btnVerArchivos_click(queryString);

  }


  btnVerArchivos_click(query: string = '') {
    //  this.codigoHistoria
    let queryString = '';
    if (query.length > 0) {
      queryString = query;
    } else {
      queryString = 'pacientes/archivos/' + this.pacienteId;
      queryString += '?page=1&tipo=ARCHIVO_PARA_CLINICO';
      queryString += '&origen=HISTORIA_CLINICA';
      queryString += '&codigoasociado=' + this.codigoHistoria;
    }
    this.verVentanas.verArchivos = true;
    this.apiServices.getData(queryString, 'archivos', false).
      subscribe(data => {
        console.log(data);
        if (data.success === true && data.archivos !== null) {
          const archivos = data['Archivos'];
          console.log(archivos);
          const nuevoArchivos = [];
          if (archivos !== null) {
            Object.keys(archivos).forEach((index) => {
              const item = archivos[index];
              const obj = {
                nombre: item.nombre,
                fecha: item.fecha,
                url: this.baseUrl2 + data.path + item.nombre_auto,
                posicion: Number(index),
                id: item.id,
                codigo: item.codigo
              };

              nuevoArchivos.push(
                obj
              );
            });

            console.log(nuevoArchivos);
            console.log(data.paginationInfo.count);
            this.pageSize = data.paginationInfo.perPage;
            this.totalPages = data.paginationInfo.count;

            this.gridView = {
              data: nuevoArchivos,
              total: this.totalPages
            };

          }

        } else {
          this.alertMsgArchivo.showAlert = true;
          this.alertMsgArchivo.class = 'alert alert-danger';
          this.alertMsgArchivo.msg = data.response;
        }
      }, data => {
        console.log(data);
        this.alertMsgArchivo.showAlert = true;
        this.alertMsgArchivo.class = 'alert alert-danger';
        this.alertMsgArchivo.msg = data.error.response;
      }, () => {
        // console.log('finally subirFotoExamenFisico');
        // this.subiendoFoto = false;
      });
  }

  // btnConfirmarBorrarArchivo_click($event) {
  // }

  btnConfirmarBorrarArchivoParaClinico_click(dataItem) {
    this.dataItem = dataItem;

    if (this.gridView === null || this.gridView.total === 0) {
      return false;
    }

    console.log(this.dataItem);
    console.log(this.gridView.total);

    // console.log(this.fotoVisualizada);
    this.alertMsgArchivo.showAlert = false;
    this.verVentanas.verVenConBorArchivo = true;
    this.verVentanas.titVenConBorArchivo = 'Confirmación de borrado';
    this.verVentanas.tetVenConBorArchivo = '¿Está seguro de borrar el archivo?';

  }


  btnBorrarArchivoParaClinico_click($event) {
    console.log($event);

    this.verVentanas.verVenConBorArchivo = false;
    this.alertMsgArchivo.showAlert = false;

    if (this.dataItem === null || this.dataItem === undefined) {
      return false;
    }

    if ($event === false) {
      return false;
    }

    const idArchivo = this.dataItem.codigo; //return false;

    this.apiServices.deleteData('archivos', idArchivo, '?pacienteId=' + this.pacienteId).
      subscribe((data: any) => {
        // const data = response as any;
        if (data.success === true) {
          this.alertMsgArchivo.showAlert = true;
          this.alertMsgArchivo.class = 'alert alert-success';
          this.alertMsgArchivo.msg = data.response;
          this.btnVerArchivos_click();

        } else {
          this.alertMsgArchivo.showAlert = true;
          this.alertMsgArchivo.class = 'alert alert-danger';
          this.alertMsgArchivo.msg = data.response;
        }
      }, data => {
        console.log(data);
        this.alertMsgArchivo.showAlert = true;
        this.alertMsgArchivo.class = 'alert alert-danger';
        this.alertMsgArchivo.msg = 'Error al intentar borrar la foto. Intente de nuevo.';
      }, () => {
        console.log('finally borrar foto');
        // this.subiendoFoto = false;
      });


  }

  btnAbrirArchivoParaClinico_click(dataItem) {
    console.log(dataItem);
    const url = dataItem.url;
    window.open(url, '_blank');
  }



  // Subir foto y descargar https://www.youtube.com/watch?v=lEsl-B4TrG0

  cerrarVentana() {
    this.verVentanas.adjuntarFotos = false;
    this.verVentanas.adjuntarArchivos = false;
    this.alertMsgFoto.showAlert = false;
    this.alertMsgFoto.msg = '';
    this.alertMsgArchivo.showAlert = false;
    this.alertMsgArchivo.msg = '';
    this.fotoExamenFisico = undefined;
    this.archivoParaClinico = undefined;
    this.verVentanas.verFotos = false;
    this.verVentanas.verArchivos = false;
    this.verVentanas.mostrarBuscarCie10 = false;
    this.verVentanas.mostrarVentanaLogin = false;
    this.verVentanas.verVentanaConfirmarGuardar = false;
    this.verVentanas.verVentanaConfirmarCredenciales = false;
    this.verVentanas.verVentanaCredenciales = false;
  }


  seleccionFotoExamenFisico(archivo) {
    this.alertMsgFoto.showAlert = false;
    //  console.log(archivo)
    if (archivo.files) {
      this.fotoExamenFisico = archivo.files[0].rawFile;
    }
    this.alertMsgFoto.showAlert = false;
    this.alertMsgFoto.msg = '';
  }

  seleccionArchivoParaClinico(archivo) {
    this.alertMsgFoto.showAlert = false;
    //  console.log(archivo)
    if (archivo.files) {
      this.archivoParaClinico = archivo.files[0].rawFile;
    }
    this.alertMsgArchivo.showAlert = false;
    this.alertMsgArchivo.msg = '';
  }


  removerFotoExamenFisico($event) {
    this.fotoExamenFisico = undefined;
    // const file = (event.target as HTMLInputElement).files[0];
    // console.log(file);

  }

  removerArchivoParaClinico($event) {
    this.archivoParaClinico = undefined;
    // const file = (event.target as HTMLInputElement).files[0];
    // console.log(file);

  }



  btnSubirFotoExamenFisico_click() {
    this.alertMsgFoto.showAlert = false;
    if (this.fotoExamenFisico !== undefined) {
      this.subiendoFoto = true;
      const fotoExamenFisico = new FormData();
      fotoExamenFisico.set('nombre', this.ngModelNombreFoto);
      fotoExamenFisico.set('archivo', this.fotoExamenFisico);
      fotoExamenFisico.set('paciente_id', this.pacienteId);
      fotoExamenFisico.set('codigo_asociado', '' + this.codigoHistoria);
      fotoExamenFisico.set('tipo', 'FOTO_EXAMEN_FISICO');
      fotoExamenFisico.set('origen', 'HISTORIA_CLINICA');

      this.apiServices.postFormData(fotoExamenFisico, 'archivos').
        subscribe(data => {
          console.log(data);
          if (data.success === true) {
            this.alertMsgFoto.showAlert = true;
            this.alertMsgFoto.class = 'alert alert-success';
            this.alertMsgFoto.msg = data.response;
            this.resetearSubirFotos();
          } else {
            this.alertMsgFoto.showAlert = true;
            this.alertMsgFoto.class = 'alert alert-danger';
            this.alertMsgFoto.msg = data.response;
          }
        }, data => {
          console.log(data);
          this.alertMsgFoto.showAlert = true;
          this.alertMsgFoto.class = 'alert alert-danger';
          this.alertMsgFoto.msg = data.error.response;
          this.subiendoFoto = false;
        }, () => {
          console.log('finally subirFotoExamenFisico');
          // this.cargandoDatos = true;
          this.subiendoFoto = false;
        });

    } else {
      this.alertMsgFoto.showAlert = true;
      this.alertMsgFoto.class = 'alert alert-danger';
      this.alertMsgFoto.msg = 'Primero debe seleccionar una foto';
    }
  }



  btnSubirArchivoParaClinico_click() {
    this.alertMsgArchivo.showAlert = false;
    if (this.ngModelNombreArchivo !== null || this.ngModelNombreArchivo !== undefined) {
      if (this.ngModelNombreArchivo === '' || this.ngModelNombreArchivo === undefined) {
        this.alertMsgArchivo.showAlert = true;
        this.alertMsgArchivo.class = 'alert alert-danger';
        this.alertMsgArchivo.msg = 'Debe especificar el nombre del archivo';
        return false;
      }

    } else {
      this.alertMsgArchivo.showAlert = true;
      this.alertMsgArchivo.class = 'alert alert-danger';
      this.alertMsgArchivo.msg = 'Debe especificar el nombre del archivo';
      return false;
    }

    if (this.archivoParaClinico !== undefined) {
      this.subiendoFoto = true;
      const archivoParaClinico = new FormData();
      archivoParaClinico.set('nombre', this.ngModelNombreArchivo);
      archivoParaClinico.set('archivo', this.archivoParaClinico);
      archivoParaClinico.set('paciente_id', this.pacienteId);
      archivoParaClinico.set('codigo_asociado', '' + this.codigoHistoria);
      archivoParaClinico.set('tipo', 'ARCHIVO_PARA_CLINICO');
      archivoParaClinico.set('origen', 'HISTORIA_CLINICA');

      this.apiServices.postFormData(archivoParaClinico, 'archivos').
        subscribe(data => {
          console.log(data);
          if (data.success === true) {
            this.alertMsgArchivo.showAlert = true;
            this.alertMsgArchivo.class = 'alert alert-success';
            this.alertMsgArchivo.msg = data.response;
            this.resetearSubirArchivos();

          } else {
            this.alertMsgArchivo.showAlert = true;
            this.alertMsgArchivo.class = 'alert alert-danger';
            this.alertMsgArchivo.msg = data.response;
          }
        }, data => {
          console.log(data);
          this.alertMsgArchivo.showAlert = true;
          this.alertMsgArchivo.class = 'alert alert-danger';
          this.alertMsgArchivo.msg = data.error.response;
          this.subiendoFoto = false;
        }, () => {
          console.log('finally subirFotoExamenFisico');
          // this.cargandoDatos = true;
          this.subiendoFoto = false;
        });

    } else {
      this.alertMsgArchivo.showAlert = true;
      this.alertMsgArchivo.class = 'alert alert-danger';
      this.alertMsgArchivo.msg = 'Primero debe seleccionar un archivo';
    }
  }


  cboMestruacion_change(valor) {

    if (valor !== undefined) {
      if (valor === 'Si') {
        this.formData.get('txtMestruacion').enable();
        // this.cajasDeTexto.antecedentesPersonales.mestruacionOpcion = 'Si'this.cajasDeTexto.antecedentesPersonales.mestruacionOpcion = 'Si'
      } else if (valor === 'No') {
        this.formData.get('txtMestruacion').disable();
        // this.cajasDeTexto.antecedentesPersonales.mestruacionOpcion = 'No'
      }
    }
    // console.log(this.cajasDeTexto.antecedentesPersonales);
  }

  // examenFisco_input($event, pregunta: string) {




  verificarValoresRepetidos(valor, index?): boolean {
    // const strArray = [];
    // const alreadySeen = [];
    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    const lengthRows = this.cie10Rows['controls'].length;
    console.log(valor);
    for (let i = 0; i < lengthRows; i++) {
      const cie10CodigoControl: any = this.cie10Rows.controls[i]['controls']['cie10Codigo'] as FormControl
      if (cie10CodigoControl.value === valor && i !== index) {
        return true;
      }
    }

    return false;
    // return new Set(strArray).size !== strArray.length;
  }





  //   return new Set(strArray).size !== strArray.length;
  // }

  agregarFilaCie10() {
    console.log(this.desBtnGuardar);
    if (this.desBtnGuardar === true) {
      return false;
    }

    if (this.msjErrorCie10.length === 0) {

      this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
      const lengthRows = this.cie10Rows['controls'].length;
      console.log(lengthRows);
      if (lengthRows > 0) {
        for (let i = 0; i < lengthRows; i++) {
          const cie10CodigoControl: any = this.cie10Rows.controls[i]['controls']['cie10Codigo'] as FormControl
          if (cie10CodigoControl.value === '') {
            this.msjErrorCie10 = 'Debe completar el código vacío';
            console.log('Hay códigos vacios');
            break;
          }
        }
      }

      if (this.msjErrorCie10.length > 0) {
        return false;
      }
      // return false;

      this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
      this.cie10Rows.push(this.createCie10Row());
    }
  }


  deleteCie10Row(index: number) {
    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    if (this.cie10Rows.controls.length > 1) {
      this.cie10Rows.removeAt(index);
      this.indiceCie10--;
    } else {
      const cie10CodigoControl: any = this.cie10Rows.controls[0]['controls']['cie10Codigo'] as FormControl;
      // const cie10TituloControl: any = this.cie10Rows.controls[0]['controls']['cie10Titulo'] as FormControl;
      cie10CodigoControl.setValue('');
      // cie10TituloControl.setValue('');
      this.indiceCie10 = 0;
    }
    this.msjErrorCie10 = '';
    return false;
  }


  createCie10Row(): FormGroup {
    return this.formBuilder.group({
      cie10Codigo: [''],
      cie10Titulo: [''],
    });
  }




  constructor(private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiServices: ServiciosService) {


    this.activatedRoute.queryParams.subscribe(params => {
      const pacienteId = params.pacienteId;
      this.pacienteId = pacienteId;
      console.log(pacienteId);

    });
  }

  public onPaste(): void {
    console.log('Paste');
  }

  deshabilitarValidaciones() {

    const omitir = [
      'txtMestruacion'
    ];

    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        //  console.log({ desha: element });
        this.formData.get(element).clearValidators();
      } else {
        console.log({ habi: element });
        this.formData.get(element).setValidators([Validators.required]);
      }
      this.formData.get(element).updateValueAndValidity();
    });
  }

  habilitarValidaciones() {

    const greaterThanZero = [
      'txtTemp', 'txtPulso', 'txtFrecuencia',
      'txtTension1', 'txtTension2',
      'txtPeso', 'txtTalla'
    ];

    const omitirNotas = [
      'txtNotaAntecedentesPersonales', 'txtNotasMotivoConsulta',
      'txtNotasEnfermedadActual', 'txtDiagnosticoNotas',
      'txtNotasExamen', 'txtNotaSistemas', 'txtPlanNotas',
      'txtNotasGenerales',
      'txtNotasSignos', 'txtNotasParaClinicos', 'txtNotasAnalisis'
    ];

    Object.keys(this.formData.controls).forEach(element => {



      if (greaterThanZero.includes(element)) {
        this.formData.get(element).setValidators([Validators.required, GreaterThanZero(true)]);
      } else if (omitirNotas.includes(element)) {
        this.formData.get(element).clearValidators();
      } else {
        this.formData.get(element).setValidators([Validators.required]);
      }

      this.formData.get(element).updateValueAndValidity();
    });
  }



  obtenerCodigoCie10() {
    this.apiServices.getData('cies', 'cies').
      subscribe(data => {
        //  console.log(data);
        this.lstCodigosCie10 = data;
      }, error => {
        console.log(error);
      }, () => {
        console.log('Finally');
      });
  }

  getData(pacienteId: number) {
    // console.log(itemId);
    this.cargandoDatos = true;

    if (pacienteId !== undefined && pacienteId > 0) {
      let urlParams = 'historiaclinicas/' + pacienteId + '?medid=all';
      //urlParams += '?historia=all';
      this.apiServices.getData(urlParams, 'Historiaclinica', false).subscribe(
        data => {
          console.log(data);
          // const item = data;
          if (data !== null && data.success !== undefined && data.success === true) {

            const item = data['Historiaclinica'];
            this.historiaId = item.id;
            this.cargarValoresHistoria(item);
            this.codigoHistoria = item.codigo;
            this.getPaciente(Number(this.pacienteId));
            this.medicoIdReg = item.medico_id;

            // if(item.Medico!== undefined) {
            // this.medicoDelIngreso = item.Medico.nombres+' '+item.Medico.apellidos+ ' (CC: '+item.Medico.identificacion+')';
            // }

            // Esto siempre debe ir antes del siguiente bloque
            if (item.Medico !== undefined && item.Medico !== null && item.tiene_historia === true) {
              //Médico que hizo el ingreso
              this.medicoDelIngreso = item.Medico.nombres + ' ' + item.Medico.apellidos + ' (CC: ' + item.Medico.identificacion + ')'; //medicoDelIngreso
            }

            if (item.clausurada === true) {
              this.clausurada = true;
              this.deshabilitarControles();
              this.desBtnGuardar = true;
              this.desBtnBorrarArchivo = true;
              this.desBtnBorrarFoto = true;
              //  console.log('Está entrando aquí 1');
            } else if (item.tiene_historia === true && this.tipoUsuario === 'ADMIN') {
              //   console.log('Está entrando aquí 2');
              this.habilitarControles(['txtMestruacion']);
              this.desBtnGuardar = false;
            } else if (item.tiene_historia === true && !this.medicoActualHizoIngreso(this.medicoId, this.medicoIdReg, this.tipoUsuario)) { //Si no es el propietario
              //   console.log('Está entrando aquí 3');
              this.deshabilitarControles();
              this.desBtnGuardar = true;
              this.desBtnBorrarArchivo = true;
              this.desBtnBorrarFoto = true;
            }

            // Verificar el registro guardado
            if (item.tiene_historia === true) {
              this.registroGuardado = true;
            }

          } else {
            this.cargandoDatos = false;
            this.registroEncontrado = false;
            this.mostrarMsgError('Error al intentar cargar los datos de la historia clínica. Intente de nuevo');
          }
          //  this.obtenerCodigoCie10();
        },
        dataError => {
          console.log(dataError);
          if (dataError.error.response !== undefined) {
            this.mostrarMsgError(dataError.error.response);
          } else {
            this.mostrarMsgError('Error al intentar cargar los datos de la historia clínica. Intente de nuevo');
          }
          //  this.registroEncontrado = false;
          this.cargandoDatos = false;
        },
        () => {
          //this.cargandoDatos = false;
        }
      );
    } else {

      this.mostrarMsgError('Error al intentar cargar los datos de la historia clínica. Intente de nuevo');

      this.registroEncontrado = false;
      this.cargandoDatos = false;
    }
  }

  medicoActualHizoIngreso(idMedicoLogin: Number, idMedicoHistoria: Number, tipoUsuario: string) {
    console.log({ idMedicoHistoria: idMedicoHistoria });
    console.log({ idMedicoLogin: idMedicoLogin });

    if (tipoUsuario === 'MEDICO' && (idMedicoHistoria === idMedicoLogin)) {
      return true; //Si es el propietario
    }
    return false;
  }

  deshabilitarControles(omitir = []) {
    this.desBtnGuardar = true;
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).disable();
      }
    });

    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    const lengthRows = this.cie10Rows['controls'].length;

    // if (lengthRows > 0) {
    console.log('deshabilitarControles deshabilitarControles 1');
    for (let i = 0; i < lengthRows; i++) {
      console.log('deshabilitarControles deshabilitarControles 2');
      const cie10CodigoControl: any = this.cie10Rows.controls[i]['controls']['cie10Codigo'] as FormControl;
      cie10CodigoControl.disable();
      //  }
    }

  }


  habilitarControles(omitir = []) {
    this.desBtnGuardar = true;
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).enable();
      }
    });

    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    const lengthRows = this.cie10Rows['controls'].length;

    if (lengthRows > 0) {
      for (let i = 0; i < lengthRows; i++) {
        const cie10CodigoControl: any = this.cie10Rows.controls[i]['controls']['cie10Codigo'] as FormControl;
        cie10CodigoControl.enable();
      }
    }
  }

  recargarValores_click() {
    this.getData(Number(this.pacienteId));
  }

  cargarValoresHistoria(historiaClinica) {
    //  console.log(historiaJson.historia_json);


    if (historiaClinica == undefined) {
      //  this.cargandoDatos = false;
      return false;
    }

    // #region ASIGNACIÓN DE VALORES MOTIVO DE CONSULTA
    this.textMotivoConsulta.setValue(historiaClinica.consulta_motivo);
    this.textNotasMotivoConsulta.setValue(historiaClinica.consulta_notas);
    //#endregion

    //#region ASIGNACIÓN DE VALORES ENFERMEDAD ACTUAL
    this.textEnfermedadActual.setValue(historiaClinica.enfermedad_actual);
    this.textNotasEnfermedadActual.setValue(historiaClinica.enfermedad_notas);
    //#endregion

    // #region ASIGNACIÓN DE VALORES ANTECEDENTES PERSONALES
    //  const antPerRegistrados = historiaClinica.antecedentesPersonales;
    // const txtsPerRegistrados = this.cajasDeTexto.antecedentesPersonales;

    this.textPatologicos.setValue(historiaClinica.ant_patologicos);
    // txtsPerRegistrados.patologicos = antPerRegistrados.patologicos;

    this.textTraumas.setValue(historiaClinica.ant_traumas);
    // txtsPerRegistrados.traumas = antPerRegistrados.traumas;

    this.textFamiliares.setValue(historiaClinica.ant_familiares);
    //  txtsPerRegistrados.familiares = antPerRegistrados.familiares;

    this.textFarmacologicos.setValue(historiaClinica.ant_farmacologicos);
    // txtsPerRegistrados.farmacologicos = antPerRegistrados.farmacologicos;

    this.textPlanificacion.setValue(historiaClinica.ant_planificacion);
    // txtsPerRegistrados.planificacion = antPerRegistrados.planificacion;

    this.textQuirurgicos.setValue(historiaClinica.ant_quirurgicos);
    // txtsPerRegistrados.quirurgicos = antPerRegistrados.quirurgicos;

    this.textAlergicos.setValue(historiaClinica.ant_alergicos);

    if (historiaClinica.alergico.trim() === 'Si') {
      // this.chkAlergiaRef.nativeElement.checked = true;
      this.formData.get('txtAlergicos').enable();
      this.formData.patchValue({ chkAlergiaRef: true });
      this.esAlergico = true;
    } else {
      // this.chkAlergiaRef.nativeElement.checked = false;
      this.formData.patchValue({ chkAlergiaRef: false });
      this.formData.get('txtAlergicos').setValue('No');
      this.formData.get('txtAlergicos').disable();
      this.esAlergico = false;
    }
    // txtsPerRegistrados.alergicos = antPerRegistrados.alergicos;

    this.textToxicos.setValue(historiaClinica.ant_toxicos);
    // txtsPerRegistrados.toxicos = antPerRegistrados.toxicos;

    this.textEsquemaDeVacunacion.setValue(historiaClinica.ant_vacunacion);
    // txtsPerRegistrados.esquemaVacunacion = antPerRegistrados.esquemaVacunacion;

    this.textNotaAntecedentesPersonales.setValue(historiaClinica.ant_notas);
    //  txtsPerRegistrados.notas = antPerRegistrados.notas;

    if (historiaClinica.ant_mestruacion !== null && historiaClinica.ant_mestruacion.trim().length > 0) {
      this.formData.get('cboMestruacion').setValue('Si');
      this.textMestruacion.enable();
      //  txtsPerRegistrados.mestruacion.opcion = 'Si';
      //  txtsPerRegistrados.mestruacion.valor = antPerRegistrados.mestruacion.valor;
      const tempDate = new Date(historiaClinica.ant_mestruacion)
      this.textMestruacion.setValue(tempDate);
    } else {
      // this.formData.get('cboMestruacion').
      //   txtsPerRegistrados.mestruacion.opcion = 'No';
      //  txtsPerRegistrados.mestruacion.valor = '';
      this.formData.get('cboMestruacion').setValue('No');
      this.textMestruacion.disable();
    }

    // console.log(Number(antPerRegistrados.ginecoObstetricos.gravidez));
    this.formData.get('txtGravidez').setValue(Number(historiaClinica.ant_gineco_gravidez));
    this.formData.get('txtPartos').setValue(Number(historiaClinica.ant_gineco_partos));
    this.formData.get('txtVivos').setValue(Number(historiaClinica.ant_gineco_vivos));
    this.formData.get('txtAbortos').setValue(Number(historiaClinica.ant_gineco_abortos));
    this.formData.get('txtCesareas').setValue(Number(historiaClinica.ant_gineco_cesareas));
    //#endregion

    //#region ASIGNACIÓN DE VALORES REVISIÓN POR SISTEMAS
    // const signosVitales = historiaClinica.signosVitales;
    // console.log(revisionSistemas);

    this.textTemp.setValue(Number(historiaClinica.signos_temp));
    this.textPulso.setValue(Number(historiaClinica.signos_pulso));
    this.textFrecuencia.setValue(Number(historiaClinica.signos_frecuencia));
    this.textTalla.setValue(Number(historiaClinica.signos_talla));
    this.textTension1.setValue(Number(historiaClinica.signos_tension1));
    this.textTension2.setValue(Number(historiaClinica.signos_tension2));
    this.textPeso.setValue(Number(historiaClinica.signos_peso));
    this.textImc.setValue(Number(historiaClinica.signos_imc));
    this.textNotasSignos.setValue(historiaClinica.signos_notas);
    //#endregion

    //#region ASIGNACIÓN DE VALORES REVISIÓN POR SISTEMAS
    this.textSistemaVisual.setValue(historiaClinica.sistema_visual);
    this.textSistemaDigestivo.setValue(historiaClinica.sistema_digestivo);
    this.textSistemaEsqueletico.setValue(historiaClinica.sistema_esqueletico);
    this.textSistemaAuditivo.setValue(historiaClinica.sistema_auditivo);
    this.textSistemaMuscular.setValue(historiaClinica.sistema_muscular);
    this.textSistemaNervioso.setValue(historiaClinica.sistema_nervioso);
    this.textPiel.setValue(historiaClinica.piel);
    this.textNotaSistemas.setValue(historiaClinica.sistema_notas);
    if (historiaClinica.sistema_tegumentario.trim().length === 0) {
      this.comboSistemaTegumentario.setValue('I');
    } else {
      this.comboSistemaTegumentario.setValue(historiaClinica.sistema_tegumentario);
    }
    //#endregion

    //#region ASIGNACIÓN DE VALORES EXAMEN FISÍCO
    // const examenFisico = historiaClinica.examenFisico;
    // console.log(examenFisico);
    this.textTercioSuperior.setValue(historiaClinica.examen_ter_sup);
    this.textTercioMedio.setValue(historiaClinica.examen_ter_med);
    this.textTercioInferior.setValue(historiaClinica.examen_ter_inf);
    this.textCuello.setValue(historiaClinica.examen_cuello);
    this.textMamas.setValue(historiaClinica.examen_mamas);
    this.textAbdomen.setValue(historiaClinica.examen_abdomen);
    this.textDorso.setValue(historiaClinica.examen_dorso);
    this.textTorax.setValue(historiaClinica.examen_torax);
    this.textGluteos.setValue(historiaClinica.examen_gluteos);
    this.textMiembroSuperior.setValue(historiaClinica.examen_mien_sup);
    this.textMiembroInferior.setValue(historiaClinica.examen_mien_inf);
    this.textGenitales.setValue(historiaClinica.examen_genitales);
    this.textNotasExamen.setValue(historiaClinica.examen_notas);
    //#endregion

    //#region ASIGNACIÓN DE VALORES PARA-CLINICOS
    this.textParaClinicos.setValue(historiaClinica.para_paraclinicos);
    this.textNotasClinicos.setValue(historiaClinica.para_notas);
    //#endregion

    //#region ASIGNACIÓN DE VALORES ANÁLISIS
    // console.log(analisis);
    this.textAnalisis.setValue(historiaClinica.analisis_analisis);
    this.textNotasAnalisis.setValue(historiaClinica.analisis_notas);
    //#endregion

    if (historiaClinica.clausurada === 0) {
      this.clausurada = false;
    } else if (historiaClinica.clausurada === 1) {
      this.clausurada = true;
    }

    //#region ASIGNACIÓN DE VALORES DIAGNOSTICO
    // const diagnostico = historiaClinica.diagnostico;

    // console.log(Object.keys(historiaClinica.diagnosticos_cie10).length);pf

    //  this.indiceCie10 = this.cie10Rows.length;
    // while (this.cie10Rows.length > 0) {
    //   this.cie10Rows.removeAt(0)
    // }
    //https://stackoverflow.com/questions/41852183/angular-2-remove-all-items-from-a-formarray


    this.indiceCie10 = 0;

    if (historiaClinica.diagnosticos_cie10.trim().length > 0 && this.isJson(historiaClinica.diagnosticos_cie10)) {
      this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
      const diagnosticos_cie10 = JSON.parse(historiaClinica.diagnosticos_cie10);
      const valoresCargados = [];
      if (Object.keys(diagnosticos_cie10).length > 0) {
        const arr = <FormArray>this.formData.controls.cie10Rows;
        arr.controls = [];

        // const itemCie10 = diagnostico.diagnosticos_cie10;
        Object.keys(diagnosticos_cie10).forEach(index => {
          // const itemCie10 = historiaClinica.diagnosticos_cie10;
          console.log({ indiceCie10: index })
          // if (Number(index) === 0) {
          this.cie10Rows.push(this.createCie10Row());
          const cie10CodigoControl: any = this.cie10Rows.controls[Number(index)]['controls']['cie10Codigo'] as FormControl;
          // const cie10TituloControl: any = this.cie10Rows.controls[Number(index)]['controls']['cie10Titulo'] as FormControl;
          const valor = diagnosticos_cie10[Number(index)].codigo + ' - ' + diagnosticos_cie10[Number(index)].titulo;
          cie10CodigoControl.setValue(valor);
          // cie10TituloControl.setValue(diagnosticos_cie10[Number(index)].titulo);
          valoresCargados.push(valor);
          this.indiceCie10++;
        });

        //Almacenar códigos CIE10
        // const codigosCieE10 = this.agregarValoresCie10();
        console.log(valoresCargados);
        const nombreObjeto = 'ingresoCie10_' + historiaClinica.codigo;
        console.log(nombreObjeto);
        localStorage.removeItem(nombreObjeto);
        this.setObjectInStorage(nombreObjeto, valoresCargados)
      }
    }

    this.textDiagnostico.setValue(historiaClinica.diagnostico_manual);
    this.textdiagnosticoNotas.setValue(historiaClinica.diagnostico_notas);

    //  console.log(valoresCargados);
    const nombreObjeto = 'ingresoDiagnostico_' + historiaClinica.codigo;
    console.log(nombreObjeto);
    localStorage.removeItem(nombreObjeto);
    localStorage.setItem(nombreObjeto, historiaClinica.diagnostico_manual);
    //this.setObjectInStorage(nombreObjeto, valoresCargados)

    // #endregion

    //#region ASIGNACIÓN DE VALORES PLAN
    // const plan = historiaClinica.plan;
    // console.log(plan);
    this.textPlan.setValue(historiaClinica.plan_plan);
    this.textPlanNotas.setValue(historiaClinica.plan_notas);
    //#endregion


    //#region ASIGNACIÓN DE VALORES GENERALES
    // const generales = historiaClinica.generales;
    // console.log(generales);
    this.textNotasGenerales.setValue(historiaClinica.general_notas);
    //#endregion

    // this.cargandoDatos = false;


  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  setObjectInStorage(nombre: string, object: any) {
    localStorage.setItem(nombre, JSON.stringify(object));
  }

  getObjectInStorage(nombre: string) {
    const retrievedObject = localStorage.getItem(nombre);
    return JSON.parse(retrievedObject);
  }


  btnRedireccionar_click(url: string) {
    // this.verVentanaDetallePaciente = true;
    console.log(url);
    const queryParams = {
      pacienteId: this.pacienteId,
      tipo: 'nuevo'
    };
    this.router.navigate([url], { queryParams });
    return false;
  }

  btnGotoUrl(url: string, params = null, skip = false) {
    // this.verVentanaDetallePaciente = true;
    console.log(url);

    if (skip === true) {
      // skipLocationChange: this.skipLocationChange
      if (params === null) {
        this.router.navigate([url], { skipLocationChange: skip });
      } else {
        const queryParams = params;
        this.router.navigate([url], { queryParams, skipLocationChange: skip });
      }

    } else {
      if (params === null) {
        this.router.navigate([url]);
      } else {
        const queryParams = params;
        this.router.navigate([url], { queryParams, });
      }
    }
    return false;
  }


  generarPdf() {
    this.accionEjecutandose = true;
    let url = 'reportes/generarpdf?origen=ingreso';
    url += '&codigo=' + this.codigoHistoria +'&pacienteid='+this.pacienteId
    this.apiServices.getData(url, 'Reporte', false)
      .subscribe(
        data => {
          console.log(data);

          if (data !== null && data.success !== undefined && data.success === true) {
            //  this.tienePdf = true;
            // this.urlPdf = this.baseUrl2 + data.path;
            this.alertMsg.showAlert = false;
            this.accionEjecutandose = false;
            window.open(this.baseUrl2 + data.path);
          } else {
            this.accionEjecutandose = false;
            this.mostrarMsgError('Error al intentar generar el PDF, Debe intentar de nuevo');
            window.scroll(0, 0);
          }
        }, error => {
          console.log(error);
          //  this.codigoHistoria = 0;
          //  this.cerrarVentana();
          this.accionEjecutandose = false;
          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError('Error al intentar generar el PDF, Debe intentar de nuevo');
          }

          window.scroll(0, 0);
        }, () => {

        });
  }

  btnAcciones_click($event) {
    console.log($event);

    if ($event.item === 'listado_pacientes') {
      this.btnGotoUrl($event.url, { pacienteId: this.pacienteId, tipo: 'nuevo' });

    } else if ($event.item === 'agregar_formula') {
      this.btnGotoUrl($event.url, { pacienteId: this.pacienteId, tipo: 'nuevo', origen: 'ingreso', codigo_origen: '' + this.historiaId });

    } else if ($event.item === 'agregar_incapacidad') {
      this.btnGotoUrl($event.url, { pacienteId: this.pacienteId, tipo: 'nuevo', origen: 'ingreso', codigo_origen: '' + this.historiaId });

    } else if ($event.item === 'imprimir') {
      //  console.log(this.codigoHistoria);
      if (Number(this.codigoHistoria) > 0 && this.registroGuardado === true) {
        this.generarPdf();
      } else if (Number(this.codigoHistoria) === 0 || this.registroGuardado === false) {
        alert('Primero debe guardar (al menos por primera vez, para asociar el ingreso a un médico)');
      }

    }




  }

  isObject(item) {
    return (typeof item === 'object' && !Array.isArray(item) && item !== null);
  }

  ngOnInit(): void {
    this.formData = this.formBuilder.group({
      // Antecedentes médicos personales
      // cboPatologicos: [''],
      txtPatologicos: ['No', [Validators.required]],
      chkAlergiaRef: [''],

      //  cboFamiliares: [''],
      txtFamiliares: ['No', [Validators.required]],

      // cboTraumas: [''],
      txtTraumas: ['No', [Validators.required]],

      // cboQuirurgicos: [''],
      txtQuirurgicos: ['No', [Validators.required]],

      // cboFarmacologicos: [''],
      txtFarmacologicos: ['No', [Validators.required]],

      //  cboAlergicos: [''],
      txtAlergicos: [{ value: 'No', disabled: true }, [Validators.required]],

      //  cboToxicos: [''],
      txtToxicos: ['No', [Validators.required]],
      // textPlanificacion: ['No', [Validators.required]],

      txtGravidez: [''],
      txtPartos: [''],
      txtVivos: [''],
      txtAbortos: [''],
      txtCesareas: [''],

      // ginecoRows: this.formBuilder.array([this.createGinecoItem()]),
      cie10Rows: this.formBuilder.array([this.createCie10Row()]),

      // cboEsquemaDeVacunacion: [''],
      txtEsquemaDeVacunacion: ['No', Validators.required],

      cboMestruacion: ['No', [Validators.required]],
      txtMestruacion: [{ value: '', disabled: true }, [Validators.required]],

      //  cboPlanificacion: [''],
      txtPlanificacion: ['No', [Validators.required]],

      txtNotaAntecedentesPersonales: [''],

      // Revisión de sintomas por sistemas
      // cboSistemaVisual: [''],
      txtSistemaVisual: ['No', [Validators.required]],

      // cboSistemaAuditivo: [''],
      txtSistemaAuditivo: ['No', [Validators.required]],

      // cboSistemaDigestivo: [''],
      txtSistemaDigestivo: ['No', [Validators.required]],

      // cboSistemaMuscular: [''],
      txtSistemaMuscular: ['No', [Validators.required]],

      //  cboSistemaEsqueletico: [''],
      txtSistemaEsqueletico: ['No', [Validators.required]],

      // cboSistemaNervioso: [''],
      txtSistemaNervioso: ['No', [Validators.required]],
      txtPiel: ['No', [Validators.required]],


      cboSistemaTegumentario: [{ value: 'No', disabled: false }, [Validators.required]],

      txtNotaSistemas: [''],

      // Signos vitales
      txtTemp: ['', [Validators.required, GreaterThanZero(true)]],
      txtPulso: ['', [Validators.required, GreaterThanZero(true)]],
      txtFrecuencia: ['', [Validators.required, GreaterThanZero(true)]],
      txtTension1: ['', [Validators.required, GreaterThanZero(true)]],
      txtTension2: ['', [Validators.required, GreaterThanZero(true)]],
      txtPeso: ['', [Validators.required, GreaterThanZero(true)]],
      txtTalla: ['', [Validators.required, GreaterThanZero(true)]],
      txtImc: [''],
      txtNotasSignos: [''],

      // Motivo de consulta
      txtMotivoConsulta: ['No', [Validators.required]],
      txtNotasMotivoConsulta: [''],

      // Enfermedad Actual
      txtEnfermedadActual: ['No', [Validators.required]],
      txtNotasEnfermedadActual: [''],

      // Examen fisíco
      txtTercioSuperior: ['No', [Validators.required]],
      txtTercioMedio: ['No', [Validators.required]],
      txtTercioInferior: ['No', [Validators.required]],
      txtTorax: ['No', [Validators.required]],
      txtCuello: ['No', [Validators.required]],
      txtMamas: ['No', [Validators.required]],
      txtAbdomen: ['No', [Validators.required]],
      txtDorso: ['No', [Validators.required]],
      txtGluteos: ['No', [Validators.required]],
      txtMiembroSuperior: ['No', [Validators.required]],
      txtMiembroInferior: ['No', [Validators.required]],
      //   txtNotasExamenFisico: [''],
      txtGenitales: ['No', [Validators.required]],
      txtNotasExamen: [''],

      // Paraclinicos
      txtParaClinicos: ['No', [Validators.required]],
      txtNotasParaClinicos: [''],

      // Análisis
      txtAnalisis: ['No', [Validators.required]],
      txtNotasAnalisis: [''],

      // Diagnostico
      txtDiagnostico: ['No', [Validators.required]],
      txtDiagnosticoNotas: [''],

      // Plan
      txtPlan: ['No', [Validators.required]],
      txtPlanNotas: [''],

      // Notas generales
      txtNotasGenerales: ['']


    });

    // this.valoresPorDefectoAntecedentes();

    this.formData.get('txtGravidez').setValue(0);
    this.formData.get('txtPartos').setValue(0);
    this.formData.get('txtVivos').setValue(0);
    this.formData.get('txtAbortos').setValue(0);
    this.formData.get('txtCesareas').setValue(0);

    this.formData.get('txtTemp').setValue(0);
    this.formData.get('txtPulso').setValue(0);
    this.formData.get('txtPeso').setValue(0);
    this.formData.get('txtTension1').setValue(0);
    this.formData.get('txtTension2').setValue(0);
    this.formData.get('txtFrecuencia').setValue(0);
    this.formData.get('txtTalla').setValue(0);
    this.formData.get('txtImc').setValue(0);

    this.tipoUsuario = this.apiServices.getTipoUsuario();
    const userInfo = this.apiServices.getUserInfoFromToken();

    if (this.tipoUsuario === 'MEDICO') {
      this.medicoId = userInfo.userInfo.id;
    } else {
      this.medicoId = 0;
    }
  }

  mostrarMsgError(msg: string) {
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }




  // 1 - Guardar
  btnMostrarConfirmarGuardar_click() {
    this.deshabilitarValidaciones();
    //  console.log({guardarClausurarEditar: guardarClausurarEditar});
    //Es un nuevo registro
    this.guardarClausurarEditar = 'guardar';
    // this.medicoId = this.medicoIdReg;
    // con
    console.log(this.medicoId);
    //this.tipoAccion = 'nuevo';
    //Para determinar si es clausurar o guardar
    // this.guardarClausurarEditar = guardarClausurarEditar;
    // if(this.tipoUsuario !== 'MEDICO') {
    //   this.saltarValidacion = false;
    // }

    this.verVentanas.verVentanaConfirmarGuardar = true;
    this.verVentanas.titVenCon = 'Confirmación de acción';
    this.verVentanas.tetVenCon = '¿Está seguro(s)?';
  }

  //1.1 

  btnMostrarConfirmarClausurar_click() {

    this.saltarValidacion = false;
    //    console.log({guardarClausurarEditar: guardarClausurarEditar});
    //Es un nuevo registro
    // this.tipoAccion = 'clausurar'; //Guardar
    //Para determinar si es clausurar o guardar
    this.guardarClausurarEditar = 'clausurar';

    this.verVentanas.verVentanaConfirmarGuardar = true;
    this.verVentanas.titVenCon = 'Confirmación de acción';
    this.verVentanas.tetVenCon = '¿Está seguro(s)?';
  }


  // 2
  btnConfimarGuardar_click($event) {

    //Tiene que ir siempre arrriba,por   this.alertMsg.showAlert = false;
    this.cerrarVentana();
    this.alertMsg.showAlert = false;

    //Opción No
    if ($event === false) {
      return false;
    }


    let msg = '';
    if (this.guardarClausurarEditar === 'guardar' && this.tipoUsuario === 'MEDICO') {
      this.saltarValidacion = true;
    }

    if (this.guardarClausurarEditar === 'clausurar') {
      this.habilitarValidaciones();
    }

    console.log(this.formData);

    /****** VALIDAR FORMULARIO ********/
    this.submitted = true;
    if (this.formData.invalid) {
      this.msgNotificacion = 'Error al intentar ' + this.msgNotificacion + ' el ingreso. ';
      this.msgNotificacion += 'Verifique que el formulario no contenga errorese intente de nuevo.';
      this.mostrarMsgError(this.msgNotificacion);
      window.scroll(0, 0);
      return false;
    }


    this.msgNotificacion = '';
    console.log({ tipoUsuario: this.tipoUsuario });

    if (this.guardarClausurarEditar === 'guardar' && this.tipoUsuario == 'ADMIN') {
      //  let msg = 'Se ha detectado que ';
      msg = 'Se ha detectado que ';
      msg += 'su rol no es Médico. Para poder guardar <br />';
      msg += 'primero debe ingresar las credenciales del médico que será asociado a los cambios realizados. <br />';
      msg += 'Presione el botón Si para continuar.';
      this.msgNotificacion = 'guardar los cambios en el';
      this.tituloBotonCredenciales = 'Guardar cambios';

    } else if (this.guardarClausurarEditar === 'clausurar' && this.tipoUsuario == 'ADMIN') {
      msg = 'Se ha detectado que ';
      msg += 'su rol no es Médico. Para poder clausurar <br />';
      msg += 'primero debe ingresar las credenciales del médico que será asociado al cierre de la historia. <br />';
      msg += 'Presione el botón Si para continuar.';
      this.tituloVentanaCredenciales = 'Clausurar ingreso';
      this.tituloBotonCredenciales = 'Clausurar';
      this.msgNotificacion = 'clausurar';

    } else if (this.guardarClausurarEditar === 'clausurar' && this.tipoUsuario === 'MEDICO') {
      msg = '';
      msg += 'Para poder clausurar <br />';
      msg += 'primero debe ingresar sus credenciales (usuario y contraseña). <br />';
      msg += 'Presione el botón Si para continuar.';
      this.tituloVentanaCredenciales = 'Clausurar ingreso';
      this.tituloBotonCredenciales = 'Clausurar';
      this.msgNotificacion = 'clausurar';
    }



    /****** FIN VALIDAR FORMULARIO */

    if ((this.tipoUsuario === 'MEDICO' || this.tipoUsuario === 'ADMIN') && this.saltarValidacion === false) {
      this.verVentanas.verVentanaConfirmarCredenciales = true;
      this.verVentanas.titentanaConfirmarCredenciales = 'Notificación';
      this.verVentanas.tetVentanaConfirmarCredenciales = msg;
      // } else if ($event === true && this.tipoUsuario === 'MEDICO' && this.saltarValidacion === false) { 
      //   this.verVentanas.verVentanaConfirmarCredenciales = true;
      //   this.verVentanas.titentanaConfirmarCredenciales = 'Notificación';
      //   this.verVentanas.tetVentanaConfirmarCredenciales = msg;
    } else if ($event === true) {
      this.enviarDatos();
    }


  }

  // 3
  btnConfirmarCredenciales_click($event) {
    // console.log($event);
    if ($event === true) {
      this.cerrarVentana();
      this.verVentanas.verVentanaCredenciales = true;
    } else {
      this.cerrarVentana();
    }
  }

  // 4
  btnLogin_click($event) {
    console.log($event);
    if ($event !== null) {
      this.cerrarVentana();
      this.medicoId = $event.id;
      //  if(this.medicoId === this,)
      this.saltarValidacion = true;
      this.enviarDatos();
    }
  }



  enviarDatos() {
    this.alertMsg.showAlert = false;
    const formData = new FormData();
    formData.append('paciente_id', this.pacienteId);
    formData.append('medico_id', '' + this.medicoId);

    // Consulta
    // const consulta = this.cajasDeTexto.motivoConsulta;
    formData.append('consulta_motivo', this.textMotivoConsulta.value);
    formData.append('consulta_notas', this.textNotasMotivoConsulta.value);
    // Enfermedad actual
    // const enfermedadActual = this.cajasDeTexto.enfermedadActual;
    formData.append('enfermedad_actual', this.textEnfermedadActual.value);
    console.log(this.textNotasEnfermedadActual.value);
    formData.append('enfermedad_notas', this.textNotasEnfermedadActual.value);
    // Antecedentes
    //  const antecedentes = this.cajasDeTexto.antecedentesPersonales;
    formData.append('ant_patologicos', this.textPatologicos.value);
    formData.append('ant_traumas', this.textTraumas.value);
    formData.append('ant_familiares', this.textFamiliares.value);
    formData.append('ant_quirurgicos', this.textQuirurgicos.value);
    formData.append('ant_farmacologicos', this.textFarmacologicos.value);

    //  console.log(this.comboMestruacion.value);
    //  console.log(this.comboMestruacion.value.length);

    if (this.comboMestruacion.value === 'Si') {
      // const tempData = new Date(antecedentes.mestruacionValor);
      formData.append('ant_mestruacion', this.textMestruacion.value.toISOString());
    } else {
      formData.append('ant_mestruacion', 'No');
    }

    formData.append('ant_alergicos', this.textAlergicos.value);
    if (this.esAlergico) {
      formData.append('alergico', 'Si');
    } else {
      formData.append('alergico', 'No');
    }

    formData.append('ant_toxicos', this.textToxicos.value);
    formData.append('ant_vacunacion', this.textEsquemaDeVacunacion.value);
    formData.append('ant_planificacion', this.textPlanificacion.value);
    formData.append('ant_gineco_gravidez', this.textGravidez.value);
    formData.append('ant_gineco_partos', this.textPartos.value);
    formData.append('ant_gineco_vivos', this.textVivos.value);
    formData.append('ant_gineco_abortos', this.textAbortos.value);
    formData.append('ant_gineco_cesareas', this.textCesareas.value);
    formData.append('ant_notas', this.textNotaAntecedentesPersonales.value);
    // Revisión de sistemas
    formData.append('sistema_visual', this.textSistemaVisual.value);
    formData.append('sistema_auditivo', this.textSistemaAuditivo.value);
    formData.append('sistema_digestivo', this.textSistemaDigestivo.value);
    formData.append('sistema_muscular', this.textSistemaMuscular.value);
    formData.append('sistema_esqueletico', this.textSistemaEsqueletico.value);
    formData.append('sistema_nervioso', this.textSistemaNervioso.value);
    formData.append('piel', this.textPiel.value);
    formData.append('sistema_tegumentario', this.comboSistemaTegumentario.value);
    formData.append('sistema_notas', this.textNotaSistemas.value);
    // Signos vitales
    formData.append('signos_temp', this.textTemp.value);
    formData.append('signos_pulso', this.textPulso.value);
    formData.append('signos_frecuencia', this.textFrecuencia.value);
    formData.append('signos_tension1', this.textTension1.value);
    formData.append('signos_tension2', this.textTension2.value);
    formData.append('signos_peso', this.textPeso.value);
    formData.append('signos_talla', this.textTalla.value);
    formData.append('signos_imc', this.textImc.value);
    formData.append('signos_notas', this.textNotasSignos.value);

    // Examen físico
    formData.append('examen_ter_sup', this.textTercioSuperior.value);
    formData.append('examen_ter_med', this.textTercioMedio.value);
    formData.append('examen_ter_inf', this.textTercioInferior.value);
    //console.log(this.textCuello.value);
    formData.append('examen_cuello', this.textCuello.value);
    formData.append('examen_dorso', this.textDorso.value);
    formData.append('examen_torax', this.textTorax.value);
    formData.append('examen_abdomen', this.textAbdomen.value);
    formData.append('examen_mien_sup', this.textMiembroSuperior.value);
    formData.append('examen_mien_inf', this.textMiembroInferior.value);
    formData.append('examen_genitales', this.textGenitales.value);
    formData.append('examen_gluteos', this.textGluteos.value);
    formData.append('examen_notas', this.textNotasExamen.value);
    formData.append('examen_mamas', this.textMamas.value); //Revisar mamas

    // Para-clínicos
    // const paraClinicos = this.cajasDeTexto.paraClinicos;
    formData.append('para_paraclinicos', this.textParaClinicos.value);
    formData.append('para_notas', this.textNotasClinicos.value);

    // Análisis
    // const analisis = this.cajasDeTexto.analisis;
    formData.append('analisis_analisis', this.textAnalisis.value);
    formData.append('analisis_notas', this.textNotasAnalisis.value);

    // Diagnostico
    // const diagnostico = this.cajasDeTexto.diagnostico;

    formData.append('diagnosticos_cie10', JSON.stringify(this.agregarValoresCie10()));
    formData.append('diagnostico_manual', this.textDiagnostico.value);
    formData.append('diagnostico_notas', this.textDiagnosticoNotas.value);

    // Plan
    // const plan = this.cajasDeTexto.plan;
    formData.append('plan_plan', this.textPlan.value);
    formData.append('plan_notas', this.textPlanNotas.value);

    // General
    // const generales = this.cajasDeTexto.generales;
    formData.append('general_notas', this.textNotasGenerales.value);

    formData.append('paciente_id', this.pacienteId)



    let url = 'historiaclinicas';
    console.log(this.guardarClausurarEditar);

    if (this.guardarClausurarEditar === 'clausurar') {
      formData.append('accion', 'clausurar');
    } else {
      formData.append('accion', 'guardar');
    }


    this.apiServices.postJsonData(url, formData)
      .subscribe(data => {
        console.log(data);
        this.alertMsg.showAlert = true;
        if (data !== undefined && data.success !== undefined && data.success === true) {
          this.alertMsg.msg = data.response;
          this.alertMsg.class = 'alert alert-success';

          const historia = data.historiaclinica;

          this.alertMsg.msg = data.response + '. Código: ' + historia.codigo;
          this.codigoHistoria = historia.codigo;

          if (historia.clausurada === 1) {
            this.clausurada = true;
            this.deshabilitarControles();
            this.desBtnBorrarArchivo = true;
            this.desBtnBorrarFoto = true;
          } else {
            this.clausurada = false;
          }

          if (historia.alergico === 'Si') {
            this.esAlergico = true;
          } else {
            this.esAlergico = false;
          }

          this.registroEncontrado = true;
          this.registroGuardado = true;
          //Almacenar códigos CIE10
          const codigosCieE10 = historia.diagnosticos_cie10;
          const nombreObjeto = 'ingreso_' + data.historiaclinica.codigo;
          this.setObjectInStorage(nombreObjeto, codigosCieE10)

        } else {
          this.alertMsg.class = 'alert alert-danger';
          this.alertMsg.msg = data.response;
        }

      }, dataError => {
        console.log(dataError);
        if (dataError.error.response !== undefined) {
          this.mostrarMsgError(dataError.error.response);
        } else {
          this.mostrarMsgError('Error al intentar procesar los datos enviados');
        }

        const errors = GetMsgFromError(dataError);
        console.log(errors);

        this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
          errors.errors
        );

        this.saltarValidacion = false;
        console.log(this.serverErrorsInfo);
        // this.cerrarVentana();
        window.scroll(0, 0);
      }, () => {
        console.log('finally')
        window.scroll(0, 0);
      });
  }


  guardarHistoria() {
    this.submitted = true;
    this.deshabilitarValidaciones();
    this.alertMsg.showAlert = false;
    this.alertMsg.msg = '';
    console.log(this.formData);
    this.enviarDatos();
  }

  // Aplicar validaciones a un control

  agregarValoresCie10() {
    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    const lengthRows = this.cie10Rows['controls'].length;
    const diagnosticos_cie10 = [];
    if (lengthRows > 0) {
      for (let i = 0; i < lengthRows; i++) {
        const cie10CodigoControl: any = this.cie10Rows.controls[i]['controls']['cie10Codigo'];
        if (cie10CodigoControl.value !== null && cie10CodigoControl.value.length > 0) {
          //  console.log({ codigo: cie10CodigoControl.value, titulo: cie10TituloControl.value });
          const codigo = { codigo: cie10CodigoControl.value };
          // console.log(codigo);
          diagnosticos_cie10.push(codigo);
        }
      }
    }

    return diagnosticos_cie10;
    // const historiaClinica = JSON.parse(historiaJson.historia_json);

    // console.log(this.cajasDeTexto.diagnostico.diagnosticos_cie10);
  }

  // btnLogin_click($event) {
  //   if ($event.loggedIn === true && $event.userInfo !== null) {
  //     //Validar la sesión actual con el usuario que intenta clausurar
  //     this.clausuararHistoria();
  //     this.verVentanas.mostrarVentanaLogin = false;
  //   }
  // }

  public virtual: any = {
    itemHeight: 28
  };

  handleFilterCie10($event, index) {
    if ($event === '' || $event === null || $event === undefined) {
      return false;
    }
    this.buscandoCodigoCie = true;
    console.log({ Valor_seleccionado: $event });
    this.delay(() => {
      this.apiServices.getData('cies?codigo=' + $event + '&titulo=' + $event + '&filtro=or', 'Cies', false)
        .subscribe(data => {
          console.log(data);
          if (data.success === true) {
            this.dataCies = data['Cies'];
            console.log(this.dataCies);
          }
          this.buscandoCodigoCie = false;
        }, error => {
          this.buscandoCodigoCie = false;
          console.log(error);
        }, () => {

        });

    }, 2500, '');

  }

  public valueChange(value: any, index): void {
    console.log('valueChange', value);
    if (value === '' || value === undefined || value === null) {
      this.deleteCie10Row(index);
    }
    if (this.dataCies.length > 0) {
      if (this.verificarValoresRepetidos(value, index) && this.cie10Rows.length > 1) {
        console.log('******** valueChange')
        this.msjErrorCie10 = 'Error: código repetido';
      } else {
        console.log('******** valueChange vacio')
        this.msjErrorCie10 = '';
      }

      this.valoresCie10Agegados.push(value);
      // const item: any = this.dataCies[0];
      // if (value === item.codigo) {
      //   const cie10TituloControl: any = this.cie10Rows.controls[index]['controls']['cie10Titulo'] as FormControl;
      //   cie10TituloControl.setValue(item.titulo);
      // }
    } else {
      this.deleteCie10Row(index);
      //const cie10CodigoControl: any = this.cie10Rows.controls[index]['controls']['cie10Codigo'] as FormControl;
      //  const cie10TituloControl: any = this.cie10Rows.controls[index]['controls']['cie10Titulo'] as FormControl;
      // cie10CodigoControl.setValue('');
    }

    // console.log
  }


  delay = (function () {
    const timers = {};
    return function (callback, ms, label) {
      label = label || 'defaultTimer';
      window.clearTimeout(timers[label] || 0);
      timers[label] = window.setTimeout(callback, ms);
    };
  })();


  chkHabDesEdicion_click($event) {
    $event.preventDefault();
    this.cerrarVentana();
    this.alertMsg.showAlert = false;

    if (this.tipoUsuario !== 'ADMIN') {
      return false;
    }

    console.log($event);
    console.log(this.chkHabDesEdicion.nativeElement.checked);
    let respuesta = confirm("¿Está seguro?");
    if (respuesta === true) {

      this.apiServices.getData('historiaclinicas/descerrar/' + this.codigoHistoria, 'Historiaclinica', false).
        subscribe(data => {
          console.log(data);
          if (data !== null && data.success !== undefined && data.success === true) {
            this.clausurada = false;
            this.habilitarControles(['txtMestruacion']);
            this.desBtnGuardar = false;
          } else {
            alert('No fue posible habilitar la edición. Debe intentar de nuevo');
          }
          window.scroll(0, 0);
        }, error => {
          if (error.status !== 401) {
            if (error.error.response !== undefined) {
              this.mostrarMsgError(error.error.response);
            } else {
              this.mostrarMsgError('Error al intentar habilitar la edición, debe intentar nuevamente');
            }
          } else {
            this.mostrarMsgError('Error inesperado al intentar habilitar la edición, debe intentar nuevamente');
          }
          window.scroll(0, 0);
        }, () => {

        });
      // txt = "You pressed OK!";
    } else {
      if (this.chkHabDesEdicion.nativeElement.checked === true) {
        this.chkHabDesEdicion.nativeElement.checked = false;
      } else if (this.chkHabDesEdicion.nativeElement.checked === false) {
        this.chkHabDesEdicion.nativeElement.checked = true;
      }

    }

    return false;
  }


  resetearSubirArchivos() {
    this.ngModelNombreArchivo = '';
    this.ngModelArchivo = [];
    this.archivoParaClinico = undefined;
  }

  resetearSubirFotos() {
    this.ngModelNombreFoto = '';
    this.ngModelFoto = [];
    this.fotoExamenFisico = undefined;
  }

  // mostrarMsgError(msg: string) {
  //   this.alertMsg.class = 'alert alert-danger';
  //   this.alertMsg.showAlert = true;
  //   this.alertMsg.msg = msg;
  // }

  // mostrarMsgExito(msg: string) {
  //   this.alertMsg.class = 'alert alert-success';
  //   this.alertMsg.showAlert = true;
  //   this.alertMsg.msg = msg;
  // }


}
