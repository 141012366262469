import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { ServiciosService } from '../../servicios/servicios.service';

@Component({
  selector: 'app-datos-paciente-formula',
  templateUrl: './datos-paciente-formula.component.html',
  styleUrls: ['./datos-paciente-formula.component.css']
})
export class DatosPacienteFormulaComponent implements OnInit {

  @Input() datosPaciente : any;
  ngModelTxtNombrePaciente = '';
  ngModelTxtIdenPaciente = '';
  ngModelTxtFecha = '';
  // ngModelTxtEstadoCivilPaciente = '';
  // ngModelTxtGeneroPaciente = '';
  // ngModelTxtOcupacionPaciente = '';
  // ngModelTxtResidenciaPaciente = '';
  // ngModelTxtDireccionPaciente = '';
  // ngModelTxtTelefonoPaciente = '';
  // ngModelTxtFecNacPaciente = '';
  // ngModelTxtEdadPaciente = '';


  ngOnInit(): void {
  }

  ngAfterContentInit() {
    console.log(this.datosPaciente);
    this.ngModelTxtNombrePaciente = this.datosPaciente.nombre_completo;
    this.ngModelTxtIdenPaciente = this.datosPaciente.identificacion;
    this.ngModelTxtFecha = this.datosPaciente.fecha_actual;
    // this.ngModelTxtEstadoCivilPaciente = this.datosPaciente.EstadoCivil.nombre;
    // this.ngModelTxtGeneroPaciente = this.datosPaciente.Genero.nombre;
    // this.ngModelTxtOcupacionPaciente = this.datosPaciente.Ocupacion.nombre;
    // this.ngModelTxtResidenciaPaciente = this.datosPaciente.CiudadResidencia.nombre;
    // this.ngModelTxtDireccionPaciente = this.datosPaciente.direccion;
    // this.ngModelTxtTelefonoPaciente = this.datosPaciente.celular;
    // this.ngModelTxtFecNacPaciente = this.datosPaciente.fecha_nac;
    // this.ngModelTxtEdadPaciente = this.datosPaciente.edad;
  }



}
