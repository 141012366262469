import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MaxLength, OnlyIntegerNumbers, ImageValidator, MinLength, OnlyLetters } from 'src/app/validators/CustomValidators';
import { ServiciosService } from '../../servicios/servicios.service';
import { Router } from '@angular/router';
import { GetMsgFromError } from 'src/app/functions/SharedFunctions';
import { serverErrorsInfo } from 'src/app/utilities/serverErrorsInfo';
import { NotificationService } from '@progress/kendo-angular-notification';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-agregar-medico',
  templateUrl: './agregar-medico.component.html',
  styleUrls: ['./agregar-medico.component.css']
})
export class AgregarMedicoComponent implements OnInit {

  @ViewChild('appendTo', { read: ViewContainerRef }) public appendTo: ViewContainerRef;

  activarKTextbox: false
  formData: FormGroup;
  submitted = false;
  serverErrorsInfo: any = {};
  lstEspecialidades = [];

  spinners = {
    spinnerSave: false
  };

  uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint

  public myFiles: Array<any>;
  // public userImages: Array<FileInfo>;

  windowNotification = {
    msg: '',
    title: '',
    buttonMsg: 'Cerrar',
    status: false,
  };

  public userImages: Array<any>;

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  }

  public myRestrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png'],
    maxFileSize: 2097152
  };


  constructor(
    private router: Router,
    private apiServices: ServiciosService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder) {
    window.scrollTo(0, 0);
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'startsWith'
  };

  ngOnInit(): void {
    this.formData = this.formBuilder.group({
      txtIdentificacion: ['', [Validators.required, MaxLength(false, 20), OnlyIntegerNumbers()]],
      txtNombres: ['', [Validators.required,  MaxLength(false, 100)]],
      txtPassword: ['', [Validators.required, MinLength(false, 10), MaxLength(false, 30)]],
      txtNumRegistro: ['', [Validators.required, MaxLength(false, 20),  OnlyIntegerNumbers()]],
      txtApellidos: ['', [Validators.required, MaxLength(false, 100)]],
      txtCelular: ['', [Validators.required, MinLength(false, 10), MaxLength(false, 15), OnlyIntegerNumbers()]],
      txtEmail: ['', [Validators.required, MaxLength(false, 75), Validators.email]],
      txtDireccion: ['', [Validators.required, MaxLength(true, 200)]],

      //  txtFoto: [''],
      txtFoto: [''],
      // cboEspecialidad: ['', [Validators.required, MaxLength(false, 18), OnlyIntegerNumbers()]],
      cboEspecialidad: ['', [Validators.required]]
    });


    this.getEspecialidades();
  }

  modalAgregarEspecialidad_Click($event) {
    //console.log('modalAgregarEspecialidad_Click');
  }

  // Gets
  get txtIdentificacion() { return this.formData.get('txtIdentificacion'); }
  get txtNombres() { return this.formData.get('txtNombres'); }
  get txtPassword() { return this.formData.get('txtPassword'); }
  get txtNumRegistro() { return this.formData.get('txtNumRegistro'); }
  get txtApellidos() { return this.formData.get('txtApellidos'); }
  get txtDireccion() { return this.formData.get('txtDireccion'); }
  get txtCelular() { return this.formData.get('txtCelular'); }
  // get cboEstados() { return this.formData.get('cboEstados'); }
  get txtEmail() { return this.formData.get('txtEmail'); }
  get txtFoto() { return this.formData.get('txtFoto'); }
  get cboEspecialidad() { return this.formData.get('cboEspecialidad'); }

  onSubmit() {

    this.alertMsg.showAlert = false;

    //console.log(this.formData);

    //console.log(this.formData.get('txtFoto').value[0]);

    this.submitted = true;

    if (this.formData.invalid) {
      this.mostrarMsgError('El formulario contiene errores, debe verificar e intentar de nuevo');
      this.serverErrorsInfo.serverErrorsInfo = false;
      return false;
    }

    this.spinners.spinnerSave = true;
    const formData = new FormData();

    formData.append('identificacion', this.formData.get('txtIdentificacion').value);
    formData.append('nombres', this.formData.get('txtNombres').value);
    formData.append('password', this.formData.get('txtPassword').value);
    formData.append('apellidos', this.formData.get('txtApellidos').value);
    formData.append('direccion', this.formData.get('txtDireccion').value);
    formData.append('celular', this.formData.get('txtCelular').value);
    formData.append('email', this.formData.get('txtEmail').value);
    formData.append('num_registro', this.formData.get('txtNumRegistro').value);
    if( this.formData.get('txtFoto').value!== null) {
      formData.append('foto', this.formData.get('txtFoto').value[0]);
    } else {
      formData.append('foto', '');
    }
    formData.append('especialidades', JSON.stringify(this.formData.get('cboEspecialidad').value));

    this.apiServices.postFormData(formData, 'medicos')
      .subscribe(res => {
        const response = res;
        //  //console.log(response);
        this.serverErrorsInfo.serverErrorsInfo = false;

        if (response !== null && response.success !== undefined && response.success === true) {
          this.alertMsg.class = 'alert alert-success';
          this.limpiarControles();
          this.submitted = false;
          this.serverErrorsInfo.serverErrorsInfo = false;
        } else {
          this.alertMsg.class = 'alert alert-danger';
        }

        this.alertMsg.showAlert = true;
        this.alertMsg.msg = response.response;

      },  error => {
        //  if (error.status !== 401) {
     //   this.spinners.mostrarSpinner = false;

        this.spinners.spinnerSave = false;

        if (error.error.response !== undefined) {
          this.mostrarMsgError(error.error.response);
        } else {
          this.mostrarMsgError('Error al intentar guardar el registro');
        }

        const errors = GetMsgFromError(error);
        console.log(errors);

        this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
          errors.errors
        );

        this.serverErrorsInfo.serverErrorsInfo = true;
        console.log(this.serverErrorsInfo);
        //  this.spinners.mostrarSpinner = false;


        // this.spinners.mostrarSpinner = false;
         window.scrollTo(0, 0);
      },
        () => {
          //console.log('Finally');
          this.spinners.spinnerSave = false;
          window.scrollTo(0, 0);
        }
      );

  }

  public showSuccess(msg: string): void {
    this.notificationService.show({
      appendTo: this.appendTo,
      content: '' + msg,
      hideAfter: 600,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 400 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'success', icon: true },
      closable: true
    });
  }

  public showError(msg: string): void {
    this.notificationService.show({
      appendTo: this.appendTo,
      content: '' + msg,
      hideAfter: 600,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 400 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'error', icon: true },
      closable: true
    });
  }

  limpiarControles(omitir = []) {
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).reset();
        this.formData.get(element).updateValueAndValidity();
      }
    });
    
  }

  onFileSelect(event) {

    const file = (event.target as HTMLInputElement).files[0];

    //  //console.log(event.target.files.length);
    // //console.log(event.target.files);
    // //console.log(event.target.value);
    // if (event.target.files.length > 0) {
    // const file = event.target.files[0];
    //console.log(file);
    //this.formData.get('txtFoto').setValue(file);
    //}
  }

  cerrarVentana($event) {
    this.alertMsg.showAlert = false;
  }

  getEspecialidades() {
    this.apiServices.getData('especialidads', 'Especialidads', false)
      .subscribe(data => {
        //  //console.log(data);
        if (data !== null && data.success !== undefined && data.success === true) {
          this.lstEspecialidades = data['Especialidads'];
          //  //console.log(this.lstEspecialidades);
        }
      }, error => {
        if (error.status !== 401) {
          console.log('Error al intentar cargar las especialidades, debe intentar nuevamente');
        } else {
          console.log('Error: ' + error.status);
        }
      }, () => {

      });
  }

  mostrarMsgError(msg: string) {
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }
}
