import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { CanActivateGuardianGuard } from './guards/can-activate-guardian.guard';
import { AgregarMedicoComponent } from './medicos/agregar-medico/agregar-medico.component';
import { ListadoMedicosComponent } from './medicos/listado-medicos/listado-medicos.component';
import { DetalleMedicoComponent } from './medicos/detalle-medico/detalle-medico.component';
// import { AgregarPacienteComponent } from './pacientes/agregar-paciente/agregar-paciente.component';
import { AgregarPacientePotencialComponent } from './pacientes-potenciales/agregar-paciente-potencial/agregar-paciente-potencial.component';
import { ListadoPacientesPotencialesComponent } from './pacientes-potenciales/listado-pacientes-potenciales/listado-pacientes-potenciales.component';
import { DetallePacientesPotencialesComponent } from './pacientes-potenciales/detalle-paciente-potencial/detalle-paciente-potencial.component';

import { ListadoPacientesComponent } from './pacientes/listado-pacientes/listado-pacientes.component';
import { DetallePacienteComponent } from './pacientes/detalle-paciente/detalle-paciente.component';
import { HistoriaPacienteComponent } from './pacientes/historia-paciente/historia-paciente.component';
import { AgregarCitasComponent } from './citas/agregar-citas/agregar-citas.component';
import { ListadoCitasComponent } from './citas/listado-citas/listado-citas.component';
import { VerReportesComponent } from './reportes/ver-reportes/ver-reportes.component';
//import { ListadoCie10Component } from './cie10/listado-cie10/listado-cie10.component';
//import { AgregarCie10Component } from './cie10/agregar-cie10/agregar-cie10.component';
//import { AgregarCirugiaComponent } from './cirugias/agregar-cirugia/agregar-cirugia.component';
import { ListadoCirugiasComponent } from './cirugias/listado-cirugias/listado-cirugias.component';

import { ListadoControlesComponent } from './controles/listado-controles/listado-controles.component';

import { ListadoEvolucionesComponent } from './evoluciones/listado-evoluciones/listado-evoluciones.component';
//import { AgregarIncapacidadComponent } from './incapacidades/agregar-incapacidad/agregar-incapacidad.component';
import { ListadoIncapacidadesComponent } from './incapacidades/listado-incapacidades/listado-incapacidades.component';
//import { AgregarFormulaComponent } from './formulas/agregar-formula/agregar-formula.component';
import { ListadoFormulasComponent } from './formulas/listado-formulas/listado-formulas.component';
import { AgregarUsuarioComponent } from './usuarios/agregar-usuario/agregar-usuario.component';
import { ListadoUsuariosComponent } from './usuarios/listado-usuarios/listado-usuarios.component';
import { DetalleUsuarioComponent } from './usuarios/detalle-usuario/detalle-usuario.component';
import { AuditoriasComponent } from './auditorias/auditorias/auditorias.component';
import { FormCirugiasComponent } from './cirugias/form-cirugias/form-cirugias.component';
import { FormIncapacidadesComponent } from './incapacidades/form-incapacidades/form-incapacidades.component';
import { FormFormulasComponent } from './formulas/form-formulas/form-formulas.component';
import { FormControlesComponent } from './controles/form-controles/form-controles.component';
import { FormEvolucionesComponent } from './evoluciones/form-evoluciones/form-evoluciones.component';
import { AgendaMedicosComponent } from './medicos/agenda-medicos/agenda-medicos.component';
import { FormCiesComponent } from './cie10/form-cies/form-cies.component';
import { ListadoCiesComponent } from './cie10/listado-cies/listado-cies.component';
import { FormGenerosComponent } from './generos/form-generos/form-generos.component';
import { ListadoGenerosComponent } from './generos/listado-generos/listado-generos.component';
import { FormGruposSanComponent } from './grupossan/form-grupos-san/form-grupos-san.component';
import { ListadoGruposSanComponent } from './grupossan/listado-grupos-san/listado-grupos-san.component';
import { FormTiposIdeComponent } from './tiposide/form-tipos-ide/form-tipos-ide.component';
import { ListadoTiposIdsComponent } from './tiposide/listado-tipos-ids/listado-tipos-ids.component';
import { FormEstadosCivComponent } from './estadosciv/form-estados-civ/form-estados-civ.component';
import { ListadoEstadosCivComponent } from './estadosciv/listado-estados-civ/listado-estados-civ.component';
import { FormNivelesEscComponent } from './nivelesesc/form-niveles-esc/form-niveles-esc.component';
import { ListadoNivelesEscComponent } from './nivelesesc/listado-niveles-esc/listado-niveles-esc.component';
import { FormDepartamentosComponent } from './departamentos/form-departamentos/form-departamentos.component';
import { ListadoDepartamentosComponent } from './departamentos/listado-departamentos/listado-departamentos.component';
import { FormEpsComponent } from './eps/form-eps/form-eps.component';
import { ListadoEpsComponent } from './eps/listado-eps/listado-eps.component';
import { FormFondosPensionComponent } from './fondospension/form-fondos-pension/form-fondos-pension.component';
import { ListadoFondosPensionComponent } from './fondospension/listado-fondos-pension/listado-fondos-pension.component';
import { FormEspecialiadadesComponent } from './especialidades/form-especialiadades/form-especialiadades.component';
import { ListadoEspecialdiadesComponent } from './especialidades/listado-especialdiades/listado-especialdiades.component';
import { FormConsultoriosComponent } from './consultorios/form-consultorios/form-consultorios.component';
import { ListadoConsultoriosComponent } from './consultorios/listado-consultorios/listado-consultorios.component';
import { ListadoTipoCitasComponent } from './tiposcitas/listado-tipo-citas/listado-tipo-citas.component';
import { FormTiposCitasComponent } from './tiposcitas/form-tipos-citas/form-tipos-citas.component';
import { DatosEmpresaComponent } from './empresa/datos-empresa/datos-empresa.component';
import { FormCiudadesComponent } from './ciudades/form-ciudades/form-ciudades.component';
import { ListadoCiudadesComponent } from './ciudades/listado-ciudades/listado-ciudades.component';
import { FormOcupacionesComponent } from './ocupaciones/form-ocupaciones/form-ocupaciones.component';
import { ListadoOcupacionesComponent } from './ocupaciones/listado-ocupaciones/listado-ocupaciones.component';
import { FormCountriesComponent } from './countries/form-countries/form-countries.component';
import { ListadoCountriesComponent } from './countries/listado-countries/listado-countries.component';




const routes: Routes = [
  { path: '', component: ListadoPacientesComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'AgregarMedico', component: AgregarMedicoComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoMedicos', component: ListadoMedicosComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'DetalleMedico', component: DetalleMedicoComponent, canActivate: [CanActivateGuardianGuard] },
  
  
  { path: 'AgregarPacientePotencial', component: AgregarPacientePotencialComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoPacientesPotenciales', component: ListadoPacientesPotencialesComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'DetallePacientesPotenciales', component: DetallePacientesPotencialesComponent, canActivate: [CanActivateGuardianGuard] },

  { path: 'DetallePaciente', component: DetallePacienteComponent , canActivate: [CanActivateGuardianGuard]},
  // { path: 'pacientes',loadChildren: './pacientes/pacientes.module#PacientesModule'},
  // { path: 'cies', loadChildren: () => import('./cies/cies.module').then(m => m.CiesModule)},

  { path: 'ListadoPacientes', component: ListadoPacientesComponent , canActivate: [CanActivateGuardianGuard]},
  { path: 'DetallePaciente', component: DetallePacienteComponent , canActivate: [CanActivateGuardianGuard]},
  { path: 'HistoriaPaciente', component: HistoriaPacienteComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'AgregarCitas', component: AgregarCitasComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoCitas', component: ListadoCitasComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'VerReportes', component: VerReportesComponent, canActivate: [CanActivateGuardianGuard] },
 // { path: 'ListadoCie10', component: ListadoCie10Component, canActivate: [CanActivateGuardianGuard] },
 // { path: 'AgregarCie10', component: AgregarCie10Component, canActivate: [CanActivateGuardianGuard] },
  // { path: 'AgregarCirugia', component: AgregarCirugiaComponent , canActivate: [CanActivateGuardianGuard]},
  { path: 'ListadoCirugias', component: ListadoCirugiasComponent, canActivate: [CanActivateGuardianGuard] },
 
  { path: 'ListadoControles', component: ListadoControlesComponent, canActivate: [CanActivateGuardianGuard] },
 
  { path: 'ListadoEvoluciones', component: ListadoEvolucionesComponent, canActivate: [CanActivateGuardianGuard] },
  //{ path: 'AgregarIncapacidad', component: AgregarIncapacidadComponent , canActivate: [CanActivateGuardianGuard]},
  { path: 'ListadoIncapacidades', component: ListadoIncapacidadesComponent, canActivate: [CanActivateGuardianGuard] },
  //{ path: 'AgregarFormula', component: AgregarFormulaComponent , canActivate: [CanActivateGuardianGuard]},
  { path: 'ListadoFormulas', component: ListadoFormulasComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'AgregarUsuario', component: AgregarUsuarioComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoUsuarios', component: ListadoUsuariosComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'DetalleUsuario', component: DetalleUsuarioComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'Auditorias', component: AuditoriasComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormCirugias', component: FormCirugiasComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormIncapacidades', component: FormIncapacidadesComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormFormulas', component: FormFormulasComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormControles', component: FormControlesComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormEvoluciones', component: FormEvolucionesComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'AgendaMedicos', component: AgendaMedicosComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormCies', component: FormCiesComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoCies', component: ListadoCiesComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormGeneros', component: FormGenerosComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoGeneros', component: ListadoGenerosComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormGruposSan', component: FormGruposSanComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoGruposSan', component: ListadoGruposSanComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormTiposIde', component: FormTiposIdeComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoTiposIds', component: ListadoTiposIdsComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormEstadosCiv', component: FormEstadosCivComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoEstadosCiv', component: ListadoEstadosCivComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormNivelesEsc', component: FormNivelesEscComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoNivelesEsc', component: ListadoNivelesEscComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormDepartamentos', component: FormDepartamentosComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoDepartamentos', component: ListadoDepartamentosComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormEps', component: FormEpsComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoEps', component: ListadoEpsComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormFondosPension', component: FormFondosPensionComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoFondosPension', component: ListadoFondosPensionComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormEspecialidades', component: FormEspecialiadadesComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoEspecialidades', component: ListadoEspecialdiadesComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormConsultorios', component: FormConsultoriosComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoConsultorios', component: ListadoConsultoriosComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormConsultorios', component: FormTiposCitasComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoConsultorios', component: ListadoTipoCitasComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormTiposCitas', component: FormTiposCitasComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoTiposCitas', component: ListadoTipoCitasComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormDatosEmpresa', component: DatosEmpresaComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoCiudades', component: ListadoCiudadesComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormCiudades', component: FormCiudadesComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormOcupaciones', component: FormOcupacionesComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoOcupaciones', component: ListadoOcupacionesComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'FormCountries', component: FormCountriesComponent, canActivate: [CanActivateGuardianGuard] },
  { path: 'ListadoCountries', component: ListadoCountriesComponent, canActivate: [CanActivateGuardianGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
