import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Input,
  AfterViewInit,
  ElementRef,
  Output,
  EventEmitter,
  AfterContentInit
} from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {
  MaxLength,
  OnlyIntegerNumbers,
  MinLength,
  OnlyLetters
} from 'src/app/validators/CustomValidators';
import { ServiciosService } from '../../servicios/servicios.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GetMsgFromError } from 'src/app/functions/SharedFunctions';
import { serverErrorsInfo } from 'src/app/utilities/serverErrorsInfo';
import { NotificationService } from '@progress/kendo-angular-notification';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { environment } from 'src/environments/environment';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { TransferService } from 'src/app/servicios/transfer.service';
import { EncryptionService } from 'src/app/servicios/encryption.service';

@Component({
  selector: 'app-detalle-medico',
  templateUrl: './detalle-medico.component.html',
  styleUrls: ['./detalle-medico.component.css']
})
export class DetalleMedicoComponent implements OnInit, AfterViewInit, AfterContentInit {
  @ViewChild('appendTo', { read: ViewContainerRef })
  public appendTo: ViewContainerRef;
  @Input() inputMedicoId = 0;
  @ViewChild('imgFoto', { static: false }) imgFoto: ElementRef;
  @Output() ValueChanged = new EventEmitter<any>();
  @Output() OutputCerrarVentana = new EventEmitter<any>();

  activarKTextbox: false;
  formData: FormGroup;
  submitted = false;
  serverErrorsInfo: any = {};
  public imagePath;
  imgURL: any;
  public message: string;
  mostrarVentanaConfirmacion = false;
  spinnerActivo = true;
  tipoUsuario = '';
  spinners = {
    mostrarSpinner: false
  };
  usuarioId = 0;
  lstEspecialidades = [];

  baseUrl2 = environment.baseUrl2;

  queryParams: any;

  public myFiles: Array<any>;
  // public userImages: Array<FileInfo>;

  loadedData = false;
  medicoId = 0;
  registroEncontrado = false;
  estadoMedico = '';

  windowNotification = {
    msg: '',
    title: '',
    buttonMsg: 'Cerrar',
    status: false
  };

  nombreCompleto = '';

  public userImages: Array<any>;

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  };

  public myRestrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png'],
    maxFileSize: 2097152
  };

  public lstEstados: Array<{ text: string; value: string }> = [
    { text: 'Activo', value: 'Activo' },
    { text: 'Bloqueado', value: 'Bloqueado' }
  ];

  customData = {
    especialdiades: []
  };

  fotos = {
    mini: '',
    original: ''
  };

  constructor(
    private router: Router,
    private apiServices: ServiciosService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private encryption: EncryptionService,
    private transferService: TransferService,
    private formBuilder: FormBuilder
  ) {
    window.scrollTo(0, 0);
    // console.log('aqui');
    // const navigation = this.router.getCurrentNavigation();
    // this.queryParams = navigation.extras.queryParams;
    // console.log(this.queryParams);
    this.activatedRoute.queryParams.subscribe(params => {
      const medicoId = params.medicoId;
      this.medicoId = medicoId;
      console.log(medicoId);
      // this.getData(medicoId);
    });
  }

  ngAfterContentInit() {
    this.spinnerActivo = true;
    //this.medicoId = this.inputMedicoId;
    this.getData(this.medicoId);

    this.tipoUsuario = this.apiServices.getTipoUsuario();
  }

  ngAfterViewInit(): void {

  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'startsWith'
  };


  ngOnInit(): void {
    this.formData = this.formBuilder.group({
      txtIdentificacion: [
        '',
        [Validators.required, MaxLength(false, 20), OnlyIntegerNumbers()]
      ],
      txtNumRegistro: [
        '',
        [Validators.required, OnlyIntegerNumbers()]
      ],
      txtNombres: ['', [Validators.required, MaxLength(false, 100)]],
      cboEstados: ['', [Validators.required]],
      txtApellidos: ['', [Validators.required, MaxLength(false, 100)]],
      txtCelular: [
        '',
        [Validators.required, MaxLength(false, 15), MinLength(false, 10), OnlyIntegerNumbers()]
      ],
      txtEmail: ['', [Validators.required, MaxLength(false, 75), Validators.email]],
      txtDireccion: ['', [Validators.required, MaxLength(true, 200)]],
      txtPassword: ['', [MinLength(true, 10), MaxLength(true, 30)]],
      //  txtFoto: [''],
      txtFoto: [
        ''
      ],
      cboEspecialidad: ['', [Validators.required]]
    });

    this.getEspecialidades();
  }

  modalAgregarEspecialidad_Click($event) {
    console.log('modalAgregarEspecialidad_Click');
  }

  // Gets
  get txtIdentificacion() {
    return this.formData.get('txtIdentificacion');
  }

  get txtNumRegistro() {
    return this.formData.get('txtNumRegistro');
  }

  get txtNombres() {
    return this.formData.get('txtNombres');
  }
  get txtApellidos() {
    return this.formData.get('txtApellidos');
  }
  get txtDireccion() {
    return this.formData.get('txtDireccion');
  }
  get txtPassword() {
    return this.formData.get('txtPassword');
  }
  get txtCelular() {
    return this.formData.get('txtCelular');
  }
  get txtEmail() {
    return this.formData.get('txtEmail');
  }
  get txtFoto() {
    return this.formData.get('txtFoto');
  }
  get cboEstados() {
    return this.formData.get('cboEstados');
  }
  get cboEspecialidad() {
    return this.formData.get('cboEspecialidad');
  }

  btnGuardarCambios() {
    this.submitted = true;
    if (this.formData.invalid) {
      this.serverErrorsInfo.serverErrorsInfo = false;
      return false;
    }

    this.mostrarVentanaConfirmacion = true;
  }

  onSubmit() {
    this.alertMsg.showAlert = false;

    // console.log(this.formData);

    //  console.log(this.formData.get('txtFoto').value[0]);
    // if (this.formData.get('txtPassword').value.toString().trim === '') {
    //   this.formData.get('txtPassword').disable();
    // }

    this.mostrarVentanaConfirmacion = false;

    this.submitted = true;

    if (this.formData.invalid) {
      this.mostrarMsgError('El formulario contiene errores, debe verificar e intentar de nuevo');
      this.serverErrorsInfo.serverErrorsInfo = false;
      //  this.spinners.mostrarSpinner = false;
      return false;
    }

    this.spinners.mostrarSpinner = true;
    const formData = new FormData();

    formData.append(
      'identificacion',
      this.formData.get('txtIdentificacion').value
    );
    formData.append('id', this.medicoId.toString());
    formData.append('nombres', this.formData.get('txtNombres').value);
    formData.append('apellidos', this.formData.get('txtApellidos').value);
    formData.append('direccion', this.formData.get('txtDireccion').value);
    formData.append('num_registro', this.formData.get('txtNumRegistro').value);
    // if (this.formData.get('txtPassword').value) {
    formData.append('password', this.formData.get('txtPassword').value);
    // }
    if (this.tipoUsuario === 'ADMIN') {
      formData.append('estado', this.formData.get('cboEstados').value);
    } else {
      formData.append('estado', 'Activo');
    }

    formData.append('celular', this.formData.get('txtCelular').value);
    formData.append('email', this.formData.get('txtEmail').value);

    if( this.formData.get('txtFoto').value!== null) {
      formData.append('foto', this.formData.get('txtFoto').value[0]);
    } else {
      formData.append('foto', '');
    }

   // formData.append('foto', this.formData.get('txtFoto').value[0]);
    // formData.append(
    //   'especialidad_id',
    //   this.formData.get('cboEspecialidad').value
    // );

    formData.append('especialidades', JSON.stringify(this.formData.get('cboEspecialidad').value));

    this.apiServices
      .putFormData(formData, 'medicos/' + this.medicoId)
      .subscribe(
        res => {
          const response = res;
          console.log(response);

          if (response !== null && response.success !== undefined && response.success === true) {

            const item = response['Medico'];
            this.alertMsg.class = 'alert alert-success';
            this.ValueChanged.emit(true);
            this.estadoMedico = item.estado;
            this.submitted = false;
            const usuarioId = item.id;
            this.serverErrorsInfo.serverErrorsInfo = false;


            let userInfo = this.apiServices.getUserInfoFromToken();
            userInfo = userInfo.userInfo;
            const idUserLogin = userInfo['id'];
            console.log(idUserLogin);

            if (Number(idUserLogin) === Number(usuarioId)) {
              console.log('Ingreso aquí');
              this.transferService.setData({ fotoIconosPerfil: response.urlFotoMiniatura });
              if (this.formData.get('txtPassword').value !== '') {
                const password = this.formData.get('txtPassword').value;
                localStorage.setItem('LOCAL_USER2', this.encryption.encryptUsingAES256(password));
              }
            }

          } else {
            this.alertMsg.class = 'alert alert-danger';
          }

          this.serverErrorsInfo.serverErrorsInfo = false;
          this.alertMsg.showAlert = true;
          this.alertMsg.msg = response.response;
          //  console.log(this.alertMsg);
        },
        error => {
          //  if (error.status !== 401) {
          this.spinners.mostrarSpinner = false;

          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError('Error al intentar guardar el registro');
          }

          const errors = GetMsgFromError(error);
          console.log(errors);

          this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
            errors.errors
          );

          this.serverErrorsInfo.serverErrorsInfo = true;
          console.log(this.serverErrorsInfo);
          //  this.spinners.mostrarSpinner = false;


          // this.spinners.mostrarSpinner = false;
           window.scrollTo(0, 0);
        },
        () => {
          console.log('Finally');
          this.spinners.mostrarSpinner = false;
          //  this.mostrarVentanaConfirmacion = false;
          window.scrollTo(0, 0);
        }
      );
  }

  bloquearMedico() {
    this.alertMsg.showAlert = false;
    this.spinners.mostrarSpinner = true;

    this.apiServices.bloquearRegistro('medicos/' + this.medicoId).subscribe(
      res => {
        const response = res;
        console.log(response);

        if (response.success === 'true') {
          this.alertMsg.class = 'alert alert-success';
          this.estadoMedico = response.estado;
        } else {
          this.alertMsg.class = 'alert alert-danger';
        }

        this.serverErrorsInfo.serverErrorsInfo = false;
        this.alertMsg.showAlert = true;
        this.alertMsg.msg = response.response;

        //  console.log(this.alertMsg);
      },
      error => {
        if (error.status !== 401) {
          this.alertMsg.showAlert = true;
          this.alertMsg.class = 'alert alert-danger';
          this.alertMsg.msg = 'Error al intentar guardar el registro';

          const errors = GetMsgFromError(error);
          console.log(errors);

          this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
            errors.errors
          );
          console.log(this.serverErrorsInfo);
          this.spinners.mostrarSpinner = false;
          // this.spinners.mostrarSpinner = false;
          // window.scrollTo(0, 0);
        } else {
          console.log('Error: ' + error.status);
        }
      },
      () => {
        console.log('Finally');
        this.spinners.mostrarSpinner = false;
        window.scrollTo(0, 0);
      }
    );
  }

  getData(medicoId: number) {
    // console.log(itemId);
    console.log({ medicoId: medicoId })
    if (medicoId !== undefined && Number(medicoId) > 0) {
      let urlParams = 'medicos/' + medicoId;
      // urlParams += 'id/' + medicoId;
      urlParams += '?espid=all';
      // let item: Item;
      this.apiServices.getData(urlParams, 'Medico', false).subscribe(
        data => {
          console.log({ registroEncontrado: this.registroEncontrado })
          console.log(data);
          if (data !== null && data.success !== undefined && data.success === true) {
            const item = data['Medico'];
            console.log(data);
            console.log({ registroEncontrado: this.registroEncontrado })
            this.registroEncontrado = true;

            //  Set Form Values
            this.txtIdentificacion.patchValue(item.identificacion);
            this.txtNombres.patchValue(item.nombres);
            this.txtApellidos.patchValue(item.apellidos);
            this.txtEmail.patchValue(item.email);
            this.txtCelular.patchValue(item.celular);
            this.txtDireccion.patchValue(item.direccion);
            this.cboEstados.patchValue(item.estado);
            this.medicoId = item.id;
            this.txtNumRegistro.patchValue(item.num_registro);
            this.nombreCompleto = ': ' + item.nombres + ' ' + item.apellidos;
            this.usuarioId = item.id;
            // this.estadoMedico = item.estado;
            //  this.fotos.original = item.foto;
            if (item.urlFotoMiniatura) {
              this.fotos.mini = this.baseUrl2 + '' + item.urlFotoMiniatura;
            } else {
              this.fotos.mini = '';
            }
            this.estadoMedico = item.estado;



            // Set accounts values
            if (
              item.Especialidads !== undefined &&
              Object.keys(item.Especialidads).length > 0
            ) {
              // this.initLoad.iva = false;
              this.formData
                .get('cboEspecialidad')
                .patchValue(item.Especialidads);


            }



          } else {
            this.registroEncontrado = false;
          }

          this.spinnerActivo = false;

          if (this.tipoUsuario !== 'ADMIN') {
            this.deshabilitarControles(['txtFoto', 'txtPassword']);
          }
        },
        error => {
          if (error.status !== 401) {
            console.log(error);
            const errors = GetMsgFromError(error);
            this.alertMsg.showAlert = true;
            this.alertMsg.class = 'alert alert-danger';
            this.alertMsg.msg = '' + errors.msg;
            this.spinnerActivo = false;
            this.registroEncontrado = false;

            //  console.log(errors);

            this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(errors.errors);
            console.log(this.serverErrorsInfo);
          } else {
            console.log('Error: ' + error.status);
          }
        },
        () => {
          console.log('Finally');
          //  this.loadedData = true;
          // this.spinners.mostrarSpinner = false;
        }
      );
    } else {
      //  this.loadingData = false;
      this.spinnerActivo = false;
      this.registroEncontrado = false;
      this.alertMsg.showAlert = true;
      this.alertMsg.class = 'alert alert-danger';
      this.alertMsg.msg = 'Error al intentar cargar el registro';
    }
  }

  deshabilitarControles(omitir = []) {
    // this.desBtnGuardar = true;
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).disable();
      }
    });
  }

  public btnCerrarVentanaConfirmacion() {
    this.mostrarVentanaConfirmacion = false;
  }
  public btnCerrarVentanaDetalle() {
    this.OutputCerrarVentana.emit(true);
  }
  public showSuccess(msg: string): void {
    this.notificationService.show({
      appendTo: this.appendTo,
      content: '' + msg,
      hideAfter: 600,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 400 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'success', icon: true },
      closable: true
    });
  }

  public showError(msg: string): void {
    this.notificationService.show({
      appendTo: this.appendTo,
      content: '' + msg,
      hideAfter: 600,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 400 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'error', icon: true },
      closable: true
    });
  }

  mostrarMsgError(msg: string) {
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  onFileSelect(event) {
    const file = (event.target as HTMLInputElement).files[0];

    //  console.log(event.target.files.length);
    // console.log(event.target.files);
    // console.log(event.target.value);
    // if (event.target.files.length > 0) {
    // const file = event.target.files[0];
    console.log(file);
    //this.formData.get('txtFoto').setValue(file);
    //}
  }

  preview(event) {
    const files = event.target as HTMLInputElement;


    const foto = this.formData.get('txtFoto').value[0];
    const reader = new FileReader();
    // this.imagePath = files;
    reader.readAsDataURL(foto);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      // const foto1 = window.document.getElementById('txtFoto') as HTMLInputElement;
      // foto1.src = this.imgURL;
      // console.log(this.imgFoto.nativeElement.src);
      //console.log(this.imgURL);
      this.imgFoto.nativeElement.src = this.imgURL;
      // console.log(this.imgFoto.nativeElement.src);
    }
  }

  getEspecialidades() {
    this.apiServices.getData('especialidads', 'Especialidads', false)
      .subscribe(data => {
        console.log(data);
        if (data !== null && data.success !== undefined && data.success === true) {
          this.lstEspecialidades = data['Especialidads'];
          // console.log(data);
          //  console.log(this.lstEspecialidades);

        }
      }, error => {
        console.log(error);
        if (error.status !== 401) {
          console.log('Error al intentar cargar las especialidades, debe intentar nuevamente');
        } else {
          console.log('Error: ' + error.status);
        }
      }, () => {

      });
  }
}
