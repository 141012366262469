import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-alert',
  templateUrl: './custom-alert.component.html',
  styleUrls: ['./custom-alert.component.css']
})
export class CustomAlertComponent implements OnInit {
  @Input() showAlert;
  @Input() alertClass;
  @Input() alertMsg;
  @Output() CerrarAlert = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
  }

  cerrarAlert() {
    this.CerrarAlert.emit(true);
  }

}
