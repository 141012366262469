import { ServiciosService } from './servicios/servicios.service';
import { EncryptionService } from './servicios/encryption.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TransferService } from './servicios/transfer.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit, AfterViewInit {
  title = 'clientecitas';
  isUserLoggedIn = false;
  formData: FormGroup;
  submitted = false;
  esComponentLogin = false;
  accionEjecutandose = false;

  infoUsuario = {
    fotoPerfil: '',
    nombreCompleto: '',
    tipo: ''
  }
  _subscription: any;
  baseUrl2 = environment.baseUrl2;

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private apiServices: ServiciosService,
    private transferService: TransferService,
    private encryption: EncryptionService,
    private ref: ChangeDetectorRef) {



    this._subscription = this.transferService.getData().subscribe(obj => {
      console.log(obj);
      console.log(obj.item);

      if (obj !== null && obj.item !== undefined && obj.data.length > 0 && obj.item == 'fotoIconosPerfil' ) {
        console.log(obj.data);
        // this.infoUsuario.fotoPerfil == data.fotoIconosPerfil;
        this.infoUsuario.fotoPerfil =  obj.data;
        //  console.log(this.infoUsuario.fotoPerfil);
        // this.infoUsuario.fotoPerfil.
      }
    });
    // localStorage.removeItem('LOGGED_IN');
    // localStorage.removeItem('JWT_TOKEN');
    // localStorage.removeItem('LOCAL_USER1');
    // localStorage.removeItem('LOCAL_USER2');

    // router.events.subscribe(ruta => {
    //   const path: any = ruta;
    //   if (path.url === '/Login') {

    //   }
    // });

    //  fontawesome.library.add(faTrashAlt);

    // localStorage.removeItem('loggedId');

    // ref.detach();
    // setInterval(() => {
    //   this.ref.detectChanges();
    // }, 5000);
    // this.router.navigateByUrl('/ListadoPacientes');
  }




  ngOnInit(): void {

    this.formData = this.formBuilder.group({
      txtPassword: ['', Validators.required],
      txtUsername: ['', Validators.required]
    });



    this.configurarInicioSesion();

  }
  get txtUsername() {
    return this.formData.get('txtUsername');
  }

  get txtPassword() {
    return this.formData.get('txtPassword');
  }


  cerrarSesion() {
    localStorage.clear();
    sessionStorage.clear();
    this.alertMsg.showAlert = false;
    //  localStorage.removeItem('LOGGED_IN');
    //  localStorage.removeItem('JWT_TOKEN');
    //  localStorage.removeItem('LOCAL_USER1');
    //  localStorage.removeItem('LOCAL_USER2');
    this.isUserLoggedIn = false;
    // window.location.reload();
    //this.router.navigate([''], {});
    return false;
  }

  iniciarSesion() {
    this.accionEjecutandose = true;
    localStorage.clear();
    sessionStorage.clear();
    this.submitted = true;
    if (this.formData.invalid) { return; }


    const username = this.formData.get('txtUsername').value;
    const password = this.formData.get('txtPassword').value;

    const formData = new FormData();
    formData.set('username', username);
    formData.set('password', password);

    try {
      this.apiServices.postFormData(formData, 'users/login')
        .subscribe(data => {
          console.log(data);
          this.alertMsg.showAlert = true;
          this.alertMsg.class = 'alert alert-danger';
          this.alertMsg.msg = 'Usuario o contraseña incorrecta';

          if (data === null) {
            return false;
          }

          if (data.loggedIn === true) {
            this.alertMsg.showAlert = false;
            //console.log(this.encryption.decryptUsingAES256(data.token));

            console.log(this.encryption.encryptUsingAES256('LOGGED_IN'));
            localStorage.setItem('LOGGED_IN', this.encryption.encryptUsingAES256('LOGGED_IN'));
            localStorage.setItem('JWT_TOKEN', data.token);
            if (data.token === '' || data.token === null || data.token === undefined) {
              throw new Error('Error al intentar iniciar sesión. Debe intentar nuevamente.')
            }
            localStorage.setItem('LOCAL_USER1', this.encryption.encryptUsingAES256(username));
            localStorage.setItem('LOCAL_USER2', this.encryption.encryptUsingAES256(password));
            this.alertMsg.showAlert = false;
            //   window.location.reload();
            //  this.configurarInicioSesion();

            //  this.btnGotoUrl('/ListadoPacientes');
            setTimeout( () => { 
              this.accionEjecutandose = false;
              window.location.href = '/';
             }, 1000);
            

            // let userInfo = this.apiServices.getUserInfoFromToken('', true);

            // const tipoUsuario = userInfo.tipo;
            // if (tipoUsuario === 'ADMIN') {
            //   this.infoUsuario.tipo = 'Admin';
            //   window.location.href = '/ListadoPacientes';
            // } else if (tipoUsuario === 'MEDICO') {
            //   window.location.href = '/ListadoPacientes';
            // } else if (tipoUsuario === 'OPERADOR') {
            //   window.location.href = '/ListadoPacientes';
            // }

            //  window.location.href = '/';
            // }}}window.location.href = '/ListadoPacientes';
          }

          this.alertMsg.msg = data.response;

        }, (error) => {
          this.alertMsg.showAlert = true;
          this.alertMsg.class = 'alert alert-danger';
          this.accionEjecutandose = false;
          console.log(error);
          if (error.error.response !== undefined) {
            this.alertMsg.msg = '' + error.error.response;
          } else {
            this.alertMsg.msg = 'Usuario o contraseña incorrecta';
          }

        }, () => {
          console.log('finally');
        });
    } catch (e) {
      console.log(e);
      this.accionEjecutandose = false;
      this.cerrarSesion();
    }

  }

  configurarInicioSesion() {
    try {

      if (localStorage.getItem('LOGGED_IN') !== null) {
        //  console.log('Por que entra aquí...');
        const JWT_TOKEN = localStorage.getItem('JWT_TOKEN');
        if (JWT_TOKEN === '' || JWT_TOKEN === null || JWT_TOKEN === undefined) {
          //   this.cerrarSesion();
          throw new Error('Se detectó una actividad sospechosa. Debe iniciar sesión nuevamente.')
        }
        const LOCAL_USER1 = localStorage.getItem('LOCAL_USER1');
        if (LOCAL_USER1 === '' || LOCAL_USER1 === null || LOCAL_USER1 === undefined) {
          //   this.cerrarSesion();
          throw new Error('Se detectó una actividad sospechosa. Debe iniciar sesión nuevamente.')
        }

        const LOCAL_USER2 = localStorage.getItem('LOCAL_USER2');
        if (LOCAL_USER2 === '' || LOCAL_USER2 === null || LOCAL_USER2 === undefined) {
          //  this.cerrarSesion();
          throw new Error('Se detectó una actividad sospechosa. Debe iniciar sesión nuevamente.')
        }

        const LOGGED_IN = localStorage.getItem('LOGGED_IN');
        if (LOGGED_IN === '' || LOGGED_IN === null || LOGGED_IN === undefined) {
          // this.cerrarSesion();
          throw new Error('Se detectó una actividad sospechosa. Debe iniciar sesión nuevamente.')
        }


        let userInfo = this.apiServices.getUserInfoFromToken(JWT_TOKEN, true);
        const tipoUsuario = userInfo.tipo;
        if (tipoUsuario === 'ADMIN') {
          this.infoUsuario.tipo = 'Admin';
        } else if (tipoUsuario === 'MEDICO') {
          this.infoUsuario.tipo = 'Médico(a)';
        } else if (tipoUsuario === 'OPERADOR') {
          this.infoUsuario.tipo = 'Operador(a)';
        }


        userInfo = userInfo.userInfo;

        if (userInfo.urlFotoMiniatura !== null) {
          // console.log('Entro 1');
          this.infoUsuario.fotoPerfil = this.baseUrl2 + '' + userInfo.urlFotoMiniatura;
        } else {
          //console.log('Entro 2');
          this.infoUsuario.fotoPerfil = '';
        }


        console.log(this.infoUsuario.fotoPerfil);

        this.infoUsuario.nombreCompleto = userInfo.nombres + ' ' + userInfo.apellidos;
        const loggedIn = this.encryption.decryptUsingAES256(localStorage.getItem('LOGGED_IN'));

        if (loggedIn === 'LOGGED_IN') {
          this.isUserLoggedIn = true;
          // this.apiServices.gotoUrl('/ListadoPacientes');
        } else {
          this.isUserLoggedIn = false;
          // this.cerrarSesion();
          //    this.apiServices.gotoUrl('/');
        }

      } else {
        // this.isUserLoggedIn = false;
        this.cerrarSesion();
      }
    } catch (e) {
      console.log(e);
      this.cerrarSesion();
    }
  }


  btnVerPerfil_click() {
    let userInfo = this.apiServices.getUserInfoFromToken();
    userInfo = userInfo.userInfo;
    console.log(userInfo);


    let url = '';
    let queryParams = {};
    if (this.apiServices.getTipoUsuario() === 'MEDICO') {
      url = 'DetalleMedico';
      queryParams = {
        medicoId: userInfo.id
      };
    } else {
      url = 'DetalleUsuario';
      queryParams = {
        usuarioId: userInfo.id
      };
    }
    // if(this.apiServices.getTipoUsuario())


    this.router.navigate([url], { queryParams });
    return false;

  }
  // preview(event) {
  //   // const files = event.target as HTMLInputElement;

  //   //   console.log(this.formData.get('txtFoto').value[0]);
  //   // console.log(files.length);

  //   //  if (files.length === 0)
  //   //    return;

  //   // const mimeType = files[0].type;
  //   // if (mimeType.match(/image\/*/) == null) {
  //   //   this.message = 'Only images are supported.';
  //   //   return;
  //   // }

  //   const foto = this.formData.get('txtFoto').value[0];
  //   const reader = new FileReader();
  //   // this.imagePath = files;
  //   reader.readAsDataURL(foto);
  //   reader.onload = (_event) => {
  //     this.imgURL = reader.result;
  //     // const foto1 = window.document.getElementById('txtFoto') as HTMLInputElement;
  //     // foto1.src = this.imgURL;
  //     // console.log(this.imgFoto.nativeElement.src);
  //     //console.log(this.imgURL);
  //     this.imgFoto.nativeElement.src = this.imgURL;
  //     // console.log(this.imgFoto.nativeElement.src);
  //   }
  // }

  ngAfterViewInit() {


  }

  ngOnDestroy() {
    //prevent memory leak when component destroyed
    this._subscription.unsubscribe();
  }

  btnGotoUrl(url: string) {
    this.router.navigate([url]);
    return false;
  }

}
