import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ServiciosService } from '../../servicios/servicios.service';
import { error } from 'protractor';

@Component({
  selector: 'app-ver-reportes',
  templateUrl: './ver-reportes.component.html',
  styleUrls: ['./ver-reportes.component.css']
})
export class VerReportesComponent implements OnInit {

  formData: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private apiServices: ServiciosService) { }

  dataReportes: Array<{ value: string, text: string }> = [
    { value: 'lstPacientes', text: 'Listado de pacientes registrados x mes' },
    { value: 'lstCitas', text: 'Listado de citas registradas x mes' },
    { value: 'lstMedicos', text: 'Listado de médicos registrados x mes' },
  ];

  lstDataReportFields = []
  accionEjecutandose = false;
  lstDataReport = [];
  nombreArchivo = '';

  data = [];
  columns = [];

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  };

  queries = {
    lstPacientes: ''
  }

  ngOnInit(): void {
    window.scroll(0, 0);

    this.formData = this.formBuilder.group({
      cboMeses: [''],
      cboReportes: ['']
    });

    //    this.getData();
  }

  public showSuccess(msg: string): void {
    this.alertMsg.showAlert = true;
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.msg = msg;
  }

  public showError(msg: string): void {
    this.alertMsg.showAlert = true;
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.msg = msg;
  }

  cboReportes_change($event) {

  }




  generarReporte(componente: any) {
    const mes = this.formData.get('cboMeses').value;
    const reporte = this.formData.get('cboReportes').value;
    

    if (reporte === '' || mes === '') {
      this.showError('Debe seleccionar un mes y un reporte');
      return false;
    }
    this.accionEjecutandose = true;
    const tempDate = new Date(mes);
    const fecha = tempDate.toISOString();
    console.log(fecha);

    console.log(reporte);

    switch (reporte) {

      case 'lstPacientes':
        this.lstDataReportFields = [];
        this.lstDataReportFields.push({ titulo: 'Tipo de identificacion', field: 'TipoDocumento.nombre' });
        this.lstDataReportFields.push({ titulo: 'Identificación', field: 'identificacion' });
        this.lstDataReportFields.push({ titulo: 'Nombres', field: 'nombres' });

        this.lstDataReportFields.push({ titulo: 'Apellidos', field: 'apellidos' });
        this.lstDataReportFields.push({ titulo: 'Genero', field: 'Genero.nombre' });
        this.lstDataReportFields.push({ titulo: 'Estatura (metros)', field: 'estatura' });

        this.lstDataReportFields.push({ titulo: 'Peso (KG)', field: 'peso' });
        this.lstDataReportFields.push({ titulo: 'Grupo sanguíneo', field: 'GrupoSanguineo.nombre' });
        this.lstDataReportFields.push({ titulo: 'Celular', field: 'celular' });

        this.lstDataReportFields.push({ titulo: 'Email', field: 'email' });
        this.lstDataReportFields.push({ titulo: 'Estado civl', field: 'EstadoCivil.nombre' });
        this.lstDataReportFields.push({ titulo: 'Número de hijos', field: 'num_hijos' });

        this.lstDataReportFields.push({ titulo: 'Nivel escolaridad', field: 'Escolaridad.nombre' });
        this.lstDataReportFields.push({ titulo: 'Ocupación', field: 'Ocupacion.nombre' });
        this.lstDataReportFields.push({ titulo: 'Fecha de nacimiento', field: 'fecha_nac' });

        this.lstDataReportFields.push({ titulo: 'Edad', field: 'edad' });
        this.lstDataReportFields.push({ titulo: 'Departamento de residencia', field: 'DepartamentoResidencia.nombre' });
        this.lstDataReportFields.push({ titulo: 'Ciudad de residencia', field: 'CiudadResidencia.nombre' });

        this.lstDataReportFields.push({ titulo: 'Dirección', field: 'direccion' });
        this.lstDataReportFields.push({ titulo: 'EPS', field: 'Eps.nombre' });
        this.lstDataReportFields.push({ titulo: 'Fondo de pensión', field: 'FondoPension.nombre' });

        this.lstDataReportFields.push({ titulo: 'Estado actual', field: 'estado' });
        this.lstDataReportFields.push({ titulo: 'Notas', field: 'notas' });

        let urlPacaientes = 'pacientes?grpsa=all&tipdoc=all&estciv=all&genero=all&ocup=all&fecha=' + fecha;
        urlPacaientes += '&nivesc=all&ciures=all&depres=all&eps=all&fndpe=all&acomp=all&respo=all&reporte=true';

        this.getDataReport(urlPacaientes, componente, 'Pacientes');

        break;

      case 'lstCitas':

        let urlCitas = 'citas/index?page=1&medico=all&paciente=all&consultorio=all&reporte=true&fecha=' + fecha;
        this.lstDataReportFields = [];
        this.lstDataReportFields.push({ titulo: 'Código', field: 'codigo' });
        // this.lstDataReportFields.push({ titulo: 'Médico', field: 'nombre_medico' });
        // this.lstDataReportFields.push({ titulo: 'Paciente', field: 'nombre_paciente' });
        this.lstDataReportFields.push({ titulo: 'Consultorio', field: 'Consultorio.nombre' });
        this.lstDataReportFields.push({ titulo: 'Fecha', field: 'fecha_cita' });
        this.lstDataReportFields.push({ titulo: 'Hora', field: 'horario' });
        this.lstDataReportFields.push({ titulo: 'Estado', field: 'status' });
        this.getDataReport(urlCitas, componente, 'Citas');
        break;

      case 'lstMedicos':

        let urlMedicos = 'medicos?page=1&espid=all&reporte=true&fecha=' + fecha;
        this.lstDataReportFields = [];
        this.lstDataReportFields.push({ titulo: 'Identificación', field: 'identificacion' });
        this.lstDataReportFields.push({ titulo: 'Nombres', field: 'nombre_completo' });
       // this.lstDataReportFields.push({ titulo: 'Especialdiad', field: 'nombre_paciente' });
        this.lstDataReportFields.push({ titulo: 'Email', field: 'email' });
        this.lstDataReportFields.push({ titulo: 'Celular', field: 'celular' });
        this.lstDataReportFields.push({ titulo: 'Estado', field: 'estado' });

        this.getDataReport(urlMedicos, componente, 'Medicos');
        break;
    }


    //  componente.save();
  }

  getDataReport(url, componente, lista) {



    this.apiServices.getData(url, lista, false)
      .subscribe(data => {

        this.data = data;
        console.log(data);

        if (data !== null && data[lista] !== undefined && data.success === true) {
          console.log(data);
          this.lstDataReport = data[lista];
          console.log(this.lstDataReport);
          setTimeout(() => { componente.save(); }, 2);
        } else {
          this.mostrarMsgError('Error al intentar generar el reporte, debe intentar nuevamente');
        }


      }, error => {
        if (error.error.response !== undefined) {
          this.mostrarMsgError(error.error.response);
        } else {
          this.mostrarMsgError('Error al intentar generar el reporte, debe intentar nuevamente');
        }
        this.accionEjecutandose = false;
      }, () => {
        console.log('finally');
        this.accionEjecutandose = false;
      });
  }

  enviarDatos() {

  }

  mostrarMsgError(msg: string) {
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }
}
