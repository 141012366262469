import { Component, OnInit, ElementRef, ViewChild, AfterContentInit } from '@angular/core';
import { GridDataResult, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ServiciosService } from '../../servicios/servicios.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
// import { TransferServiceService } from '../../servicios/tra';
import { ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { EncryptionService } from '../../servicios/encryption.service';
import { Observable } from 'rxjs/Rx';

@Component({
  selector: 'app-listado-pacientes',
  templateUrl: './listado-pacientes.component.html',
  styleUrls: ['./listado-pacientes.component.css']
})
export class ListadoPacientesComponent implements OnInit, AfterContentInit {
  // Filters
  @ViewChild('txtId', { static: false }) txtId: ElementRef;
  @ViewChild('txtName', { static: false }) txtName: ElementRef;
  @ViewChild('txtReference', { static: false }) txtReference: ElementRef;
  @ViewChild(GridComponent, { static: false }) grid: GridComponent;
  // @ViewChild('grid') grid: ElementRef;


  // var grid = $('#Grid').data('kendoGrid')


  public gridView: GridDataResult;
  public buttonCount = 15;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  public skip = 0;
  currentPage = 1;
  public multiple = true;
  public allowUnsort = true;
  windowFilters = false;
  public sort: SortDescriptor[] = [];
  windowFormFilters: FormGroup;
  loading = true;
  queryString = '';
  queryStringTotales = '';
  showWindowFilters = false;

  medicoId = 0;
  medicoNombre = '';
  dataGridViewIndex = 0;
  dataGridViewItem: any;
  nombrePaciente = '';

  queryContain = ''
  paginaInicio = 0;

  //  loading = true;

  // showItemDetails = false;

  public splitButtonHistoriaClinica: Array<any> = [
    {
      text: 'Datos básicos', url: 'DetallePaciente', tipo: 'detalle'
    }, {
      text: 'Ingreso', url: 'HistoriaPaciente'
    }
  ];

  //Operador
  public splitButtonHistoriaClinicaOperador: Array<any> = [
    {
      text: 'Datos básicos', url: 'DetallePaciente', tipo: 'detalle'
    }
  ];

  public splitButtonCirugias: Array<any> = [
    {
      text: 'Agregar descripción', url: 'FormCirugias', tipo: 'nuevo'
    }, {
      text: 'Historial de cirúgias', url: 'ListadoCirugias'
    }
  ];

  public splitButtonControles: Array<any> = [
    // {
    //   text: 'Agregar control', url: 'AgregarControl', tipo:'nuevo'
    // },

    {
      text: 'Agregar control', url: 'FormControles', tipo: 'nuevo'
    }, {
      text: 'Historial de controles', url: 'ListadoControles'
    }
  ];

  public splitButtonIncapacidades: Array<any> = [
    {
      text: 'Agregar incapacidad', url: 'FormIncapacidades', tipo: 'nuevo'
    }, {
      text: 'Historial de incapacidades', url: 'ListadoIncapacidades'
    }
  ];

  public splitButtonEvoluciones: Array<any> = [
    // {
    //   text: 'Agregar eveloción', url: 'AgregarEvolucion', tipo:'nuevo'
    // },

    {
      text: 'Agregar eveloción', url: 'FormEvoluciones', tipo: 'nuevo'
    }, {
      text: 'Historial de evoluciones', url: 'ListadoEvoluciones'
    }
  ];

  public splitButtonFormulas: Array<any> = [
    {
      text: 'Generar formula', url: 'FormFormulas', tipo: 'nuevo'
    }, {
      text: 'Historial de formulas', url: 'ListadoFormulas'
    }
  ];


  verVentanaDetallePaciente = false;
  verVentanaHistoriaPaciente = false;


  // dataPricesList: Array<{ id: string, name: string }>;
  // dataUnitMeasurements: Array<{ id: string, name: string }>;
  // dataCategories: Array<{ id: string, name: string }>;
  // datawarehouses: Array<{ id: string, name: string }>;
  // dataAccounts: Array<{ id: string, name: string }>;
  // dataTaxes: Array<{ id: string, name: string }>;

  public lstStatus: Array<{ text: string, value: number }> = [
    { text: 'Activo', value: 1 },
    { text: 'Inactivo', value: 0 }
  ];

  // loading = true;
  userInfo = '';
  tipoUsuario = '';
  desBtnGuardar = false;

  lstData: any[];
  tempLstData: any[];
  public pageSize = 10;
  totalPages = 0;
  pageCount = 0;
  gridData: any[];
  // gridData: GridDataResult;


  constructor(
    private apiServices: ServiciosService,
    private formBuilder: FormBuilder,
    private location: Location,
    private encryption: EncryptionService,

    //   private transfereService: TransferServiceService,

    private router: Router) {


    // this.encryption.encryptUsingAES256('nelson');
    //  let x =  this.encryption.decryptUsingAES256(this.encryption.encryptUsingAES256('nelson'));
    // console.log(x);



  }

  ngOnInit() {


    this.windowFormFilters = this.formBuilder.group({
      txtFiltro: ['']
    });
    window.scroll(0, 0);

    let token = this.apiServices.getToken();
    // token = this.encryption.decryptUsingAES256(token);
    let userInfo = this.apiServices.getUserInfoFromToken(token, true);
    this.userInfo = userInfo.userInfo;
    this.tipoUsuario = userInfo.tipo.toUpperCase();




  }

  /* 
    @ViewChildren(MyComponent) childrenComponent: QueryList<MyComponent>;

  public ngAfterViewInit(): void
  {
    this.childrenComponent.changes.subscribe((comps: QueryList<MyComponent>) =>
    {
      // Now you can access the child component
    });
  }
}
  */
  ngAfterContentInit() {

    //  this.apiServices.refreshToken().
    // subscribe(item => {
    //   console.log('Refresh...');
    //   console.log(item);
    // }, error => {
    //   console.log(error);
    // }, () => {
    //   console.log('finally');
    // });


    this.removerVariablesSessionStorage();

    setTimeout(_ => {

      // const paginaActiva = sessionStorage.getItem('ListadoPacientesPaginaActiva');
      const queryString = sessionStorage.getItem('queryStringListadoPacientes');

      console.log(queryString);

      if (queryString === null) {
        this.getData('pacientes?page=1' + this.queryContain);
      } else {
        this.getData(queryString);
      }

      const valFiltro = sessionStorage.getItem('valFiltroListadoPacientes');
      this.windowFormFilters.get('txtFiltro').value;
      if (valFiltro !== null) {
        this.windowFormFilters.get('txtFiltro').setValue(valFiltro);
      }

      const filaActiva = sessionStorage.getItem('ListadoPacientesFilaActiva');
      console.log({ filaActiva: filaActiva });
      if (filaActiva !== null) {
        console.log({ filaActiva: filaActiva });
        this.grid.expandRow(Number(filaActiva))
      }
      /*   const paginaActiva = sessionStorage.getItem('ListadoPacientesPaginaActiva');
         console.log({ paginaActiva: paginaActiva });
         if (paginaActiva !== null) {
           this.getData('pacientes?page=' + paginaActiva + this.queryContain);
         } else {
           this.getData('pacientes?page=1' + this.queryContain);
         }*/

    }, 0);
  }

  removerVariablesSessionStorage() {
    sessionStorage.removeItem('ListadoControlesPaginaActiva');
    sessionStorage.removeItem('ListadoControlesFilaActiva');

    sessionStorage.removeItem('ListadoCirugiasPaginaActiva');
    sessionStorage.removeItem('ListadoCirugiasFilaActiva');

    sessionStorage.removeItem('ListadoEvolucionesPaginaActiva');
    sessionStorage.removeItem('ListadoEvolucionesFilaActiva');

    sessionStorage.removeItem('ListadoIncapacidadesPaginaActiva');
    sessionStorage.removeItem('ListadoIncapacidadesFilaActiva');

    sessionStorage.removeItem('ListadoFormulasPaginaActiva');
    sessionStorage.removeItem('ListadoFormulasFilaActiva');
  }

  removerVariablesLocalesSessionStorage() {
    sessionStorage.removeItem('valFiltroListadoPacientes');
    sessionStorage.removeItem('queryStringListadoPacientes');
    //  sessionStorage.removeItem('ListadoPacientesPaginaActiva');
    sessionStorage.removeItem('ListadoPacientesFilaActiva');
    this.apiServices.collapseAll(this.grid, this.currentPage, this.pageSize, Number(this.paginaInicio));
  }

  onSubmitFilters() {

    this.loading = true;
    this.queryString = '';

    console.log(this.windowFormFilters);
    // console.log(this.queryString);

    const valFiltro = this.windowFormFilters.get('txtFiltro').value;
    sessionStorage.setItem('valFiltroListadoPacientes', valFiltro);

    if (valFiltro.length === 0) {
      this.removerVariablesLocalesSessionStorage();
      this.getData('pacientes?page=1' + this.queryContain);
      return false;
    }



    let queryString = '';
    if (this.queryString.length === 0) {

      queryString = 'pacientes?page=1';
      this.queryString += '&nombres=' + valFiltro;
      this.queryString += '&identificacion=' + valFiltro;
      this.queryString += this.queryContain;

      queryString = queryString + this.queryString;
    }

    //  sessionStorage.removeItem('queryStringListadoPacientes');

    // this.removerVariablesSessionStorage();


    //  this.queryString = queryString;

    console.log(queryString);


    // console.log(this.queryString);

    this.getData(queryString);



    // console.log(this.dataW);

    // this.postItem(this.itemData);
  }

  btnCerrarDetalleMedico() {
    //  this.getData(this.queryStringTotales);
    this.verVentanaDetallePaciente = false;
  }

  eventoCerrarVentana($event) {
    this.verVentanaDetallePaciente = false;
  }

  eventoEditarMedico($itemEditado = false) {
    console.log($itemEditado);
    if ($itemEditado === true) {
      this.getData(this.queryStringTotales);
    }



  }
  btnNuevoPaciente_click(url: string) {
    const queryParams = {
      tipo: 'nuevo'
    };
    this.router.navigate([url], { queryParams });
    return false;
  }


  btnVerDetallePaciente(dataItem, index) {
    this.verVentanaDetallePaciente = true;
    const queryParams = {
      pacienteId: dataItem.id
    };
    this.router.navigate(['DetallePaciente'], { skipLocationChange: false, queryParams });
    return false;
  }

  btnVerHistoriaPaciente(dataItem, index) {
    // this.verVentanaHistoriaPaciente = true;
    const queryParams = {
      pacienteId: dataItem.id
    };
    this.router.navigate(['HistoriaPaciente'], { skipLocationChange: false, queryParams });
    return false;
  }

  btnVerListadoCirgurias_click(dataItem, index) {
    // this.verVentanaHistoriaPaciente = true;
    const queryParams = {
      pacienteId: dataItem.id
    };
    this.router.navigate(['ListadoCirugias'], { skipLocationChange: false, queryParams });
    return false;
  }

  btnBotonesAccion_click(dataItem, url) {
    // this.verVentanaHistoriaPaciente = true;
    const queryParams = {
      pacienteId: dataItem.id,
      tipo: 'detalle'
    };
    this.router.navigate([url], { skipLocationChange: false, queryParams });
    return false;
  }

  btnUrlBotonesAccionItem_click(item, dataItem) {
    console.log(item);
    console.log(dataItem);
    const url = item.url;
    const queryParams = {
      pacienteId: dataItem.id,
      tipo: item.tipo
    };
    this.router.navigate([url], { skipLocationChange: false, queryParams });
    return false;
  }


  btnVerListadoControles_click(dataItem, index) {
    //  this.verVentanaHistoriaPaciente = true;
    const queryParams = {
      pacienteId: dataItem.id
    };
    this.router.navigate(['ListadoControles'], { skipLocationChange: false, queryParams });
    return false;
  }


  btnAddInvoice() {
    // this.router.navigateByUrl('/AddItem');
    this.router.navigateByUrl('/AddInvoice');
  }

  showFilters() {
    if (this.showWindowFilters) {
      this.showWindowFilters = false;
    } else {
      this.showWindowFilters = true;
    }
    // this.showWindowFilters = val;
  }

  //#region   **************************** collapseAll
  public collapseAll(grid: any) {
    const data: any = this.grid.data;
    Object.keys(data.data).forEach((index) => {
      console.log(index);
      this.grid.collapseRow(Number(index));
    })
  }

  onCellClick(event) {

    // event.dataItem.name = 'hola'; //Modificar fila
    // this.collapseAll(this.grid);
    // this.grid.expandRow(event.index);
    // this.collapseAll(this.grid);
    console.log('onCellClick');
    console.log({ grd: this.grid });
    console.log({ event: event });
    console.log('index ' + event.index);
    this.apiServices.collapseAll(this.grid, this.currentPage, this.pageSize, Number(this.paginaInicio));
    sessionStorage.setItem('ListadoPacientesFilaActiva', event.index)
    //  sessionStorage.setItem('ListadoPacientesPaginaActiva', '' + this.currentPage)
    // sessionStorage.setItem('ListadoPacienteExpandRow', event.rowIndex);
    console.log(event.dataItem);
    // event.sender.expandRow(event.rowIndex);
  }
  //#endregion  collapseAll


  public dataStateChange(state: DataStateChangeEvent): void {
    console.log({ 'state': state });
    this.loading = true;

    //sessionStorage.removeItem('ListadoPacientesFilaActiva');
    console.log(sessionStorage.getItem('ListadoPacientesFilaActiva'));
    //  sessionStorage.removeItem('ListadoPacientesPaginaActiva');
    this.skip = state.skip;
    this.pageSize = state.take;
    this.sort = state.sort;

    if (this.skip === 0) {
      this.skip = 1;
    }
    //  this.skip = 10;
    this.currentPage = Math.floor(this.skip / this.pageSize);
    this.currentPage++;
    // sessionStorage.setItem('ListadoPacientesPaginaActiva', '' + this.currentPage);
    // this.grid.pa

    let queryString = 'pacientes?page=' + this.currentPage;

    // if (this.sort.length > 0) {
    //   const sortDir = this.sort[0].dir;
    //   let sortField = this.sort[0].field;
    //   // console.log(sortField);
    //   if ('' + sortField === 'GrupoSanguineo.nombre') {
    //     sortField = 'grpid';
    //   } else if ('' + sortField === 'nombre_completo') {
    //     sortField = 'nombres';
    //   }
    //   queryString += '&sortfield=' + sortField + '&sortdir=' + sortDir;
    // }

    if (this.queryString.length === 0) {
      queryString += this.queryContain;
    }

    queryString = queryString + this.queryString;
    console.log(queryString);

    this.getData(queryString);


    // this.grid.collapseRow(1);

    setTimeout(_ => {
      this.apiServices.collapseAll(this.grid, this.currentPage, this.pageSize, Number(this.paginaInicio));
    }, 0);

    //this.onSubmitFilters(this.currentPage);
  }


  /*public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.getData('?page=' + this.currentPage);
  } */

  public filterChange(filter: CompositeFilterDescriptor): void {
    console.log(filter);
  }

  // openWindowFilters() {
  //   // this.getData();

  // }

  // closeWindowFilters() {
  //   // this.windowFormFilters.reset();
  //   this.showWindowFilters = false;
  // }

  // public close(status) {
  //   // console.log(`Dialog result: ${status}`);
  //   // this.messageEvent.emit(false);
  //   this.itemDetails.showItemDetails = false;
  // }

  getData(queryString: string = '') {
    this.queryStringTotales = queryString;
    console.log({ 'queryStringTotales': this.queryStringTotales });
    sessionStorage.setItem('queryStringListadoPacientes', this.queryStringTotales);
    this.apiServices.getData(queryString, 'Pacientes', false)
      // .retry(4)
      .subscribe(
        data => {

          // if (data.sucess === true && data['Pacientes'] !== null) {
          this.lstData = this.tempLstData = data['Pacientes'];

          if (data !== null && data.success === true && this.lstData !== null) {

            console.log(data.paginationInfo);

            this.totalPages = data.paginationInfo.count;

            this.gridView = {
              data: this.lstData,
              total: this.totalPages
            };

            // Paginación inteligente

            if (data.paginationInfo !== undefined) {
              this.pageSize = Number(data.paginationInfo.perPage);
              const page = Number(data.paginationInfo.page);
              const perPage = Number(data.paginationInfo.perPage);
              console.log((page * perPage) - perPage);
              const saltar = (page * perPage) - perPage;
              this.skip = saltar;
              this.paginaInicio = data.paginationInfo.start;
            }
          } else {
            this.removerVariablesSessionStorage();
            this.removerVariablesLocalesSessionStorage();
            this.gridView = {
              data: [],
              total: 0
            };
          }

          this.loading = false;

        }, (error) => {
          console.log(error);
          this.loading = false;
          // this.loading = false;
        });


  }


  /*public sliderChange(pageIndex: number): void {
    this.skip = (pageIndex - 1) * this.pageSize;
    console.log(pageIndex);
  } */

  /*  public pageChange(event: PageChangeEvent): void {

     //  this.grid.nativeElement.notifyDataChange();

     // console.log(currentPage);


     this.skip = event.skip;
     this.pageSize = event.take;

     this.loading = true;
     this.skip = event.skip;




     /* if (this.queryString === '') {
        this.queryString += '?page=' + this.currentPage;
      } else {
        this.queryString += '&page=' + this.currentPage;
      }*/

  //if (this.totalPages > 0 && this.currentPage <= this.pageCount) {





  // this.notifyDataChange();

  // this.getData(this.queryString);
  //}

  public exportToPDF(grid: GridComponent): void {
    grid.saveAsPDF();
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  btnClick() {
    // this.router.navigateByUrl('/AddItem');
    this.router.navigateByUrl('/AddInvoice');
  }


  searchItemById() {
    this.currentPage = 1;
    this.loading = true;
    let queryString = '';
    let id = '';
    let name = '';
    let reference = '';

    if (this.txtId.nativeElement.value.trim()) {
      id = 'id=' + this.txtId.nativeElement.value.trim();
    } else {
      id = '';
    }

    if (this.txtName.nativeElement.value.trim()) {
      name = 'name=' + this.txtName.nativeElement.value.trim();
    } else {
      name = '';
    }

    if (this.txtReference.nativeElement.value.trim()) {
      reference = 'reference=' + this.txtReference.nativeElement.value.trim();
    } else {
      reference = '';
    }

    if (id !== '' || name !== '' || reference !== '') {
      queryString = '?' + id + '&' + name + '&' + reference;
    }

    // this.getData();
    const page = 1;
    //this.onSubmitFilters(page);
    this.skip = 0;

    // console.log(this.currentPage);
  }

  applyFilters() {

  }

  // viewItem(id, rowIndex) {
  //   console.log(id);

  //   // this.itemDetails.itemId = id;
  //   this.loading = true;
  //   this.getItem(id);
  // }


  deleteItem(id, rowIndex) {
    // console.log($event);
    console.log(rowIndex);

    // this.grid.collapseRow(rowIndex);



    this.loading = true;
    this.apiServices.deleteData('pacientes', id).subscribe(
      data => {

        console.log(data);
        this.grid.collapseRow(rowIndex);

        this.loading = false;
        // this.loading = false;

      }, (error) => {
        console.log(error);
        this.loading = false;

        // this.loading = false;
      }, () => {
        this.loading = false;
      });

  }

  showAll() {
    this.skip = 0;
    this.windowFormFilters.reset();
    this.loading = true;

    this.getData('pacientes');
  }


}
