import * as CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';

 // Autor:https://github.com/brix/crypto-js/issues/154
 // https://stackoverflow.com/questions/36909746/aes-ctr-encrypt-in-go-and-decrypt-in-cryptojs
// https://www.geeksforgeeks.org/how-to-encrypt-and-decrypt-a-php-string/
// https://github.com/brix/crypto-js/issues/154
@Injectable({
  providedIn: 'root'
})

export class EncryptionService {

  KEY = CryptoJS.enc.Hex.parse("021a5809551a2ce422c293dcaa4fe4fc");
  IV = CryptoJS.enc.Hex.parse("a1924d624b641532c52ecb9fe04617e6");
  secret = "012345678DDA9abcdef0123456789abcdef";

  //var key = CryptoJS.enc.Hex.parse("0123456789abcdef0123456789abcdef");
  // var iv = CryptoJS.enc.Hex.parse("abcdef9876543210abcdef9876543210");

  // encrypted(value) {
  //   var encrypted = CryptoJS.AES.encrypt(value, this.KEY, {iv : this.IV});
  //   //and the ciphertext put to base64
  //   encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  // }

  // constructor() { }
  // set(value) {
  //   var key = CryptoJS.enc.Utf8.parse(this.KEY);
  //   var iv = CryptoJS.enc.Utf8.parse(this.KEY);
  //   var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
  //     {
  //       keySize: 128 / 8,
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7
  //     });

  //   return encrypted.toString();
  // }

  // //The get method is use for decrypt the value.
  // get(value) {
  //   var key = CryptoJS.enc.Utf8.parse(this.KEY);
  //   var iv = CryptoJS.enc.Utf8.parse(this.KEY);
  //   var decrypted = CryptoJS.AES.decrypt(value, key, {
  //     keySize: 128 / 8,
  //     iv: iv,
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7
  //   });

  //   return decrypted.toString(CryptoJS.enc.Utf8);
  // }

  encryptUsingAES256(val) {
    let _key = this.KEY;
    let _iv = this.IV;
    let encrypted = CryptoJS.AES.encrypt(
      val, _key, {
      iv: _iv
    });

  //  console.log(encrypted.toString());
    //encrypted = encrypted.toString();
    return encrypted.toString();
  }


  decryptUsingAES256(val) {
    let _key = this.KEY;
    let _iv = this.IV;

    let decrypted = CryptoJS.AES.decrypt(
      val, _key, {
      iv: _iv
      // mode: CryptoJS.mode.CBC,
      // padding: CryptoJS.pad.ZeroPadding
    }).toString(CryptoJS.enc.Utf8);

    // let decrypted = CryptoJS.AES.decrypt(
    //   value, _key, {
    //   keySize: 16,
    //   iv: _iv,
    //   mode: CryptoJS.mode.ECB,
    //   padding: CryptoJS.pad.Pkcs7
    // }).toString(CryptoJS.enc.Utf8);
   return decrypted;
  }
}