import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterContentInit,
} from "@angular/core";
import {
  GridDataResult,
  DataStateChangeEvent,
  GridComponent,
} from "@progress/kendo-angular-grid";
import {
  SortDescriptor,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import { ServiciosService } from "../../servicios/servicios.service";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// import { TransferServiceService } from '../../servicios/tra';
import { ChangeDetectorRef } from "@angular/core";
import { Location } from "@angular/common";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-listado-citas",
  templateUrl: "./listado-citas.component.html",
  styleUrls: ["./listado-citas.component.css"],
})
export class ListadoCitasComponent implements OnInit, AfterContentInit {
  // Filters
  @ViewChild("txtId", { static: false }) txtId: ElementRef;
  @ViewChild("txtName", { static: false }) txtName: ElementRef;
  @ViewChild("txtReference", { static: false }) txtReference: ElementRef;
  @ViewChild(GridComponent, { static: false }) grid: GridComponent;

  // var grid = $('#Grid').data('kendoGrid')

  public gridView: GridDataResult;
  public buttonCount = 15;
  public info = true;
  public type: "numeric" | "input" = "numeric";
  public pageSizes = true;
  public previousNext = true;
  public skip = 0;
  currentPage = 1;
  public multiple = true;
  public allowUnsort = true;
  windowFilters = false;
  public sort: SortDescriptor[] = [];
  windowFormFilters: FormGroup;
  loading = true;
  queryString = "";
  queryStringTotales = "";
  showWindowFilters = false;
  generandoReporte = false;
  medicoId = 0;
  medicoNombre = "";
  dataGridViewIndex = 0;
  dataGridViewItem: any;
  ngModelMotivoCancelacion = "";
  userInfo: any;
  tipoUsuario = "";
  paginaInicio = 0;
  lstMedicos = [];
  statustoSearch = ["Activo", "Bloqueado"];
  allowCustom = true;
  listItems: Array<string> = [
    "Filtrar por código de la cita o identificación del médico o paciente",
    "Filtrar por medico",
    "Filtrar por estado de cita",
  ];
  showMe1: boolean = false;
  showMe2: boolean = false;
  showMe3: boolean = false;
  public valueChange(value: any): void {
    console.log("valueChange", value);
    if (
      value ===
      "Filtrar por código de la cita o identificación del médico o paciente"
    ) {
      this.showMe1 = true;
      this.showMe2 = false;
      this.showMe3 = false;
    } else if (value === "Filtrar por medico") {
      this.showMe2 = true;
      this.showMe3 = false;
      this.showMe1 = false;
    } else if (value === "Filtrar por estado de cita") {
      this.showMe3 = true;
      this.showMe1 = false;
      this.showMe2 = false;
    } else {
      this.showMe1 = false;
      this.showMe2 = false;
      this.showMe3 = false;
    }
  }

  queryContain = "&medico=all&paciente=all&consultorio=all";

  detalleCita = {
    verVentanaDetalleCita: false,
    nomMedico: "",
    idenMedico: "",
    celMedico: "",
    nomPaciente: "",
    idenPaciente: "",
    celPaciente: "",
    fechaCita: "",
    horaCita: "",
    nomConsultorio: "",
    celConsultorio: "",
    telConsultorio: "",
    dirConsultorio: "",
    codCita: 0,
  };
  baseUrl2 = environment.baseUrl2;
  codigoCita = 0;
  idCita = 0;

  mostrarVentanaConfirmacion = false;

  alertMsg = {
    showAlert: false,
    msg: "",
    class: "",
  };

  //  loading = true;

  // showItemDetails = false;

  // dataPricesList: Array<{ id: string, name: string }>;
  // dataUnitMeasurements: Array<{ id: string, name: string }>;
  // dataCategories: Array<{ id: string, name: string }>;
  // datawarehouses: Array<{ id: string, name: string }>;
  // dataAccounts: Array<{ id: string, name: string }>;
  // dataTaxes: Array<{ id: string, name: string }>;

  public lstStatus: Array<{ text: string; value: number }> = [
    { text: "Activo", value: 1 },
    { text: "Inactivo", value: 0 },
  ];

  // loading = true;

  lstData: any[];
  tempLstData: any[];
  public pageSize = 10;
  totalPages = 0;
  pageCount = 0;
  gridData: any[];
  // gridData: GridDataResult;

  constructor(
    private apiServices: ServiciosService,
    private formBuilder: FormBuilder,
    private location: Location,
    //   private transfereService: TransferServiceService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit() {
    //  this.getData('citas/index?page=1' + this.queryContain);

    this.windowFormFilters = this.formBuilder.group({
      txtFiltro: [""],
    });

    let token = this.apiServices.getToken();
    // token = this.encryption.decryptUsingAES256(token);
    let userInfo = this.apiServices.getUserInfoFromToken(token, true);
    this.userInfo = userInfo.userInfo;
    //    console.log({userInfoMenu: userInfo });

    this.tipoUsuario = userInfo.tipo.toUpperCase();
  }

  ngAfterContentInit() {
    //  this.apiServices.refreshToken().
    // subscribe(item => {
    //   console.log('Refresh...');
    //   console.log(item);
    // }, error => {
    //   console.log(error);
    // }, () => {
    //   console.log('finally');
    // });

    //  this.removerVariablesSessionStorage();

    setTimeout((_) => {
      // const paginaActiva = sessionStorage.getItem('ListadoPacientesPaginaActiva');

      const queryString = sessionStorage.getItem("queryStringListadoCitas");

      console.log(queryString);

      if (queryString === null) {
        this.getData("citas/index?page=1" + this.queryContain);
        this.getDataMed();
      } else {
        this.getData(queryString);
        this.getDataMed();
      }

      const valFiltro = sessionStorage.getItem("valFiltroListadoCitas");
      this.windowFormFilters.get("txtFiltro").value;
      if (valFiltro !== null) {
        this.windowFormFilters.get("txtFiltro").setValue(valFiltro);
      }

      // const filaActiva = sessionStorage.getItem('ListadoCitasFilaActiva');
      // console.log({ filaActiva: filaActiva });
      // if (filaActiva !== null) {
      //   console.log({ filaActiva: filaActiva });
      //   this.grid.expandRow(Number(filaActiva))
      // }
      /*   const paginaActiva = sessionStorage.getItem('ListadoPacientesPaginaActiva');
         console.log({ paginaActiva: paginaActiva });
         if (paginaActiva !== null) {
           this.getData('pacientes?page=' + paginaActiva + this.queryContain);
         } else {
           this.getData('pacientes?page=1' + this.queryContain);
         }*/
    }, 0);
  }

  removerVariablesLocalesSessionStorage() {
    sessionStorage.removeItem("valFiltroListadoCitas");
    sessionStorage.removeItem("queryStringListadoCitas");
    //  sessionStorage.removeItem('ListadoPacientesPaginaActiva');
    sessionStorage.removeItem("ListadoCitasFilaActiva");
    this.apiServices.collapseAll(
      this.grid,
      this.currentPage,
      this.pageSize,
      Number(this.paginaInicio)
    );
  }

  public btnMostrarVentanaConfirmacion(id) {
    this.alertMsg.showAlert = false;
    this.idCita = id;
    this.mostrarVentanaConfirmacion = true;
  }

  public btnCerrarVentanaConfirmacion() {
    this.mostrarVentanaConfirmacion = false;
  }

  public btnCancelarCita() {
    if (this.ngModelMotivoCancelacion.trim().length === 0) {
      alert("Primero debe escribir el motivo de cancelación");
      return false;
    }

    const formData = new FormData();
    formData.append("motivo_cancelacion", this.ngModelMotivoCancelacion);
    formData.append("cita_id", "" + this.idCita);

    this.apiServices
      .putFormData(formData, "citas/cancelar/" + this.idCita)
      .subscribe(
        (response) => {
          if (
            response !== null &&
            response.response !== undefined &&
            response.success === true
          ) {
            const data: any = response;
            this.alertMsg.showAlert = true;
            this.alertMsg.msg = data.response;

            // this.queryStringTotales = queryString;
            console.log(this.queryStringTotales);

            this.getData(this.queryStringTotales);
            if (data.success) {
              this.alertMsg.class = "alert alert-success";
            } else {
              this.alertMsg.class = "alert alert-danger";
            }
          }
        },
        (error) => {
          console.log(this.alertMsg.showAlert);
          this.alertMsg.class = "alert alert-danger";
          this.alertMsg.showAlert = true;
          this.alertMsg.msg = error.error.response;
        },
        () => {
          console.log("Finally");
        }
      );

    this.mostrarVentanaConfirmacion = false;
  }

  cerrarAlert($event) {
    this.alertMsg.msg = "";
    this.alertMsg.showAlert = false;
  }

  onSubmitFilters() {
    this.loading = true;
    this.queryString = "";

    console.log(this.windowFormFilters);
    console.log(this.queryString);

    const valFiltro = this.windowFormFilters.get("txtFiltro").value;
    sessionStorage.setItem("valFiltroListadoPacientes", valFiltro);

    if (valFiltro.length === 0) {
      this.removerVariablesLocalesSessionStorage();
      this.getData("citas/index?page=1" + this.queryContain);
      return false;
    }

    let queryString = "";
    if (this.queryString.length === 0) {
      queryString = "citas/index?page=1";
      this.queryString += "&codigo=" + valFiltro;
      this.queryString += "&medicoide=" + valFiltro;
      this.queryString += "&pacienteide=" + valFiltro;
      // this.queryString += '&filterable=true';
      // this.queryString += '&identificacion=' + valFiltro;
      this.queryString += this.queryContain;

      queryString = queryString + this.queryString;
    }

    //  this.queryString = queryString;

    console.log("averquejesto" + queryString);

    this.getData(queryString);

    // console.log(this.dataW);

    // this.postItem(this.itemData);
  }

  onSubmitFiltrs() {
    this.loading = true;
    this.queryString = "";
    console.log(this.windowFormFilters);
    console.log(this.queryString);
    var inputValue = document.querySelector<HTMLInputElement>(
      '[placeholder="MedicoToSearch"]'
    );
    inputValue.value = inputValue.value.replace(/\D/g, "");
    console.log(inputValue.value);
    const valFiltro = inputValue.value;
    sessionStorage.setItem("valFiltroListadoPacientes", valFiltro);

    if (valFiltro.length === 0) {
      this.removerVariablesLocalesSessionStorage();
      this.getData("citas/index?page=1" + this.queryContain);
      return false;
    }

    let queryString = "";
    if (this.queryString.length === 0) {
      queryString = "citas/index?page=1";
      this.queryString += "&medicoide=" + valFiltro;

      this.queryString += this.queryContain;

      queryString = queryString + this.queryString;
    }
    this.getData(queryString);
  }

  onSubmitFiltrsbyStatus() {
    this.loading = true;
    this.queryString = "";
    console.log(this.windowFormFilters);
    console.log(this.queryString);
    var inputValue = document.querySelector<HTMLInputElement>(
      '[placeholder="Estado_cita"]'
    );
    console.log(inputValue.value);
    const valFiltro = inputValue.value;
    sessionStorage.setItem("valFiltroListadoPacientes", valFiltro);

    if (valFiltro.length === 0) {
      this.removerVariablesLocalesSessionStorage();
      this.getData("citas/index?page=1" + this.queryContain);
      return false;
    }

    let queryString = "";
    if (this.queryString.length === 0) {
      queryString = "citas/index?page=1";
      this.queryString += "&estado=" + valFiltro;

      this.queryString += this.queryContain;

      queryString = queryString + this.queryString;
    }
    this.getData(queryString);
  }

  btnCerrarDetalleCita() {
    //  this.getData(this.queryStringTotales);
    this.detalleCita.verVentanaDetalleCita = false;
    this.codigoCita = 0;
  }

  // eventoCerrarVentana($event) {
  //   this.detalleCita.verVentanaDetalleCita = false;
  // }

  btnVerDetalleCita(item, index) {
    console.log(item);

    const medico = item.Medico;
    const paciente = item.Paciente;
    const consultorio = item.Consultorio;

    this.detalleCita.codCita = item.codigo;
    this.codigoCita = item.codigo;
    this.detalleCita.nomMedico = medico.nombres + " " + medico.apellidos;
    this.detalleCita.idenMedico = medico.identificacion;
    this.detalleCita.celMedico = medico.celular;
    this.detalleCita.nomPaciente = paciente.nombres + " " + paciente.apellidos;
    this.detalleCita.idenPaciente = paciente.identificacion;
    this.detalleCita.celPaciente = medico.celular;
    this.detalleCita.nomConsultorio = consultorio.nombre;
    this.detalleCita.telConsultorio = consultorio.telefono;
    this.detalleCita.dirConsultorio = consultorio.direccion;
    this.detalleCita.celConsultorio = consultorio.celular;
    this.detalleCita.fechaCita = item.fecha_cita;
    this.detalleCita.horaCita = item.horario;

    this.detalleCita.verVentanaDetalleCita = true;
  }

  eventoEditarMedico($itemEditado = false) {
    console.log($itemEditado);
    if ($itemEditado === true) {
      this.getData(this.queryStringTotales);
    }
  }

  generarPdf() {
    if (this.codigoCita > 0) {
      this.generandoReporte = true;
      let url = "reportes/generarpdf?origen=citas";
      url += "&codigo=" + this.codigoCita;
      this.apiServices.getData(url, "Reporte", false).subscribe(
        (data) => {
          console.log(data);

          if (
            data !== null &&
            data.success !== undefined &&
            data.success === true
          ) {
            // this.tienePdf = true;
            // this.urlPdf = this.baseUrl2 + data.path;
            this.generandoReporte = false;
            window.open(this.baseUrl2 + data.path);
          } else {
            this.mostrarMsgError(
              "Error al intentar generar el PDF, Debe intentar de nuevo"
            );
          }
        },
        (error) => {
          console.log(error);
          this.generandoReporte = false;
          //  this.codigoFormula = 0;
          //  this.cerrarVentana();
          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError(
              "Error al intentar generar el PDF, Debe intentar de nuevo"
            );
          }
        },
        () => {}
      );
    } else {
      alert("Error al intentar generar la cita médica, debe intentar de nuevo");
    }
  }

  mostrarMsgError(msg: string) {
    this.alertMsg.class = "alert alert-danger";
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = "alert alert-success";
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }
  btnAddInvoice() {
    // this.router.navigateByUrl('/AddItem');
    this.router.navigateByUrl("/AddInvoice");
  }

  showFilters() {
    if (this.showWindowFilters) {
      this.showWindowFilters = false;
    } else {
      this.showWindowFilters = true;
    }
    // this.showWindowFilters = val;
  }

  onCellClick(event) {
    // event.dataItem.name = 'hola';
    console.log(event);
    // event.sender.expandRow(event.rowIndex);
    this.apiServices.collapseAll(
      this.grid,
      this.currentPage,
      this.pageSize,
      Number(this.paginaInicio)
    );
    sessionStorage.setItem("ListadoCitasFilaActiva", event.index);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    console.log({ state: state });
    this.removerVariablesLocalesSessionStorage();
    this.loading = true;
    this.skip = state.skip;
    this.pageSize = state.take;
    this.sort = state.sort;

    if (this.skip === 0) {
      this.skip = 1;
    }

    this.currentPage = Math.floor(this.skip / this.pageSize);
    this.currentPage++;

    let queryString = "citas/index?page=" + this.currentPage;

    if (this.sort.length > 0) {
      const sortDir = this.sort[0].dir;
      let sortField = this.sort[0].field;
      // console.log(sortField);
      if ("" + sortField === "nombre_medico") {
        sortField = "medico_id";
      } else if ("" + sortField === "nombre_paciente") {
        sortField = "paciente_id";
      } else if ("" + sortField === "consultorio.nombre") {
        sortField = "consultorio_id";
      } else if ("" + sortField === "fecha_cita") {
        sortField = "fecha";
      }
      queryString += "&sortfield=" + sortField + "&sortdir=" + sortDir;
    }

    if (this.queryString.length === 0) {
      queryString += this.queryContain;
    }

    queryString = queryString + this.queryString;
    console.log(queryString);

    this.getData(queryString);

    //this.onSubmitFilters(this.currentPage);
  }

  /*public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.getData('?page=' + this.currentPage);
  } */

  public filterChange(filter: CompositeFilterDescriptor): void {
    console.log(filter);
  }

  getData(queryString: string = "") {
    this.queryStringTotales = queryString;
    console.log({ queryStringTotales: this.queryStringTotales });
    sessionStorage.setItem("queryStringListadoCitas", this.queryStringTotales);

    this.apiServices.getData(queryString, "Citas", false).subscribe(
      (data) => {
        if (
          data !== null &&
          data.success !== undefined &&
          data.success === true
        ) {
          this.lstData = this.tempLstData = data["Citas"];
          console.log("this is eha data xd");
          console.log(data);

          let newData = [];
          Object.keys(this.lstData).forEach((index) => {
            const medico = this.lstData[index].Medico;
            const paciente = this.lstData[index].Paciente;
            this.lstData[index].nombre_medico =
              medico.nombres +
              " " +
              medico.apellidos +
              " (" +
              medico.identificacion +
              ")";
            this.lstData[index].nombre_paciente =
              paciente.nombres +
              " " +
              paciente.apellidos +
              " (" +
              paciente.identificacion +
              ")";

            newData.push(this.lstData[index]);
            console.log(newData);
          });

          this.totalPages = data.paginationInfo.count;

          this.gridView = {
            data: this.lstData,
            total: this.totalPages,
          };

          // Paginación inteligente

          if (data.paginationInfo !== undefined) {
            this.pageSize = Number(data.paginationInfo.perPage);
            const page = Number(data.paginationInfo.page);
            const perPage = Number(data.paginationInfo.perPage);
            console.log(page * perPage - perPage);
            const saltar = page * perPage - perPage;
            this.skip = saltar;
            this.paginaInicio = data.paginationInfo.start;
          }

          //  console.log(this.gridView);
          // this.grid.resetGroupsState();
          // this.grid.que
          this.loading = false;
          // this.loading = false;
        } else {
          this.removerVariablesLocalesSessionStorage();
          this.gridView = {
            data: [],
            total: 0,
          };
        }
      },
      (error) => {
        console.log(error);
        this.loading = false;
        // this.loading = false;
      }
    );
  }

  getDataMed() {
    this.apiServices.getData("medicos", "Medicos", false).subscribe(
      (response) => {
        if (
          response !== null &&
          response.success !== undefined &&
          response.success === true
        ) {
          const medicos = response["Medicos"];
          console.log(medicos);
          this.lstMedicos = medicos;
          console.log("this is medicos");
          console.log({ lstMedicos: this.lstMedicos });
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        //console.log('Finally cboEspecialidad_click');
      }
    );
  }

  /*public sliderChange(pageIndex: number): void {
    this.skip = (pageIndex - 1) * this.pageSize;
    console.log(pageIndex);
  } */

  /*  public pageChange(event: PageChangeEvent): void {

     //  this.grid.nativeElement.notifyDataChange();

     // console.log(currentPage);


     this.skip = event.skip;
     this.pageSize = event.take;

     this.loading = true;
     this.skip = event.skip;




     /* if (this.queryString === '') {
        this.queryString += '?page=' + this.currentPage;
      } else {
        this.queryString += '&page=' + this.currentPage;
      }*/

  //if (this.totalPages > 0 && this.currentPage <= this.pageCount) {

  // this.notifyDataChange();

  // this.getData(this.queryString);
  //}

  public exportToPDF(grid: GridComponent): void {
    grid.saveAsPDF();
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  btnClick() {
    // this.router.navigateByUrl('/AddItem');
    this.router.navigateByUrl("/AddInvoice");
  }

  searchItemById() {
    this.currentPage = 1;
    this.loading = true;
    let queryString = "";
    let id = "";
    let name = "";
    let reference = "";

    if (this.txtId.nativeElement.value.trim()) {
      id = "id=" + this.txtId.nativeElement.value.trim();
    } else {
      id = "";
    }

    if (this.txtName.nativeElement.value.trim()) {
      name = "name=" + this.txtName.nativeElement.value.trim();
    } else {
      name = "";
    }

    if (this.txtReference.nativeElement.value.trim()) {
      reference = "reference=" + this.txtReference.nativeElement.value.trim();
    } else {
      reference = "";
    }

    if (id !== "" || name !== "" || reference !== "") {
      queryString = "?" + id + "&" + name + "&" + reference;
    }

    // this.getData();
    const page = 1;
    //this.onSubmitFilters(page);
    this.skip = 0;

    // console.log(this.currentPage);
  }

  applyFilters() {}

  // viewItem(id, rowIndex) {
  //   console.log(id);

  //   // this.itemDetails.itemId = id;
  //   this.loading = true;
  //   this.getItem(id);
  // }

  deleteItem(id, rowIndex) {
    // console.log($event);
    console.log(rowIndex);

    // this.grid.collapseRow(rowIndex);

    this.loading = true;
    this.apiServices.deleteData("citas", id).subscribe(
      (data) => {
        console.log(data);
        this.grid.collapseRow(rowIndex);
        // delete this.gridData['lastname'];
        /* let ind = 0;
         // const filtered = this.gridData.filter(function (el) { return el.id == 'id'; });
         this.lstData.forEach(item => {
           if (item.id === id) {
             this.gridView.data.splice(this.lstData.indexOf(item), 1);
           }

           // console.log(item);
         });


         // this.gridView.data.splice(index, 1);
        // this.cd.detectChanges();

         //this.lstData = this.tempLstData = data.items;
         // this.gridData = process(this.lstData, this.state);
         // console.log(data.paginationInfo.count);
         // this.totalPages = data.paginationInfo.count;
         // this.pageCount = data.paginationInfo.pageCount;
         // console.log(this.totalPages);
         // this.lstData.length = this.totalPages;
         // this.gridData = [];
         // this.gridData = this.lstData;
         // this.lstData.length = this.totalPages;
         // console.log(this.gridData);

         // this.view = this.lstData;
         // this.notifyDataChange();
         /* this.gridData = {
            data: orderBy(this.lstData, this.sort),
            total: this.totalPages
          };*/

        //  this.onSubmitFilters(this.currentPage);
        // this.cd.detectChanges();

        //  console.log(this.gridView);
        // this.grid.resetGroupsState();
        // this.grid.que
        this.loading = false;
        // this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;

        // this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  showAll() {
    this.skip = 0;
    this.windowFormFilters.reset();
    this.loading = true;

    this.getData("citas/index");
  }

  // receiveMessageFromViewItem($event) {
  //   console.log($event);
  //   this.itemDetails.showItemDetails = $event;
  // }

  // getItem(id) {
  //   const queryString = 'items/view?id=' + id + '&whid=all&accounts=all&catid=all&prid=all&iva=all&units=all&tax=all';
  //   this.apiServices.getData(queryString, 'items', false)
  //     .subscribe(data => {

  //       this.itemDetails.newData = data.items[0];

  //       // console.log(data.items[0].length);

  //       if (this.itemDetails.newData) {

  //         this.itemDetails.newData['pricelists'].forEach(element => {
  //           this.itemDetails.pricelists.push({ 'name': element.name, 'value': element.PricelistsItems.value });
  //         });

  //         this.itemDetails.taxes = [];
  //         this.itemDetails.taxes.push({
  //           'name': this.itemDetails.newData['tax'].name,
  //           'value': this.itemDetails.newData['tax'].value
  //         });

  //         this.itemDetails.newData['warehouses'].forEach(element => {
  //           this.itemDetails.warehouses.push({
  //             'name': element.name,
  //             'quantity': element.ItemsWarehouses.available_quantity
  //           });
  //         });

  //         this.itemDetails.windowTitle = 'Detalle del artículo: ' + this.itemDetails.newData.id;

  //         this.itemDetails.showItemDetails = true;
  //       } else {
  //         //  this.loading = false;
  //         this.wNotification.status = true;
  //         this.wNotification.title = 'Error';
  //         this.wNotification.textMsg = 'No se puede cargar los detalles del artículo';
  //       }

  //     }, (error: any) => {
  //       console.log(error);
  //     }, () => {
  //       this.loading = false;
  //       console.log('final');
  //     });
  // }

  // enableDisableItem(item, status) {
  //   // console.log(item);
  //   const formData = new FormData();

  //   if (status === 'Activo') {
  //     formData.append('active', '0');
  //     //  item.status = 'Inactivo';
  //   } else {
  //     formData.append('active', '1');
  //     // item.status = 'Activo';
  //   }

  //   formData.append('_method', 'PUT');

  //   this.apiServices.postFormData(formData, 'items/' + item.id)
  //     .subscribe(res => {
  //       // console.log(res);
  //       this.wNotification.textMsg = res.response;
  //       this.wNotification.status = true;
  //       this.wNotification.buttonMsg = 'Cerrar';

  //       // console.log(parseInt(res.item.active, 10));
  //       // console.log(res.response );
  //       // console.log(Boolean(res.response)) ;

  //       if (Boolean(res.response) === true) {

  //         if (parseInt(res.item.active, 10) === 1) {
  //           item.status = 'Activo';
  //           // console.log('Entro aquí 1');
  //         } else if (parseInt(res.item.active, 10) === 0) {
  //           //  console.log('Entro aquí 2');
  //           item.status = 'Inactivo';
  //         }

  //       }

  //     }, (error: any) => {
  //       console.log(error);
  //     }, () => {
  //       console.log(status);
  //     });
  // }

  // closeWindowNotification() {
  //   this.wNotification.textMsg = '';
  //   this.wNotification.status = false;
  // }

  // closeWindowAdjustment() {
  //   this.wAdjustment.textMsg = '';
  //   this.wAdjustment.status = false;
  // }

  // openWindowAdjustment(id) {
  //   this.wAdjustment.disableBtn = true;
  //   this.wAdjustment.status = true;
  //   this.wAdjustment.itemId = id;
  //   this.wAdjustment.buttonMsg = 'Adjustar';
  // }

  // valueChangeItemWareHouses(value): void {
  //   if (value !== undefined && value > 0) {
  //     this.wAdjustment.disableBtn = false;
  //     this.urlParams.wareHouseId = value;
  //     console.log({ 'item id': value });
  //   } else {
  //     this.wAdjustment.disableBtn = true;
  //   }
  // }

  // goToInventoryAdjustment(id) {

  //   this.urlParams.itemID = id;
  //   // routerLink='/InventoryAdjustments'
  //   this.router.navigate(['AddInventoryAdjustment'], { queryParams: this.urlParams }); // here 'About' is path
  // }

  // goToInvoiceDetails(id) {
  //   console.log({ 'invoiceId': id });
  //   const queryParams = {
  //     invoiceId: id
  //   };

  //   //    this.transfereService.setData(queryParams);

  //   // this.router.navigateByUrl('/EditItem?itemId=' + id);

  //   // this.location.replaceState('/EditItem?itemId=' + id);

  //   this.router.navigate(['InvoiceDetails'], { queryParams: queryParams });

  //   return false;
  // }

  // goToRegisterPayment(id) {
  //   // console.log({ 'invoiceId': id });
  //   const queryParams = {
  //     invoiceId: id
  //   };

  //   ///  this.transfereService.setData(queryParams);

  //   // this.router.navigateByUrl('/EditItem?itemId=' + id);

  //   // this.location.replaceState('/EditItem?itemId=' + id);

  //   this.router.navigate(['RegisterPayment'], { queryParams: queryParams });

  //   return false;
  // }
}
