import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {
  MaxLength,
  OnlyIntegerNumbers,
  MinLength,
  GreaterThanZero
} from 'src/app/validators/CustomValidators';
import { ServiciosService } from '../../servicios/servicios.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GetMsgFromError } from 'src/app/functions/SharedFunctions';
import { serverErrorsInfo } from 'src/app/utilities/serverErrorsInfo';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detalle-paciente',
  templateUrl: './detalle-paciente.component.html',
  styleUrls: ['./detalle-paciente.component.css']
})
export class DetallePacienteComponent implements OnInit {
  // @ViewChild('chkAgregarAcompananteRef') chkAgregarAcompananteRef: ElementRef<HTMLInputElement>;
  @ViewChild('chkAgregarAcompananteRef') chkAgregarAcompananteRef: ElementRef;
  @ViewChild('imgFoto', { static: false }) imgFoto: ElementRef;

  public appendTo: ViewContainerRef;

  activarKTextbox: false;
  formData: FormGroup;
  submitted = false;
  serverErrorsInfo: any = {};
  registrarAcompanante = false;
  registrarResponsable = false;
  responsableHabilitado = false;
  acompananteHabilitado = false;
  viveEnColombia = true;
  errorDeCarga = false;
  cargandoDatos = false;
  tipoUsuario: any;
  tipoAccion = '';
  tituloFormulario = '';
  pacienteId = 0;
  desBtnBorrarRespon = true;
  desBtnBorrarAcompa = true;
  // Listado de ciudades pord departamento
  lstCiudadesPorDep: any[];
  lstDepartamentos: any[];
  registroEncontrado = false;
  estadoPaciente = 'Activo';
  nombreCompleto = '';
  verRegistrarAcompanante = false;
  chkAgregarResponsableChecked = false;
  desBtnGuardar = false;
  imgURL: any;

  public myRestrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png'],
    maxFileSize: 2097152
  };

  fotos = {
    mini: '',
    original: ''
  };

  public lstEstados: Array<{ text: string; value: string }> = [
    { text: 'Activo', value: 'Activo' },
    { text: 'Bloqueado', value: 'Bloqueado' }
  ];

  baseUrl2 = environment.baseUrl2;

  spinners = {
    spinnerSave: false
  };

  uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint

  public myFiles: Array<any>;
  // public userImages: Array<FileInfo>;

  windowNotification = {
    msg: '',
    title: '',
    buttonMsg: 'Cerrar',
    status: false
  };

  public userImages: Array<any>;

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  };

  customData = {
    fondoPensions: [],
    epss: [],
    depsRes: [],
    paisRes: [],
    ciuRes: [],
    ocupacions: [],
    escolaridads: [],
    estadoCivils: [],
    generos: [],
    tipoDocs: [],
    grpSans: [],
    parentescos: []
  }

  verVentanas = {
    verVentanaConfirmarGuardar: false,
    titVenCon: 'Confirmación de acción',
    textVenCon: '¿Está seguro(s)?',
    verVentanaConfirmarBorrar: false,
    titVenNotificacion: 'Notificación',
    textVenNotificacion: 'Registro borrado correctamente',
    ventanaNotificacion: false,
    verVenBorrarAcom: false,
    titVenBorrarAcom: 'Confirmación de acción',
    tetBorrarAcom: '¿Está seguro(s)?'
  }

  mostrarverVentanas = false;


  constructor(
    private router: Router,
    private apiServices: ServiciosService,
    private activatedRoute: ActivatedRoute,
    //  private notificationService: NotificationService,
    private formBuilder: FormBuilder
  ) {
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    this.formData = this.formBuilder.group({
      cboTipoId: ['', [Validators.required, MaxLength(false, 18), OnlyIntegerNumbers()]],
      txtIdentificacion: ['', [Validators.required, MaxLength(false, 20)]],
      txtNombres: ['', [Validators.required, MaxLength(false, 145)]],
      txtApellidos: ['', [Validators.required, MaxLength(false, 145)]],
      txtCelular: ['', [Validators.required, MaxLength(false, 15), MinLength(false, 10), OnlyIntegerNumbers()]],
      txtEmail: ['', [Validators.required, Validators.email, MaxLength(false, 75)]],
      // txtPeso: ['', [Validators.required, MaxLength(false, 5), OneDecimal]],
      // txtEstatura: ['', [Validators.required, MaxLength(false, 5), OneDecimal]],
      cboGenero: ['', Validators.required],
      cboGrupoSan: ['', [Validators.required, OnlyIntegerNumbers()]],
      cboEstadoCivil: ['', Validators.required],
      txtFoto: [
        ''
      ],
      cboNivelEsc: ['', Validators.required],
      txtFechaNacimiento: ['', Validators.required],
      txtEdad: [''],
      cboDepResidencia: ['', [Validators.required, OnlyIntegerNumbers()]],
      txtCiudadResidencia: ['', [Validators.required]],
      cboPaisResidencia: ['', [Validators.required]],
      cboCiuResidencia: [{ value: '', disabled: true }, [Validators.required, GreaterThanZero(false), MaxLength(false, 200)]],
      txtDireccion: ['', [MaxLength(true, 200)]],
      cboEps: ['', [Validators.required, OnlyIntegerNumbers()]],
      cboFondoPension: ['', [Validators.required, OnlyIntegerNumbers()]],
      txtNumHijos: [
        '',
        [Validators.required, MaxLength(false, 3), OnlyIntegerNumbers()]
      ],
      txtNomAcompanante: ['', [Validators.required, MaxLength(false, 300)]],
      txtCelularAcompanante: ['', [Validators.required, OnlyIntegerNumbers(), MaxLength(false, 13)]],
      txtNomResponsable: ['', [Validators.required, MaxLength(false, 300)]],
      txtCelularResponsable: ['', [Validators.required, OnlyIntegerNumbers(), MaxLength(false, 13)]],
      txtIdentificacionAcompanante: [
        '',
        [Validators.required, MaxLength(false, 20)]
      ],
      txtIdentificacionResponsable: [
        '',
        [Validators.required, MaxLength(false, 20), OnlyIntegerNumbers()]
      ],
      cboParentezcoResponsable: [
        '',
        [Validators.required, OnlyIntegerNumbers()]
      ],
      cboOcupacion: ['', [Validators.required, OnlyIntegerNumbers()]],
      txtNotas: ['', MaxLength(true, 1000)],
      chkAgregarAcompanante: [''],
      chkAgregarResponsable: [''],
      cboEstados: [{ value: '', disabled: true }, [Validators.required]]
      // txtPassword: ['', [Validators.required, MinLength(false, 10), MaxLength(false, 15)]],
      //   txtPassword2: ['', [Validators.required, MinLength(false, 10), MaxLength(false, 15)]],

      // txtCelular: ['', [Validators.required, MaxLength(false, 18), OnlyIntegerNumbers()]],

      // txtDireccion: ['', [MaxLength(true, 445)]],
      // //  txtFoto: [''],
      // txtFoto: [''],
      // cboEspecialidad: ['', [Validators.required, MaxLength(false, 18), OnlyIntegerNumbers()]],
    });



    this.formData.get('txtFechaNacimiento').valueChanges.subscribe(value => {
      console.log(value);
      this.formData.get('txtEdad').patchValue(this.getAge(value));
    });


    this.habilitarControlesAcompanante();
    this.habilitarControlesResposanble();

  }

  ngAfterContentInit(): void {

    this.tipoUsuario = this.apiServices.getTipoUsuario();
    const userInfo = this.apiServices.getUserInfoFromToken();

    console.log(this.tipoUsuario);



    this.activatedRoute.queryParams.subscribe(params => {

      const tipo = params.tipo;
      const pacienteId = params.pacienteId;
      this.tipoAccion = tipo;

      console.log(this.tipoAccion);

      if (tipo === 'nuevo') { //Nuevo registro
        // tipo = tipo;
        this.resetearFotoControlesCheckBox();
        this.viveEnColombia = true;
        this.tituloFormulario = 'Registrar paciente';
        this.formData.get('cboDepResidencia').disable();
        this.formData.get('cboCiuResidencia').disable();
        this.errorDeCarga = false;
        this.cargandoDatos = false;
        this.alertMsg.showAlert = false;
        this.apiServices.removerVariablesSession();

      } else if (tipo === 'detalle'
        && pacienteId !== undefined &&
        this.isInt(pacienteId) === true) { //Detalle registro

        this.errorDeCarga = false;
        this.cargandoDatos = false;
        this.pacienteId = pacienteId;

        this.getPaciente(pacienteId);

      } else {
        this.errorDeCarga = true;
        this.tituloFormulario = 'Tipo de acción desconocida';
        this.cargandoDatos = false;
        const msg = 'Error al intentar determinar el tipo de acción a realizar.';
        this.mostrarMsgError(msg);
        return false;
      }

    });



  }

  isInt(value) {
    return Number.isInteger(Number(value));
  }

  getPaciente(pacienteId: number) {
    // console.log(itemPacienteId);
    if (pacienteId !== undefined && pacienteId > 0) {
      let urlParams = 'pacientes/' + pacienteId;
      // urlParams += 'id/' + pacienteId;
      urlParams += '?grpsa=all&tipdoc=all&estciv=all';
      urlParams += '&genero=all&ocup=all&nivesc=all&ciures=all&depres=all';
      urlParams += '&eps=all&fndpe=all&acomp=all&respo=all&countryid=all';
      // let itemPaciente: Item;
      this.apiServices.getData(urlParams, 'Paciente', false).subscribe(
        data => {
          this.alertMsg.showAlert = false;
          if (data !== null && data.success !== undefined && data.success === true) {
            console.log(data);

            // this.itemPaciente = data['Paciente'];
            const itemPaciente = data['Paciente'];
            console.log(itemPaciente);
            this.registroEncontrado = true;
            this.cargandoDatos = false;
            this.formData.get('txtNombres').setValue(itemPaciente.nombres);
            this.formData.get('txtIdentificacion').setValue(itemPaciente.identificacion);
            this.formData.get('txtApellidos').setValue(itemPaciente.apellidos);

            this.formData.get('txtCelular').setValue(itemPaciente.celular);
            this.formData.get('txtEmail').setValue(itemPaciente.email);
            this.formData.get('txtNumHijos').setValue(itemPaciente.num_hijos);
            const tempDate = new Date(itemPaciente.fecha_nac);
            this.formData.get('txtFechaNacimiento').patchValue(tempDate);
            this.formData.get('txtDireccion').patchValue(itemPaciente.direccion);
            this.formData.get('txtNotas').patchValue(itemPaciente.notas);
            this.formData.get('txtEdad').patchValue(this.getAge(tempDate));
            if (this.tipoAccion === 'detalle') {
              this.formData.get('cboEstados').enable();
              this.formData.get('cboEstados').patchValue(itemPaciente.estado);
            } else {
              this.formData.get('cboEstados').disable();
            }

            this.estadoPaciente = itemPaciente.estado;

            this.nombreCompleto = itemPaciente.nombres + ' ' + itemPaciente.apellidos + ' (' + itemPaciente.identificacion + ')';
            this.tituloFormulario = 'Detalle del paciente: ' + this.nombreCompleto;
            //  console.log(itemPaciente.urlFotoMiniatura);
            //  console.log(typeof data.urlFotoMiniatura);

            if (data.urlFotoMiniatura !== undefined &&
              data.urlFotoMiniatura !== null &&
              data.urlFotoMiniatura.length > 0) {

              this.fotos.mini = this.baseUrl2 + '' + data.urlFotoMiniatura;
            } else {
              this.fotos.mini = '';
            }

            //   console.log(data.urlFotoMiniatura);

            // Tipos de documentos
            if (itemPaciente.TipoDocumento !== undefined && Object.keys(itemPaciente.TipoDocumento).length > 0) {
              const val = {
                id: itemPaciente.TipoDocumento.id,
                nombre: itemPaciente.TipoDocumento.nombre
              };
              this.customData.tipoDocs.push(val);
              this.formData.get('cboTipoId').patchValue(Number(itemPaciente.tipodocumento_id));
            }

            // Genero
            if (itemPaciente.Genero !== undefined && Object.keys(itemPaciente.Genero).length > 0) {
              const val = {
                id: itemPaciente.Genero.id,
                nombre: itemPaciente.Genero.nombre
              };
              this.customData.generos.push(val);
              this.formData.get('cboGenero').patchValue(Number(itemPaciente.genero_id));
            }

            // Grupo sanguíneo
            if (itemPaciente.GrupoSanguineo !== undefined && Object.keys(itemPaciente.GrupoSanguineo).length > 0) {
              const val = {
                id: itemPaciente.GrupoSanguineo.id,
                nombre: itemPaciente.GrupoSanguineo.nombre
              };
              this.customData.grpSans.push(val);
              this.formData.get('cboGrupoSan').patchValue(Number(itemPaciente.gruposanguineo_id));
            }

            // Estados civiles
            if (itemPaciente.EstadoCivil !== undefined && Object.keys(itemPaciente.EstadoCivil).length > 0) {
              const val = {
                id: itemPaciente.EstadoCivil.id,
                nombre: itemPaciente.EstadoCivil.nombre
              };
              this.customData.estadoCivils.push(val);
              this.formData.get('cboEstadoCivil').patchValue(Number(itemPaciente.estadocivil_id));
            }

            // Nivel de escolaridad
            if (itemPaciente.Escolaridad !== undefined && Object.keys(itemPaciente.Escolaridad).length > 0) {
              const val = {
                id: itemPaciente.Escolaridad.id,
                nombre: itemPaciente.Escolaridad.nombre
              };
              this.customData.escolaridads.push(val);
              this.formData.get('cboNivelEsc').patchValue(Number(itemPaciente.escolaridad_id));
            }

            // Ocupaciones
            if (itemPaciente.Ocupacion !== undefined && Object.keys(itemPaciente.Ocupacion).length > 0) {
              const val = {
                id: itemPaciente.Ocupacion.id,
                nombre: itemPaciente.Ocupacion.nombre
              };
              this.customData.ocupacions.push(val);
              this.formData.get('cboOcupacion').patchValue(Number(itemPaciente.ocupacion_id));
            }

            //Pais
            if (itemPaciente.Country !== undefined && Object.keys(itemPaciente.Country).length > 0) {
              
              const paisRes = {
                id: itemPaciente.Country.id,
                nombre: itemPaciente.Country.nombre
              };
              
              this.customData.paisRes.push(paisRes);
              this.formData.get('cboPaisResidencia').patchValue(Number(itemPaciente.country_id));

              console.log(this.customData.paisRes);
              console.log(itemPaciente.country_id);
            
            }

            // Departamento de residencia
            if (itemPaciente.DepartamentoResidencia !== undefined && Object.keys(itemPaciente.DepartamentoResidencia).length > 0) {

              this.formData.get('cboDepResidencia').enable();
              this.formData.get('cboCiuResidencia').enable();

              this.cargarDepartamentos(itemPaciente.country_id);
              this.cargarCiudadesPorDepartamento(itemPaciente.departamentoresidencia_id);;


              this.formData.get('cboDepResidencia').patchValue(Number(itemPaciente.departamentoresidencia_id));
              this.formData.get('cboCiuResidencia').patchValue(Number(itemPaciente.ciudadresidencia_id));

              if (this.tipoUsuario === 'MEDICO') {
                setTimeout(() => { this.formData.get('cboCiuResidencia').disable(); }, 2)
              }

              this.viveEnColombia = true;
              this.formData.get('txtCiudadResidencia').disable();

            } else {
              this.viveEnColombia = false;
              this.formData.get('txtCiudadResidencia').setValue(itemPaciente.ciudad_residencia);

              this.cboDepResidencia.disable();
              this.cboCiuResidencia.disable();
            }

            // EPS
            if (itemPaciente.Eps !== undefined && Object.keys(itemPaciente.Eps).length > 0) {
              const val = {
                id: itemPaciente.Eps.id,
                nombre: itemPaciente.Eps.nombre
              };
              this.customData.epss.push(val);
              this.formData.get('cboEps').patchValue(Number(itemPaciente.eps_id));
            }

            // Fondo de pensiones
            if (itemPaciente.FondoPension !== undefined && Object.keys(itemPaciente.FondoPension).length > 0) {
              const val = {
                id: itemPaciente.FondoPension.id,
                nombre: itemPaciente.FondoPension.nombre
              };
              this.customData.fondoPensions.push(val);
              this.formData.get('cboFondoPension').patchValue(Number(itemPaciente.fondopension_id));
            }

            // Responsable
            // if (itemPaciente.tiene_responsable === 'Si') {
            //   if (itemPaciente.Responsable !== undefined && Object.keys(itemPaciente.Responsable).length > 0) {

            //     {
            //       // console.log()
            //       this.verRegistrarResponsable = false;

            //       // this.customData.fondoPensions.push(val);
            //       this.formData.get('cboParentezcoResponsable').patchValue(itemPaciente.responsable_id);
            //     }
            //   }

            // Acompañante
            if (itemPaciente.tiene_acompanante === 'Si' && itemPaciente.acompanante_json.length > 0) {
              this.desBtnBorrarAcompa = false;
              const acompanante = JSON.parse(itemPaciente.acompanante_json);
              this.habilitarControlesAcompanante(true);
              this.formData.get('chkAgregarAcompanante').enable();
              this.formData.patchValue({ chkAgregarAcompanante: true });
              // this.chkAgregarAcompananteRef.nativeElement.checked = true;
              // this.chkAgregarAcompananteChecked = true;
              // window.document.getElementById('chkAgregarAcompanante').click();
              //   this.formData.get('chkAgregarAcompanante').patchValue({checked, true});
              this.verRegistrarAcompanante = true;
              this.formData.get('txtIdentificacionAcompanante').setValue(acompanante.identificacion);
              this.formData.get('txtNomAcompanante').setValue(acompanante.nombre_completo);
              this.formData.get('txtCelularAcompanante').setValue(acompanante.celular);
              //  this.verRegistrarAcompanante = true;

              this.formData.get('chkAgregarAcompanante').disable();
              //  this.customData.fondoPensions.push(val);
              // this.formData.get('cboParentezcoResponsable').patchValue(itemPaciente.responsable_id);

            } else {
              this.desBtnBorrarAcompa = true;
              this.verRegistrarAcompanante = false;
              this.habilitarControlesAcompanante(false);
              // this.chkAgregarAcompananteRef.nativeElement.checked = false;
              this.formData.patchValue({ chkAgregarAcompanante: false });
              // this.chkAgregarAcompananteChecked = false;

            }

            // Reponsable
            if (itemPaciente.tiene_responsable === 'Si' && itemPaciente.responsable_json.length > 0) {
              this.desBtnBorrarRespon = false;
              this.chkAgregarResponsableChecked = true;
              //  this.chkAgregarResponsableRef.nativeElement.checked = true;
              const responsable = JSON.parse(itemPaciente.responsable_json);
              console.log(responsable.parentesco_id);
              this.habilitarControlesResposanble(true);
              //    window.document.getElementById('chkAgregarResponsable').click();
              //   this.verRegistrarResponsable = true;
              this.formData.get('cboParentezcoResponsable').patchValue(Number(responsable.parentesco_id));
              this.formData.get('txtIdentificacionResponsable').setValue(responsable.identificacion);
              this.formData.get('txtNomResponsable').setValue(responsable.nombre_completo);
              this.formData.get('txtCelularResponsable').setValue(responsable.celular);

              //  this.verRegistrarAcompanante = true;
              this.formData.get('chkAgregarResponsable').disable();


            } else {
              this.desBtnBorrarRespon = true;
              //  this.verRegistrarResponsable = false;
              this.habilitarControlesResposanble(false);
              // this.chkAgregarResponsableRef.nativeElement.checked = false;
              // this.chkAgregarResponsableChecked = false;
            }

            console.log(this.customData);

          } else {
            this.cargandoDatos = false;
            this.alertMsg.showAlert = true;
            this.alertMsg.class = 'alert alert-danger';
            this.alertMsg.msg = 'Error al intentar cargar los datos del paciente';
          }

          if (this.tipoUsuario === 'MEDICO') {
            this.deshabilitarControles();
          }
        },
        error => {
          console.log(error);
          const errors = GetMsgFromError(error);
          this.alertMsg.showAlert = true;
          this.alertMsg.class = 'alert alert-danger';
          if (error.error.response !== undefined) {
            this.alertMsg.msg = error.error.response;
          } else {
            this.alertMsg.msg = 'Error al intentar cargar el paciente. El paciente que intenta visualizar no existe';
          }

          this.tituloFormulario = 'Error al intentar cargar el paciente';
          this.errorDeCarga = true;
          // this.loadedData = true;
          // this.registroEncontrado = false;
          this.cargandoDatos = false;
          this.registroEncontrado = false;
          console.log(errors);

          // this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(errors.errors);
          // console.log(this.serverErrorsInfo);
          // this.spinners.mostrarSpinner = false;
        },
        () => {
          this.cargandoDatos = false;
          //  console.log('Finally');
          // this.loadedData = true;
          // this.spinners.mostrarSpinner = false;
        }
      );
    } else {
      this.cargandoDatos = false;
      this.registroEncontrado = false;
      this.alertMsg.showAlert = true;
      this.alertMsg.class = 'alert alert-danger';
      this.alertMsg.msg = 'Error al intentar cargar el paciente';
    }
  }

  deshabilitarControles(omitir = []) {
    this.desBtnGuardar = true;
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).disable();
      }
    });
  }

  getAge(dateString): number {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  chkAgregarResponsable_Click($event) {
    if (this.registrarResponsable === false) {
      this.registrarResponsable = true;
      this.habilitarControlesResposanble(true);
    } else {
      this.registrarResponsable = false;
      this.habilitarControlesResposanble(false);
    }
  }

  chkAgregarAcompanante_Click($event) {
    // console.log($event);
    if (this.verRegistrarAcompanante === false) {
      this.verRegistrarAcompanante = true;
      this.habilitarControlesAcompanante(true);
    } else {
      this.desBtnBorrarAcompa = true;
      this.verRegistrarAcompanante = false;
      this.habilitarControlesAcompanante(false);
    }
  }


  modalAgregarEspecialidad_Click($event) {
    console.log('modalAgregarEspecialidad_Click');
  }

  habilitarControlesResposanble(estado = false) {
    if (estado === true) {
      this.responsableHabilitado = true;
      console.log('Esta habilitando....');
      this.formData.get('txtIdentificacionResponsable').enable();
      this.formData.get('txtIdentificacionResponsable').reset();
      this.formData.get('txtNomResponsable').enable();
      this.formData.get('txtNomResponsable').reset();
      this.formData.get('txtCelularResponsable').enable();
      this.formData.get('txtCelularResponsable').reset();
      this.formData.get('cboParentezcoResponsable').enable();
      this.formData.get('cboParentezcoResponsable').reset();
    } else {
      this.responsableHabilitado = false;
      this.formData.get('txtIdentificacionResponsable').disable();
      this.formData.get('txtIdentificacionResponsable').reset();
      this.formData.get('txtNomResponsable').disable();
      this.formData.get('txtNomResponsable').reset();
      this.formData.get('txtCelularResponsable').disable();
      this.formData.get('txtCelularResponsable').reset()
      this.formData.get('cboParentezcoResponsable').disable();
      this.formData.get('cboParentezcoResponsable').reset();
    }
  }

  habilitarControlesAcompanante(estado = false) {
    if (estado === true) {
      this.acompananteHabilitado = true;
      this.formData.get('txtIdentificacionAcompanante').enable();
      this.formData.get('txtIdentificacionAcompanante').reset();
      this.formData.get('txtIdentificacionAcompanante').updateValueAndValidity();

      this.formData.get('txtNomAcompanante').enable();
      this.formData.get('txtNomAcompanante').reset();
      this.formData.get('txtNomAcompanante').updateValueAndValidity();

      this.formData.get('txtCelularAcompanante').enable();
      this.formData.get('txtCelularAcompanante').reset();
      this.formData.get('txtCelularAcompanante').updateValueAndValidity();

    } else {
      this.acompananteHabilitado = false;
      this.formData.get('txtIdentificacionAcompanante').disable();
      // this.formData.get('txtIdentificacionAcompanante').updateValueAndValidity();
      this.formData.get('txtNomAcompanante').disable();
      // this.formData.get('txtNomAcompanante').updateValueAndValidity();
      this.formData.get('txtCelularAcompanante').disable();
      //  this.formData.get('txtNomAcompanante').updateValueAndValidity();
    }
  }


  btnBorrarAcomp($event) {
    // console.log($event);
    this.verVentanas.verVenBorrarAcom = false;
    if ($event === false) {
      //   console.log('Entro aquí');
      return false;
    }


    this.apiServices.deleteData('acompanantes', this.pacienteId)
      .subscribe(data => {
        console.log(data);
        const response = data as any;

        if (response.success === true) {
          this.desBtnBorrarAcompa = true;
          //this.itemPaciente.tiene_acompanante = 'No';
          this.habilitarControlesAcompanante(false);
          this.formData.get('chkAgregarAcompanante').enable();
          if (this.verRegistrarAcompanante === true) {
            this.verRegistrarAcompanante = false;
          }
          this.chkAgregarAcompananteRef.nativeElement.checked = false;
          this.formData.get('txtIdentificacionAcompanante').setValue('');
          this.formData.get('txtNomAcompanante').setValue('');
          this.formData.get('txtCelularAcompanante').setValue('');
          this.habilitarControlesAcompanante(false);
        } else {
          this.desBtnBorrarAcompa = false;
        }

        this.windowNotification.status = true;
        this.windowNotification.title = 'Notificación';
        this.windowNotification.msg = response.response;

      }, error => {
        console.log(error);
        this.windowNotification.status = true;
        this.windowNotification.title = 'Notificación';
        this.windowNotification.msg = 'Error al intentar borrar el acompañante. Intente de nuevo.';
      }, () => {
        console.log('finally');
      });


  }

  // Gets
  get cboTipoId() {
    return this.formData.get('cboTipoId');
  }
  get txtIdentificacion() {
    return this.formData.get('txtIdentificacion');
  }
  get txtNombres() {
    return this.formData.get('txtNombres');
  }
  get txtApellidos() {
    return this.formData.get('txtApellidos');
  }
  get txtCelular() {
    return this.formData.get('txtCelular');
  }
  get txtEmail() {
    return this.formData.get('txtEmail');
  }
  get txtEstatura() {
    return this.formData.get('txtEstatura');
  }
  get txtIdentificacionAcompanante() {
    return this.formData.get('txtIdentificacionAcompanante');
  }
  get txtIdentificacionResponsable() {
    return this.formData.get('txtIdentificacionResponsable');
  }
  get cboGrupoSan() {
    return this.formData.get('cboGrupoSan');
  }
  get cboGenero() {
    return this.formData.get('cboGenero');
  }
  get cboEstadoCivil() {
    return this.formData.get('cboEstadoCivil');
  }
  get txtFoto() {
    return this.formData.get('txtFoto');
  }
  get cboNivelEsc() {
    return this.formData.get('cboNivelEsc');
  }
  get txtPeso() {
    return this.formData.get('txtPeso');
  }
  get txtFechaNacimiento() {
    return this.formData.get('txtFechaNacimiento');
  }
  get txtEdad() {
    return this.formData.get('txtEdad');
  }
  get cboDepResidencia() {
    return this.formData.get('cboDepResidencia');
  }
  get txtCiudadResidencia() {
    return this.formData.get('txtCiudadResidencia');
  }
  get cboPaisResidencia() {
    return this.formData.get('cboPaisResidencia');
  }
  get cboCiuResidencia() {
    return this.formData.get('cboCiuResidencia');
  }
  get txtDireccion() {
    return this.formData.get('txtDireccion');
  }
  get cboEps() {
    return this.formData.get('cboEps');
  }
  get cboFondoPension() {
    return this.formData.get('cboFondoPension');
  }
  get txtNumHijos() {
    return this.formData.get('txtNumHijos');
  }
  get txtNomAcompanante() {
    return this.formData.get('txtNomAcompanante');
  }
  get txtCelularAcompanante() {
    return this.formData.get('txtCelularAcompanante');
  }
  get txtNomResponsable() {
    return this.formData.get('txtNomResponsable');
  }

  get txtCelularResponsable() {
    return this.formData.get('txtCelularResponsable');
  }
  get cboParentezcoResponsable() {
    return this.formData.get('cboParentezcoResponsable');
  }
  get cboOcupacion() {
    return this.formData.get('cboOcupacion');
  }
  get txtNotas() {
    return this.formData.get('txtNotas');
  }

  btnMostrarConfimarGuardar_click($event) {
    this.verVentanas.verVentanaConfirmarGuardar = true;
  }


  btnConfimarGuardar_click($event) {
    this.verVentanas.verVentanaConfirmarGuardar = false;
    if ($event === true) {
      this.enviarDatos();
    }
    return false;
  }



  enviarDatos() {

    if (this.tipoUsuario !== 'ADMIN') {
      this.formData.get('cboEstados').disable();
    }

    console.log({ viveEnColombia: this.viveEnColombia });
    console.log({ tipoAccion: this.tipoAccion });

   /* if (this.tipoAccion === 'nuevo' || this.viveEnColombia === false) {
     // this.cboCiuResidencia.disable();
    } else {
      this.cboCiuResidencia.enable();
    }*/

    this.alertMsg.showAlert = false;

    console.log(this.formData);

    // console.log(this.formData.get('txtFoto').value[0]);

    this.submitted = true;

    if (this.formData.invalid) {
      this.mostrarMsgError('El formulario contiene errores, debe verificar e intentar de nuevo');
      this.serverErrorsInfo.serverErrorsInfo = false;
      window.scroll(0, 0);
      return false;
    }

    const edad = Number(this.formData.get('txtEdad').value);
    if (edad < 0 || edad > 100) {
      alert('La fecha de nacimiento es incorrecta, la edad no puede ser negativa, ni mayor a 100');
      return false;
    }

    this.spinners.spinnerSave = true;
    const formData = new FormData();


    formData.append('tipodocumento_id', this.formData.get('cboTipoId').value);
    formData.append('identificacion', this.formData.get('txtIdentificacion').value);
    formData.append('nombres', this.formData.get('txtNombres').value);
    formData.append('apellidos', this.formData.get('txtApellidos').value);
    // formData.append('peso', this.formData.get('txtPeso').value);
    // formData.append('estatura', this.formData.get('txtEstatura').value);

    if (this.formData.get('txtFechaNacimiento').value) {
      formData.append('fecha_nacimiento', this.formData.get('txtFechaNacimiento').value.toISOString());
    } else {
      formData.append('fecha_nacimiento', '');
    }

    formData.append('escolaridad_id', this.formData.get('cboNivelEsc').value);
    formData.append('country_id', this.formData.get('cboPaisResidencia').value);

    if (this.formData.get('cboDepResidencia').enabled) {
      formData.append('departamentoresidencia_id', this.formData.get('cboDepResidencia').value);
    } else {
      formData.append('departamentoresidencia_id', '');
    }

    if (this.formData.get('cboCiuResidencia').enabled) {
      formData.append('ciudadresidencia_id', this.formData.get('cboCiuResidencia').value);
    } else {
      formData.append('ciudadresidencia_id', '');
    }

    if (this.formData.get('txtCiudadResidencia').enabled) {
      formData.append('ciudad_residencia', this.formData.get('txtCiudadResidencia').value);
    } else {
      formData.append('ciudad_residencia', '');
    }

    formData.append('genero_id', this.formData.get('cboGenero').value);
    formData.append('gruposanguineo_id', this.formData.get('cboGrupoSan').value);
    formData.append('direccion', this.formData.get('txtDireccion').value);
    formData.append('eps_id', this.formData.get('cboEps').value);
    formData.append('fondopension_id', this.formData.get('cboFondoPension').value);
    formData.append('ocupacion_id', this.formData.get('cboOcupacion').value);
    formData.append('celular', this.formData.get('txtCelular').value);
    formData.append('email', this.formData.get('txtEmail').value);
    formData.append('estadocivil_id', this.formData.get('cboEstadoCivil').value);

    if (this.formData.get('cboEstados').enabled) {
      console.log('Ingreso aquí...');
      formData.append('estado', this.formData.get('cboEstados').value);
    }

    formData.append('num_hijos', this.formData.get('txtNumHijos').value);

    if (this.formData.get('txtFoto').value !== null) {
      formData.append('foto', this.formData.get('txtFoto').value[0]);
    } else {
      formData.append('foto', '');
    }

    if (this.acompananteHabilitado === true) {
      formData.append('ide_acompanante', this.formData.get('txtIdentificacionAcompanante').value);
      formData.append('nom_acompanante', this.formData.get('txtNomAcompanante').value);
      formData.append('cel_acompanante', this.formData.get('txtCelularAcompanante').value);
    }

    if (this.responsableHabilitado === true) {
      formData.append('ide_responsable', this.formData.get('txtIdentificacionResponsable').value);
      formData.append('nom_responsable', this.formData.get('txtNomResponsable').value);
      formData.append('cel_responsable', this.formData.get('txtCelularResponsable').value);
      formData.append('par_responsable', this.formData.get('cboParentezcoResponsable').value);
    }

    let url = 'pacientes';
    if (this.tipoAccion === 'detalle') {
      url = url + '/' + this.pacienteId;
      formData.append('_method', 'PUT');
    }

    formData.append('notas', this.formData.get('txtNotas').value);
    // formData.append('estado', this.formData.get('txtEmail').value);

    this.apiServices.postFormData(formData, url).subscribe(
      res => {
        const response = res;
        console.log(response);
        this.serverErrorsInfo.serverErrorsInfo = false;
        this.alertMsg.showAlert = true;

        if (response !== null && response.success !== undefined && response.success === true) {

          this.alertMsg.class = 'alert alert-success';
          //   this.limpiarControles();
          this.submitted = false;

          if (this.tipoAccion === 'nuevo') {

            this.resetearFotoControlesCheckBox();

            let inputElement: HTMLElement = this.chkAgregarAcompananteRef.nativeElement as HTMLElement;
            inputElement.click();
            this.chkAgregarAcompananteRef.nativeElement.checked = false;
            this.formData.patchValue({ chkAgregarAcompanante: false });


            if (this.verRegistrarAcompanante) {
              this.verRegistrarAcompanante = false
              this.desBtnBorrarAcompa = false;
            }

            this.habilitarControlesAcompanante(false);

            this.formData.get('cboDepResidencia').disable();
            this.formData.get('cboCiuResidencia').disable();

          } else if (this.verRegistrarAcompanante && this.tipoAccion === 'detalle') {
            //  this.verRegistrarAcompanante = false
            this.estadoPaciente = response.item.estado;
            this.desBtnBorrarAcompa = false;
          }

        } else {
          this.alertMsg.class = 'alert alert-danger';
        }

        this.alertMsg.msg = response.response;
        window.scrollTo(0, 0);
      },
      error => {

        if (error.status !== 401) {
          this.alertMsg.showAlert = true;
          this.alertMsg.class = 'alert alert-danger';
          if (error.error.response !== undefined) {
            this.alertMsg.msg = error.error.response;
          } else {
            this.alertMsg.msg = 'Error al intentar guardar el registro. Deber verificar que el formulario no contenta errores, e intentar nuevamente';
          }

          const errors = GetMsgFromError(error);
          console.log(errors);

          this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
            errors.errors
          );

          console.log(this.serverErrorsInfo);
          this.spinners.spinnerSave = false;
          // this.spinners.spinnerSave = false;
          // window.scrollTo(0, 0);

          window.scrollTo(0, 0);
        } else {
          console.log('Error 401');
        }
      },
      () => {
        console.log('Finally');
        this.spinners.spinnerSave = false;
        window.scrollTo(0, 0);
      }
    );
  }

  resetearFotoControlesCheckBox() {
    this.fotos.mini = ''
    this.formData.get('txtFoto').reset();
    setTimeout(() => {
      this.imgFoto.nativeElement.src = '../../../assets/img/avatar.jpg';
    }, 15);

    this.limpiarControles();
   

    if (this.verRegistrarAcompanante) {
      this.verRegistrarAcompanante = false
      this.desBtnBorrarAcompa = false;
    }

    this.habilitarControlesAcompanante(false);

  }

  cargarDepartamentos(codigoPais) {
    this.apiServices.getData('departamentos/pais/' + codigoPais, 'Departamentos', false)
      .subscribe(data => {
        if (data !== null && data.success === true) {
          this.lstDepartamentos = data['Departamentos'];
        } else {
          this.cboCiuResidencia.disable();
          this.alertMsg.showAlert = true;
          this.alertMsg.class = 'alert alert-danger';
          this.alertMsg.msg = 'Error al intentar cargar los municipios del departamento. Intente de nuevo';
        }

      }, error => {
        console.log(error);
        this.cboCiuResidencia.disable();
        this.alertMsg.showAlert = true;
        this.alertMsg.class = 'alert alert-danger';
        this.alertMsg.msg = 'Error al intentar cargar los municipios del departamento. Intente de nuevo';

      }, () => {
        console.log('Finally cargarDepartamentos');
      });

  }




  btnMostrarConfirmarBorrarAcom_click() {
    this.verVentanas.verVenBorrarAcom = true;
    this.verVentanas.titVenBorrarAcom = 'Confirmación de acción';
    this.verVentanas.tetBorrarAcom = '¿Está seguro(s)?';
  }


  cboPaisResidencia_click(codigoPais) {
    // if (codigoPais > 0) {
    console.log(codigoPais);

    if (codigoPais === undefined) {
      return false;
    }

    if (codigoPais === 49) {
      this.cargarDepartamentos(codigoPais);
      setTimeout(() => {
        this.cboDepResidencia.enable();
        this.txtCiudadResidencia.disable();
        this.viveEnColombia = true;
      }, 5);
    } else {
      setTimeout(() => {
        this.cboDepResidencia.disable();
        this.cboCiuResidencia.disable();
        this.txtCiudadResidencia.enable();
        this.txtCiudadResidencia.reset();
        this.txtCiudadResidencia.updateValueAndValidity();
        this.viveEnColombia = false;
      }, 5);

    }

  }

  cboDepResidencia_Click(idDep: number): void {
    if (idDep > 0) {
      this.apiServices.getData('departamentos/' + idDep + '?ciuid=all', 'Departamento', false)
        .subscribe(response => {
          if (response.success === true) {
            this.cboCiuResidencia.enable();
            this.cboCiuResidencia.setValue('');
            this.lstCiudadesPorDep = response.Departamento.ciudads;
            console.log({ 'lstCiudadesPorDep': this.lstCiudadesPorDep });

          } else {
            this.cboCiuResidencia.disable();
            this.alertMsg.showAlert = true;
            this.alertMsg.class = 'alert alert-danger';
            this.alertMsg.msg = 'Error al intentar cargar los municipios del departamento. Intente de nuevo';
          }

        }, error => {
          if (error.status !== 401) {
            console.log(error);
            this.cboCiuResidencia.disable();
            this.alertMsg.showAlert = true;
            this.alertMsg.class = 'alert alert-danger';
            this.alertMsg.msg = 'Error al intentar cargar los municipios del departamento. Intente de nuevo';
          } else {
            console.log('Error 401');
          }
        }, () => {
          console.log('Finally cboDepResidencia_Click');
        });
    }
  }

  cargarCiudadesPorDepartamento(idDep: number): void {
    console.log(idDep);
    if (idDep > 0) {
      this.apiServices.getData('departamentos/' + idDep + '?ciuid=all', 'Departamento', false)
        .subscribe(response => {
          console.log(response);
          if (response.success === true) {
            this.lstCiudadesPorDep = response.Departamento.ciudads;
          }
        }, error => {
          console.log(error);
        }, () => {
          console.log('Finally cargarCiudadesPorDepartamento');
        });
    }
  }



  onFileSelect(event) {
    const file = (event.target as HTMLInputElement).files[0];

    //  console.log(event.target.files.length);
    // console.log(event.target.files);
    // console.log(event.target.value);
    // if (event.target.files.length > 0) {
    // const file = event.target.files[0];
    console.log(file);
    //this.formData.get('txtFoto').setValue(file);
    //}
  }

  limpiarControles(omitir = []) {
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        //    this.formData.get(element).setValue('');
        this.formData.get(element).reset();
        this.formData.get(element).updateValueAndValidity();
      }
    });

  }

  mostrarMsgError(msg: string) {
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  btnEventoNotificacion($event) {
    this.verVentanas.ventanaNotificacion = false;
    this.btnGotoUrl('ListadoDepartamentos');
  }

  btnGotoUrl(url: string) {
    this.router.navigate([url]);
    return false;
  }

  cerrarVentanas() {
    this.verVentanas.verVentanaConfirmarGuardar = false;
    this.verVentanas.verVentanaConfirmarBorrar = false;
    this.alertMsg.showAlert = false;
    //  this.mostrarVentanaConfirmacion = false;
    this.windowNotification.status = false;
  }

  preview(event) {
    const files = event.target as HTMLInputElement;


    const foto = this.formData.get('txtFoto').value[0];
    const reader = new FileReader();
    // this.imagePath = files;
    reader.readAsDataURL(foto);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      // const foto1 = window.document.getElementById('txtFoto') as HTMLInputElement;
      // foto1.src = this.imgURL;
      // console.log(this.imgFoto.nativeElement.src);
      //console.log(this.imgURL);
      this.imgFoto.nativeElement.src = this.imgURL;
      // console.log(this.imgFoto.nativeElement.src);
    }
  }


}
