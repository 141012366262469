import { Component, OnInit, AfterContentInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import {
  MaxLength
} from 'src/app/validators/CustomValidators';
import { ServiciosService } from '../../servicios/servicios.service';
import { GetMsgFromError } from 'src/app/functions/SharedFunctions';
import { serverErrorsInfo } from 'src/app/utilities/serverErrorsInfo';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-form-incapacidades',
  templateUrl: './form-incapacidades.component.html',
  styleUrls: ['./form-incapacidades.component.css']
})
export class FormIncapacidadesComponent implements OnInit, AfterContentInit {
  @ViewChild('chkProrroga') chkProrroga: ElementRef<HTMLInputElement>;
  cie10Rows: FormArray;
  incapacidadId = 0;
  pacienteId = 0;
  incapacidadCodigo = '';
  formData: FormGroup;
  submitted = false;
  serverErrorsInfo: any = {};
  cargandoDatos = false;
  registroEncontrado = false;
  nombrePaciente = '';
  esAlergico = false;
  tipoUsuario = null;
  saltarValidacion = false;
  medicoId = 0;
  errorDiasIncacidad = false;
  accionGuardarEditar = '';
  nuevoRegistro = false;
  tituloFormulario = '';
  tipoAccion = '';
  errorDeCarga = false;
  url = 'formulas';
  buscandoCodigoCie = false;
  msgNotificacion = ''
  msjErrorCie10 = '';
  msjErrorFechaFin = '';
  msjErrorFechaInicio = '';
  dataCies: Array<{ id: string, codigo_titulo: string }>;
  valoresCie10Agregados = [];
  nuevoNuevo = false;
  origen = undefined;
  codigoOrigen = undefined;
  codigoIncapacidad = 0;
  tienePdf = false;
  urlPdf = '';
  baseUrl2 = environment.baseUrl2;
  accionEjecutandose = false;
  medicoIdReg = 0;
  // accionGuardarEditar = '';
  // loadingData = false;
  //serverErrorsInfo: any = {};

  urlOrigen = {
    origen: '',
    tieneOrigen: false,
    url: '',
    id: 0
  }

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  };


  verVentanas = {
    verVentanaConfirmarGuardar: false,
    titVenCon: '',
    tetVenCon: '',
    accion: '',
    verVentanaCredenciales: false,
    verVentanaConfirmarCredenciales: false,
    titentanaConfirmarCredenciales: '',
    tetVentanaConfirmarCredenciales: '',
    verVentanaImprimir: false,
    titVentanaImprimir: 'Generar PDF',
    texVentanaImprimir: '¿Desea generar un PDF?'
  }

  dataPaciente: any;
  datosPacienteCargados = false;
  desBtnGuardar = false;

  // ngModelTxtCiuResPaciente = '';

  dataAcciones: Array<any> = [{
    text: 'Volver al listado de pacientes', url: 'ListadoPacientes', item: 'listado_pacientes'
  }, {
    text: 'Volver a la historia clinica del paciente', url: 'HistoriaPaciente', item: 'historia_paciente'
  }, {
    text: 'Volver a su historial de incapacidades', url: 'ListadoIncapacidades', item: 'listado_incapacidades'
  }, {
    text: 'Habilitar edición', item: 'habilitar_edicion', url: '',
  }, {
    text: 'Nueva incapacidad vacía', item: 'nueva_incapacidad', url: 'AgregarIncapacidad',
  }, {
    text: 'Imprimir', item: 'imprimir'
  }];

  public lstEstados: Array<{ text: string; value: string }> = [
    { text: 'Activo', value: 'Activo' },
    { text: 'Bloqueado', value: 'Bloqueado' }
  ];

  //esAlergico = false;


  constructor(private router: Router,
    private apiServices: ServiciosService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute) {
    // window.scrollTo(0, 0);
    // this.activatedRoute.queryParams.subscribe(params => {
    //   const incapacidadId = params.incapacidadId;
    //   this.incapacidadId = incapacidadId;
    //   //console.log(incapacidadId);
    //   // this.getData(incapacidadId);
    // });

  }



  ngOnInit(): void {
    //this.esAlergico = false;
    this.formData = this.formBuilder.group({

      txtEmpresa: ['', [Validators.required, MaxLength(false, 1000)]],
      txtDiagnostico: ['', [MaxLength(true, 6000)]],
      txtOrigen: ['', [Validators.required, MaxLength(false, 6000)]],
      txtFechaInicio: ['', [Validators.required]],
      txtFechaFin: ['', [Validators.required]],
      txtDias: ['', [Validators.required]],
      txtTelefono: ['', [MaxLength(true, 15)]],
      chkProrroga: [''],
      cboEstados: ['', [Validators.required]],
      txtDireccion: ['', [MaxLength(true, 1000)]],
      txtNotas: ['', [MaxLength(true, 6000)]],
      cie10Rows: this.formBuilder.array([this.createCie10Row()]),

    });

    window.scrollTo(0, 0);

    // this.tipoUsuario = this.apiServices.getTipoUsuario();
    // const userInfo = this.apiServices.getUserInfoFromToken();
    // this.medicoId = userInfo.userInfo.id;
    // console.log(userInfo);

    // debugger;
  }

  isInt(value) {
    return Number.isInteger(Number(value));
  }

  createCie10Row(): FormGroup {
    return this.formBuilder.group({
      cie10Codigo: [''],
      cie10Titulo: [''],
    });
  }

  deleteCie10Row(index: number) {
    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    if (this.cie10Rows.controls.length > 1) {
      this.cie10Rows.removeAt(index);
      // this.indiceCie10--;
    } else {
      const cie10CodigoControl: any = this.cie10Rows.controls[0]['controls']['cie10Codigo'] as FormControl;
      // const cie10TituloControl: any = this.cie10Rows.controls[0]['controls']['cie10Titulo'] as FormControl;
      cie10CodigoControl.setValue('');
      // cie10TituloControl.setValue('');
      // this.indiceCie10 = 0;
    }
    this.msjErrorCie10 = '';
    return false;
  }

  handleFilterCie10($event: string, index) {
    if ($event === '' || $event === null || $event === undefined) {
      return false;
    }
    console.log({ Valor_seleccionado: $event });
    if ($event.length > 0) {
      this.buscandoCodigoCie = true;
      this.delay(() => {
        this.apiServices.getData('cies?codigo=' + $event + '&titulo=' + $event + '&filtro=or', 'Cies', false)
          .subscribe(data => {
            console.log(data);
            if (data !== null && data.success === true) {
              this.dataCies = data['Cies'];
              console.log(this.dataCies);
            }
            this.buscandoCodigoCie = false;
          }, error => {
            this.buscandoCodigoCie = false;
            console.log(error);
          }, () => {

          });

      }, 2000, '');
    }
  }

  delay = (function () {
    const timers = {};
    return function (callback, ms, label) {
      label = label || 'defaultTimer';
      window.clearTimeout(timers[label] || 0);
      timers[label] = window.setTimeout(callback, ms);
    };
  })();

  public valueChange(value: string, index: number): void {
    console.log('valueChange', value);
    if (value === '' || value === undefined || value === null) {
      this.deleteCie10Row(index);
    }
    if (this.dataCies.length > 0) {
      if (this.verificarValoresRepetidos(value, index) && this.cie10Rows.length > 1) {
        console.log('******** valueChange')
        this.msjErrorCie10 = 'Error: código repetido';
      } else {
        console.log('******** valueChange vacio')
        this.msjErrorCie10 = '';
      }

      this.valoresCie10Agregados.push(value);
      // const item: any = this.dataCies[0];
      // if (value === item.codigo) {
      //   const cie10TituloControl: any = this.cie10Rows.controls[index]['controls']['cie10Titulo'] as FormControl;
      //   cie10TituloControl.setValue(item.titulo);
      // }
    } else {
      this.deleteCie10Row(index);
      //const cie10CodigoControl: any = this.cie10Rows.controls[index]['controls']['cie10Codigo'] as FormControl;
      //  const cie10TituloControl: any = this.cie10Rows.controls[index]['controls']['cie10Titulo'] as FormControl;
      // cie10CodigoControl.setValue('');
    }

    // console.log
  }

  verificarValoresRepetidos(valor, index?): boolean {
    // const strArray = [];
    // const alreadySeen = [];
    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    const lengthRows = this.cie10Rows['controls'].length;
    console.log(valor);
    for (let i = 0; i < lengthRows; i++) {
      const cie10CodigoControl: any = this.cie10Rows.controls[i]['controls']['cie10Codigo'] as FormControl
      if (cie10CodigoControl.value === valor && i !== index) {
        return true;
      }
    }

    return false;
    // return new Set(strArray).size !== strArray.length;
  }


  ngAfterContentInit(): void {

    this.tipoUsuario = this.apiServices.getTipoUsuario();
    const userInfo = this.apiServices.getUserInfoFromToken();
    if (this.tipoUsuario === 'MEDICO') {
      this.medicoId = userInfo.userInfo.id;
    } else {
      this.medicoId = 0;
    }




    this.activatedRoute.queryParams.subscribe(params => {

      //Cargar diagnosticos si existent
      // origen=ingreso&codigo=3386114859

      //Determinar el orien de la incapacidad
      this.origen = params.origen;
      this.codigoOrigen = params.codigo_origen;

      //Determinar el orien de la incapacidad
      this.origen = params.origen;
      this.codigoOrigen = params.codigo_origen;

      if (this.origen !== undefined &&
        this.codigoOrigen !== undefined &&
        this.isInt(this.codigoOrigen)) {
        //    var Backlen=history.length;   history.go(-Backlen); 
        this.determinarOrigenIncapacidad();
      }


      const tipo = params.tipo;
      const incapacidadId = params.incapacidadId;
      const pacienteId = params.pacienteId;
      console.log({ paciente_id: this.isInt(pacienteId) });
      this.registroEncontrado = false;
      this.tipoAccion = tipo;

      // tipo = tipo;
      this.pacienteId = pacienteId;
      this.incapacidadId = incapacidadId;

      //tipo = params.tipo;

      if (tipo === 'nuevo') {
        // tipo = tipo;
        this.limpiarContoles();
        this.nuevoRegistro = true;
        this.tituloFormulario = 'Registrar nueva incapacidad';
        this.formData.get('cboEstados').disable();
        this.dataAcciones = this.dataAccionesesGuardar();
        //console.log();
        this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
        const arr = <FormArray>this.formData.controls.cie10Rows;
        arr.controls = [];
        this.cie10Rows.push(this.createCie10Row());

      } else if (tipo === 'detalle') {

        if (this.tipoUsuario === 'ADMIN') {
          this.formData.get('cboEstados').enable();
        } else {
          this.formData.get('cboEstados').disable();
        }
        //  tipo = tipo;
        this.nuevoRegistro = false;
        this.dataAcciones = this.dataAccionesesEditar();

      } else {
        this.errorDeCarga = true;
        this.cargandoDatos = false;
        const msg = 'Error al intentar determinar el tipo de acción a realizar.';
        this.mostrarMsgError(msg);
        return false;
      }

      if (pacienteId === undefined && incapacidadId === undefined) {
        this.errorDeCarga = true;
        //   this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      } else if (this.isInt(pacienteId) === false && tipo == 'nuevo') {
        this.errorDeCarga = true;
        console.log({ tipoRecibido2: tipo });
        //  this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      } else if (this.isInt(incapacidadId) === false && tipo == 'detalle') {
        this.errorDeCarga = true;
        //  this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar cargar los datos. El ID de la Formula es incorrecto o no existe.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      }

      console.log({ nuevoRegistro: this.nuevoRegistro });
      console.log({ errorDeCarga: this.errorDeCarga });
      //console.log({tipoAccion: tipo });

      if (this.nuevoRegistro === false && this.errorDeCarga === false) {
        this.cargandoDatos = true;
        this.getData(this.incapacidadId);
      } else if (this.nuevoRegistro === true && this.errorDeCarga === false) {

        this.obtenerCodigosCie10();
        this.getPaciente(this.pacienteId);
      }

    });


    // this.formData.get('txtTiempo').disable();

    // debugger;
  }

  determinarOrigenIncapacidad() {
    switch (this.origen) {
      case 'control':
        this.urlOrigen.tieneOrigen = true;
        this.urlOrigen.origen = 'Controles';
        this.urlOrigen.url = 'FormControles';
        this.urlOrigen.id = this.codigoOrigen;
        break;
      case 'evolucion':
        this.urlOrigen.tieneOrigen = true;
        this.urlOrigen.origen = 'Evoluciones';
        this.urlOrigen.url = 'FormEvoluciones';
        this.urlOrigen.id = this.codigoOrigen;
        break;
      case 'cirugia':
        this.urlOrigen.tieneOrigen = true;
        this.urlOrigen.origen = 'Cirugias';
        this.urlOrigen.url = 'FormCirugias';
        this.urlOrigen.id = this.codigoOrigen;
        break;
    }
  }
  // chkProrroga_click($event) {
  //  // console.log($event.target.);
  //   console.log(this.formData.get('chkProrroga').value)
  // }
  agregarFilaCie10() {

    console.log(this.desBtnGuardar);
    if (this.desBtnGuardar === true) {
      return false;
    }

    if (this.msjErrorCie10.length === 0) {

      this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
      const lengthRows = this.cie10Rows['controls'].length;
      console.log(lengthRows);
      if (lengthRows > 0) {
        for (let i = 0; i < lengthRows; i++) {
          const cie10CodigoControl: any = this.cie10Rows.controls[i]['controls']['cie10Codigo'] as FormControl
          if (cie10CodigoControl.value === '') {
            this.msjErrorCie10 = 'Debe completar el código vacío';
            console.log('Hay códigos vacios');
            break;
          }
        }
      }

      if (this.msjErrorCie10.length > 0) {
        return false;
      }
      // return false;

      this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
      this.cie10Rows.push(this.createCie10Row());
    }
  }

  setObjectInStorage(nombre: string, object: any) {
    localStorage.setItem(nombre, JSON.stringify(object));
  }

  getObjectInStorage(nombre: string) {
    const retrievedObject = localStorage.getItem(nombre);
    return JSON.parse(retrievedObject);
  }

  obtenerCodigosCie10() {
    if (this.origen !== undefined &&
      this.codigoOrigen !== undefined &&
      this.isInt(this.codigoOrigen)) {

      if (this.isInt(this.codigoOrigen > 0)) {
        let url = ''; let list = '';
        let objCodigosCie: any; let diagnostico: any;

        if (this.origen === 'ingreso') {
          url = 'historiaclinicas/' + this.pacienteId;
          list = 'Historiaclinica';
        } else if (this.origen === 'control') {
          url = 'controles/' + this.codigoOrigen;
          list = 'Control';
        } else if (this.origen === 'evolucion') {
          url = 'evoluciones/' + this.codigoOrigen;
          list = 'Evolucion';
        }

        console.log(url);
        console.log('Petición no cargando');
        this.apiServices.getData(url, list, false).subscribe(
          data => {
            console.log(data);
            if (data !== null && data.success !== undefined && data.success ===true) {
              console.log(data);
              const item = data[list];
              objCodigosCie = item.diagnosticos_cie10;

              console.log(objCodigosCie);
              diagnostico = item.diagnostico_manual;
              if (diagnostico !== null) {
                this.txtDiagnostico.setValue('' + diagnostico);
              }

              console.log(objCodigosCie);
              //  console.log(objCodigosCie);
              if (item.diagnosticos_cie10.trim().length > 0 && this.IsJsonString(item.diagnosticos_cie10)) {
                objCodigosCie = JSON.parse(item.diagnosticos_cie10);
                this.asignarCodigosCie10(objCodigosCie);
              }

              console.log({ codigos: objCodigosCie });

            } else {
              console.log('No tiene diagnostico');
            }

          }, error => {
            console.log(error);
            console.log('Error al intentar obtener los códigos CIE10');
            objCodigosCie = null;
            diagnostico = null;
          }, () => {

          }
        );


        // const nombreObjetoCie10 = this.origen + 'Cie10_' + this.codigoOrigen;
        // const objCodigosCie = this.getObjectInStorage(nombreObjetoCie10);


      } //Fin   if (this.isInt(this.codigoOrigen > 0)) {
    }
  }

  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  asignarCodigosCie10(objCodigosCie) {
    if (objCodigosCie !== null && objCodigosCie !== undefined) {
      if (Object.keys(objCodigosCie).length > 0) {
        this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
        const arr = <FormArray>this.formData.controls.cie10Rows;
        arr.controls = [];
        // const itemCie10 = diagnostico.diagnosticos_cie10;
        Object.keys(objCodigosCie).forEach(index => {
          const itemCie10 = objCodigosCie[Number(index)];
          console.log({ itemCie10: itemCie10 });
          //console.log({ indiceCie10: index })
          // if (Number(index) === 0) {
          this.cie10Rows.push(this.createCie10Row());
          const cie10CodigoControl: any = this.cie10Rows.controls[Number(index)]['controls']['cie10Codigo'] as FormControl;
          // const cie10TituloControl: any = this.cie10Rows.controls[Number(index)]['controls']['cie10Titulo'] as FormControl;
          // setTimeout(() => {
          cie10CodigoControl.setValue(itemCie10.codigo + ' - ' + itemCie10.titulo);
          //  }, 0);

          // cie10TituloControl.setValue(diagnosticos_cie10[Number(index)].titulo);
          //  this.indiceCie10++;
        });
      }
    }
  }

  /* cargarDiagnosticos() {
     if (this.origen !== undefined && this.codigoOrigen !== undefined) {
       const nombreObjetoCie10 = this.origen + 'Cie10_' + this.codigoOrigen;
       const objCodigosCie = this.getObjectInStorage(nombreObjetoCie10);
 
       if (objCodigosCie !== null) {
         if (Object.keys(objCodigosCie).length > 0) {
           this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
           const arr = <FormArray>this.formData.controls.cie10Rows;
           arr.controls = [];
           // const itemCie10 = diagnostico.diagnosticos_cie10;
           Object.keys(objCodigosCie).forEach(index => {
             const itemCie10 = objCodigosCie[Number(index)];
             console.log({ itemCie10: itemCie10.codigo });
             //console.log({ indiceCie10: index })
             // if (Number(index) === 0) {
             this.cie10Rows.push(this.createCie10Row());
             const cie10CodigoControl: any = this.cie10Rows.controls[Number(index)]['controls']['cie10Codigo'] as FormControl;
             // const cie10TituloControl: any = this.cie10Rows.controls[Number(index)]['controls']['cie10Titulo'] as FormControl;
             setTimeout(() => {
               cie10CodigoControl.setValue('' + itemCie10);
             }, 0);
 
             // cie10TituloControl.setValue(diagnosticos_cie10[Number(index)].titulo);
             //  this.indiceCie10++;
           });
         }
       }
       console.log({ codigos: objCodigosCie });
 
       const nombreObjetoDiagnostico = this.origen + 'Diagnostico_' + this.codigoOrigen;
       const diagnostico = localStorage.getItem(nombreObjetoDiagnostico);
       console.log(nombreObjetoDiagnostico);
       console.log(diagnostico);
 
       if (diagnostico !== null) {
         this.txtDiagnostico.setValue('' + diagnostico);
       }
 
     }
 
   } */

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  chkProrroga_click($event) {
    //  console.log($event.target.value);
    console.log(this.chkProrroga.nativeElement.checked);

    if (this.chkProrroga.nativeElement.checked === true) {
      this.chkProrroga.nativeElement.checked = true;
      let valFechaFin = this.formData.get('txtFechaFin').value;
      console.log(valFechaFin);
      console.log(typeof valFechaFin);

      if (valFechaFin === '' || valFechaFin === null) {
        // alert('Debe seleccionar una fecha de fin valida');
        alert('Debe seleccionar una fecha de fin valida');
        console.log(this.msjErrorFechaFin);
        return false;
      } else {
        this.msjErrorFechaFin = '';
      }

      // if (!isNaN(valFechaFin.getTime())) {
      //   console.log('La fecha x');
      //   this.msjErrorFechaFin = 'Debe seleccionar una fecha de fin valida';
      // }

      if (valFechaFin !== null && valFechaFin !== undefined) {
        valFechaFin = new Date(this.formData.get('txtFechaFin').value);
        console.log(valFechaFin);

        if (valFechaFin === 'invalidDate') {
          //  alert('Debe seleccionar una fecha de fin valida');
          alert('Debe seleccionar una fecha de fin valida');
          return false;
        }

        this.formData.get('txtFechaInicio').setValue(this.addDays(valFechaFin, 1));
        // this.formData.get('txtFechaFin').setValue('');
        this.formData.get('txtFechaFin').reset();
        this.formData.get('txtFechaFin').updateValueAndValidity();
        this.formData.get('txtDias').setValue(0);
        this.chkProrroga.nativeElement.checked = false;

      } else {
        this.chkProrroga.nativeElement.checked = false;
        this.formData.get('txtFechaFin').reset();
        this.formData.get('txtFechaFin').updateValueAndValidity();
        this.formData.get('txtDias').setValue(0);
        // this.msjErrorFechaFin = 'Debe seleccionar una fecha de fin valida';
        alert('Debe seleccionar una fecha de fin valida');
      }

    } else {
      this.chkProrroga.nativeElement.checked = false;
    }
  }

  getData(incapacidadId: number) {
    // console.log(itemId);
    console.log({ incapacidadId: incapacidadId })
    if (incapacidadId !== undefined && Number(incapacidadId) > 0) {
      let urlParams = 'incapacidades/' + incapacidadId;
      // urlParams += 'id/' + incapacidadId;
      //   urlParams += '?espid=all';
      // let item: Item;
      this.apiServices.getData(urlParams, 'Incapacidad', false).subscribe(
        data => {
          // console.log({ registroEncontrado: this.registroEncontrado })
          // console.log(data);
          if (data !== null && data.success !== undefined && data.success === true) {
            const item = data['Incapacidad'];
            console.log(data);
            // console.log({registroEncontrado: item.paciente_id})
            this.registroEncontrado = true;
            this.pacienteId = item.paciente_id;
            this.tituloFormulario = 'Detalle de la incapacidad médica. Código: ' + item.codigo;
            this.codigoIncapacidad = item.codigo;

            this.txtEmpresa.patchValue(item.dirigido_a);
            this.txtDiagnostico.patchValue(item.diagnostico);
            this.txtOrigen.patchValue(item.origen);

            // let valFecha1 = new Date(item.fecha1);
            // var timestamp = valFecha1.getTime();
            // var formattedDate = new Date(timestamp);
            // console.log(formattedDate);

            let valFecha = new Date(item.fecha1);
            this.formData.get('txtFechaInicio').setValue(this.addDays(valFecha, 1));
            this.txtFechaInicio.patchValue(this.addDays(valFecha, 1));

            valFecha = new Date(item.fecha2);
            this.formData.get('txtFechaFin').setValue(this.addDays(valFecha, 1));
            this.txtFechaFin.patchValue(this.addDays(valFecha, 1));



            this.txtDias.patchValue(item.num_dias);
            this.txtTelefono.patchValue(item.telefono_empresa);
            this.txtDireccion.patchValue(item.direccion_empresa);
            this.txtNotas.patchValue(item.notas_incapacidad);
            this.cboEstados.patchValue(item.estado);
            //  this.incapacidadCodigo = '# ' + item.codigo;
            this.getPaciente(item.paciente_id);
            this.medicoIdReg = item.medico_id;

            if (item.diagnosticos_cie10.trim().length > 0) {
              console.log(item.diagnosticos_cie10);
              const objCodigosCie = JSON.parse(item.diagnosticos_cie10);
              this.asignarCodigosCie10(objCodigosCie);
            }


            if (this.tipoUsuario === 'ADMIN') {
              this.habilitarControles(['txtDias']);
              this.desBtnGuardar = false;
            } else if (!this.medicoActualHizoIngreso(this.medicoId, this.medicoIdReg, this.tipoUsuario)) {
              this.deshabilitarControles();
              this.desBtnGuardar = true;
            }
          } else {
            this.registroEncontrado = false;
          }

          //  this.cargandoDatos = false;
        },
        error => {
          console.log(error);
          const errors = GetMsgFromError(error);
          this.alertMsg.showAlert = true;
          this.alertMsg.class = 'alert alert-danger';
          this.alertMsg.msg = '' + errors.msg;
          this.cargandoDatos = false;
          this.registroEncontrado = false;

          //  console.log(errors);

          this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(errors.errors);
          console.log(this.serverErrorsInfo);
          // this.spinners.mostrarSpinner = false;
        },
        () => {
          console.log('Finally');
          //  this.loadedData = true;
          // this.spinners.mostrarSpinner = false;
        }
      );
    } else {
      //  this.loadingData = false;
      this.cargandoDatos = false;
      this.registroEncontrado = false;
      this.alertMsg.showAlert = true;
      this.alertMsg.class = 'alert alert-danger';
      this.alertMsg.msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe.';
    }
  }

  medicoActualHizoIngreso(idMedicoLogin: Number, idMedicoHistoria: Number, tipoUsuario: string) {
    console.log({ idMedicoHistoria: idMedicoHistoria });
    console.log({ idMedicoLogin: idMedicoLogin });

    if (tipoUsuario === 'MEDICO' && (idMedicoHistoria === idMedicoLogin)) {
      return true; //Si es el propietario
    }
    return false;
  }

  get txtEmpresa() {
    return this.formData.get('txtEmpresa');
  }
  get txtDiagnostico() {
    return this.formData.get('txtDiagnostico');
  }
  get txtOrigen() {
    return this.formData.get('txtOrigen');
  }
  get txtFechaInicio() {
    return this.formData.get('txtFechaInicio');
  }
  get txtFechaFin() {
    return this.formData.get('txtFechaFin');
  }
  get txtDias() {
    return this.formData.get('txtDias');
  }
  get txtTelefono() {
    return this.formData.get('txtTelefono');
  }
  get txtDireccion() {
    return this.formData.get('txtDireccion');
  }
  get txtNotas() {
    return this.formData.get('txtNotas');
  }

  get cboEstados() {
    return this.formData.get('cboEstados');
  }



  // get txtAyudante() {
  //   return this.formData.get('txtAyudante');
  // }

  // get txtAntibiotico() {
  //   return this.formData.get('txtAntibiotico');
  // }




  // btnGuardarNueva_click($event) {
  //   this.guardarComoNueva = true;
  //   this.enviarDatos();
  // }

  // btnActualizar_click($event) {
  //   this.guardarComoNueva = false;
  //   this.enviarDatos();
  // }

  // btnGuardar_click(accion: string) {

  //   const acciones = ['guardar', 'editar'];
  //   if (!acciones.includes(accion)) {
  //     this.mostrarMsgError('Acción no permitida');
  //     return false;
  //   }
  //   if (accion === 'guardar') {
  //     this.enviarDatos('guardar');
  //   } else {
  //     this.enviarDatos('editar');
  //   }

  //   this.accionGuardarEditar = accion;
  //   console.log(this.accionGuardarEditar);
  // }
  btnGotoUrl(url: string, params = null) {
    // this.verVentanaDetallePaciente = true;
    console.log(url);
    if (params === null) {
      this.router.navigate([url]);
    } else {
      const queryParams = params;
      this.router.navigate([url], { queryParams });
    }

    return false;
  }


  getPaciente(id: number) {

    if (id > 0) {
      let urlParams = 'pacientes/' + id;
      // urlParams += 'id/' + incapacidadId;
      urlParams += '?genero=all';
      urlParams += '&ocup=all';

      this.apiServices.getData(urlParams, 'Paciente', false)
        .subscribe(data => {

          if (data.success === true && data['Paciente'] !== null && data['Paciente'] !== undefined) {
            this.dataPaciente = data['Paciente'];
            //const paciente = this.dataPaciente;
            this.dataPaciente['fecha_actual'] = data.fecha_actual;
            this.nombrePaciente = 'de: ' + this.dataPaciente.nombre_completo + ' (CC: ' + this.dataPaciente.identificacion + ')';
            //console.log(data);
            this.datosPacienteCargados = true;
            this.registroEncontrado = true;
            console.log(this.dataPaciente);

            if (this.dataPaciente.alergico === 'Si') {
              this.esAlergico = true;
            } else {
              this.esAlergico = false;
            }

          } else {
            this.registroEncontrado = false;
            let msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe. ';
            this.mostrarMsgError(msg);
          }

          // this.registroEncontrado = true;
          this.cargandoDatos = false;
        }, error => {
          console.log(error);
          // let msg = '';
          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError('Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe. ');
          }
          this.registroEncontrado = false;
          this.cargandoDatos = false;
        }, () => {
          console.log('Finally');
        });
    } else {
      this.cargandoDatos = false;
      this.registroEncontrado = false;
      let msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe. ';
      //   msg += 'Debe verificar que el formulario no contenga errores';
      this.mostrarMsgError(msg);
    }
  }

  obtenerDiasIncapacidad_input($event, tipo) {

    console.log(this.formData.get('txtFechaInicio').value);
    console.log(this.formData.get('txtFechaFin').value);
    //  this.formData.get('txtDias').setValue(0);
    this.alertMsg.showAlert = false;

    const tipos = ['fecha_inicio', 'fecha_fin'];
    if (tipos.includes(tipo)) {
      let fechaInicio = '';
      let fechaFin = '';

      console.log(this.formData.get('txtFechaInicio').value);
      console.log(this.formData.get('txtFechaFin').value);

      fechaInicio = this.formData.get('txtFechaInicio').value;
      fechaFin = this.formData.get('txtFechaFin').value;

      console.log(fechaInicio);
      console.log(fechaFin);

      //Limpiar el mensaje de validación
      // if(fechaFin!== null) {
      //   this.msjErrorFechaFin = '';
      // }

      if (fechaInicio === null || fechaFin === null) {
        this.errorDiasIncacidad = true;
      }

      // let m = moment(fechaInicio, 'DD-MM-YYYY');
      // if (m.isValid() === false) {
      //   this.msjErrorFechaInicio = 'La fecha de inicio seleccionada es incorrecta';
      //   this.errorDiasIncacidad = true;
      //   return false;
      // } else {
      //   this.errorDiasIncacidad = false;
      //   this.msjErrorFechaInicio = '';
      // }

      // m = moment(fechaFin, 'DD-MM-YYYY');
      // if (m.isValid() === false) {
      //   this.msjErrorFechaFin = 'La fecha de fin seleccionada es incorrecta';
      //   this.errorDiasIncacidad = true;
      //   return false;
      // } else {
      //   this.errorDiasIncacidad = false;
      //   this.msjErrorFechaFin = '';
      // }

      // console.log(this.msjErrorFechaInicio);
      // console.log(this.msjErrorFechaFin);
      // console.log(this.errorDiasIncacidad);

      let dias = 0;
      // let msg = 'Los días de la incapacidad son incorrectos.';
      // msg += ' No pueden ser menor o igual a cero o superior a 30 días.';
      // msg += ' Debe verificar que la fecha de inicio y fin sean correctas.  ';

      if (fechaInicio === null || fechaInicio === undefined) {
        this.formData.get('txtDias').setValue('' + dias);
      } else if (fechaFin === null || fechaFin === undefined) {
        this.formData.get('txtDias').setValue('' + dias);
      } else if (fechaInicio.toString().length > 0 && fechaFin.toString().length > 0) {

        fechaInicio = fechaInicio.toString();
        fechaFin = fechaFin.toString();
        console.log(fechaInicio);
        console.log(fechaFin);
        dias = Math.floor((Date.parse(fechaFin) - Date.parse(fechaInicio)) / 86400000);

        if (dias > 30) {
          this.errorDiasIncacidad = true;
          this.formData.get('txtDias').setValue('' + dias);
          // this.formData.get('txtFechaFin').setValue('');
          //  this.formData.get('txtFechaFin').reset();
          // this.mostrarMsgError(msg);
          //   this.errorDiasIncacidad = true;
          //   alert(msg);
          // window.scroll(0, 0);
        } else if (dias == 0) {
          this.errorDiasIncacidad = true;
          console.log('número días' + dias);
          this.formData.get('txtDias').setValue('' + dias);
          // this.formData.get('txtFechaFin').setValue('');
          // this.formData.get('txtFechaFin').reset();
          /// alert('Error: la fecha final debe ser superior a la fecha inicial')
          // alert(msg);
          //  this.errorDiasIncacidad = true;
          //  alert(msg);
          // window.scroll(0, 0);
          // return false;
        } else if (dias <= 0) {
          this.formData.get('txtDias').setValue('' + dias);
          //this.mostrarMsgError(msg);
          //  this.errorDiasIncacidad = true;
          //  alert(msg);
          // window.scroll(0, 0);
        }

        console.log(dias);

        if (dias > -1 && dias <= 30) { //La incapacidad no puede superar 30 días
          this.errorDiasIncacidad = false;
          this.formData.get('txtDias').setValue(dias + 1);
          this.alertMsg.showAlert = false;
        } else {
          this.errorDiasIncacidad = true;
          this.formData.get('txtDias').setValue(dias);
        }

        console.log(dias);
      } else {
        console.log(dias);
        this.formData.get('txtDias').setValue(0);
      }

    }
    // const diff =  Math.floor(( Date.parse(str2) - Date.parse(str1) ) / 86400000); 
  }

  // 1
  btnMostrarConfirmarNuevo_click() {
    this.accionGuardarEditar = 'nuevo';
    this.verVentanas.verVentanaConfirmarGuardar = true;
    this.verVentanas.titVenCon = 'Confirmación de acción';
    this.verVentanas.tetVenCon = '¿Está seguro(s)?';
  }

  // 1.1
  btnMostrarConfirmarEditar_click() {
    this.accionGuardarEditar = 'nuevo'; //Poner aqui editar para  poder editar, se deja el valor de nuevo para evitar que se pueda editar
    this.verVentanas.verVentanaConfirmarGuardar = true;
    this.verVentanas.titVenCon = 'Confirmación de acción';
    this.verVentanas.tetVenCon = '¿Está seguro(s)?';
  }

  // 2
  btnConfimarGuardar_click($event) {
    this.cerrarVentana();
    console.log($event);
    if ($event === true && this.tipoUsuario == 'MEDICO') {
      this.enviarDatos();
    } else if ($event === true && this.tipoUsuario !== 'MEDICO' && this.saltarValidacion === false) {
      let msg = 'Se ha detectado que ';
      msg += 'su rol no es Médico. Para poder generar la incapacidad <br />';
      msg += 'primero debe ingresar las credenciales del médico que será asociado a la misma. <br />';
      msg += 'Presione el botón Si para continuar.';
      this.verVentanas.verVentanaConfirmarCredenciales = true;
      this.verVentanas.titentanaConfirmarCredenciales = 'Notificación';
      this.verVentanas.tetVentanaConfirmarCredenciales = msg;
    } else if ($event === true && this.tipoUsuario !== 'MEDICO' && this.saltarValidacion === true) {
      this.enviarDatos();
    }
  }

  // 3 
  btnConfirmarCredenciales_click($event) {
    console.log($event);
    if ($event === true) {
      this.cerrarVentana();
      this.verVentanas.verVentanaCredenciales = true;
    } else {
      this.cerrarVentana();
    }
  }

  // 4
  btnLogin_click($event) {
    console.log($event);
    if ($event !== null) {
      this.cerrarVentana();
      this.medicoId = $event.id;
      this.saltarValidacion = true;
      this.enviarDatos();
    }
  }


  btnGenerarPdf_click($event) {

    if ($event !== null && $event === true) {
      console.log($event);
      console.log($event);
      if (this.codigoIncapacidad > 0) {
        window.scrollTo(0, document.body.scrollHeight);
        this.generarPdf();
      } else if (this.codigoIncapacidad === 0) {
        alert('Primero debe guardar');
      }
    }

    this.cerrarVentana();
  }

  generarPdf() {
    this.accionEjecutandose = true;
    let url = 'reportes/generarpdf?origen=incapacidades';
    url += '&codigo=' + this.codigoIncapacidad+'&pacienteid='+this.pacienteId
    this.apiServices.getData(url, 'Reporte', false)
      .subscribe(
        data => {
          console.log(data);

          if (data !== null && data.success !== undefined && data.success === true) {
            this.tienePdf = true;
            this.urlPdf = this.baseUrl2 + data.path;
            this.accionEjecutandose = false;
            this.alertMsg.showAlert = false;
            window.open(this.baseUrl2 + data.path);
          } else {
            this.mostrarMsgError('Error al intentar generar el PDF, intente de nuevo');
          }
          this.accionEjecutandose = false;
        }, error => {
          console.log(error);
          //  this.codigoFormula = 0;
          //  this.cerrarVentana();
          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError('Error al intentar generar el PDF, intente de nuevo');
          }
          this.accionEjecutandose = false;
        }, () => {

        });
  }

  enviarDatos() {
    this.alertMsg.showAlert = false;
    this.submitted = true;
    const formData = new FormData();
    console.log(this.formData);
    //  this.submitted = true;
    let url = 'incapacidades';
    let msgNotificacion = '';
    if (this.accionGuardarEditar === 'editar') {
      url = 'incapacidades/' + this.incapacidadId;
      msgNotificacion = 'editar';
    } else {

      msgNotificacion = 'guardar';
    }

    if (this.errorDiasIncacidad === true) {
      let msg = 'Los días de la incapacidad son incorrectos.';
      msg += ' No pueden ser menor o igual a cero o superior a 30 días.';
      msg += ' Debe verificar que la fecha de inicio y fin sean correctas.  ';
      this.mostrarMsgError(msg);
      window.scroll(0, 0);
      return false;
    }

    console.log(this.formData);

    if (this.formData.invalid) {
      let msg = 'Error al intentar ' + msgNotificacion + ' la incapacidad. ';
      msg += 'Verifique que el formulario no contenga errores e intente de nuevo';
      this.mostrarMsgError(msg);
      window.scroll(0, 0);
      return false;
    }


    //console.log({ guardarComoNueva: this.guardarComoNueva });
    //Sino es un médico no puede dejar generar la incapacidad


    formData.append('paciente_id', '' + this.dataPaciente.id);
    formData.append('incapacidad_id', '' + this.incapacidadId);
    formData.append('medico_id', '' + this.medicoId);// this.apiServices.getUserInfoFromToken();
    formData.append('dirigido_a', this.formData.get('txtEmpresa').value);
    formData.append('diagnostico', this.formData.get('txtDiagnostico').value);
    formData.append('diagnosticos_cie10', JSON.stringify(this.agregarValoresCie10()));
    formData.append('fecha_inicio', this.formData.get('txtFechaInicio').value.toISOString());
    formData.append('fecha_fin', this.formData.get('txtFechaFin').value.toISOString());
    formData.append('origen', this.formData.get('txtOrigen').value);
    formData.append('estado', this.formData.get('cboEstados').value);
    formData.append('telefono_empresa', this.formData.get('txtTelefono').value);
    formData.append('direccion_empresa', this.formData.get('txtDireccion').value);
    formData.append('notas_incapacidad', this.formData.get('txtNotas').value);
    formData.append('notas_incapacidad', this.formData.get('txtNotas').value);


    console.log(this.accionGuardarEditar);

    if (this.accionGuardarEditar === 'editar') {
      formData.append('_method', 'PUT');
    }

    let codigo = 0;


    this.cerrarVentana();

    this.apiServices.postFormData(formData, url)
      .subscribe(data => {
        console.log(data);
        this.alertMsg.showAlert = true;
        if (data.success === true) {
          this.alertMsg.class = 'alert alert-success';
          codigo = data.codigo;
          //  this.desBtnGuardar = true;
          this.submitted = false;
          this.incapacidadId = data.Incapacidad.id;

          if (this.tipoAccion === 'detalle') {
            this.tituloFormulario = 'Detalle de la incapacidad médica  ' + '. Código: ' + codigo;
            this.habilitarControles();
          } else if (this.tipoAccion === 'nuevo') {
            this.desBtnGuardar = true;
            this.deshabilitarControles();
          }

          this.alertMsg.msg = data.response + '. Código: ' + codigo;

          //Generar PDF
          this.codigoIncapacidad = codigo;
          this.verVentanas.verVentanaImprimir = true;

        } else {
          this.alertMsg.class = 'alert alert-danger';
          this.alertMsg.msg = data.response;
          this.desBtnGuardar = false;
        }



        //  debugger;
      }, dataError => {
        console.log(dataError);
        if (dataError.error.response !== undefined) {
          this.mostrarMsgError(dataError.error.response);
        } else {
          this.mostrarMsgError('Error al intentar procesar los datos enviados');
        }

        this.desBtnGuardar = false;
        const errors = GetMsgFromError(dataError);
        console.log(errors);

        this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
          errors.errors
        );
        this.cerrarVentana();

        console.log(this.serverErrorsInfo);
        this.desBtnGuardar = false;
        window.scroll(0, 0);
      }, () => {

        console.log('finally')
        window.scroll(0, 0);
      });
  }

  mostrarMsgError(msg: string) {
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  btnMostrarConfirmar_click() {
    this.verVentanas.verVentanaConfirmarGuardar = true;
    this.verVentanas.titVenCon = 'Confirmación de acción';
    this.verVentanas.tetVenCon = '¿Está seguro(s)?';
  }

  // btnConfimarGuardar_click($event) {
  //   if ($event === true) {
  //     this.enviarDatos(this.accionGuardarEditar);
  //   }

  //   this.verVentanas.verVentanaConfirmarGuardar = false;
  // }


  habilitarControles(omitir = []) {
    this.desBtnGuardar = false;
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).enable();
      }
    });

  }

  deshabilitarControles(omitir = []) {
    this.desBtnGuardar = true;
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).disable();
      }
    });
  }

  cerrarVentana() {
    this.verVentanas.verVentanaConfirmarGuardar = false;
    this.verVentanas.verVentanaConfirmarCredenciales = false;
    this.verVentanas.verVentanaCredenciales = false;
    this.verVentanas.verVentanaImprimir = false;
  }

  /* 
    btnAcciones_click($event) {
    console.log($event);
    switch ($event.item) {
      case 'habilitar_edicion':
        const omitir = [
          'txtDias'
        ];
        this.habilitarControles();
        this.desBtnGuardar = false;
        // this.alertMsg.showAlert = false;
        window.scroll(0, 0);
        // this.guardarComoNueva = true;
        break;
      case 'listado_pacientes':
        //this.router.navigate(['ListadoPacientes'], {});
        this.btnGotoUrl('ListadoPacientes');
      case 'historia_paciente':
        this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id });
        break;
      case 'nueva_incapacidad':
        this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id });
        break;
      case 'listado_incapacidades':
        this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id });
        break;
      case 'imprimir':
        break;
    }
  }

  */

  agregarValoresCie10() {
    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    const lengthRows = this.cie10Rows['controls'].length;
    const diagnosticos_cie10 = [];
    if (lengthRows > 0) {
      for (let i = 0; i < lengthRows; i++) {
        const cie10CodigoControl: any = this.cie10Rows.controls[i]['controls']['cie10Codigo'];
        if (cie10CodigoControl.value !== null && cie10CodigoControl.value.length > 0) {
          //  console.log({ codigo: cie10CodigoControl.value, titulo: cie10TituloControl.value });
          const codigo = { codigo: cie10CodigoControl.value };
          // console.log(codigo);
          diagnosticos_cie10.push(codigo);
        }
      }
    }

    return diagnosticos_cie10;

  }


  limpiarContoles(omitir = []) {

    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).reset();
      }
    });

    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    const arr = <FormArray>this.formData.controls.cie10Rows;
    arr.controls = [];
    this.cie10Rows.push(this.createCie10Row());


  }

  btnAcciones_click($event) {
    console.log($event);

    if ($event.item === 'listado_pacientes') {
      this.btnGotoUrl('ListadoPacientes');

    } else if ($event.item === 'historia_paciente') {
      this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id });

    } else if ($event.item === 'listado_incapacidades') {
      this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id });

    }
    else if ($event.item === 'detalle_cirugia') {
      console.log(this.urlOrigen);
      if (this.urlOrigen.tieneOrigen === true && this.urlOrigen.origen === 'Cirugias') {
        this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id, tipo: 'detalle', cirugiaId: this.urlOrigen.id });
      } else {
        alert('La incapacidad médica no proviene de una descripción quirúrgica');
      }
    }
    else if ($event.item === 'detalle_control') {
      if (this.urlOrigen.tieneOrigen === true && this.urlOrigen.origen === 'Controles') {
        this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id, tipo: 'detalle', controlId: this.urlOrigen.id });
      } else {
        alert('La incapacidad médica no proviene de un control');
      }
    }

    else if ($event.item === 'detalle_evolucion') {
      if (this.urlOrigen.tieneOrigen === true && this.urlOrigen.origen === 'Evoluciones') {
        this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id, tipo: 'detalle', evolucionId: this.urlOrigen.id });
      } else {
        alert('La incapacidad médica no proviene de una evolución');
      }
    }
    else if ($event.item === 'registro_vacio') {
      this.habilitarControles(['cboEstados']);
      this.codigoIncapacidad = 0; //Resetear el código de la formula
      this.tienePdf = false;
      this.limpiarContoles();
      this.submitted = false;
      this.saltarValidacion = false;
      this.desBtnGuardar = false;

      //  this.nuevoNuevo = true;
      this.tituloFormulario = 'Registrar nueva incapacidad';
      this.alertMsg.showAlert = false;
      this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id, tipo: 'nuevo' });

    } else if ($event.item === 'imprimir') {

      // if (this.tienePdf === true) {
      //  window.open(this.urlPdf);
      if (this.codigoIncapacidad > 0) {
        this.generarPdf();
      } else if (this.codigoIncapacidad === 0) {
        alert('Primero debe guardar');
      }

      // this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id });
    }

  }




  dataAccionesesGuardar() {
    return [{
      text: 'Volver al listado de pacientes', url: 'ListadoPacientes', item: 'listado_pacientes'
    }, {
      text: 'Volver a la historia clinica del paciente', url: 'HistoriaPaciente', item: 'historia_paciente'
    }, {
      text: 'Volver al historial de incapacidades', url: 'ListadoIncapacidades', item: 'listado_incapacidades'
    },
    {
      text: 'Nueva incapacidad vacía', item: 'registro_vacio', url: 'FormIncapacidades',
    }
      , {
      text: 'Volver a la descripción quirúrgica (si existe)', url: 'FormCirugias', item: 'detalle_cirugia'
    },
    {
      text: 'Volver al control (si existe)', url: 'FormControles', item: 'detalle_control'
    },
    {
      text: 'Volver a la evolución (si existe)', url: 'FormEvoluciones', item: 'detalle_evolucion'
    },
    {
      text: 'Imprimir', item: 'imprimir'
    }];
  }

  dataAccionesesEditar() {
    return [{
      text: 'Volver al listado de pacientes', url: 'ListadoPacientes', item: 'listado_pacientes'
    }, {
      text: 'Volver a la historia clinica del paciente', url: 'HistoriaPaciente', item: 'historia_paciente'
    }, {
      text: 'Volver al historial de incapacidades', url: 'ListadoIncapacidades', item: 'listado_incapacidades'
    },
    {
      text: 'Nueva incapacidad vacía', item: 'registro_vacio', url: 'FormIncapacidades',
    },
    {
      text: 'Imprimir', item: 'imprimir'
    }];
  }

}

