import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ServiciosService } from '../servicios/servicios.service';
import { EncryptionService } from '../servicios/encryption.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateGuardianGuard implements CanActivate {

  constructor(
    private router: Router,
    private encryption: EncryptionService,
    private apiServices: ServiciosService
  ) {
    window.scrollTo(0, 0);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let result = false;

    try {


      const url = next.routeConfig.path;


      let token = localStorage.getItem('JWT_TOKEN');

      // if (url !== '/' && token === null) {
      //   this.gotoUrl('/');
      // }

      if (token !== null) {

        // token = this.encryption.decryptUsingAES256(token);
        // console.log(token);
        const tokenPayload: any = this.apiServices.getUserInfoFromToken(token, true);
        console.log({ tokenPayload: tokenPayload });

        const PERMISOS = [
          { tipo: 'MEDICO', url: 'ListadoCitas' },
          { tipo: 'MEDICO', url: 'IniciarSesion' },
          { tipo: 'MEDICO', url: 'CerrarSesion' },
          { tipo: 'MEDICO', url: 'ListadoPacientes' },
          { tipo: 'MEDICO', url: 'ListadoIncapacidades' },
          { tipo: 'MEDICO', url: 'ListadoMedicos' },
          { tipo: 'MEDICO', url: 'HistoriaPaciente' },
          { tipo: 'MEDICO', url: 'FormCirugias' },
          { tipo: 'MEDICO', url: 'ListadoCirugias' },
          { tipo: 'MEDICO', url: 'FormFormulas' },
          { tipo: 'MEDICO', url: 'ListadoFormulas' },
          { tipo: 'MEDICO', url: 'FormEvoluciones' },
          { tipo: 'MEDICO', url: 'ListadoEvoluciones' },
          { tipo: 'MEDICO', url: 'FormControles' },
          { tipo: 'MEDICO', url: 'ListadoControles' },
          { tipo: 'MEDICO', url: 'FormIncapacidades' },
          { tipo: 'MEDICO', url: 'AgendaMedicos' },
          { tipo: 'MEDICO', url: '' },
          { tipo: 'MEDICO', url: 'AgregarUsuario' }, //OJO TEMPORAL
          { tipo: 'MEDICO', url: 'DetalleUsuario' }, //OJO TEMPORAL
          { tipo: 'MEDICO', url: 'ListadoUsuarios' }, //OJO TEMPORAL
          { tipo: 'MEDICO', url: 'DetallePaciente' }, //OJO TEMPORAL
          { tipo: 'MEDICO', url: 'DetalleMedico' }, //OJO TEMPORAL
          { tipo: 'OPERADOR', url: '' },
          { tipo: 'OPERADOR', url: 'DetallePaciente' },
          { tipo: 'OPERADOR', url: 'DetallePaciente' },
          { tipo: 'OPERADOR', url: 'AgregarCitas' },
          { tipo: 'OPERADOR', url: 'ListadoPacientes' },
          { tipo: 'OPERADOR', url: 'DetalleUsuario' },
          { tipo: 'OPERADOR', url: 'IniciarSesion' },
          { tipo: 'OPERADOR', url: 'CerrarSesion' },
          { tipo: 'OPERADOR', url: 'FormCitas' },
          { tipo: 'OPERADOR', url: 'ListadoCitas' },
          { tipo: 'OPERADOR', url: 'ListadoPacientesPotenciales' },
          { tipo: 'OPERADOR', url: 'AgregarPacientePotencial' },
          { tipo: 'OPERADOR', url: 'DetallePacientesPotenciales' },


        ]

        if (tokenPayload.userInfo !== undefined) {
          const userInfo = tokenPayload.userInfo;
          //  console.log(userInfo);
          if (tokenPayload.tipo === undefined) {
            alert('Error al intentar detemerinar el tipo de usuario');
            result = false;
            // localStorage.clear();
            // if (url !== '/') {
            //   window.document.location.reload();
            // }
          } else {
            const tipo = tokenPayload.tipo.toUpperCase();
            if (tipo === 'ADMIN') {
              result = true;
            } else {
              //    console.log(tipo);
              Object.keys(PERMISOS).forEach(index => {
                if (result === false) {
                  const item = PERMISOS[index];
                  // console.log(item);
                  if (item.tipo === tipo && url === item.url) {
                    //  console.log(item.tipo)
                    // console.log(tipo)
                    // console.log(item.url)
                    // console.log(url)
                    result = true;
                  }
                }
              });
            }
          }
          //  console.log(userInfo);
        }
      }

    } catch (e) {
      // console.log(e);
      console.log('canActivate error: ' + e.message);
    }
    // console.log(typeof tokenPayload);
    // console.log(tokenPayload);

    return result;
  }

  gotoUrl(url: string) {
    this.router.navigate([url]);
    return false;
  }

}
