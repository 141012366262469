import { Component, OnInit, AfterContentInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {
  MaxLength
} from 'src/app/validators/CustomValidators';
import { ServiciosService } from '../../servicios/servicios.service';
import { GetMsgFromError } from 'src/app/functions/SharedFunctions';
import { serverErrorsInfo } from 'src/app/utilities/serverErrorsInfo';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-form-cirugias',
  templateUrl: './form-cirugias.component.html',
  styleUrls: ['./form-cirugias.component.css']
})
export class FormCirugiasComponent implements OnInit, AfterContentInit {
  @ViewChild('chkHabDesEdicion', { static: false }) chkHabDesEdicion: ElementRef<HTMLInputElement>;

  cirugiaId = 0;
  pacienteId = 0;
  formData: FormGroup;
  submitted = false;
  serverErrorsInfo: any = {};
  // public format: string = 'HH:mm';
  public format: string = 'yyyy-MM-dd HH:mm';


  tipoUsuario = null;
  userInfo: any;
  saltarValidacion = false;
  medicoId = 0;
  cargandoDatos = true;
  esAlergico = false;
  codigoCirugia = 0;
  registroEncontrado = false;
  nuevoRegistro = false;
  tituloFormulario = '';
  guardarClausurarEditar = '';
  tituloBotonCredenciales = '';
  tituloVentanaCredenciales = '';
  msgNotificacion = '';
  tipoAccion = '';
  errorDeCarga = false;
  clausurada = false;
  medicoIdReg = 0;
  archivoConsentimiento = undefined;
  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  };

  registroGuardadoExiste = false;

  verVentanas = {
    verVenConBorArchivo: false,
    titVenConBorArchivo: '',
    tetVenConBorArchivo: '',
    verVenConBorFoto: false,
    titVenConBorFoto: '',
    tetVenConBorFoto: '',
    adjuntarFotos: false,
    verFotos: false,
    verArchivos: false,
    tituloVentanaTexto: '',
    ventanaPlaceHolder: '',
    mostrarRegistrarNota: false,
    mostrarNumerosGineco: false,
    mostrarCalendario: false,
    mostrarBuscarCie10: false,
    mostrarVentanaLogin: false,
    adjuntarArchivos: false,
    verVentanaConfirmarGuardar: false,
    titVenCon: '',
    tetVenCon: '',
    accion: '',
    verVentanaCredenciales: false,
    verVentanaConfirmarCredenciales: false,
    titentanaConfirmarCredenciales: '',
    tetVentanaConfirmarCredenciales: ''
  }

  baseUrl2 = environment.baseUrl2;
  urlConsentimiento = '';

  dataPaciente: any;
  datosPacienteCargados = false;
  desBtnGuardar = false;
  accionEjecutandose = false;



  // ngModelTxtCiuResPaciente = '';

  // dataAcciones: Array<any> = [{
  //   text: 'Volver al listado de pacientes', item: 'listado_pacientes'
  // }, {
  //   text: 'Nueva descripción', item: 'nuevo_registro'
  // }, {
  //   text: 'Imprimir', item: 'imprimir'
  // }];

  dataAcciones: any;

  public myRestrictions: FileRestrictions = {
    allowedExtensions: ['.doc', '.docx', '.pdf'],
    maxFileSize: 2097152
  };



  btnGotoUrl(url: string, params = null) {
    // this.verVentanaDetallePaciente = true;
    console.log(url);
    if (params === null) {
      this.router.navigate([url]);
    } else {
      const queryParams = params;
      this.router.navigate([url], { queryParams });
    }

    return false;
  }



  limpiarContoles() {

    Object.keys(this.formData.controls).forEach(element => {
      this.formData.get(element).reset();
    });
  }
  constructor(private router: Router,
    private apiServices: ServiciosService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute) {

  }


  getData(cirugiaId: number) {
    // console.log(itemId);
    console.log({ cirugiaId: cirugiaId })
    if (cirugiaId !== undefined && Number(cirugiaId) > 0) {
      let urlParams = 'cirugias/' + cirugiaId + '?medid=all';
      // urlParams += 'id/' + cirugiaId;
      //   urlParams += '?espid=all';
      // let item: Item;
      this.apiServices.getData(urlParams, 'cirugía', false).subscribe(
        data => {
          // console.log({ registroEncontrado: this.registroEncontrado })
          console.log(data);
          if (data !== undefined && data.success === true && data['Cirugia'] !== undefined) {
            const item = data['Cirugia'];
            console.log(data);
            // console.log({registroEncontrado: item.paciente_id})
            this.registroEncontrado = true;
            //  this.medicoId = item.Medico.id;
            this.medicoIdReg = item.Medico.id;

            this.txtHoraInicio.patchValue(new Date(item.hora_inicio));
            this.txtHoraFin.patchValue(new Date(item.hora_fin));
            this.txtTiempo.patchValue(item.tiempo);
            this.cirugiaId = item.id;

            if (item.anestesiologo == 'null') {
              this.txtAnestesiologo.patchValue('');
            } else {
              this.txtAnestesiologo.patchValue(item.anestesiologo);
            }

            if (item.tipo_anestesia == 'null') {
              this.txtTipoAnestesia.patchValue('');
            } else {
              this.txtTipoAnestesia.patchValue(item.tipo_anestesia);
            }

            if (item.ayudante == 'null') {
              this.txtAyudante.patchValue('');
            } else {
              this.txtAyudante.patchValue(item.ayudante);
            }

            if (item.antibiotico == 'null') {
              this.txtAntibiotico.patchValue('');
            } else {
              this.txtAntibiotico.patchValue(item.antibiotico);
            }

            if (item.complicaciones == 'null') {
              this.txtComplicaciones.patchValue('');
            } else {
              this.txtComplicaciones.patchValue(item.complicaciones);
            }

            if (item.procedimiento == 'null') {
              this.txtProcedimiento.patchValue('');
            } else {
              this.txtProcedimiento.patchValue(item.procedimiento);
            }

            if (item.hallazgos == 'null') {
              this.txtHallazgos.patchValue('');
            } else {
              this.txtHallazgos.patchValue(item.hallazgos);
            }

            if (item.descripcion == 'null') {
              this.txtDescripcion.patchValue('');
            } else {
              this.txtDescripcion.patchValue(item.descripcion);
            }

            if (item.conducta == 'null') {
              this.txtConducta.patchValue('');
            } else {
              this.txtConducta.patchValue(item.conducta);
            }

            if (item.plan == 'null') {
              this.txtPlan.patchValue('');
            } else {
              this.txtPlan.patchValue(item.plan);
            }

            if (item.notas == 'null') {
              this.txtNotas.patchValue('');
            } else {
              this.txtNotas.patchValue(item.notas);
            }


            //  this.txtAyudante.patchValue(item.ayudante);
            // this.txtIdeAyudante.patchValue(item.ayudante_ide);

            // this.txtAntibiotico.patchValue(item.antibiotico);
            this.txtSangrado.patchValue(item.sangrado);
            //   this.txtComplicaciones.patchValue(item.complicaciones);
            // this.txtProcedimiento.patchValue(item.procedimiento);
            // this.txtHallazgos.patchValue(item.hallazgos);
            //this.txtDescripcion.patchValue(item.descripcion);
            //  this.txtConducta.patchValue(item.conducta);
            // this.txtPlan.patchValue(item.plan);
            //this.txtNotas.patchValue(item.notas);
            this.tituloFormulario = 'Detalles de la descripción quirúrgica. Código: ' + item.codigo;
            this.codigoCirugia = item.codigo;
            this.registroGuardadoExiste = true;

            if (Number(item.clausurada) === 1) {
              this.clausurada = true;
              this.deshabilitarControles();
              this.desBtnGuardar = true;
            } else if (this.tipoUsuario === 'ADMIN') {
              this.habilitarControles(['txtTiempo']);
              this.desBtnGuardar = false;
            } else if (!this.medicoActualHizoIngreso(this.medicoId, this.medicoIdReg, this.tipoUsuario)) {
              this.deshabilitarControles();
              this.desBtnGuardar = true;
            }


            this.getPaciente(item.paciente_id);

          } else {
            this.cargandoDatos = false;
            console.log('Error: data.Cirugia null o undefined');
            const msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe.';
            this.mostrarMsgError(msg);
          }

          if (data.consentimiento !== undefined && data.consentimiento !== null) {
            this.urlConsentimiento = this.baseUrl2 + data.consentimiento;
          }

          console.log(this.urlConsentimiento);
        },
        error => {
          console.log(error);
          const errors = GetMsgFromError(error);
          const msg = '' + errors.msg;
          // this.mostrarMsgError(msg);
          this.cargandoDatos = false;
          this.registroEncontrado = false;
          this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(errors.errors);
          console.log(this.serverErrorsInfo);
          if (errors.msg !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError('Error al intentar cargar los datos de la descripción quirúrgica.');
          }
          // this.spinners.mostrarSpinner = false;
        },
        () => {
          console.log('Finally');
          //  this.loadedData = true;
          // this.spinners.mostrarSpinner = false;
        }
      );
    } else {
      //  this.loadingData = false;
      this.cargandoDatos = false;
      this.registroEncontrado = false;
      console.log('Error: cirugiaId igual a cero o undefined');
      const msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe.';
      this.mostrarMsgError(msg);
    }
  }

  medicoActualHizoIngreso(idMedicoLogin: Number, idMedicoHistoria: Number, tipoUsuario: string) {
    console.log({ idMedicoHistoria: idMedicoHistoria });
    console.log({ idMedicoLogin: idMedicoLogin });

    if (tipoUsuario === 'MEDICO' && (idMedicoHistoria === idMedicoLogin)) {
      return true; //Si es el propietario
    }
    return false;
  }

  ngOnInit(): void {

    this.tipoUsuario = this.apiServices.getTipoUsuario();
    const userInfo = this.apiServices.getUserInfoFromToken();
    this.userInfo = userInfo.userInfo;
    if (this.tipoUsuario === 'MEDICO') {
      this.medicoId = userInfo.userInfo.id;
    }


    this.formData = this.formBuilder.group({

      txtHoraInicio: ['', Validators.required],
      txtHoraFin: ['', Validators.required],
      txtTiempo: [{ value: '', disabled: true }],
      txtConsentimiento: ['', [Validators.required]],

      txtAnestesiologo: ['', [Validators.required, MaxLength(false, 250)]],
      // txtIdeAnestesiologo: ['', [Validators.required, MaxLength(false, 44), OnlyIntegerNumbers()]],
      txtTipoAnestesia: ['', [Validators.required, MaxLength(false, 6000)]],
      txtAyudante: ['', [Validators.required, MaxLength(false, 250)]],
      // txtIdeAyudante: ['', [Validators.required, MaxLength(false, 44), OnlyIntegerNumbers()]],
      txtAntibiotico: ['', [Validators.required, MaxLength(false, 6000)]],
      txtSangrado: ['', [Validators.required, MaxLength(false, 8)]],

      txtComplicaciones: ['', [Validators.required, MaxLength(false, 6000)]],
      txtProcedimiento: ['', [Validators.required, MaxLength(false, 6000)]],
      txtHallazgos: ['', [Validators.required, MaxLength(false, 6000)]],

      txtDescripcion: ['', [Validators.required, MaxLength(false, 6000)]],
      txtConducta: ['', [Validators.required, MaxLength(false, 6000)]],
      txtPlan: ['', [Validators.required, MaxLength(false, 6000)]],
      txtNotas: ['', [MaxLength(true, 6000)]],


    });

    //public max: Date = new Date(2000, 11, 31);


    let today = new Date();
    this.formData.get('txtHoraInicio').setValue(today);
    this.formData.get('txtHoraFin').setValue(today);

    let hora1 = this.formData.get('txtHoraInicio').value;
    let hora2 = this.formData.get('txtHoraFin').value;

    setTimeout(() => {
      this.calcularTiempo(hora1, hora2);
    }, 200);

    window.scrollTo(0, 0);


  }


  isInt(value) {
    return Number.isInteger(Number(value));
  }

  btnAbrirConsentimiento_click() {
    // console.log(dataItem);
    if (this.urlConsentimiento.length > 0) {
      const url = this.urlConsentimiento;
      window.open(url, '_blank');
    }
  }

  ngAfterContentInit(): void {
    //    window.scroll(0,0);
    // console.log('Entro aquí otras vez...');

    this.deshabilitarValidaciones();

    this.activatedRoute.queryParams.subscribe(params => {
      const tipo = params.tipo;
      const cirugiaId = params.cirugiaId;
      const pacienteId = params.pacienteId;
      console.log({ paciente_id: this.isInt(pacienteId) });
      this.registroEncontrado = false;

      this.tipoAccion = tipo;
      this.pacienteId = pacienteId;
      this.cirugiaId = cirugiaId;
      console.log({ tipoRecibido: tipo });
      //this.tipoAccion = params.tipo;

      if (tipo === 'nuevo') {
        // this.tipoAccion = tipo;
        //this.limpiarContoles();
        this.nuevoRegistro = true;
        this.tituloFormulario = 'Nueva descripción quirúrgica';
        this.dataAcciones = this.dataAccionesesGuardar();
        this.clausurada = false;
        this.codigoCirugia = 0;


      } else if (tipo === 'detalle') {
        //  this.tipoAccion = tipo;

        this.nuevoRegistro = false;
        this.dataAcciones = this.dataAccionesesEditar();
      } else {
        this.errorDeCarga = true;
        //   this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar determinar el tipo de acción a realizar.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      }

      if (pacienteId === undefined && cirugiaId === undefined) {
        this.errorDeCarga = true;
        //   this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      } else if (this.isInt(pacienteId) === false && tipo == 'nuevo') {
        this.errorDeCarga = true;
        console.log({ tipoRecibido2: tipo });
        //  this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      } else if (this.isInt(cirugiaId) === false && tipo == 'detalle') {
        this.errorDeCarga = true;
        //  this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar cargar los datos. El ID de la Formula es incorrecto o no existe.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      }

      if (this.nuevoRegistro === false && this.errorDeCarga === false) {
        this.cargandoDatos = true;
        this.getData(this.cirugiaId);
        //  this.tipoUsuario = this.apiServices.getTipoUsuario();
        //  console.log({ tipoUsuario: this.apiServices.getTipoUsuario() });
      } else if (this.nuevoRegistro === true && this.errorDeCarga === false) {
        this.getPaciente(this.pacienteId);
      }

      console.log({ nuevoRegistro: this.nuevoRegistro });
    });



    // this.formData.get('txtTiempo').disable();

  }
  get txtHoraInicio() {
    return this.formData.get('txtHoraInicio');
  }
  get txtHoraFin() {
    return this.formData.get('txtHoraFin');
  }
  get txtTiempo() {
    return this.formData.get('txtTiempo');
  }
  get txtAnestesiologo() {
    return this.formData.get('txtAnestesiologo');
  }
  // get txtIdeAnestesiologo() {
  //   return this.formData.get('txtIdeAnestesiologo');
  // }
  get txtTipoAnestesia() {
    return this.formData.get('txtTipoAnestesia');
  }
  get txtAyudante() {
    return this.formData.get('txtAyudante');
  }
  // get txtIdeAyudante() {
  //   return this.formData.get('txtIdeAyudante');
  // }
  get txtConsentimiento() {
    return this.formData.get('txtConsentimiento');
  }

  get txtAntibiotico() {
    return this.formData.get('txtAntibiotico');
  }

  get txtSangrado() {
    return this.formData.get('txtSangrado');
  }
  get txtComplicaciones() {
    return this.formData.get('txtComplicaciones');
  }

  get txtProcedimiento() {
    return this.formData.get('txtProcedimiento');
  }
  get txtHallazgos() {
    return this.formData.get('txtHallazgos');
  }
  get txtDescripcion() {
    return this.formData.get('txtDescripcion');
  }
  get txtConducta() {
    return this.formData.get('txtConducta');
  }
  get txtPlan() {
    return this.formData.get('txtPlan');
  }

  get txtNotas() {
    return this.formData.get('txtNotas');
  }

  txtHoraInicio_change($event) {
    console.log($event);
  }


  getPaciente(id: number) {
    console.log({ datosPaciente: id });
    if (id > 0) {
      let urlParams = 'pacientes/' + id;
      // urlParams += 'id/' + cirugiaId;
      urlParams += '?grpsa=all&estciv=all';
      urlParams += '&genero=all&ocup=all&ciures=all&depres=all&countryid=all';

      console.log({ idPaciente: id });

      this.apiServices.getData(urlParams, 'Paciente', false)
        .subscribe(data => {
          console.log(data);
          if (data.success === true) {
            this.dataPaciente = data['Paciente'];
            this.datosPacienteCargados = true;
            this.registroEncontrado = true;
            this.pacienteId = this.dataPaciente.id;

            if (this.dataPaciente.alergico.trim() === 'Si') {
              this.esAlergico = true;
            } else {
              this.esAlergico = false;
            }
          } else {
            this.registroEncontrado = false;
            console.log({ Error: 'Paciente null o undefined' });
            let msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe. ';
            this.mostrarMsgError(msg);
          }

          this.cargandoDatos = false;


        }, error => {
          this.cargandoDatos = false;
          this.registroEncontrado = false;
          console.log(error);

          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError('Error al intentar cargar los datos.');
          }


        }, () => {
          console.log('Finally');
        });
    } else {
      //  this.cargandoDatos = false;
      this.registroEncontrado = false;
      this.cargandoDatos = false;
      let msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe. 45 ';
      //   msg += 'Debe verificar que el formulario no contenga errores';
      this.mostrarMsgError(msg);
    }
  }



  //****************** GUARDAR ******************************************** */

  deshabilitarValidaciones(omitir = []) {

    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        //  console.log({ desha: element });
        this.formData.get(element).clearValidators();
      } else {
        console.log({ habi: element });
        this.formData.get(element).setValidators([Validators.required]);
      }
      this.formData.get(element).updateValueAndValidity();
    });
  }

  habilitarControles(omitir = []) {
    this.desBtnGuardar = false;
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).enable();
      }
    });

  }

  deshabilitarControles(omitir = []) {
    this.desBtnGuardar = true;
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).disable();
      }
    });
  }


  habilitarValidaciones() {

    this.formData.get('txtHoraInicio').setValidators([Validators.required]);
    this.formData.get('txtHoraInicio').updateValueAndValidity();

    this.formData.get('txtHoraFin').setValidators([Validators.required]);
    this.formData.get('txtHoraFin').updateValueAndValidity();

    this.formData.get('txtAnestesiologo').setValidators([Validators.required, MaxLength(false, 250)]);
    this.formData.get('txtAnestesiologo').updateValueAndValidity();

    this.formData.get('txtTipoAnestesia').setValidators([Validators.required, MaxLength(false, 6000)]);
    this.formData.get('txtTipoAnestesia').updateValueAndValidity();

    this.formData.get('txtAyudante').setValidators([Validators.required, MaxLength(false, 250)]);
    this.formData.get('txtAyudante').updateValueAndValidity();

    this.formData.get('txtAntibiotico').setValidators([Validators.required, MaxLength(false, 6000)]);
    this.formData.get('txtAntibiotico').updateValueAndValidity();

    this.formData.get('txtSangrado').setValidators([Validators.required, MaxLength(false, 8)]);
    this.formData.get('txtSangrado').updateValueAndValidity();

    this.formData.get('txtComplicaciones').setValidators([Validators.required, MaxLength(false, 6000)]);
    this.formData.get('txtComplicaciones').updateValueAndValidity();

    this.formData.get('txtProcedimiento').setValidators([Validators.required, MaxLength(false, 6000)]);
    this.formData.get('txtProcedimiento').updateValueAndValidity();

    this.formData.get('txtHallazgos').setValidators([Validators.required, MaxLength(false, 6000)]);
    this.formData.get('txtHallazgos').updateValueAndValidity();

    this.formData.get('txtDescripcion').setValidators([Validators.required, MaxLength(false, 6000)]);
    this.formData.get('txtDescripcion').updateValueAndValidity();

    this.formData.get('txtConducta').setValidators([Validators.required, MaxLength(false, 6000)]);
    this.formData.get('txtConducta').updateValueAndValidity();

    this.formData.get('txtPlan').setValidators([Validators.required, MaxLength(false, 6000)]);
    this.formData.get('txtPlan').updateValueAndValidity();

    this.formData.get('txtNotas').setValidators([MaxLength(true, 6000)]);
    this.formData.get('txtNotas').updateValueAndValidity();

  }

  // 1 - Guardar
  btnMostrarConfirmarGuardar_click() {
    this.deshabilitarValidaciones();
    //  console.log({guardarClausurarEditar: guardarClausurarEditar});
    //Es un nuevo registro
    this.guardarClausurarEditar = 'guardar';

    if (this.tipoUsuario === 'MEDICO') {
      this.medicoId = this.userInfo.id;
    }

    //this.tipoAccion = 'nuevo';
    //Para determinar si es clausurar o guardar
    // this.guardarClausurarEditar = guardarClausurarEditar;

    this.verVentanas.verVentanaConfirmarGuardar = true;
    this.verVentanas.titVenCon = 'Confirmación de acción';
    this.verVentanas.tetVenCon = '¿Está seguro(s)?';
  }
  //1.1 

  btnMostrarConfirmarClausurar_click() {


    if (this.tipoUsuario === 'MEDICO') {
      this.medicoId = this.userInfo.id;
    }
    //    console.log({guardarClausurarEditar: guardarClausurarEditar});
    //Es un nuevo registro
    // this.tipoAccion = 'clausurar'; //Guardar
    //Para determinar si es clausurar o guardar
    this.guardarClausurarEditar = 'clausurar';
    this.saltarValidacion = false;
    this.verVentanas.verVentanaConfirmarGuardar = true;
    this.verVentanas.titVenCon = 'Confirmación de acción';
    this.verVentanas.tetVenCon = '¿Está seguro(s)?';
  }

  // // 1.2
  // btnMostrarConfirmarEditar_click() {

  //   this.cerrarVentana();
  //   this.alertMsg.showAlert = false;
  //   this.deshabilitarValidaciones();

  //   // this.tipoAccion = 'editar';
  //   this.guardarClausurarEditar = 'editar';
  //   this.verVentanas.verVentanaConfirmarGuardar = true;
  //   this.verVentanas.titVenCon = 'Confirmación de acción';
  //   this.verVentanas.tetVenCon = '¿Está seguro(s)?';
  // }

  // 2
  btnConfimarGuardar_click($event) {

    console.log($event);
    this.cerrarVentana();
    this.alertMsg.showAlert = false;

    if ($event === false) {
      return false;
    }

    if (this.guardarClausurarEditar === 'clausurar') {
      this.habilitarValidaciones();
    }

    let msg = '';
    if (this.guardarClausurarEditar === 'guardar' && this.tipoUsuario === 'MEDICO') {
      this.saltarValidacion = true;
    }

    console.log(this.formData);

    this.submitted = true;
    if (this.formData.invalid) {
      this.msgNotificacion = 'Error al intentar enviar el formulario. ';
      this.msgNotificacion += 'Verifique que el formulario no contenga errorese intente de nuevo';
      this.mostrarMsgError(this.msgNotificacion);
      window.scroll(0, 0);
      return false;
    }


    console.log(this.formData);
    console.log(this.tipoUsuario);
    console.log(this.guardarClausurarEditar);
    console.log(this.saltarValidacion);

    // this.submitted = true;
    // if (this.formData.invalid) {
    //   this.msgNotificacion = 'Error al intentar ' + this.msgNotificacion + ' la descripción quirúrgica. ';
    //   this.msgNotificacion += 'Verifique que el formulario no contenga errorese intente de nuevo.';
    //   this.mostrarMsgError(this.msgNotificacion);
    //   window.scroll(0, 0);
    //   return false;
    // }


    if (this.guardarClausurarEditar === 'guardar' && this.tipoUsuario == 'ADMIN') {
      //  let msg = 'Se ha detectado que ';
      msg = 'Se ha detectado que ';
      msg += 'su rol no es Médico. Para poder guardar <br />';
      msg += 'primero debe ingresar las credenciales del médico que será asociado a los cambios realizados. <br />';
      msg += 'Presione el botón Si para continuar.';
      this.msgNotificacion = 'guardar los cambios en el';
      this.tituloBotonCredenciales = 'Guardar cambios';

    } else if (this.guardarClausurarEditar === 'clausurar' && this.tipoUsuario == 'ADMIN') {
      msg = 'Se ha detectado que ';
      msg += 'su rol no es Médico. Para poder clausurar <br />';
      msg += 'primero debe ingresar las credenciales del médico que será asociado al cierre de la descripción quirúrgica. <br />';
      msg += 'Presione el botón Si para continuar.';
      this.tituloVentanaCredenciales = 'Clausurar descripción quirúrgica';
      this.tituloBotonCredenciales = 'Clausurar';
      this.msgNotificacion = 'clausurar';

    } else if (this.guardarClausurarEditar === 'clausurar' && this.tipoUsuario === 'MEDICO') {
      msg = '';
      msg += 'Para poder clausurar <br />';
      msg += 'primero debe ingresar sus credenciales (usuario y contraseña). <br />';
      msg += 'Presione el botón Si para continuar.';
      this.tituloVentanaCredenciales = 'Clausurar descripción quirúrgica';
      this.tituloBotonCredenciales = 'Clausurar';
      this.msgNotificacion = 'clausurar';
    }


    /****** FIN VALIDAR FORMULARIO */

    if ((this.tipoUsuario === 'MEDICO' || this.tipoUsuario === 'ADMIN') && this.saltarValidacion === false) {
      this.verVentanas.verVentanaConfirmarCredenciales = true;
      this.verVentanas.titentanaConfirmarCredenciales = 'Notificación';
      this.verVentanas.tetVentanaConfirmarCredenciales = msg;
      // } else if ($event === true && this.tipoUsuario === 'MEDICO' && this.saltarValidacion === false) { 
      //   this.verVentanas.verVentanaConfirmarCredenciales = true;
      //   this.verVentanas.titentanaConfirmarCredenciales = 'Notificación';
      //   this.verVentanas.tetVentanaConfirmarCredenciales = msg;
    } else if ($event === true) {
      this.enviarDatos();
    }


  }

  // 3
  btnConfirmarCredenciales_click($event) {
    // console.log($event);
    if ($event === true) {
      this.cerrarVentana();
      this.verVentanas.verVentanaCredenciales = true;
    } else {
      this.cerrarVentana();
    }
  }

  // 4
  btnLogin_click($event) {
    console.log($event);
    console.log('Ingreso a confirmar su login....');
    if ($event !== null) {
      this.cerrarVentana();
      this.medicoId = $event.id;
      console.log('Id confirmación login ' + this.medicoId);
      this.saltarValidacion = true;
      this.enviarDatos();
    }
  }


  cerrarVentana() {
    this.verVentanas.verVentanaConfirmarGuardar = false;
    this.verVentanas.verVentanaConfirmarCredenciales = false;
    this.verVentanas.verVentanaCredenciales = false;
    // this.verVentanas.verVentanaCredenciales = false;
  }

  enviarDatos() {
    this.submitted = true;

    console.log(this.formData);

    const formData = new FormData();
    formData.append('paciente_id', this.dataPaciente.id)
    formData.append('medico_id', '' + this.medicoId)
    formData.append('hallazgos', this.formData.get('txtHallazgos').value);
    formData.append('descripcion', this.formData.get('txtDescripcion').value);
    formData.append('conducta', this.formData.get('txtConducta').value);
    formData.append('plan', this.formData.get('txtPlan').value);
    formData.append('complicaciones', this.formData.get('txtComplicaciones').value);
    // formData.append('ayudante_ide', this.formData.get('txtIdeAyudante').value);
    //  formData.append('anestesiologo_ide', this.formData.get('txtIdeAnestesiologo').value);
    formData.append('procedimiento', this.formData.get('txtProcedimiento').value);
    formData.append('sangrado', this.formData.get('txtProcedimiento').value);

    if (this.archivoConsentimiento === undefined) {
      formData.append('consentimiento', '');
    } else {
      formData.append('consentimiento', this.archivoConsentimiento);
    }

    let horaInicio = '';
    if (this.formData.get('txtHoraInicio').value !== null && this.formData.get('txtHoraInicio').value.toString().length > 0) {
      horaInicio = this.formData.get('txtHoraInicio').value.toISOString();
    }

    formData.append('hora_inicio', horaInicio);

    let horaFin = '';
    if (this.formData.get('txtHoraFin').value !== null && this.formData.get('txtHoraFin').value.toString().length > 0) {
      horaFin = this.formData.get('txtHoraFin').value.toISOString();
    }

    formData.append('hora_fin', horaFin);
    // formData.append('hora_fin', horaFin);
    formData.append('sangrado', this.formData.get('txtSangrado').value);
    // formData.append('sangrado', this.formData.get('txtSangrado').value);
    formData.append('tipo_anestesia', this.formData.get('txtTipoAnestesia').value);
    formData.append('anestesiologo', this.formData.get('txtAnestesiologo').value);
    formData.append('ayudante', this.formData.get('txtAyudante').value);
    formData.append('antibiotico', this.formData.get('txtAntibiotico').value);


    formData.append('codigo_descripcion', '' + this.codigoCirugia);


    if (this.guardarClausurarEditar === 'editar') {
      formData.append('_method', 'PUT');
    }

    let url = 'cirugias';
    console.log(this.guardarClausurarEditar);
    //   if (this.tipoAccion === 'nuevo') {
    if (this.guardarClausurarEditar === 'clausurar') {
      formData.append('accion', 'clausurar');
    } else {
      formData.append('accion', 'guardar');
    }


    this.apiServices.postJsonData(url, formData)
      .subscribe(data => {
        console.log(data);
        // let codigo = 0;
        this.alertMsg.showAlert = true;
        if (data !== null && data.success === true) {

          this.alertMsg.class = 'alert alert-success';
          const codigo = data.codigo;
          this.alertMsg.msg = data.response + ', Código: ' + codigo;
          this.cirugiaId = data.id;
          this.submitted = false;
          this.codigoCirugia = data.codigo;
          this.registroGuardadoExiste = true;

          if (this.guardarClausurarEditar === 'clausurar') {
            this.clausurada = true;
            this.deshabilitarControles();
          } else {
            this.clausurada = false;
            this.habilitarControles(['txtTiempo']);
          }

          if (this.guardarClausurarEditar === 'nuevo') {
            this.tituloFormulario = 'Detalles de la descripción quirúrgica. Código: ' + codigo;
          }

        } else if (data == null) {

          this.mostrarMsgError('Error al intentar procesar los datos enviados.');
        } else {
          this.mostrarMsgExito(data.response);
        }


        window.scroll(0, 0);
        this.cerrarVentana();

      }, dataError => {
        console.log(dataError);
        if (dataError.error.response !== undefined) {
          this.mostrarMsgError(dataError.error.response);
        } else {
          let msg = 'Error al intentar procesar los datos enviados. ';
          msg += 'Debe verificar que el formulario no contenga errores';
          this.mostrarMsgError(msg);
        }


        const errors = GetMsgFromError(dataError);
        console.log(errors);

        this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
          errors.errors
        );

        this.cerrarVentana();

        this.saltarValidacion = false;

        console.log(this.serverErrorsInfo);

        window.scroll(0, 0);

      }, () => {

        console.log('finally')
        window.scroll(0, 0);
      });
  }


  seleccionArchivoParaClinico(archivo) {
    //  console.log(archivo)
    if (archivo.files) {
      this.archivoConsentimiento = archivo.files[0].rawFile;
    }
    //  this.alertMsgArchivo.showAlert = false;
    // this.alertMsgArchivo.msg = '';
  }


  removerArchivo($event) {
    this.archivoConsentimiento = undefined;
    // const file = (event.target as HTMLInputElement).files[0];
    // console.log(file);

  }

  // ***************************************************************************************************
  mostrarMsgError(msg: string) {
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  habilitarcirugias(omitir = []) {
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).enable();
      }
    });

  }

  deshabilitarcirugias(omitir = []) {
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).disable();
      }
    });
  }


  //Calcular tiempo de la cirugía
  calcularTiempo_change($event) {

    console.log($event);

    setTimeout(() => {

      let hora1 = this.formData.get('txtHoraInicio').value;
      let hora2 = this.formData.get('txtHoraFin').value;
      this.calcularTiempo(hora1, hora2);

      //  ;
    }, 200);
  }

  calcularTiempo(hora1, hora2) {
    // let hora1 = this.formData.get('txtHoraInicio').value;
    // let hora2 = this.formData.get('txtHoraFin').value;
    console.log({ hora1: hora1 });
    console.log({ hora2: hora2 });
    //const tiempo = this.formData.get('txtTiempo').value;

    if (hora1 === null || hora2 === null ||
      hora1 === '' || hora2 === '') {
      this.formData.get('txtTiempo').reset();
      return false;
    }


    let timeStart = moment(hora1);
    let timeEnd = moment(hora2);

    //var now = moment(new Date()); //todays date
    //  var end = moment("2015-12-1"); // another date
    // var duration = moment.duration(timeStart.diff(timeEnd));
    let dias = timeEnd.diff(timeStart, 'days');
    let horas = timeEnd.diff(timeStart, 'hours');
    let minutos = timeEnd.diff(timeStart, 'minutes');
    // var minutos = duration.asHours();
    //var segundos = duration.asMinutes();
    console.log(dias)
    console.log(horas)
    console.log(minutos)
    //  console.log(minutos)
    //    console.log(segundos)



    let h1 = moment(timeStart);
    console.log(h1.minutes());
    let h2 = moment(timeEnd);
    console.log(h2.minutes());
    const h1Mins = Number(h1.minutes());
    const h2Mins = Number(h2.minutes());
   // let minutosCalculo = (60 - h2Mins) - h1Mins;

   let minutosCalculo = (60 - h1Mins) +  h2Mins;

    //Funciona para comparar si dos fechas incluido horas y minutos son iguales, devulve false si no son iguales y true si son iguales
    var isAfter = moment(timeStart).isAfter(timeEnd);
    console.log(isAfter);
    
    //Comparación incluida fecha y hora
    const resComparacion = this.compare_dates(new Date(hora1), new Date(hora2));
    console.log(resComparacion);

    if (resComparacion === 3) { //Si las fechas son exactamente iguales (incluido horas y minutos)
      minutosCalculo = 0;
      console.log('Both date are same');
    }

    //Comparación solo fecha
    const newDate1 = new Date(new Date(hora1).toDateString());
    const newDate2 = new Date(new Date(hora2).toDateString());
    const resComparacion1 = this.compare_dates(newDate1, newDate2);
    if(resComparacion1 === 3) { //Si la fecha es la misma (solo compara la fecha)
      minutosCalculo =  h2Mins - h1Mins;
    }
    console.log(resComparacion1);

    if(minutosCalculo === 60) {
      minutosCalculo = 0;
      horas++;
    
    } else if(minutosCalculo > 60) {
      minutosCalculo = minutosCalculo - 60;
      //horas++;
    }

    this.formData.get('txtTiempo').setValue(horas + ' hora(s) : ' + minutosCalculo + ' minuto(s)');
  }

  compare_dates(date1, date2) {
    if (date1 > date2) return 1; //date1 es mayor que dato 2
    else if (date1 < date2) return 2; //date2 es mayor que dato 1
    else return 3; //Son iguales
  }

   getDuration (d1, d2) {
    let d3 = new Date(d2 - d1);
    let d0 = new Date(0);

    return {
        getHours: function(){
            return d3.getHours() - d0.getHours();
        },
        getMinutes: function(){
            return d3.getMinutes() - d0.getMinutes();
        },
        getMilliseconds: function() {
            return d3.getMinutes() - d0.getMilliseconds();
        },
        toString: function(){
            return this.getHours() + ":" +
                   this.getMinutes() + ":" + 
                   this.getMilliseconds();
        },
    };
}


  generarPdf() {
    this.accionEjecutandose = true;
    let url = 'reportes/generarpdf?origen=cirugia';
    url += '&codigo=' + this.codigoCirugia+'&pacienteid='+this.pacienteId
    this.apiServices.getData(url, 'Reporte', false)
      .subscribe(
        data => {
          console.log(data);

          if (data !== null && data.success !== undefined && data.success === true) {
            //  this.tienePdf = true;
            // this.urlPdf = this.baseUrl2 + data.path;
            this.alertMsg.showAlert = false;
            this.accionEjecutandose = false;
            window.open(this.baseUrl2 + data.path);
          } else {
            this.accionEjecutandose = false;
            this.mostrarMsgError('Error al intentar generar el PDF, Debe intentar de nuevo');
            window.scroll(0, 0);
          }
        }, error => {
          console.log(error);
          //  this.codigoHistoria = 0;
          //  this.cerrarVentana();
          this.accionEjecutandose = false;
          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError('Error al intentar generar el PDF, Debe intentar de nuevo');
          }

          window.scroll(0, 0);
        }, () => {

        });
  }

  btnAccciones_Click($event) {
    console.log($event);
    if ($event.item === 'listado_pacientes') {
      this.btnGotoUrl('ListadoPacientes');
    } else if ($event.item === 'historia_paciente') {
      this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id });
    } else if ($event.item === 'listado_cirugias') {
      this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id });
    } else if ($event.item === 'agregar_formula') {
      // console.log({ origen: 'cirugia', codigo_origen: this.cirugiaId });
      if (this.registroGuardadoExiste === true) {
        this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id, tipo: 'nuevo', origen: 'cirugia', codigo_origen: this.cirugiaId });
      } else {
        alert('Primero debe guardar');
      }
    }

    else if ($event.item === 'agregar_incapacidad') {
      //  console.log({ origen: 'cirugia', codigo_origen: this.cirugiaId });
      if (this.registroGuardadoExiste === true) {
        this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id, tipo: 'nuevo', origen: 'cirugia', codigo_origen: this.cirugiaId });
      } else {
        alert('Primero debe guardar');
      }

    }
    // else if ($event.item === 'reutilizar_datos') {
    //   //  this.habilitarcirugias();
    //   this.alertMsg.showAlert = false;
    //   this.submitted = false;
    // }
    /* else if ($event.item === 'limpiar_datos') {
       // this.habilitarcirugias();
       this.limpiarContoles();
       this.alertMsg.showAlert = false;
       this.submitted = false;
     } */

    else if ($event.item === 'registro_vacio') {
      window.scroll(0, 0);

      //   setTimeout(() => {
      this.codigoCirugia = 0;
      this.clausurada = false;
      this.habilitarControles(['txtTiempo']);
      this.limpiarContoles();
      this.alertMsg.showAlert = false;
      this.saltarValidacion = false;
      this.submitted = false;
      this.urlConsentimiento = '';
      this.registroGuardadoExiste = false;
      this.alertMsg.showAlert = false;
      this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id, tipo: 'nuevo' });
      // }, 1000);
      // window.scroll(0,0);
    } else if ($event.item === 'imprimir') {
      //  console.log(this.codigoHistoria);
      if (Number(this.codigoCirugia) > 0) {
        this.generarPdf();
      } else if (Number(this.codigoCirugia) === 0) {
        alert('Primero debe guardar');
      }

    }

  }

  dataAccionesesGuardar() {
    return [{
      text: 'Volver al listado de pacientes', url: 'ListadoPacientes', item: 'listado_pacientes'
    }, {
      text: 'Volver a la historia clinica del paciente', url: 'HistoriaPaciente', item: 'historia_paciente'
    }, {
      text: 'Volver al historial de cirugías', url: 'ListadoCirugias', item: 'listado_cirugias'
    },
    {
      text: 'Generar formula médica', url: 'FormFormulas', item: 'agregar_formula'
    },
    {
      text: 'Generar incapacidad médica', url: 'FormIncapacidades', item: 'agregar_incapacidad'
    },
    {
      text: 'Nueva descripción vacía', item: 'registro_vacio', url: 'FormCirugias',
    }, {
      text: 'Imprimir', item: 'imprimir'
    }];
  }

  dataAccionesesEditar() {
    return [{
      text: 'Volver al listado de pacientes', url: 'ListadoPacientes', item: 'listado_pacientes'
    }, {
      text: 'Volver a la historia clinica del paciente', url: 'HistoriaPaciente', item: 'historia_paciente'
    }, {
      text: 'Volver al historial de cirugías', url: 'ListadoCirugias', item: 'listado_cirugias'
    }, {
      text: 'Generar formula médica', url: 'FormFormulas', item: 'agregar_formula'
    },
    {
      text: 'Generar incapacidad médica', url: 'FormIncapacidades', item: 'agregar_incapacidad'
    },
    {
      text: 'Nueva descripción vacía', item: 'registro_vacio', url: 'FormCirugias',
    }, {
      text: 'Imprimir', item: 'imprimir'
    }];
  }

  chkHabDesEdicion_click($event) {
    $event.preventDefault();
    this.cerrarVentana();
    this.alertMsg.showAlert = false;

    if (this.tipoUsuario !== 'ADMIN') {
      return false;
    }

    console.log($event);
    console.log(this.chkHabDesEdicion.nativeElement.checked);
    let respuesta = confirm("¿Está seguro?");
    if (respuesta === true) {

      this.apiServices.getData('cirugias/descerrar/' + this.codigoCirugia, 'Cirugia', false).
        subscribe(data => {
          console.log(data);
          if (data !== null && data.success !== undefined && data.success === true) {
            this.clausurada = false;
            this.habilitarControles(['txtMestruacion']);
            this.desBtnGuardar = false;
          } else {
            alert('No fue posible habilitar la edición. Debe intentar de nuevo');
          }
          window.scroll(0, 0);
        }, error => {
          if (error.status !== 401) {
            if (error.error.response !== undefined) {
              this.mostrarMsgError(error.error.response);
            } else {
              this.mostrarMsgError('Error al intentar habilitar la edición, debe intentar nuevamente');
            }
          } else {
            this.mostrarMsgError('Error inesperado al intentar habilitar la edición, debe intentar nuevamente');
          }
          window.scroll(0, 0);
        }, () => {

        });
      // txt = "You pressed OK!";
    } else {
      if (this.chkHabDesEdicion.nativeElement.checked === true) {
        this.chkHabDesEdicion.nativeElement.checked = false;
      } else if (this.chkHabDesEdicion.nativeElement.checked === false) {
        this.chkHabDesEdicion.nativeElement.checked = true;
      }

    }

    return false;
  }


}

