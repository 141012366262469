import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { GridDataResult, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ServiciosService } from '../../servicios/servicios.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { TransferServiceService } from '../../servicios/tra';
import { ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
//import { forkJoin, Observable } from 'rxjs';  // RxJS 6 syntax


@Component({
  selector: 'app-auditorias',
  templateUrl: './auditorias.component.html',
  styleUrls: ['./auditorias.component.css']
})
export class AuditoriasComponent implements OnInit {
  // Filters
  @ViewChild('txtId', { static: false }) txtId: ElementRef;
  @ViewChild('txtName', { static: false }) txtName: ElementRef;
  @ViewChild('txtReference', { static: false }) txtReference: ElementRef;
  @ViewChild(GridComponent, { static: false }) grid: GridComponent;


  // var grid = $('#Grid').data('kendoGrid')


  public gridView: GridDataResult;
  public buttonCount = 15;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  public skip = 0;
  currentPage = 1;
  public multiple = true;
  public allowUnsort = true;
  windowFilters = false;
  public sort: SortDescriptor[] = [];
  windowFormFilters: FormGroup;
  loading = true;
  queryString = '';
  queryStringTotales = '';
  showWindowFilters = false;
  desHabBtnImprimir = true;
  procesandoPeticion = false;
  totalPages = 0;
  medicoId = 0;
  medicoNombre = '';
  dataGridViewIndex = 0;
  dataGridViewItem: any;
  lstDataReportFields = []

  nombreArchivo = '';


  dataAcciones: Array<any> = [{
    text: 'Nuevo registro', item: 'nuevo_registro', url: '/AgregarUsuario'
  }];



  //  loading = true;

  // showItemDetails = false;


  ventanaConfirmacion = {
    verVenCon: false,
    titVenCon: '',
    tetVenCon: '',
    accion: '',
  }

  ventanaBoton = {
    verVenCon: false,
    titVenCon: '',
    tetVenCon: '',
    accion: '',
  }

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  };

  verVentanaDetalleMedico = false;


  // dataPricesList: Array<{ id: string, name: string }>;
  // dataUnitMeasurements: Array<{ id: string, name: string }>;
  // dataCategories: Array<{ id: string, name: string }>;
  // datawarehouses: Array<{ id: string, name: string }>;
  // dataAccounts: Array<{ id: string, name: string }>;
  // dataTaxes: Array<{ id: string, name: string }>;

  public lstStatus: Array<{ text: string, value: number }> = [
    { text: 'Activo', value: 1 },
    { text: 'Inactivo', value: 0 }
  ];

  // loading = true;


  lstData: any[];
  lstDataReport: any[];
  tempLstData: any[];
  public pageSize = 10;
  totalRows = 0;
  pageCount = 0;
  gridData: any[];
  // gridData: GridDataResult;


  constructor(
    private apiServices: ServiciosService,
    private formBuilder: FormBuilder,
    private location: Location,
    //   private transfereService: TransferServiceService,
    private cd: ChangeDetectorRef,
    private router: Router) {

  }

  ngOnInit() {
    this.getData('audits?page=1');

    this.windowFormFilters = this.formBuilder.group({
      txtFiltro: [''],
      cboMeses: ['']
    });
    window.scroll(0, 0);

    this.lstDataReportFields.push({ titulo: 'Acción', field: 'tipo_accion' });
    this.lstDataReportFields.push({ titulo: 'Descripción', field: 'descripcion' });
    this.lstDataReportFields.push({ titulo: 'Usuario', field: 'usuario_descripcion' });
    this.lstDataReportFields.push({ titulo: 'Rol', field: 'tipo_usuario' });
    this.lstDataReportFields.push({ titulo: 'Fecha', field: 'fecha' });

  }

  mostrarMsgError(msg: string) {
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }


  btnAccciones_Click($event) {
    console.log($event);
    switch ($event.item) {
      case 'nuevo_registro':
        this.router.navigate([$event.url], {});
        break;
    }
  }


  btnGotoUrl_click(url: string) {
    try {
      this.apiServices.gotoUrl(url);
    } catch (e) {
      alert(e.message);
    }
  }


  onSubmitFilters() {

    this.loading = true;
    this.queryString = '';
    //  this.procesandoPeticion = true;
    // console.log(this.windowFormFilters);
    // console.log(this.queryString);

    const valFiltro = this.windowFormFilters.get('txtFiltro').value;
    let valMes = '';
    // console.log(this.windowFormFilters.get('cboMeses').value);
    // console.log(typeof this.windowFormFilters.get('cboMeses').value);
    // console.log(this.windowFormFilters.get('cboMeses').value.toISOString().length);
    // console.log(this.windowFormFilters.get('cboMeses').value.length);
    if (this.windowFormFilters.get('cboMeses').value !== null &&
      this.windowFormFilters.get('cboMeses').value !== '') {
      valMes = this.windowFormFilters.get('cboMeses').value.toISOString();
    }
    console.log(valMes);
    console.log(valMes.length);
    if (valFiltro !== null && valMes !== null) {
      if (valFiltro.length === 0 && valMes.length === 0) {
        this.grid.skip = 0;
        this.getData('audits?page=1');
        return false;
      }
    }



    let queryString = '';
    if (this.queryString.length === 0) {

      queryString = 'audits?page=1';
      this.queryString += '&mes=' + valMes;
      this.queryString += '&identificacion=' + valFiltro;
      queryString = queryString + this.queryString;
    }

    //  this.queryString = queryString;

    // console.log(queryString);


    this.getData(queryString);

  }


  public dataStateChange(state: DataStateChangeEvent): void {
    console.log({ 'state': state });

    this.skip = state.skip;
    this.pageSize = state.take;
    this.sort = state.sort;

    if (this.skip === 0) {
      this.skip = 1;
    }

    this.currentPage = Math.floor(this.skip / this.pageSize);
    this.currentPage++;


    let queryString = 'audits?page=' + this.currentPage;

    if (this.sort.length > 0) {
      const sortDir = this.sort[0].dir;
      const sortField = this.sort[0].field;
      queryString += '&sortfield=' + sortField + '&sortdir=' + sortDir;
    }

    if (this.queryString.length === 0) {
      // queryString += '&espid=all';
    }

    queryString = queryString + this.queryString;
    console.log(queryString);

    this.getData(queryString);


    //this.onSubmitFilters(this.currentPage);
  }


  /*public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.getData('?page=' + this.currentPage);
  } */

  public filterChange(filter: CompositeFilterDescriptor): void {
    console.log(filter);
  }



  getData(queryString: string = '') {
    this.queryStringTotales = queryString;
    console.log({ 'queryStringTotales': this.queryStringTotales });
    this.apiServices.getData(queryString, 'audits', false).subscribe(
      data => {
        this.lstData = this.tempLstData = data['Audits'];

        console.log(this.lstData);
        this.totalRows = data.paginationInfo.count;
        this.totalPages = data.paginationInfo.pageCount;
        this.alertMsg.showAlert = false;
        // this.gridView.data({});

        this.gridView = {
          data: this.lstData,
          total: this.totalRows
        };

        this.loading = false;
        this.procesandoPeticion = false;
        // this.loading = false;

      }, (error) => {
        // console.log(error);
        this.loading = false;
        this.procesandoPeticion = false;

        if (error.error.response !== undefined) {
          this.mostrarMsgError(error.error.response);
        } else {
          this.mostrarMsgError('Error al intentar filtrar el listado. Debe intentar nuevamente.');
        }

        // this.loading = false;
      });


  }

  getDataReport(queryString: string = '', componente) {
    this.apiServices.getData(queryString, 'audits', false).subscribe(
      data => {
        // this.lstData = this.tempLstData = data['Audits'];
        // this.alertMsg.showAlert = false;
        this.procesandoPeticion = false;
        this.lstDataReport = data['Audits'];
        console.log(this.lstDataReport);
        if (this.lstDataReport.length !== undefined && this.lstDataReport.length > 0) {
          setTimeout(() => { componente.save(); }, 0);
        } else {
          this.procesandoPeticion = false;
          alert('El filtro aplicado no tiene registros');
        }

      }, (error) => {
        // console.log(error);
        this.loading = false;
        this.procesandoPeticion = false;

        if (error.error.response !== undefined) {
          this.mostrarMsgError(error.error.response);
        } else {
          this.mostrarMsgError('Error al intentar filtrar el listado. Debe intentar nuevamente.');
        }

        // this.loading = false;
      });
    // //console.log({ 'queryStringTotales': this.queryStringTotales });
    // this.apiServices.getDataPromise(queryString, 'audits', false)
    // // .then(
    // //       res => { // Success
    // //        // res = res[list]
    // //         console.log(res);
    // //        // resolve();
    // //       },
    // //       msg => { // Error
    // //         console.log(msg);
    // //        // reject(msg);
    // //       });
    //   .then(data => {
    //     this.alertMsg.showAlert = false;
    //   //  this.procesandoPeticion = false;
    //     this.lstDataReport = data['Audits'];
    //     console.log(this.lstDataReport);
    //     setTimeout(() => { componente.save(); }, 0);
    //   }).catch(error => {
    //    // console.log(error);
    //     if(error.error.response !== undefined) {
    //       this.mostrarMsgError(error.error.response);
    //     } else {
    //       this.mostrarMsgError('Error al intentar filtrar el listado. Debe intentar nuevamente.');
    //     }
    //     this.procesandoPeticion = false;
    //   }).finally(() => {
    //     this.procesandoPeticion = false;
    //    // setTimeout(() => { componente.save(); }, 0);
    //   });
  }
  // getDataReport(queryString: string): any {
  //   this.procesandoPeticion = true;
  //   //let dataReport: any;
  //   //  this.queryStringTotales = queryString;
  //   //  console.log({ 'queryStringTotales': this.queryStringTotales });
  //   this.apiServices.getData(queryString, 'audits', false).subscribe(
  //     data => {
  //       this.lstDataReport = data['Audits'];
  //       console.log(this.lstDataReport);
  //       // this.lstDataReportFields = Object.keys(this.lstDataReport[0]);
  //       this.generarReporte = true;
  //       //console.log(this.lstDataReportFields);



  //       //  console.log(this.lstDataReportFields);



  //     }, (error) => {
  //       console.log(error);
  //       this.procesandoPeticion = false;
  //     }, () => {
  //       this.procesandoPeticion = false;
  //       this.btnMostrarBoton_click();
  //     });


  // }



  public exportToPDF(grid: GridComponent): void {
    grid.saveAsPDF();
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  btnClick() {
    // this.router.navigateByUrl('/AddItem');
    this.router.navigateByUrl('/AddInvoice');
  }


  // searchItemById() {
  //   this.currentPage = 1;
  //   this.loading = true;
  //   let queryString = '';
  //   let id = '';
  //   let name = '';
  //   let reference = '';

  //   if (this.txtId.nativeElement.value.trim()) {
  //     id = 'id=' + this.txtId.nativeElement.value.trim();
  //   } else {
  //     id = '';
  //   }

  //   if (this.txtName.nativeElement.value.trim()) {
  //     name = 'name=' + this.txtName.nativeElement.value.trim();
  //   } else {
  //     name = '';
  //   }

  //   if (this.txtReference.nativeElement.value.trim()) {
  //     reference = 'reference=' + this.txtReference.nativeElement.value.trim();
  //   } else {
  //     reference = '';
  //   }

  //   if (id !== '' || name !== '' || reference !== '') {
  //     queryString = '?' + id + '&' + name + '&' + reference;
  //   }

  //   // this.getData();
  //   const page = 1;
  //   //this.onSubmitFilters(page);
  //   this.skip = 0;

  //   // console.log(this.currentPage);
  // }

  // applyFilters() {

  // }


  showAll() {
    this.skip = 0;
    this.windowFormFilters.reset();
    this.loading = true;

    this.getData('audits?page=1');
  }

  btnRefresh() {
    this.grid.skip = 0;
    this.procesandoPeticion = true;
    this.getData('audits?page=1');
  }

  btnGenerarReporte(componente) {
    // this.recoverData().then(data => {
    // this.ventanaBoton.verVenCon = true;
    this.procesandoPeticion = true;

    const valFiltro = this.windowFormFilters.get('txtFiltro').value;
    let valMes = '';

    if (this.windowFormFilters.get('cboMeses').value !== null &&
      this.windowFormFilters.get('cboMeses').value !== '') {
      valMes = this.windowFormFilters.get('cboMeses').value.toISOString();
    }

    if (valFiltro == null || valMes == null) {
      this.procesandoPeticion = false;
      this.btnMostrarConfirmar_click();
      return false;
    } else if (valFiltro == undefined || valMes == undefined) {
      this.procesandoPeticion = false;
      this.btnMostrarConfirmar_click();
      return false;
    } else if (valFiltro.length === 0 || valMes.length === 0) {
      this.procesandoPeticion = false;
      this.btnMostrarConfirmar_click();
      return false;
    }


    //this.nombreArchivo = 'Acciones_de_'

    let queryString = 'audits?page=1';
    queryString += '&mes=' + valMes;
    queryString += '&identificacion=' + valFiltro;
    queryString += '&reporte=true';
    // queryString = queryString + 
    this.getDataReport(queryString, componente);
    // });
  }

  // btnImprimir(componente) {

  //   // queryString = queryString + this.queryString;
  //   //  this.getDataReport(queryString);

  //   this.btnMostrarBoton_click();;

  //   componente.save();
  // }

  // btnImprimir(componente) {

  //   // queryString = queryString + this.queryString;
  //   //  this.getDataReport(queryString);

  //   this.btnMostrarBoton_click();;

  //   componente.save();
  // }


  btnMostrarConfirmar_click() {
    this.ventanaConfirmacion.verVenCon = true;
    this.ventanaBoton.verVenCon = false;
    this.ventanaConfirmacion.titVenCon = 'Acción no permitida';
    this.ventanaConfirmacion.tetVenCon = 'Primero debe especificar una identificacion y seleccionar un mes';
  }

  btnMostrarBoton_click() {
    console.log('Por');
    this.ventanaConfirmacion.verVenCon = false;
    this.ventanaBoton.verVenCon = true;
    this.ventanaBoton.titVenCon = 'Presione el botón descargar';
    this.ventanaBoton.tetVenCon = 'Primero debe especificar una identificacion y seleccionar un mes';
  }

  btnBotonAccion($event, componente) {
    if ($event === true) {
      componente.save();
    }

    this.ventanaConfirmacion.verVenCon = false;
  }


  btnConfirmarAccion($event) {
    if ($event === true) {
    }

    this.ventanaConfirmacion.verVenCon = false;
  }


  recoverData() {
    return new Promise((resolve, reject) => {

      resolve();
    });
  }

  // requestDataFromMultipleSources(): Observable<any[]> {
  //   const respuestas = [];
  //   for (let i = 1; i <= this.totalPages; i++) {
  //     let response = this.getDataReport('audits?page=' + i);
  //     respuestas.push(response);
  //   }
  //   // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
  //   return forkJoin(respuestas);
  // }
  //this.requestDataFromMultipleSources()
  //   .subscribe(response => {
  //     console.log(response);
  //   }, error => {
  //     console.log(error);
  //   }, () => {
  //     console.log('Finally');
  //   });
}
