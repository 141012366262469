import { ServiciosService } from './../../servicios/servicios.service';
import { Component, OnInit, Input, Output, EventEmitter, AfterContentInit, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewChecked, AfterContentChecked } from '@angular/core';
import { FormGroup, FormControlName } from '@angular/forms';
import { TransferServiceService } from '../../servicios/transfer-service.service';

interface Item {
  id: string;
  name: string;
}


@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-custom-combo-box',
  templateUrl: './custom-combo-box.component.html',
  styleUrls: ['./custom-combo-box.component.css']
})

// https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/forms/#toc-reactive-forms

export class CustomComboBoxComponent implements OnInit,
  AfterContentChecked, AfterContentInit, AfterViewChecked, OnChanges {

  @Input() parentForm: FormGroup;
  @Input() textField;
  @Input() valueField;
  @Input() list: string;
  @Input() url: string;
  @Input() queryFilter = '?name=';
  @Input() initFilter = '?page=1';
  @Input() toolTip = 'Si no encuentra el item que busca pruebe con escrbir una palabra clave';
  @Input() addNewItemTitle = '';
  @Input() frmCtrlName: string;
  @Input() errorClass: string;
  @Input() component: any;
  @Input() addNewItem: false;
  @Input() placeHolder = 'Escriba una palabra clave o despliegue las opciones';
  @Output() OpenModalClick = new EventEmitter<any>();
  @Output() ValueChanged = new EventEmitter<any>();
  @Output() LoadedData = new EventEmitter<any>();
  @Output() OnBlur = new EventEmitter<any>();
  @Input() initLoad = false;
  @Input() customData: [];

  @Input() disableControlIfEmpty = false;


  public selectedItem: Item;
  filterable = true;
  controlName: FormControlName;

  delay = (function () {
    const timers = {};
    return function (callback, ms, label) {
      label = label || 'defaultTimer';
      window.clearTimeout(timers[label] || 0);
      timers[label] = window.setTimeout(callback, ms);
    };
  })();


  isModalFormOpen = false;

  spinners = {
    spinnerData: false
  };

  dataLoaded = {
    cboData: false
  };


  dataSaved = false;
  duplicateIds = [];

  /*  dataPricesList: Array<{ id: string, name: string }>;
   dataPricesFilter: Array<{ id: string, name: string }>;

   dataWineries: Array<{ id: string, name: string }>;
   dataWineriesFilter: Array<{ id: string, name: string }>;

   dataTaxes: Array<{ id: string, name: string }>;
   dataTaxesFilter: Array<{ id: string, name: string }>;

   dataIva: Array<{ id: string, name: string }>;
   dataIvaFilter: Array<{ id: string, name: string }>; */

  dataData: Array<{ id: string, name: string }>;
  dataFiltered: Array<{ id: string, name: string }>;
  newData: Array<{ id: string, name: string }>;

  public openModalForm() {
    // this.isModalFormOpen = true;
    this.OpenModalClick.emit(true);
  }

  public closeCategoriesModalForm_Click() {
    this.isModalFormOpen = false;
  }
  //#endregion

  //#region CLOSE MODAL FORMS
  public closeModalForm() {
    this.isModalFormOpen = false;
    this.OpenModalClick.emit(this.isModalFormOpen);
  }


  // message = '';
  constructor(private ServiciosServices: ServiciosService,
    private cdr: ChangeDetectorRef,
    transferService: TransferServiceService) {

    transferService.getData().subscribe(data => {
      //console.log({ 'data': data });
      if (data.list === this.list) {
        //console.log({ 'data.data': data.data });
        this.dataData.unshift(data.data);
        this.dataFiltered.unshift(data.data);
        // this.dataFiltered;
      }

    });


  }

  ngOnInit() {

    //console.log({initload: this.initLoad});
    // this.parentForm.get(this.frmCtrlName).valueChanges.subscribe(val => {
    //   //console.log(val);
    // });
  }

  ngAfterViewChecked() {
    // your code to update the model
    // this.getData(this.url + this.initFilter, this.list);
    this.cdr.detectChanges();
  }

  ngAfterContentChecked() {
    // this.message = 'all done loading :)'
   //  this.getData(this.url + this.initFilter, this.list);
   // this.cdr.detectChanges();
  }

  ngAfterContentInit() {
    //console.log({initLoad: this.initLoad});
    //console.log({customData: this.customData});
    if (this.initLoad === true) {
      //console.log('Por defecto está entrando aqui....');
      this.loadData();
    } else if (this.customData !== undefined) {
      //console.log('Por defecto está entrando aqui 2....');
      this.dataData = this.customData;
      // Indicar que el combo esta cargado
      this.dataLoaded.cboData = false; // Vital para cuando el tipo de carga es customData //Si es false agrega los datos al combo
    }
    //  //console.log({ 'this.customData ': this.customData });
    // //console.log('this.initLoad ' + this.initLoad);
    // //console.log('ngAfterContentInit ');

    //console.log({ 'parentForm': this.parentForm });
    //console.log({ 'frmCtrlName': this.frmCtrlName });


   // this.cdr.detectChanges();

    // //console.log(this.parentForm.controls.invoiceItems);
  }



  loadData() {
    //console.log({loadData: this.dataData});
    // This line check if cboData already is loaded
    if (this.dataLoaded.cboData === false) {
      this.spinners.spinnerData = true; // Start gif spinner
      // Call to getIva method
      this.getData(this.url + this.initFilter, this.list);
    }
  }

  getData(queryString: string = '', list: string) {

    //console.log('esta entrando aquó otra vez');

    try {

      this.ServiciosServices.getData(queryString, list)
        .subscribe(data => {
          this.dataData = data;

          //   //console.log(this.list);
          //  //console.log(data);

          if (this.dataData !== undefined) {

            if (this.dataData.length > 0) {
              //  this.dataData = this.dataData[list];
              //  //console.log({ 'data': this.dataData });
              // this.tempDataIva = this.dataIva;
              this.LoadedData.emit(this.dataData);
              this.dataFiltered = this.dataData.slice();
              // Set cboTaxes comobobox like loaded for avoid reload it again
              this.dataLoaded.cboData = true;
              this.parentForm.get(this.frmCtrlName).enable();


            } else if (this.disableControlIfEmpty === true) {
              this.parentForm.get(this.frmCtrlName).disable();
            }

          }

          //console.log({ FormControlName: this.frmCtrlName });

        }, (error) => {
          //console.log(error);
          this.dataLoaded.cboData = false; // Set cboTaxes comobobox like not loaded
          this.spinners.spinnerData = false; // Stop gif spinner
        }, () => {
          this.spinners.spinnerData = false; // Stop gif spinner
        });

    } catch (ex) {
      //console.log(ex);
    }
  }

  onBlur($event) {
    this.filterable = false;
    //console.log('onBlur');
    this.OnBlur.emit($event);
  }

  onOpen($event) {
    //console.log($event);
    //console.log('onOpen');
    //console.log(this.dataData);
    //console.log(this.dataFiltered);
  //  if (this.dataData === undefined) {
      this.dataData = this.dataFiltered;
  //  }

  }
  onSelectionChange($event) {
    //console.log('onSelectionChange ');

  }
  onFilterChange($event) {
    //console.log('onFilterChange');
  }

  onKeyPress($event) {
    //console.log('onKeyPress');
    //console.log($event);
    // this.filterable = true;
  }

  onKeyDown($event) {
    //   //console.log({ 'onKeyDown ': $event.keyCode });
    if ($event.keyCode === 39 || $event.keyCode === 37
      || $event.keyCode === 17 || $event.keyCode === 18) {
      //  //console.log('Esta entrando aquiiiiii');
      this.filterable = false;
      // this.parentForm.get(this.frmCtrlName).patchValue([{ id: '190990856828053', name: 'Brazil' }]);
      // this.getData(this.url + this.initFilter, this.list);
      $event.stopPropagation();
      return;
    }
  }
  onKeyUp($event) { // Back Key

    // //console.log('Enotttttttttt'+$event.target.value);

    if ($event.target.value === '') {
      // //console.log('Enotttttttttt');
      $event.stopPropagation();
      return false;
    }
    // this.filterable = false;
    //console.log({ 'onKeyUp ': $event });
  }

  onChange($event) {
    //console.log({ 'onChange ': $event });
  }

  ngOnChanges(changes: any) {
    //   //console.log(changes);
    if (changes.hasOwnProperty('queryFilter')) {
      // changes.hasOwnProperty('queryFilter')
      //  //console.log(changes);
      //  //console.log(changes.queryFilter.currentValue);

      // this.queryFilter = changes.initFilter.currentValue;
      if (changes.initFilter && changes.initFilter.previousValue !== undefined) {
        this.getData(this.url + changes.initFilter.currentValue, this.list);
      }
    }
  }

  public dataValueChange(value: any): void {
    //console.log(value);
    if (value === undefined) {
      this.dataData = this.dataFiltered;
      //console.log(this.dataData);
    }
    this.ValueChanged.emit(value);
    return;
  }


  dataFilter(event) {
    //console.log('dataFilter ' + this.filterable);
    if (this.filterable === false) {
      this.filterable = true;
      return;
    }
    this.spinners.spinnerData = true;
    this.delay(() => { this.filterData(event.target.value, this.list); }, 1500, '');
  }


  filterData(value, list) {

    // //console.log(value);
    // //console.log(list);
    //  //console.log(this.dataFiltered);

    // let tempData; let tempDataLength = 0;
    // if (this.dataFiltered !== undefined) {
    //   //    //console.log('esta entrando aqui');
    //   tempData = this.dataFiltered.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    //   tempDataLength = tempData.length;
    // }

    //  //console.log(tempDataLength);

    if (value.length >= 2) {
      // if (tempDataLength === 0 && value.length >= 2) {
        value = value.slice(0,30);

      this.ServiciosServices.getData(this.url + this.queryFilter + value, list)
        .subscribe(data => {
          this.dataData = data;

          //console.log(data);
          //console.log(this.dataData);

          if (this.dataData !== undefined) {
            if (this.dataData.length > 0) {
              this.dataData.forEach(item => {
                // const val = { id: item.id, name: item.name };
                // //console.log('Resultado: ' + this.dataFiltered.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1));

                //  this.dataFiltered.forEach(item1 => {
                // const val = { id: item.id, name: item.name };

                //   this.dataFiltered.push(item);
                this.dataFiltered.push(item);
                //  //console.log(item1.id);

                //  });


                //  //console.log(unique);


              });

              this.dataFiltered = this.removeDuplicateObjectFromArray(this.dataFiltered, 'id');
              // this.dataFiltered = (this.dataFiltered) => this.dataFiltered.filter((v, i) => this.dataFiltered.indexOf(v) === i)
              // x(nathis.dataFilteredmes);



              // const val = { id: item.id, name: item.name };
              //  //console.log(this.dataFiltered.indexOf(val));
              // if (this.dataFiltered.indexOf(val) === -1) {
              //   this.dataFiltered.push(item);
              // }
              // //console.log(this.dataFiltered.indexOf(val));
              // //console.log(this.dataFiltered);


              this.parentForm.get(this.frmCtrlName).enable();
            } else {
             // this.parentForm.get(this.frmCtrlName).disable(); Error de consola por esto
             // this.parentForm.get(this.frmCtrlName).updateValueAndValidity();
            }
          } else {

            this.parentForm.get(this.frmCtrlName).disable();
          }

        }, (error) => {
          //console.log(error);
        }, () => {
          this.spinners.spinnerData = false; // Stop gif spinner
        });

    } else {
      // this.dataData = tempData;
      this.spinners.spinnerData = false; // Stop gif spinner
    }

  }

  // ngAfterViewInit() {
  //   //console.log(this.parentForm);
  // //console.log(this.frmCtrlName);

  //   //console.log(this.list);

  //  this.controlName = this.frmCtrlName;
  //  //console.log(this.parentForm.get('cboWineries'));
  // }



  removeDuplicateObjectFromArray(array, key) {
    const check = new Set();
    return array.filter(obj => !check.has(obj[key]) && check.add(obj[key]));
  }

  get getControl() { return this.parentForm.get(this.frmCtrlName); }
}
