import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterContentInit, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { GreaterThanZero } from 'src/app/validators/CustomValidators';
import { ServiciosService } from '../../servicios/servicios.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { environment } from 'src/environments/environment';
import { GetMsgFromError } from 'src/app/functions/SharedFunctions';
import { serverErrorsInfo } from 'src/app/utilities/serverErrorsInfo';
import { GridDataResult, DataStateChangeEvent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'app-form-evoluciones',
  templateUrl: './form-evoluciones.component.html',
  styleUrls: ['./form-evoluciones.component.css']
})
export class FormEvolucionesComponent implements OnInit, AfterContentInit, AfterViewInit {
  // @ViewChildren('elementosAntecedentesRef') elementosAntecedentesRef: QueryList<ElementRef>
  // @ViewChildren('elementosRevisionSistemasRef') elementosRevisionSistemasRef: QueryList<ElementRef>
  @ViewChild('chkAlergiaRef') chkAlergiaRef: ElementRef<HTMLInputElement>;
  @ViewChild('chkHabDesEdicion', { static: false }) chkHabDesEdicion: ElementRef<HTMLInputElement>;

  // @ViewChild('txtTraumas') txtTraumasRef: ElementRef;
  // @ViewChild('txtFamiliares') txtFamiliaresRef: ElementRef;
  // @ViewChild('txtFarmacologicos') txtFarmacologicosRef: ElementRef;
  // @ViewChild('txtQuirurgicos') txtQuirurgicosRef: ElementRef;
  // @ViewChild('txtAlergicos') txtAlergicosRef: ElementRef;
  // @ViewChild('txtToxicos') txtToxicosRef: ElementRef;
  // @ViewChild('cboGinecoObstetricos') cboGinecoObstetricosRef: ElementRef;
  // @ViewChild('cboValorGinecoObstetricos') cboValorGinecoObstetricosRef: ElementRef;
  // @ViewChild('txtEsquemaDeVacunacion') txtEsquemaDeVacunacionRef: ElementRef;
  // @ViewChild('txtMestruacion') txtMestruacionRef: ElementRef;
  // @ViewChild('txtPlanificacion') txtPlanificacionRef: ElementRef;
  // @ViewChild('txtNotaAntecedentesPersonales') txtNotaAntecedentesPersonalesRef: ElementRef;

  formData: FormGroup;
  accionEjecutandose = false;
  baseUrl2 = environment.baseUrl2;
  buscandoCodigoCie = false;
  tipoAccion = '';
  msgNotificacion = '';
  url = '';
  saltarValidacion = false;
  medicoId = 0;
  guardarClausurarEditar = '';
  tituloBotonCredenciales = '';
  tituloVentanaCredenciales = 'Verificación de credenciales';
  jsonOriginal = {};
  clausurada = false;
  codigoEvolucion = 0;
  evolucionId = 0;
  dataItem = null;
  medicoIdReg = 0;
  medicoDeLaEvolucion = '';
  nuevoRegistro = false;
  cargandoDatos = false;
  errorDeCarga = false;
  tipoUsuario = '';
  dataPaciente: any;
  datosPacienteCargados = false;
  pacienteId = 0;
  userInfo: any;
  registroGuardadoExiste = false;
  //tipoAccion: any;
  tituloFormulario = '';
  desBtnGuardar = false;
  infoArchivos = [];
  infoFotos = [];
  fotosExamenFisico = [];

  tipAccion = {
    NUEVO: 'nuevo',
    DETALLE: 'detalle'
  };

  //pacienteId = 0;

  verVentanas = {
    verVenConBorArchivo: false,
    titVenConBorArchivo: '',
    tetVenConBorArchivo: '',
    verVenConBorFoto: false,
    titVenConBorFoto: '',
    tetVenConBorFoto: '',
    adjuntarFotos: false,
    verFotos: false,
    verArchivos: false,
    tituloVentanaTexto: '',
    ventanaPlaceHolder: '',
    mostrarRegistrarNota: false,
    mostrarNumerosGineco: false,
    mostrarCalendario: false,
    mostrarBuscarCie10: false,
    mostrarVentanaLogin: false,
    adjuntarArchivos: false,
    verVentanaConfirmarGuardar: false,
    titVenCon: '',
    tetVenCon: '',
    accion: '',
    verVentanaCredenciales: false,
    verVentanaConfirmarCredenciales: false,
    titentanaConfirmarCredenciales: '',
    tetVentanaConfirmarCredenciales: ''
  }


  serverErrorsInfo: any = {};

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  };

  alertMsgFoto = {
    showAlert: false,
    msg: '',
    class: ''
  };

  alertMsgArchivo = {
    showAlert: false,
    msg: '',
    class: ''
  };

  public gridViewArchivos: GridDataResult;
  public buttonCount = 10;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  public skip = 0;

  public multiple = true;
  public allowUnsort = true;

  public sort: SortDescriptor[] = [];
  public pageSize = 5;
  totalPages = 0;
  pageCount = 0;

  esAlergico = false;
  submitted = false;
  loadingData = false;
  pathArchivosDetalle = '';



  // cajasDeTexto = {
  //   antecedentesPersonales: {
  //     patologicos: 'No',
  //     familiares: 'No',
  //     traumas: 'No',
  //     quirurgicos: 'No',
  //     farmacologicos: 'No',
  //     alergicos: 'No',
  //     esAlergico: 'No',
  //     toxicos: 'No',
  //     ginecoGravidez: 0,
  //     ginecoPartos: 0,
  //     ginecoVivos: 0,
  //     ginecoAbortos: 0,
  //     ginecoCesareas: 0,
  //     esquemaVacunacion: 'No',
  //     mestruacionOpcion: 'No',
  //     mestruacionValor: '',
  //     planificacion: 'No',
  //     notasAntecedentes: ''
  //   },
  //   revisionSistemas: {
  //     sistemaVisual: 'No',
  //     sistemaAuditivo: 'No',
  //     sistemaDigestivo: 'No',
  //     sistemaMuscular: 'No',
  //     sistemaEsqueletico: 'No',
  //     sistemaNervioso: 'No',
  //     sistemaTegumentario: 'No',
  //     sistemasNotas: ''
  //   },
  //   signosVitales: {
  //     temperatura: 0,
  //     pulso: 0,
  //     tension1: 0,
  //     tension2: 0,
  //     frecuencia: 0,
  //     peso: 0,
  //     talla: 0,
  //     imc: 0,
  //     notasSignos: ''
  //   },
  //   motivoConsulta: {
  //     motivo: 'No',
  //     notasMotivo: ''
  //   },
  //   enfermedadActual: {
  //     enfermedad: 'No',
  //     notasEnfermedad: ''
  //   },
  //   examenFisico: {
  //     tercioSuperior: 'No',
  //     tercioMEDICO: 'No',
  //     tercioInferior: 'No',
  //     cuello: 'No',
  //     mamas: 'No',
  //     abdomen: 'No',
  //     dorso: 'No',
  //     gluteos: 'No',
  //     miembroSuperior: 'No',
  //     miembroInferior: 'No',
  //     genitales: 'No',
  //     torax: 'No',
  //     notasExamen: ''
  //   },
  //   paraClinicos: {
  //     paraclinicos: 'No',
  //     notasParaClinicos: ''
  //   },
  //   analisis: {
  //     analisis: 'No',
  //     notasAnalisis: ''
  //   },
  //   diagnostico: {
  //     diagnosticos_cie10: [],
  //     diagnosticoManual: 'No',
  //     diagnosticoNotas: ''
  //   },
  //   plan: {
  //     plan: 'No',
  //     planNotas: ''
  //   },
  //   generales: {
  //     clausurar: 'No',
  //     notasGenerales: ''
  //   }
  // }

  // ginecoSelectedValues = [];



  // badgeValues = {
  //   ginecoObstetricos: '',
  //   mestruacion: '',
  //   tegumentario: ''
  // }

  // valoresPorDefecto = [];

  //pacienteId = '';
  registroEncontrado = false;
  dataAcciones: any;

  nombrePaciente = '';
  historiaJson = '';

  lstCodigosCie10: [];

  // opcionesSelecciondas = {
  //   patologias: '',
  //   traumas: ''
  // }


  // dataAcciones: Array<any> = [
  //   { text: 'Regresar al listado', accion: 'ListadoPacientes' },
  //   { text: 'Generar formula médica', accion: 'AgregarFormula' },
  //   { text: 'Generar incapacidad', accion: 'AgregarIncapacidad' },
  //   { text: 'Clausurar', accion: 'Clausurar' },
  //   { text: 'Imprimir', accion: 'Imprimir' }
  // ];

  valoresCie10Agegados = [];
  codigosFotosAgregadas = [];
  codigosArchivosAgregados = [];

  // #region ************************ DECLARACIÓNES ENFERMEDAD ACTUAL
  get textObjetivo() { return this.formData.get('txtObjetivo'); }
  get textNotasEnfermedadActual() { return this.formData.get('txtNotasEnfermedadActual'); }
  // #endregion


  // #region ************************ DECLARACIÓNES MOTIVO DE CONSULTA
  get textSubjetivo() { return this.formData.get('txtSubjetivo'); }
  get textNotasMotivoConsulta() { return this.formData.get('txtNotasMotivoConsulta'); }
  //#endregion

  // #region ************************ DECLARACIÓNES PARA-CLÍNICOS
  get textParaClinicos() { return this.formData.get('txtParaClinicos'); }
  get textNotasClinicos() { return this.formData.get('txtNotasParaClinicos'); }
  // #endregion

  // #region ************************ DECLARACIÓNES ANÁLISIS
  get textAnalisis() { return this.formData.get('txtAnalisis'); }
  get textNotasAnalisis() { return this.formData.get('txtNotasAnalisis'); }
  // #endregion

  // #region ************************ DECLARACIÓNES SIGNOS VITALES
  get textTemp() { return this.formData.get('txtTemp'); }
  get textPulso() { return this.formData.get('txtPulso'); }
  get textFrecuencia() { return this.formData.get('txtFrecuencia'); }
  get textTension1() { return this.formData.get('txtTension1'); }
  get textTension2() { return this.formData.get('txtTension2'); }
  get textPeso() { return this.formData.get('txtPeso'); }
  get textTalla() { return this.formData.get('txtTalla'); }
  get textImc() { return this.formData.get('txtImc'); }
  get textNotasSignos() { return this.formData.get('txtNotasSignos'); }
  // #endregion

  // #region ************************ DECLARACIÓNES DIAGNOSTICO
  indiceCie10 = 0;
  cie10Rows: FormArray;
  get comboCie10() { return this.formData.get('cboCie10'); }
  get textdiagnosticoNotas() { return this.formData.get('txtDiagnosticoNotas'); }
  msjErrorCie10 = '';

  get textDiagnostico() { return this.formData.get('txtDiagnostico'); }
  get textDiagnosticoNotas() { return this.formData.get('txtDiagnosticoNotas'); }


  // #endregion

  // #region ************************ DECLARACIÓNES PLAN
  get textPlan() { return this.formData.get('txtPlan'); }
  get textPlanNotas() { return this.formData.get('txtPlanNotas'); }
  // #endregion

  // #region ************************ DECLARACIÓNES ANOTACIONES GENERALES
  get textNotasGenerales() { return this.formData.get('txtNotasGenerales'); }
  // get textPlanNotas() { return this.formData.get('txtPlanNotas'); }
  // #endregion
  // ginecoRows: FormArray;
  // msjErrorGinecoOpcion = '';
  // msjErrorGinecoValor = '';

  // #region ************************** DECLARACIÓNES ANTECEDENTES PERSONALES

  // get textPatologicos() { return this.formData.get('txtPatologicos'); }
  // get textTraumas() { return this.formData.get('txtTraumas'); }
  // get textFamiliares() { return this.formData.get('txtFamiliares'); }
  // get textQuirurgicos() { return this.formData.get('txtQuirurgicos'); }
  // get textFarmacologicos() { return this.formData.get('txtFarmacologicos'); }
  // get textAlergicos() { return this.formData.get('txtAlergicos'); }
  // get textToxicos() { return this.formData.get('txtToxicos'); }
  // get textEsquemaDeVacunacion() { return this.formData.get('txtEsquemaDeVacunacion'); }
  // get comboGinecoObstetricosOpcion() { return this.formData.get('cboGinecoObstetricosOpcion'); }
  // get comboGinecoObstetricosValor() { return this.formData.get('cboGinecoObstetricosValor'); }
  // get textPlanificacion() { return this.formData.get('txtPlanificacion'); }
  // get comboMestruacion() { return this.formData.get('cboMestruacion'); }
  // get textMestruacion() { return this.formData.get('txtMestruacion'); }

  // get textGravidez() { return this.formData.get('txtGravidez'); }
  // get textPartos() { return this.formData.get('txtPartos'); }
  // get textVivos() { return this.formData.get('txtVivos'); }
  // get textAbortos() { return this.formData.get('txtAbortos'); }
  // get textCesareas() { return this.formData.get('txtCesareas'); }

  // get textNotaAntecedentesPersonales() {
  //   return this.formData.get('txtNotaAntecedentesPersonales');
  // }

  dataCies: Array<{ id: string, codigo_titulo: string }>;

  dataGinecoObstetricosOpciones: Array<{ text: string, value: string }> = [
    { text: 'G - Gravidez', value: 'G - Gravidez' },
    { text: 'P - Partos', value: 'P - Partos' },
    { text: 'V - Vivos', value: 'V - Vivos' },
    { text: 'A - Abortos', value: 'A - Abortos' },
    { text: 'C - Cesáreas', value: 'C - Cesáreas' },
    { text: 'No', value: 'No' },
    { text: 'No aplica', value: 'No aplica' },
  ];

  dataGinecoObstetricosValores: Array<number> = [
    0, 1, 2, 3, 4, 5, 6, 7
  ];

  dataMestruacion: Array<{ text: string, value: string }> = [
    { text: 'Si', value: 'Si' },
    { text: 'No', value: 'No' }
  ];

  // arrayAntPersonalesElementos = {
  //   patologicos: 'txtPatologicos',
  //   familiares: 'txtFamiliares',
  //   traumas: 'txtTraumas',
  //   quirurgicos: 'txtQuirurgicos',
  //   farmacologicos: 'txtFarmacologicos',
  //   alergicos: 'txtAlergicos',
  //   toxicos: 'txtToxicos',
  //   //  ginecoObstetricos: 'cboGinecoObstetricosOpcion',
  //   //  ginecoObstetricosOpcion: 'cboGinecoObstetricosOpcion',
  //   //  ginecoObstetricosValor: 'cboGinecoObstetricosValor',
  //   esquemaVacunacion: 'txtEsquemaDeVacunacion',
  //   //  mestruacion: 'txtMestruacion',
  //   planificacion: 'txtPlanificacion',
  //   notas: 'txtNotaAntecedentesPersonales'
  // }

  public dataAntecedentes: Array<string> = ['Si', 'No', 'No aplica'];


  // #endregion

  // #region ************************** DECLARACIÓNES REVISIÓN SISTEMAS

  // get textSistemaVisual() { return this.formData.get('txtSistemaVisual'); }
  // get textSistemaAuditivo() { return this.formData.get('txtSistemaAuditivo'); }
  // get textSistemaDigestivo() { return this.formData.get('txtSistemaDigestivo'); }
  // get textSistemaMuscular() { return this.formData.get('txtSistemaMuscular'); }
  // get textSistemaEsqueletico() { return this.formData.get('txtSistemaEsqueletico'); }
  // get textSistemaNervioso() { return this.formData.get('txtSistemaNervioso'); }
  // get comboSistemaTegumentario() { return this.formData.get('cboSistemaTegumentario'); }
  // get textNotaSistemas() { return this.formData.get('txtNotaSistemas'); }
  // get textEsquemaDeVacunacion() { return this.formData.get('txtEsquemaDeVacunacion'); }
  // get comboGinecoObstetricosOpcion() { return this.formData.get('cboGinecoObstetricosOpcion'); }
  // get comboGinecoObstetricosValor() { return this.formData.get('cboGinecoObstetricosValor'); }
  // get textPlanificacion() { return this.formData.get('txtPlanificacion'); }
  // get comboMestruacion() { return this.formData.get('cboMestruacion'); }
  // get textMestruacion() { return this.formData.get('txtMestruacion'); }
  // get textNotaAntecedentesPersonales() {
  //   return this.formData.get('txtNotaAntecedentesPersonales');
  // }
  /*
    <div *ngIf="textPlanificacion.hasError('required') && submitted" class="error">
                  Debe llenar este campo o seleccinar la opción No/No aplica
                </div>

                <div *ngIf="textMestruacion.hasError('required') && submitted" class="error">
                Debe seleccinar una fecha
              </div>
  */
  dataRevisionSistemas: Array<string> = ['Si', 'No', 'No aplica'];

  dataSistemaTegumentario: Array<{ text: string, value: string }> = [
    { text: 'No', value: 'No' },
    { text: 'I', value: 'I' },
    { text: 'II', value: 'II' },
    { text: 'III', value: 'III' },
    { text: 'IV', value: 'IV' },
    { text: 'V', value: 'V' },
    { text: 'VI', value: 'VI' },
    { text: 'VII', value: 'VII' }
  ];

  // arrayrRevisionSistemasElementos = {
  //   sistemaVisual: 'txtSistemaVisual',
  //   sistemaAuditivo: 'txtSistemaAuditivo',
  //   sistemaDigestivo: 'txtSistemaDigestivo',
  //   sistemaMuscular: 'txtSistemaMuscular',
  //   sistemaEsqueletico: 'txtSistemaEsqueletico',
  //   sistemaNervioso: 'txtSistemaNervioso',
  //   // sistemaTegumentario: 'cboSistemaTegumentario',
  //   notas: 'txtNotaSistemas'
  // }
  // #endregion

  // #region ************************** DECLARACIÓNES EXAMEN FÍSICO


  subiendoFoto = false;

  fotoVisualizada: any;
  indiceFotoVisualizada = 0;
  urlFotoVisualizada = '';

  public width = '400px';
  public height = '400px';

  public myRestrictions: FileRestrictions = {
    maxFileSize: 3097152
  };

  ngModelNombreFoto: '';
  ngModelNombreArchivo: '';
  ngModelArchivo: any;
  ngModelFoto: any;
  fotoExamenFisico = undefined;
  archivoParaClinico = undefined;

  // get textTercioSuperior() { return this.formData.get('txtTercioSuperior'); }
  // get textTercioMEDICO() { return this.formData.get('txtTercioMEDICO'); }
  // get textTercioInferior() { return this.formData.get('txtTercioInferior'); }
  // get textCuello() { return this.formData.get('txtCuello'); }
  // get textMamas() { return this.formData.get('txtMamas'); }
  // get textAbdomen() { return this.formData.get('txtAbdomen'); }
  // get textAnterior() { return this.formData.get('txtAnterior'); }
  // get textPosterior() { return this.formData.get('txtPosterior'); }
  // get textGluteos() { return this.formData.get('txtGluteos'); }
  // get textMiembroSuperior() { return this.formData.get('txtMiembroSuperior'); }
  // get textMiembroInferior() { return this.formData.get('txtMiembroInferior'); }
  // get textGenitales() { return this.formData.get('txtGenitales'); }
  // get textNotasExamen() { return this.formData.get('txtNotasExamen'); }


  // arrayExamenFisicoElementos = {
  //   tercioSuperior: 'txtTercioSuperior',
  //   tercioMEDICO: 'txtTercioMEDICO',
  //   tercioInferior: 'txtTercioInferior',
  //   cuello: 'txtCuello',
  //   mamas: 'txtMamas',
  //   abdomen: 'txtAbdomen',
  //   dorso: 'txtAnterior',
  //   gluteos: 'txtGluteos',
  //   miembroSuperior: 'txtMiembroSuperior',
  //   miembroInferior: 'txtMiembroInferior',
  //   genitales: 'txtGenitales',
  //   notas: 'txtNotasExamen'
  // }

  ngAfterViewInit() {


    // this.ChangeDetectorRef.

    //  this.cd.detectChanges();p
  }

  // ngAfterContentInit(): void {
  //   this.deshabilitarValidaciones();
  //   this.getData(Number(this.pacienteId));
  //   // this.cd.detectChanges();
  // }



  calcularImc(): void {
    let imc = 0;
    const peso = this.formData.get('txtPeso').value;
    let talla = this.formData.get('txtTalla').value;
    console.log(peso);
    console.log(talla);

    this.formData.get('txtImc').setValue(0);
    if (peso > 0 && talla > 0) {
      // const peso = Number(item.txtPeso);
      // let talla = item.txtTalla;
      // talla = talla * talla;
      talla = Number(talla) * Number(talla);
      imc = peso / talla;
      console.log(imc);
      this.formData.get('txtImc').setValue(imc)
      // debugger;
    }
    //  this.cajasDeTexto.signosVitales.imc = imc;
  }

  //#region *************************** MÉTODOS SIGNOS VITALES
  signosVitales_input($event, pregunta: string) {

    this.calcularImc();

    // const valor = $event;
    // switch (pregunta) {
    //   case 'temperatura':
    //     this.cajasDeTexto.signosVitales.temperatura = valor;
    //     break;
    //   case 'pulso':
    //     this.cajasDeTexto.signosVitales.pulso = valor;
    //     break;
    //   case 'tension1':
    //     this.cajasDeTexto.signosVitales.tension1 = valor;
    //     break;
    //   case 'tension2':
    //     this.cajasDeTexto.signosVitales.tension2 = valor;
    //     break;
    //   case 'peso':
    //     this.cajasDeTexto.signosVitales.peso = valor;
    //     break;
    //   case 'frecuencia':
    //     this.cajasDeTexto.signosVitales.frecuencia = valor;
    //     break;
    //   case 'talla':
    //     this.cajasDeTexto.signosVitales.talla = valor;
    //     break;
    //   case 'imc':
    //     this.cajasDeTexto.signosVitales.imc = valor;
    //     break;
    //   case 'notas':
    //     this.cajasDeTexto.signosVitales.notasSignos = valor;
    //     break;
    // }
    // console.log(this.cajasDeTexto.signosVitales);
  }

  //#endregion


  //#region *************************** MÉTODOS MOTIVO DE CONSULTA
  // motivoConsulta_input($event, pregunta: string) {
  //   const valor = $event.target.value;
  //   switch (pregunta) {
  //     case 'motivo':
  //       this.cajasDeTexto.motivoConsulta.motivo = valor;
  //       break;
  //     case 'notas':
  //       this.cajasDeTexto.motivoConsulta.notasMotivo = valor;
  //       break;
  //   }
  //   console.log(this.cajasDeTexto.motivoConsulta);
  // }
  //#endregion

  //#region *************************** MÉTODOS ENFERMEDAD ACTUAL
  // enfermedadActual_input($event, pregunta: string) {
  //   const valor = $event.target.value;
  //   switch (pregunta) {
  //     case 'enfermedad':
  //       this.cajasDeTexto.enfermedadActual.enfermedad = valor;
  //       break;
  //     case 'notas':
  //       this.cajasDeTexto.enfermedadActual.notasEnfermedad = valor;
  //       break;
  //   }

  //   console.log(this.cajasDeTexto.enfermedadActual);
  // }
  //#endregion

  // #region ************************** MÉTODOS ANTECEDENTES PERSONALES

  chkAlergicos_click($event) {
    console.log($event);
    console.log(this.chkAlergiaRef.nativeElement.checked);
    if (this.chkAlergiaRef.nativeElement.checked === true) {
      this.chkAlergiaRef.nativeElement.checked = true;
      this.formData.get('txtAlergicos').enable();
      this.formData.get('txtAlergicos').setValue('');
      // this.esAlergico = 'Si';
      this.esAlergico = true;
    } else {
      this.chkAlergiaRef.nativeElement.checked = false;
      //  this.esAlergico = 'No';
      this.formData.get('txtAlergicos').setValue('No');
      this.formData.get('txtAlergicos').disable();
      this.esAlergico = false;
    }
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }



  btnMostrarConfirmacionBorrarFoto_click() {
    // this.verVentanas.verVenConBorFoto = false;

    console.log(this.fotosExamenFisico);
    if (this.fotosExamenFisico == null || this.fotosExamenFisico.length === 0) {

      return false;
    }

    if (this.infoFotos.length === 0) {
      alert('No hay fotos para visualizar');
      this.verVentanas.verFotos = false;
      return false;
    }

    // console.log(this.fotoVisualizada);
    this.alertMsg.showAlert = false;
    this.verVentanas.verVenConBorFoto = true;
    this.verVentanas.titVenConBorFoto = 'Confirmación de borrado';
    this.verVentanas.tetVenConBorFoto = '¿Está seguro de borrar la foto?';

    return false;
  }

  // Evento de confirmar borrado
  btnConfirmarBorradoFotoExamenFisico_click($event) {
    //  this.cerrarVentana();
    console.log($event);

    this.alertMsgFoto.showAlert = false;
    this.verVentanas.verVenConBorFoto = false;
    // console.log(this.fotoVisualizada);

    if ($event === false) {
      return false;
    }


    switch (this.tipoAccion) {

      case 'nuevo':
        // debugger;
        // console.log(this.fotosExamenFisico);
        // console.log(this.indiceFotoVisualizada);



        console.log(this.fotosExamenFisico.length);

        if (this.fotosExamenFisico.length > 0) {
          const deepClone = JSON.parse(JSON.stringify(this.fotosExamenFisico));
          // const deepClone1 = JSON.parse(JSON.stringify(this.fotosExamenFisico));
          // const arrayVacio = [];

          //  console.log({seg1_deepClone1: deepClone1});
          //Eliminar la foto actual

          console.log(deepClone.length);
          console.log(deepClone);
          console.log(typeof this.fotoVisualizada);
          console.log(this.fotoVisualizada);



          let obj;
          if (this.fotoVisualizada === undefined && deepClone.length >= 0) {
            const item = deepClone[0];
            obj = {
              index: 0,
              item: {
                title: item.title,
                url: this.baseUrl2 + item.url_foto_mini,
                url2: this.baseUrl2 + item.url_foto_original,
                posicion: 0,
                codigo: item.codigo
              }
            };
            this.fotoVisualizada = obj;
          }

          console.log(this.fotosExamenFisico);
          let indiceBorrada = 0;
          for (let i = 0; i < deepClone.length; i++) {

            if (Number(deepClone[i].codigo) === Number(this.fotoVisualizada.item.codigo)) {
              // const item = deepClone[i];
              // let index = 0
              // for (index; index < this.fotosExamenFisico.length; index++) {
              //   const foto = this.fotosExamenFisico[index];
              //   if (item.codigo === foto.codigo) {
              //     //Borrar fotos del archivo original
              //   }
              // }

              indiceBorrada = i;
              console.log({ indiceBorrado: i });
              //  console.log({indiceFotoBorrada: i})
              //  console.log({seg3_indiceFotoBorrada: item});
              this.fotosExamenFisico.splice(i, 1);
              this.infoFotos.splice(i, 1);
              deepClone.splice(i, 1);
              // this.indiceFotoVisualizada = i;
            }
          }

          console.log(this.fotosExamenFisico.length);
          console.log(deepClone.length);
          console.log(indiceBorrada);

          if (indiceBorrada === 0 && this.fotosExamenFisico.length === 1) {
            console.log(indiceBorrada);
            const item = deepClone[0];
            console.log(item);
            const obj = {
              index: 0,
              item: {
                title: item.title,
                url: this.baseUrl2 + item.url_foto_mini,
                url2: this.baseUrl2 + item.url_foto_original,
                posicion: 0,
                codigo: item.codigo
              }
            };
            this.fotoVisualizada = obj;
            this.indiceFotoVisualizada = obj.index;
            //  this.fotoVisualizada = obj;
          } else if (this.fotosExamenFisico.length === indiceBorrada && this.fotosExamenFisico.length > 0) {
            console.log(indiceBorrada);
            const item = deepClone[indiceBorrada - 1];
            console.log(item);
            obj = {
              index: indiceBorrada - 1,
              item: {
                title: item.title,
                url: this.baseUrl2 + item.url_foto_mini,
                url2: this.baseUrl2 + item.url_foto_original,
                posicion: 0,
                codigo: item.codigo
              }
            };
            this.fotoVisualizada = obj;
            this.indiceFotoVisualizada = obj.index;
          } else if (indiceBorrada === 0 && this.fotosExamenFisico.length > 1) {
            console.log(indiceBorrada);
            const item = deepClone[indiceBorrada + 1];
            console.log(item);
            obj = {
              index: indiceBorrada + 1,
              item: {
                title: item.title,
                url: this.baseUrl2 + item.url_foto_mini,
                url2: this.baseUrl2 + item.url_foto_original,
                posicion: 0,
                codigo: item.codigo
              }
            };
            this.fotoVisualizada = obj;
            this.indiceFotoVisualizada = obj.index;
          } else if (indiceBorrada > 0) {
            console.log(indiceBorrada);
            const item = deepClone[indiceBorrada - 1];
            console.log(item);
            obj = {
              index: indiceBorrada - 1,
              item: {
                title: item.title,
                url: this.baseUrl2 + item.url_foto_mini,
                url2: this.baseUrl2 + item.url_foto_original,
                posicion: 0,
                codigo: item.codigo
              }
            };
            this.fotoVisualizada = obj;
            this.indiceFotoVisualizada = obj.index;
          } else if (indiceBorrada === 0 && this.fotosExamenFisico.length === 0) {
            this.fotosExamenFisico = [0];
            this.fotoVisualizada = null;
            this.indiceFotoVisualizada = 0;

          }

        }
        // console.log({indiceFotoVisualizada: this.indiceFotoVisualizada});

        // if (deepClone.length > 0) {

        //   //Actualizar el indice de la foto actual
        //   if (Number(this.indiceFotoVisualizada) === 0) {
        //     this.indiceFotoVisualizada = Number(this.indiceFotoVisualizada) + 1;
        //   } else if (Number(this.indiceFotoVisualizada) > 0) {
        //     this.indiceFotoVisualizada = Number(this.indiceFotoVisualizada) - 1;
        //   }

        //   console.log({seg4_indiceFotoVisualizada: this.indiceFotoVisualizada});
        //   //  console.log(this.fotosExamenFisico);
        //   Object.keys(deepClone).forEach(index => {
        //     const item = deepClone[index];


        //     //Obtener foto visualizada
        //     if (Number(item.posicion) === Number(this.indiceFotoVisualizada)) {
        //       console.log(Number(this.indiceFotoVisualizada));

        //       console.log({seg5_indiceFotoVisualizada: this.indiceFotoVisualizada});

        //       const obj = {
        //         index: Number(this.indiceFotoVisualizada),
        //         item: {
        //           title: item.nombre,
        //           url: this.baseUrl2 + item.url_foto_mini,
        //           url2: this.baseUrl2 + item.url_foto_original,
        //           posicion: Number(this.indiceFotoVisualizada),
        //           codigo: item.codigo
        //         }
        //       };

        //       this.fotoVisualizada = obj;
        //       //    console.log({ IngresoAqui: this.fotoVisualizada });
        //     }

        //     //  this.fotosExamenFisico.push(obj);
        //   });


        //   // this.infoFotos = [];
        //   this.fotosExamenFisico = deepClone;
        //   //  this.fotoVisualizada = this.fotosExamenFisico[this.indiceFotoVisualizada];
        // } else {
        //   this.fotosExamenFisico = [];
        //   this.infoFotos = []
        // }


        // console.log(this.indiceFotoVisualizada);
        // console.log({ infoFotos: this.infoFotos });
        // console.log({ fotosExamenFisico: this.fotosExamenFisico });
        // debugger;
        break;

      case 'detalle':

        let idFoto = 0;
        if (Number(this.indiceFotoVisualizada) === 0) {
          idFoto = this.fotosExamenFisico[0].id;
        } else {
          idFoto = this.fotoVisualizada.item.id;
        }

        // debugger;
        if ($event === true) {
          this.apiServices.deleteData('archivos', idFoto, '?pacienteId=' + this.pacienteId).
            subscribe((data: any) => {
              // const data = response as any;
              if (data.success === true) {
                this.alertMsgFoto.showAlert = true;
                this.alertMsgFoto.class = 'alert alert-success';
                this.alertMsgFoto.msg = data.response;
                this.btnVerFotos_click(); //Cargar Nuevomente las fotos
                if (this.fotosExamenFisico !== null && this.fotosExamenFisico.length > 0) {

                  //  console.log(Number(this.indiceFotoVisualizada));
                  //Actualizar el indice de la foto actual
                  if (Number(this.indiceFotoVisualizada) === 0) {
                    this.indiceFotoVisualizada = Number(this.indiceFotoVisualizada) + 1;
                  } else if (Number(this.indiceFotoVisualizada) > 0) {
                    this.indiceFotoVisualizada = Number(this.indiceFotoVisualizada) - 1;
                  }

                  // console.log(this.fotoVisualizada);
                  //  console.log(Number(this.indiceFotoVisualizada));
                  //Obtener foto visualizada
                  Object.keys(this.fotosExamenFisico).forEach(index => {
                    const item = this.fotosExamenFisico[index];

                    if (Number(item.posicion) === Number(this.indiceFotoVisualizada)) {
                      console.log(Number(this.indiceFotoVisualizada));
                      const obj = {
                        index: Number(index),
                        item: {
                          title: item.nombre,
                          url: this.baseUrl2 + item.url_foto_mini,
                          url2: this.baseUrl2 + item.url_foto_original,
                          posicion: Number(index),
                          codigo: item.codigo
                        }
                      };

                      this.fotoVisualizada = obj;
                      //    console.log({ IngresoAqui: this.fotoVisualizada });
                    }
                  });
                }


                //  debugger;
                console.log({ fotosExamenFisico3: this.fotosExamenFisico });
                console.log({ fotoVisualizada3: this.fotoVisualizada });
                console.log({ indiceFotoVisualizada3: Number(this.indiceFotoVisualizada) });

                //debugger;
              } else {
                this.alertMsgFoto.showAlert = true;
                this.alertMsgFoto.class = 'alert alert-danger';
                this.alertMsgFoto.msg = data.response;
              }
            }, data => {
              console.log(data);
              this.alertMsgFoto.showAlert = true;
              this.alertMsgFoto.class = 'alert alert-danger';
              this.alertMsgFoto.msg = 'Error al intentar borrar la foto. Intente de nuevo.';
            }, () => {
              console.log('finally borrar foto');

              this.subiendoFoto = false;
            });
        }

        break;
    }



    return false;
  }

  btnAdjuntarFotos_click($event) {
    this.verVentanas.adjuntarFotos = true;
  }

  btnAdjuntarArchivos_click($event) {
    this.verVentanas.adjuntarArchivos = true;
  }

  itemChanged($event) {
    console.log({ itemChanged$event: $event });
    this.indiceFotoVisualizada = Number($event.index);
    console.log({ indiceFotoVisualizada_itemChanged: this.indiceFotoVisualizada })
    this.urlFotoVisualizada = $event.item.url2;
    this.fotoVisualizada = $event;
    console.log($event);
  }

  btnVerFotos_click($event?) {
    console.log(this.infoFotos);



    this.verVentanas.verFotos = true;
    console.log('this.tipoAccion btnVerFotos_click ' + this.tipoAccion);
    switch (this.tipoAccion) {

      case 'nuevo':

        if (this.infoFotos.length === 0) {
          alert('No hay fotos para visualizar');
          this.verVentanas.verFotos = false;
          return false;
        }

        console.log({ fotosExamenFisico: this.fotosExamenFisico });

        this.fotosExamenFisico = [];

        Object.keys(this.infoFotos).forEach((index) => {
          //  console.log(index);
          const item = this.infoFotos[index];
          console.log(item);

          const obj = {
            title: item.nombre,
            url: this.baseUrl2 + item.url_foto_mini,
            url2: this.baseUrl2 + item.url_foto_original,
            posicion: Number(index),
            codigo: item.codigo
          };

          this.fotosExamenFisico.push(
            obj
          );

          if (Number(index) === 0) {
            this.urlFotoVisualizada = obj.url2;
          }
        });

        // console.log(this.fotosExamenFisico);
        console.log({ fotosExamenFisico: this.fotosExamenFisico });
        break;

      case 'detalle':


        let queryString = 'pacientes/archivos/' + this.pacienteId;
        queryString += '?tipo=FOTO_EXAMEN_FISICO';
        queryString += '&origen=CONTROL';
        queryString += '&codigoasociado=' + this.codigoEvolucion;

        this.apiServices.getData(queryString, 'archivos', false).
          subscribe(data => {
            console.log(data);
            if (data.success === true && data.archivos !== null) {
              const archivos = data['Archivos'];
              this.fotosExamenFisico = [];
              if (archivos !== null) {
                Object.keys(archivos).forEach((index) => {
                  //  console.log(index);
                  const item = archivos[index];
                  const obj = {
                    title: item.nombre,
                    url: this.baseUrl2 + item.foto_thumb,
                    url2: this.baseUrl2 + item.foto,
                    posicion: Number(index),
                    codigo: item.codigo
                  };

                  this.fotosExamenFisico.push(
                    obj
                  );

                  if (Number(index) === 0) {
                    this.urlFotoVisualizada = obj.url2;
                  }
                });

                console.log({ btnVerFotos_click: this.fotosExamenFisico });
                console.log({ indiceFotoVisualizada4: Number(this.indiceFotoVisualizada) });
              }

              // console.log(this.fotosExamenFisico);;

            } else {
              this.alertMsgFoto.showAlert = true;
              this.alertMsgFoto.class = 'alert alert-danger';
              this.alertMsgFoto.msg = data.response;
            }
          }, data => {
            console.log(data);
            this.alertMsgFoto.showAlert = true;
            this.alertMsgFoto.class = 'alert alert-danger';
            this.alertMsgFoto.msg = data.error.response;
          }, () => {
            console.log('finally subirFotoExamenFisico');
            this.subiendoFoto = false;
          });
        break;
    }


    // debugger;
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    console.log({ 'state': state });
    // let queryString

    this.skip = state.skip;
    this.pageSize = state.take;
    this.sort = state.sort;

    if (this.skip === 0) {
      this.skip = 1;
    }

    let currentPage = Math.floor(this.skip / this.pageSize);
    currentPage++;

    // this.verVentanas.verArchivos = true;
    let queryString = 'pacientes/archivos/' + this.pacienteId;
    queryString += '?page=' + currentPage + '&tipo=ARCHIVO_PARA_CLINICO';
    queryString += '&origen=HISTORIA_CLINICA';
    queryString += '&codigoasociado=' + this.codigoEvolucion;

    this.btnVerArchivos_click(queryString);

  }


  btnVerArchivos_click(query: string = '') {
    //   this.infoArchivos = this.getObjectInStorage('lstArchivos');
    if (this.infoArchivos.length === 0 && this.tipoAccion === 'nuevo') {
      alert('No hay archivos para visualizar');
      return false;
    }

    this.verVentanas.verArchivos = true;
    this.cargarArchivos();

  }

  // btnConfirmarBorrarArchivo_click($event) {
  // }

  btnMostrarConfirmarBorrarArchivo_click(dataItem) {
    this.dataItem = dataItem;


    console.log(this.dataItem);
    console.log(this.gridViewArchivos.total);

    // console.log(this.fotoVisualizada);
    this.alertMsgArchivo.showAlert = false;
    this.verVentanas.verVenConBorArchivo = true;
    this.verVentanas.titVenConBorArchivo = 'Confirmación de borrado';
    this.verVentanas.tetVenConBorArchivo = '¿Está seguro de borrar el archivo?';

  }


  btnBorrarArchivoParaClinico_click($event) {
    this.verVentanas.verVenConBorArchivo = false;
    if (this.dataItem === null || this.dataItem === undefined) {
      return false;
    }

    if ($event === false) {
      return false;
    }

    switch (this.tipoAccion) {

      case 'nuevo':

        if (this.infoArchivos.length === 0) {

          return false;
        }

        console.log({ eventoBorrar: $event });
        console.log({ dataItem: this.dataItem });

        this.verVentanas.verVenConBorArchivo = false;
        this.alertMsgArchivo.showAlert = false;

        for (let i = 0; i < this.infoArchivos.length; i++) {
          // console.log(infoArchivos[i]);
          if (this.infoArchivos[i].codigo == this.dataItem.codigo) {
            this.infoArchivos.splice(i, 1);
          }
        }

        console.log({ infArchivos: this.infoArchivos });

        this.cargarArchivos();
        break;

      case 'detalle':

        const codigoArchivo = this.dataItem.codigo; //return false;
        this.apiServices.deleteData('archivos', codigoArchivo, '?pacienteId=' + this.pacienteId).
          subscribe((data: any) => {
            // const data = response as any;

            if (data.success === true) {
              this.alertMsgArchivo.showAlert = true;
              this.alertMsgArchivo.class = 'alert alert-success';
              this.alertMsgArchivo.msg = data.response;
              this.btnVerArchivos_click();

            } else {
              this.alertMsgArchivo.showAlert = true;
              this.alertMsgArchivo.class = 'alert alert-danger';
              this.alertMsgArchivo.msg = data.response;
            }

            this.verVentanas.verVenConBorArchivo = false;

          }, data => {
            console.log(data);
            this.alertMsgArchivo.showAlert = true;
            this.alertMsgArchivo.class = 'alert alert-danger';
            if (data.error.response !== undefined) {
              this.alertMsgArchivo.msg = data.error.response;
            } else {
              this.alertMsgArchivo.msg = 'Error al intentar borrar el archivo. Intente de nuevo.';
            }

          }, () => {
            console.log('finally borrar archivo');

            // this.subiendoFoto = false;
          });
        break;
    }


  }

  btnAbrirArchivoParaClinico_click(dataItem) {
    console.log(dataItem);
    let url = '';
    if (this.tipoAccion === 'detalle') {
      url = this.baseUrl2 + this.pathArchivosDetalle + dataItem.nombre_auto;
    } else if (this.tipoAccion === 'nuevo') {
      url = this.baseUrl2 + dataItem.path + dataItem.archivo;
    }

    console.log(url);
    // const url = this.baseUrl2 + dataItem.path + dataItem.archivo;
    window.open(url, '_blank');
  }



  // Subir foto y descargar https://www.youtube.com/watch?v=lEsl-B4TrG0

  cerrarVentana() {
    this.verVentanas.adjuntarFotos = false;
    this.verVentanas.adjuntarArchivos = false;
    this.alertMsgFoto.showAlert = false;
    this.alertMsgFoto.msg = '';
    this.alertMsgArchivo.showAlert = false;
    this.alertMsgArchivo.msg = '';
    this.fotoExamenFisico = undefined;
    this.archivoParaClinico = undefined;
    this.verVentanas.verFotos = false;
    this.verVentanas.verArchivos = false;
    this.verVentanas.mostrarBuscarCie10 = false;
    this.verVentanas.mostrarVentanaLogin = false;
    this.verVentanas.verVentanaConfirmarGuardar = false;
    this.verVentanas.verVentanaConfirmarCredenciales = false;
    this.verVentanas.verVentanaCredenciales = false;
  }


  seleccionFotoExamenFisico(archivo) {
    //  console.log(archivo)
    if (archivo.files) {
      this.fotoExamenFisico = archivo.files[0].rawFile;
    }
    this.alertMsgFoto.showAlert = false;
    this.alertMsgFoto.msg = '';
  }

  seleccionArchivoParaClinico(archivo) {
    //  console.log(archivo)
    if (archivo.files) {
      this.archivoParaClinico = archivo.files[0].rawFile;
    }
    this.alertMsgArchivo.showAlert = false;
    this.alertMsgArchivo.msg = '';
  }


  removerFotoExamenFisico($event) {
    this.fotoExamenFisico = undefined;
    // const file = (event.target as HTMLInputElement).files[0];
    // console.log(file);

  }

  removerArchivoParaClinico($event) {
    this.archivoParaClinico = undefined;
    // const file = (event.target as HTMLInputElement).files[0];
    // console.log(file);

  }



  btnSubirFotoExamenFisico_click() {
    this.indiceFotoVisualizada = 0;
    this.alertMsgFoto.showAlert = false;
    if (this.fotoExamenFisico !== undefined) {
      this.subiendoFoto = true;
      const fotoExamenFisico = new FormData();
      fotoExamenFisico.set('nombre', this.ngModelNombreFoto);
      fotoExamenFisico.set('archivo', this.fotoExamenFisico);
      fotoExamenFisico.set('paciente_id', '' + this.pacienteId);
      fotoExamenFisico.set('codigo_asociado', '');
      fotoExamenFisico.set('tipo', 'FOTO_EXAMEN_FISICO');
      fotoExamenFisico.set('origen', 'CONTROL');

      this.apiServices.postFormData(fotoExamenFisico, 'archivos').
        subscribe(data => {
          console.log(data);
          if (data.success === true) {
            this.alertMsgFoto.showAlert = true;
            this.alertMsgFoto.class = 'alert alert-success';
            this.alertMsgFoto.msg = data.response;

            if (data.tipo === 'FOTO_EXAMEN_FISICO') {
              this.infoFotos.push(data);
            }

            this.resetearSubirFotos();

          } else {
            this.alertMsgFoto.showAlert = true;
            this.alertMsgFoto.class = 'alert alert-danger';
            this.alertMsgFoto.msg = data.response;
          }
        }, data => {
          console.log(data);
          this.alertMsgFoto.showAlert = true;
          this.alertMsgFoto.class = 'alert alert-danger';
          this.alertMsgFoto.msg = data.error.response;
          this.subiendoFoto = false;
        }, () => {
          console.log('finally subirFotoExamenFisico');
          // this.loadingData = false;
          this.subiendoFoto = false;
        });

    } else {
      this.alertMsgFoto.showAlert = true;
      this.alertMsgFoto.class = 'alert alert-danger';
      this.alertMsgFoto.msg = 'Debe seleccionar una foto';
    }
  }



  btnSubirArchivoParaClinico_click() {
    console.log('btnSubirArchivoParaClinico_click');
    this.alertMsgArchivo.showAlert = false;
    if (this.ngModelNombreArchivo !== null || this.ngModelNombreArchivo !== undefined) {
      if (this.ngModelNombreArchivo === '' || this.ngModelNombreArchivo === undefined) {
        this.alertMsgArchivo.showAlert = true;
        this.alertMsgArchivo.class = 'alert alert-danger';
        this.alertMsgArchivo.msg = 'Debe especificar el nombre del archivo';
        return false;
      }

    } else {
      this.alertMsgArchivo.showAlert = true;
      this.alertMsgArchivo.class = 'alert alert-danger';
      this.alertMsgArchivo.msg = 'Debe especificar el nombre del archivo';
      return false;
    }

    if (this.archivoParaClinico !== undefined) {
      this.subiendoFoto = true;
      const archivoParaClinico = new FormData();
      archivoParaClinico.set('nombre', this.ngModelNombreArchivo);
      archivoParaClinico.set('archivo', this.archivoParaClinico);
      archivoParaClinico.set('paciente_id', '' + this.pacienteId);
      archivoParaClinico.set('codigo_asociado', '');
      archivoParaClinico.set('tipo', 'ARCHIVO_PARA_CLINICO');
      archivoParaClinico.set('origen', 'CONTROL');

      this.apiServices.postFormData(archivoParaClinico, 'archivos').
        subscribe(data => {
          console.log(data);
          if (data !== null && data.success === true) {

            console.log({ archivoSubido: data });

            this.alertMsgArchivo.showAlert = true;
            this.alertMsgArchivo.class = 'alert alert-success';
            this.alertMsgArchivo.msg = data.response;

            if (data.tipo === 'ARCHIVO_PARA_CLINICO') {
              this.infoArchivos.push(data);
            }
            console.log({ fotosExamenFisico: this.fotosExamenFisico });
            console.log({ infoArchivos: this.infoArchivos });
            this.resetearSubirArchivos();
            //this.resetea
            //this.reste

          } else {
            this.alertMsgArchivo.showAlert = true;
            this.alertMsgArchivo.class = 'alert alert-danger';
            this.alertMsgArchivo.msg = data.response;
          }
        }, data => {
          console.log(data);
          this.alertMsgArchivo.showAlert = true;
          this.alertMsgArchivo.class = 'alert alert-danger';
          this.alertMsgArchivo.msg = data.error.response;
          this.subiendoFoto = false;
        }, () => {
          console.log('finally btnSubirArchivoParaClinico_click');
          // this.loadingData = false;
          this.subiendoFoto = false;
        });

    } else {
      this.alertMsgArchivo.showAlert = true;
      this.alertMsgArchivo.class = 'alert alert-danger';
      this.alertMsgArchivo.msg = 'Debe seleccionar un archivo';
    }
  }




  verificarValoresRepetidos(valor, index?): boolean {
    // const strArray = [];
    // const alreadySeen = [];
    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    const lengthRows = this.cie10Rows['controls'].length;
    console.log(valor);
    for (let i = 0; i < lengthRows; i++) {
      const cie10codigoEvolucion: any = this.cie10Rows.controls[i]['controls']['cie10Codigo'] as FormControl
      if (cie10codigoEvolucion.value === valor && i !== index) {
        return true;
      }
    }

    return false;
    // return new Set(strArray).size !== strArray.length;
  }





  //   return new Set(strArray).size !== strArray.length;
  // }

  agregarFilaCie10($event = null) {
    if(this.desBtnGuardar === true) {
      return false;
    }
    if ($event !== null) {
      $event.preventDefault();
    }

    if (this.msjErrorCie10.length === 0) {

      this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
      const lengthRows = this.cie10Rows['controls'].length;
      console.log(lengthRows);
      if (lengthRows > 0) {
        for (let i = 0; i < lengthRows; i++) {
          const cie10codigoEvolucion: any = this.cie10Rows.controls[i]['controls']['cie10Codigo'] as FormControl
          if (cie10codigoEvolucion.value === '') {
            this.msjErrorCie10 = 'Debe completar el código vacío';
            console.log('Hay códigos vacios');
            break;
          }
        }
      }

      if (this.msjErrorCie10.length > 0) {
        return false;
      }
      // return false;

      this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
      this.cie10Rows.push(this.createCie10Row());
    }
  }
  // addCie10Row(item, verificarRepetidos = true) {
  //   this.cie10Rows = this.formData.get('cie10Rows') as FormArray;

  //   console.log(this.indiceCie10);

  //   if (verificarRepetidos === true) {
  //     if (this.verificarValoresRepetidos(item.codigo)) {
  //       this.msjErrorCie10 = 'Error: Código repetido';
  //       return false;
  //     } else {
  //       this.msjErrorCie10 = '';
  //     }
  //   }
  //   //  console.log(this.cie10Rows.length);

  //   const index = this.cie10Rows.length;

  //   if (this.indiceCie10 == 0) {
  //     console.log(index);
  //     //this.cie10Rows.push(this.createCie10Row());
  //     const cie10codigoEvolucion: any = this.cie10Rows.controls[this.indiceCie10]['controls']['cie10Codigo'] as FormControl;
  //     const cie10TituloControl: any = this.cie10Rows.controls[this.indiceCie10]['controls']['cie10Titulo'] as FormControl;
  //     cie10codigoEvolucion.setValue(item.codigo);
  //     cie10TituloControl.setValue(item.titulo);
  //     this.indiceCie10++;
  //   }
  //   else if (this.indiceCie10 > 0) {
  //     console.log(index);
  //     this.cie10Rows.push(this.createCie10Row());
  //     const cie10codigoEvolucion: any = this.cie10Rows.controls[this.indiceCie10]['controls']['cie10Codigo'] as FormControl;
  //     const cie10TituloControl: any = this.cie10Rows.controls[this.indiceCie10]['controls']['cie10Titulo'] as FormControl;
  //     cie10codigoEvolucion.setValue(item.codigo);
  //     cie10TituloControl.setValue(item.titulo);
  //     this.indiceCie10++;
  //   }


  //   // } else {
  //   //   console.log(index);
  //   //   //this.cie10Rows.push(this.createCie10Row());
  //   //   const cie10codigoEvolucion: any = this.cie10Rows.controls[0]['controls']['cie10Codigo'] as FormControl;
  //   //   const cie10TituloControl: any = this.cie10Rows.controls[0]['controls']['cie10Titulo'] as FormControl;
  //   //   cie10codigoEvolucion.setValue(item.codigo);
  //   //   cie10TituloControl.setValue(item.titulo);
  //   // }

  //   console.log(this.cajasDeTexto.diagnostico);
  // }

  deleteCie10Row(index: number) {
    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    if (this.cie10Rows.controls.length > 1) {
      this.cie10Rows.removeAt(index);
      this.indiceCie10--;
    } else {
      const cie10codigoEvolucion: any = this.cie10Rows.controls[0]['controls']['cie10Codigo'] as FormControl;
      // const cie10TituloControl: any = this.cie10Rows.controls[0]['controls']['cie10Titulo'] as FormControl;
      cie10codigoEvolucion.setValue('');
      // cie10TituloControl.setValue('');
      this.indiceCie10 = 0;
    }
    this.msjErrorCie10 = '';
    return false;
  }


  createCie10Row(): FormGroup {
    return this.formBuilder.group({
      cie10Codigo: [''],
      cie10Titulo: [''],
    });
  }


  // diagnostico_input($event, pregunta: string) {
  //   const valor = $event.target.value;
  //   switch (pregunta) {
  //     case 'notas':
  //       this.cajasDeTexto.diagnostico.diagnosticoNotas = valor;
  //       break;
  //     case 'diagnostico':
  //       this.cajasDeTexto.diagnostico.diagnosticoManual = valor;
  //       break;
  //   }
  //   console.log(this.cajasDeTexto.diagnostico);
  // }

  // cboCie10_change($event) {
  //   const valor = $event;
  //   if (valor !== undefined) {
  //     // this.cajasDeTexto.diagnostico.cie10 = valor;
  //   }
  //   console.log(this.cajasDeTexto.diagnostico);
  // }

  // btnVerVentanaBuscarCie10_click($event) {
  //   this.verVentanas.mostrarBuscarCie10 = true;
  // }

  // #endregion

  // #region **************************MÉTODOS PLAN
  // plan_input($event, pregunta: string) {
  //   const valor = $event.target.value;
  //   switch (pregunta) {
  //     case 'plan':
  //       this.cajasDeTexto.plan.plan = valor;
  //       break;
  //     case 'notas':
  //       this.cajasDeTexto.plan.planNotas = valor;
  //       break;
  //   }
  //   console.log(this.cajasDeTexto.plan);
  // }
  // #endregion

  // #region **************************MÉTODOS GENERALES
  // generales_input($event, pregunta: string) {
  //   const valor = $event.target.value;
  //   switch (pregunta) {
  //     case 'notas':
  //       this.cajasDeTexto.generales.notasGenerales = valor;
  //       break;
  //   }
  //   console.log(this.cajasDeTexto.generales);
  // }
  // #endregion


  // public valoresPorDefectoAntecedentes() {
  //   const entries = Object.entries(this.arrayAntPersonalesElementos)
  //   // console.log(entries);

  //   for (const [key, item] of entries) {
  //     this.formData.get(item).setValue('No aplica');
  //   }

  // }

  // public onRevisionSistemasItem_Click(dataItem: string, pregunta: string) {
  //   console.log(pregunta);
  //   console.log(dataItem);
  // }

  constructor(private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiServices: ServiciosService) {




    // console.log('aqui');
    // const navigation = this.router.getCurrentNavigation();
    // this.queryParams = navigation.extras.queryParams;
    // console.log(this.queryParams);
    // this.activatedRoute.queryParams.subscribe(params => {
    //   const pacienteId = params.pacienteId;
    //   this.pacienteId = pacienteId;
    //   console.log(pacienteId);

    // });
  }

  public onPaste(): void {
    console.log('Paste');
  }

  deshabilitarValidaciones() {

    const omitir = [
      'txtMestruacion'
    ];

    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        //  console.log({ desha: element });
        this.formData.get(element).clearValidators();
      } else {
        console.log({ habi: element });
        this.formData.get(element).setValidators([Validators.required]);
      }
      this.formData.get(element).updateValueAndValidity();
    });
  }

  habilitarValidaciones() {

    const greaterThanZero = [
      'txtTemp', 'txtPulso', 'txtFrecuencia',
      'txtTension1', 'txtTension2',
      'txtPeso', 'txtTalla'
    ];

    const omitirNotas = [
      'txtNotaAntecedentesPersonales', 'txtNotasMotivoConsulta',
      'txtNotasEnfermedadActual', 'txtDiagnosticoNotas',
      'txtNotasExamen', 'txtNotaSistemas', 'txtPlanNotas',
      'txtNotasGenerales', 'cie10Rows',
      'txtNotasSignos', 'txtNotasParaClinicos', 'txtNotasAnalisis'
    ];

    Object.keys(this.formData.controls).forEach(element => {



      if (greaterThanZero.includes(element)) {
        this.formData.get(element).setValidators([Validators.required, GreaterThanZero(true)]);
      } else if (omitirNotas.includes(element)) {
        this.formData.get(element).clearValidators();
      } else {
        this.formData.get(element).setValidators([Validators.required]);
      }

      this.formData.get(element).updateValueAndValidity();
    });
  }

  // btnAcciones_click($event) {
  //   // console.log($event);
  //   switch ($event.accion) {
  //     case 'Clausurar':
  //       console.log('clausurar');
  //       // this.habilitarValidaciones();
  //       this.verVentanas.mostrarVentanaLogin = true;
  //       console.log(this.verVentanas.mostrarVentanaLogin);
  //       break;
  //     case 'AgregarFormula':
  //       this.btnRedireccionar_click('AgregarFormula');
  //       break;
  //     case 'AgregarIncapacidad':
  //       this.btnRedireccionar_click('AgregarIncapacidad');
  //       break;
  //     case 'ListadoPacientes':
  //       this.btnRedireccionar_click('ListadoPacientes');
  //       break;
  //     case 'Imprimir':
  //       // this.deshabilitarValidaciones();
  //       break;

  //   }
  // }

  obtenerCodigoCie10() {
    this.apiServices.getData('cies', 'cies').
      subscribe(data => {
        //  console.log(data);
        this.lstCodigosCie10 = data;
      }, error => {
        console.log(error);
      }, () => {
        console.log('Finally');
      });
  }

  getData(pacienteId: number) {
    // console.log(itemId);
    if (pacienteId !== undefined && pacienteId > 0) {
      let urlParams = 'evoluciones/' + pacienteId + '?medid=all';
      //urlParams += '?historia=all';
      this.apiServices.getData(urlParams, 'Evolucion', false).subscribe(
        data => {
          console.log({ evolucionObtenido: data });
          // const item = data;
          if (data !== null && data !== undefined && data.success === true) {
            const item = data['Evolucion'];

            console.log({ item: item });
            // if (item.Control !== null) {
            this.medicoIdReg = item.Medico.id;
            this.codigoEvolucion = item.codigo;
            this.medicoDeLaEvolucion = item.Medico.nombres + ' ' + item.Medico.apellidos + ' (CC: ' + item.Medico.identificacion + ')';
            this.cargarValoresEvolucion(item);

            if (Number(item.clausurada) === 1) {
              this.clausurada = true;
              this.deshabilitarControles();
              this.desBtnGuardar = true;
            } else if (this.tipoUsuario === 'ADMIN') {
           //   console.log('Está entrando aquí 2');
              this.habilitarControles();
              this.desBtnGuardar = false;
            }else if (!this.medicoActualHizoIngreso(this.medicoId, this.medicoIdReg, this.tipoUsuario)) {
              this.deshabilitarControles();
              this.desBtnGuardar = true;
            }

            this.evolucionId = item.id;
            this.codigoEvolucion = item.codigo;
            this.tituloFormulario = 'Detalle de la evolución. Código: ' + item.codigo;
            this.registroGuardadoExiste = true;

            // this.cargarValoresHistoria(item.Control);
            //  }

            // if (item.Paciente == null) {
            //   this.registroEncontrado = false;
            //   this.mostrarMsgError('El paciente no existe');
            //   } else {

            // this.pacienteId = item.Paciente.id;
            // this.nombrePaciente = item.Paciente.nombres + ' ' + item.Paciente.apellidos + ' (' + item.Paciente.identificacion + ')';
            // this.codigoEvolucion = item.codigo;
            // this.registroEncontrado = true;
            // this.cargandoDatos = false;
            //            }

            this.getPaciente(item.paciente_id);
          } else {
            this.mostrarMsgError('Error al intentar cargar los datos de la evolución. Intente de nuevo.');
          }

          this.cargandoDatos = false;
          //  this.obtenerCodigoCie10();
        },
        dataError => {
          console.log(dataError);
          if (dataError.error.response !== undefined) {
            this.mostrarMsgError(dataError.error.response);
          } else {
            this.mostrarMsgError('Error al intentar cargar los datos de la evolución. Intente de nuevo.');
          }
          this.registroEncontrado = false;
          this.cargandoDatos = false;
          this.loadingData = true;
        },
        () => {
          this.loadingData = true;
        }
      );
    } else {

      this.mostrarMsgError('Error al intentar cargar los datos de la evolución. Intente de nuevo.');

      this.registroEncontrado = false;
      this.loadingData = true;
      this.cargandoDatos = false;
    }
  }

  medicoActualHizoIngreso(idMedicoLogin: Number, idMedicoHistoria: Number, tipoUsuario: string) {
    console.log({ idMedicoHistoria: idMedicoHistoria });
    console.log({ idMedicoLogin: idMedicoLogin });

    if (tipoUsuario === 'MEDICO' && (idMedicoHistoria === idMedicoLogin)) {
      return true; //Si es el propietario
    }
    return false;
  }

  recargarValores_click() {
    this.getData(Number(this.pacienteId));
  }

  cargarValoresEvolucion(evolucion) {
    //  console.log(historiaJson.historia_json);


    if (evolucion == undefined) {
      return false;
    }

    // #region ASIGNACIÓN DE VALORES MOTIVO DE CONSULTA
    this.textSubjetivo.setValue(evolucion.subjetivo);
    //this.textNotasMotivoConsulta.setValue(evolucion.consulta_notas);
    //#endregion

    //#region ASIGNACIÓN DE VALORES ENFERMEDAD ACTUAL
    this.textObjetivo.setValue(evolucion.objetivo);
    //this.textNotasEnfermedadActual.setValue(evolucion.enfermedad_notas);
    //#endregion

    // #region ASIGNACIÓN DE VALORES ANTECEDENTES PERSONALES
    //  const antPerRegistrados = evolucion.antecedentesPersonales;
    // const txtsPerRegistrados = this.cajasDeTexto.antecedentesPersonales;

    // this.textPatologicos.setValue(evolucion.ant_patologicos);
    // // txtsPerRegistrados.patologicos = antPerRegistrados.patologicos;

    // this.textTraumas.setValue(evolucion.ant_traumas);
    // // txtsPerRegistrados.traumas = antPerRegistrados.traumas;

    // this.textFamiliares.setValue(evolucion.ant_familiares);
    // //  txtsPerRegistrados.familiares = antPerRegistrados.familiares;

    // this.textFarmacologicos.setValue(evolucion.ant_farmacologicos);
    // // txtsPerRegistrados.farmacologicos = antPerRegistrados.farmacologicos;

    // this.textPlanificacion.setValue(evolucion.ant_planificacion);
    // // txtsPerRegistrados.planificacion = antPerRegistrados.planificacion;

    // this.textQuirurgicos.setValue(evolucion.ant_quirurgicos);
    // // txtsPerRegistrados.quirurgicos = antPerRegistrados.quirurgicos;

    // this.textAlergicos.setValue(evolucion.ant_alergicos);

    if (evolucion.alergico.trim() === 'Si') {
      // this.chkAlergiaRef.nativeElement.checked = true;
      // this.formData.get('txtAlergicos').enable();
      // this.formData.patchValue({ chkAlergiaRef: true });
      this.esAlergico = true;
    } else {
      // this.chkAlergiaRef.nativeElement.checked = false;
      this.esAlergico = false;
    }
    // txtsPerRegistrados.alergicos = antPerRegistrados.alergicos;

    // this.textToxicos.setValue(evolucion.ant_toxicos);
    // // txtsPerRegistrados.toxicos = antPerRegistrados.toxicos;

    // this.textEsquemaDeVacunacion.setValue(evolucion.ant_vacunacion);
    // // txtsPerRegistrados.esquemaVacunacion = antPerRegistrados.esquemaVacunacion;

    // this.textNotaAntecedentesPersonales.setValue(evolucion.ant_notas);
    // //  txtsPerRegistrados.notas = antPerRegistrados.notas;

    // if (evolucion.ant_mestruacion !== null && evolucion.ant_mestruacion.trim().length > 0) {
    //   this.formData.get('cboMestruacion').setValue('Si');
    //   this.textMestruacion.enable();
    //   //  txtsPerRegistrados.mestruacion.opcion = 'Si';
    //   //  txtsPerRegistrados.mestruacion.valor = antPerRegistrados.mestruacion.valor;
    //   const tempDate = new Date(evolucion.ant_mestruacion)
    //   this.textMestruacion.setValue(tempDate);
    // } else {
    //   // this.formData.get('cboMestruacion').
    //   //   txtsPerRegistrados.mestruacion.opcion = 'No';
    //   //  txtsPerRegistrados.mestruacion.valor = '';
    //   this.formData.get('cboMestruacion').setValue('No');
    //   this.textMestruacion.disable();
    // }

    // console.log(Number(antPerRegistrados.ginecoObstetricos.gravidez));
    // this.formData.get('txtGravidez').setValue(Number(evolucion.ant_gineco_gravidez));
    // this.formData.get('txtPartos').setValue(Number(evolucion.ant_gineco_partos));
    // this.formData.get('txtVivos').setValue(Number(evolucion.ant_gineco_vivos));
    // this.formData.get('txtAbortos').setValue(Number(evolucion.ant_gineco_abortos));
    // this.formData.get('txtCesareas').setValue(Number(evolucion.ant_gineco_cesareas));
    //#endregion

    //#region ASIGNACIÓN DE VALORES REVISIÓN POR SISTEMAS
    // const signosVitales = evolucion.signosVitales;
    // console.log(revisionSistemas);

    this.textTemp.setValue(Number(evolucion.signos_temp));
    this.textPulso.setValue(Number(evolucion.signos_pulso));
    this.textFrecuencia.setValue(Number(evolucion.signos_frecuencia));
    this.textTalla.setValue(Number(evolucion.signos_talla));
    this.textTension1.setValue(Number(evolucion.signos_tension1));
    this.textTension2.setValue(Number(evolucion.signos_tension2));
    this.textPeso.setValue(Number(evolucion.signos_peso));
    this.textImc.setValue(Number(evolucion.signos_imc));
    // this.textNotasSignos.setValue(evolucion.signos_notas);
    //#endregion

    //#region ASIGNACIÓN DE VALORES REVISIÓN POR SISTEMAS
    // this.textSistemaVisual.setValue(evolucion.sistema_visual);
    // this.textSistemaDigestivo.setValue(evolucion.sistema_digestivo);
    // this.textSistemaEsqueletico.setValue(evolucion.sistema_esqueletico);
    // this.textSistemaAuditivo.setValue(evolucion.sistema_auditivo);
    // this.textSistemaMuscular.setValue(evolucion.sistema_muscular);
    // this.textSistemaNervioso.setValue(evolucion.sistema_nervioso);
    // this.textNotaSistemas.setValue(evolucion.sistema_notas);
    // if (evolucion.sistema_tegumentario.trim().length === 0) {
    //   this.comboSistemaTegumentario.setValue('I');
    // } else {
    //   this.comboSistemaTegumentario.setValue(evolucion.sistema_tegumentario);
    // }
    //#endregion

    //#region ASIGNACIÓN DE VALORES EXAMEN FISÍCO
    // const examenFisico = evolucion.examenFisico;
    // console.log(examenFisico);
    // this.textTercioSuperior.setValue(evolucion.examen_ter_sup);
    // this.textTercioMEDICO.setValue(evolucion.examen_ter_med);
    // this.textTercioInferior.setValue(evolucion.examen_ter_inf);
    // this.textCuello.setValue(evolucion.examen_cuello);
    // this.textMamas.setValue(evolucion.examen_mamas);
    // this.textAbdomen.setValue(evolucion.examen_abdomen);
    // this.textAnterior.setValue(evolucion.examen_anterior);
    // this.textPosterior.setValue(evolucion.examen_posterior);
    // this.textGluteos.setValue(evolucion.examen_gluteos);
    // this.textMiembroSuperior.setValue(evolucion.examen_mien_sup);
    // this.textMiembroInferior.setValue(evolucion.examen_mien_inf);
    // this.textGenitales.setValue(evolucion.examen_genitales);
    // this.textNotasExamen.setValue(evolucion.examen_notas);
    //#endregion

    //#region ASIGNACIÓN DE VALORES PARA-CLINICOS
    // this.textParaClinicos.setValue(evolucion.para_paraclinicos);
    // this.textNotasClinicos.setValue(evolucion.para_notas);
    //#endregion

    //#region ASIGNACIÓN DE VALORES ANÁLISIS
    // console.log(analisis);
    this.textAnalisis.setValue(evolucion.analisis_analisis);
    this.textNotasAnalisis.setValue(evolucion.analisis_notas);
    //#endregion

    if (evolucion.clausurada === 0) {
      this.clausurada = false;
    } else if (evolucion.clausurada === 1) {
      this.clausurada = true;
    }

    //#region ASIGNACIÓN DE VALORES DIAGNOSTICO
    // const diagnostico = evolucion.diagnostico;

    // console.log(Object.keys(evolucion.diagnosticos_cie10).length);
    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    //  this.indiceCie10 = this.cie10Rows.length;
    // while (this.cie10Rows.length > 0) {
    //   this.cie10Rows.removeAt(0)
    // }
    //https://stackoverflow.com/questions/41852183/angular-2-remove-all-items-from-a-formarray


    this.indiceCie10 = 0;

    if (evolucion.diagnosticos_cie10.trim().length > 0 && this.isJson(evolucion.diagnosticos_cie10)) {

      const diagnosticos_cie10 = JSON.parse(evolucion.diagnosticos_cie10);

      if (Object.keys(diagnosticos_cie10).length > 0) {
        const arr = <FormArray>this.formData.controls.cie10Rows;
        arr.controls = [];
        // const itemCie10 = diagnostico.diagnosticos_cie10;
        Object.keys(diagnosticos_cie10).forEach(index => {
          // const itemCie10 = evolucion.diagnosticos_cie10;
          console.log({ indiceCie10: index })
          // if (Number(index) === 0) {
          this.cie10Rows.push(this.createCie10Row());
          const cie10Codigoevolucion: any = this.cie10Rows.controls[Number(index)]['controls']['cie10Codigo'] as FormControl;
          // const cie10Tituloevolucion: any = this.cie10Rows.controls[Number(index)]['controls']['cie10Titulo'] as FormControl;
          cie10Codigoevolucion.setValue(diagnosticos_cie10[Number(index)].codigo + ' - ' + diagnosticos_cie10[Number(index)].titulo);
          // cie10Tituloevolucion.setValue(diagnosticos_cie10[Number(index)].titulo);
          this.indiceCie10++;
        });

      }
    }

    this.textDiagnostico.setValue(evolucion.diagnostico_manual);
    this.textdiagnosticoNotas.setValue(evolucion.diagnostico_notas);
    // #endregion

    //#region ASIGNACIÓN DE VALORES PLAN
    // const plan = evolucion.plan;
    // console.log(plan);
    this.textPlan.setValue(evolucion.plan_plan);
    this.textPlanNotas.setValue(evolucion.plan_notas);
    //#endregion


    //#region ASIGNACIÓN DE VALORES GENERALES
    // const generales = evolucion.generales;
    // console.log(generales);
    this.textNotasGenerales.setValue(evolucion.general_notas);
    //#endregion

    //this.loadingData = true;


  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  btnRedireccionar_click(url: string) {
    // this.verVentanaDetallePaciente = true;
    const queryParams = {
      pacienteId: this.pacienteId
    };
    this.router.navigate([url], { queryParams });
    return false;
  }

  isObject(item) {
    return (typeof item === 'object' && !Array.isArray(item) && item !== null);
  }



  ngOnInit(): void {

    this.cargandoDatos = true;

    this.formData = this.formBuilder.group({
      // Antecedentes médicos personales
      // cboPatologicos: [''],
      // txtPatologicos: ['No', [Validators.required]],
      // chkAlergiaRef: [''],

      // //  cboFamiliares: [''],
      // txtFamiliares: ['No', [Validators.required]],

      // // cboTraumas: [''],
      // txtTraumas: ['No', [Validators.required]],

      // // cboQuirurgicos: [''],
      // txtQuirurgicos: ['No', [Validators.required]],

      // // cboFarmacologicos: [''],
      // txtFarmacologicos: ['No', [Validators.required]],

      // //  cboAlergicos: [''],
      // txtAlergicos: [{ value: 'No', disabled: true }, [Validators.required]],

      // //  cboToxicos: [''],
      // txtToxicos: ['No', [Validators.required]],
      // // textPlanificacion: ['No', [Validators.required]],

      // txtGravidez: [''],
      // txtPartos: [''],
      // txtVivos: [''],
      // txtAbortos: [''],
      // txtCesareas: [''],
      // txtFoto: [''],

      // ginecoRows: this.formBuilder.array([this.createGinecoItem()]),
      cie10Rows: this.formBuilder.array([this.createCie10Row()]),

      // // cboEsquemaDeVacunacion: [''],
      // txtEsquemaDeVacunacion: ['No', Validators.required],

      // cboMestruacion: ['No', [Validators.required]],
      // txtMestruacion: [{ value: '', disabled: true }, [Validators.required]],

      // //  cboPlanificacion: [''],
      // txtPlanificacion: ['No', [Validators.required]],

      // txtNotaAntecedentesPersonales: [''],

      // // Revisión de sintomas por sistemas
      // // cboSistemaVisual: [''],
      // txtSistemaVisual: ['No', [Validators.required]],

      // // cboSistemaAuditivo: [''],
      // txtSistemaAuditivo: ['No', [Validators.required]],

      // // cboSistemaDigestivo: [''],
      // txtSistemaDigestivo: ['No', [Validators.required]],

      // // cboSistemaMuscular: [''],
      // txtSistemaMuscular: ['No', [Validators.required]],

      // //  cboSistemaEsqueletico: [''],
      // txtSistemaEsqueletico: ['No', [Validators.required]],

      // // cboSistemaNervioso: [''],
      // txtSistemaNervioso: ['No', [Validators.required]],

      // cboSistemaTegumentario: [{ value: 'No', disabled: false }, [Validators.required]],

      // txtNotaSistemas: [''],

      // Signos vitales
      txtTemp: ['', [Validators.required, GreaterThanZero(true)]],
      txtPulso: ['', [Validators.required, GreaterThanZero(true)]],
      txtFrecuencia: ['', [Validators.required, GreaterThanZero(true)]],
      txtTension1: ['', [Validators.required, GreaterThanZero(true)]],
      txtTension2: ['', [Validators.required, GreaterThanZero(true)]],
      txtPeso: ['', [Validators.required, GreaterThanZero(true)]],
      txtTalla: ['', [Validators.required, GreaterThanZero(true)]],
      txtImc: [''],
      txtNotasSignos: [''],

      // Motivo de consulta
      txtSubjetivo: ['No', [Validators.required]],
      txtNotasMotivoConsulta: [''],

      // Enfermedad Actual
      txtObjetivo: ['No', [Validators.required]],
      txtNotasEnfermedadActual: [''],

      // // Examen fisíco
      // txtTercioSuperior: ['No', [Validators.required]],
      // txtTercioMEDICO: ['No', [Validators.required]],
      // txtTercioInferior: ['No', [Validators.required]],
      // txtPosterior: ['No', [Validators.required]],
      // txtCuello: ['No', [Validators.required]],
      // txtMamas: ['No', [Validators.required]],
      // txtAbdomen: ['No', [Validators.required]],
      // txtAnterior: ['No', [Validators.required]],
      // txtGluteos: ['No', [Validators.required]],
      // txtMiembroSuperior: ['No', [Validators.required]], //Posterior
      // txtMiembroInferior: ['No', [Validators.required]], //Anterior
      // //   txtNotasExamenFisico: [''],
      // txtGenitales: ['No', [Validators.required]],
      // txtNotasExamen: [''],

      // Paraclinicos
      txtParaClinicos: ['No', [Validators.required]],
      txtNotasParaClinicos: [''],

      // Análisis
      txtAnalisis: ['No', [Validators.required]],
      txtNotasAnalisis: [''],

      // Diagnostico
      txtDiagnostico: ['No', [Validators.required]],
      txtDiagnosticoNotas: [''],

      // Plan
      txtPlan: ['No', [Validators.required]],
      txtPlanNotas: [''],

      // Notas generales
      txtNotasGenerales: ['']


    });

    // this.valoresPorDefectoAntecedentes();

    // this.formData.get('txtGravidez').setValue(0);
    // this.formData.get('txtPartos').setValue(0);
    // this.formData.get('txtVivos').setValue(0);
    // this.formData.get('txtAbortos').setValue(0);
    // this.formData.get('txtCesareas').setValue(0);

    this.formData.get('txtTemp').setValue(0);
    this.formData.get('txtPulso').setValue(0);
    this.formData.get('txtPeso').setValue(0);
    this.formData.get('txtTension1').setValue(0);
    this.formData.get('txtTension2').setValue(0);
    this.formData.get('txtFrecuencia').setValue(0);
    this.formData.get('txtTalla').setValue(0);
    this.formData.get('txtImc').setValue(0);

    this.tipoUsuario = this.apiServices.getTipoUsuario();
    const userInfo = this.apiServices.getUserInfoFromToken();
    this.userInfo = userInfo.userInfo;

    if (this.tipoUsuario === 'MEDICO') {
      this.medicoId = userInfo.userInfo.id;
    } else {
      this.medicoId = 0;
    }
    console.log(userInfo);

  }

  resetearSubirArchivos() {
    this.ngModelNombreArchivo = '';
    this.ngModelArchivo = [];
    this.archivoParaClinico = undefined;
  }

  resetearSubirFotos() {
    this.ngModelNombreFoto = '';
    this.ngModelFoto = [];
    this.fotoExamenFisico = undefined;
  }

  // cargarValoresEvolucion(): void {

  // }

  cargarValoresPorDefecto(): void {
    console.log(this.codigosArchivosAgregados);
    console.log(this.infoArchivos);
    console.log(this.infoFotos);
    //  this.tituloFormulario = 'Registrar Nueva evolución';
    this.dataAcciones = this.dataAccionesesGuardar();
    this.nuevoRegistro = true;
    this.tipoAccion = 'nuevo';
    this.codigosArchivosAgregados = [];
    this.codigosFotosAgregadas = [];
    this.infoArchivos = [];
    this.infoFotos = [];
    this.fotosExamenFisico = [];
    this.alertMsg.showAlert = false;
    this.tituloFormulario = 'Registrar Nueva evolución';

    this.desBtnGuardar = false;
    this.codigoEvolucion = 0;
    this.clausurada = false;
    this.submitted = false;
    //this.habilitarControles();

    //    this.getPaciente(this.pacienteId);

    // this.formData.get('txtPatologicos').setValue('No');
    // this.formData.patchValue({ chkAlergiaRef: false });

    this.formData.get('txtSubjetivo').setValue('No');
    this.formData.get('txtObjetivo').setValue('No');
    // this.formData.get('txtTercioSuperior').setValue('No');
    // this.formData.get('txtTercioMEDICO').setValue('No');
    // this.formData.get('txtTercioInferior').setValue('No');

    // this.formData.get('txtPosterior').setValue('No');
    // this.formData.get('txtCuello').setValue('No');
    // this.formData.get('txtMamas').setValue('No');
    // this.formData.get('txtAbdomen').setValue('No');
    // this.formData.get('txtAnterior').setValue('No');
    // this.formData.get('txtGluteos').setValue('No');
    // this.formData.get('txtMiembroSuperior').setValue('No');
    // this.formData.get('txtMiembroInferior').setValue('No');
    // this.formData.get('txtGenitales').setValue('No');

    this.formData.get('txtParaClinicos').setValue('No');
    this.formData.get('txtAnalisis').setValue('No');
    this.formData.get('txtDiagnostico').setValue('No');
    this.formData.get('txtPlan').setValue('No');
    this.formData.get('txtNotasGenerales').setValue('');


    //Limpiar códigos CIE10
    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    this.clearFormArray(this.cie10Rows);
    this.agregarFilaCie10(null);

    // this.formData.get('txtGravidez').setValue(0);
    // this.formData.get('txtPartos').setValue(0);
    // this.formData.get('txtVivos').setValue(0);
    // this.formData.get('txtAbortos').setValue(0);
    // this.formData.get('txtCesareas').setValue(0);

    this.formData.get('txtTemp').setValue(0);
    this.formData.get('txtPulso').setValue(0);
    this.formData.get('txtPeso').setValue(0);
    this.formData.get('txtTension1').setValue(0);
    this.formData.get('txtTension2').setValue(0);
    this.formData.get('txtFrecuencia').setValue(0);
    this.formData.get('txtTalla').setValue(0);
    this.formData.get('txtImc').setValue(0);


    window.scroll(0, 0);
  }


  ngAfterContentInit(): void {

    this.tipoUsuario = this.apiServices.getTipoUsuario();
    const userInfo = this.apiServices.getUserInfoFromToken();

    if (this.tipoUsuario === 'MEDICO') {
      this.medicoId = userInfo.userInfo.id;
    } else {
      this.medicoId = 0;
    }


    this.deshabilitarValidaciones();

    this.activatedRoute.queryParams.subscribe(params => {
      const tipo = params.tipo;
      const evolucionId = params.evolucionId;
      const pacienteId = params.pacienteId;
      console.log({ paciente_id: this.isInt(pacienteId) });
      this.registroEncontrado = false;

      this.tipoAccion = tipo;
      this.pacienteId = pacienteId;
      this.evolucionId = evolucionId;
      console.log({ tipoRecibido: tipo });
      //this.tipoAccion = params.tipo;

      if (tipo === 'nuevo') {
        // this.tipoAccion = tipo;

        this.nuevoRegistro = true;
        this.tituloFormulario = 'Registrar evolución';
        this.dataAcciones = this.dataAccionesesGuardar();
        //  this.cargarValoresPorDefecto();

      } else if (tipo === 'detalle') {
        //  this.tipoAccion = tipo;

        this.nuevoRegistro = false;
        this.dataAcciones = this.dataAccionesesEditar();
      } else {
        this.errorDeCarga = true;
        //   this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar determinar el tipo de acción a realizar.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      }

      if (pacienteId === undefined && evolucionId === undefined) {
        this.errorDeCarga = true;
        //   this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe 1.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      } else if (this.isInt(pacienteId) === false && tipo == 'nuevo') {
        this.errorDeCarga = true;
        console.log({ tipoRecibido2: tipo });
        //  this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe 2.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      } else if (this.isInt(evolucionId) === false && tipo == 'detalle') {
        this.errorDeCarga = true;
        //  this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar cargar los datos. El ID de la Formula es incorrecto o no existe 3.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      }

      if (this.nuevoRegistro === false && this.errorDeCarga === false) {
        this.cargandoDatos = true;
        this.getData(this.evolucionId);
        this.tipoUsuario = this.apiServices.getTipoUsuario();
        //  console.log({ tipoUsuario: this.apiServices.getTipoUsuario() });
      } else if (this.nuevoRegistro === true && this.errorDeCarga === false) {
        this.getPaciente(this.pacienteId);
      }

      console.log({ nuevoRegistro: this.nuevoRegistro });
    });



    // this.formData.get('txtTiempo').disable();

  }
  isInt(value) {
    return Number.isInteger(Number(value));
  }

  getPaciente(id: number) {

    console.log({ getPacienteId: id });

    if (id > 0) {
      let urlParams = 'pacientes/' + id;
      // urlParams += 'id/' + pacienteId;
      urlParams += '?grpsa=all&estciv=all';
      urlParams += '&genero=all&ocup=all&ciures=all&depres=all&countryid=all';


      this.apiServices.getData(urlParams, 'Paciente', false)
        .subscribe(data => {

          if (data.success === true) {
            this.dataPaciente = data['Paciente'];
            this.dataPaciente['fecha_actual'] = data.fecha_actual;
            this.pacienteId = this.dataPaciente.id;
            // const fechaActual = data.fecha_actual;
            console.log(data);
            this.datosPacienteCargados = true;

            console.log(this.dataPaciente.alergico);

            if (this.dataPaciente.alergico.trim() === 'Si') {
              this.esAlergico = true;
            } else {
              this.esAlergico = false;
            }

            this.registroEncontrado = true;

          } else {
            this.registroEncontrado = false;

            let msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe.';
            //   msg += 'Debe verificar que el formulario no contenga errores';
            this.mostrarMsgError(msg);
          }

          this.cargandoDatos = false;
        }, error => {
          this.cargandoDatos = false;
          this.registroEncontrado = false;
          console.log(error);

          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError('Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe.');
          }

        }, () => {
          console.log('Finally');
        });
    } else {
      //  this.cargandoDatos = false;
      this.registroEncontrado = false;
      this.cargandoDatos = false;
      let msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe.';
      //   msg += 'Debe verificar que el formulario no contenga errores';
      this.mostrarMsgError(msg);
    }

  }


  mostrarMsgError(msg: string) {
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  /******************************** SUBMIT FORM **********************************/
  clausuararHistoria() {
    console.log('clausuararHistoria');
    this.habilitarValidaciones();
    console.log(this.formData);
    //console.log(this.cajasDeTexto)

    this.submitted = true;
    if (this.formData.invalid) {
      let msg = 'El formulario que intenta enviar contiene errores. ';
      msg += 'Debe corregir los errores indicados e intentar guardar de nuevo';
      this.mostrarMsgError(msg);
      window.scroll(0, 0);
      return false;
    }

    //this.cajasDeTexto.generales.clausurar = 'Si';

    this.enviarDatos();

  }

  // 1 - Guardar
  btnMostrarConfirmarNuevo_click() {
    this.deshabilitarValidaciones();
    //  console.log({guardarClausurarEditar: guardarClausurarEditar});
    //Es un nuevo registro
    this.guardarClausurarEditar = 'guardar';

    //this.tipoAccion = 'nuevo';
    //Para determinar si es clausurar o guardar
    // this.guardarClausurarEditar = guardarClausurarEditar;
    if (this.tipoUsuario === 'MEDICO') {
      this.medicoId = this.userInfo.id;
    }

    this.verVentanas.verVentanaConfirmarGuardar = true;
    this.verVentanas.titVenCon = 'Confirmación de acción';
    this.verVentanas.tetVenCon = '¿Está seguro(s)?';
  }
  //1.1 

  btnMostrarConfirmarClausurar_click() {


    if (this.tipoUsuario === 'MEDICO') {
      this.medicoId = this.userInfo.id;
    }
    //    console.log({guardarClausurarEditar: guardarClausurarEditar});
    //Es un nuevo registro
    // this.tipoAccion = 'clausurar'; //Guardar
    //Para determinar si es clausurar o guardar
    this.guardarClausurarEditar = 'clausurar';
    this.saltarValidacion = false;
    this.verVentanas.verVentanaConfirmarGuardar = true;
    this.verVentanas.titVenCon = 'Confirmación de acción';
    this.verVentanas.tetVenCon = '¿Está seguro(s)?';
  }

  // 1.2
  btnMostrarConfirmarEditar_click() {

    this.cerrarVentana();
    this.alertMsg.showAlert = false;
    this.deshabilitarValidaciones();


    /****** VALIDAR FORMULARIO ********/

    // this.msgNotificacion = '';
    // if (this.tipoAccion === 'editar') {
    //   this.url = 'evoluciones/' + this.evolucionId;
    //   this.msgNotificacion = 'editar';
    // } else {
    //   this.msgNotificacion = 'guardar';
    // }

    // console.log(this.formData);

    // this.submitted = true;
    // if (this.formData.invalid) {
    //   this.msgNotificacion = 'Error al intentar ' + this.msgNotificacion + ' la evolución médica. ';
    //   this.msgNotificacion += 'Verifique que el formulario no contenga errores, he intente de nuevo';
    //   this.mostrarMsgError(this.msgNotificacion);
    //   window.scroll(0, 0);
    //   return false;
    // }


    if (this.tipoUsuario === 'MEDICO') {
      this.medicoId = this.userInfo.id;
    }
    /****** FIN VALIDAR FORMULARIO */

    // this.tipoAccion = 'editar';
    this.guardarClausurarEditar = 'guardar';
    this.verVentanas.verVentanaConfirmarGuardar = true;
    this.verVentanas.titVenCon = 'Confirmación de acción';
    this.verVentanas.tetVenCon = '¿Está seguro(s)?';
  }

  // 2
  btnConfimarGuardar_click($event) {

    console.log($event);
    this.cerrarVentana();
    this.alertMsg.showAlert = false;

    if ($event === false) {
      return false;
    }

    // this.habilitarValidaciones();

    console.log(this.guardarClausurarEditar)
    if (this.guardarClausurarEditar === 'clausurar') {
      this.habilitarValidaciones();
    } else {
      console.log('deshabilitarValidaciones....');
      this.deshabilitarValidaciones();
    }

    let msg = '';
    if ((this.guardarClausurarEditar === 'guardar' || this.guardarClausurarEditar === 'editar')
      && this.tipoUsuario === 'MEDICO') {
      this.saltarValidacion = true;
    }

    console.log(this.formData);

    this.submitted = true;
    if (this.formData.invalid) {
      this.msgNotificacion = 'Error al intentar enviar el formulario. ';
      this.msgNotificacion += 'Verifique que el formulario no contenga errores, he intente de nuevo';
      this.mostrarMsgError(this.msgNotificacion);
      window.scroll(0, 0);
      return false;
    }

    /****** VALIDAR FORMULARIO ********/
    if ((this.guardarClausurarEditar === 'guardar' || this.guardarClausurarEditar === 'editar')
      && this.tipoUsuario == 'ADMIN') {
      //  let msg = 'Se ha detectado que ';
      msg = 'Se ha detectado que ';
      msg += 'su rol no es Médico. Para poder guardar <br />';
      msg += 'primero debe ingresar las credenciales del médico que será asociado a los cambios realizados. <br />';
      msg += 'Presione el botón Si para continuar.';
      this.msgNotificacion = 'guardar los cambios en el';
      this.tituloBotonCredenciales = 'Guardar cambios';

    } else if (this.guardarClausurarEditar === 'clausurar' && this.tipoUsuario == 'ADMIN') {
      msg = 'Se ha detectado que ';
      msg += 'su rol no es Médico. Para poder clausurar <br />';
      msg += 'primero debe ingresar las credenciales del médico que será asociado al cierre de la evolución. <br />';
      msg += 'Presione el botón Si para continuar.';
      this.tituloVentanaCredenciales = 'Clausurar evolución';
      this.tituloBotonCredenciales = 'Clausurar';
      this.msgNotificacion = 'clausurar';

    } else if (this.guardarClausurarEditar === 'clausurar' && this.tipoUsuario === 'MEDICO') {
      msg = '';
      msg += 'Para poder clausurar <br />';
      msg += 'primero debe ingresar sus credenciales (usuario y contraseña). <br />';
      msg += 'Presione el botón Si para continuar.';
      this.tituloVentanaCredenciales = 'Clausurar evolución';
      this.tituloBotonCredenciales = 'Clausurar';
      this.msgNotificacion = 'clausurar';
    }


    /****** FIN VALIDAR FORMULARIO */

    if ((this.tipoUsuario === 'MEDICO' || this.tipoUsuario === 'ADMIN') && this.saltarValidacion === false) {
      this.verVentanas.verVentanaConfirmarCredenciales = true;
      this.verVentanas.titentanaConfirmarCredenciales = 'Notificación';
      this.verVentanas.tetVentanaConfirmarCredenciales = msg;
      // } else if ($event === true && this.tipoUsuario === 'MEDICO' && this.saltarValidacion === false) { 
      //   this.verVentanas.verVentanaConfirmarCredenciales = true;
      //   this.verVentanas.titentanaConfirmarCredenciales = 'Notificación';
      //   this.verVentanas.tetVentanaConfirmarCredenciales = msg;
    } else if ($event === true) {
      this.enviarDatos();
    }


  }

  // 3
  btnConfirmarCredenciales_click($event) {
    // console.log($event);
    if ($event === true) {
      this.cerrarVentana();
      this.verVentanas.verVentanaCredenciales = true;
    } else {
      this.cerrarVentana();
    }
  }

  // 4
  btnLogin_click($event) {
    console.log($event);
    if ($event !== null) {
      this.cerrarVentana();
      this.medicoId = $event.id;
      this.saltarValidacion = true;
      this.enviarDatos();
    }
  }


  enviarDatos() {

    //console.log(guardarClausurarEditar);
    //  this.submitted = true;
    this.accionEjecutandose = true;
    const formData = new FormData();



    //******** */
    formData.append('paciente_id', '' + this.pacienteId)
    // Consulta
    // const consulta = this.cajasDeTexto.motivoConsulta;
    formData.append('subjetivo', this.textSubjetivo.value);
    // formData.append('consulta_notas', this.textNotasMotivoConsulta.value);
    // Enfermedad actual
    // const enfermedadActual = this.cajasDeTexto.enfermedadActual;
    formData.append('objetivo', this.textObjetivo.value);
    //console.log(this.textNotasEnfermedadActual.value);
    //formData.append('enfermedad_notas', this.textNotasEnfermedadActual.value);
    // Antecedentes
    //  const antecedentes = this.cajasDeTexto.antecedentesPersonales;
    // formData.append('ant_patologicos', this.textPatologicos.value);
    // formData.append('ant_traumas', this.textTraumas.value);
    // formData.append('ant_familiares', this.textFamiliares.value);
    // formData.append('ant_quirurgicos', this.textQuirurgicos.value);
    // formData.append('ant_farmacologicos', this.textFarmacologicos.value);

    //  console.log(this.comboMestruacion.value);
    //  console.log(this.comboMestruacion.value.length);

    // if (this.comboMestruacion.value === 'Si') {
    //   // const tempData = new Date(antecedentes.mestruacionValor);
    //   formData.append('ant_mestruacion', this.textMestruacion.value.toISOString());
    // } else {
    //   formData.append('ant_mestruacion', '');
    // }

    // formData.append('ant_alergicos', this.textAlergicos.value);
    if (this.esAlergico) {
      formData.append('alergico', 'Si');
    } else {
      formData.append('alergico', 'No');
    }

    // formData.append('ant_toxicos', this.textToxicos.value);
    // formData.append('ant_vacunacion', this.textEsquemaDeVacunacion.value);
    // formData.append('ant_planificacion', this.textPlanificacion.value);
    // formData.append('ant_gineco_gravidez', this.textGravidez.value);
    // formData.append('ant_gineco_partos', this.textPartos.value);
    // formData.append('ant_gineco_vivos', this.textVivos.value);
    // formData.append('ant_gineco_abortos', this.textAbortos.value);
    // formData.append('ant_gineco_cesareas', this.textCesareas.value);
    // formData.append('ant_notas', this.textNotaAntecedentesPersonales.value);
    // // Revisión de sistemas
    // formData.append('sistema_visual', this.textSistemaVisual.value);
    // formData.append('sistema_auditivo', this.textSistemaAuditivo.value);
    // formData.append('sistema_digestivo', this.textSistemaDigestivo.value);
    // formData.append('sistema_muscular', this.textSistemaMuscular.value);
    // formData.append('sistema_esqueletico', this.textSistemaEsqueletico.value);
    // formData.append('sistema_nervioso', this.textSistemaNervioso.value);
    // formData.append('sistema_tegumentario', this.comboSistemaTegumentario.value);
    // formData.append('sistema_notas', this.textNotaSistemas.value);
    // Signos vitales
    formData.append('signos_temp', this.textTemp.value);
    formData.append('signos_pulso', this.textPulso.value);
    formData.append('signos_frecuencia', this.textFrecuencia.value);
    formData.append('signos_tension1', this.textTension1.value);
    formData.append('signos_tension2', this.textTension2.value);
    formData.append('signos_peso', this.textPeso.value);
    formData.append('signos_talla', this.textTalla.value);
    formData.append('signos_imc', this.textImc.value);
    formData.append('signos_notas', this.textNotasSignos.value);

    // Examen físico
    // formData.append('examen_ter_sup', this.textTercioSuperior.value);
    // formData.append('examen_ter_med', this.textTercioMEDICO.value);
    // formData.append('examen_ter_inf', this.textTercioInferior.value);
    // console.log(this.textCuello.value);
    // formData.append('examen_cuello', this.textCuello.value);
    // formData.append('examen_anterior', this.textAnterior.value);
    // formData.append('examen_posterior', this.textPosterior.value);
    // formData.append('examen_abdomen', this.textAbdomen.value);
    // formData.append('examen_mien_sup', this.textMiembroSuperior.value);
    // formData.append('examen_mien_inf', this.textMiembroInferior.value);
    // formData.append('examen_genitales', this.textGenitales.value);
    // formData.append('examen_gluteos', this.textGluteos.value);
    // formData.append('examen_notas', this.textNotasExamen.value);
    // formData.append('examen_mamas', this.textMamas.value);

    // Para-clínicos
    // const paraClinicos = this.cajasDeTexto.paraClinicos;
    formData.append('para_paraclinicos', this.textParaClinicos.value);
    // formData.append('para_notas', this.textNotasClinicos.value);

    // Análisis
    // const analisis = this.cajasDeTexto.analisis;
    formData.append('analisis_analisis', this.textAnalisis.value);
    //  formData.append('analisis_notas', this.textNotasAnalisis.value);

    // Diagnostico
    // const diagnostico = this.cajasDeTexto.diagnostico;

    formData.append('diagnosticos_cie10', JSON.stringify(this.agregarValoresCie10()));
    formData.append('fotos_examen_fisico', JSON.stringify(this.agregarCodigosFotos()));
    formData.append('archivos_plan', JSON.stringify(this.agregarCodigosArchivos()));
    formData.append('diagnostico_manual', this.textDiagnostico.value);
    //  formData.append('diagnostico_notas', this.textDiagnosticoNotas.value);

    // Plan
    // const plan = this.cajasDeTexto.plan;
    formData.append('plan_plan', this.textPlan.value);
    formData.append('medico_id', '' + this.medicoId);
    formData.append('codigo_evolucion', '' + this.codigoEvolucion);
    //  formData.append('plan_notas', this.textPlanNotas.value);

    // General
    // const generales = this.cajasDeTexto.generales;
    formData.append('general_notas', this.textNotasGenerales.value);

    // formData.append('paciente_id', this.pacienteId)

    //************* */
    let url = 'evoluciones';
    console.log(this.guardarClausurarEditar);
    if (this.tipoAccion === 'nuevo') {
      if (this.guardarClausurarEditar === 'clausurar') {
        formData.append('accion', 'clausurar');
      } else {
        formData.append('accion', 'guardar');
      }
    } else if (this.tipoAccion === 'detalle') {
      formData.append('_method', 'PUT');
      if (this.guardarClausurarEditar === 'clausurar') {
        formData.append('accion', 'clausurar');
      } else {
        formData.append('accion', 'editar');
      }
      url = url + '/' + this.evolucionId;
    }

    this.apiServices.postFormData(formData, url)
      .subscribe(data => {
        console.log(data);
        this.alertMsg.showAlert = true;
        if (data !== null && data.success === true) {
          this.alertMsg.class = 'alert alert-success';
          if (this.guardarClausurarEditar === 'clausurar') {
            this.clausurada = true;
            this.desBtnGuardar = true;
            this.deshabilitarControles();
          } else {
            this.clausurada = false;
            this.desBtnGuardar = false;
            this.habilitarControles();
          }

          this.msjErrorCie10 = '';
          this.alertMsg.msg = data.response + '. Código: ' + data.codigo;
          this.codigoEvolucion = data.codigo;
          this.evolucionId = data.id;
          this.registroGuardadoExiste = true;

        } else {
          this.alertMsg.class = 'alert alert-danger';
          this.alertMsg.msg = 'Error al intentar procesar la evolución enviada. Debe intentar de nuevo.';
          //this.codigoEvolucion = data.codigo;
        }

        // if(this.guardarClausurarEditar !== 'editar') {
        //   this.deshabilitarControles();
        //   this.desBtnGuardar = true;
        // }

        this.accionEjecutandose = false;
      }, dataError => {
        console.log(dataError);
        if (dataError.error.response !== undefined) {
          this.mostrarMsgError(dataError.error.response);
        } else {
          this.mostrarMsgError('Error al intentar procesar los datos enviados');
        }

        const errors = GetMsgFromError(dataError);
        console.log(errors);

        this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
          errors.errors
        );

        console.log(this.serverErrorsInfo);
        this.accionEjecutandose = false;
        this.saltarValidacion = false;
        window.scroll(0, 0);
      }, () => {
        console.log('finally')
        window.scroll(0, 0);
      });
  }



  guardarControlMedico() {
    this.submitted = true;
    this.deshabilitarValidaciones();
    this.alertMsg.showAlert = false;
    this.alertMsg.msg = '';
    console.log(this.formData);
    this.enviarDatos();
  }

  // Aplicar validaciones a un evolucion

  agregarValoresCie10() {
    // $event.preventDefault();
    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    const lengthRows = this.cie10Rows['controls'].length;
    const diagnosticos_cie10 = [];
    if (lengthRows > 0) {
      for (let i = 0; i < lengthRows; i++) {
        const cie10codigoEvolucion: any = this.cie10Rows.controls[i]['controls']['cie10Codigo'];
        if (cie10codigoEvolucion.value !== null && cie10codigoEvolucion.value.length > 0) {
          //  console.log({ codigo: cie10codigoEvolucion.value, titulo: cie10TituloControl.value });
          const codigo = { codigo: cie10codigoEvolucion.value };
          // console.log(codigo);
          diagnosticos_cie10.push(codigo);
        }
      }
    }

    return diagnosticos_cie10;

  }

  agregarCodigosFotos() {
    const codigosFotosAgregadas = [];
    if (this.fotosExamenFisico.length > 0) {
      for (let i = 0; i < this.fotosExamenFisico.length; i++) {
        codigosFotosAgregadas.push({ codigo: this.fotosExamenFisico[i].codigo });
      }
    }
    return codigosFotosAgregadas;
  }

  agregarCodigosArchivos() {
    const codigosArchivosAgregados = [];
    if (this.infoArchivos.length > 0) {
      for (let i = 0; i < this.infoArchivos.length; i++) {
        codigosArchivosAgregados.push({ codigo: this.infoArchivos[i].codigo });
      }
    }
    return codigosArchivosAgregados;
  }


  // btnLogin_click($event) {
  //   if ($event.loggedIn === true && $event.userInfo !== null) {
  //     //Validar la sesión actual con el usuario que intenta clausurar
  //     this.clausuararHistoria();
  //     this.verVentanas.mostrarVentanaLogin = false;
  //   }
  // }

  public virtual: any = {
    itemHeight: 28
  };

  handleFilterCie10($event: string, index) {
    if ($event === '' || $event === null || $event === undefined) {
      return false;
    }
    console.log({ Valor_seleccionado: $event });
    if ($event.length > 0) {
      this.buscandoCodigoCie = true;
      this.delay(() => {
        this.apiServices.getData('cies?codigo=' + $event + '&titulo=' + $event + '&filtro=or', 'Cies', false)
          .subscribe(data => {
            console.log(data);
            if (data.success === true) {
              this.dataCies = data['Cies'];
              console.log(this.dataCies);
            }
            this.buscandoCodigoCie = false;
          }, error => {
            this.buscandoCodigoCie = false;
            console.log(error);
          }, () => {

          });

      }, 2000, '');
    }
  }

  public valueChange(value: string, index: number): void {
    console.log('valueChange', value);
    if (value === '' || value === undefined || value === null) {
      this.deleteCie10Row(index);
    }
    if (this.dataCies.length > 0) {
      if (this.verificarValoresRepetidos(value, index) && this.cie10Rows.length > 1) {
        console.log('******** valueChange')
        this.msjErrorCie10 = 'Error: código repetido';
      } else {
        console.log('******** valueChange vacio')
        this.msjErrorCie10 = '';
      }

      this.valoresCie10Agegados.push(value);
      // const item: any = this.dataCies[0];
      // if (value === item.codigo) {
      //   const cie10TituloControl: any = this.cie10Rows.controls[index]['controls']['cie10Titulo'] as FormControl;
      //   cie10TituloControl.setValue(item.titulo);
      // }
    } else {
      this.deleteCie10Row(index);
      //const cie10codigoEvolucion: any = this.cie10Rows.controls[index]['controls']['cie10Codigo'] as FormControl;
      //  const cie10TituloControl: any = this.cie10Rows.controls[index]['controls']['cie10Titulo'] as FormControl;
      // cie10codigoEvolucion.setValue('');
    }

    // console.log
  }


  limpiarContoles(omitir = []) {

    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).reset();
      }
    });
  }

  btnGotoUrl(url: string, params = null) {
    // this.verVentanaDetallePaciente = true;
    console.log(url);
    if (params === null) {
      this.router.navigate([url]);
    } else {
      const queryParams = params;
      this.router.navigate([url], { queryParams });
    }

    return false;
  }


  btnAcciones_click($event) {
    console.log($event);

    if ($event.item === 'listado_pacientes') {
      this.btnGotoUrl('ListadoPacientes');

    } else if ($event.item === 'historia_paciente') {
      this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id });

    } else if ($event.item === 'listado_evoluciones') {
      this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id });

    } else if ($event.item === 'reutilizar_datos') {
      const omitir = ['cboEstados', 'chkProrroga'];
      this.habilitarControles(omitir);
      this.alertMsg.showAlert = false;
      this.submitted = false;

      // } else if ($event.item === 'limpiar_datos') {
      //   const omitir = ['cboEstados', 'chkProrroga'];
      //   this.habilitarControles(omitir);
      //   this.limpiarContoles(omitir);
      //   this.alertMsg.showAlert = false;
      //   this.submitted = false;

      // } else if ($event.item === 'habilitar_edicion_editar') {
      //  const omitir = ['cboEstados', 'chkProrroga'];
      this.habilitarControles();
      //  this.limpiarContoles(omitir);
      this.alertMsg.showAlert = false;
      this.submitted = false;

    } else if ($event.item === 'habilitar_edicion_nuevo') {
      const omitir = ['cboEstados', 'chkProrroga'];
      this.habilitarControles();
      this.limpiarContoles(omitir);
      this.alertMsg.showAlert = false;
      this.submitted = false;

    } else if ($event.item === 'registro_vacio') {
      this.saltarValidacion = false;
      this.submitted = false;
      // this.alertMsg.showAlert = false;
      // console.log('Esta entrando aquí');
      window.scroll(0, 0);
      this.limpiarContoles()
      this.habilitarControles();
      this.resetearControles();
      this.cargarValoresPorDefecto();
      this.registroGuardadoExiste = false;

      //   this.clearFormArray(this.cie10Rows);
      // this.limpiarContoles();
      //  this.cargarValoresPorDefecto();
      this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id, tipo: 'nuevo' });

    } else if ($event.item === 'agregar_formula') {
      //  console.log({ origen: 'evolucion', codigo_origen: this.evolucionId });
      if (this.registroGuardadoExiste === true) {
        this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id, tipo: 'nuevo', origen: 'evolucion', codigo_origen: this.evolucionId });
      } else {
        alert('Primero debe guardar');
      }
      //

    } else if ($event.item === 'agregar_incapacidad') {

      if (this.registroGuardadoExiste === true) {
        this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id, tipo: 'nuevo', origen: 'evolucion', codigo_origen: this.evolucionId });
      } else {
        alert('Primero debe guardar');
      }

    } else if ($event.item === 'imprimir') {
      console.log(this.codigoEvolucion);
      console.log(this.registroGuardadoExiste);

      if (Number(this.codigoEvolucion) > 0  && this.registroGuardadoExiste === true) {
        this.generarPdf();
      } else if (Number(this.codigoEvolucion) === 0) {
        alert('Primero debe guardar');
      }

    }
  }


  resetearControles(omitir = []) {
    this.desBtnGuardar = false;
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).enable();
        this.formData.get(element).reset();
      }
    });

  }

  habilitarControles(omitir = []) {
    this.desBtnGuardar = false;
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).enable();
      }
    });

  }

  deshabilitarControles(omitir = []) {
    this.desBtnGuardar = true;
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).disable();
      }
    });
  }


  dataAccionesesGuardar() {
    return [{
      text: 'Volver al listado de pacientes', url: 'ListadoPacientes', item: 'listado_pacientes'
    }, {
      text: 'Volver a la historia clinica del paciente', url: 'HistoriaPaciente', item: 'historia_paciente'
    }, {
      text: 'Volver al historial de evoluciones', url: 'ListadoEvoluciones', item: 'listado_evoluciones'
    },

    // {
    //   text: 'Nueva evolución a partir de la actual', item: 'reutilizar_datos', url: '',
    // }, 

    {
      text: 'Nueva evolución vacía', item: 'registro_vacio', url: 'FormEvoluciones',
    },
    { text: 'Generar formula médica', url: 'FormFormulas', item: 'agregar_formula' },
    { text: 'Generar incapacidad', url: 'FormIncapacidades', item: 'agregar_incapacidad' },

    // {
    //   text: 'Habilitar edición', item: 'habilitar_edicion_nuevo', url: '',
    // }, 

    {
      text: 'Imprimir', item: 'imprimir'
    }];
  }

  dataAccionesesEditar() {
    return [{
      text: 'Volver al listado de pacientes', url: 'ListadoPacientes', item: 'listado_pacientes'
    }, {
      text: 'Volver a la historia clinica del paciente', url: 'HistoriaPaciente', item: 'historia_paciente'
    }, {
      text: 'Volver al historial de evoluciones', url: 'ListadoEvoluciones', item: 'listado_evoluciones'
    },

    {
      text: 'Nueva evolución vacía', item: 'registro_vacio', url: 'FormEvoluciones',
    },
    // {
    //   text: 'Nueva evolución a partir de la actual', item: 'reutilizar_datos', url: '',
    // },
    { text: 'Generar formula médica', url: 'FormFormulas', item: 'agregar_formula' },
    { text: 'Generar incapacidad', url: 'FormIncapacidades', item: 'agregar_incapacidad' },
    {
      text: 'Imprimir', item: 'imprimir'
    }];
  }


  delay = (function () {
    const timers = {};
    return function (callback, ms, label) {
      label = label || 'defaultTimer';
      window.clearTimeout(timers[label] || 0);
      timers[label] = window.setTimeout(callback, ms);
    };
  })();


  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.cargarArchivos();
  }

  setObjectInStorage(nombre: string, object: any) {
    localStorage.setItem(nombre, JSON.stringify(object));
  }

  getObjectInStorage(nombre: string) {
    const retrievedObject = localStorage.getItem(nombre);
    return JSON.parse(retrievedObject);
  }



  private cargarArchivos(): void {
    switch (this.tipoAccion) {

      case 'nuevo':
        this.gridViewArchivos = {
          data: this.infoArchivos.slice(this.skip, this.skip + this.pageSize),
          total: this.infoArchivos.length
        };
        break;

      case 'detalle':
        let queryString = 'pacientes/archivos/' + this.pacienteId;
        queryString += '?tipo=ARCHIVO_PARA_CLINICO';
        queryString += '&origen=CONTROL';
        queryString += '&codigoasociado=' + this.codigoEvolucion;

        this.apiServices.getData(queryString, 'archivos', false).
          subscribe(data => {
            console.log(data);
            if (data.success === true && data.archivos !== null) {
              this.infoArchivos = data['Archivos'];

              this.pathArchivosDetalle = data.path;

              console.log(this.infoArchivos);


              this.gridViewArchivos = {
                data: this.infoArchivos.slice(this.skip, this.skip + this.pageSize),
                total: this.infoArchivos.length
              };

            } else {
              this.alertMsgFoto.showAlert = true;
              this.alertMsgFoto.class = 'alert alert-danger';
              this.alertMsgFoto.msg = data.response;
            }
          }, data => {
            console.log(data);
            this.alertMsgFoto.showAlert = true;
            this.alertMsgFoto.class = 'alert alert-danger';
            this.alertMsgFoto.msg = data.error.response;
          }, () => {
            console.log('finally cargando archivos ');
            this.subiendoFoto = false;
          });
        break;

    }
  }

  generarPdf() {
    this.accionEjecutandose = true;
    let url = 'reportes/generarpdf?origen=evolucion';
    url += '&codigo=' + this.codigoEvolucion +'&pacienteid='+this.pacienteId
    this.apiServices.getData(url, 'Reporte', false)
      .subscribe(
        data => {
          console.log(data);

          if (data !== null && data.success !== undefined && data.success === true) {
            //  this.tienePdf = true;
            // this.urlPdf = this.baseUrl2 + data.path;
            this.alertMsg.showAlert = false;
            this.accionEjecutandose = false;
            window.open(this.baseUrl2 + data.path);
          } else {
            this.accionEjecutandose = false;
            this.mostrarMsgError('Error al intentar generar el PDF, Debe intentar de nuevo');
            window.scroll(0, 0);
          }
        }, error => {
          console.log(error);
          //  this.codigoHistoria = 0;
          //  this.cerrarVentana();
          this.accionEjecutandose = false;
          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError('Error al intentar generar el PDF, Debe intentar de nuevo');
          }

          window.scroll(0, 0);
        }, () => {

        });
  }

  chkHabDesEdicion_click($event) {
    $event.preventDefault();
    this.cerrarVentana();
    this.alertMsg.showAlert = false;

    if (this.tipoUsuario !== 'ADMIN') {
      return false;
    }

    console.log($event);
    console.log(this.chkHabDesEdicion.nativeElement.checked);
    let respuesta = confirm("¿Está seguro?");
    if (respuesta === true) {

      this.apiServices.getData('evoluciones/descerrar/' + this.codigoEvolucion, 'Evolucion', false).
        subscribe(data => {
          console.log(data);
          if (data !== null && data.success !== undefined && data.success === true) {
            this.clausurada = false;
            this.habilitarControles(['txtMestruacion']);
            this.desBtnGuardar = false;
          } else {
            alert('No fue posible habilitar la edición. Debe intentar de nuevo');
          }
          window.scroll(0, 0);
        }, error => {
          if (error.status !== 401) {
            if (error.error.response !== undefined) {
              this.mostrarMsgError(error.error.response);
            } else {
              this.mostrarMsgError('Error al intentar habilitar la edición, debe intentar nuevamente');
            }
          } else {
            this.mostrarMsgError('Error inesperado al intentar habilitar la edición, debe intentar nuevamente');
          }
          window.scroll(0, 0);
        }, () => {

        });
      // txt = "You pressed OK!";
    } else {
      if (this.chkHabDesEdicion.nativeElement.checked === true) {
        this.chkHabDesEdicion.nativeElement.checked = false;
      } else if (this.chkHabDesEdicion.nativeElement.checked === false) {
        this.chkHabDesEdicion.nativeElement.checked = true;
      }
      
    }

    return false;
  }
}
