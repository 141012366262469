import { Component, OnInit, Input, AfterContentInit } from '@angular/core';

@Component({
  selector: 'app-datos-paciente-incapacidad',
  templateUrl: './datos-paciente-incapacidad.component.html',
  styleUrls: ['./datos-paciente-incapacidad.component.css']
})
export class DatosPacienteIncapacidadComponent implements OnInit {

  @Input() datosPaciente : any;
  ngModelTxtNombrePaciente = '';
  ngModelTxtIdenPaciente = '';
 // ngModelTxtEstadoCivilPaciente = '';
  ngModelTxtGeneroPaciente = '';
  ngModelTxtOcupacionPaciente = '';
//  ngModelTxtResidenciaPaciente = '';
 // ngModelTxtDireccionPaciente = '';
 ngModelTxtCelularPaciente = '';
 // ngModelTxtFecNacPaciente = '';
  ngModelTxtEdadPaciente = '';
  ngModelTxtFechaActual ='';

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterContentInit() {
    console.log(this.datosPaciente);
    this.ngModelTxtNombrePaciente = this.datosPaciente.nombre_completo;
    this.ngModelTxtIdenPaciente = this.datosPaciente.identificacion;
   // this.ngModelTxtEstadoCivilPaciente = this.datosPaciente.EstadoCivil.nombre;
    this.ngModelTxtGeneroPaciente = this.datosPaciente.Genero.nombre;
    this.ngModelTxtOcupacionPaciente = this.datosPaciente.Ocupacion.nombre;
  //  this.ngModelTxtResidenciaPaciente = this.datosPaciente.CiudadResidencia.nombre;
  //  this.ngModelTxtDireccionPaciente = this.datosPaciente.direccion;
   this.ngModelTxtCelularPaciente = this.datosPaciente.celular;
  //  this.ngModelTxtFecNacPaciente = this.datosPaciente.fecha_nac;
    this.ngModelTxtEdadPaciente = '' + this.getAge(this.datosPaciente.fecha_nac);
    this.ngModelTxtFechaActual= this.datosPaciente.fecha_actual;
  }

  getAge(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

}