import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterContentInit, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { GreaterThanZero } from 'src/app/validators/CustomValidators';
import { ServiciosService } from '../../servicios/servicios.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { environment } from 'src/environments/environment';
import { GetMsgFromError } from 'src/app/functions/SharedFunctions';
import { serverErrorsInfo } from 'src/app/utilities/serverErrorsInfo';
import { GridDataResult, DataStateChangeEvent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'app-form-controles',
  templateUrl: './form-controles.component.html',
  styleUrls: ['./form-controles.component.css']
})
export class FormControlesComponent implements OnInit, AfterContentInit, AfterViewInit {
  // @ViewChildren('elementosAntecedentesRef') elementosAntecedentesRef: QueryList<ElementRef>
  // @ViewChildren('elementosRevisionSistemasRef') elementosRevisionSistemasRef: QueryList<ElementRef>
  // @ViewChild('chkAlergiaRef') chkAlergiaRef: ElementRef<HTMLInputElement>;
  @ViewChild('chkHabDesEdicion', { static: false }) chkHabDesEdicion: ElementRef<HTMLInputElement>;
  // @ViewChild('txtTraumas') txtTraumasRef: ElementRef;
  // @ViewChild('txtFamiliares') txtFamiliaresRef: ElementRef;
  // @ViewChild('txtFarmacologicos') txtFarmacologicosRef: ElementRef;
  // @ViewChild('txtQuirurgicos') txtQuirurgicosRef: ElementRef;
  // @ViewChild('txtAlergicos') txtAlergicosRef: ElementRef;
  // @ViewChild('txtToxicos') txtToxicosRef: ElementRef;
  // @ViewChild('cboGinecoObstetricos') cboGinecoObstetricosRef: ElementRef;
  // @ViewChild('cboValorGinecoObstetricos') cboValorGinecoObstetricosRef: ElementRef;
  // @ViewChild('txtEsquemaDeVacunacion') txtEsquemaDeVacunacionRef: ElementRef;
  // @ViewChild('txtMestruacion') txtMestruacionRef: ElementRef;
  // @ViewChild('txtPlanificacion') txtPlanificacionRef: ElementRef;
  // @ViewChild('txtNotaAntecedentesPersonales') txtNotaAntecedentesPersonalesRef: ElementRef;

  formData: FormGroup;
  accionEjecutandose = false;
  baseUrl2 = environment.baseUrl2;
  buscandoCodigoCie = false;
  tipoAccion = '';
  msgNotificacion = '';
  url = '';
  saltarValidacion = false;
  medicoId = 0;
  medicoRegId = 0;
  guardarClausurarEditar = '';
  medicoIdReg = 0;
  jsonOriginal = {};
  clausurada = false;
  codigoControl = 0;
  controlId = 0;
  dataItem = null;

  tituloBotonCredenciales = '';
  tituloVentanaCredenciales = 'Verificación de credenciales';
  registroGuardadoExiste = false;
  nuevoRegistro = false;
  cargandoDatos = false;
  errorDeCarga = false;
  tipoUsuario = '';
  dataPaciente: any;
  datosPacienteCargados = false;
  pacienteId = 0;
  medicoDelControl = '';
  //tipoAccion: any;
  tituloFormulario = '';
  desBtnGuardar = false;
  infoArchivos = [];
  infoFotos = [];
  fotosExamenFisico = [];
  userInfo: any;

  tipAccion = {
    NUEVO: 'nuevo',
    DETALLE: 'detalle'
  };

  desBtnBorrarFoto = false;
  desBtnBorrarArchivo = false;

  //pacienteId = 0;

  verVentanas = {
    verVenConBorArchivo: false,
    titVenConBorArchivo: '',
    tetVenConBorArchivo: '',
    verVenConBorFoto: false,
    titVenConBorFoto: '',
    tetVenConBorFoto: '',
    adjuntarFotos: false,
    verFotos: false,
    verArchivos: false,
    tituloVentanaTexto: '',
    ventanaPlaceHolder: '',
    mostrarRegistrarNota: false,
    mostrarNumerosGineco: false,
    mostrarCalendario: false,
    mostrarBuscarCie10: false,
    mostrarVentanaLogin: false,
    adjuntarArchivos: false,
    verVentanaConfirmarGuardar: false,
    titVenCon: '',
    tetVenCon: '',
    accion: '',
    verVentanaCredenciales: false,
    verVentanaConfirmarCredenciales: false,
    titentanaConfirmarCredenciales: '',
    tetVentanaConfirmarCredenciales: ''
  }


  serverErrorsInfo: any = {};

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  };

  alertMsgFoto = {
    showAlert: false,
    msg: '',
    class: ''
  };

  alertMsgArchivo = {
    showAlert: false,
    msg: '',
    class: ''
  };

  public gridViewArchivos: GridDataResult;
  public buttonCount = 10;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  public skip = 0;

  public multiple = true;
  public allowUnsort = true;

  public sort: SortDescriptor[] = [];
  public pageSize = 5;
  totalPages = 0;
  pageCount = 0;

  esAlergico = false;
  submitted = false;
  loadingData = false;
  pathArchivosDetalle = '';


  registroEncontrado = false;
  dataAcciones: any;

  nombrePaciente = '';
  historiaJson = '';

  lstCodigosCie10: [];


  valoresCie10Agregados = [];
  codigosFotosAgregadas = [];
  codigosArchivosAgregados = [];

  // #region ************************ DECLARACIÓNES ENFERMEDAD ACTUAL
  get textEnfermedadActual() { return this.formData.get('txtEnfermedadActual'); }
  get textNotasEnfermedadActual() { return this.formData.get('txtNotasEnfermedadActual'); }
  // #endregion


  // #region ************************ DECLARACIÓNES MOTIVO DE CONSULTA
  get textMotivoConsulta() { return this.formData.get('txtMotivoConsulta'); }
  get textNotasMotivoConsulta() { return this.formData.get('txtNotasMotivoConsulta'); }
  //#endregion

  // #region ************************ DECLARACIÓNES PARA-CLÍNICOS
  get textParaClinicos() { return this.formData.get('txtParaClinicos'); }
  get textNotasClinicos() { return this.formData.get('txtNotasParaClinicos'); }
  // #endregion

  // #region ************************ DECLARACIÓNES ANÁLISIS
  get textAnalisis() { return this.formData.get('txtAnalisis'); }
  get textNotasAnalisis() { return this.formData.get('txtNotasAnalisis'); }
  // #endregion

  // #region ************************ DECLARACIÓNES SIGNOS VITALES
  get textTemp() { return this.formData.get('txtTemp'); }
  get textPulso() { return this.formData.get('txtPulso'); }
  get textFrecuencia() { return this.formData.get('txtFrecuencia'); }
  get textTension1() { return this.formData.get('txtTension1'); }
  get textTension2() { return this.formData.get('txtTension2'); }
  get textPeso() { return this.formData.get('txtPeso'); }
  get textTalla() { return this.formData.get('txtTalla'); }
  get textImc() { return this.formData.get('txtImc'); }
  get textNotasSignos() { return this.formData.get('txtNotasSignos'); }
  // #endregion

  // #region ************************ DECLARACIÓNES DIAGNOSTICO
  indiceCie10 = 0;
  cie10Rows: FormArray;
  get comboCie10() { return this.formData.get('cboCie10'); }
  get textdiagnosticoNotas() { return this.formData.get('txtDiagnosticoNotas'); }
  msjErrorCie10 = '';

  get textDiagnostico() { return this.formData.get('txtDiagnostico'); }
  get textDiagnosticoNotas() { return this.formData.get('txtDiagnosticoNotas'); }


  // #endregion

  // #region ************************ DECLARACIÓNES PLAN
  get textPlan() { return this.formData.get('txtPlan'); }
  get textPlanNotas() { return this.formData.get('txtPlanNotas'); }
  // #endregion

  // #region ************************ DECLARACIÓNES ANOTACIONES GENERALES
  get textNotasGenerales() { return this.formData.get('txtNotasGenerales'); }


  dataCies: Array<{ id: string, codigo_titulo: string }>;

  dataGinecoObstetricosOpciones: Array<{ text: string, value: string }> = [
    { text: 'G - Gravidez', value: 'G - Gravidez' },
    { text: 'P - Partos', value: 'P - Partos' },
    { text: 'V - Vivos', value: 'V - Vivos' },
    { text: 'A - Abortos', value: 'A - Abortos' },
    { text: 'C - Cesáreas', value: 'C - Cesáreas' },
    { text: 'No', value: 'No' },
    { text: 'No aplica', value: 'No aplica' },
  ];

  dataGinecoObstetricosValores: Array<number> = [
    0, 1, 2, 3, 4, 5, 6, 7
  ];

  dataMestruacion: Array<{ text: string, value: string }> = [
    { text: 'Si', value: 'Si' },
    { text: 'No', value: 'No' }
  ];



  public dataAntecedentes: Array<string> = ['Si', 'No', 'No aplica'];

  dataRevisionSistemas: Array<string> = ['Si', 'No', 'No aplica'];

  dataSistemaTegumentario: Array<{ text: string, value: string }> = [
    { text: 'No', value: 'No' },
    { text: 'I', value: 'I' },
    { text: 'II', value: 'II' },
    { text: 'III', value: 'III' },
    { text: 'IV', value: 'IV' },
    { text: 'V', value: 'V' },
    { text: 'VI', value: 'VI' },
    { text: 'VII', value: 'VII' }
  ];


  subiendoFoto = false;

  fotoVisualizada: any;
  indiceFotoVisualizada = 0;
  urlFotoVisualizada = '';

  public width = '400px';
  public height = '400px';

  public myRestrictions: FileRestrictions = {
    maxFileSize: 3097152
  };

  ngModelNombreFoto: '';
  ngModelNombreArchivo: '';
  ngModelArchivo: any;
  ngModelFoto: any;
  fotoExamenFisico = undefined;
  archivoParaClinico = undefined;

  get textTercioSuperior() { return this.formData.get('txtTercioSuperior'); }
  get textTercioMedio() { return this.formData.get('txtTercioMedio'); }
  get textTercioInferior() { return this.formData.get('txtTercioInferior'); }
  get textCuello() { return this.formData.get('txtCuello'); }
  get textMamas() { return this.formData.get('txtMamas'); }
  get textAbdomen() { return this.formData.get('txtAbdomen'); }
  get textAnterior() { return this.formData.get('txtAnterior'); }
  get textPosterior() { return this.formData.get('txtPosterior'); }
  get textGluteos() { return this.formData.get('txtGluteos'); }
  get textMiembroSuperior() { return this.formData.get('txtMiembroSuperior'); }
  get textMiembroInferior() { return this.formData.get('txtMiembroInferior'); }
  get textGenitales() { return this.formData.get('txtGenitales'); }
  get textNotasExamen() { return this.formData.get('txtNotasExamen'); }



  ngAfterViewInit() {


    // this.ChangeDetectorRef.

    //  this.cd.detectChanges();p
  }



  calcularImc(): void {
    let imc = 0;
    const peso = this.formData.get('txtPeso').value;
    let talla = this.formData.get('txtTalla').value;
    console.log(peso);
    console.log(talla);

    this.formData.get('txtImc').setValue(0);
    if (peso > 0 && talla > 0) {
      // const peso = Number(item.txtPeso);
      // let talla = item.txtTalla;
      // talla = talla * talla;
      talla = Number(talla) * Number(talla);
      imc = peso / talla;
      console.log(imc);
      this.formData.get('txtImc').setValue(imc)
      // debugger;
    }
    //  this.cajasDeTexto.signosVitales.imc = imc;
  }

  //#region *************************** MÉTODOS SIGNOS VITALES
  signosVitales_input($event, pregunta: string) {

    this.calcularImc();


  }



  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }



  btnMostrarConfirmacionBorrarFoto_click() {
    // this.verVentanas.verVenConBorFoto = false;

    console.log(this.fotosExamenFisico);
    if (this.fotosExamenFisico == null || this.fotosExamenFisico.length === 0) {

      return false;
    }

    if (this.infoFotos.length === 0) {
      alert('No hay fotos para visualizar');
      this.verVentanas.verFotos = false;
      return false;
    }

    // console.log(this.fotoVisualizada);
    this.alertMsg.showAlert = false;
    this.verVentanas.verVenConBorFoto = true;
    this.verVentanas.titVenConBorFoto = 'Confirmación de borrado';
    this.verVentanas.tetVenConBorFoto = '¿Está seguro de borrar la foto?';

    return false;
  }

  // Evento de confirmar borrado
  btnConfirmarBorradoFotoExamenFisico_click($event) {
    //  this.cerrarVentana();
    console.log($event);

    this.alertMsgFoto.showAlert = false;
    this.verVentanas.verVenConBorFoto = false;
    // console.log(this.fotoVisualizada);

    if ($event === false) {
      return false;
    }


    switch (this.tipoAccion) {

      case 'nuevo':
        // debugger;
        // console.log(this.fotosExamenFisico);
        // console.log(this.indiceFotoVisualizada);
        console.log(this.fotosExamenFisico.length);
        this.borrarFotosExamenFisico();
        break;

      case 'detalle':
        console.log(this.fotosExamenFisico.length);
        this.borrarFotosExamenFisico();
        break;
    }



    return false;
  }

  borrarFotosExamenFisico() {
    if (this.fotosExamenFisico.length > 0) {
      const deepClone = JSON.parse(JSON.stringify(this.fotosExamenFisico));


      console.log(deepClone.length);
      console.log(deepClone);
      console.log(typeof this.fotoVisualizada);
      console.log(this.fotoVisualizada);

      let obj;
      if (this.fotoVisualizada === undefined && deepClone.length >= 0) {
        const item = deepClone[0];
        obj = {
          index: 0,
          item: {
            title: item.title,
            url: this.baseUrl2 + item.url_foto_mini,
            url2: this.baseUrl2 + item.url_foto_original,
            posicion: 0,
            codigo: item.codigo
          }
        };
        this.fotoVisualizada = obj;
      }

      console.log(this.fotosExamenFisico);
      let indiceBorrada = 0;
      for (let i = 0; i < deepClone.length; i++) {

        if (Number(deepClone[i].codigo) === Number(this.fotoVisualizada.item.codigo)) {

          indiceBorrada = i;
          console.log({ indiceBorrado: i });
          //  console.log({indiceFotoBorrada: i})
          //  console.log({seg3_indiceFotoBorrada: item});

          console.log({ fotoBorrada: deepClone[i] });
          //   this.borrarFoto(Number(deepClone[i]));
          // this.indiceFotoVisualizada = i;
          const codigoFoto = Number(deepClone[i].codigo);

          console.log(codigoFoto);

          // ************************** Borrar foto ******************************

          this.apiServices.deleteData('archivos', codigoFoto, '?pacienteId=' + this.pacienteId).
            subscribe((data: any) => {

              this.alertMsgArchivo.showAlert = true;
              // const data = response as any;
              if (data.success === true) {

                this.fotosExamenFisico.splice(i, 1);
                this.infoFotos.splice(i, 1);
                deepClone.splice(i, 1);

                this.alertMsgArchivo.class = 'alert alert-success';
                //debugger;
              } else {
                this.alertMsgFoto.class = 'alert alert-danger';
              }

              this.alertMsgArchivo.msg = data.response;
            }, data => {
              console.log(data);
              this.alertMsgFoto.showAlert = true;
              this.alertMsgFoto.class = 'alert alert-danger';
              this.alertMsgFoto.msg = 'Error al intentar borrar la foto. Intente de nuevo.';
            }, () => {
              console.log('finally borrar foto');
              // this.subiendoFoto = false;
            });

          // ************************** Borrar foto ******************************

        }
      }

      console.log(this.fotosExamenFisico.length);
      console.log(deepClone.length);
      console.log(indiceBorrada);

      if (indiceBorrada === 0 && this.fotosExamenFisico.length === 1) {
        console.log(indiceBorrada);
        const item = deepClone[0];
        console.log(item);
        const obj = {
          index: 0,
          item: {
            title: item.title,
            url: this.baseUrl2 + item.url_foto_mini,
            url2: this.baseUrl2 + item.url_foto_original,
            posicion: 0,
            codigo: item.codigo
          }
        };
        this.fotoVisualizada = obj;
        this.indiceFotoVisualizada = obj.index;
        //  this.fotoVisualizada = obj;
      } else if (this.fotosExamenFisico.length === indiceBorrada && this.fotosExamenFisico.length > 0) {
        console.log(indiceBorrada);
        const item = deepClone[indiceBorrada - 1];
        console.log(item);
        obj = {
          index: indiceBorrada - 1,
          item: {
            title: item.title,
            url: this.baseUrl2 + item.url_foto_mini,
            url2: this.baseUrl2 + item.url_foto_original,
            posicion: 0,
            codigo: item.codigo
          }
        };
        this.fotoVisualizada = obj;
        this.indiceFotoVisualizada = obj.index;
      } else if (indiceBorrada === 0 && this.fotosExamenFisico.length > 1) {
        console.log(indiceBorrada);
        const item = deepClone[indiceBorrada + 1];
        console.log(item);
        obj = {
          index: indiceBorrada + 1,
          item: {
            title: item.title,
            url: this.baseUrl2 + item.url_foto_mini,
            url2: this.baseUrl2 + item.url_foto_original,
            posicion: 0,
            codigo: item.codigo
          }
        };
        this.fotoVisualizada = obj;
        this.indiceFotoVisualizada = obj.index;
      } else if (indiceBorrada > 0) {
        console.log(indiceBorrada);
        const item = deepClone[indiceBorrada - 1];
        console.log(item);
        obj = {
          index: indiceBorrada - 1,
          item: {
            title: item.title,
            url: this.baseUrl2 + item.url_foto_mini,
            url2: this.baseUrl2 + item.url_foto_original,
            posicion: 0,
            codigo: item.codigo
          }
        };
        this.fotoVisualizada = obj;
        this.indiceFotoVisualizada = obj.index;
      } else if (indiceBorrada === 0 && this.fotosExamenFisico.length === 0) {
        this.fotosExamenFisico = [0];
        this.fotoVisualizada = null;
        this.indiceFotoVisualizada = 0;

      }

    }
  }


  btnAdjuntarFotos_click($event) {
    if (this.codigoControl === 0) {
      alert('Primero debe guardar (al menos por primera vez, para asociar el control a un médico)');
      return false;
    }
    this.verVentanas.adjuntarFotos = true;
  }

  btnAdjuntarArchivos_click($event) {
    if (this.codigoControl === 0) {
      alert('Primero debe guardar (al menos por primera vez, para asociar el control a un médico)');
      return false;
    }
    this.verVentanas.adjuntarArchivos = true;
  }

  itemChanged($event) {
    console.log({ itemChanged$event: $event });
    this.indiceFotoVisualizada = Number($event.index);
    console.log({ indiceFotoVisualizada_itemChanged: this.indiceFotoVisualizada })
    this.urlFotoVisualizada = $event.item.url2;
    this.fotoVisualizada = $event;
    console.log($event);
  }

  btnVerFotos_click($event?) {
    console.log(this.infoFotos);



    this.verVentanas.verFotos = true;
    console.log('this.tipoAccion btnVerFotos_click ' + this.tipoAccion);
    switch (this.tipoAccion) {

      case 'nuevo':

        if (this.infoFotos.length === 0) {
          alert('No hay fotos para visualizar');
          this.verVentanas.verFotos = false;
          return false;
        }

        console.log({ fotosExamenFisico: this.fotosExamenFisico });

        this.fotosExamenFisico = [];

        Object.keys(this.infoFotos).forEach((index) => {
          //  console.log(index);
          const item = this.infoFotos[index];
          console.log(item);

          const obj = {
            title: item.nombre,
            url: this.baseUrl2 + item.url_foto_mini,
            url2: this.baseUrl2 + item.url_foto_original,
            posicion: Number(index),
            codigo: item.codigo
          };

          this.fotosExamenFisico.push(
            obj
          );

          if (Number(index) === 0) {
            this.urlFotoVisualizada = obj.url2;
          }
        });

        // console.log(this.fotosExamenFisico);
        console.log({ fotosExamenFisico: this.fotosExamenFisico });
        break;

      case 'detalle':


        let queryString = 'pacientes/archivos/' + this.pacienteId;
        queryString += '?tipo=FOTO_EXAMEN_FISICO';
        queryString += '&origen=CONTROL';
        queryString += '&codigoasociado=' + this.codigoControl;

        this.apiServices.getData(queryString, 'archivos', false).
          subscribe(data => {
            console.log(data);
            if (data.success === true && data.archivos !== null) {
              const archivos = data['Archivos'];
              this.fotosExamenFisico = [];
              if (archivos !== null) {
                Object.keys(archivos).forEach((index) => {
                  //  console.log(index);
                  const item = archivos[index];
                  const obj = {
                    title: item.nombre,
                    url: this.baseUrl2 + item.foto_thumb,
                    url2: this.baseUrl2 + item.foto,
                    posicion: Number(index),
                    codigo: item.codigo
                  };

                  this.fotosExamenFisico.push(
                    obj
                  );

                  this.infoFotos.push(obj);

                  if (Number(index) === 0) {
                    this.urlFotoVisualizada = obj.url2;
                  }
                });

                console.log({ btnVerFotos_click: this.fotosExamenFisico });
                console.log({ indiceFotoVisualizada4: Number(this.indiceFotoVisualizada) });
              }

              // console.log(this.fotosExamenFisico);;

            } else {
              this.alertMsgFoto.showAlert = true;
              this.alertMsgFoto.class = 'alert alert-danger';
              this.alertMsgFoto.msg = data.response;
            }
          }, data => {
            console.log(data);
            this.alertMsgFoto.showAlert = true;
            this.alertMsgFoto.class = 'alert alert-danger';
            this.alertMsgFoto.msg = data.error.response;
          }, () => {
            console.log('finally subirFotoExamenFisico');
            this.subiendoFoto = false;
          });
        break;
    }


    // debugger;
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    console.log({ 'state': state });
    // let queryString

    this.skip = state.skip;
    this.pageSize = state.take;
    this.sort = state.sort;

    if (this.skip === 0) {
      this.skip = 1;
    }

    let currentPage = Math.floor(this.skip / this.pageSize);
    currentPage++;

    // this.verVentanas.verArchivos = true;
    let queryString = 'pacientes/archivos/' + this.pacienteId;
    queryString += '?page=' + currentPage + '&tipo=ARCHIVO_PARA_CLINICO';
    queryString += '&origen=HISTORIA_CLINICA';
    queryString += '&codigoasociado=' + this.codigoControl;

    this.btnVerArchivos_click(queryString);

  }


  btnVerArchivos_click(query: string = '') {
    //   this.infoArchivos = this.getObjectInStorage('lstArchivos');
    if (this.infoArchivos.length === 0 && this.tipoAccion === 'nuevo') {
      alert('No hay archivos para visualizar');
      return false;
    }

    this.verVentanas.verArchivos = true;
    this.cargarArchivos();

  }

  // btnConfirmarBorrarArchivo_click($event) {
  // }

  btnMostrarConfirmarBorrarArchivo_click(dataItem) {
    this.dataItem = dataItem;


    console.log(this.dataItem);
    console.log(this.gridViewArchivos.total);

    // console.log(this.fotoVisualizada);
    this.alertMsgArchivo.showAlert = false;
    this.verVentanas.verVenConBorArchivo = true;
    this.verVentanas.titVenConBorArchivo = 'Confirmación de borrado';
    this.verVentanas.tetVenConBorArchivo = '¿Está seguro de borrar el archivo?';

  }


  btnBorrarArchivoParaClinico_click($event) {
    this.verVentanas.verVenConBorArchivo = false;
    if (this.dataItem === null || this.dataItem === undefined) {
      return false;
    }

    if ($event === false) {
      return false;
    }

    switch (this.tipoAccion) {

      case 'nuevo':

        if (this.infoArchivos.length === 0) {

          return false;
        }

        console.log({ eventoBorrar: $event });
        console.log({ dataItem: this.dataItem });

        this.verVentanas.verVenConBorArchivo = false;
        this.alertMsgArchivo.showAlert = false;

        for (let i = 0; i < this.infoArchivos.length; i++) {
          // console.log(infoArchivos[i]);
          if (this.infoArchivos[i].codigo == this.dataItem.codigo) {
            this.infoArchivos.splice(i, 1);
          }
        }

        console.log({ infArchivos: this.infoArchivos });

        this.cargarArchivos();
        break;

      case 'detalle':

        const codigoArchivo = this.dataItem.codigo; //return false;
        this.apiServices.deleteData('archivos', codigoArchivo, '?pacienteId=' + this.pacienteId).
          subscribe((data: any) => {
            // const data = response as any;

            if (data.success === true) {
              this.alertMsgArchivo.showAlert = true;
              this.alertMsgArchivo.class = 'alert alert-success';
              this.alertMsgArchivo.msg = data.response;
              this.btnVerArchivos_click();

            } else {
              this.alertMsgArchivo.showAlert = true;
              this.alertMsgArchivo.class = 'alert alert-danger';
              this.alertMsgArchivo.msg = data.response;
            }

            this.verVentanas.verVenConBorArchivo = false;

          }, data => {
            console.log(data);
            this.alertMsgArchivo.showAlert = true;
            this.alertMsgArchivo.class = 'alert alert-danger';
            if (data.error.response !== undefined) {
              this.alertMsgArchivo.msg = data.error.response;
            } else {
              this.alertMsgArchivo.msg = 'Error al intentar borrar el archivo. Intente de nuevo.';
            }

          }, () => {
            console.log('finally borrar archivo');

            // this.subiendoFoto = false;
          });
        break;
    }


  }

  btnAbrirArchivoParaClinico_click(dataItem) {
    console.log(dataItem);
    let url = '';
    if (this.tipoAccion === 'detalle') {
      url = this.baseUrl2 + this.pathArchivosDetalle + dataItem.nombre_auto;
    } else if (this.tipoAccion === 'nuevo') {
      url = this.baseUrl2 + dataItem.path + dataItem.archivo;
    }

    console.log(url);
    // const url = this.baseUrl2 + dataItem.path + dataItem.archivo;
    window.open(url, '_blank');
  }



  // Subir foto y descargar https://www.youtube.com/watch?v=lEsl-B4TrG0

  cerrarVentana() {
    this.verVentanas.adjuntarFotos = false;
    this.verVentanas.adjuntarArchivos = false;
    this.alertMsgFoto.showAlert = false;
    this.alertMsgFoto.msg = '';
    this.alertMsgArchivo.showAlert = false;
    this.alertMsgArchivo.msg = '';
    this.fotoExamenFisico = undefined;
    this.archivoParaClinico = undefined;
    this.verVentanas.verFotos = false;
    this.verVentanas.verArchivos = false;
    this.verVentanas.mostrarBuscarCie10 = false;
    this.verVentanas.mostrarVentanaLogin = false;
    this.verVentanas.verVentanaConfirmarGuardar = false;
    this.verVentanas.verVentanaConfirmarCredenciales = false;
    this.verVentanas.verVentanaCredenciales = false;
  }


  seleccionFotoExamenFisico(archivo) {
    //  console.log(archivo)
    if (archivo.files) {
      this.fotoExamenFisico = archivo.files[0].rawFile;
    }
    this.alertMsgFoto.showAlert = false;
    this.alertMsgFoto.msg = '';
  }

  seleccionArchivoParaClinico(archivo) {
    //  console.log(archivo)
    if (archivo.files) {
      this.archivoParaClinico = archivo.files[0].rawFile;
    }
    this.alertMsgArchivo.showAlert = false;
    this.alertMsgArchivo.msg = '';
  }


  removerFotoExamenFisico($event) {
    this.fotoExamenFisico = undefined;
    // const file = (event.target as HTMLInputElement).files[0];
    // console.log(file);

  }

  removerArchivoParaClinico($event) {
    this.archivoParaClinico = undefined;
    // const file = (event.target as HTMLInputElement).files[0];
    // console.log(file);

  }



  btnSubirFotoExamenFisico_click() {
    this.indiceFotoVisualizada = 0;
    this.alertMsgFoto.showAlert = false;
    if (this.fotoExamenFisico !== undefined) {
      this.subiendoFoto = true;
      const fotoExamenFisico = new FormData();
      fotoExamenFisico.set('nombre', this.ngModelNombreFoto);
      fotoExamenFisico.set('archivo', this.fotoExamenFisico);
      fotoExamenFisico.set('paciente_id', '' + this.pacienteId);
      fotoExamenFisico.set('codigo_asociado', '' + this.codigoControl);
      fotoExamenFisico.set('tipo', 'FOTO_EXAMEN_FISICO');
      fotoExamenFisico.set('origen', 'CONTROL');

      this.apiServices.postFormData(fotoExamenFisico, 'archivos').
        subscribe(data => {
          console.log(data);
          if (data.success === true) {
            this.alertMsgFoto.showAlert = true;
            this.alertMsgFoto.class = 'alert alert-success';
            this.alertMsgFoto.msg = data.response;

            if (data.tipo === 'FOTO_EXAMEN_FISICO') {
              this.infoFotos.push(data);
            }

            this.resetearSubirFotos();

          } else {
            this.alertMsgFoto.showAlert = true;
            this.alertMsgFoto.class = 'alert alert-danger';
            this.alertMsgFoto.msg = data.response;
          }
        }, data => {
          console.log(data);
          this.alertMsgFoto.showAlert = true;
          this.alertMsgFoto.class = 'alert alert-danger';
          this.alertMsgFoto.msg = data.error.response;
          this.subiendoFoto = false;
        }, () => {
          console.log('finally subirFotoExamenFisico');
          // this.loadingData = false;
          this.subiendoFoto = false;
        });

    } else {
      this.alertMsgFoto.showAlert = true;
      this.alertMsgFoto.class = 'alert alert-danger';
      this.alertMsgFoto.msg = 'Debe seleccionar una foto';
    }
  }



  btnSubirArchivoParaClinico_click() {
    console.log('btnSubirArchivoParaClinico_click');
    this.alertMsgArchivo.showAlert = false;
    if (this.ngModelNombreArchivo !== null || this.ngModelNombreArchivo !== undefined) {
      if (this.ngModelNombreArchivo === '' || this.ngModelNombreArchivo === undefined) {
        this.alertMsgArchivo.showAlert = true;
        this.alertMsgArchivo.class = 'alert alert-danger';
        this.alertMsgArchivo.msg = 'Debe especificar el nombre del archivo';
        return false;
      }

    } else {
      this.alertMsgArchivo.showAlert = true;
      this.alertMsgArchivo.class = 'alert alert-danger';
      this.alertMsgArchivo.msg = 'Debe especificar el nombre del archivo';
      return false;
    }

    if (this.archivoParaClinico !== undefined) {
      this.subiendoFoto = true;
      const archivoParaClinico = new FormData();
      archivoParaClinico.set('nombre', this.ngModelNombreArchivo);
      archivoParaClinico.set('archivo', this.archivoParaClinico);
      archivoParaClinico.set('paciente_id', '' + this.pacienteId);
      archivoParaClinico.set('codigo_asociado', '' + this.codigoControl);
      archivoParaClinico.set('tipo', 'ARCHIVO_PARA_CLINICO');
      archivoParaClinico.set('origen', 'CONTROL');

      this.apiServices.postFormData(archivoParaClinico, 'archivos').
        subscribe(data => {
          console.log(data);
          if (data !== null && data.success === true) {

            console.log({ archivoSubido: data });

            this.alertMsgArchivo.showAlert = true;
            this.alertMsgArchivo.class = 'alert alert-success';
            this.alertMsgArchivo.msg = data.response;

            if (data.tipo === 'ARCHIVO_PARA_CLINICO') {
              this.infoArchivos.push(data);
            }
            console.log({ fotosExamenFisico: this.fotosExamenFisico });
            console.log({ infoArchivos: this.infoArchivos });
            this.resetearSubirArchivos();
            //this.resetea
            //this.reste

          } else {
            this.alertMsgArchivo.showAlert = true;
            this.alertMsgArchivo.class = 'alert alert-danger';
            this.alertMsgArchivo.msg = data.response;
          }
        }, data => {
          console.log(data);
          this.alertMsgArchivo.showAlert = true;
          this.alertMsgArchivo.class = 'alert alert-danger';
          this.alertMsgArchivo.msg = data.error.response;
          this.subiendoFoto = false;
        }, () => {
          console.log('finally btnSubirArchivoParaClinico_click');
          // this.loadingData = false;
          this.subiendoFoto = false;
        });

    } else {
      this.alertMsgArchivo.showAlert = true;
      this.alertMsgArchivo.class = 'alert alert-danger';
      this.alertMsgArchivo.msg = 'Debe seleccionar un archivo';
    }
  }




  verificarValoresRepetidos(valor, index?): boolean {
    // const strArray = [];
    // const alreadySeen = [];
    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    const lengthRows = this.cie10Rows['controls'].length;
    console.log(valor);
    for (let i = 0; i < lengthRows; i++) {
      const cie10CodigoControl: any = this.cie10Rows.controls[i]['controls']['cie10Codigo'] as FormControl
      if (cie10CodigoControl.value === valor && i !== index) {
        return true;
      }
    }

    return false;
    // return new Set(strArray).size !== strArray.length;
  }





  //   return new Set(strArray).size !== strArray.length;
  // }

  agregarFilaCie10() {

    console.log(this.desBtnGuardar);
    if (this.desBtnGuardar === true) {
      return false;
    }

    if (this.msjErrorCie10.length === 0) {

      this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
      const lengthRows = this.cie10Rows['controls'].length;
      console.log(lengthRows);
      if (lengthRows > 0) {
        for (let i = 0; i < lengthRows; i++) {
          const cie10CodigoControl: any = this.cie10Rows.controls[i]['controls']['cie10Codigo'] as FormControl
          if (cie10CodigoControl.value === '') {
            this.msjErrorCie10 = 'Debe completar el código vacío';
            console.log('Hay códigos vacios');
            break;
          }
        }
      }

      if (this.msjErrorCie10.length > 0) {
        return false;
      }
      // return false;

      this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
      this.cie10Rows.push(this.createCie10Row());
    }
  }


  deleteCie10Row(index: number) {
    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    if (this.cie10Rows.controls.length > 1) {
      this.cie10Rows.removeAt(index);
      this.indiceCie10--;
    } else {
      const cie10CodigoControl: any = this.cie10Rows.controls[0]['controls']['cie10Codigo'] as FormControl;
      // const cie10TituloControl: any = this.cie10Rows.controls[0]['controls']['cie10Titulo'] as FormControl;
      cie10CodigoControl.setValue('');
      // cie10TituloControl.setValue('');
      this.indiceCie10 = 0;
    }
    this.msjErrorCie10 = '';
    return false;
  }


  createCie10Row(): FormGroup {
    return this.formBuilder.group({
      cie10Codigo: [''],
      cie10Titulo: [''],
    });
  }




  constructor(private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiServices: ServiciosService) {


  }

  public onPaste(): void {
    console.log('Paste');
  }

  deshabilitarValidaciones() {

    const omitir = [
      'txtMestruacion'
    ];

    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        //  console.log({ desha: element });
        this.formData.get(element).clearValidators();
      } else {
        console.log({ habi: element });
        this.formData.get(element).setValidators([Validators.required]);
      }
      this.formData.get(element).updateValueAndValidity();
    });
  }

  habilitarValidaciones() {

    const greaterThanZero = [
      'txtTemp', 'txtPulso', 'txtFrecuencia',
      'txtTension1', 'txtTension2',
      'txtPeso', 'txtTalla'
    ];

    const omitirNotas = [
      'txtNotaAntecedentesPersonales', 'txtNotasMotivoConsulta',
      'txtNotasEnfermedadActual', 'txtDiagnosticoNotas',
      'txtNotasExamen', 'txtNotaSistemas', 'txtPlanNotas',
      'txtNotasGenerales', 'cie10Rows',
      'txtNotasSignos', 'txtNotasParaClinicos', 'txtNotasAnalisis'
    ];

    Object.keys(this.formData.controls).forEach(element => {



      if (greaterThanZero.includes(element)) {
        this.formData.get(element).setValidators([Validators.required, GreaterThanZero(true)]);
      } else if (omitirNotas.includes(element)) {
        this.formData.get(element).clearValidators();
      } else {
        this.formData.get(element).setValidators([Validators.required]);
      }

      this.formData.get(element).updateValueAndValidity();
    });
  }


  obtenerCodigoCie10() {
    this.apiServices.getData('cies', 'cies').
      subscribe(data => {
        //  console.log(data);
        this.lstCodigosCie10 = data;
      }, error => {
        console.log(error);
      }, () => {
        console.log('Finally');
      });
  }

  getData(pacienteId: number) {
    // console.log(itemId);
    if (pacienteId !== undefined && pacienteId > 0) {
      let urlParams = 'controles/' + pacienteId + '?medid=all';
      //urlParams += '?historia=all';
      this.apiServices.getData(urlParams, 'Control', false).subscribe(
        data => {
          console.log({ controlObtenido: data });
          // const item = data;
          if (data.success === true) {
            const item = data['Control'];
            // if (item.Control !== null) {
            this.cargarValoresControl(item);
            this.medicoIdReg = item.Medico.id;
            this.medicoDelControl = item.Medico.nombres + ' ' + item.Medico.apellidos + ' (CC: ' + item.Medico.identificacion + ')';
           // this.registroGuardadoExiste = true;
            console.log(item);

            // if (Number(item.clausurada) === 1) {
            //   this.clausurada = true;
            //   this.deshabilitarControles();
            //   //  this.deshabilitarValidaciones();
            //   this.desBtnGuardar = true;
            // } else {
            //   this.clausurada = false;
            //   this.habilitarControles();
            //   this.desBtnGuardar = false;
            //   //this.habilitarValidaciones();
            // }

            this.controlId = item.id;
            this.codigoControl = item.codigo;
            this.tituloFormulario = 'Detalle del control médico. Código: ' + item.codigo;
            this.registroGuardadoExiste = true;

            this.getPaciente(item.paciente_id);

            if (Number(item.clausurada) === 1) {
              this.clausurada = true;
              this.deshabilitarControles();
              this.desBtnGuardar = true;
              this.desBtnBorrarArchivo = true;
              this.desBtnBorrarFoto = true;
            } else if (this.tipoUsuario === 'ADMIN') {
              this.habilitarControles();
              this.desBtnGuardar = false;
            } else if (!this.medicoActualHizoIngreso(this.medicoId, this.medicoIdReg, this.tipoUsuario)) { //Si no es de el
              this.deshabilitarControles();
              this.desBtnGuardar = true;
              this.desBtnBorrarArchivo = true;
              this.desBtnBorrarFoto = true;
            }

            // if(item.clausurada == 1) {
            //   this.clausurada = true;
            //   this.deshabilitarControles();
            // } else {
            //   this.deshabilitarControles();
            //   this.clausurada = false; //
            // }

          }
          //  this.obtenerCodigoCie10();
        },
        dataError => {
          console.log(dataError);
          if (dataError.error.response !== undefined) {
            this.mostrarMsgError(dataError.error.response);
          } else {
            this.mostrarMsgError('Error al intentar cargar los datos del control médico. Intente de nuevo.');
          }
          this.registroEncontrado = false;
          this.cargandoDatos = false;
          this.loadingData = true;
        },
        () => {
          this.loadingData = true;
        }
      );
    } else {

      this.mostrarMsgError('Error al intentar cargar los datos del control médico. Intente de nuevo.');

      this.registroEncontrado = false;
      this.loadingData = true;
      this.cargandoDatos = false;
    }
  }

  medicoActualHizoIngreso(idMedicoLogin: Number, idMedicoHistoria: Number, tipoUsuario: string) {
    console.log({ idMedicoHistoria: idMedicoHistoria });
    console.log({ idMedicoLogin: idMedicoLogin });

    if (tipoUsuario === 'MEDICO' && (idMedicoHistoria === idMedicoLogin)) {
      return true; //Si es el propietario
    }
    return false;
  }

  recargarValores_click() {
    this.getData(Number(this.pacienteId));
  }

  cargarValoresControl(control) {
    //  console.log(historiaJson.historia_json);


    if (control == undefined) {
      return false;
    }

    // #region ASIGNACIÓN DE VALORES MOTIVO DE CONSULTA
    this.textMotivoConsulta.setValue(control.consulta_motivo);
    //this.textNotasMotivoConsulta.setValue(control.consulta_notas);
    //#endregion

    //#region ASIGNACIÓN DE VALORES ENFERMEDAD ACTUAL
    this.textEnfermedadActual.setValue(control.enfermedad_actual);


    if (control.alergico.trim() === 'Si') {
      // this.chkAlergiaRef.nativeElement.checked = true;
      // this.formData.get('txtAlergicos').enable();
      // this.formData.patchValue({ chkAlergiaRef: true });
      this.esAlergico = true;
    } else {
      // this.chkAlergiaRef.nativeElement.checked = false;
      this.esAlergico = false;
    }


    this.textTemp.setValue(Number(control.signos_temp));
    this.textPulso.setValue(Number(control.signos_pulso));
    this.textFrecuencia.setValue(Number(control.signos_frecuencia));
    this.textTalla.setValue(Number(control.signos_talla));
    this.textTension1.setValue(Number(control.signos_tension1));
    this.textTension2.setValue(Number(control.signos_tension2));
    this.textPeso.setValue(Number(control.signos_peso));
    this.textImc.setValue(Number(control.signos_imc));

    this.textTercioSuperior.setValue(control.examen_ter_sup);
    this.textTercioMedio.setValue(control.examen_ter_med);
    this.textTercioInferior.setValue(control.examen_ter_inf);
    this.textCuello.setValue(control.examen_cuello);
    this.textMamas.setValue(control.examen_mamas);
    this.textAbdomen.setValue(control.examen_abdomen);
    this.textAnterior.setValue(control.examen_anterior);
    this.textPosterior.setValue(control.examen_posterior);
    this.textGluteos.setValue(control.examen_gluteos);
    this.textMiembroSuperior.setValue(control.examen_mien_sup);
    this.textMiembroInferior.setValue(control.examen_mien_inf);
    this.textGenitales.setValue(control.examen_genitales);
    this.textNotasExamen.setValue(control.examen_notas);
    //#endregion

    //#region ASIGNACIÓN DE VALORES PARA-CLINICOS
    this.textParaClinicos.setValue(control.para_paraclinicos);
    this.textNotasClinicos.setValue(control.para_notas);
    //#endregion

    //#region ASIGNACIÓN DE VALORES ANÁLISIS
    // console.log(analisis);
    this.textAnalisis.setValue(control.analisis_analisis);
    this.textNotasAnalisis.setValue(control.analisis_notas);
    //#endregion

    if (control.clausurada === 0) {
      this.clausurada = false;
    } else if (control.clausurada === 1) {
      this.clausurada = true;
    }

    //#region ASIGNACIÓN DE VALORES DIAGNOSTICO
    // const diagnostico = control.diagnostico;

    // console.log(Object.keys(control.diagnosticos_cie10).length);
    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    //  this.indiceCie10 = this.cie10Rows.length;
    // while (this.cie10Rows.length > 0) {
    //   this.cie10Rows.removeAt(0)
    // }
    //https://stackoverflow.com/questions/41852183/angular-2-remove-all-items-from-a-formarray


    this.indiceCie10 = 0;

    if (control.diagnosticos_cie10.trim().length > 0 && this.isJson(control.diagnosticos_cie10)) {

      const diagnosticos_cie10 = JSON.parse(control.diagnosticos_cie10);

      if (Object.keys(diagnosticos_cie10).length > 0) {
        const arr = <FormArray>this.formData.controls.cie10Rows;
        arr.controls = [];
        // const itemCie10 = diagnostico.diagnosticos_cie10;
        Object.keys(diagnosticos_cie10).forEach(index => {
          // const itemCie10 = control.diagnosticos_cie10;
          console.log({ indiceCie10: index })
          // if (Number(index) === 0) {
          this.cie10Rows.push(this.createCie10Row());
          const cie10Codigocontrol: any = this.cie10Rows.controls[Number(index)]['controls']['cie10Codigo'] as FormControl;
          // const cie10Titulocontrol: any = this.cie10Rows.controls[Number(index)]['controls']['cie10Titulo'] as FormControl;
          cie10Codigocontrol.setValue(diagnosticos_cie10[Number(index)].codigo + ' - ' + diagnosticos_cie10[Number(index)].titulo);
          // cie10Titulocontrol.setValue(diagnosticos_cie10[Number(index)].titulo);
          this.indiceCie10++;
        });

      }
    }

    this.textDiagnostico.setValue(control.diagnostico_manual);
    this.textdiagnosticoNotas.setValue(control.diagnostico_notas);
    // #endregion

    //#region ASIGNACIÓN DE VALORES PLAN
    // const plan = control.plan;
    // console.log(plan);
    this.textPlan.setValue(control.plan_plan);
    this.textPlanNotas.setValue(control.plan_notas);
    //#endregion


    //#region ASIGNACIÓN DE VALORES GENERALES
    // const generales = control.generales;
    // console.log(generales);
    this.textNotasGenerales.setValue(control.general_notas);
    //#endregion

    //this.loadingData = true;


  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  btnRedireccionar_click(url: string) {
    // this.verVentanaDetallePaciente = true;
    const queryParams = {
      pacienteId: this.pacienteId
    };
    this.router.navigate([url], { queryParams });
    return false;
  }

  isObject(item) {
    return (typeof item === 'object' && !Array.isArray(item) && item !== null);
  }



  ngOnInit(): void {

    this.tipoUsuario = this.apiServices.getTipoUsuario();
    const userInfo = this.apiServices.getUserInfoFromToken();
    this.userInfo = userInfo.userInfo;

    if (this.tipoUsuario === 'MEDICO') {
      this.medicoId = userInfo.userInfo.id;
    }

    console.log(userInfo);
    this.cargandoDatos = true;

    this.formData = this.formBuilder.group({

      cie10Rows: this.formBuilder.array([this.createCie10Row()]),



      // Signos vitales
      txtTemp: ['', [Validators.required, GreaterThanZero(true)]],
      txtPulso: ['', [Validators.required, GreaterThanZero(true)]],
      txtFrecuencia: ['', [Validators.required, GreaterThanZero(true)]],
      txtTension1: ['', [Validators.required, GreaterThanZero(true)]],
      txtTension2: ['', [Validators.required, GreaterThanZero(true)]],
      txtPeso: ['', [Validators.required, GreaterThanZero(true)]],
      txtTalla: ['', [Validators.required, GreaterThanZero(true)]],
      txtImc: [''],
      txtNotasSignos: [''],

      // Motivo de consulta
      txtMotivoConsulta: ['No', [Validators.required]],
      txtNotasMotivoConsulta: [''],

      // Enfermedad Actual
      txtEnfermedadActual: ['No', [Validators.required]],
      txtNotasEnfermedadActual: [''],

      // Examen fisíco
      txtTercioSuperior: ['No', [Validators.required]],
      txtTercioMedio: ['No', [Validators.required]],
      txtTercioInferior: ['No', [Validators.required]],
      txtPosterior: ['No', [Validators.required]],
      txtCuello: ['No', [Validators.required]],
      txtMamas: ['No', [Validators.required]],
      txtAbdomen: ['No', [Validators.required]],
      txtAnterior: ['No', [Validators.required]],
      txtGluteos: ['No', [Validators.required]],
      txtMiembroSuperior: ['No', [Validators.required]], //Posterior
      txtMiembroInferior: ['No', [Validators.required]], //Anterior
      //   txtNotasExamenFisico: [''],
      txtGenitales: ['No', [Validators.required]],
      txtNotasExamen: [''],

      // Paraclinicos
      txtParaClinicos: ['No', [Validators.required]],
      txtNotasParaClinicos: [''],

      // Análisis
      txtAnalisis: ['No', [Validators.required]],
      txtNotasAnalisis: [''],

      // Diagnostico
      txtDiagnostico: ['No', [Validators.required]],
      txtDiagnosticoNotas: [''],

      // Plan
      txtPlan: ['No', [Validators.required]],
      txtPlanNotas: [''],

      // Notas generales
      txtNotasGenerales: ['']


    });



    this.formData.get('txtTemp').setValue(0);
    this.formData.get('txtPulso').setValue(0);
    this.formData.get('txtPeso').setValue(0);
    this.formData.get('txtTension1').setValue(0);
    this.formData.get('txtTension2').setValue(0);
    this.formData.get('txtFrecuencia').setValue(0);
    this.formData.get('txtTalla').setValue(0);
    this.formData.get('txtImc').setValue(0);



  }

  resetearSubirArchivos() {
    this.ngModelNombreArchivo = '';
    this.ngModelArchivo = [];
    this.archivoParaClinico = undefined;
  }

  resetearSubirFotos() {
    this.ngModelNombreFoto = '';
    this.ngModelFoto = [];
    this.fotoExamenFisico = undefined;
  }

  // cargarValoresControl(): void {

  // }

  cargarValoresPorDefecto(): void {
    console.log(this.codigosArchivosAgregados);
    console.log(this.infoArchivos);
    console.log(this.infoFotos);
    //  this.tituloFormulario = 'Registrar nuevo control';
    this.dataAcciones = this.dataAccionesesGuardar();
    this.nuevoRegistro = true;
    this.tipoAccion = 'nuevo';
    this.codigosArchivosAgregados = [];
    this.codigosFotosAgregadas = [];
    this.infoArchivos = [];
    this.infoFotos = [];
    this.fotosExamenFisico = [];
    this.alertMsg.showAlert = false;
    this.tituloFormulario = 'Registrar nuevo control';
    this.habilitarControles();
    this.desBtnGuardar = false;
    this.codigoControl = 0;
    this.clausurada = false;
    this.submitted = false;
    //this.habilitarControles();
    this.resetearControles();
    //    this.getPaciente(this.pacienteId);

    // this.formData.get('txtPatologicos').setValue('No');
    // this.formData.patchValue({ chkAlergiaRef: false });

    this.formData.get('txtMotivoConsulta').setValue('No');
    this.formData.get('txtEnfermedadActual').setValue('No');
    this.formData.get('txtTercioSuperior').setValue('No');
    this.formData.get('txtTercioMedio').setValue('No');
    this.formData.get('txtTercioInferior').setValue('No');

    this.formData.get('txtPosterior').setValue('No');
    this.formData.get('txtCuello').setValue('No');
    this.formData.get('txtMamas').setValue('No');
    this.formData.get('txtAbdomen').setValue('No');
    this.formData.get('txtAnterior').setValue('No');
    this.formData.get('txtGluteos').setValue('No');
    this.formData.get('txtMiembroSuperior').setValue('No');
    this.formData.get('txtMiembroInferior').setValue('No');
    this.formData.get('txtGenitales').setValue('No');

    this.formData.get('txtParaClinicos').setValue('No');
    this.formData.get('txtAnalisis').setValue('No');
    this.formData.get('txtDiagnostico').setValue('No');
    this.formData.get('txtPlan').setValue('No');
    this.formData.get('txtNotasGenerales').setValue('');


    //Limpiar códigos CIE10
    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    this.clearFormArray(this.cie10Rows);
    this.createCie10Row();

    // this.formData.get('txtGravidez').setValue(0);
    // this.formData.get('txtPartos').setValue(0);
    // this.formData.get('txtVivos').setValue(0);
    // this.formData.get('txtAbortos').setValue(0);
    // this.formData.get('txtCesareas').setValue(0);

    this.formData.get('txtTemp').setValue(0);
    this.formData.get('txtPulso').setValue(0);
    this.formData.get('txtPeso').setValue(0);
    this.formData.get('txtTension1').setValue(0);
    this.formData.get('txtTension2').setValue(0);
    this.formData.get('txtFrecuencia').setValue(0);
    this.formData.get('txtTalla').setValue(0);
    this.formData.get('txtImc').setValue(0);


    window.scroll(0, 0);
  }


  ngAfterContentInit(): void {

    console.log('Entro aquí otras vez...');

    this.deshabilitarValidaciones();

    this.activatedRoute.queryParams.subscribe(params => {
      const tipo = params.tipo;
      const controlId = params.controlId;
      const pacienteId = params.pacienteId;
      console.log({ paciente_id: this.isInt(pacienteId) });
      this.registroEncontrado = false;

      this.tipoAccion = tipo;
      this.pacienteId = pacienteId;
      this.controlId = controlId;
      console.log({ tipoRecibido: tipo });
      //this.tipoAccion = params.tipo;

      if (tipo === 'nuevo') {
        // this.tipoAccion = tipo;
        //this.limpiarContoles();
        this.nuevoRegistro = true;
        this.tituloFormulario = 'Registrar nuevo control';
        this.dataAcciones = this.dataAccionesesGuardar();
      } else if (tipo === 'detalle') {
        //  this.tipoAccion = tipo;

        this.nuevoRegistro = false;
        this.dataAcciones = this.dataAccionesesEditar();
      } else {
        this.errorDeCarga = true;
        //   this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar determinar el tipo de acción a realizar.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      }

      if (pacienteId === undefined && controlId === undefined) {
        this.errorDeCarga = true;
        //   this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe 1.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      } else if (this.isInt(pacienteId) === false && tipo == 'nuevo') {
        this.errorDeCarga = true;
        console.log({ tipoRecibido2: tipo });
        //  this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe 2.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      } else if (this.isInt(controlId) === false && tipo == 'detalle') {
        this.errorDeCarga = true;
        //  this.registroEncontrado = false;
        this.cargandoDatos = false;
        const msg = 'Error al intentar cargar los datos. El ID de la Formula es incorrecto o no existe 3.';
        //   msg += 'Debe verificar que el formulario no contenga errores';
        this.mostrarMsgError(msg);
        return false;
      }

      if (this.nuevoRegistro === false && this.errorDeCarga === false) {
        this.cargandoDatos = true;
        this.getData(this.controlId);
        //  this.tipoUsuario = this.apiServices.getTipoUsuario();
        //  console.log({ tipoUsuario: this.apiServices.getTipoUsuario() });
      } else if (this.nuevoRegistro === true && this.errorDeCarga === false) {
        this.getPaciente(this.pacienteId);
      }

      console.log({ nuevoRegistro: this.nuevoRegistro });
    });



    // this.formData.get('txtTiempo').disable();

  }


  isInt(value) {
    return Number.isInteger(Number(value));
  }

  getPaciente(id: number) {

    console.log({ getPacienteId: id });

    if (id > 0) {
      let urlParams = 'pacientes/' + id;
      // urlParams += 'id/' + pacienteId;
      urlParams += '?grpsa=all&estciv=all';
      urlParams += '&genero=all&ocup=all&ciures=all&depres=all&countryid=all';


      this.apiServices.getData(urlParams, 'Paciente', false)
        .subscribe(data => {

          if (data.success === true) {
            this.dataPaciente = data['Paciente'];
            this.dataPaciente['fecha_actual'] = data.fecha_actual;
            this.pacienteId = this.dataPaciente.id;
            // const fechaActual = data.fecha_actual;
            console.log(data);
            this.datosPacienteCargados = true;

            console.log(this.dataPaciente.alergico);

            if (this.dataPaciente.alergico.trim() === 'Si') {
              this.esAlergico = true;
            } else {
              this.esAlergico = false;
            }

            this.registroEncontrado = true;

          } else {
            this.registroEncontrado = false;

            let msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe 5.';
            //   msg += 'Debe verificar que el formulario no contenga errores';
            this.mostrarMsgError(msg);
          }

          this.cargandoDatos = false;
        }, error => {
          this.cargandoDatos = false;
          this.registroEncontrado = false;
          console.log(error);
        }, () => {
          console.log('Finally');
        });
    } else {
      //  this.cargandoDatos = false;
      this.registroEncontrado = false;
      this.cargandoDatos = false;
      let msg = 'Error al intentar cargar los datos. El ID del paciente es incorrecto o no existe 6.';
      //   msg += 'Debe verificar que el formulario no contenga errores';
      this.mostrarMsgError(msg);
    }

  }

  cboMestruacion_change(valor) {

    if (valor !== undefined) {
      if (valor === 'Si') {
        this.formData.get('txtMestruacion').enable();
        // this.cajasDeTexto.antecedentesPersonales.mestruacionOpcion = 'Si'this.cajasDeTexto.antecedentesPersonales.mestruacionOpcion = 'Si'
      } else if (valor === 'No') {
        this.formData.get('txtMestruacion').disable();
        // this.cajasDeTexto.antecedentesPersonales.mestruacionOpcion = 'No'
      }
    }
    // console.log(this.cajasDeTexto.antecedentesPersonales);
  }

  mostrarMsgError(msg: string) {
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }



  // 1 - Guardar
  btnMostrarConfirmarGuardar_click() {
    this.deshabilitarValidaciones();
    //  console.log({guardarClausurarEditar: guardarClausurarEditar});
    //Es un nuevo registro
    this.guardarClausurarEditar = 'guardar';

    if (this.tipoUsuario === 'MEDICO') {
      this.medicoId = this.userInfo.id;
    }

    //this.tipoAccion = 'nuevo';
    //Para determinar si es clausurar o guardar
    // this.guardarClausurarEditar = guardarClausurarEditar;

    this.verVentanas.verVentanaConfirmarGuardar = true;
    this.verVentanas.titVenCon = 'Confirmación de acción';
    this.verVentanas.tetVenCon = '¿Está seguro(s)?';
  }
  //1.1 

  btnMostrarConfirmarClausurar_click() {

    //    console.log({guardarClausurarEditar: guardarClausurarEditar});
    //Es un nuevo registro
    // this.tipoAccion = 'clausurar'; //Guardar
    //Para determinar si es clausurar o guardar
    if (this.tipoUsuario === 'MEDICO') {
      this.medicoId = this.userInfo.id;
    }

    this.guardarClausurarEditar = 'clausurar';
    this.saltarValidacion = false;
    this.verVentanas.verVentanaConfirmarGuardar = true;
    this.verVentanas.titVenCon = 'Confirmación de acción';
    this.verVentanas.tetVenCon = '¿Está seguro(s)?';
  }

  // 1.2
  btnMostrarConfirmarEditar_click() {

    this.cerrarVentana();
    this.alertMsg.showAlert = false;
    this.deshabilitarValidaciones();

    if (this.tipoUsuario === 'MEDICO') {
      this.medicoId = this.userInfo.id;
    }
    // this.tipoAccion = 'editar';
    this.guardarClausurarEditar = 'editar';
    this.verVentanas.verVentanaConfirmarGuardar = true;
    this.verVentanas.titVenCon = 'Confirmación de acción';
    this.verVentanas.tetVenCon = '¿Está seguro(s)?';
  }

  // 2
  btnConfimarGuardar_click($event) {

    console.log($event);
    this.cerrarVentana();
    this.alertMsg.showAlert = false;

    if ($event === false) {
      return false;
    }

    console.log(this.guardarClausurarEditar)
    if (this.guardarClausurarEditar === 'clausurar') {
      this.habilitarValidaciones();
    } else {
      console.log('deshabilitarValidaciones....');
      this.deshabilitarValidaciones();
    }




    let msg = '';
    console.log(this.guardarClausurarEditar);
    console.log(this.tipoUsuario);

    if ((this.guardarClausurarEditar === 'guardar' || this.guardarClausurarEditar === 'editar')
      && this.tipoUsuario === 'MEDICO') {
      console.log('Entro a saltarValidacion.....');
      this.saltarValidacion = true;
    }

    console.log(this.formData);

    this.submitted = true;
    if (this.formData.invalid) {
      this.msgNotificacion = 'Error al intentar enviar el formulario. ';
      this.msgNotificacion += 'Verifique que el formulario no contenga errorese intente de nuevo';
      this.mostrarMsgError(this.msgNotificacion);
      window.scroll(0, 0);
      return false;
    }


    if ((this.guardarClausurarEditar === 'guardar' || this.guardarClausurarEditar === 'editar')
      && this.tipoUsuario == 'ADMIN') {
      //  let msg = 'Se ha detectado que ';
      msg = 'Se ha detectado que ';
      msg += 'su rol no es Médico. Para poder guardar <br />';
      msg += 'primero debe ingresar las credenciales del médico que será asociado a los cambios realizados. <br />';
      msg += 'Presione el botón Si para continuar.';
      this.msgNotificacion = 'guardar los cambios en el';
      this.tituloBotonCredenciales = 'Guardar cambios';

    } else if (this.guardarClausurarEditar === 'clausurar' && this.tipoUsuario == 'ADMIN') {
      msg = 'Se ha detectado que ';
      msg += 'su rol no es Médico. Para poder clausurar <br />';
      msg += 'primero debe ingresar las credenciales del médico que será asociado al control. <br />';
      msg += 'Presione el botón Si para continuar.';
      this.tituloVentanaCredenciales = 'Clausurar control';
      this.tituloBotonCredenciales = 'Clausurar';
      this.msgNotificacion = 'clausurar';

    } else if (this.guardarClausurarEditar === 'clausurar' && this.tipoUsuario === 'MEDICO') {
      msg = '';
      msg += 'Para poder clausurar <br />';
      msg += 'primero debe ingresar sus credenciales (usuario y contraseña). <br />';
      msg += 'Presione el botón Si para continuar.';
      this.tituloVentanaCredenciales = 'Clausurar control';
      this.tituloBotonCredenciales = 'Clausurar';
      this.msgNotificacion = 'clausurar';
    }


    /****** FIN VALIDAR FORMULARIO */

    if ((this.tipoUsuario === 'MEDICO' || this.tipoUsuario === 'ADMIN') && this.saltarValidacion === false) {
      console.log('Ingreso aqui.....');
      this.verVentanas.verVentanaConfirmarCredenciales = true;
      this.verVentanas.titentanaConfirmarCredenciales = 'Notificación';
      this.verVentanas.tetVentanaConfirmarCredenciales = msg;
      // } else if ($event === true && this.tipoUsuario === 'MEDICO' && this.saltarValidacion === false) { 
      //   this.verVentanas.verVentanaConfirmarCredenciales = true;
      //   this.verVentanas.titentanaConfirmarCredenciales = 'Notificación';
      //   this.verVentanas.tetVentanaConfirmarCredenciales = msg;
    } else if ($event === true) {
      this.enviarDatos();
    }


  }

  // 3
  btnConfirmarCredenciales_click($event) {
    // console.log($event);
    if ($event === true) {
      this.cerrarVentana();
      this.verVentanas.verVentanaCredenciales = true;
    } else {
      this.cerrarVentana();
    }
  }

  // 4
  btnLogin_click($event) {
    console.log($event);
    if ($event !== null) {
      this.cerrarVentana();
      this.medicoId = $event.id;
      this.saltarValidacion = true;
      this.enviarDatos();
    }
  }


  enviarDatos() {

    //console.log(guardarClausurarEditar);
    //  this.submitted = true;
    this.accionEjecutandose = true;
    const formData = new FormData();



    //******** */
    formData.append('paciente_id', '' + this.pacienteId)
    // Consulta
    // const consulta = this.cajasDeTexto.motivoConsulta;
    formData.append('consulta_motivo', this.textMotivoConsulta.value);
    // formData.append('consulta_notas', this.textNotasMotivoConsulta.value);
    // Enfermedad actual
    // const enfermedadActual = this.cajasDeTexto.enfermedadActual;
    formData.append('enfermedad_actual', this.textEnfermedadActual.value);

    // formData.append('ant_alergicos', this.textAlergicos.value);
    if (this.esAlergico) {
      formData.append('alergico', 'Si');
    } else {
      formData.append('alergico', 'No');
    }

    // Signos vitales
    formData.append('signos_temp', this.textTemp.value);
    formData.append('signos_pulso', this.textPulso.value);
    formData.append('signos_frecuencia', this.textFrecuencia.value);
    formData.append('signos_tension1', this.textTension1.value);
    formData.append('signos_tension2', this.textTension2.value);
    formData.append('signos_peso', this.textPeso.value);
    formData.append('signos_talla', this.textTalla.value);
    formData.append('signos_imc', this.textImc.value);
    formData.append('signos_notas', this.textNotasSignos.value);

    // Examen físico
    formData.append('examen_ter_sup', this.textTercioSuperior.value);
    formData.append('examen_ter_med', this.textTercioMedio.value);
    formData.append('examen_ter_inf', this.textTercioInferior.value);
    console.log(this.textCuello.value);
    formData.append('examen_cuello', this.textCuello.value);
    formData.append('examen_anterior', this.textAnterior.value);
    formData.append('examen_posterior', this.textPosterior.value);
    formData.append('examen_abdomen', this.textAbdomen.value);
    formData.append('examen_mien_sup', this.textMiembroSuperior.value);
    formData.append('examen_mien_inf', this.textMiembroInferior.value);
    formData.append('examen_genitales', this.textGenitales.value);
    formData.append('examen_gluteos', this.textGluteos.value);
    formData.append('examen_notas', this.textNotasExamen.value);
    formData.append('examen_mamas', this.textMamas.value);

    // Para-clínicos
    // const paraClinicos = this.cajasDeTexto.paraClinicos;
    formData.append('para_paraclinicos', this.textParaClinicos.value);
    // formData.append('para_notas', this.textNotasClinicos.value);

    // Análisis
    // const analisis = this.cajasDeTexto.analisis;
    formData.append('analisis_analisis', this.textAnalisis.value);
    //  formData.append('analisis_notas', this.textNotasAnalisis.value);

    // Diagnostico
    // const diagnostico = this.cajasDeTexto.diagnostico;

    formData.append('diagnosticos_cie10', JSON.stringify(this.agregarValoresCie10()));
    formData.append('fotos_examen_fisico', JSON.stringify(this.agregarCodigosFotos()));
    formData.append('archivos_plan', JSON.stringify(this.agregarCodigosArchivos()));
    formData.append('diagnostico_manual', this.textDiagnostico.value);
    //  formData.append('diagnostico_notas', this.textDiagnosticoNotas.value);

    // Plan
    // const plan = this.cajasDeTexto.plan;
    formData.append('plan_plan', this.textPlan.value);
    formData.append('medico_id', '' + this.medicoId);
    formData.append('codigo_control', '' + this.codigoControl);
    //  formData.append('plan_notas', this.textPlanNotas.value);

    // General
    // const generales = this.cajasDeTexto.generales;
    formData.append('general_notas', this.textNotasGenerales.value);

    // formData.append('paciente_id', this.pacienteId)

    //************* */
    let url = 'controles';
    console.log(this.guardarClausurarEditar);
    if (this.tipoAccion === 'nuevo') {
      if (this.guardarClausurarEditar === 'clausurar') {
        formData.append('accion', 'clausurar');
      } else {
        formData.append('accion', 'guardar');
      }
    } else if (this.tipoAccion === 'detalle') {
      formData.append('_method', 'PUT');
      if (this.guardarClausurarEditar === 'clausurar') {
        formData.append('accion', 'clausurar');
      } else {
        formData.append('accion', 'editar');
      }
      url = url + '/' + this.controlId;
    }

    this.apiServices.postFormData(formData, url)
      .subscribe(data => {
        console.log(data);
        this.alertMsg.showAlert = true;
        if (data !== null && data.success === true) {
          this.alertMsg.class = 'alert alert-success';

          if (this.guardarClausurarEditar === 'clausurar') {
            this.clausurada = true;
            this.desBtnGuardar = true;
            this.deshabilitarControles();
            this.desBtnBorrarArchivo = true;
            this.desBtnBorrarFoto = true;
            
          } else {
            this.clausurada = false;
            this.desBtnGuardar = false;
          }



          this.alertMsg.msg = data.response + '. Código: ' + data.codigo;
          this.codigoControl = data.codigo;
          this.controlId = data.id;
          this.registroGuardadoExiste = true;

        } else {
          this.alertMsg.class = 'alert alert-danger';
          this.alertMsg.msg = 'Error al intentar procesar el control enviado. Debe intentar de nuevo.';
          //this.codigoControl = data.codigo;
        }

        // if(this.guardarClausurarEditar !== 'editar') {
        //   this.deshabilitarControles();
        //   this.desBtnGuardar = true;
        // }

        this.accionEjecutandose = false;
      }, dataError => {
        console.log(dataError);
        if (dataError.error.response !== undefined) {
          this.mostrarMsgError(dataError.error.response);
        } else {
          this.mostrarMsgError('Error al intentar procesar los datos enviados');
        }

        this.saltarValidacion = false;

        const errors = GetMsgFromError(dataError);
        console.log(errors);

        this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(
          errors.errors
        );

        console.log(this.serverErrorsInfo);
        this.accionEjecutandose = false;
        window.scroll(0, 0);
      }, () => {
        console.log('finally')
        window.scroll(0, 0);
      });
  }

  generarPdf() {
    this.accionEjecutandose = true;
    let url = 'reportes/generarpdf?origen=control';
    url += '&codigo=' + this.codigoControl+'&pacienteid='+this.pacienteId
    this.apiServices.getData(url, 'Reporte', false)
      .subscribe(
        data => {
          console.log(data);

          if (data !== null && data.success === true && data.success !== undefined) {
            //  this.tienePdf = true;
            // this.urlPdf = this.baseUrl2 + data.path;
            this.alertMsg.showAlert = false;
            this.accionEjecutandose = false;
            window.open(this.baseUrl2 + data.path);
          } else {
            this.accionEjecutandose = false;
            this.mostrarMsgError('Error al intentar generar el PDF, Debe intentar de nuevo');
            window.scroll(0, 0);
          }
        }, error => {
          console.log(error);
          //  this.codigoHistoria = 0;
          //  this.cerrarVentana();
          this.accionEjecutandose = false;
          if (error.error.response !== undefined) {
            this.mostrarMsgError(error.error.response);
          } else {
            this.mostrarMsgError('Error al intentar generar el PDF, Debe intentar de nuevo');
          }

          window.scroll(0, 0);
        }, () => {

        });
  }


  guardarControlMedico() {
    this.submitted = true;
    this.deshabilitarValidaciones();
    this.alertMsg.showAlert = false;
    this.alertMsg.msg = '';
    console.log(this.formData);
    this.enviarDatos();
  }

  // Aplicar validaciones a un control

  agregarValoresCie10() {
    this.cie10Rows = this.formData.get('cie10Rows') as FormArray;
    const lengthRows = this.cie10Rows['controls'].length;
    const diagnosticos_cie10 = [];
    if (lengthRows > 0) {
      for (let i = 0; i < lengthRows; i++) {
        const cie10CodigoControl: any = this.cie10Rows.controls[i]['controls']['cie10Codigo'];
        if (cie10CodigoControl.value !== null && cie10CodigoControl.value.length > 0) {
          //  console.log({ codigo: cie10CodigoControl.value, titulo: cie10TituloControl.value });
          const codigo = { codigo: cie10CodigoControl.value };
          // console.log(codigo);
          diagnosticos_cie10.push(codigo);
        }
      }
    }

    return diagnosticos_cie10;

  }

  agregarCodigosFotos() {
    const codigosFotosAgregadas = [];
    if (this.fotosExamenFisico.length > 0) {
      for (let i = 0; i < this.fotosExamenFisico.length; i++) {
        codigosFotosAgregadas.push({ codigo: this.fotosExamenFisico[i].codigo });
      }
    }
    return codigosFotosAgregadas;
  }

  agregarCodigosArchivos() {
    const codigosArchivosAgregados = [];
    if (this.infoArchivos.length > 0) {
      for (let i = 0; i < this.infoArchivos.length; i++) {
        codigosArchivosAgregados.push({ codigo: this.infoArchivos[i].codigo });
      }
    }
    return codigosArchivosAgregados;
  }


  public virtual: any = {
    itemHeight: 28
  };

  handleFilterCie10($event: string, index) {
    if ($event === '' || $event === null || $event === undefined) {
      return false;
    }
    console.log({ Valor_seleccionado: $event });
    if ($event.length > 0) {
      this.buscandoCodigoCie = true;
      this.delay(() => {
        this.apiServices.getData('cies?codigo=' + $event + '&titulo=' + $event + '&filtro=or', 'Cies', false)
          .subscribe(data => {
            console.log(data);
            if (data.success === true) {
              this.dataCies = data['Cies'];
              console.log(this.dataCies);
            }
            this.buscandoCodigoCie = false;
          }, error => {
            this.buscandoCodigoCie = false;
            console.log(error);
          }, () => {

          });

      }, 2000, '');
    }
  }

  public valueChange(value: string, index: number): void {
    console.log('valueChange', value);
    if (value === '' || value === undefined || value === null) {
      this.deleteCie10Row(index);
    }
    if (this.dataCies.length > 0) {
      if (this.verificarValoresRepetidos(value, index) && this.cie10Rows.length > 1) {
        console.log('******** valueChange')
        this.msjErrorCie10 = 'Error: código repetido';
      } else {
        console.log('******** valueChange vacio')
        this.msjErrorCie10 = '';
      }

      this.valoresCie10Agregados.push(value);
      // const item: any = this.dataCies[0];
      // if (value === item.codigo) {
      //   const cie10TituloControl: any = this.cie10Rows.controls[index]['controls']['cie10Titulo'] as FormControl;
      //   cie10TituloControl.setValue(item.titulo);
      // }
    } else {
      this.deleteCie10Row(index);
      //const cie10CodigoControl: any = this.cie10Rows.controls[index]['controls']['cie10Codigo'] as FormControl;
      //  const cie10TituloControl: any = this.cie10Rows.controls[index]['controls']['cie10Titulo'] as FormControl;
      // cie10CodigoControl.setValue('');
    }

    // console.log
  }


  limpiarContoles(omitir = []) {

    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).reset();
      }
    });
  }

  btnGotoUrl(url: string, params = null) {
    // this.verVentanaDetallePaciente = true;
    console.log(url);
    if (params === null) {
      this.router.navigate([url]);
    } else {
      const queryParams = params;
      this.router.navigate([url], { queryParams });
    }

    return false;
  }


  btnAcciones_click($event) {
    console.log($event);

    if ($event.item === 'listado_pacientes') {
      this.btnGotoUrl('ListadoPacientes');

    } else if ($event.item === 'historia_paciente') {
      this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id });

    } else if ($event.item === 'listado_controles') {
      this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id });

    } else if ($event.item === 'habilitar_edicion_nuevo') {
      const omitir = ['cboEstados', 'chkProrroga'];
      this.habilitarControles();
      this.limpiarContoles(omitir);
      this.alertMsg.showAlert = false;
      this.submitted = false;

    } else if ($event.item === 'registro_vacio') {
      // this.alertMsg.showAlert = false;
      this.registroGuardadoExiste = false;
      this.medicoDelControl = '';
      this.saltarValidacion = false;
      this.submitted = false;
      window.scroll(0, 0);
      this.cargarValoresPorDefecto();
      this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id, tipo: 'nuevo' });

    } else if ($event.item === 'agregar_formula') {
      //  console.log({ origen: 'evolucion', codigo_origen: this.evolucionId });
      if (this.registroGuardadoExiste === true) {
        this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id, tipo: 'nuevo', origen: 'control', codigo_origen: this.controlId });;
      } else {
        alert('Primero debe guardar');
      }
      //

    } else if ($event.item === 'agregar_incapacidad') {

      if (this.registroGuardadoExiste === true) {
        this.btnGotoUrl($event.url, { pacienteId: this.dataPaciente.id, tipo: 'nuevo', origen: 'control', codigo_origen: this.controlId });
      } else {
        alert('Primero debe guardar');
      }

    } else if ($event.item === 'imprimir') {
      console.log(this.codigoControl);
      console.log(this.registroGuardadoExiste);

      if (Number(this.codigoControl) > 0 && this.registroGuardadoExiste === true) {
        this.generarPdf();
      } else if (Number(this.codigoControl) === 0) {
        alert('Primero debe guardar');
      }
    }
  }


  resetearControles(omitir = []) {
    this.desBtnGuardar = false;
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).enable();
        this.formData.get(element).reset();
      }
    });

  }

  habilitarControles(omitir = []) {
    this.desBtnGuardar = false;
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).enable();
      }
    });

  }

  deshabilitarControles(omitir = []) {
    this.desBtnGuardar = true;
    Object.keys(this.formData.controls).forEach(element => {
      if (!omitir.includes(element)) {
        this.formData.get(element).disable();
      }
    });
  }


  dataAccionesesGuardar() {
    return [{
      text: 'Volver al listado de pacientes', url: 'ListadoPacientes', item: 'listado_pacientes'
    }, {
      text: 'Volver a la historia clinica del paciente', url: 'HistoriaPaciente', item: 'historia_paciente'
    }, {
      text: 'Volver al historial de controles', url: 'ListadoControles', item: 'listado_controles'
    },

    // {
    //   text: 'Nuevo control a partir de la actual', item: 'reutilizar_datos', url: '',
    // }, 

    {
      text: 'Nuevo control vacío', item: 'registro_vacio', url: 'FormControles',
    },
    { text: 'Generar formula médica', url: 'FormFormulas', item: 'agregar_formula' },
    { text: 'Generar incapacidad', url: 'FormIncapacidades', item: 'agregar_incapacidad' },

    // {
    //   text: 'Habilitar edición', item: 'habilitar_edicion_nuevo', url: '',
    // }, 

    {
      text: 'Imprimir', item: 'imprimir'
    }];
  }

  dataAccionesesEditar() {
    return [{
      text: 'Volver al listado de pacientes', url: 'ListadoPacientes', item: 'listado_pacientes'
    }, {
      text: 'Volver a la historia clinica del paciente', url: 'HistoriaPaciente', item: 'historia_paciente'
    }, {
      text: 'Volver al historial de controles', url: 'ListadoControles', item: 'listado_controles'
    },

    {
      text: 'Nuevo control vacío', item: 'registro_vacio', url: 'FormControles',
    },
    // {
    //   text: 'Nuevo control a partir de la actual', item: 'reutilizar_datos', url: '',
    // },
    { text: 'Generar formula médica', url: 'FormFormulas', item: 'agregar_formula' },

    { text: 'Generar incapacidad', url: 'FormIncapacidades', item: 'agregar_incapacidad' },
    {
      text: 'Imprimir', item: 'imprimir'
    }];
  }


  delay = (function () {
    const timers = {};
    return function (callback, ms, label) {
      label = label || 'defaultTimer';
      window.clearTimeout(timers[label] || 0);
      timers[label] = window.setTimeout(callback, ms);
    };
  })();


  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.cargarArchivos();
  }

  setObjectInStorage(nombre: string, object: any) {
    localStorage.setItem(nombre, JSON.stringify(object));
  }

  getObjectInStorage(nombre: string) {
    const retrievedObject = localStorage.getItem(nombre);
    return JSON.parse(retrievedObject);
  }



  private cargarArchivos(): void {
    switch (this.tipoAccion) {

      case 'nuevo':
        this.gridViewArchivos = {
          data: this.infoArchivos.slice(this.skip, this.skip + this.pageSize),
          total: this.infoArchivos.length
        };
        break;

      case 'detalle':
        let queryString = 'pacientes/archivos/' + this.pacienteId;
        queryString += '?tipo=ARCHIVO_PARA_CLINICO';
        queryString += '&origen=CONTROL';
        queryString += '&codigoasociado=' + this.codigoControl;

        this.apiServices.getData(queryString, 'archivos', false).
          subscribe(data => {
            console.log(data);
            if (data.success === true && data.archivos !== null) {
              this.infoArchivos = data['Archivos'];

              this.pathArchivosDetalle = data.path;

              console.log(this.infoArchivos);


              this.gridViewArchivos = {
                data: this.infoArchivos.slice(this.skip, this.skip + this.pageSize),
                total: this.infoArchivos.length
              };

            } else {
              this.alertMsgFoto.showAlert = true;
              this.alertMsgFoto.class = 'alert alert-danger';
              this.alertMsgFoto.msg = data.response;
            }
          }, data => {
            console.log(data);
            this.alertMsgFoto.showAlert = true;
            this.alertMsgFoto.class = 'alert alert-danger';
            this.alertMsgFoto.msg = data.error.response;
          }, () => {
            console.log('finally cargando archivos ');
            this.subiendoFoto = false;
          });
        break;

    }
  }


  chkHabDesEdicion_click($event) {
    $event.preventDefault();
    this.cerrarVentana();
    this.alertMsg.showAlert = false;

    if (this.tipoUsuario !== 'ADMIN') {
      return false;
    }

    console.log($event);
    console.log(this.chkHabDesEdicion.nativeElement.checked);
    let respuesta = confirm("¿Está seguro?");
    if (respuesta === true) {

      this.apiServices.getData('controles/descerrar/' + this.codigoControl, 'Control', false).
        subscribe(data => {
          console.log(data);
          if (data !== null && data.success !== undefined && data.success === true) {
            this.clausurada = false;
            this.habilitarControles(['txtMestruacion']);
            this.desBtnGuardar = false;
          } else {
            alert('No fue posible habilitar la edición. Debe intentar de nuevo');
          }
          window.scroll(0, 0);
        }, error => {
          if (error.status !== 401) {
            if (error.error.response !== undefined) {
              this.mostrarMsgError(error.error.response);
            } else {
              this.mostrarMsgError('Error al intentar habilitar la edición, debe intentar nuevamente');
            }
          } else {
            this.mostrarMsgError('Error inesperado al intentar habilitar la edición, debe intentar nuevamente');
          }
          window.scroll(0, 0);
        }, () => {

        });
      // txt = "You pressed OK!";
    } else {
      if (this.chkHabDesEdicion.nativeElement.checked === true) {
        this.chkHabDesEdicion.nativeElement.checked = false;
      } else if (this.chkHabDesEdicion.nativeElement.checked === false) {
        this.chkHabDesEdicion.nativeElement.checked = true;
      }

    }

    return false;
  }

  borrarFoto(codigoFoto) {

    this.apiServices.deleteData('archivos', codigoFoto, '?pacienteId=' + this.pacienteId).
      subscribe((data: any) => {

        this.alertMsgArchivo.showAlert = true;
        // const data = response as any;
        if (data.success === true) {
          this.alertMsgArchivo.class = 'alert alert-success';
          //debugger;
        } else {
          this.alertMsgFoto.class = 'alert alert-danger';
        }

        this.alertMsgArchivo.msg = data.response;
      }, data => {
        console.log(data);
        this.alertMsgFoto.showAlert = true;
        this.alertMsgFoto.class = 'alert alert-danger';
        this.alertMsgFoto.msg = 'Error al intentar borrar la foto. Intente de nuevo.';
      }, () => {
        console.log('finally borrar foto');
        // this.subiendoFoto = false;
      });
  }

}
