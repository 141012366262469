import { Component, OnInit, ElementRef, ViewChild, AfterContentInit } from '@angular/core';
import { GridDataResult, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ServiciosService } from '../../servicios/servicios.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { TransferServiceService } from '../../servicios/tra';
import { ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';



@Component({
  selector: 'app-listado-controles',
  templateUrl: './listado-controles.component.html',
  styleUrls: ['./listado-controles.component.css']
})
export class ListadoControlesComponent implements OnInit, AfterContentInit {
  // var grid = $('#Grid').data('kendoGrid')
  @ViewChild(GridComponent, { static: false }) grid: GridComponent;


  public gridView: GridDataResult;
  public buttonCount = 15;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  public skip = 0;
  currentPage = 1;
  public multiple = true;
  public allowUnsort = true;
  windowFilters = false;
  public sort: SortDescriptor[] = [];
  windowFormFilters: FormGroup;
  loading = true;
  queryString = '';
  queryStringTotales = '';
  showWindowFilters = false;
  queryContain = '';
  medicoId = 0;
  medicoNombre = '';
  dataGridViewIndex = 0;
  dataGridViewItem: any;
  paginaInicio = 0;

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  };

  //  loading = true;

  // showItemDetails = false;




  verVentanaDetalleMedico = false;


  public lstStatus: Array<{ text: string, value: number }> = [
    { text: 'Activo', value: 1 },
    { text: 'Inactivo', value: 0 }
  ];

  // loading = true;


  lstData: any[];
  tempLstData: any[];
  public pageSize = 5;
  totalPages = 0;
  pageCount = 0;
  gridData: any[];
  // gridData: GridDataResult;

  pacienteId = 0;

  constructor(
    private apiServices: ServiciosService,
    private formBuilder: FormBuilder,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    //   private transfereService: TransferServiceService,
    private cd: ChangeDetectorRef,
    private router: Router) {

  }

  ngOnInit() {
    

    this.windowFormFilters = this.formBuilder.group({
      txtFiltro: ['']
    });

    this.activatedRoute.queryParams.subscribe(params => {
      const pacienteId = params.pacienteId;
      this.pacienteId = pacienteId;
     // this.getData('controles?page=1&pacienteid='+pacienteId);
    });
    window.scroll(0,0);
  }

  onSubmitFilters() {

    this.loading = true;
    this.queryString = '';

    console.log(this.windowFormFilters);
    // console.log(this.queryString);

    const valFiltro = this.windowFormFilters.get('txtFiltro').value;

    if (valFiltro.length === 0) {
      this.getData('controles?page=1&pacienteid='+this.pacienteId);
      return false;
    }

    let queryString = '';
    if (this.queryString.length === 0) {

      queryString = 'controles?page=1';
      this.queryString += '&codigo=' + valFiltro;
      this.queryString += '&pacienteid='+this.pacienteId;
      this.queryString += '&medicoide=' + valFiltro;
      this.queryString += '&filtro=or';
      queryString = queryString + this.queryString;
    }

    //  this.queryString = queryString;

    console.log(queryString);
     // console.log(this.queryString);

    this.getData(queryString);



    // console.log(this.dataW);

    // this.postItem(this.itemData);
  }

  btnCerrarDetalleMedico() {
    //  this.getData(this.queryStringTotales);
    this.verVentanaDetalleMedico = false;
  }

  eventoCerrarVentana($event) {
    this.verVentanaDetalleMedico = false;
  }

  eventoEditarMedico($itemEditado = false) {
    console.log($itemEditado);
    if ($itemEditado === true) {
      this.getData(this.queryStringTotales);
    }

  }


  btnVerDetalleMedico(dataItem, index) {
 
    const queryParams = {
      medicoId: dataItem.id
    };

    this.router.navigate(['DetalleMedico'], { queryParams });

    return false;

  }



  showFilters() {
    if (this.showWindowFilters) {
      this.showWindowFilters = false;
    } else {
      this.showWindowFilters = true;
    }
    // this.showWindowFilters = val;
  }

 
  ngAfterContentInit() {
    setTimeout(_ => {
      const filaActiva = sessionStorage.getItem('ListadoControlesFilaActiva');
      console.log({ filaActiva: filaActiva });
      if (filaActiva !== null) {
        console.log({ filaActiva: filaActiva });
        this.grid.expandRow(Number(filaActiva))
      }


      const paginaActiva = sessionStorage.getItem('ListadoControlesPaginaActiva');
      console.log({ paginaActiva: paginaActiva });
      if (paginaActiva !== null) {
        this.getData('controles?page=' + paginaActiva + '&pacienteid='+this.pacienteId);
      } else {
        this.getData('controles?page=1' + '&pacienteid='+this.pacienteId);
      }

    }, 0);
  }

  onCellClick(event) {
    // event.dataItem.name = 'hola';
    console.log(event);
    this.apiServices.collapseAll(this.grid, this.currentPage, this.pageSize, Number(this.paginaInicio));
    sessionStorage.setItem('ListadoControlesFilaActiva', event.index)
    sessionStorage.setItem('ListadoControlesPaginaActiva', '' + this.currentPage)
    // event.sender.expandRow(event.rowIndex);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    console.log({ 'state': state });
    this.loading = true;
    this.skip = state.skip;
    this.pageSize = state.take;
    this.sort = state.sort;

    if (this.skip === 0) {
      this.skip = 1;
    }

    this.currentPage = Math.floor(this.skip / this.pageSize);
    this.currentPage++;
    sessionStorage.setItem('ListadoControlesPaginaActiva', '' + this.currentPage);

    let queryString = 'controles?page=' + this.currentPage;

    if (this.sort.length > 0) {
      const sortDir = this.sort[0].dir;
      const sortField = this.sort[0].field;
      queryString += '&sortfield=' + sortField + '&sortdir=' + sortDir;
    }

    if (this.queryString.length === 0) {
      queryString += '&espid=all&pacienteid=' + this.pacienteId;
    }

    queryString = queryString + this.queryString;
    console.log(queryString);

    this.getData(queryString);

    setTimeout(_ => {
       this.apiServices.collapseAll(this.grid, this.currentPage, this.pageSize, Number(this.paginaInicio));
    }, 0);

    //this.onSubmitFilters(this.currentPage);
  }



  public filterChange(filter: CompositeFilterDescriptor): void {
    console.log(filter);
  }



  getData(queryString: string = '') {
    this.queryStringTotales = queryString;
    // console.log({ 'queryStringTotales': this.queryStringTotales });
    this.apiServices.getData(queryString, 'Controles', false).subscribe(
      data => {
        this.lstData = this.tempLstData = data['Controles'];

        console.log(this.lstData); 
        if (this.lstData !== null && data.success !== undefined && data.success === true) {

          this.totalPages = data.paginationInfo.count;

          this.gridView = {
            data: this.lstData,
            total: this.totalPages
          };

          // Paginación inteligente

          if (data.paginationInfo !== undefined) {
            this.pageSize = Number(data.paginationInfo.perPage);
            const page = Number(data.paginationInfo.page);
            const perPage = Number(data.paginationInfo.perPage);
            console.log((page * perPage) - perPage);
            const saltar = (page * perPage) - perPage;
            this.skip = saltar;
            this.paginaInicio = data.paginationInfo.start;
          }
        } else {
          this.gridView = {
            data: [],
            total: 0
          };
        }

        //  console.log(this.gridView);
        // this.grid.resetGroupsState();
        // this.grid.que
        this.loading = false;
        // this.loading = false;

      }, (error) => {
        this.mostrarMsgError('Error al intentar cargar los registros. Intente de nuevo.')
        this.loading = false;
        // this.loading = false;
      });


  }

  mostrarMsgError(msg: string) {
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }




  verHistoriaPaciente(dataItem) {
    // console.log({ 'invoiceId': id });
    console.log(dataItem);
    const queryParams = {
      pacienteId: this.pacienteId
    };

    this.router.navigate(['AgregarControl'], { queryParams: queryParams });

    return false;
  }

  btnAgregarNuevoControl_click() {

    const queryParams = {
      pacienteId: this.pacienteId,
      tipo:'nuevo'
    };

    this.router.navigate(['FormControles'], { queryParams: queryParams });

    return false;
  }


  btnVerDetalleControl(dataItem, rowIndex) {
    // console.log({ 'invoiceId': id });
    const queryParams = {
      controlId: dataItem.id,
      tipo: 'detalle'
    };

    this.router.navigate(['FormControles'], { queryParams: queryParams });
    // this.router.navigate(['FormControles'], { queryParams: queryParams, skipLocationChange : true});
    return false;
  }
}
