import { Injectable } from '@angular/core';
// import { ErrorDialogService } from '../error-dialog/errordialog.service';
import { EncryptionService } from '../servicios/encryption.service';
import {
  HttpInterceptor,
  HttpRequest,
  // HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
  // HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { tap, filter, take, switchMap, catchError } from 'rxjs/operators';
// import { map, catchError } from 'rxjs/operators';
import { ServiciosService } from '../servicios/servicios.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  refreshTokenInProgress = false;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  // private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
  //   null
  // );



  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.apiServices.getToken();
    //   //console.log(token);


    //if (token !== null) {
    request = this.addToken(request, token);

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return this.handle401Error(request, next);
      } else if (error instanceof HttpErrorResponse && error.status === 403) {

        localStorage.clear();
        sessionStorage.clear();

        if (error.error.response !== undefined) {
          alert('' + error.error.response);
        } else {
          alert('Su cuenta fue desactivada por el Administrador' + error.error.response)
        }
       // //console.log(error);
        //  //console.log(error.error.response);

        window.location.reload();
        return throwError(error);
        
      } else {
        return throwError(error);
      }
    }));
    // return next.handle(request);
  }
  //return next.handle(request);
  //}

  constructor(private apiServices: ServiciosService, private encryption: EncryptionService) {

  }

  private addToken(request: HttpRequest<any>, token: string) {
    const httpHeaders = new HttpHeaders({
      //    'Content-Type': 'multipart/form-data',
      //'Content-Type': 'application/json; charset=utf-8',
      // 'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    const options = {
      headers: httpHeaders
    };

    return request.clone(options);
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.apiServices.refreshToken().pipe(
        switchMap((token: any) => {
          //console.log('Token refreshed');
          //console.log(token);
          //   const token = data.token;
          this.isRefreshing = false;
          // this.authService.saveAccessToken(token['access']);
          localStorage.setItem("JWT_TOKEN", token.token);
          this.refreshTokenSubject.next(token.token);
          return next.handle(this.addToken(request, token.token));
        }), catchError(error => {
          // //console.log('hi88888888888888');
          if (error instanceof HttpErrorResponse && error.status === 401) {
            // this.authService.logout(); logout
            return
          } else {
            return throwError(error);
          }
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          //console.log(jwt);
          localStorage.setItem("JWT_TOKEN", jwt);
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}