import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiciosService } from 'src/app/servicios/servicios.service';
import { GetMsgFromError } from 'src/app/functions/SharedFunctions';
import { serverErrorsInfo } from 'src/app/utilities/serverErrorsInfo';

@Component({
  selector: 'app-agenda-medicos',
  templateUrl: './agenda-medicos.component.html',
  styleUrls: ['./agenda-medicos.component.css']
})
export class AgendaMedicosComponent implements OnInit {

  medicoId = 0;
  spinnerCargandoDatos = false;
  tipoUsuario: any;
  registroEncontrado = false;
  serverErrorsInfo: any;
  data = [];
  dataExcel = [];
  buscandoAgenda = false;
  userInfo: any;
  nombreArchivo = '';
  generandoReporte = false;
  nombreMedico = '';
  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  };

  ngModelFecha = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiServices: ServiciosService
  ) {
    window.scrollTo(0, 0);

    this.activatedRoute.queryParams.subscribe(params => {
      const medicoId = params.medicoId;
      this.medicoId = medicoId;
      console.log(medicoId);
      // this.getData(medicoId);
    });

  }

  ngOnInit(): void {
  }

  ngAfterContentInit() {
    this.spinnerCargandoDatos = true;
    //this.medicoId = this.inputMedicoId;


    this.tipoUsuario = this.apiServices.getTipoUsuario();
    const userInfo = this.apiServices.getUserInfoFromToken();
    this.userInfo = userInfo.userInfo;
  //  this.medicoId = userInfo.userInfo.id;
    if (this.tipoUsuario === 'MEDICO') {
      this.medicoId = userInfo.userInfo.id;
    }
  }

  getCitas(medicoId, fecha) {
    console.log({ medicoId: medicoId })

    if (medicoId !== undefined && Number(medicoId) > 0) {
      let urlParams = 'citas/medicos/' + medicoId;
      urlParams += '?fecha=' + fecha;

      // let item: Item;
      this.apiServices.getData(urlParams, 'Citas', false).subscribe(
        data => {
          console.log({ registroEncontrado: this.registroEncontrado })
          console.log(data);
          if (data !== null && data.success !== undefined && data.success === true) {
            this.alertMsg.showAlert = false;
            this.data = data['Citas'];

            if (this.nombreMedico.length === 0) {
              this.nombreMedico =  this.data[0].Medico.nombres + ' ' +  this.data[0].Medico.apellidos + ' (' +  this.data[0].Medico.identificacion + ')';
            }

            console.log(this.data);

          } else {

          }

          this.buscandoAgenda = false;

          this.spinnerCargandoDatos = false;
        },
        error => {
          console.log(error);
          const errors = GetMsgFromError(error);
          this.alertMsg.showAlert = true;
          this.alertMsg.class = 'alert alert-danger';
          this.alertMsg.msg = '' + errors.msg;
          this.spinnerCargandoDatos = false;
          this.buscandoAgenda = false;
          this.serverErrorsInfo = serverErrorsInfo.setServerErrorsInfo(errors.errors);
          this.data = [];
          console.log(this.serverErrorsInfo);
          // this.spinners.mostrarSpinner = false;
        },
        () => {
          console.log('Finally');
          //  this.loadedData = true;
          // this.spinners.mostrarSpinner = false;
        }
      );
    } else {
      //  this.loadingData = false;
      this.spinnerCargandoDatos = false;
      this.registroEncontrado = false;
      this.buscandoAgenda = false;

      this.alertMsg.showAlert = true;
      this.alertMsg.class = 'alert alert-danger';
      this.alertMsg.msg = 'Error al intentar cargar el registro';
    }
  }

  public showSuccess(msg: string): void {
    this.alertMsg.showAlert = true;
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.msg = msg;
  }

  public showError(msg: string): void {
    this.alertMsg.showAlert = true;
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.msg = msg;
  }

  generarReporte(componente: any) {
    console.log(this.ngModelFecha);
    console.log(this.userInfo);
    this.generandoReporte = true;

    if (this.ngModelFecha.length === 0) {
      this.showError('Debe seleccionar un mes');
      return false;
    }

    this.nombreMedico = '';
    this.nombreArchivo = '';
    this.dataExcel = [];

    this.data.forEach((element, index, array) => {
      console.log(element); // 100, 200, 300
      console.log(index); // 0, 1, 2
      console.log(array); // same myArray object 3 times

      console.log(this.nombreMedico.length);
      console.log(this.nombreArchivo.length);

      if (this.nombreMedico.length === 0) {
        this.nombreMedico = array[0].Medico.nombres + ' ' + array[0].Medico.apellidos + ' (' + array[0].Medico.identificacion + ')';
      }

      if (this.nombreArchivo.length === 0) {
        this.nombreArchivo = 'Agenda_';
        this.nombreArchivo += array[0].Medico.nombres + '_' + array[0].Medico.apellidos + '_' + array[0].Medico.identificacion;
        this.nombreArchivo += '.xlsx';
      }

      const object = {
        codigo: element.codigo,
        paciente: element.Paciente.nombres + ' ' + element.Paciente.apellidos + ' (' + element.Paciente.identificacion + ')',
        tipo_cita: element.Tipocita.nombre,
        especialidad: element.Especialidad.nombre,
        medico: element.Medico.nombres + ' ' + element.Medico.apellidos + ' (' + element.Medico.identificacion + ')',
        consultorio: element.Consultorio.nombre,
        fecha: element.fecha_cita,
        horario: element.horario,
        estado: element.estado
      }

      this.dataExcel.push(object);
      //  console.log(element); // 100, 200, 300
      //   console.log(index); // 0, 1, 2
      //   console.log(array); // same myArray object 3 times

    });

    console.log(this.nombreMedico);
    console.log(this.nombreArchivo);
    console.log(this.dataExcel);

    // const tempDate = new Date(this.ngModelFecha);
    // const fecha = tempDate.toISOString();
    // console.log(fecha);
    // this.ngModelFecha = this.ngModelFecha.toString();
    //  this.getCitas(this.medicoId, fecha);
    setTimeout ( () => { componente.save(); this.generandoReporte = false; }, 1000 );
    // componente.save();
  }

  cboVisualizarAgenda_change($event) {
    console.log($event);
    if ($event !== null) {
      this.buscandoAgenda = true;
      this.alertMsg.showAlert = false;
      this.delay(() => {

        //console.log(data)
        const tempDate = new Date(this.ngModelFecha);
        const fecha = tempDate.toISOString();
        console.log(fecha);
        // this.ngModelFecha = this.ngModelFecha.toString();
        this.getCitas(this.medicoId, fecha);

        //   this.dataCies = data['Cies'];
        //   console.log(this.dataCies);

        // this.buscandoCodigoCie = false;


      }, 2000, '');
    } else {
      this.data = [];
    }
  }

  delay = (function () {
    const timers = {};
    return function (callback, ms, label) {
      label = label || 'defaultTimer';
      window.clearTimeout(timers[label] || 0);
      timers[label] = window.setTimeout(callback, ms);
    };
  })();
}
