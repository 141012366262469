import { Component, OnInit, ViewChild, Output, EventEmitter, Input, AfterContentInit } from '@angular/core';
import { GridDataResult, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ServiciosService } from '../../servicios/servicios.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-listado-fondos-pension',
  templateUrl: './listado-fondos-pension.component.html',
  styleUrls: ['./listado-fondos-pension.component.css']
})
export class ListadoFondosPensionComponent implements OnInit, AfterContentInit {
  @Output() messageEvent = new EventEmitter<any>();
  @Input() verBotonSeleccionar = false;
  @Input() verDetalles = true;
  @Input() esVentanaModal = false;
  // Filters
  // @ViewChild('txtCodigo', { static: false }) txtId: ElementRef;
  // @ViewChild('txtTitulo', { static: false }) txtName: ElementRef;
  // @ViewChild('txtReference', { static: false }) txtReference: ElementRef;
  @ViewChild(GridComponent, { static: false }) grid: GridComponent;


  // var grid = $('#Grid').data('kendoGrid')

  alertMsg = {
    showAlert: false,
    msg: '',
    class: ''
  }


  public gridView: GridDataResult;
  public buttonCount = 15;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  public skip = 0;
  currentPage = 1;
  public multiple = true;
  public allowUnsort = true;
  windowFilters = false;
  public sort: SortDescriptor[] = [];
  windowFormFilters: FormGroup;
  loading = true;
  queryString = '';
  queryStringTotales = '';
  showWindowFilters = false;


  // medicoId = 0;
  // medicoNombre = '';
  dataGridViewIndex = 0;
  dataGridViewItem: any;

  queryContain = '&limit=5'
  paginaInicio = 0;
  //  loading = true;

  // showItemDetails = false;

  verVentanaDetallePaciente = false;
  verVentanaHistoriaPaciente = false;


  // dataPricesList: Array<{ id: string, name: string }>;
  // dataUnitMeasurements: Array<{ id: string, name: string }>;
  // dataCategories: Array<{ id: string, name: string }>;
  // datawarehouses: Array<{ id: string, name: string }>;
  // dataAccounts: Array<{ id: string, name: string }>;
  // dataTaxes: Array<{ id: string, name: string }>;

  public lstStatus: Array<{ text: string, value: number }> = [
    { text: 'Activo', value: 1 },
    { text: 'Inactivo', value: 0 }
  ];

  // loading = true;


  lstData: any[];
  tempLstData: any[];
  public pageSize = 10;
  totalPages = 0;
  pageCount = 0;
  gridData: any[];
  // gridData: GridDataResult;


  constructor(
    private apiServices: ServiciosService,
    private formBuilder: FormBuilder,
    //  private location: Location,
    //   private transfereService: TransferServiceService,
    //  private cd: ChangeDetectorRef,
    private router: Router) {

  }

  ngOnInit() {
    //  this.getData('fondopensions?page=1' + this.queryContain);

    this.windowFormFilters = this.formBuilder.group({
      txtFiltro: ['']
    });

    if (this.esVentanaModal === false) {
      window.scroll(0, 0);
    }

  }

  ngAfterContentInit() {

    //  this.apiServices.refreshToken().
    // subscribe(item => {
    //   console.log('Refresh...');
    //   console.log(item);
    // }, error => {
    //   console.log(error);
    // }, () => {
    //   console.log('finally');
    // });


    // this.removerVariablesSessionStorage();

    setTimeout(_ => {

      // const paginaActiva = sessionStorage.getItem('ListadoPacientesPaginaActiva');
      const queryString = sessionStorage.getItem('ListadoFondosPensionQueryString');

      console.log(queryString);

      if (queryString === null) {
        this.getData('fondopensions?page=1' + this.queryContain);
      } else {
        this.getData(queryString);
      }

      const valFiltro = sessionStorage.getItem('ListadoFondosPensionValFiltro');
      this.windowFormFilters.get('txtFiltro').value;
      if (valFiltro !== null) {
        this.windowFormFilters.get('txtFiltro').setValue(valFiltro);
      }

      const filaActiva = sessionStorage.getItem('ListadoFondosPensionFilaActiva');
      console.log({ filaActiva: filaActiva });
      if (filaActiva !== null) {
        console.log({ filaActiva: filaActiva });
        this.grid.expandRow(Number(filaActiva))
      }
      /*   const paginaActiva = sessionStorage.getItem('ListadoPacientesPaginaActiva');
         console.log({ paginaActiva: paginaActiva });
         if (paginaActiva !== null) {
           this.getData('fondopensions?page=' + paginaActiva + this.queryContain);
         } else {
           this.getData('fondopensions?page=1' + this.queryContain);
         }*/

    }, 0);
  }


  onSubmitFilters() {

    this.loading = true;
    this.queryString = '';

    console.log(this.windowFormFilters);
    // console.log(this.queryString);

    const valFiltro = this.windowFormFilters.get('txtFiltro').value;
    sessionStorage.setItem('ListadoFondosPensionValFiltro', valFiltro);

    if (valFiltro.length === 0) {
      this.getData('fondopensions?page=1' + this.queryContain);
      setTimeout(_ => {
         this.apiServices.collapseAll(this.grid, this.currentPage, this.pageSize, Number(this.paginaInicio));
      }, 0);
      return false;
    }

    let queryString = '';
    if (this.queryString.length === 0) {

      queryString = 'fondopensions?page=1';
      this.queryString += '&nombre=' + valFiltro;
      this.queryString += '&codigo=' + valFiltro;
      this.queryString += '&filtro=or';
      this.queryString += this.queryContain;

      queryString = queryString + this.queryString;
    }

    //  this.queryString = queryString;

    console.log(queryString);

    this.getData(queryString);



    // console.log(this.dataW);

    // this.postItem(this.itemData);
  }


  onCellClick(event) {
    // event.dataItem.name = 'hola';
    console.log(event);
     this.apiServices.collapseAll(this.grid, this.currentPage, this.pageSize, Number(this.paginaInicio));
    sessionStorage.setItem('ListadoFondosPensionFilaActiva', event.index)
    // event.sender.expandRow(event.rowIndex);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    console.log({ 'state': state });
   // sessionStorage.removeItem('ListadoFondosPensionFilaActiva');

    this.skip = state.skip;
    this.pageSize = state.take;
    this.sort = state.sort;

    if (this.skip === 0) {
      this.skip = 1;
    }

    this.currentPage = Math.floor(this.skip / this.pageSize);
    this.currentPage++;


    let queryString = 'fondopensions?page=' + this.currentPage;

    // if (this.sort.length > 0) {
    //   const sortDir = this.sort[0].dir;
    //   let sortField = this.sort[0].field;
    //   // console.log(sortField);
    //   if (''+sortField === 'GrupoSanguineo.nombre') {
    //     sortField = 'grpid';
    //   } else if (''+sortField === 'nombre_completo') {
    //     sortField = 'nombres';
    //   }
    //   queryString += '&sortfield=' + sortField + '&sortdir=' + sortDir;
    // }

    if (this.queryString.length === 0) {
      queryString += this.queryContain;
    }

    queryString = queryString + this.queryString;
    console.log(queryString);

    this.getData(queryString);

    //Cerrar todas las filas
    setTimeout(_ => {
       this.apiServices.collapseAll(this.grid, this.currentPage, this.pageSize, Number(this.paginaInicio));
    }, 0);

    //this.onSubmitFilters(this.currentPage);
  }


  /*public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.getData('?page=' + this.currentPage);
  } */

  public filterChange(filter: CompositeFilterDescriptor): void {
    console.log(filter);
  }


  getData(queryString: string = '') {
    this.alertMsg.showAlert = false;
    this.queryStringTotales = queryString;
    console.log({ 'queryStringTotales': this.queryStringTotales });
    sessionStorage.setItem('ListadoFondosPensionQueryString', this.queryStringTotales);
    this.apiServices.getData(queryString, 'fondopensions', false).subscribe(
      data => {
        this.lstData = this.tempLstData = data['Fondopensions'];

        console.log(data);
        if (data !== null && data.success === true && this.lstData !== null) {

          this.totalPages = data.paginationInfo.count;

          this.gridView = {
            data: this.lstData,
            total: this.totalPages
          };

          if (data.paginationInfo !== undefined) {
            this.pageSize = Number(data.paginationInfo.perPage);
            const page = Number(data.paginationInfo.page);
            const perPage = Number(data.paginationInfo.perPage);
            console.log((page * perPage) - perPage);
            const saltar = (page * perPage) - perPage;
            this.skip = saltar;
            this.paginaInicio = data.paginationInfo.start;
          }

        } else {
          this.removerVariablesLocalesSessionStorage();
          this.gridView = {
            data: [],
            total: 0
          };
        }
        //  console.log(this.gridView);
        // this.grid.resetGroupsState();
        // this.grid.que
        this.loading = false;
        // this.loading = false;

      }, (error) => {
        console.log(error);
        if(error.error.response !== undefined) {
          this.mostrarMsgError(error.error.response);
        } else {
          this.mostrarMsgError('Error al intentar cargar el listado, debe intentar nuevamente');
        }

      
        this.loading = false;
        // this.loading = false;
      });


  }


  mostrarMsgError(msg: string) {
    this.alertMsg.class = 'alert alert-danger';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }

  mostrarMsgExito(msg: string) {
    this.alertMsg.class = 'alert alert-success';
    this.alertMsg.showAlert = true;
    this.alertMsg.msg = msg;
  }


  removerVariablesLocalesSessionStorage() {
    sessionStorage.removeItem('ListadoFondosPensionValFiltro');
    sessionStorage.removeItem('ListadoFondosPensionQueryString');
    sessionStorage.removeItem('ListadoFondosPensionFilaActiva');
     this.apiServices.collapseAll(this.grid, this.currentPage, this.pageSize, Number(this.paginaInicio));
  }

  btnAgregarNuevo_click(url: string) {

    const queryParams = {
      tipo: 'nuevo'
    };
    this.router.navigate([url], { queryParams });
    return false;
  }

  btnVerDetalleItem_click(url:string, dataItem, index) {

    const queryParams = {
      tipo: 'detalle',
      parametroId: ''+dataItem.id
    };
    this.router.navigate([url], { queryParams });
    return false;
  }
  
}


