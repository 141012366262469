export function GetMsgFromError(error) {
  const resSTR = JSON.stringify(error);
  const resJSON = JSON.parse(resSTR);

  console.log(resJSON);
  console.log(resJSON.error.errors);
  // this.showAlert = true;

  let msg = '';
  msg = resJSON.error.response;
  // msg += '<br /><br />';
  const data = resJSON.error.errors;
  const arr = [];

  const response = {
    msg: '',
    errors: []
  };

  for (const key in data) {
    // console.log(data);
    if (data.hasOwnProperty(key)) {
      const val = data[key];
      const item = { 'key': key, 'value': val };
      arr.push(item);
      // msg += '* ' + key + ' = ' + val;
      // msg += '* ' + val;
      // msg += '<br />';
    }
  }

  response.msg = resJSON.error.response;
  response.errors = arr;

  console.log(response);

  return response;
}
