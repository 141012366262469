import { CustomAlertComponent } from './shared/custom-alert/custom-alert.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuLateralComponent } from './layout/menu-lateral/menu-lateral.component';
import { AgregarMedicoComponent } from './medicos/agregar-medico/agregar-medico.component';
import { AgregarPacientePotencialComponent } from './pacientes-potenciales/agregar-paciente-potencial/agregar-paciente-potencial.component';
import { RutaModuloComponent } from './layout/ruta-modulo/ruta-modulo.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomComboBoxComponent } from './Shared/custom-combo-box/custom-combo-box.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { ListadoMedicosComponent } from './medicos/listado-medicos/listado-medicos.component';
import { ListadoPacientesPotencialesComponent } from './pacientes-potenciales/listado-pacientes-potenciales/listado-pacientes-potenciales.component';
import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DetalleMedicoComponent } from './medicos/detalle-medico/detalle-medico.component';
import { DetallePacientesPotencialesComponent } from './pacientes-potenciales/detalle-paciente-potencial/detalle-paciente-potencial.component';
//import { AgregarPacienteComponent } from './pacientes/agregar-paciente/agregar-paciente.component';
import { AgregarListadoPacientesComponent } from './pacientes/agregar-listado-pacientes/agregar-listado-pacientes.component';
import { ListadoPacientesComponent } from './pacientes/listado-pacientes/listado-pacientes.component';
import { DetallePacienteComponent } from './pacientes/detalle-paciente/detalle-paciente.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { HistoriaPacienteComponent } from './pacientes/historia-paciente/historia-paciente.component';
import { AgregarCitasComponent } from './citas/agregar-citas/agregar-citas.component';
import { VentanaBuscarMedicoComponent } from './medicos/ventana-buscar-medico/ventana-buscar-medico.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { VentanaBuscarPacienteComponent } from './pacientes/ventana-buscar-paciente/ventana-buscar-paciente.component';
import { ListadoCitasComponent } from './citas/listado-citas/listado-citas.component';
import { LoginComponent } from './usuarios/login/login.component';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { DialogoConfirmacionComponent } from './Shared/dialogo-confirmacion/dialogo-confirmacion.component';
import { VerReportesComponent } from './reportes/ver-reportes/ver-reportes.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';

import { ListadoConsultoriosComponent } from './consultorios/listado-consultorios/listado-consultorios.component';

import { ListadoGenerosComponent } from './generos/listado-generos/listado-generos.component';



import { ListadoDepartamentosComponent } from './departamentos/listado-departamentos/listado-departamentos.component';

import { ListadoCiudadesComponent } from './ciudades/listado-ciudades/listado-ciudades.component';
//import { ListadoCie10Component } from './cie10/listado-cie10/listado-cie10.component';
//import { AgregarCie10Component } from './cie10/agregar-cie10/agregar-cie10.component';
//import { AgregarCirugiaComponent } from './cirugias/agregar-cirugia/agregar-cirugia.component';
import { ListadoCirugiasComponent } from './cirugias/listado-cirugias/listado-cirugias.component';




import { ListadoControlesComponent } from './controles/listado-controles/listado-controles.component';

//import { AgregarIncapacidadComponent } from './incapacidades/agregar-incapacidad/agregar-incapacidad.component';
import { ListadoIncapacidadesComponent } from './incapacidades/listado-incapacidades/listado-incapacidades.component';

import { ListadoEvolucionesComponent } from './evoluciones/listado-evoluciones/listado-evoluciones.component';

import { DatosPacienteComponent } from './shared/datos-paciente/datos-paciente.component';
import { ListadoFormulasComponent } from './formulas/listado-formulas/listado-formulas.component';
import { JwtInterceptor} from './interceptor/jwt.interceptor';
import { AgregarUsuarioComponent } from './usuarios/agregar-usuario/agregar-usuario.component';
import { ListadoUsuariosComponent } from './usuarios/listado-usuarios/listado-usuarios.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { DetalleUsuarioComponent } from './usuarios/detalle-usuario/detalle-usuario.component';
import { AuditoriasComponent } from './auditorias/auditorias/auditorias.component';
import { AuditsComponent } from './auditorias/audits/audits.component';
import { VentanaNotificacionComponent } from './Shared/ventana-notificacion/ventana-notificacion.component';
import { VentanaBotonComponent } from './Shared/ventana-boton/ventana-boton.component';
import { VentanaAdjuntarFotosComponent } from './Shared/ventana-adjuntar-fotos/ventana-adjuntar-fotos.component';

import { DatosPacienteFormulaComponent } from './pacientes/datos-paciente-formula/datos-paciente-formula.component';
import { DatosPacienteIncapacidadComponent } from './pacientes/datos-paciente-incapacidad/datos-paciente-incapacidad.component';
//import { DetalleIncapacidadComponent } from './incapacidades/detalle-incapacidad/detalle-incapacidad.component';
import { ValidarCredencialesComponent } from './usuarios/validar-credenciales/validar-credenciales.component';
//import { DetalleCirugiaComponent } from './cirugias/detalle-cirugia/detalle-cirugia.component';
import { FormCirugiasComponent } from './cirugias/form-cirugias/form-cirugias.component';
import { FormIncapacidadesComponent } from './incapacidades/form-incapacidades/form-incapacidades.component';
import { FormFormulasComponent } from './formulas/form-formulas/form-formulas.component';
import { FormControlesComponent } from './controles/form-controles/form-controles.component';
import { FormEvolucionesComponent } from './evoluciones/form-evoluciones/form-evoluciones.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { AgendaMedicosComponent } from './medicos/agenda-medicos/agenda-medicos.component';
import { FormGenerosComponent } from './generos/form-generos/form-generos.component';
import { FormGruposSanComponent } from './grupossan/form-grupos-san/form-grupos-san.component';
import { FormTiposIdeComponent } from './tiposide/form-tipos-ide/form-tipos-ide.component';
import { FormEstadosCivComponent } from './estadosciv/form-estados-civ/form-estados-civ.component';
import { FormNivelesEscComponent } from './nivelesesc/form-niveles-esc/form-niveles-esc.component';
import { FormDepartamentosComponent } from './departamentos/form-departamentos/form-departamentos.component';
import { FormCiudadesComponent } from './ciudades/form-ciudades/form-ciudades.component';
import { FormEspecialiadadesComponent } from './especialidades/form-especialiadades/form-especialiadades.component';

import { FormEpsComponent } from './eps/form-eps/form-eps.component';
import { FormCiesComponent } from './cie10/form-cies/form-cies.component';
import { ListadoCiesComponent } from './cie10/listado-cies/listado-cies.component';
import { ListadoGruposSanComponent } from './grupossan/listado-grupos-san/listado-grupos-san.component';

import { ListadoTiposIdsComponent } from './tiposide/listado-tipos-ids/listado-tipos-ids.component';
import { ListadoEstadosCivComponent } from './estadosciv/listado-estados-civ/listado-estados-civ.component';
import { ListadoNivelesEscComponent } from './nivelesesc/listado-niveles-esc/listado-niveles-esc.component';
import { ListadoEpsComponent } from './eps/listado-eps/listado-eps.component';
import { FormFondosPensionComponent } from './fondospension/form-fondos-pension/form-fondos-pension.component';
import { ListadoFondosPensionComponent } from './fondospension/listado-fondos-pension/listado-fondos-pension.component';
import { ListadoEspecialdiadesComponent } from './especialidades/listado-especialdiades/listado-especialdiades.component';
import { FormConsultoriosComponent } from './consultorios/form-consultorios/form-consultorios.component';
import { FormTiposCitasComponent } from './tiposcitas/form-tipos-citas/form-tipos-citas.component';
import { ListadoTipoCitasComponent } from './tiposcitas/listado-tipo-citas/listado-tipo-citas.component';
import { DatosEmpresaComponent } from './empresa/datos-empresa/datos-empresa.component';
import { FormOcupacionesComponent } from './ocupaciones/form-ocupaciones/form-ocupaciones.component';
import { ListadoOcupacionesComponent } from './ocupaciones/listado-ocupaciones/listado-ocupaciones.component';
import { FormCountriesComponent } from './countries/form-countries/form-countries.component';
import { ListadoCountriesComponent } from './countries/listado-countries/listado-countries.component';

//import { FormCiesComponent } from './cies/form-cies/form-cies.component';


@NgModule({
  declarations: [
    AppComponent,
    MenuLateralComponent,
    AgregarMedicoComponent,
    AgregarPacientePotencialComponent,
    RutaModuloComponent,
    CustomComboBoxComponent,
    CustomAlertComponent,
    ListadoMedicosComponent,
    ListadoPacientesPotencialesComponent,
    DetalleMedicoComponent,
    DetallePacientesPotencialesComponent,
 //   AgregarPacienteComponent,
    AgregarListadoPacientesComponent,
    ListadoPacientesComponent,
    DetallePacienteComponent,
    HistoriaPacienteComponent,
    AgregarCitasComponent,
    VentanaBuscarMedicoComponent,
    VentanaBuscarPacienteComponent,
    ListadoCitasComponent,
    LoginComponent,
    DialogoConfirmacionComponent,
    VerReportesComponent,
    ListadoGruposSanComponent,
    ListadoConsultoriosComponent,
    ListadoGenerosComponent,
    ListadoEstadosCivComponent,
    ListadoNivelesEscComponent,
    ListadoDepartamentosComponent,
    ListadoCiudadesComponent,
    //ListadoCie10Component,
   // AgregarCie10Component,
   // AgregarCirugiaComponent,
    ListadoCirugiasComponent,
    ListadoControlesComponent,
   // AgregarIncapacidadComponent,
    ListadoIncapacidadesComponent,
    ListadoEvolucionesComponent,
    DatosPacienteComponent,
    ListadoFormulasComponent,
    AgregarUsuarioComponent,
    ListadoUsuariosComponent,
    DetalleUsuarioComponent,
    AuditoriasComponent,
    AuditsComponent,
    VentanaNotificacionComponent,
    VentanaBotonComponent,
    VentanaAdjuntarFotosComponent,
    DatosPacienteFormulaComponent,
    DatosPacienteIncapacidadComponent,
  //  DetalleIncapacidadComponent,
    ValidarCredencialesComponent,
   // DetalleCirugiaComponent,
    FormCirugiasComponent,
   FormIncapacidadesComponent,
   FormFormulasComponent,
   FormControlesComponent,
   FormEvolucionesComponent,
   AgendaMedicosComponent,
   FormGenerosComponent,
   FormGruposSanComponent,
   FormTiposIdeComponent,
   FormEstadosCivComponent,
   FormNivelesEscComponent,
   FormDepartamentosComponent,
   FormCiudadesComponent,
   FormEspecialiadadesComponent,
   FormTiposCitasComponent,
   FormEpsComponent,
   FormCiesComponent,
   ListadoCiesComponent,
   ListadoTiposIdsComponent,
   ListadoNivelesEscComponent,
   ListadoEpsComponent,
   FormFondosPensionComponent,
   ListadoFondosPensionComponent,
   ListadoEspecialdiadesComponent,
   FormConsultoriosComponent,
   ListadoTipoCitasComponent,
   DatosEmpresaComponent,
   FormOcupacionesComponent,
   ListadoOcupacionesComponent,
   FormCountriesComponent,
   ListadoCountriesComponent,
//   FormCiesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    InputsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DropDownsModule,
    NotificationModule,
    DialogsModule,
    UploadsModule,
    GridModule,
    ExcelModule,
    PDFModule,
    DateInputsModule,
    ButtonsModule,
    ScrollViewModule,
    LayoutModule,
    ExcelExportModule,
    TreeViewModule,
    TooltipModule,

  ],
  providers: [
    { provide: APP_BASE_HREF, useValue : '/' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
